import { React, useState, useMemo } from 'react'
import Table from './Table'

/**
 * The TableComponent function renders a table with specified columns and pagination, using the props
 * passed to it.
 * @param props - The `props` parameter is an object that contains the properties passed to the
 * `TableComponent` component. These properties can be accessed using dot notation, such as
 * `props.menu`, `props.sub_type_master_id`, etc.
 * @returns The TableComponent is being returned.
 */
const TableComponent = (props) => {

    const columns = [
        { accessor: 'Investor', label: 'Firm Name' },
        { accessor: 'fundName', label: 'Fund Name' },
        { accessor: 'sector', label: 'Type' },
        { accessor: 'size ', label: 'Size (US$ M)' },
        // { accessor: 'amount_raised', label: 'Amount raised (US$M) ' },
        { accessor: ' closeStatus', label: 'Status' },
        { accessor: 'source', label: 'Capital Source' },
        { accessor: 'fundingDate', label: 'Date' },
         { accessor: 'add', label: 'Add' },
    
 
      ]
     console.log(props.menu,'fund props menu');
     const [pagination, setPagination] = useState()
     const paginationData = useMemo(() => pagination, pagination); 


    return (
      
        <div className="pr-[8px] pl-[11px]">
            <div className="App lg:relative">
                <Table  columns={columns} menu={props?.menu} pagination = {paginationData} sub_type_master_id={props?.sub_type_master_id}/>
            </div>
        </div>
    )
}

export default TableComponent