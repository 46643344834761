import React, { useState } from 'react';
import { SteppedLineTo } from 'react-lineto';

/**
 * The RangeComponent function is a React component that renders two input fields for selecting minimum
 * and maximum values, and updates the selectedChild state when the values are changed.
 * @returns The RangeComponent is returning a JSX element that contains two input fields for selecting
 * a minimum and maximum value. The input fields are wrapped in div elements with classNames that
 * include the uniqueId of the child. The component also includes event handlers for handling changes
 * in the input fields and preventing the input of certain characters.
 */
const RangeComponent = ({ child, selectedChild, setSelectedChild }) => {
  
  const childIndex = child.uniqueId;
  
  console.log(child.values,"jkjlkjlki");
  const lineColor = "#BDBDBD";
  const [minValue, setMinValue] = useState(child.values[0])
  const [maxValue, setMaxValue] = useState(child.values[1])

/**
 * The function `handleMinAmountChange` updates the minimum amount value and the selected child's start
 * year value in an array of objects.
 * @param event - The event parameter is an object that represents the event that triggered the
 * function. In this case, it is an event object related to a change event, such as when the value of
 * an input field is changed.
 */
  const handleMinAmountChange = (event) => {
    const minAMount = event.target.value;
    setMinValue(event.target.value)

    setSelectedChild((prevSelectedChild) =>
      prevSelectedChild.map((row, index) => {
        if (row.id === child.id && row.uniqueId === childIndex) {
          return {
            ...row,
            values: [event.target.value, child.values[1]] // Update start year value, keep end year unchanged
          };
        }
        return row;
      })
    );
  };


  

/**
 * The function `handleMaxAmountChange` updates the `maxAmount` value and modifies the `selectedChild`
 * array by updating the end year value of a specific child object.
 * @param event - The `event` parameter is an object that represents the event that triggered the
 * function. It contains information about the event, such as the target element and the value of the
 * input field that triggered the event. In this case, it is used to get the new value of the input
 * field.
 */
  const handleMaxAmountChange = (event) => {
    const maxAmount = event.target.value;
    setMaxValue(event.target.value)
    setSelectedChild((prevSelectedChild) =>
      prevSelectedChild.map((row, index) => {
        // if (row.id === child.id && index === childIndex) {
          if (row.id === child.id && row.uniqueId === childIndex) {
          console.log('TRUE_ row.id === child.id', row.id, child.id);
          console.log('index === childIndex', row.uniqueId, childIndex);
          return {
            ...row,
            values: [child.values[0], event.target.value] // Keep start year unchanged, update end year value
          };
        } else {
          console.log('FALSE_ row.id === child.id', row.id, child.id);
          console.log('index === childIndex', row.index, childIndex);
        }
        return row;
      })
    );
  };


/**
 * The function `blockInvalidCharForNumber` prevents the input of certain characters in a text field,
 * specifically 'e', 'E', '+', and '-'.
 * @param e - The parameter `e` is an event object that represents the event that triggered the
 * function. In this case, it is likely an event object related to a key press event.
 */
 const blockInvalidCharForNumber = (e) => {
  console.log(e.key,"kjmnkj");
  if(['e', 'E', '+', '-'].includes(e.key)){
      e.preventDefault()
  }
}


  return (
    <div className='flex space-x-2'>
      {/* <pre> {JSON.stringify(child.values, null, 2)}  </pre> */}
    <div className={`singleSearch minValue${childIndex}`}>
    <SteppedLineTo within='query-builder' borderColor={lineColor} delay={100} borderWidth={5} from={`minValue${childIndex}`} to={`maxValue${childIndex}`} orientation={"h"} />
    <input
        key={childIndex}
      type="number"
      name={child.id}
      onChange={handleMinAmountChange}
      onKeyDown={blockInvalidCharForNumber}
      value={child.values[0]}
      // value={minValue}
      placeholder="Min"
      className="p-2 border border-[#D9BD7F] rounded focus:outline-none bg-[#FFFFFF] w-[7rem] "
    />
    </div>
    <div className={`singleSearch maxValue${childIndex}`}>
    <input
        key={childIndex}
      type="number"
      name={child.id}
      onChange={handleMaxAmountChange}
      onKeyDown={blockInvalidCharForNumber}
      value={child.values[1]}
      // value={maxValue}
      placeholder="Max"
      className="p-2 border border-[#D9BD7F] rounded focus:outline-none bg-[#FFFFFF] w-[7rem] "
    />
    </div>
    </div>
  );
};

export default RangeComponent;
