import React from 'react'
import IncubationLayout from '../../Components/IncubationDealPage/IncubationLayout'
import IncubationCard from '../../Components/IncubationDealPage/IncubationCard/Card'
import RefineSearch from '../../Components/RefineSearchComponent'
import IncubationDealDetail from '../../Components/IncubationDealPage/IncubationDealDetail'
import { COMPANY_PROFILE} from '../../Queries/Pe'
import ChatIcon from '../../images/Group 13/png/Group 13.png';
import ChatPopup from '../../Components/ChatPopup'
import chatPopupIcon from '../../images/chatPopupIcon.png'

/**
 * The IncubationDealPage component is a React functional component that renders a layout with a
 * primary and secondary column, and includes a modal and a refine search component.
 * @returns The IncubationDealPage component is being returned.
 */
const IncubationDealPage = () => {
    const [showModal, setShowModal] = React.useState(false);
    return (
        <div>
        <IncubationLayout>
        <div className="flex h-screen flex-col">
                <IncubationCard />
                <div className="flex min-h-0 flex-1 overflow-hidden">
                    {/* Primary column */}
                    <section
                        aria-labelledby="primary-heading"
                        className="flex h-full min-w-0 flex-1 flex-col overflow-y-auto lg:order-last">
                        <IncubationDealDetail COMPANY_PROFILE={COMPANY_PROFILE}  />
                        
                    {/* <Outlet /> */}
                    {/* Your content */}
                    </section>
                    {/* Secondary column (hidden on smaller screens) */}
                    <aside className="hidden lg:order-first lg:block lg:flex-shrink-0">
                        <div className="relative flex h-full  flex-col overflow-y-auto border-r border-gray-200 bg-[#FAF5EA]">
                            {/* Your content */}
                            <RefineSearch />
                        </div>
                    </aside>
                </div>
                {/* <div className="px-[1rem]">          
                    <img className="float-right" src={ChatIcon} /> 
                </div>  */}
                {/* chaticon */}
                <br/>
                 
            </div>
        </IncubationLayout>
    </div>
    )
}

export default IncubationDealPage