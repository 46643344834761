import { Popover } from 'antd';
import React, { useRef, useEffect, useState } from 'react';
import { debounceFn } from '../../Utils/utils';

const OverflowToolTip = (props) => {
    const textElementRef = useRef(null);
    const [hoverStatus, setHover] = useState(false);
    const [page, setPage] = useState(props?.page)
   /**
    * The function `compareSize` compares the scroll width of a text element to its client width and
    * sets a hover state based on the comparison.
    */
    const compareSize = () => {
        const compare =
            textElementRef?.current?.scrollWidth > textElementRef?.current?.clientWidth;
        setHover(compare);
    };

    useEffect(() => {
        compareSize();
    }, [textElementRef, props?.component, props?.originalContent])

    useEffect(() => {
        compareSize();
        window.addEventListener('resize', compareSize);
        return () => {
            window.removeEventListener('resize', compareSize)
        }
    }, []);

    


    useEffect(() => {
        const element = textElementRef?.current;

        if (!element) return;

        const observer = new ResizeObserver(debounceFn(compareSize, 500));

        observer.observe(element);
        return () => {
            observer?.disconnect();
        };
    }, [])



    return (
        <div ref={textElementRef} className={" block lg:overflow-x-clip lg:overflow-ellipsis lg:whitespace-nowrap " + (props?.className ? props?.className : page ? 'whitespace-nowrap md:max-w-[30rem]' : 'md:max-w-[14rem] ')}>
            {!hoverStatus
                ? props?.originalContent ? props?.originalContent : props?.component
                : <Popover placement={props?.isTopCard ? 'bottomLeft' : 'topLeft'} content={props?.component} title={props?.title}>
                    {props?.originalContent ? props?.originalContent : props?.component}
                </Popover>}
        </div>
    );
};

export default OverflowToolTip;