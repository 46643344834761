import React, { useContext, useState } from "react";
import { useParams } from 'react-router-dom';
import LPInvestment from './Investments';
import ExcelDownloader from "../shared/ExcelDownloader";
import { exportExcel } from "../../Config/config";
import useModule from "../../hooks/useModule";
import { UserContext } from "../../Contexts/UserContext";

/**
 * The `Funding` component is a React component that displays limited partners and their investments.
 * @returns The component `Funding` is returning JSX elements.
 */
const Funding = ({menu,exportFileName}) => {
  const [openTab, setOpenTab] = React.useState(1);
  // const [showPopup, setShowPopup] = useState(false);
  const [showXlDownload, setshowXlDownload] = useState(false);
  const { partnerId } = useParams();
  const{primaryMasterId}=useModule();
  const {isStudent, isTrialExport} = useContext(UserContext)



  return (
    <>
      <div className="space-y-6 py-4 px-4 bg-[#EDEDED]">
        <div className=" bg-white border  shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] sm:rounded-lg">
    <div className=" pt-4 px-5  border-b border-b-[#F1F1F1] ">
        <div className="flex justify-between">
            <p className=" text-[14pt] leading-[19pt] text-[#333333] lg:text-[17px] font-semibold mb-3 lg:leading-[22px]">
                 Limited Partners in
            </p>
            
              {isTrialExport !='false' && isStudent =='false' && showXlDownload && <ExcelDownloader
              fileName={exportFileName}
              data={{
                "type": primaryMasterId,
                "export_from": "detail",
                "module_type": "limited_partners",
                "sub_section": "pe_vc_investment",
                "lpid": [partnerId]
              }
              } url={exportExcel} />}
        </div>
    </div>
          <div className="flex flex-row overflow-x-auto space-x-9 scrollbar px-5  border-b border-b-[#F1F1F1]">

           

          </div >
          <div className={openTab === 1 ? "block" : "hidden"} id="link1">
            <LPInvestment setshowXlDownload={setshowXlDownload} menu={menu}/>
          </div>
          {/* <div className={openTab === 2 ? "block" : "hidden"} id="link2">
            <LPExit />
          </div> */}
          {/* <div className={openTab === 3 ? "block" : "hidden"} id="link3">
            <LPAngelInvestment />
          </div> */}
          {/* <div className={openTab === 4 ? "block" : "hidden"} id="link4">
            <LPIncubation />
          </div>
           */}
        </div>
      </div>
    </>
  )
}

export default Funding