import React, { useMemo, useEffect, useState, useCallback, useContext } from "react";
import { useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import SortAscendingIcon from 'mdi-react/SortAscendingIcon';
import SortDescendingIcon from 'mdi-react/SortDescendingIcon';
import { MdOutlineModeEditOutline } from 'react-icons/md';
import { RiDeleteBin5Line } from 'react-icons/ri';
import SortIcon from 'mdi-react/SortIcon';
import axios from "axios";
import { alertDeleteUrl, alertListUrl } from "../../Config/config";
import Paginate from "../Paginate";
import Loader from "../Loader";
import AlertPopup, { AlertManager } from "../shared/AlertPopup";
import { toast } from "react-toastify";
import { Popconfirm, Popover } from "antd";
import CompanyLogo from "../CompanyLogo";
import RandomLogo from "../RandomLogo";
import { UserContext } from "../../Contexts/UserContext";

/**
 * The `AlertEdit` function is a React component that renders a popover with an edit icon, which when
 * clicked, opens an `AlertManager` component.
 * @returns The code is returning a Popover component that contains an MdOutlineModeEditOutline icon.
 */
const AlertEdit = ({ entityId, typeId, onClose, primaryMasterId }) => {
    const [open, setOpen] = useState(false);

    const hide = () => {
        setOpen(false);
    };

    const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
    };

    const closePopup = () => {
        hide();
        onClose();
    }

    return <Popover
        trigger='click'
        placement="leftTop"
        open={open}
        onOpenChange={handleOpenChange}
        content={<AlertManager
            cid={entityId}
            type_id={typeId}
            primaryMasterId={primaryMasterId}
            onClose={closePopup} />}>
        <MdOutlineModeEditOutline className="cursor-pointer -ml-4 md:ml-0" />

    </Popover>
}


/**
 * The `AlertsDataTable` function is a React component that displays a table of alerts based on a
 * selected filter and search text.
 */
function AlertsDataTable({ selectedFilter, searchText }) {

    const [alertList, setAlertList] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [showAlert, setShowAlert] = useState({ show: false });
    const {getToken} = useContext(UserContext);




    const getAlertList = (typeId) => {
        setIsLoading(true)
        axios.post(alertListUrl, {
            "user_id": 10,
            "type_id": typeId
        },{
            headers:{
                "Authorization": getToken()
            }
        }).then(response => {
            setIsLoading(false)
            if (response.status === 200) {
                setAlertList(response.data?.alerts ?? [])
            }
        })
    }

    useEffect(() => {
        getAlertList(selectedFilter ?? "All");
    }, [selectedFilter])


    const onEditClick = (row) => {
        console.log("columncolumn-Edit", row);
        setShowAlert(prev => ({
            ...prev,
            show: !prev.show,
            entityId: row.original?.actionsData?.entityId,
            typeId: row.original?.actionsData?.typeId,
        }));
    }

    const onDeleteClick = (alertId, entityId, typeId) => {
        axios.post(alertDeleteUrl, {
            "user_id": 10,
            "type_id": typeId,
            "entity_id": entityId,
            "alert_id": alertId
        },{
            headers:{
                "Authorization": getToken()
            }
        }).then(response => {
            if (response.status === 200) {
                toast.success(response.data?.message)
                getAlertList(selectedFilter ?? "All");
            }
        })
    }




    const columns = useMemo(() => [
        {
            Header: "Name",
            accessor: "name",
            className: "w-1/5",
            Cell: ({ value }) => {
                return <div className="flex items-center">
                    <RandomLogo id={value.id} name={value.entityName}/>
                    {value?.entityName}
                </div>
            }
        },
        {
            Header: "Type",
            accessor: "type",
            className: "w-1/5",
        },
        {
            Header: "Current Alerts",
            accessor: "currentAlerts",
            className: "w-2/4	",
            Cell: ({ value }) => {
                if (value[0] === null) {
                    return "--"
                }
                return value?.join(', ')
            }
        },
    ], []);

    const data = useMemo(() => {
        let rows = [];
        alertList?.forEach(alert => {
            rows.push({
                name: { entityName: alert.entity_name, id: alert.id },
                type: alert.entity_type,
                currentAlerts: alert?.alert_name ?? [],
                actionsData: {
                    entityId: alert.entity_id,
                    typeId: alert.type_id,
                    selectedEntity: showAlert.entityId,
                    showAlert: showAlert.show,
                    alertId: alert.id,
                    primary_type_master_id:alert.primary_type_master_id,
                }
            })
        })
        return rows;
    }, [alertList, showAlert.entityId, showAlert.show])



    const tableInstance = useTable({
        columns,
        data,
        autoResetPage: false,
        autoResetFilters: false,
        initialState: { pageSize: 10, pageIndex: 0 },
    },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination,
        hooks => {

            hooks.visibleColumns.push(columns => [
                ...columns,
                {
                    id: 'actions',
                    filterable: false,
                    disableSortBy: true,
                    Cell: ({ row }) => {
                        const alertId = row.original?.actionsData?.alertId;
                        const entityId = row.original?.actionsData?.entityId;
                        const typeId = row.original?.actionsData?.typeId;
                        const primaryMasterId =  row.original?.actionsData?.primary_type_master_id;
                        return <div className="flex items-center justify-around">
                            <AlertEdit 
                            entityId={entityId} 
                            typeId={typeId}
                            primaryMasterId={primaryMasterId}
                            onClose={()=>{
                                getAlertList(selectedFilter ?? "All")
                            }}/>

                            <Popconfirm
                                placement="left"
                                title="Are you sure to delete this alert?"
                                onConfirm={() => onDeleteClick(alertId, entityId, typeId)}
                                onCancel={() => { }}
                                okText="Yes"
                                cancelText="No"
                                okButtonProps={{style:{backgroundColor:"#BC8B20"}}}
                            >
                                <RiDeleteBin5Line className="cursor-pointer ml-4 md:ml-0" />
                            </Popconfirm>


                        </div>
                    },
                },
            ])
        }
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        page,
        prepareRow,
        gotoPage,
        setFilter,
        setGlobalFilter,
        state: { pageIndex, pageSize }
    } = tableInstance;



    console.log("tableInstancetableInstance", tableInstance);

    useEffect(() => {
        setGlobalFilter(searchText);
    }, [searchText])


    return (
        <>
            <div className='overflow-x-auto scrollbar'>
                <table className="w-full " {...getTableProps()}>
                    <thead className="text-[12pt] leading-[15pt] bg-[#EDEDED] lg:text-[12px] lg:leading-[15px] text-[#666666] font-sans_semi_bold py-4 px-0 text-center ">
                        {
                            headerGroups.map(headerGroup => (

                                <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                                    {
                                        headerGroup.headers.map(column => (

                                            <th {...column.getHeaderProps([
                                                {
                                                    className: `py-3.5 px-5 whitespace-nowrap ${column.headerClassName}`,
                                                    style: column.headerStyle,
                                                },
                                                column.getSortByToggleProps(),
                                            ])}>
                                                <div className="flex">
                                                    <span className="mr-1">{column.render('Header')}</span>
                                                    {column.disableSortBy ? "" : column.isSorted
                                                        ? column.isSortedDesc
                                                            ? <SortAscendingIcon size={16} className="mt-[0.15rem] lg:mt-0" />
                                                            : <SortDescendingIcon size={16} className="mt-[0.15rem] lg:mt-0" />
                                                        : <SortIcon size={16} className="mt-[0.15rem] lg:mt-0" />}
                                                </div>
                                            </th>
                                        ))
                                    }
                                </tr>
                            ))
                        }
                    </thead>


                    <tbody {...getTableBodyProps()} className="text-[12pt] leading-[16pt] lg:text-[14px] font-sans_book_body text-left">

                        {page.map(row => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()} className="border-b">
                                    {
                                        row.cells.map(cell => {

                                            return (
                                                <td {...cell.getCellProps([
                                                    {
                                                        className: `whitespace-nowrap px-5 py-4 ${cell.column.className}`,
                                                        style: cell.column.style,
                                                    },
                                                ])}>
                                                    {
                                                        cell.render('Cell')
                                                    }
                                                </td>
                                            )
                                        })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                {page?.length === 0
                    ? <div className="w-full h-40 flex items-center justify-center">
                        {isLoading ? <Loader /> : "No alerts found"}
                    </div>
                    : <></>
                }
            </div>
            {rows?.length <= 5
                ? <></>
                : <Paginate
                    currentPage={pageIndex + 1}
                    totalCount={rows?.length}
                    pageSize={pageSize}
                    onPageChange={(page) => {
                        gotoPage(page - 1);
                    }}
                />}
        </>
    )
}

export default AlertsDataTable