import React from 'react';
import { Select } from 'antd';
import { values } from 'lodash';
const { Option } = Select;


/* The code is defining a functional component called `NumberYearsComponent`. It takes in four props:
`child`, `years`, `selectedChild`, and `setSelectedChild`. */
const NumberYearsComponent = ({ child, years, selectedChild, setSelectedChild }) => {

  const childIndex = child.uniqueId;

  console.log(child, years, selectedChild, setSelectedChild, childIndex,"jkjl")
 /**
  * The handleChangeYear function updates the selected year value for a specific child in an array of
  * children.
  * @param event - The `event` parameter is the event object that is triggered when the year is
  * changed. It contains information about the event, such as the target element and the value of the
  * selected year.
  */
  const handleChangeYear = (event) => {
    const selectedYear = event;
    setSelectedChild((prevSelectedChild) =>
      prevSelectedChild.map((prevChild, index) => {
        if (prevChild.id === child.id && child.uniqueId === childIndex) {
          return { ...prevChild, values: [selectedYear] };
        }
        return prevChild;
      })
    );
  };

  return (
    <div className='singleSearch'>
    <Select
    showSearch
    key={childIndex}
      name={child.id}
      onChange={handleChangeYear}
      placeholder="Select a year"
      value={child.values[0] || child.values[0] != "" ? child.values[0] : undefined}
      className="rounded focus:outline-none w-[10rem]"
    >
      {years.map((year) => (
        <Option key={year} value={year}>
          {year}
        </Option>
      ))}
    </Select>
    </div>
  );
};

export default NumberYearsComponent;
