import React, { useEffect, useState, useContext } from 'react'
import axios from 'axios';
import { UserContext } from '../../../src/Contexts/UserContext';
import ArchieveSideMenu from "./ArchieveSideMenu";
import {archivePage} from "./../../Config/config";
/**
 * The `Archive` component is a React component that makes a POST request to an API endpoint, retrieves
 * data, and renders a side menu component with the retrieved data.
 * @returns The component is returning a `<div>` element that contains another `<div>` element with the
 * class names "grid grid-cols-1 lg:grid-cols-7 md:grid-cols-1 m-3 mt-0". Inside this inner `<div>`,
 * there is a component called `ArchieveSideMenu` being rendered, which is passed the `archiveData` and
 * `isLoading` props
 */
const Archive = ({ select, openTab, setOpenTab, accordionClick }) => {
  const { getToken, logout } = useContext(UserContext);
  const [archiveData, setArchiveData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    apiCall();
  },[])
/**
 * The function `apiCall` makes a POST request to an API endpoint, passing an empty object as the
 * request body, and sets the response data to the `archiveData` state variable.
 */
  const apiCall = () => {
    axios(`${archivePage}`, {
      method: "POST",
      data: {
      },
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken()
      }

    })
      .then((response) => {
        setArchiveData(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  return (
    <div>
      <div className="grid grid-cols-1 lg:grid-cols-7 md:grid-cols-1  m-3 mt-0">
        <ArchieveSideMenu
          archiveData={archiveData}
          isLoading={isLoading}
        />
       
      </div>      
    </div>
  )
}

export default Archive
