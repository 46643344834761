import React, { useEffect, useRef } from 'react';
import "../../src/App.css";
const SliderLabels = ({ header, selected, setSelected, foundMap, addCommasToNumber, gsExpand }) => {

    const scrollContainerRef = useRef(null);
    const filteredHeader = header?.filter(h => foundMap[h.accessor] > 0);

    return (
        <div className="relative w-full flex items-center">
            <div 
                ref={scrollContainerRef}
                className="w-full overflow-x-auto px-4 whitespace-nowrap rounded flex items-center gs-scrollbar  scroll-smooth"
            >
                {filteredHeader?.map((h, i) => (
                    <button
                        key={i}
                        className={`min-w-fit text-sm px-1   first:pl-0 last:pr-0 ${gsExpand ? 'mb-[10px]' : ''}`}
                        type="button"
                        onClick={() => {
                        document.getElementById(h.accessor).scrollIntoView({behavior: 'smooth'});
                        setSelected(h.accessor);
                        }}
                    >
                        <span
                        className={`
                            py-1 px-3 text-sm
                            ${h.accessor === selected 
                            ? "bg-[#BC8B20] text-white" 
                            : "text-[#BC8B20] border-[1.25px] border-[#BC8B20] hover:bg-[#BC8B20]/10"
                            }
                            rounded-[13.5px] font-medium leading-[16px]
                            inline-flex items-center justify-center gap-1 transition-colors
                        `}
                        >
                        {h.label}
                        <span className="text-xs">
                            ({addCommasToNumber(foundMap[h.accessor])})
                        </span>
                        </span>
                    </button>
                ))}
            </div>
        </div>
    );
};

export default SliderLabels;