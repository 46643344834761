import React, { useState, useMemo, useContext, useRef, useEffect } from "react";
import { useSearchParams, Routes, Route, useParams, useLocation } from 'react-router-dom';
import { useQuery } from "@apollo/client";
import { CompareCompanyContext } from '../../../Contexts/CompareCompanyContext'
import { NavLink } from 'react-router-dom';
import compare from '../../../images/compare.png'
import pdf from '../../../images/pdf.png'
import atom from '../../../images/atom.png'
import xls from '../../../images/download-file-icon.png'
import edit from '../../../images/combined-shape.png'
import addNoted from "../../../images/AtomFavourite Copy/png/AtomFavourite Copy.png";
import addNotedActive from '../../../images/AtomFavourite Copy 3/png/AtomFavourite Copy 3.png';
import ReactReadMoreReadLess from "react-read-more-read-less";
import { descriptionCharLimit, exportPdf, getInvestorFundingUrl, getInvestorProfileUrl, invIncProfile } from "../../../Config/config"
import barRaiser from '../../../images/barraiser-logo.png'
import InvestorProfileHighlights from './InvestorIncubationProfileHighlight'
import InvestorAngelPortfolioCompanies from "./InvestorAngelPortfolioCompanies";
import InvestorIncubationPortfolioCompanies from "./InvestorIncubationPortfolioCompanies"
import InvestorPortfolioExits from './InvestorPortfolioExits'
import InvestorFunds from './InvestorFunds'
// import InvestorCapTable from './CapTable'
import InvestorCo_Investors from './InvestorCo_Investors'
import InvestorNewsActivity from './InvestorNewsActivity'
import ComparePopUp from '../../../Pages/ComparePopUp'
import Warning from '../../../images/warning-btn.png'
import AddNote from "../../../Pages/addNote";
import CompanyLogo from '../../CompanyLogo'
import RandomLogo from '../../RandomLogo'
import AlertPopUp from "../../DetailPage/AlertPopUp";
import PENotesPopup from '../../NotesPopup/PENotesPopup'
import SendDealPopUp from "../../SendDealPopUp";
import ExcelPopup from '../../DetailPage/DetailExcelPopUp.jsx'
import { INVESTOR_COMPANY_PROFILE, INVESTOR_PROFILE_TABLE_QUERY, INVESTOR_PORTFOLIO_TABLE_QUERY, CO_INVESTOR_TAB, EXIT_PORTFOLIO_TABLE_QUERY, INVESTORNEWSLETTER } from "../../../Queries/Pe"
import PdfDownloader from "../../shared/PdfDownloader";
import Constants from "../../../Constants";
import { Tooltip } from "antd";
import axios from 'axios';
import { UserContext } from "../../../Contexts/UserContext";
import useModule from "../../../hooks/useModule";
import { routeNames } from "../../../routeSegments";
import Loader from "../../Loader";
import AddNotePopover from "../../shared/AddNotePopover";
import Tab from '../../shared/Tab';
import { debounce, delay } from 'lodash';
import { useInView } from 'react-intersection-observer';
import { TableDataContext } from "../../../Contexts/TableDataContext";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";


/**
 * The `InvestorCompanyDetail` function is a React component that displays the details of an investor
 * company, including its profile, investments, and news activity. It also provides functionality for
 * comparing the company with others and adding notes.
 * @returns The component is returning a JSX element.
 */
const InverstorCompanyDetail = (props) => {
    const overview = () => { document.getElementById("profile").scrollIntoView({ behavior: 'smooth' });}
    const investments = () => { document.getElementById("investments").scrollIntoView({ behavior: 'smooth' }); }
    const newsActivity = () => { document.getElementById("news").scrollIntoView(({ behavior: 'smooth' })); }
    const [showModal, setShowModal] = useState(false);
    const [showAdd, setShowAdd] = useState(false)
    const [showPopup, setShowPopup] = useState(false);
    const [isActive, setIsActive] = useState(1);
    const [showNote, setShowNote] = useState(false)
    const [showMyNote, setshowMyNote] = useState(false);
    const location = useLocation();
    const [note, setNote] = useState(false)
    const [profileSection, setProfileSection] = useState({})

    const { companyDetail, setCompanyDetail, setCurrentPage } = useContext(CompareCompanyContext);


    const [profile, setProfile] = useState({});
    const [profileHighlights, setProfileHighlights] = useState([]);
    const [investmentPortfolio, setInvestmentPortfolio] = useState([])
    const [exitPortfolio, setexitPortfolio] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isProfileLoading, setIsProfileLoading] = useState(true);
    const { primaryMasterId, isPE,isVC } = useModule();
    const { getToken } = useContext(UserContext);
    const { investorId } = useParams();
    const [skipScroll, setSkipScroll] = useState(false)
    const [overviewEl, isOverviewVisible, overviewEntry] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })
    const [investmentEl, isInvestmentVisible, investmentVisibleEntry] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })
    const [newsEl, isnewsVisible, newsEntry] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })

    const getFundsData = () => {
        setIsLoading(true);
        axios.post(getInvestorFundingUrl, {
            "category": props.page,
            "investor_id": investorId,
            "type": primaryMasterId
        }, {
            headers: {
                'Authorization': getToken(),
            }
        }).then(response => {
            if (response.status == 200) {
                setInvestmentPortfolio(response.data?.data?.investments);
                setexitPortfolio(response.data?.data?.exits);
                setIsLoading(false);
            }
            console.log(response.data?.data?.investments, "datas")

        }).catch(error => {
            setInvestmentPortfolio([]);
            setexitPortfolio([]);
            setIsLoading(false);
        })
    }
    const getProfileData = () => {
        setIsProfileLoading(true);
        axios.post(getInvestorProfileUrl, {
            "category": props.page,
            "investor_id": investorId,
            "type": primaryMasterId
        }, {
            headers: {
                'Authorization': getToken(),
            }
        }).then(response => {
            if (response.status == 200) {
                setProfile(response.data?.data?.topProfile)
                setProfileHighlights(response.data?.data?.profile ?? [])
                setIsProfileLoading(false);
            }
        }).catch(error => {
            setIsProfileLoading(false);
        })
    }

    useEffect(() => {
        getFundsData();
        getProfileData();
    }, [investorId])

    const tagsList = profile?.tags?.split(",").map((tag) => {
        const capital = tag.charAt(0).toUpperCase() + tag.slice(1);
        return (
            <li className="mt-2 lg:mt-0 nav-item flex active " role="presentation">
                {capital ? (
                    <p className=" lg:block text-[10.7pt] mt-2 leading-[17pt] lg:leading-[16px] font-sans_book_body px-3 mr-2 py-1 text-center text-[#333333] bg-[#EFEFEF] lg:text-[12px] rounded-[5px]">
                        {capital}
                    </p>
                ) : null}
            </li>
        );
    });

    const desc = profile?.description?.trim() ?? "";
    const descLen = desc?.length;

    const onTabClick = (tabIndex) => {
        setSkipScroll(true);
        delay(()=>{
            setSkipScroll(false);
        },500)
        if (tabIndex === 1) {
            overview()
        } else if (tabIndex === 2) {
            investments()
        }  
        else if (tabIndex === 3) {
            newsActivity();
        }  
        setIsActive(tabIndex);
    }

    const setIsActiveOnScroll = useMemo(() => {
        return debounce(index => setIsActive(index), 100)
    }, [])
    

    useEffect(() => {

        if (isOverviewVisible) {
            setIsActiveOnScroll(1);
        } 
        else if (isInvestmentVisible)  {
           
            setIsActiveOnScroll(2);
        }
         else if (isnewsVisible) {
            setIsActiveOnScroll(3);
        }

    }, [isOverviewVisible,isInvestmentVisible, isnewsVisible, setIsActiveOnScroll])

    const subTypeMasterId =() =>{
        if(isPE){
            
            if(props?.page=='angel'){
                return Constants.subCategoryIds.pe_investor_angel_invesments_id
            }
            else if(props?.page=='incubation'){
                return Constants.subCategoryIds.pe_investor_incubation_id
            }
        }
        else if(isVC){
            
            if(props?.page=='angel'){
                return Constants.subCategoryIds.vc_investor_angel_invesments_id
            }
            else if(props?.page=='incubation'){
                return Constants.subCategoryIds.vc_investor_incubation_id
            }

        }
    }

        const { tableInvestorId: invId } = useContext(TableDataContext);
        
         const currentIndex = invId?.indexOf(Number(investorId));

        function getLeftNavLinkRoute(id, page, inverstorId, index) {
            if (id === Constants.primaryMasterIds.vcId) {
                switch (page) {
                case 'angel':
                    return routeNames.vcAngelInvestorById(inverstorId[index - 1]);
                case 'incubation':
                    return routeNames.vcIncubationInvestorById(inverstorId[index - 1]);
                default:
                    return null;
                }
            } else {
                switch (page) {
                case 'angel':
                    return routeNames.peAngelInvestorById(inverstorId[index - 1]);
                case 'incubation':
                    return routeNames.peIncubationInvestorById(inverstorId[index - 1]);
                default:
                    return null;
                }
            }
        }
        function getRightNavLinkRoute(id, page, inverstorId, index) {
            if (id === Constants.primaryMasterIds.vcId) {
                switch (page) {
                case 'angel':
                    return routeNames.vcAngelInvestorById(inverstorId[index + 1]);
                case 'incubation':
                    return routeNames.vcIncubationInvestorById(inverstorId[index + 1]);
                default:
                    return null;
                }
            } else {
                switch (page) {
                case 'angel':
                    return routeNames.peAngelInvestorById(inverstorId[index + 1]);
                case 'incubation':
                    return routeNames.peIncubationInvestorById(inverstorId[index + 1]);
                default:
                    return null;
                }
            }
        }


    return (
        <>
            <div className="bg-[#FFFFFF] relative">
                <nav className="flex p-1 lg:px-1 px-3 border-b  " aria-label="Breadcrumb">
                    <ol className="flex items-center space-x-1 overflow-x-auto scrollbar-remove">
                        <li className='flex items-center'>
                            <div className='flex items-center whitespace-nowrap'>
                            {console.log(location.state?.prevPath,'location.state?.prevPath')}
                                <NavLink to={location.state?.prevPath != null && location.state?.prevPath != ''
                                    ? location.state?.prevPath
                                    : props.menu == "vc" ? routeNames.vc_investors_vc_investments
                                        : routeNames.pe_investors_pe_vc_investments} className="ml-2 text-[11pt] lg:text-[12px] text-[#666666] font-sans_book_body">
                                    Investor</NavLink>

                            </div>
                        </li>

                        <li>
                            <div className="flex items-center  whitespace-nowrap">
                                <span className=" text-[12px] text-[#666666] mr-1">/</span>
                                <a className=" text-[11pt] lg:text-[12px] text-[#666666] font-semibold"
                                    aria-current="page">{profile?.investor_name}</a>
                            </div>
                        </li>
                    </ol>
                {invId && 
                    <>
                        <Tooltip title="Prev" placement="bottom">
                            <NavLink
                            className="absolute top-2 right-8"
                            to={getLeftNavLinkRoute(primaryMasterId, props.page, invId, currentIndex )}
                            >
                            {invId.length === 0 || currentIndex === 0 ? null : <FaChevronLeft size={15} color='grey'/>}
                            </NavLink>
                        </Tooltip>
                        <Tooltip title="Next" placement="bottom">
                            <NavLink
                            className="absolute top-2 right-4"
                            to={getRightNavLinkRoute(primaryMasterId, props.page, invId, currentIndex)}
                            >
                            {invId.length - 1 === currentIndex ? null : (
                                <FaChevronRight size={15} color='grey'/>
                            )}
                            </NavLink>
                        </Tooltip>
                    </>
                }
                </nav>
                {/* <!-- BarRaiser--> */}
                {isProfileLoading ?
                    <div className="h-[10rem] w-full bg-[#FFFFFF] flex items-center justify-center ">
                        <Loader />
                    </div>
                    :
                    <div className="px-5 ">
                        <div className="lg:hidden mt-3  grid  md:grid-cols-8 grid-cols-10  items-center mb-4">
                            <div className="md:col-span-6 col-span-5" title="Compare Company">
                                {/* <button
                                    className={
                                        showModal
                                            ? "bg-[#BC8B20] text-white text-[14px] py-1 lg:py-1 px-2 lg:px:4 border border-[#DDDDDD] rounded-[4px] inline-flex items-center"
                                            : "bg-[#FFFFFF] text-[#666666] text-[14px] py-1 lg:py-1 px-2 lg:px:4 border border-[#DDDDDD] rounded-[4px] inline-flex items-center"
                                    }
                                    type="button"
                                    onClick={() => {
                                        setShowModal(!showModal)
                                        setCompanyDetail([])
                                        setCurrentPage(props?.page == "angels" ? "investorAngel" : 'investorIncubation')
                                    }
                                    }
                                >
                                    <img
                                        className={
                                            showModal ? "pr-1 brightness-0 invert-[1]" : "pr-1"
                                        }
                                        src={compare}
                                        alt=""
                                    />
                                    <span>Compare</span>
                                </button> */}
                            </div>
                            <div className="flex md:col-span-2 col-span-5 space-x-3">
                                <div >
                                    <AlertPopUp cid={investorId} type_id={2} page="investor"/>
                                </div>
                                <div >
                                    <span title="Save List">
                                        <img className=" cursor-pointer w-[24px] h-[22px]"
                                            src={showNote ? addNotedActive : addNoted} onClick={() => setShowNote(!showNote)}
                                            alt=""
                                        />
                                        {showNote ? <AddNote module="Investors" page="detailpage" type_id={2} cname={profile?.investor_name ?? ""} cid={investorId} sub_type_master_id={subTypeMasterId()} /> : ""}
                                    </span>
                                </div>


                                <div className="col-span-1">
                                    <PENotesPopup note={note} cid={investorId} type_id={2} />
                                </div>
                                <div className="col-span-1">
                                    <SendDealPopUp data={profile?.investor_name ?? ""} />
                                </div>

                                <div >
                                    <img className=" w-5 h-6" src={pdf} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="pb-4 pt-6 bg-[#FFFFFF]">
                            <ul >
                                <li className="w-full flex ">
                                    <div className=" rounded-md">
                                        {profile?.icon_path == "default.png" || profile?.icon_path == "/default.png" || !profile?.icon_path ?
                                            <RandomLogo name={profile?.investor_name} page="detail page" />
                                            :
                                            <CompanyLogo name={profile?.investor_name} page="detail page" logo={profile?.icon_path} />

                                        }
                                    </div>

                                    <div className="ml-3 flex flex-1 flex-col">
                                        <a className="text-[#333333] text-[22px] font-semibold  leading-[24px]" href="#"> {profile?.investor_name} </a>
                                        <span className="text-[12pt] leading-[16pt] text-[#333333]  lg:text-[13px] lg:leading-[17px] font-sans_roman_body  ">{profile?.firm_type ? profile?.firm_type : ""}</span>
                                    </div>

                                    <div className="hidden lg:grid grid-cols-8 gap-2 mr-2 h-10">
                                        <div className="col-span-3 mt-2">

                                        </div>

                                        <div className="col-span-5 mt-[5px]">
                                            <div className="grid grid-cols-5 gap-2 flex items-center">
                                                <div className="col-span-1 ">
                                                    <AlertPopUp cid={investorId} type_id={2} page="investor" />{" "}
                                                </div>

                                                <div className="col-span-1" >
                                                    <AddNotePopover
                                                        title="Save List"
                                                        companyId={investorId}
                                                        companyName={profile?.investor_name ?? ""}
                                                        type_id={props.page=="angel" ? Constants.moduleIds.investorsId : Constants.moduleIds.incubators_id}
                                                        sub_type_master_id={subTypeMasterId()}
                                                        IsDetailed={true} />
                                                    {/* <Tooltip title="Save List">
                                                        <img
                                                            className="w-[22px] h-[22px] cursor-pointer"
                                                            src={showNote ? addNotedActive : addNoted} onClick={() => setShowNote(!showNote)}
                                                            alt=""
                                                        />
                                                        {showNote ? <AddNote module="Investors" page="detailpage" type_id={Constants.moduleIds.incubators_id} cname={profile?.investor_name ?? ""} cid={investorId} /> : ""}
                                                    </Tooltip> */}
                                                </div>
                                                <div className="col-span-1 mt-1">
                                                    <PENotesPopup note={note} cid={investorId} type_id={props.page=="angel" ? Constants.moduleIds.investorsId : Constants.moduleIds.incubators_id} />
                                                </div>
                                                <div className="col-span-1">
                                                    <SendDealPopUp data={profile?.investor_name ?? ""} />
                                                </div>
                                                <div className="col-span-1">
                                                    {/* {props.menu == "dealpage" ?

                                                        <img
                                                            title="Export"
                                                            src={xls}
                                                            className="h-[27px] w-[25px] cursor-pointer"
                                                            onClick={() => setShowPopup(!showPopup)}
                                                        />

                                                        :
                                                        <img className="w-[25px] h-[27px] cursor-pointer" title="Export" src={pdf} alt="" />
                                                    }
                                                    {showPopup && (
                                                        <div>
                                                            <ExcelPopup menu="dealpage" showPop={showPopup} setShowPop={setShowPopup} />{" "}
                                                        </div>
                                                    )} */}
                                                    <PdfDownloader
                                                        url={exportPdf}
                                                        fileName={profile?.investor_name?.replaceAll(/\s/g, '')}
                                                        data={{
                                                            "type_id": 2,
                                                            "entity_id": investorId,
                                                            "category": props.page,
                                                            "type": primaryMasterId
                                                        }} />
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </li>
                                {showAdd ? <AddNote id={1} /> : ""}
                            </ul>
                        </div>
                        {/* <!-- tags--> */}

                        {/* <ul
                            className="nav nav-tabs flex flex-wrap  list-none border-b-0 pl-0 mb-0"
                            id="tabs-tab"
                            role="tablist"
                        >
                            {tagsList}
                        </ul> */}

                        {/* <a className="text-[#BC8B20] text-[13px] leading-[16px]" href="#">... View More</a> */}

                        {profile?.industry != "" && profile?.industry != null ? <div>
                            <p className="text-[11pt] leading-[17pt] lg:block lg:leading-[16px]  text-[#333333]  lg:text-[13px] font-sans_book_body mr-3 pb-3">
                                Industries (Existing Investments): {profile?.industry}
                            </p>
                        </div> : <></>}


                        <div className="bg-[#FFFFFF]">

                            {desc != "" && desc != null ? <div >
                                <p className=" text-[11pt] leading-[18pt] py-2 lg:text-[14px] text-[#333333] lg:leading-[20px] font-sans_book_body">
                                    {/* {ProfileHighlightData?.Description ? */}
                                    <ReactReadMoreReadLess
                                        charLimit={descLen}
                                        readMoreText={"View More "}
                                        readLessText={"View Less "}
                                        readMoreClassName="read-more-less--more lg:[text-12px]"
                                        readLessClassName="read-more-less--less lg:[text-12px]"
                                    >
                                        {profile?.description ? desc : "--"}

                                    </ReactReadMoreReadLess>
                                    {/* : ""} */}
                                </p>
                            </div> : <></>}


                        </div>
                    </div>
                }
              
            </div>
            <div className="bg-[#EDEDED]">
                <div className="flex flex-row overflow-x-auto scrollbar space-x-6 bg-white px-6 pt-1  border-b border-[#D7D7D7] cursor-pointer sticky top-0  custom-z-index scrollbar-remove">
                    <Tab active={isActive === 1} label={"Overview"} onClick={() => onTabClick(1)} />
                    <Tab active={isActive === 2} label={props?.page == "angel" ? "Investments":"Incubatee"} onClick={() => onTabClick(2)} />                   
                    <Tab active={isActive === 3} label={"Recent News & Activity"} onClick={() => onTabClick(3)} />
                </div>
                    <div className="">
                        <section ref={overviewEl} className="scroll-mt-14" id="profile"><InvestorProfileHighlights isLoading={isProfileLoading} profileHighlights={profileHighlights} page={props?.page} /></section>
                            {props?.page == "angel" ?
                                <section ref={investmentEl} className="scroll-mt-10" id="investments"><InvestorAngelPortfolioCompanies investmentsData={investmentPortfolio} page="angels" investorName={profile?.investor_name} /></section>
                                :
                                <section ref={investmentEl} className="scroll-mt-10" id="investments"><InvestorIncubationPortfolioCompanies investmentsData={investmentPortfolio} page="incubations" investorName={profile?.investor_name} /></section>
                            }
                        <section ref={newsEl} className="scroll-mt-14" id="news"><InvestorNewsActivity menu="investorDetail" /></section>
                    </div>
            </div>
            {showModal ? (<ComparePopUp id={1} onClose={(e) => setShowModal(false)} />) : null}
        </>
    )

}

export default InverstorCompanyDetail