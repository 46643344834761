import {React, useState, createContext} from 'react'

export const AddCompanyContext = createContext('');

/**
 * The AddCompanyProvider function is a React component that provides a context for managing a list of
 * companies and their details.
 * @returns The code is returning a component that wraps its children with the
 * `AddCompanyContext.Provider` component. The `AddCompanyContext.Provider` component is providing the
 * `addDetail` object as the value to the context. The `addDetail` object contains the `addValues`
 * array and the `setAddValues` function, which are used to manage the state of the component.
 */
const AddCompanyProvider = ({children}) => {
    const [addValues, setAddValues] = useState(
        [
            {
                id:1,
                name:"HDFC",
                Details:[
                    "Round 3,4 & Growth Stage Company",
                    "Investors list 2",
                    "Preferred company list 3",
                    "Round 4 & Pharma Companies",
                    "Early stage IT & ITS Companies",
                    "Investors list 1"
                ]
            },
            {
                id:2,
                name:"ICICI",
                Details:[
                    "Round 3,4 & Growth Stage Company",
                    "Investors list 2",
                    "Preferred company list 3",
                    "Round 4 & Pharma Companies",
                    "Early stage IT & ITS Companies",
                    "Investors list 1"
                ]
            },
            {
                id:3,
                name:"SKIL Infrastructure",
                Details:[
                    "Round 3,4 & Growth Stage Company",
                    "Investors list 2",
                    "Preferred company list 3",
                    "Round 4 & Pharma Companies",
                    "Early stage IT & ITS Companies",
                    "Investors list 1"
                ]
            },
            {
                id:4,
                name:"National Stock Exchange",
                Details:[
                    "Round 3,4 & Growth Stage Company",
                    "Investors list 2",
                    "Preferred company list 3",
                    "Round 4 & Pharma Companies",
                    "Early stage IT & ITS Companies",
                    "Investors list 1"
                ]
            },
            {
                id:5,
                name:"Punj Lloyd",
                Details:[
                    "Round 3,4 & Growth Stage Company",
                    "Investors list 2",
                    "Preferred company list 3",
                    "Round 4 & Pharma Companies",
                    "Early stage IT & ITS Companies",
                    "Investors list 1"
                ]
            }
        ]
    );

    const addDetail = { addValues, setAddValues }
    console.log(addValues,"1234567890")

    return (
        <AddCompanyContext.Provider value={addDetail}>
            {children}
        </AddCompanyContext.Provider>
    )
}

export default AddCompanyProvider
