import { React, useState, useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { useSearchParams, Routes, Route, useParams } from 'react-router-dom';
import ReactReadMoreReadLess from "react-read-more-read-less";
import addNoted from "../../images/AtomFavourite Copy/png/AtomFavourite Copy.png";
import compare from '../../images/compare.png'
import pdf from '../../images/pdf.png'
import atom from '../../images/atom.png'
import edit from '../../images/combined-shape.png'
import xls from '../../images/download-file-icon.png'
import barRaiser from '../../images/barraiser-logo.png'
import ComparePopUp from '../../Pages/ComparePopUp'
import ExcelPopup from "../../Components/ExcelPopup";
import AddNote from "../../Pages/addNote";
import Warning from '../../images/warning-btn.png'
import { descriptionCharLimit } from "../../Config/config"



/* The above code is a React component written in JavaScript. It is a functional component called
"IncubationCompanyDetailInformation". */
const IncubationCompanyDetailInformation = (props) => {
    console.log(props, "asdfghjkl")
    const [showAdd, setShowAdd] = useState(false)
    const [showPopupPDF, setShowPopupPDF] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const { companyId } = useParams();
    let companyProfileId = parseInt(companyId)
    const [showModal, setShowModal] = useState(false);
    const [isActive, setIsActive] = useState(1)


    let longText = " BarRaiser, a Bengaluru ,based.AI powered smart Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat culpa unde nostrum vel nihil amet minus laboriosam voluptas placeat natus laudantium eligendi quam earum tempora temporibus, sapiente fuga Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat culpa unde nostrum vel nihil amet minus."
    const charLength = descriptionCharLimit;

    return (
        <>
            <div>
                {/* <!-- BarRaiser--> */}
                <div className="pl-6">
                    <div className="lg:hidden mt-3 ml-4 lg:grid grid-cols-10 grid grid-cols-12  items-center mb-4">
                        <div className="lg:col-span-6 col-span-8">
                            <button className={showModal ? "bg-[#BC8B20] text-white text-[14px] py-1 lg:py-1 px-2 lg:px:4 border border-[#DDDDDD] rounded-[4px] inline-flex items-center" : "bg-[#FFFFFF] text-[#666666] text-[14px] py-1 lg:py-1 px-2 lg:px:4 border border-[#DDDDDD] rounded-[4px] inline-flex items-center"}
                                type="button" onClick={() => setShowModal(!showModal)}>
                                <img className={showModal ?
                                    "pr-1 brightness-0 invert-[1]" : "pr-1"} src={compare} alt="" />
                                <span>Compare</span>
                            </button>
                        </div>
                          <div className="col-span-1 ">
                            <img className=" pr-1" src={Warning} alt="" />
                        </div>
                        <div className="col-span-1 ">
                            <img className=" pr-1" src={atom} alt="" />
                            {/* <span>Add</span> */}
                        </div>
                        <div className="col-span-1 ">
                            <img className=" pr-1" src={edit} alt="" />
                        </div>
                        <div className="col-span-1">
                            <img className=" pr-2" src={pdf} alt="" />
                        </div>
                    </div>

                    {/* mobileview */}
                    {/* <div className='lg:hidden'>
                    <div className="lg:hidden mt-3 ml-4 grid grid-cols-9 items-center mb-4">
                        <div className="col-span-6">
                            <button className={showModal ? "bg-[#BC8B20] text-white text-[14px] py-1 lg:py-1 px-2 lg:px:4 border border-[#DDDDDD] rounded-[4px] inline-flex items-center" : "bg-[#FFFFFF] text-[#666666] text-[14px] py-1 lg:py-1 px-2 lg:px:4 border border-[#DDDDDD] rounded-[4px] inline-flex items-center"}
                                type="button" onClick={() => setShowModal(!showModal)}>
                                <img className={showModal ?
                                    "pr-1 brightness-0 invert-[1]" : "pr-1"} src={compare} alt="" />
                                <span>Compare</span>
                            </button>
                        </div>
                        <div className="col-span-1 ">
                            <img className=" pr-1" src={atom} alt="" />
                            <span className='hidden lg-flex'>Add</span>
                        </div>
                        <div className="col-span-1 ">
                            <img className=" pr-1" src={edit} alt="" />
                        </div>
                        <div className="col-span-1">
                            <img className=" pr-1" src={pdf} alt="" />
                        </div>
                    </div>
                    </div> */}
                    {/* mobileview */}


                    <div className="pb-4 pt-6 bg-[#FFFFFF]">
                        <ul role="list" >
                            {/* {Object.keys(props?.data).length >0? */}
                            <li className="w-full flex ">
                                <div className=" rounded-md">
                                    <img src={barRaiser} alt="" />
                                </div>

                                <div className="ml-3 flex flex-1 flex-col">
                                    <a className="text-[#333333] text-[22px]  font-sans_semi_bold leading-[24px]" href="#"> HDFC </a>
                                    <span className="text-[12pt] leading-[16pt] text-[#333333] lg:text-[12px] lg:text-[13px] lg:leading-[17px] font-sans_roman_body">Manufacturing</span>
                                    <span className="text-[11pt] leading-[21pt] text-[#777777] lg:text-[13px] leading-[21px] font-sans_book_body">Financial Services</span>
                                </div>
                                <div className="hidden lg:grid grid-cols-7 mr-4 gap-1 ">
                                    <div className="col-span-3 mt-2">
                                        <button className={showModal ? "bg-[#BC8B20] text-white text-[13px] py-1 lg:py-1 px-2 lg:px:4 border border-[#BC8B20] rounded-[4px] inline-flex items-center" : "bg-[#FFFFFF] text-[#666666] text-[13px] py-1 lg:py-1 px-2 lg:px:4 border border-[#DDDDDD] rounded-[4px] inline-flex items-center"}
                                            type="button" onClick={() => setShowModal(!showModal)}>
                                            <img className={showModal ?
                                                "brightness-0 invert-[1] w-[20px] h-[18px] pr-1" : "w-[20px] h-[18px] pr-1 blur-btn"} src={compare} alt="" />
                                            <span>Compare</span>
                                        </button>
                                    </div>
                                    <div className='col-span-4 mt-2'>
                                        <div className='grid grid-cols-4 gap-3 flex items-center'>
                                            <div className="col-span-1 ">
                                                <img className="w-[24px] h-[22px]" src={Warning} alt="" />
                                            </div>
                                            <div className="col-span-1">
                                                <img className="w-[22px] h-[22px]" src={atom} alt="" />
                                            </div>
                                            <div className="col-span-1">
                                                <img className="w-[22px] h-[22px]" src={edit} alt="" />
                                            </div>
                                            <div className="col-span-1">
                                                <img className="w-[25px] h-[27px]" src={pdf} alt=""
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            {showAdd ? <AddNote id={companyProfileId} /> : ""}
                        </ul>

                    </div>
                    {/* <!-- tags--> */}
                    <div className="bg-[#FFFFFF]">
                        <ul className="nav nav-tabs flex flex-wrap font-sans_book_body  list-none border-b-0 pl-0 mb-4" id="tabs-tab"
                            role="tablist">
                            <li className=' lg:block text-[10.7pt] leading-[17pt] lg:leading-[16px] font-sans_book_body px-3 mr-2 py-1 text-center text-[#333333] bg-[#EFEFEF] lg:text-[12px] rounded-[5px]'>Bfsi</li>
                            <li className=' lg:block text-[10.7pt] leading-[17pt] lg:leading-[16px] font-sans_book_body px-3 mr-2 py-1 text-center text-[#333333] bg-[#EFEFEF] lg:text-[12px] rounded-[5px]'>Consumer</li>
                            <li className=' lg:block text-[10.7pt] leading-[17pt] lg:leading-[16px] font-sans_book_body px-3 mr-2 py-1 text-center text-[#333333] bg-[#EFEFEF] lg:text-[12px] rounded-[5px]'>Leading</li>
                            <li className=' lg:block text-[10.7pt] leading-[17pt] lg:leading-[16px] font-sans_book_body px-3 mr-2 py-1 text-center text-[#333333] bg-[#EFEFEF] lg:text-[12px] rounded-[5px]'>Nbfc</li>
                        </ul>
                        {/* View more */}
                        <div >
                            <p className=" text-[11pt] leading-[18pt] py-2 lg:text-[14px] text-[#333333] lg:leading-[20px] font-sans_book_body">
                                {longText ?
                                    <ReactReadMoreReadLess
                                        charLimit={charLength}
                                        readMoreText={"View More "}
                                        readLessText={"View Less "}
                                        readMoreClassName="read-more-less--more lg:text-[13px]"
                                        readLessClassName="read-more-less--less lg:text-[13px]"
                                    >
                                        {longText}

                                    </ReactReadMoreReadLess>
                                    : ""}
                            </p>
                        </div>
                    </div>

                    <div className="flex flex-row overflow-x-auto scrollbar  bg-white pt-4  border-b border-[#D7D7D7] cursor-pointer space-x-6">
                        <div className='space-x-6 flex'>
                            <a className={
                                "py-2 whitespace-nowrap text-[14px] leading-[17px] text-[#333333]  " +
                                (isActive === 1
                                    ? "  font-sans_semi_bold border-b-[#F1CA75] border-b-[3px] "
                                    : "   font-sans_medium_body opacity-[0.4]")
                            }
                                onClick={e => {
                                    e.preventDefault();
                                    setIsActive(1);
                                }}>
                                <p>Incubation</p>
                                {/* <p>Feb-18</p> */}
                            </a>
                            <a className={
                                "py-2 whitespace-nowrap  text-[14px] leading-[17px] text-[#333333] " +
                                (isActive === 2
                                    ? "  font-sans_semi_bold border-b-[#F1CA75] border-b-[3px] "
                                    : "  font-sans_medium_body opacity-[0.4]")
                            }
                                onClick={e => {
                                    e.preventDefault();
                                    setIsActive(2);
                                }}>
                                <p>Angel</p>
                                {/* <p>Dec-18</p> */}
                            </a>
                        </div>
                        <div className='flex space-x-6'>
                            <a className={
                                "py-2 whitespace-nowrap  text-[14px] leading-[17px] text-[#333333]  " +
                                (isActive === 3
                                    ? " font-sans_semi_bold border-b-[#F1CA75] border-b-[3px]"
                                    : "  font-sans_medium_body opacity-[0.4]")
                            }
                                onClick={e => {
                                    e.preventDefault();
                                    setIsActive(3);
                                }}>Seed</a>
                            <a className={
                                "py-2 whitespace-nowrap  text-[14px] leading-[17px] text-[#333333] " +
                                (isActive === 4
                                    ? " font-sans_semi_bold border-b-[#F1CA75] border-b-[3px]"
                                    : " font-sans_medium_body opacity-[0.4]")
                            }
                                onClick={e => {
                                    e.preventDefault();
                                    setIsActive(4);
                                }} >Series B</a>
                            <a className={
                                "py-2 whitespace-nowrap  text-[14px] leading-[17px] text-[#333333]  " +
                                (isActive === 5
                                    ? "  font-sans_semi_bold border-b-[#F1CA75] border-b-[3px]"
                                    : "  font-sans_medium_body opacity-[0.4]")
                            }
                                onClick={e => {
                                    e.preventDefault();
                                    setIsActive(5);
                                }}>Series C</a>
                        </div>
                    </div>
                </div>
            </div>
            {showModal ? (<ComparePopUp id={companyProfileId} onClose={(e)=>setShowModal(false)}/>) : null}
        </>
    )

}

export default IncubationCompanyDetailInformation;