import React from 'react'

/**
 * The `SearchTrendItem` function renders a list of search data items with a click event handler.
 * @returns The component is returning a JSX element.
 */
const SearchTrendItem = ({ type, searchData, onViewResultClick }) => {

    return (
        <>
            <div className='grid grid-cols-12 relative z-0'>
                {searchData?.map((data,i) => {
                    return (                        
                            <div key={i} className={`block rounded-[4px] mx-4 my-3 bg-white z-10 shadow-lg p-3 custom-box-shadow col-span-6 h-[2.7rem] flex items-center justify-center ${searchData?.length-1 === i ? 'mb-2':''}`}>
                                <div onClick={() => onViewResultClick(data['SearchLog.search_term'])}
                                    className="flex items-center justify-center">
                                    <p className="text-[#BC8B20] font-sans_bold_body cursor-pointer text-[16px] leading-[19px] block overflow-x-clip overflow-ellipsis whitespace-nowrap max-w-[4.5rem] md:max-w-[15rem] lg:max-w-[5rem] xl:max-w-[6rem] 2xl:max-w-[9rem]">
                                        {data['SearchLog.search_term']}
                                    </p>
                                    <div>
                                    </div>
                                </div>

                            </div>                        
                    )
                })
                }

            </div>
        </>
    )
}

export default SearchTrendItem
