import React, { useContext, useEffect } from 'react'
import { PeFilterContext } from '../../Contexts/PeFilterContext'
import moment from 'moment'
import { useState } from 'react'
import { toast } from 'react-toastify'


/**
 * The function `getYears` calculates the range of years between a start date and an end date.
 * @param startDate - The `startDate` parameter is the starting date of the range. It should be a valid
 * date string or a `moment` object representing the starting date.
 * @param endDate - The `endDate` parameter represents the end date of the range you want to generate.
 * @returns The function `getYears` returns an array of moment objects representing each year between
 * the `startDate` and `endDate` (inclusive).
 */
const getYears = (startDate, endDate) => {
    let fromDate = moment(startDate)
    let toDate = moment(endDate)
    let diff = toDate.diff(fromDate, 'year')
    let range = []
    for (let i = 0; i <= diff; i++) {
        range.push(moment(startDate).add(i, 'year'))
    }
    return range
}

const monthList = moment.monthsShort().map(month => ({ id: month, name: month, isChecked: false }))
const yearsList = getYears(moment().subtract(25, 'years'), moment()).map(date => ({ id: date.year(), name: date.year(), isChecked: false }));

/**
 * The function `isFutureDate` checks if a given date is in the future.
 * @param date - The `date` parameter is a variable that represents a specific date.
 * @returns a boolean value. It returns true if the provided date is in the future (after the current
 * month), and it returns false if the provided date is in the past or within the current month.
 */
const isFutureDate = (date) => {
    return date?.isAfter(moment(),'month') ?? true
}


/**
 * The `DateFoundedFilter` function is a React component that renders a date filter UI for selecting a
 * range of dates.
 * @returns a JSX element, which represents a form for selecting a date range.
 */
function DateFoundedFilter({ setFromDateOption, setToDateOption }) {

    const [FromDate, setFromDate] = useState(moment().subtract(3, 'years'))
    const [ToDate, setToDate] = useState(moment())
    const { monthPickerValue } = useContext(PeFilterContext);

    useEffect(() => {
        console.log('DateFoundedFilter', monthPickerValue);
        setFromDate(moment(monthPickerValue[0]))
        setToDate(moment(monthPickerValue[1]))

        setFromDateOption(moment(monthPickerValue[0]))
        setToDateOption(moment(monthPickerValue[1]))
    }, [monthPickerValue])




 /**
  * The `handleMonth` function updates the selected month for either the "from" or "to" date based on
  * the user's input.
  * @param e - The parameter `e` is an event object that is passed to the `handleMonth` function. It is
  * typically triggered by an event, such as a user selecting a month from a dropdown menu.
  * @returns The function does not have a return statement, so it does not explicitly return anything.
  */
    const handleMonth = (e) => {
        const { name, value } = e.target;

        const selectedMonth = moment().month(value).format('M')

        if (name === "from") {
            const temp = moment(FromDate);
            temp.set('month', parseInt(selectedMonth) - 1)

            if (isFutureDate(temp)) {
                toast.warning('From date cannot be greater than current date')
                return
            }

            if (temp.isAfter(ToDate)) {
                toast.warning('From date cannot be greater than to date')
                return;
            }
            setFromDate(temp)
            setFromDateOption(temp)
        } else {
            const temp = moment(ToDate);
            temp.set('month', parseInt(selectedMonth) - 1)
            if (isFutureDate(temp)) {
                toast.warning('To date cannot be greater than current date')
                return
            }
            if (temp.isBefore(FromDate)) {
                toast.warning('To date cannot be less than from date')
                return;
            }
            setToDate(temp)
            setToDateOption(temp)
        }
    }

 /**
  * The `handleYear` function updates the year value of either the "from" or "to" date based on the
  * user's input, and performs validation checks to ensure the selected dates are within the desired
  * range.
  * @param e - The parameter `e` is an event object that is passed to the `handleYear` function. It is
  * typically triggered by an event, such as a change in the input field value.
  * @returns The function `handleYear` does not have a return statement. Therefore, it does not
  * explicitly return anything.
  */
    const handleYear = (e) => {
        const { name, value } = e.target;

        if (name === "from") {
            const temp = moment(FromDate);
            temp.set('year', parseInt(value))

            if (isFutureDate(temp)) {
                toast.warning('From date cannot be greater than current date')
                return
            }

            if (temp.isAfter(ToDate)) {
                toast.warning('From date cannot be greater than to date')
                return;
            }

            setFromDate(temp)
            setFromDateOption(temp)
        } else {
            const temp = moment(ToDate);
            temp.set('year', parseInt(value))
            if (isFutureDate(temp)) {
                toast.warning('To date cannot be greater than current date')
                return
            }
            if (temp.isBefore(FromDate)) {
                toast.warning('To date cannot be less than from date')
                return;
            }
            setToDate(temp)
            setToDateOption(temp)
        }
    }

    console.log('FromDate', FromDate?.year(), yearsList);


    return (
        <div className="border-b-[3px] border-[#E8E6E1]">
            <li
                className="relative px-3 py-2"
            >
                <p className="text-[14px] tracking-[-.3px]">Dates Between</p>
                <div className='flex mt-2 items-center'>

                    <div className='flex flex-col space-y-2'>
                        <select value={FromDate?.format('MMM')} name="from" id="" onChange={handleMonth}
                            className=" border border-[#D9BD7F] mr-3 rounded focus:outline-none bg-[#FFF9EC] lg:w-[5rem] w-[9.5rem] md:w-[16rem] lg:h-[1.6rem]">
                            {
                                monthList?.map(month => (
                                    <option key={month.id} value={month.id}>{month.name}</option>
                                ))
                            }
                        </select>
                        <select value={FromDate?.year()} name="from" id="" onChange={handleYear}
                            className=" border border-[#D9BD7F] mr-3 rounded focus:outline-none bg-[#FFF9EC] lg:w-[5rem] w-[9.5rem] md:w-[16rem] lg:h-[1.6rem]">
                            {
                                yearsList?.map(month => (
                                    <option key={month.id} value={month.id}>{month.name}</option>
                                ))
                            }
                        </select>
                    </div>

                    <p className="text-sm mr-3">To</p>

                    <div className='flex flex-col space-y-2'>
                        <select value={ToDate?.format('MMM')} name="to" id="" onChange={handleMonth}
                            className=" border border-[#D9BD7F] mr-3 rounded focus:outline-none bg-[#FFF9EC] lg:w-[5rem] w-[9.5rem] md:w-[16rem] lg:h-[1.6rem]">
                            {
                                monthList?.map(month => (
                                    <option key={month.id} value={month.id}>{month.name}</option>
                                ))
                            }
                        </select>
                        <select value={ToDate?.year()} name="" id="to" onChange={handleYear}
                            className=" border border-[#D9BD7F] mr-3 rounded focus:outline-none bg-[#FFF9EC] lg:w-[5rem] w-[9.5rem] md:w-[16rem] lg:h-[1.6rem]">
                            {
                                yearsList?.map(month => (
                                    <option key={month.id} value={month.id}>{month.name}</option>
                                ))
                            }
                        </select>
                    </div>

                </div>
            </li>
        </div>
    )
}

export default DateFoundedFilter