import React, { Component } from "react";
import RichTextEditor from "react-rte";
import {getTextAlignBlockMetadata,getTextAlignClassName,getTextAlignStyles} from "react-rte/lib/lib/blockStyleFunctions";

export default class MyStatefulEditor extends Component {
  value = RichTextEditor.createValueFromString(this.props.markup, "html",{
    customBlockFn: getTextAlignBlockMetadata,
  });
  state = {
    value: this.value
  };

 /* The `onChange` function is a callback function that is called whenever the value of the
 `RichTextEditor` component changes. */
  onChange = (value) => {
    this.setState({ value });
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  };

  render() {
    return <RichTextEditor blockStyleFn={getTextAlignClassName} editorClassName="h-full w-full pb-[3.1rem] overflow-y-auto" value={this.state.value} onChange={this.onChange} readOnly={this.props?.data}/>;
  }
}
