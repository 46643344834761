import { Fragment, useState, useContext, useEffect } from "react";
import OutsideClickHandler from "react-outside-click-handler";
// import { AddCompanyContext } from '../Contexts/AddCompanyContext'
import NotificationComponent from "../Utils/Notification";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import PlusIcon from "../images/plusicon.png";
import { mdiClose } from "@mdi/js";
import {
  createGroupListApi,
  groupListApi,
  addEntityApi,
} from "../Config/config";
import axios from "axios";
import "../../src/index.css";
import { ToastContainer, toast } from "react-toastify";
import { UserContext } from "../Contexts/UserContext";
import useModule from "../hooks/useModule";
// import {ToastContainerComp} from '../Components/ToastContainerComp.jsx'
// import "react-toastify/dist/ReactToastify.css";

/**
 * The `classNames` function takes in an array of classes, filters out any falsy values, and joins the
 * remaining classes into a single string.
 * @returns a string that contains all the non-empty classes joined together with a space in between
 * each class.
 */
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

/**
 * The above function is a React component that renders a dropdown menu for selecting and creating
 * groups.
 */
export default function Example(props) {
  console.log(props.module, "pop");
  console.log(props.cname,"cname12");
  // const { addValues, setAddValues } = useContext(AddCompanyContext);
  const [firstName, setFirstName] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [newList, setnewList] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [groupList, setGroupList] = useState();
  const [createGroupList, setCreateGroupList] = useState();
  const [addGroupEntity, setAddGroupEntity] = useState();
  const [msg, setMsg] = useState("");
  const [success, setSuccess] = useState();
  const [idToscroll, setIdToScroll] = useState();
  const [isOpen,setIsOpen] =useState(false);
  const {getToken} = useContext(UserContext);
 const{primaryMasterId}= useModule();

 /**
  * The function `createdGroupView` scrolls the element with the specified `idToscroll` into view
  * smoothly.
  */
  const createdGroupView = () => {
    document.getElementById(idToscroll).scrollIntoView({ behavior: "smooth" });
  };

  /**
   * The `onchangeName` function updates the value of `firstName` state based on the value of the input
   * field.
   */
  const onchangeName = (event) => {
    setFirstName(event.target.value);
    // event.currentTarget.disabled = false;
  };
 /**
  * The function `companyList` is used to create a list of companies, but it requires a first name to
  * be provided.
  */
  const companyList = (event) => {
    event.preventDefault();
    //  let id = event.target.getAttribute("id")
    if (firstName !== "") {
      createList();
    } else {
      // setMsg("The list name is required");
      toast.warning("The list name is required")
    }
    setShowNotification(true);
    setnewList(false);
    // setAddValues(oldArray => [...oldArray,addValues[id].Details.unshift(firstName)])
  };
  // const inputRef = useRef(null);
  /**
   * The function "clearValue" updates the state variables "newList" and "firstName" to their initial
   * values.
   */
  function clearValue() {
    setnewList(!newList);
    setFirstName("");
  }
 /**
  * The function "afterGroupAdd" sets the state variables "showDropdown" to true, "showNotification" to
  * false, and then calls the "listGroup" function.
  */
  const afterGroupAdd = () => {
    setShowDropdown(true);
    setShowNotification(false);
    listGroup();
  };
  useEffect(() => {
    listGroup();
    document.addEventListener("mousedown",()=>{

      setIsOpen(false)
  })
  }, []);



 

  /**
   * The `listGroup` function makes a POST request to a specified API endpoint using axios, passing in
   * some data and headers, and then handles the response by setting the group list state and
   * performing some additional actions.
   */
  const listGroup = () => {
    // setShowDropdown(!showDropdown);
    axios(`${groupListApi}`, {
      method: "POST",
      data: {
        type_id: props.type_id,
        // user_id: 1,
        type_name: props.module,
        primary_type_master_id:primaryMasterId,
        
      },
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken()
      },
    })
      .then((response) => {
        console.log(response, "list group api response");
        if (response?.data?.success == true) {
          setGroupList(response?.data);
          console.log(response.data.group[0].id, "total response count");
          setIdToScroll(response.data.group[0].id + 1);
          console.log(idToscroll, "idsss");
          createdGroupView();
        }
      })
      .catch((error) => {
        console.log("API not working", error);
      });
  };
 /**
  * The function `createList` makes a POST request to a specified API endpoint with certain data and
  * headers, and handles the response accordingly.
  */
  const createList = () => {
    axios(`${createGroupListApi}`, {
      method: "POST",
      data: {
        group_name: firstName,
        type_id: props.type_id,
        // user_id: 1,
        type_name: props.module,
        primary_type_master_id:primaryMasterId,
        investor_id:props.investor_id,

      },
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken()
      },
    })
      .then((response) => {
        //  console.log(response,'createdlist response');
        if (response?.data?.success == true) {
          setCreateGroupList(response?.data?.group);
          listGroup();
          // setCreateGroupList(oldArray => [...oldArray,groupList[id].group_name.unshift(firstName)])
          // console.log(createGroupList,'createdlist');
          if (response?.data?.message) {
            // setMsg(response?.data?.message)
            toast.success(response?.data?.message);
          } else {
            toast.success("Group Name Added Successfully");
          }
        } else {
          // setMsg(response?.data?.message)
          toast.warning(response?.data?.message);
        }
      })
      .catch((error) => {
        console.log("API not working", error);
      });
  };

 /**
  * The function `addEntity` makes a POST request to an API endpoint with specific data and headers,
  * and handles the response accordingly.
  */
  const addEntity = (groupid) => {
    //  console.log('groupid:',groupid,"type_id :",props.type_id,"entity_id :",props.cid,"type_name :",props.module);
    axios(`${addEntityApi}`, {
      method: "POST",
      data: {
        // user_id: 1,
        group_id: groupid,
        type_id: props.type_id,
        entity_id: props.cid,
        primary_type_master_id:primaryMasterId,
        investor_id:props.investor_id,
        sub_type_master_id:props?.sub_type_master_id,
        deal_id:props?.deal_id

        // type_name: props.module,
      },
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken()
      },
    })
      .then((response) => {
        console.log(response, "Add Entity group api response");
        if (response?.data?.message) {
          // setMsg(response?.data?.message)

          setShowNotification(true);
          setSuccess(response?.data?.success);
          toast.success(response?.data?.message);
        } else {
          setAddGroupEntity(response?.data);
          // setMsg(response?.data?.success)
          setShowNotification(true);
          toast.warning(response?.data?.success);
        }
      })
      .catch((error) => {
        console.log("API not working", error);
      });
  };

  // console.log(addGroupEntity,'Add group entity');
  const pages =
    props.page == "PE-VC Investments"
      ? "absolute right-[3rem] mt-2 lg:mt-0"
      : props.page == "Exists"
      ? "absolute right-[3rem] mt-2 lg:mt-0"
      : props.page == "BackedIpos"
      ? "absolute right-[3rem]"
      : props.page == "Angel"
      ? "absolute right-[3rem]"
      : props.page == "Incubation"
      ? "absolute right-[3rem]"
      : props.page == "Investor"
      ? "absolute right-[3.5rem]"
      : props.page == "InvExists"
      ? "absolute right-[3.5rem]"
      : props.page == "InvBackedIpos"
      ? "absolute right-[3.5rem]"
      : props.page == "InvAngel"
      ? "absolute right-[3.5rem]"
      : props.page == "InvIncubation"
      ? "absolute right-[3.5rem]"
      : props.page == "funds"
      ? "absolute right-[3rem]"
      : props.page == "fundsdetails"
      ? "absolute right-[8rem] mt-3"
      : props.page == "limitedpartner"
      ? "absolute md:right-[3.75rem] right-[3rem] mt-2 lg:mt-0"
      : props.page == "limitedpartnerdetail"
      ? "absolute right-[9rem] mt-2"
      : props.page == "cfs"
      ? "absolute right-[3rem] mt-2"
      :props.page == "detailpage"
       ? 'absolute right-[5rem] mt-2 md:right-[9rem] lg:mt-2'
      :props.page == "Advisor" 
      ? 'absolute right-[3rem] mt-2'
      :props.page == "TransactionAdvisor" 
      ? 'absolute right-[3rem] lg:mt-2 mt-1'
      :props.page == "detailviewLp"
      ?'absolute right-[5rem] mt-2 md:right-[9rem] lg:mt-2'
      : "";
     

  return (
    <>
      {/* {showNotification ? <ToastContainer
                className="toast-message"
                position="top-right"
               
                
       style={{width:"35%"}} /> : ''} */}
      {/* <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      /> */}
      {/* <ToastContainerComp /> */}

      {/* {showNotification ? <NotificationComponent message={msg}  success={success}/> : ''} */}

      <Menu onClick={()=>setIsOpen ((isOpen) => !isOpen)}
        as="div"
        className={
          "rounded-[4px] shadow-lg shadow-[0_2px_8px_0_rgba(0,0,0,0.2)] z-10 " +
          (pages ? pages : "")
        }
        style={
          pages
            ? { minWidth: "270px", maxWidth: "280px", maxHeight: "240px" }
            : {
                position: "absolute",
                top: "219px",
                right: "129px",
                minWidth: "270px",
                maxWidth: "280px",
                maxHeight: "240px",
              }
        }
      >
        <div className="w-full">
          <div className="sticky top-0">
            <div className=" border-b px-3 text-left grid grid-cols-1  justify-between  flex bg-white py-2 text-[14px] tracking-[-0.3px] leading-[17px]">
              {props.cname}
            </div>
            <div className=" justify-between">
              <p
                onClick={() => listGroup()}
                className={classNames(
                  "   justify-between  shadow-lg shadow-gray-300  flex bg-white  pr-4 pl-3 py-2 text-[14px] tracking-[-0.3px] leading-[17px] ",
                  showDropdown
                    ? "text-opacity-[.5] text-[#333333]"
                    : "text-opacity-[.5] text-[#333333]"
                )}
              >
                Select saved list
                {/* <div> <ChevronDownIcon className={classNames(" cursor-pointer justify-self-end col-span-2 h-5 w-5 ", showDropdown ? "rotate-180 " : "rotate-0")} aria-hidden="true" />
              </div> */}
              </p>
            </div>
            <div
              className={classNames(
                " tracking-[-0.3px] leading-[17px] bg-white text-[14px]",
                "block p-2  flex "
              )}
            >
              {newList ? (
                <div className="cursor-pointer flex items-center gap-1">
                  <form onSubmit={companyList} className="flex w-[100%] gap-1">
                    <input
                      type="text"
                      name="text"
                      id="text"
                      autocomplete="off"
                      value={firstName}
                      onChange={onchangeName}
                      className="focus:border-transparent block w-[100%] sm:text-sm border border-[#BC8B20] p-1 pl-3 rounded-md"
                      placeholder="Group Name"
                    />
                    <button type="submit" onClick={() => afterGroupAdd()}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-[22px] cursor-pointer"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </button>
                  </form>
                  <svg
                    onClick={() => setnewList(!newList)}
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 cursor-pointer"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
              ) : (
                <div
                  onClick={clearValue}
                  className="flex cursor-pointer text-[#BC8B20] text-[14px] leading-[17px] tracking-[-0.3px] items-center"
                >
                  <img className=" cursor-pointer w-[22px] h-[13px] px-1" src={PlusIcon}></img>
                  <span className="cursor-pointer">Create New list</span>
                </div>
              )}
            </div>
          </div>
          <div
            className={classNames(
              " tracking-[-0.3px] leading-[17px] text-[14px] shadow-lg shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] bg-white overflow-y-auto no-scrollbar",
              "block pr-0 bg-white"
            )}
            style={{ maxHeight: "150px" }}
          >
            {groupList?.group?.length > 0 ? (
              groupList?.group?.map((dropDownList, i) => {
                // console.log(groupList,'dropdown value');
                return (
                  <div className="hover:bg-gray-100">
                    <button
                      type="submit"
                      className="text-[14px] text-[#333333] leading-[17px] tracking-[-0.3px] flex block px-4 py-2 text-[14px] focus:outline-none "
                      onClick={() => addEntity(dropDownList.id)}
                      id={dropDownList?.id}
                    >
                      {dropDownList?.group_name}
                    </button>
                  </div>
                );
              })
            ) : (
              <div className="w-full bg-[#FFFFFF]">
                <center>
                  <div role="status" className=" h-[8rem]">
                   {groupList?.group|| <svg
                      className="relative top-[1rem] inline mr-2 w-12 h-12 text-gray-200 animate-spin dark:text-gray-600 fill-[#CA8A24]"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>}
                  </div>
                </center>
              </div>
            )}
          </div>
        </div>
      </Menu>
    </>
  );
}
