import React from 'react'
import { useMatch } from 'react-router-dom';
import routePathSegments, { routeNames } from '../../routeSegments'
import NavbarItems from './NavbarItems'
import NavbarWrapper from './NavbarWrapper'

/**
 * The `Header` component renders a navigation bar with different links based
 * on the current route.
 * @returns a JSX element, specifically a NavbarWrapper component with several NavbarItems components
 * inside.
 */
function Header() {
    const isCompanyMatch = useMatch({ path: `/${routePathSegments.pe}/${routePathSegments.company}/*` })
    const isInvestorMatch = useMatch({ path: `/${routePathSegments.investor}/*` })
    const isAdvisorMatch = useMatch({ path: `/${routePathSegments.advisor}/*` })
    const isTransactionAdvisorMatch = useMatch({ path: `/${routePathSegments.transaction_advisor}/*` })
    const maMatch = useMatch({ path: `${routePathSegments.ma}/*` })
    const acquirerMatch = useMatch({ path: `${routePathSegments.acquirers}/*` })
    const maCompanyMatch = useMatch(`/${routePathSegments.ma_company_page}/*`)
    const maDealMatch = useMatch(`/${routePathSegments.ma_dealpage}/*`)
    const maAcquirerMatch = useMatch({ path: `${routePathSegments.ma_acquirers}/*` })
    const maAdvisorMatch = useMatch({ path: `${routePathSegments.ma_advisors}/*` })
    const maTransactionrMatch = useMatch({ path: `${routePathSegments.ma_transaction}/*` })
    const reCompanyMatch = useMatch({ path: `${routePathSegments.re_company_page}/*` })
    const reDealMatch = useMatch({ path: `${routePathSegments.re_deal_page}/*` })
    const reAdvisorMatch = useMatch({ path: `${routePathSegments.readvisor}/*` })
    const isQueryBuilderMatch = useMatch({ path: `/${routePathSegments.query_builder}/*` })




    return (
        <NavbarWrapper>
            <NavbarItems
                title="Companies"
                to={routeNames.PE_company}
                isLinkActive={isCompanyMatch !== null || maCompanyMatch != null || maDealMatch !== null || reCompanyMatch !== null || reDealMatch !== null}
            />
            {
                maMatch !== null || acquirerMatch != null || maAcquirerMatch !== null || maAdvisorMatch !== null || maTransactionrMatch !== null
                    ? < NavbarItems
                        title="Acquirers"
                        to={routeNames.MA_acquirers}
                        isLinkActive={acquirerMatch != null || maAcquirerMatch !== null} />
                    : <NavbarItems
                        title="Investors"
                        to={routeNames.PE_investor}
                        isLinkActive={isInvestorMatch !== null}
                    />
            }
            <NavbarItems
                title="Legal Advisors"
                to={routeNames.PE_advisor}
                isLinkActive={isAdvisorMatch !== null || maAdvisorMatch !== null || reAdvisorMatch != null}
            />
            <NavbarItems
                title="Transaction Advisors"
                to={routeNames.PE_transaction_advisor}
                isLinkActive={isTransactionAdvisorMatch !== null || maTransactionrMatch != null}
            />
            {/* <NavbarItems
                title="Query Builder"
                to={routePathSegments.query_builder}
                isLinkActive={isQueryBuilderMatch != null}
            /> */}
        </NavbarWrapper>
    )
}

export default Header