import React, { useEffect, useMemo } from 'react'
import moment from 'moment'
import { useState } from 'react'
import { toast } from 'react-toastify'
import Constants from '../../Constants'
import momentFquarter from 'moment-fquarter'


const getYears = (startDate, endDate) => {
    let fromDate = moment(startDate)
    let toDate = moment(endDate)
    let diff = toDate.diff(fromDate, 'year')
    let range = []
    for (let i = 0; i <= diff; i++) {
        range.push(moment(startDate).add(i, 'year'))
    }
    return range
}

const getMonths = (date) => {
    let fromDate = moment(date).startOf("year")
    let toDate = moment(date).endOf("year")
    let diff = toDate.diff(fromDate, 'month')
    let range = []
    for (let i = 0; i <= diff; i++) {
        range.push(moment(date).add(i, 'month'))
    }
    return range
}

const monthList = moment.monthsShort().map(month => ({ id: month, name: month, isChecked: false }))
const yearsList = getYears(moment().subtract(25, 'years'), moment()).map(date => ({ id: date.year(), name: date.year(), isChecked: false }));


const halfYearList = [
    {
        value: "1",
        label: "H1(Jan-Jun)",
    },
    {
        value: "7",
        label: "H2(Jul-Dec)",
    }
]

const halfYearListFy = [
    {
        value: "1",
        label: "H1(Apr-Sep)",
    },
    {
        value: "2",
        label: "H2(Act-Mar)",
    }
]

const quarterYearList = [
    {
        value: "1",
        label: "Q1(Jan-Mar)",
    },
    {
        value: "2",
        label: "Q2(Apr-Jun)",
    },
    {
        value: "3",
        label: "Q3(Jul-Sep)",
    },
    {
        value: "4",
        label: "Q4(Act-Dec)",
    },
]

const quarterYearListFy = [
    {
        value: "2",
        label: "Q1(Apr-Jun)",
    },
    {
        value: "3",
        label: "Q2(Jul-Sep)",
    },
    {
        value: "4",
        label: "Q3(Act-Dec)",
    },
    {
        value: "1",
        label: "Q4(Jan-Mar)",
    },
]






/** It is used to select a range of
months and years. */
function MonthRangePicker({ value = Constants.initialMonthRange, onChange, isReports }) {


    const [FromDate, setFromDate] = useState(moment())
    const [ToDate, setToDate] = useState(moment())

    useEffect(() => {
        setFromDate(moment(value.at(0)))
        setToDate(moment(value.at(1)))
    }, [value])

   /**
    * The `handleMonth` function is used to handle the selection of a month in a date range and update
    * the `FromDate` and `ToDate` values accordingly.
    */
    const handleMonth = (e) => {
        const { name, value } = e.target;

        const selectedMonth = moment().month(value).format('M')

        if (name === "from") {
            const temp = moment(FromDate);
            temp.set('month', parseInt(selectedMonth) - 1)

            // if (isFutureDate(temp)) {
            //     toast.warning('From date cannot be greater than current date')
            //     return
            // }

            if (temp.isAfter(ToDate)) {
                toast.warning('From date cannot be greater than to date')
                return;
            }

            console.log("year-diff", ToDate.diff(temp, "years"));

            if (ToDate.diff(temp, "years") <= 4) {
                setFromDate(temp)
                onChange([temp.format('YYYY-MM-DD'), ToDate.format('YYYY-MM-DD')])
            } else {
                const toTemp = moment(temp).add(5, "year").subtract(1, "month").endOf('month')
                setFromDate(temp.startOf('month'))
                setToDate(toTemp)
                onChange([temp.startOf('month').format('YYYY-MM-DD'), toTemp.format('YYYY-MM-DD')])
            }

        } else {
            const temp = moment(ToDate);
            temp.set('month', parseInt(selectedMonth) - 1)
            if (temp.isAfter(moment().endOf('month'))) {
                toast.warning('To date cannot be greater than current date')
                return
            }
            if (temp.isBefore(FromDate)) {
                toast.warning('To date cannot be less than from date')
                return;
            }
            if (temp.diff(FromDate, "years") <= 4) {
                setToDate(temp)
                onChange([FromDate.format('YYYY-MM-DD'), temp.format('YYYY-MM-DD')])
            } else {
                const fromTemp = moment(temp).subtract(5, "year").add(1, "month").startOf('month')
                setFromDate(fromTemp)
                setToDate(temp)
                onChange([fromTemp.format('YYYY-MM-DD'), temp.format('YYYY-MM-DD')])
                // toast.warning(Constants.maximumFiveTermsMsg)
            }
        }
    }

    /**
     * The `handleYear` function is used to handle changes in the year value of a date input field and
     * update the corresponding date values accordingly, with some additional validation checks.
     */
    const handleYear = (e) => {
        const { name, value } = e.target;

        if (name === "from") {
            const temp = moment(FromDate);
            temp.set('year', parseInt(value))

            if (temp.isAfter(ToDate)) {
                toast.warning('From date cannot be greater than to date')
                return;
            }

            if (ToDate.diff(temp, "years") <= 4) {
                setFromDate(temp)
                onChange([temp.format('YYYY-MM-DD'), ToDate.format('YYYY-MM-DD')])
            } else {
                const toTemp = moment(temp).add(5, "year").subtract(1, "month").endOf('month')
                setFromDate(temp.startOf('month'))
                setToDate(toTemp)
                onChange([temp.startOf('month').format('YYYY-MM-DD'), toTemp.format('YYYY-MM-DD')])
            }

        } else {
            const temp = moment(ToDate);
            temp.set('year', parseInt(value))
            if (temp.isAfter(moment().endOf('month'))) {
                toast.warning('To date cannot be greater than current date')
                return
            }
            if (temp.isBefore(FromDate)) {
                toast.warning('To date cannot be less than from date')
                return;
            }

            if (temp.diff(FromDate, "years") <= 4) {
                setToDate(temp)
                onChange([FromDate.format('YYYY-MM-DD'), temp.format('YYYY-MM-DD')])
            } else {
                const fromTemp = moment(temp).subtract(5, "year").add(1, "month").startOf('month')
                setFromDate(fromTemp)
                setToDate(temp)
                onChange([fromTemp.format('YYYY-MM-DD'), temp.format('YYYY-MM-DD')])
                // toast.warning(Constants.maximumFiveTermsMsg)
            }

        }
    }




    return (
        <div className='flex items-center'>
            <div className='flex flex-grow items-center py-2 pl-2 pr-1 justify-evenly'>
                <select value={FromDate?.format('MMM')} name="from" id="" onChange={handleMonth}
                    className={isReports ? "border border-black mr-1 p-[5px] rounded bg-white focus:outline-none w-[60px] text-[12px]" : " border border-[#D9BD7F] mr-1 rounded focus:outline-none bg-[#FFF9EC] w-[50px] text-[12px]"}>
                    {
                        monthList?.map(month => (
                            <option key={month.id} value={month.id}>{month.name}</option>
                        ))
                    }
                </select>
                <select value={FromDate?.year()} name="from" id="" onChange={handleYear}
                    className={isReports ? "border border-black p-[5px] rounded bg-white focus:outline-none w-[60px] text-[12px]" : "border border-[#D9BD7F] rounded focus:outline-none bg-[#FFF9EC] w-[55px] text-[12px]"}>
                    {
                        yearsList?.slice(0).reverse().map(month => (
                            <option key={month.id} value={month.id}>{month.name}</option>
                        ))
                    }
                </select>
                <p className="text-[12px] mx-2">To</p>
                <select value={ToDate?.format('MMM')} name="to" id="" onChange={handleMonth}
                    className={isReports ? "border border-black mr-1 p-[5px] rounded bg-white focus:outline-none w-[60px] text-[12px]" : "border border-[#D9BD7F] mr-1 rounded focus:outline-none bg-[#FFF9EC] w-[50px] text-[12px]"}>
                    {
                        monthList?.map(month => (
                            <option key={month.id} value={month.id}>{month.name}</option>
                        ))
                    }
                </select>
                <select value={ToDate?.year()} name="to" id="" onChange={handleYear}
                    className={isReports ? "border border-black p-[5px] rounded bg-white focus:outline-none w-[60px] text-[12px]" : " border border-[#D9BD7F] rounded focus:outline-none bg-[#FFF9EC] w-[55px] text-[12px]"}>
                    {
                        yearsList?.slice(0).reverse().map(month => (
                            <option key={month.id} value={month.id}>{month.name}</option>
                        ))
                    }
                </select>
            </div>
        </div>

    )
}


/** This is a component used to select a range of
periods (months, quarters, or years) within a specified period type (FY or CY). */
export function PeriodRangePicker({ value = Constants.initialMonthRange, onChange, period, periodType }) {


    const [FromDate, setFromDate] = useState(moment(value.at(0)))
    const [ToDate, setToDate] = useState(moment(value.at(1)))

   /*  It is an
   array of objects that represent different periods of time. The periods are determined based on
   the `periodType` and `period` variables. */
    const periosData = useMemo(() => getYears(moment().subtract(25, 'years'), moment())
        .filter(date => !((periodType === "FY" && period !== "monthly") && date.isSame(moment(), "year") && moment().quarter() === 1))
        .map(date => {
            let financialStartDate;
            let financialEndDate;
            financialStartDate = date.clone().startOf("year").quarter(2).startOf("quarter");
            financialEndDate = date.clone().startOf("year").quarter(2).startOf("quarter").add(11, "month").endOf("month");


            return {
                value: periodType === "FY" && period !== "monthly" ? financialEndDate.year() : date.year(),
                label: periodType === "FY" && period !== "monthly" ? `FY ${financialEndDate.format("YY")}` : date.year(),
                CY_monthly: getMonths(date.startOf("year")).map(d => ({
                    value: d.endOf("month").format("YYYY-MM-DD"),
                    label: d.format("MMM"),
                })),
                FY_monthly: getMonths(date.startOf("year")).map(d => ({
                    value: d.endOf("month").format("YYYY-MM-DD"),
                    label: d.format("MMM"),
                })),
                CY_halfYearly: [
                    {
                        value: date.quarter(2).endOf("quarter").format("YYYY-MM-DD"),
                        label: "H1(Jan-Jun)",
                    },
                    {
                        value: date.endOf("year").format("YYYY-MM-DD"),
                        label: "H2(Jul-Dec)",
                    }
                ],
                FY_halfYearly: [
                    {
                        value: financialStartDate.add("1", "quarter").endOf("quarter").format("YYYY-MM-DD"),
                        label: "H1(Apr-Sep)",
                    },
                    {
                        value: financialEndDate.format("YYYY-MM-DD"),
                        label: "H2(Oct-Mar)",
                    }
                ],
                CY_quarterly: [
                    {
                        value: date.quarter(1).endOf("quarter").format("YYYY-MM-DD"),
                        label: "Q1(Jan-Mar)",
                    },
                    {
                        value: date.quarter(2).endOf("quarter").format("YYYY-MM-DD"),
                        label: "Q2(Apr-Jun)",
                    },
                    {
                        value: date.quarter(3).endOf("quarter").format("YYYY-MM-DD"),
                        label: "Q3(Jul-Sep)",
                    },
                    {
                        value: date.quarter(4).endOf("quarter").format("YYYY-MM-DD"),
                        label: "Q4(Oct-Dec)",
                    },
                ],
                FY_quarterly: [
                    {
                        value: financialStartDate.quarter(2).endOf("quarter").format("YYYY-MM-DD"),
                        label: "Q1(Apr-Jun)",
                    },
                    {
                        value: financialStartDate.quarter(3).endOf("quarter").format("YYYY-MM-DD"),
                        label: "Q2(Jul-Sep)",
                    },
                    {
                        value: financialStartDate.quarter(4).endOf("quarter").format("YYYY-MM-DD"),
                        label: "Q3(Oct-Dec)",
                    },
                    {
                        value: financialEndDate.quarter(1).endOf("quarter").format("YYYY-MM-DD"),
                        label: "Q4(Jan-Mar)",
                    },
                ]
            }
        }), [periodType,period])

    console.log("data----987", periosData);


    // const handleMonth = (e) => {
    //     const { value } = e.target;

    //     const temp = moment(FromDate).startOf("month");
    //     temp.set('month',value)


    //     console.log("temp-0987",temp.format('YYYY-MM-DD'));



    //     if (temp.isAfter(moment().endOf("month"))) {
    //         toast.warning('From date cannot be greater than current date')
    //         return;
    //     }

    //     if (temp.isAfter(ToDate)) {
    //         if (period === "halfYearly") {
    //             let toTemp = temp.clone().add(30, "months").endOf("month")
    //             if (toTemp.isAfter(moment().endOf("month"))) {
    //                 toTemp = moment().endOf("month")
    //             }
    //             setFromDate(temp)
    //             setToDate(toTemp)
    //         }
    //         return;
    //     }



    //     console.log("year-diff", ToDate.diff(temp, "years"));

    //     if (ToDate.diff(temp, "years") <= 4) {
    //         setFromDate(temp)
    //         onChange([temp.format('YYYY-MM-DD'), ToDate.format('YYYY-MM-DD')])
    //     } else {
    //         const toTemp = moment(temp).add(5, "year").subtract(1, "month").endOf('month')
    //         setFromDate(temp.startOf('month'))
    //         setToDate(toTemp)
    //         onChange([temp.startOf('month').format('YYYY-MM-DD'), toTemp.format('YYYY-MM-DD')])
    //     }


    // }

    const handleChange = (e) => {
        const { value, name } = e.target;

        let temp = moment()

        if (name === "month") {
            temp = moment(value)
        } else {
            if (periodType === "FY") {
                temp = FromDate.set('year', parseInt(value)).startOf("year").endOf("quarter")
            } else {
                temp = FromDate.set('year', parseInt(value)).endOf("month")
            }
        }

      

        const minDate = period === "halfYearly"
            ? periodType === "FY"
                ? moment().quarter() > 3
                    ? moment().endOf("year").add(1, "quarter").endOf("quarter")
                    : moment().quarter(3).endOf("quarter")
                : moment().quarter() > 2
                    ? moment().endOf("year")
                    : moment().quarter(2).endOf("quarter")
            : period === "quarterly"
                ? moment().endOf("quarters")
                : moment().endOf("month")
                console.log("minDate",minDate.format("YYYY-MM-DD"),temp.format("YYYY-MM-DD"));
        if (temp.isAfter(minDate)) {
            toast.warning('From date cannot be greater than current date')
            return;
        }

        if (temp.isSame(ToDate, "date")) {
            setFromDate(temp)
            onChange([temp.format("YYYY-MM-DD"), ToDate.format("YYYY-MM-DD")])
        } else if (temp.isAfter(ToDate)) {

            let toTemp = period === "quarterly"
                ? temp.clone().add(4, "quarter").endOf("quarter")
                : period === "monthly"
                    ? temp.clone().add(4, "months").endOf("month")
                    : temp.clone().add(24, "months").endOf("quarter")

            if (toTemp.isAfter(period === "quarterly" ? moment().endOf("quarter") : period === "monthly" ? moment().endOf("month") : moment().quarter(2).endOf("quarter"))) {

                toTemp = period === "quarterly"
                    ? moment().quarter(moment().quarter()).endOf("quarter")
                    : period === "monthly"
                        ? temp.endOf("month")
                        : moment().quarter(2).endOf("quarter")
            }
            setFromDate(temp)
            setToDate(toTemp)
            onChange([temp.format("YYYY-MM-DD"), toTemp.format("YYYY-MM-DD")])

        } else {

            let toTemp = period === "quarterly"
                ? temp.clone().add(4, "quarter").endOf("quarter")
                : period === "monthly"
                    ? temp.clone().add(4, "months").endOf("month")
                    : temp.clone().add(24, "months").endOf("quarter")

            if (toTemp.isAfter(ToDate)) {
                setFromDate(temp)
                onChange([temp.format("YYYY-MM-DD"), ToDate.format("YYYY-MM-DD")])
            } else {
                setFromDate(temp)
                setToDate(toTemp)

                onChange([temp.format("YYYY-MM-DD"), toTemp.format("YYYY-MM-DD")])
            }

        }
    }

    const handleToChange = (e)=>{
        const { value, name } = e.target;
console.log("value, name ",value, name ,toOption[`${periodType}_${period}`]);
        let temp = moment()

        if (name === "month") {
            temp = moment(value,"YYYY-MM-DD")
        } else {
            if (periodType === "FY") {
                temp = ToDate.set('year', parseInt(value)).startOf("year").endOf("quarter")
            } else {
                temp = ToDate.set('year', parseInt(value)).endOf("month")
            }
        }
       

        
        if(temp.isBefore(FromDate)){
            toast.warning('To date cannot be smaller than From date')
            return;
        }


        const maxDate = period === "halfYearly"
        ? periodType === "FY"
            ? FromDate.clone().add(24, "months").endOf("quarter")
            : FromDate.clone().add(24, "months").endOf("quarter")
        : period === "quarterly"
            ? FromDate.clone().add(4,"quarters").endOf("quarter")
            : FromDate.clone().add(4,"months")

            console.log("handleToChange",temp.format("YYYY-MM-DD"),maxDate.format("YYYY-MM-DD"),toOption[`${periodType}_${period}`]);
        if(temp.isAfter(maxDate)){

            if(period === "halfYearly"){
                toast.warning("Please note that you can select a maximum of 5 semi-annual periods at a time")
            }else if(period === "quarterly" ){
                toast.warning("Please note that you can select a maximum of 5 quarters at a time")
            }else{
                toast.warning("Please note that you can select a maximum of 5 months at a time")
            }
            return;
        }


        setToDate(temp)

        onChange([FromDate.format("YYYY-MM-DD"), temp.format("YYYY-MM-DD")])


    }


    console.log(`Start===>${FromDate.format('YYYY-MM-DD')}-----End===>${ToDate.format('YYYY-MM-DD')}====${FromDate.month()}====${ToDate.month()}`);

    const fromOption = useMemo(() => {
        if (periodType === "FY" && (period === "quarterly"||period === "halfYearly")) {
            return periosData.find(data => data.value === FromDate?.fquarter()?.nextYear)
        }
        return periosData.find(data => data.value === FromDate?.year())
    }, [FromDate, period, periodType, periosData]);

    const toOption = useMemo(() => {
        if (periodType === "FY" && (period === "quarterly"||period === "halfYearly")) {
            return periosData.find(data => data.value === ToDate?.fquarter()?.nextYear)
        }
        return periosData.find(data => data.value === ToDate?.year())
    }, [ToDate, period, periodType, periosData]);
console.log({toOption, fromOption,periosData,ff:getYears(moment().subtract(25, 'years'), moment())});
    return (
        <div className='flex items-center'>
            <div className='flex flex-grow items-center py-2 pl-2 pr-1 justify-evenly'>
                <select value={FromDate.format("YYYY-MM-DD")} name="month" id="" onChange={handleChange}
                    className={"border border-black mr-1 p-[5px] rounded bg-white focus:outline-none w-[100px] text-[12px]"}>
                    {
                        fromOption[`${periodType}_${period}`]?.map(hf => (
                            <option key={hf.value} value={hf.value}>{hf.label}</option>
                        ))
                    }
                </select>
                <select value={periodType === "FY" && period !== "monthly" ? FromDate?.fquarter()?.nextYear : FromDate?.year()} name="year" id="" onChange={handleChange}
                    className={"border border-black p-[5px] rounded bg-white focus:outline-none w-[80px] text-[12px]"}>
                    {
                        periosData?.slice(0).reverse().map(month => (
                            <option key={month.value} value={month.value}>{month.label}</option>
                        ))
                    }
                </select>
                <p className="text-[12px] mx-2">To</p>
                <select onChange={handleToChange} value={ToDate.format("YYYY-MM-DD")} name="month" id=""
                    className={"border border-black mr-1 p-[5px] rounded bg-white focus:outline-none w-[100px] text-[12px] "}>
                    {
                        toOption[`${periodType}_${period}`]?.slice(0).reverse().map(hf => (
                            <option key={hf.value} value={hf.value}>{hf.label}</option>
                        ))
                    }
                </select>
                <select onChange={handleToChange} value={periodType === "FY" && period !== "monthly" ? ToDate?.fquarter()?.nextYear : ToDate?.year()} name="year" id=""
                    className={"border border-black p-[5px] rounded bg-white focus:outline-none w-[80px] text-[12px] "}>
                    {
                        periosData?.slice(0).reverse().map(month => (
                            <option key={month.value} value={month.value}>{month.label}</option>
                        ))
                    }
                </select>
            </div>
        </div>

    )
}

/** This is a period range picker
component that allows the user to select a range of periods within a financial year. */
export function FyPeriodRangePicker({ value = Constants.initialMonthRange, onChange, period, periodType }) {

    const [FromPeriod, setFromPeriod] = useState({
        period: 0,
        year: "",
    })
    const [ToPeriod, setToPeriod] = useState({
        period: 0,
        year: "",
    })

    useEffect(() => {
        const financialEnddate = moment().quarter() === 1
            ? moment().startOf("year").endOf("quarter")
            : moment().startOf("year").quarter(2).startOf("quarter").add(11, "month").endOf("month")

        const fyMid = financialEnddate.subtract("3", "quarter").endOf("quarter")
        const halfNo = moment().endOf("month").isAfter(fyMid) ? 2 : 1

        if (period === "halfYearly") {
            setToPeriod({
                period: halfNo,
                year: financialEnddate.year(),
            })
            setToPeriod({
                period: halfNo,
                year: financialEnddate.year(),
            })
        }
    }, [period])


    const onPeriodChange = (e) => {
        const { value, name } = e.target;

        if (name === "month") {
            setFromPeriod(prev => ({ ...prev, period: value }))
        } else {
            setFromPeriod(prev => ({ ...prev, year: value }))
        }
    }



    return (
        <div className='flex items-center'>
            <div className='flex flex-grow items-center py-2 pl-2 pr-1 justify-evenly'>
                <select value={FromPeriod.period} name="month" id="" onChange={onPeriodChange}
                    className={"border border-black mr-1 p-[5px] rounded bg-white focus:outline-none w-[100px] text-[12px]"}>
                    {
                        halfYearListFy?.slice(0).reverse().map(hf => (
                            <option key={hf.value} value={hf.value}>{hf.label}</option>
                        ))
                    }
                </select>
                <select value={FromPeriod.year} name="year" id="" onChange={onPeriodChange}
                    className={"border border-black p-[5px] rounded bg-white focus:outline-none w-[80px] text-[12px]"}>
                    {
                        yearsList?.slice(0).reverse().map(month => (
                            <option key={month.id} value={month.id}>{month.name}</option>
                        ))
                    }
                </select>
                <p className="text-[12px] mx-2">To</p>
                <select disabled value={ToPeriod.period} name="to" id=""
                    className={"border border-black mr-1 p-[5px] rounded bg-white focus:outline-none w-[100px] text-[12px] cursor-not-allowed"}>
                    {
                        halfYearListFy?.slice(0).reverse().map(hf => (
                            <option key={hf.value} value={hf.value}>{hf.label}</option>
                        ))
                    }
                </select>
                <select disabled value={ToPeriod.year} name="to" id=""
                    className={"border border-black p-[5px] rounded bg-white focus:outline-none w-[80px] text-[12px] cursor-not-allowed"}>
                    {
                        yearsList?.slice(0).reverse().map(month => (
                            <option key={month.id} value={month.id}>{month.name}</option>
                        ))
                    }
                </select>
            </div>
        </div>

    )
}





export default MonthRangePicker