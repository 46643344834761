import React from 'react'

/* The code defines a functional component called `TopSection` in JavaScript React. */
const TopSection = ({module}) => {
  return (
    <div>
      <div className="">
        <div className='sm:full mt-[4.25rem] md:mt-[3rem] h-full  bg-[#F2F2F2]'>
          <div className={"overflow-x-auto lg:grid grid-cols-5 gap-2  px-4 flex xl:overflow-hidden "+(module? 'pt-0' : 'pt-[0.7rem]')}></div>
          <div className='border-b-2 border-[#e5e5e5]'></div>
        </div>
      </div>
    </div>
  )
}

export default TopSection