import React, { useContext } from 'react'
import { useEffect } from 'react';
import { PeFilterContext } from '../../Contexts/PeFilterContext'
import { toast } from 'react-toastify';

function DealRangeFilter({ MinFundOptions, setMinFundOptions, MaxFundOptions, setMaxFundOptions, customPathname }) {

    const { MinFundSize, MaxFundSize, refineSearchFilters } = useContext(PeFilterContext);

    /* The `useEffect` hook in this code is used to update the `MinFundOptions` and `MaxFundOptions`
    based on the selected filters. */
    useEffect(() => {
        const refineOptions = refineSearchFilters[customPathname]?.fundSize?.at(0)
        const maxRefineOptions = refineSearchFilters[customPathname]?.fundSize?.at(1)
        setMinFundOptions(MinFundSize?.map(option => ({ ...option, isChecked: refineOptions?.name == option.name })))
        setMaxFundOptions(MaxFundSize?.map(option => ({ ...option, isChecked: maxRefineOptions?.name == option.name })))
    }, [refineSearchFilters, customPathname, setMinFundOptions, MinFundSize, setMaxFundOptions, MaxFundSize])


    const selectedMin = MinFundOptions?.filter(option => option.isChecked)?.at(0)
    const selectedMax = MaxFundOptions?.filter(option => option.isChecked)?.at(0)

    /**
     * The function `handleMinRange` updates the minimum fund options based on the selected minimum
     * size and displays a warning if the selected minimum size is greater than the selected maximum
     * size.
     * @param e - The parameter `e` is an event object that is passed to the `handleMinRange` function.
     * It is typically an event object that is triggered by a user action, such as a button click or
     * input change. In this case, it seems to be an input change event.
     * @returns The function `handleMinRange` returns nothing (undefined).
     */
    const handleMinRange = (e) => {
        const { value } = e.target;
        if (selectedMax?.name !== "" && selectedMax?.name != null && (parseInt(selectedMax?.name) < parseInt(value))) {
            toast.warning('Minimum size must be less than maximum size')
            return;
        }
        setMinFundOptions(MinFundOptions?.map(type => ({ ...type, isChecked: value === "" ? false : type.name === value })))
    }

    /**
     * The function `handleMaxRange` updates the `MaxFundOptions` array based on the selected maximum
     * size value, and displays a warning toast if the selected maximum size is less than the selected
     * minimum size.
     * @param e - The parameter `e` is an event object that is passed to the `handleMaxRange` function.
     * It is typically an event object that is triggered by a user action, such as a button click or
     * input change. In this case, it seems to be an input change event, as it is
     * @returns nothing (undefined).
     */
    const handleMaxRange = (e) => {
        const { value } = e.target;
        if (selectedMin?.name !== "" && selectedMin?.name != null && (parseInt(selectedMin?.name) > parseInt(value))) {
            toast.warning('Maximum size must be greater than minimum size')
            return;
        }
        setMaxFundOptions(MaxFundOptions?.map(type => ({ ...type, isChecked: value === "" ? false : type.name === value })))
    }


    return (
        <div className="border-b-[3px] border-[#E8E6E1]">
            <li
                className={
                    "relative px-3 py-2"
                }
            >
                <p className="text-[14px] tracking-[-.3px]">Size (US$ M)</p>
                <div className="flex mt-2">
                    <select value={selectedMin?.name ?? ""} name="" id="" onChange={handleMinRange}
                        className=" border border-[#D9BD7F] mr-3 rounded focus:outline-none bg-[#FFF9EC] lg:w-[5rem] w-[9.5rem] md:w-[16rem] lg:h-[1.6rem]">
                        <option value="" selected>All</option>
                        {MinFundOptions && MinFundOptions.length > 0 ? (
                            MinFundOptions.map((date, index) => (
                                <option value={date.name ? date.name : ''}>{date.name ? date.name : ''}</option>
                            )))
                            :
                            (
                                <h1>No results found!</h1>
                            )}
                    </select>
                    <p className="text-sm mr-3">To</p>
                    <select value={selectedMax?.name ?? ""} name="" id="" onChange={handleMaxRange}
                        className=" border border-[#D9BD7F] mr-3 rounded focus:outline-none bg-[#FFF9EC] lg:w-[5rem] w-[9.5rem] md:w-[16rem] lg:h-[1.6rem]">
                        <option value="" selected>All</option>
                        {MaxFundOptions && MaxFundOptions.length > 0 ? (
                            MaxFundOptions.map((date, index) => (
                                <option value={date.name ? date.name : ''}>{date.name ? date.name : ''}</option>
                            )))
                            :
                            (
                                <h1>No results found!</h1>
                            )}
                    </select>
                </div>
            </li>
        </div>
    )
}

export default DealRangeFilter