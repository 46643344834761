import { Tooltip } from 'antd';
import React, { useContext, useEffect, useState } from 'react'
import Constants from '../../Constants';
import addNoted from "../../images/AtomFavourite Copy/png/AtomFavourite Copy.png";
import addNotedActive from '../../images/AtomFavourite Copy 3/png/AtomFavourite Copy 3.png';
import { toast } from 'react-toastify';
import PlusIcon from "../../images/plusicon.png";
import { UserContext } from '../../Contexts/UserContext';
import axios from 'axios';
import { addEntityApi, createGroupListApi, groupListApi } from '../../Config/config';
import useModule from '../../hooks/useModule';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

/**
 * The function `AddNoteManager` is a component that renders a dropdown menu for selecting or
 * creating a group, and allows the user to add an entity to the selected group.
 * @returns a JSX element.
 */
function AddNoteManager({ companyName, type_id, module, cid , open ,investor_id, deal_id,sub_type_master_id}) {

    const [firstName, setFirstName] = useState("");
    // const [showDropdown, setShowDropdown] = useState(false);
    const [newList, setnewList] = useState(false);
    // const [showNotification, setShowNotification] = useState(false);
    const [groupList, setGroupList] = useState();
    // const [createGroupList, setCreateGroupList] = useState();
    // const [addGroupEntity, setAddGroupEntity] = useState();
    // const [success, setSuccess] = useState();
    const [idToscroll, setIdToScroll] = useState();
    const { getToken } = useContext(UserContext);
    const { primaryMasterId } = useModule();

    const createdGroupView = () => {
        document.getElementById(idToscroll).scrollIntoView({ behavior: "smooth" });
    };

    const onchangeName = (event) => {
        setFirstName(event.target.value);
        // event.currentTarget.disabled = false;
    };
    const companyList = (event) => {
        event.preventDefault();
        //  let id = event.target.getAttribute("id")
        if (firstName !== "") {
            createList();
        } else {
            // setMsg("The list name is required");
            toast.warning("The list name is required")
        }
        // setShowNotification(true);
        setnewList(false);
        // setAddValues(oldArray => [...oldArray,addValues[id].Details.unshift(firstName)])
    };
    // const inputRef = useRef(null);
    function clearValue() {
        setnewList(!newList);
        setFirstName("");
    }
    const afterGroupAdd = () => {
        // setShowDropdown(true);
        // setShowNotification(false);
        listGroup();
    };
    useEffect(() => {
       if(open){
        listGroup();
       }
    }, [open]);





    const listGroup = () => {
        // setShowDropdown(!showDropdown);
        axios(`${groupListApi}`, {
            method: "POST",
            data: {
                type_id: type_id,
                // user_id: 1,
                primary_type_master_id: primaryMasterId,

            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": getToken()
            },
        })
            .then((response) => {
                // console.log(response, "list group api response");
                if (response?.data?.success == true) {
                    setGroupList(response?.data);
                    createdGroupView();
                }
            })
            .catch((error) => {
                console.log("API not working", error);
            });
    };
    //  console.log(groupList,'grouplist');
    const createList = () => {
        axios(`${createGroupListApi}`, {
            method: "POST",
            data: {
                group_name: firstName,
                type_id: type_id,
                // user_id: 1,
                primary_type_master_id: primaryMasterId,
                investor_id:investor_id
            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": getToken()
            },
        })
            .then((response) => {
                //  console.log(response,'createdlist response');
                if (response?.data?.success == true) {
                    // setCreateGroupList(response?.data?.group);
                    listGroup();
                    if (response?.data?.message) {
                        // setMsg(response?.data?.message)
                        toast.success(response?.data?.message);
                    } else {
                        toast.success("Group Name Added Successfully");
                    }
                } else {
                    // setMsg(response?.data?.message)
                    toast.warning(response?.data?.message);
                }
            })
            .catch((error) => {
                console.log("API not working", error);
            });
    };

    const addEntity = (groupid) => {
        //  console.log('groupid:',groupid,"type_id :",props.type_id,"entity_id :",props.cid,"type_name :",props.module);
        axios(`${addEntityApi}`, {
            method: "POST",
            data: {
                // user_id: 1,
                group_id: groupid,
                type_id: type_id,
                entity_id: cid,
                primary_type_master_id: primaryMasterId,
                investor_id:investor_id,
                deal_id:deal_id,
                sub_type_master_id:sub_type_master_id
                // type_name: props.module,
            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": getToken()
            },
        })
            .then((response) => {
                // console.log(response, "Add Entity group api response");
                if (response?.data?.message) {
                    // setMsg(response?.data?.message)

                    // setShowNotification(true);
                    // setSuccess(response?.data?.success);
                    toast.success(response?.data?.message);
                } else {
                    // setAddGroupEntity(response?.data);
                    // setMsg(response?.data?.success)
                    // setShowNotification(true);
                    toast.warning(response?.data?.success);
                }
            })
            .catch((error) => {
                console.log("API not working", error);
            });
    };

    return <div className="w-full min-w-[200px] max-w-[200px] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
        <div className="s">
            <div className=" border-b px-3 text-left grid grid-cols-1  justify-between  flex bg-white py-2 text-[14px] tracking-[-0.3px] leading-[17px]">
                {companyName}
            </div>
            <div className=" justify-between">
                <p onClick={() => listGroup()}
                    className="justify-between   flex bg-white  pr-4 pl-3 py-2 text-[14px] tracking-[-0.3px] leading-[17px]">
                    Select saved list
                </p>
            </div>
            <div
                className="p-2  flex  tracking-[-0.3px] leading-[17px] bg-white text-[14px]"
            >
                {newList ? (
                    <div className="cursor-pointer flex items-center gap-1">
                        <form onSubmit={companyList} className="flex w-[100%] gap-1">
                            <input
                                type="text"
                                name="text"
                                id="text"
                                autocomplete="off"
                                value={firstName}
                                onChange={onchangeName}
                                className="focus:border-transparent block w-[100%] sm:text-sm border border-[#BC8B20] p-1 pl-3 rounded-md"
                                placeholder="Group Name"
                            />
                            <button type="submit" onClick={() => afterGroupAdd()}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="w-[22px] cursor-pointer"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                    />
                                </svg>
                            </button>
                        </form>
                        <svg
                            onClick={() => setnewList(!newList)}
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-6 h-6 cursor-pointer"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                        </svg>
                    </div>
                ) : (
                    <div
                        onClick={clearValue}
                        className="flex cursor-pointer text-[#BC8B20] text-[14px] leading-[17px] tracking-[-0.3px] items-center"
                    >
                        <img className=" cursor-pointer w-[22px] h-[13px] px-1" src={PlusIcon}></img>
                        <span className="cursor-pointer">Create New list</span>
                    </div>
                )}
            </div>
        </div>
        <div
            className="h-[8rem] block pr-0 bg-white tracking-[-0.3px] leading-[17px] text-[14px]  overflow-y-auto no-scrollbar max-h-[125px]"

        >
            {groupList?.group?.length > 0 ? (
                groupList?.group?.map((dropDownList, i) => {
                    // console.log(groupList,'dropdown value');
                    return (
                        <div className="hover:bg-gray-100">
                            <button
                                type="submit"
                                className="text-[14px] text-[#333333] leading-[17px] tracking-[-0.3px] flex block px-4 py-2 text-[14px] focus:outline-none "
                                onClick={() => addEntity(dropDownList.id)}
                                id={dropDownList?.id}
                            >
                                {dropDownList?.group_name}
                            </button>
                        </div>
                    );
                })
            ) : (
                <div className="w-full bg-[#FFFFFF]">
                    <center>
                        <div role="status" className="h-full">
                            {groupList?.group || <svg
                                className="relative top-[1rem] inline mr-2 w-12 h-12 text-gray-200 animate-spin dark:text-gray-600 fill-[#CA8A24]"
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="currentColor"
                                />
                                <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentFill"
                                />
                            </svg>}
                        </div>
                    </center>
                </div>
            )}
        </div>
    </div>
}

/**
 * The function `AddNotePopover` is a component that renders a dropdown menu for selecting or
 * creating a group, and allows the user to add an entity to the selected group.
 * @returns a JSX element.
 */
function AddNotePopover({ investor_id ,onClose, companyName, companyId, type_id = Constants.moduleIds.companiesId, className,title,IsDetailed ,deal_id,sub_type_master_id}) {
    const [open, setOpen] = useState(false);
    // const [isOpen, setIsOpen] = useState(true);


   const popupContaainerRef = React.useRef(null);

    // const hide = () => {
    //     setOpen(false);
    // };

    // const handleOpenChange = (newOpen) => {
    //     setOpen(newOpen);
    // };

    // const closePopup = () => {
    //     hide();
    //     onClose();
    // }


    // useEffect(() => {
    //     const handleScroll = () => {
    //         console.log('inside-scroll');
    //       const scrollContainer = document.getElementById('overFlow');
    //       const { scrollTop } = scrollContainer;
    //     if(open) {
    //         setOpen(false)
    //     }
    //     };
    //     const scrollContainer = document.getElementById('overFlow');
    //     scrollContainer.addEventListener('scroll', handleScroll);
    //     return () => {
    //       scrollContainer.removeEventListener('scroll', handleScroll);
    //     };
    //   }, []);



    return <>
      
        
        <div ref={popupContaainerRef}  style={{position: "relative"}}> 
       
      <Popup
        appendTo={popupContaainerRef}
        position={IsDetailed ? "bottom center":["left center","left top"]} 
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        contentStyle={{ padding: 0 ,border:'none', width:'fit-content',marginTop:'8px'}}
        trigger={<div>
        <Tooltip title={title}>
        <img className={`cursor-pointer w-[24px] h-[22px] ${className}`}
            src={open ? addNotedActive : addNoted}
            alt=""
        />
        </Tooltip>
        </div>
        }
        >
           
        <AddNoteManager open={open} page="detailpage" type_id={type_id} companyName={companyName} cid={companyId}  investor_id={investor_id} deal_id={deal_id} sub_type_master_id={sub_type_master_id}/>
        
    </Popup>

      </div>
  </>
      
}

export default AddNotePopover