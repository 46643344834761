import React, { useContext } from 'react'
import { RaiseRequestContext } from '../../../Contexts/RaiseRequestContext'
import { NavLink, matchPath, useLocation } from 'react-router-dom';
import routePathSegments, { routeNames } from '../../../routeSegments';
import OverflowToolTip from '../../shared/OverflowToolTip';
import useModule from "../../../hooks/useModule";
import RandomLogo from '../../RandomLogo';
import CompanyLogo from '../../CompanyLogo';
import Loader from '../../Loader';

/* The code defines a React functional component called `OtherDeals`. It takes in a `props` object as a
parameter. */
const OtherDeals = (props) => {
    const { openRequestPopup } = useContext(RaiseRequestContext)
   
    const location = useLocation();
    const isMaMatch = matchPath(`${routePathSegments.ma}/*`, location.pathname)
    const {isMA } = useModule();

    /**
     * The function adds commas to a number to improve readability.
     * @returns The function `addCommasToNumber` returns a string with commas added to the number.
     */
    const addCommasToNumber = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      };

    return (

        <div
            className="lg:grid lg:grid-rows-8 gap-6">
            <div className={"w-full lg:grid lg:grid-rows-8 lg:grid-flow-col "+( props?.data?.same_acquirer_deals != null ? "overflow-x-auto scrollbar " :'')}>
                <div
                    className="row-span-3 ">
                    <div className=" ">
                        <p
                            className="text-[#333333] pb-4 text-[17px] font-sans_book_body leading-[22px] font-semibold">
                            Other deals by same acquirer

                        </p>                        
                        <table className='w-[100%]'>
                            <thead className='text-[12pt] leading-[15pt] bg-[#F3F3F3] lg:text-[13px] lg:leading-[15px] font-bold text-[#666666] font-semibold whitespace-nowrap'>
                                <tr>
                                    <th className="py-2 px-5 text-left whitespace-nowrap">Target</th>
                                    <th className="py-2 px-5 text-left whitespace-nowrap">Date</th>
                                    <th className="py-2 px-5 text-right whitespace-nowrap">Amount(US$ M)</th>
                                </tr>
                            </thead>
                            <tbody className="text-[11pt] leading-[12pt] lg:text-[14px] lg:leading-[16px] text-[#333333] text-left font-sans_book_body">
                                {props?.data?.length >0 ?
                                
                                props?.data?.map((data, i) => {
                                    return(
                                        <tr className={`${props?.data.length -1 ==i ? '':'border-b bg-white'}`}>
                                            <td className='py-4 px-5 text-left flex items-center'>
                                            {data?.logo_path == "default.png" || data?.logo_path == "/default.png" || !data?.logo_path ?
                                                <RandomLogo name={data?.target_company} />
                                                :
                                                <CompanyLogo name={data?.target_company} id={data?.company_id} page="company page" logo={data?.logo_path} />}
                                                <OverflowToolTip component={<NavLink className="hover:text-[#BC8B20]" to={routeNames.maCompanyById(data.company_id)} >{data?.target_company}</NavLink> }/>
                                                </td>
                                            <td className='py-4 px-5 text-left whitespace-nowrap'><NavLink to={routeNames.maCompanyDealById(data.company_id, data.deal_id)} onClick="window.location.reload()">{data?.date}</NavLink></td>
                                            <td className='py-4 px-5 text-right'>{data?.amount >0 ? addCommasToNumber(data?.amount) :'--' }</td>
                                        </tr>
                                    )
                                })
                                : 
                                props?.data?.length ==0 ?
                                <tr>
                                    <td colSpan={3} className='h-20'>
                                        <div className="bg-[#F3F3F3] p-3 text-[14px] leading-[16px] font-sans_book_body text-[#333333] flex flex-col items-center justify-center ">No data available on Acquirer's other transactions.</div>
                                    </td>
                                </tr>  
                                :  
                                <td colSpan={3}>                                                                                 
                                <div className='flex flex-col items-center justify-center h-20'>
                                    <Loader />
                                </div>   
                                </td>                          
                                }
                            </tbody>
                        </table>




                    </div>
                </div>

            </div>
        </div>

    )
}

export default OtherDeals