import React, { useContext } from 'react';
import { TableDataContext } from '../../Contexts/TableDataContext'
import TextCard from './TextCard';
import tfs_Icon from '../../images/Shape_4/png/Shape.png';
import companyIcon from '../../images/noun-investment-2180740_5/png/noun-investment-2180740.png';
import tfIn_Icon from '../../images/noun-investment-2180740_2/png/noun-investment-2180740.png';
import pe_Icon from '../../images/noun-investment-2180740_4/png/noun-investment-2180740.png';
import { checkValue } from '../../Utils/utils';


/**
 * The IncubationCard component displays various statistics related to incubated companies, follow-on
 * percentage, most funded company, number of incubators, and most active investor.
 * @returns The IncubationCard component is returning a JSX element.
 */
const IncubationCard = () => {
    const { data, isLoad, isDataLoad } = useContext(TableDataContext);
    return (
        <div className="">
            <div className='sm:full md:mt-[3rem] mt-[4.25rem] h-full bg-[#F2F2F2]'>
                <div className="overflow-x-auto lg:grid grid-cols-5 gap-4  px-4 py-[0.7rem] flex xl:overflow-hidden scrollbar-remove">
                    <TextCard
                        image={tfs_Icon}
                        isLoading={isDataLoad}
                        showCount={true}
                        subtitle={checkValue(data?.num_companies)}
                        title={"Incubated Cos."}
                    />
                    <TextCard
                        image={tfs_Icon}
                        isLoading={isDataLoad}
                        showCount={true}
                        subtitle={checkValue(data?.follow_fund)}
                        title={"Follow on %"}
                    />
                    <TextCard
                        image={companyIcon}
                        isLoading={isDataLoad}
                        showCount={false}
                        totalCount = {data?.mostFundCount ? data?.mostFundCount  : null}
                        subtitle={data?.most_fund === "" ? null : data?.most_fund}
                        title={"Most Funded"}
                    />
                    <TextCard
                        image={pe_Icon}
                        isLoading={isDataLoad}
                        showCount={true}
                        subtitle={checkValue(data?.angel_investor_count)}
                        title={"Incubators"}
                    />
                    <TextCard
                        image={companyIcon}
                        isLoading={isDataLoad}
                        showCount={false}
                        subtitle={data?.most_active_investor === "" ? null : data?.most_active_investor}
                        title={"Most Active"}
                    />
                </div>
                <div className='border-b-2 border-[#e5e5e5]'></div>
            </div >
        </div >
    )
}



export default IncubationCard