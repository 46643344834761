import { createContext, useState } from "react";

export const DataFoundContext = createContext("");

/**
 * The `DataFoundProvider` component is a wrapper that provides state variables and setter functions to
 * its children components through the `DataFoundContext`.
 * @returns The `DataFoundProvider` component is being returned.
 */
const DataFoundProvider = ({ children }) => {
  const [maDataFound, setMaDataFound] = useState(false);
  const [cfsDataFound, setCfsDataFound] = useState(false);
  const [capTableDataFound, setCapTableDataFound] = useState(false);
  const [similarCompaniesDataFound, setSimilarCompaniesDataFound] =
    useState(false);
  const [newsAndActivityDataFound, setNewsAndActivityDataFound] =
    useState(false);
  const [investorsExitsDataFound, setInvestorsExitsDataFound] = useState(false);
  const [investorsIPODataFound, setInvestorsIPODataFound] = useState(false);
  const [investorsFundsDataFound, setInvestorsFundsDataFound] = useState(false);
  const [toCompaniesInvesmentsDataFound, setToCompaniesInvesmentsDataFound] = useState(false);
  const [toCompaniesExitsDataFound, setToCompaniesExitsDataFound] =
    useState(false);
  const [toInvestorsDataFound, setToInvestorsDataFound] = useState(false);
  const [toAcquirersDataFound, setToAcquirersDataFound] = useState(false);
  const dataFoundState = {
    maDataFound,
    setMaDataFound,
    cfsDataFound,
    setCfsDataFound,
    capTableDataFound,
    setCapTableDataFound,
    similarCompaniesDataFound,
    setSimilarCompaniesDataFound,
    newsAndActivityDataFound,
    setNewsAndActivityDataFound,
    investorsExitsDataFound,
    setInvestorsExitsDataFound,
    investorsIPODataFound,
    setInvestorsIPODataFound,
    investorsFundsDataFound,
    setInvestorsFundsDataFound,
    toCompaniesInvesmentsDataFound, 
    setToCompaniesInvesmentsDataFound,
    toAcquirersDataFound,
    setToAcquirersDataFound,
    toInvestorsDataFound,
    setToInvestorsDataFound,
    toCompaniesExitsDataFound,
    setToCompaniesExitsDataFound,
  };

  return (
    <DataFoundContext.Provider value={dataFoundState}>
      {children}
    </DataFoundContext.Provider>
  );
};

export default DataFoundProvider;
