import React, { useState, useContext } from 'react';
import pe_Icon from '../../../images/noun-investment-2180740_4/png/noun-investment-2180740.png';
import tfs_Icon from '../../../images/Shape_4/png/Shape.png';
import tfIn_Icon from '../../../images/noun-investment-2180740_2/png/noun-investment-2180740.png';
import companyIcon from '../../../images/noun-investment-2180740_5/png/noun-investment-2180740.png';
import TextCard from '../../ListPageCard/TextCard';
import { UserContext } from '../../../Contexts/UserContext';
import { useParams } from 'react-router-dom';
import useModule from '../../../hooks/useModule';
import { maAdvisorDetailTopCard } from '../../../Config/config';
import axios from 'axios';
import { useEffect } from 'react';

/**
 * The `AdvisorDetailCard` component is a React component that displays information about an advisor,
 * including their investments, industry, and latest transaction.
 * @returns The AdvisorDetailCard component is returning a JSX element, which represents the structure
 * and content of the component's UI.
 */
const AdvisorDetailCard = ({ advisorType }) => {

    const [detailCard, setDetailCard] = useState();
    const { advisorsId } = useParams();
    const { getToken } = useContext(UserContext);
    const [isLoading, setisLoading] = useState(false);


    useEffect(() => {
        const abortController = new AbortController()
        setisLoading(true);
        axios.post(maAdvisorDetailTopCard, {
            "advisor_id": advisorsId,
            "advisor_type": advisorType
        }, {
            signal: abortController.signal,
            headers: {
                "Authorization": getToken()
            }
        }).then(response => {
            setisLoading(false);
            if (response.status === 200) {
                setDetailCard(response.data)

            }
        }).catch(error => {
            setisLoading(false);
        })

        return () => abortController.abort();
    }, [advisorType, advisorsId, getToken])

    return (
        <div className="">
            <div className='sm:full mt-[4.25rem] md:mt-[3rem] h-full  bg-[#F2F2F2]'>
                <div className="overflow-x-auto lg:grid grid-cols-4 gap-4  px-4 py-[0.7rem] flex xl:overflow-hidden scrollbar-remove ">
                    <TextCard
                        image={pe_Icon}
                        isLoading={isLoading}
                        showCount={true}
                        subtitle={detailCard?.to_companies_investments}
                        title="To Companies"
                    />
                    <TextCard
                        image={tfs_Icon}
                        isLoading={false}
                        showCount={false}
                        subtitle={detailCard?.to_acquirer_investments}
                        title="To Acquirers"
                    />
                    <TextCard
                        image={tfIn_Icon}
                        isLoading={false}
                        showCount={false}
                        subtitle={detailCard?.industry}
                        title="Top Industry"
                    />
                    <TextCard
                        image={companyIcon}
                        isLoading={false}
                        showCount={false}
                        subtitle={detailCard?.deal_date}
                        title="Latest Transaction"
                    />
                </div>
                <div className='border-b-2 border-[#e5e5e5]'></div>
            </div >
        </div >
    )
}

export default AdvisorDetailCard;
