import React from 'react'

/**
 * The Tab function is a  component that renders a tab with a label, logo, and optional active
 * state.
 *  It also includes an optional logo image if the "logo" prop is provided. The label is displayed as the text
 */
function Tab({ label, onClick, active, className, activeclassName,logo, href, ...props}) {
    return (
        <span {...props} spy={true} smooth={true}
            className={`py-2 whitespace-nowrap text-[12pt] leading-[17pt] lg:text-[14px] lg:leading-[17px] tab-bar ${className}` +
                (active
                    ? ` border-b-[3px] border-b-[#F1CA75] text-[#333333] font-semibold group-hover:text-[#F1CA75] ${activeclassName}`
                    : " text-[#878a99] font-semibold")}
            onClick={onClick}
        >
          {logo &&<img src={logo} className='w-5 h-5 mr-2' />}{label}
        </span>
    )
}

export default Tab