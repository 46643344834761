import { Select, Spin, Tag } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, createContext, useRef, useState } from 'react';
import { chargeBasedSearchApi } from '../../Config/config';
import '../../index.css'
import { Option } from 'antd/es/mentions';
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import { UserContext } from '../../Contexts/UserContext';
import { AiFillCloseCircle } from 'react-icons/ai'


/**
 * The `tagRender` function renders a tag component with a label, value, and a close button.
 * @param props - The `props` parameter is an object that contains the following properties:
 * @returns The code is returning a JSX element, specifically a `<div>` element with some nested
 * elements inside.
 */
const tagRender = (props) => {
  const { label, value, closable, onClose } = props;
  // const onPreventMouseDown = (event) => {
  //   event.preventDefault();
  //   event.stopPropagation();
  // };
  // console.log(props, "label-8");
  return (
    <div className="mx-1 my-1 text-[12px] flex items-stretch justify-between font-bold  px-1 py-1 rounded-sm bg-slate-100 text-black border ">
      <div>
        {label}
      </div>

      <div onClick={onClose} className='px-1 flex items-center justify-center cursor-pointer'>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="black" className="w-3 h-3">
          <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </div>


    </div>
  );
};

/**
 * The Tags component is a custom select input that allows users to search and select multiple tags
 * based on a search key and category.
 * @returns The component `Tags` is being returned.
 */
const Tags = ({ ChargeBasedSelected, customPathname, setChargeBasedSelected,placeholder,category }) => {
  const { getToken } = useContext(UserContext);


  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const [searchText, setSearchText] = useState("");
  const firstRender = useRef(false);
  const { refineSearchFilters } = useContext(PeFilterContext);
  const [open, setOpen] = useState(false);
  
useEffect(() => {
  setOptions([])
}, [category])
  


  useEffect(() => {
    let sub;
    if (firstRender.current) {
      sub = setTimeout(() => {
        if (searchText !== "" && searchText?.length > 2) {
          setOptions([]);
          setFetching(true);
          getSearchTags(searchText).then(newOptions => {
            console.log(newOptions, "newOptionsnewOptions");
            setOptions(newOptions);
            setFetching(false);
          });
        } else {
          setOptions([]);
        }
      }, 500);
    } else {
      firstRender.current = true;
      setOptions(ChargeBasedSelected)
    }
    return () => clearTimeout(sub);
  }, [searchText])

/**
 * The handleChange function logs the options and sets the selected value based on the input value,
 * then closes the dropdown and clears the options.
 * @param value - The `value` parameter is the selected value(s) from the options. It can be an array
 * of values or a single value.
 * @param options - The `options` parameter is an array of available options for the `handleChange`
 * function.
 */
  const handleChange = (value, options) => {
    console.log("optionsoptionsoptions", options);
    setChargeBasedSelected(value?.map(option => ({ id: option, name: option })))
    setOpen(false)
    clearOptions()
  };

  // console.log(ChargeBasedSelected,'ChargeBasedSelected');
/**
 * The function `getSearchTags` is an asynchronous function that makes a POST request to a charge-based
 * search API with a search key, category, and type, and returns the data from the response if the
 * status is 200, otherwise it returns an empty array.
 * @param searchText - The `searchText` parameter is the text that you want to search for in the API.
 * It is used as the search key to find relevant data.
 * @returns The function `getSearchTags` returns the data from the response if the response status is
 * 200. If the response status is not 200, it returns an empty array. If there is an error during the
 * API call, it also returns an empty array.
 */
  const getSearchTags = async (searchText) => {
    try {
      const response = await axios.post(chargeBasedSearchApi, {       
        "search_key" : searchText,
        "category"   : category,
        "type"       : 5
     

      }, {
        headers: {
          "Authorization": getToken()
        }
      });

      if (response.status === 200) {
        return response.data?.data;
      } else {
        return [];
      }
    } catch (error) {
      return [];
    }
  }

/**
 * The clearOptions function clears the options array and resets the searchText variable.
 */
  const clearOptions = () => {
    setOptions([])
    setSearchText("");
  }


  return <Select
    mode="multiple"
    filterOption={true}
    showSearch
    style={{
      width: '100%',
      maxHeight:'60px',
      overflowY: "auto"
    }}    
    className="text-[#333333]"
    open={open}    
    onDropdownVisibleChange={(visible) => setOpen(visible)}
    placeholder={`Search by ${placeholder}...`}
    bordered={false}
    tagRender={tagRender}
    onSearch={(value) => setSearchText(value)}
    onChange={handleChange}
    value={ChargeBasedSelected?.map(option => option.name)}
    onBlur={clearOptions}
    notFoundContent={searchText === "" || searchText?.length < 3 ? null : fetching ? <p className='text-[#333333] text-[13px]'>Searching...</p> : <p className='text-[#333333] text-[13px]'>No data found</p>}
    >
    {options?.map(option => {
      return <Option key={option.id} value={option.name}>{option.name}</Option>
    })}
  </Select>
};

export default Tags;