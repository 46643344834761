/* The above code is exporting a constant variable named "data" which contains an object. */
export const data = {

    initial: [
        {
            "id": "1",
            "name": "Basic Profile",
            "fileName": "Basic Profile",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "Basic Profile",
            "parent": "initial",
            "isTransaction": false,
            "withDetails": false,


        },
        {
            "id": "2",
            "name": "Investments",
            "fileName": "Investments",
            "isChecked": false,
            "hasMore": true,
            "filterKey": "Investments",
            "parent": "initial",
            "isTransaction": false,
            "withDetails": true,

        },
        {
            "id": "3",
            "name": "Exits",
            "fileName": "Exits",
            "isChecked": false,
            "hasMore": true,
            "filterKey": "Exits",
            "parent": "initial",
            "isTransaction": false,
            "withDetails": true,

        }
    ],

    "Basic Profile": [
        {
            "id": 1,
            "name": "Company Name",
            "value": "Company Name",
            "isChecked": true,
            "hasMore": false,
            "parent": "Basic Profile",
            "category": "Basic Profile",
            "values": [""],
            "member": "Pecompanies.companyname",
            "filterData": {
                "searchType": [{ "Includes any": true, "dataType": "Select" }, { "Does not include": false, "dataType": "Select" }, {"Starts with": false, "dataType": "StartWithSelect" }, {"Contains": false, "dataType": "Text" }, { "Does not contains": false, "dataType": "Text" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Select",
                "autoSuggest": true,
                "apiEndPoint": "/advanced/companysearch",
                "multiple": true
            }
        },
        {
            "id": 2,
            "name": "Country",
            "value": "Country",
            "isChecked": true,
            "hasMore": false,
            "parent": "Basic Profile",
            "category": "Basic Profile",
            "values": [""],
            "member": "Pecompanies.country",
            "filterData": {
                "searchType": [{ "Includes any": true, "dataType": "Select" }, {"Includes all": false, "dataType": "Select" }, { "Does not include": false, "dataType": "Select" }, {"Equals": false, "dataType": "Text" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Select",
                "autoSuggest": true,
                "apiEndPoint": "/advanced/countrysearch",
                "multiple": true
            }
        },
        {
            "id": 3,
            "name": "Region",
            "value": "Region",
            "isChecked": true,
            "hasMore": false,
            "parent": "Basic Profile",
            "category": "Basic Profile",
            "values": [""],
            "member": "Pecompanies.region",
            "filterData": {
                "searchType": [{ "Includes any": true, "dataType": "Select" }, {"Includes all": false, "dataType": "Select" }, { "Does not include": false, "dataType": "Select" }, {"Equals": false, "dataType": "Text" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Select",
                "apiEndPoint": "/advanced/regionsearch",
                "autoSuggest": true,
                "multiple": true
            }
        },
        {
            "id": 4,
            "name": "State",
            "value": "State",
            "isChecked": true,
            "hasMore": false,
            "parent": "Basic Profile",
            "category": "Basic Profile",
            "values": [""],
            "member": "Pecompanies.state",
            "filterData": {
                "searchType": [{ "Includes any": true, "dataType": "Select" }, {"Includes all": false, "dataType": "Select" }, { "Does not include": false, "dataType": "Select" }, {"Equals": false, "dataType": "Text" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Select",
                "apiEndPoint": "/advanced/statesearch",
                "autoSuggest": true,
                "multiple": true
            }
        },
        {
            "id": 5,
            "name": "City",
            "value": "City",
            "isChecked": true,
            "hasMore": false,
            "parent": "Basic Profile",
            "category": "Basic Profile",
            "values": [""],
            "member": "Pecompanies.city",
            "filterData": {
                "searchType": [{ "Includes any": true, "dataType": "Select" }, {"Includes all": false, "dataType": "Select" }, { "Does not include": false, "dataType": "Select" }, {"Equals": false, "dataType": "Text" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Select",
                "apiEndPoint": "/advanced/citysearch",
                "autoSuggest": true,
                "multiple": true
            }
        },
        {
            "id": 6,
            "name": "Year Founded",
            "value": "Year Founded",
            "isChecked": true,
            "hasMore": false,
            "parent": "Basic Profile",
            "category": "Basic Profile",
            "values": [""],
            "member": "Pecompanies.yearfounded",
            "filterData": {
                "searchType": [{"Before": true, "dataType": "Before" }, {"After": false, "dataType": "After" }, {"Between": false, "dataType": "Between" }, {"Equals": false, "dataType": "Before" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "number-years",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 7,
            "name": "Company Type",
            "value": "Company Type",
            "isChecked": true,
            "hasMore": false,
            "parent": "Basic Profile",
            "category": "Basic Profile",
            "values": [""],
            "member": "DealFlat.companyType",
            "filterData": {
                "searchType": [{ "Includes any": true, "dataType": "Select" }, {"Includes all": false, "dataType": "Select" }, { "Does not include": false, "dataType": "Select" }, {"Equals": false, "dataType": "Text" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "apiEndPoint": "/advanced/companytype/search",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 8,
            "name": "Industry",
            "value": "Industry",
            "isChecked": true,
            "hasMore": false,
            "parent": "Basic Profile",
            "category": "Basic Profile",
            "values": [""],
            // "values": ["BFSI,Energy"],
            "member": "DealFlat.industry",
            "filterData": {
                "searchType": [{ "Includes any": true, "dataType": "Select" }, {"Includes all": false, "dataType": "Select" }, { "Does not include": false, "dataType": "Select" }, {"Equals": false, "dataType": "Text" }],
                "dataType": "Select",
                "apiEndPoint": "/advanced/industrysearch",
                "autoSuggest": true,
                "multiple": true
            }
        },
        {
            "id": 9,
            "name": "Sector",
            "value": "Sector",
            "isChecked": true,
            "hasMore": false,
            "parent": "Basic Profile",
            "category": "Basic Profile",
            "values": [""],
            "member": "Pecompanies.sectorBusiness",
            "filterData": {
                "searchType": [{ "Includes any": true, "dataType": "Select" }, {"Includes all": false, "dataType": "Select" }, { "Does not include": false, "dataType": "Select" }, {"Equals": false, "dataType": "Text" }],
                "dataType": "Text",
                "apiEndPoint": "/advanced/sectorsearch",
                "autoSuggest": true,
                "multiple": true
            }
        },
        {
            "id": 10,
            "name": "Tags",
            "value": "Tags",
            "isChecked": true,
            "hasMore": false,
            "parent": "Basic Profile",
            "category": "Basic Profile",
            "values": [""],
            "member": "PecompanyTagCrosstabVw.tags",
            // "member":"cfs_tag.tags (CfsFlat.CIN = cfs_tag.CIN)",
            "filterData": {
                "searchType": [{ "Includes any": true, "dataType": "Select" }, { "Does not include": false, "dataType": "Select" }, {"Equals": false, "dataType": "Text" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "apiEndPoint": "/advanced/tagsearch",
                "autoSuggest": true,
                "multiple": true
            }
        },
    ],

    "Investments": [
        {
            "id": 11,
            "name": "Total Funding ($M)",
            "value": "Total Funding ($M)",
            "isChecked": true,
            "hasMore": false,
            "parent": "Investments",
            "category": "Investments",
            "values": [""],
            "member": "profile_flat.totalFunding",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true ,"dataType": "Numbers"}, {"Less than or equal to": false ,"dataType": "Numbers"}, { "Equals to": false ,"dataType": "Numbers" }, { "Does not equal to": false ,"dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 12,
            "name": "Total Funding (INR Cr)",
            "value": "Total Funding (INR Cr)",
            "isChecked": true,
            "hasMore": false,
            "parent": "Investments",
            "category": "Investments",
            "values": [""],
            "member": "profile_flat.totalFundingInr",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true ,"dataType": "Numbers"}, {"Less than or equal to": false ,"dataType": "Numbers"}, { "Equals to": false ,"dataType": "Numbers"}, { "Does not equal to": false ,"dataType": "Numbers"}, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 13,
            "name": "Deal Info",
            "value": "Deal Info",
            "isChecked": false,
            "hasMore": true,
            "filterKey": "Deal Info",
            "parent": "Investments",
            "category": "Investments",
            "values": [""],
        },
        {
            "id": 62,
            "name": "Investor Info",
            "value": "Investor Info",
            "isChecked": false,
            "hasMore": true,
            "filterKey": "Investor Info",
            "parent": "Investments",
            "category": "Investments",
            "values": [""],
        },
        {
            "id": 14,
            "name": "Valuation Info",
            "value": "Valuation Info",
            "isChecked": false,
            "hasMore": true,
            "filterKey": "Valuation Info",
            "parent": "Investments",
            "category": "Investments",
            "values": [""],
        },
        {
            "id": 15,
            "name": "Financials Info",
            "value": "Financials Info",
            "isChecked": false,
            "hasMore": true,
            "filterKey": "Financials Info",
            "parent": "Investments",
            "category": "Investments",
            "values": [""],
        },
        {
            "id": 16,
            "name": "Advisor Info",
            "value": "Advisor Info",
            "isChecked": false,
            "hasMore": true,
            "filterKey": "Advisor Info",
            "parent": "Investments",
            "category": "Investments",
            "values": [""],
        }
    ],

    "Deal Info": [
        {
            "id": 17,
            "name": "Deal Amount ($M)",
            "value": "Deal Amount ($M)",
            "isChecked": true,
            "hasMore": false,
            "parent": "Deal Info",
            "category": "Deal Info",
            "values": [""],
            "member": "DealFlat.amountM",
            "filterData": {
                "searchType": [{"Range": true, "dataType": "Range"}, {"Greater than or equal to":false,"dataType": "Numbers"}, {"Less than or equal to": false ,"dataType": "Numbers"}, { "Equals to": false, "dataType": "Numbers" }, { "Does not equal to": false, "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 18,
            "name": "Deal Amount (INR Cr)",
            "value": "Deal Amount (INR Cr)",
            "isChecked": true,
            "hasMore": false,
            "parent": "Deal Info",
            "category": "Deal Info",
            "values": [""],
            "member": "DealFlat.amountInr",
            "filterData": {
                "searchType": [{"Range": true, "dataType": "Range"}, {"Greater than or equal to":false, "dataType": "Numbers"}, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false ,"dataType": "Numbers"}, { "Does not equal to": false ,"dataType": "Numbers"}, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": true,
                "multiple": true
            }
        }, 
        {
            "id": 19,
            "name": "Deal Type",
            "value": "Deal Type",
            "isChecked": true,
            "hasMore": false,
            "parent": "Deal Info",
            "category": "Deal Info",
            "values": [""],
            "member": "DealFlat.SPV",
            "filterData": {
                "apiEndPoint": "/advanced/dealtype/search",
                "searchType": [{ "Includes any": true, "dataType": "Select" }, {"Includes all": false, "dataType": "Select" }, { "Does not include": false, "dataType": "Select" }, {"Equals": false, "dataType": "Text" }],
                "dataType": "Text",
                "autoSuggest": true,
                "multiple": true
            }
        },
        {
            "id": 20,
            "name": "Deal Involving",
            "value": "Deal Involving",
            "isChecked": true,
            "hasMore": false,
            "parent": "Deal Info",
            "category": "Deal Info",
            "values": [""],
            "member": "DealFlat.investors",
            "filterData": {
                "apiEndPoint" : "/advanced/dealinvolving/search",
                "searchType": [{ "Includes any": true, "dataType": "Select" }, { "Does not include": false, "dataType": "Select" }, {"Equals": false, "dataType": "Text" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": true,
                "multiple": true
            }
        },        
        {
            "id": 21,
            "name": "Deal Date",
            "value": "Deal Date",
            "isChecked": true,
            "hasMore": false,
            "parent": "Deal Info",
            "category": "Deal Info",
            "values": [],
            "member": "DealFlat.date",
            "filterData": {
                "searchType": [{"Before": true, "dataType": "CalenderType" }, {"After": false, "dataType": "CalenderType" }, {"Between": false, "dataType": "MultiCalenderType" }, {"Equals": false, "dataType": "CalenderType" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "numeric",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 22,
            "name": "Exit Status",
            "value": "Exit Status",
            "isChecked": true,
            "hasMore": false,
            "parent": "Deal Info",
            "category": "Deal Info",
            "values": [""],
            "member": "DealFlat.exitStatus",
            "filterData": {
                "apiEndPoint" : "/advanced/exitstatus/search",
                "searchType": [{ "Includes any": true, "dataType": "Select" }, { "Does not include": false, "dataType": "Select" }, {"Equals": false, "dataType": "Text" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": true,
                "multiple": true
            }
        },
        {
            "id": 23,
            "name": "Stage",
            "value": "Stage",
            "isChecked": true,
            "hasMore": false,
            "parent": "Deal Info",
            "category": "Deal Info",
            "values": [""],
            "member": "DealFlat.stage",
            "filterData": {
                "apiEndPoint" : "/advanced/stagesearch",
                "searchType": [{ "Includes any": true, "dataType": "Select" }, { "Does not include": false, "dataType": "Select" }, {"Equals": false, "dataType": "Text" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": true,
                "multiple": true
            }
        },
        {
            "id": 24,
            "name": "Round",
            "value": "Round",
            "isChecked": true,
            "hasMore": false,
            "parent": "Deal Info",
            "category": "Deal Info",
            "values": [""],
            "member": "DealFlat.round_type",
            "filterData": {
                "apiEndPoint" : "/advanced/roundsearch",
                "searchType": [{ "Includes any": true, "dataType": "Select" }, { "Does not include": false, "dataType": "Select" }, {"Equals": false, "dataType": "Text" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": true,
                "multiple": true
            }
        },
        {
            "id": 25,
            "name": "Price To Book",
            "value": "Price To Book",
            "isChecked": true,
            "hasMore": false,
            "parent": "Deal Info",
            "category": "Deal Info",
            "values": [""],
            "member": "DealFlat.priceToBook",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true, "dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false ,"dataType": "Numbers"}, { "Does not equal to": false, "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
    ],

    "Investor Info" : [
        {
            "id" : 60,
            "name" : "Investor Name",
            "value" : "Investor Name",
            "isChecked" : true,
            "hasMore" : false,
            "parent" : "Investor Info",
            "category" : "Investor Info",
            "values" : [""],
            "member" : "peinvestors.investor",
            "filterData" : {
                "searchType": [{ "Includes any": true, "dataType": "Select" }, { "Does not include": false, "dataType": "Select" }, {"Starts with": false, "dataType": "StartWithSelect" }, {"Contains": false, "dataType": "Text" }, { "Does not contains": false, "dataType": "Text" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Select",
                "autoSuggest": true,
                "apiEndPoint": "/advanced/investorsearch",
                "multiple": true
            }

        },
        {
            "id" : 61,
            "name" : "Investor Type",
            "value" : "Investor Type",
            "isChecked" : true,
            "hasMore" : false,
            "parent" : "Investor Info",
            "category" : "Investor Info",
            "values" : [""],
            "member" : "DealFlat.investor_type",
            "filterData": {
                "searchType": [{ "Includes any": true, "dataType": "Select" }, {"Includes all": false, "dataType": "Select" }, { "Does not include": false, "dataType": "Select" }],
                "dataType": "Select",
                "autoSuggest": true,
                "apiEndPoint": "/advanced/investortype/search",
                "multiple": true
            }

        },

    ],

    "Valuation Info": [
        {
            "id": 26,
            "name": "Pre Money Valuation",
            "value": "Pre Money Valuation",
            "isChecked": true,
            "hasMore": false,
            "parent": "Valuation Info",
            "category": "Valuation Info",
            "values": [""],
            "member": "DealFlat.preMoneyValuationInr",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true, "dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false, "dataType": "Numbers" }, { "Does not equal to": false, "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 27,
            "name": "Revenue Multiple (Pre-money)",
            "value": "Revenue Multiple (Pre-money)",
            "isChecked": true,
            "hasMore": false,
            "parent": "Valuation Info",
            "category": "Valuation Info",
            "values": [""],
            "member": "DealFlat.revenueMultiplePre",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true, "dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false, "dataType": "Numbers" }, { "Does not equal to": false , "dataType": "Numbers"}, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 28,
            "name": "EBITDA Multiple (Pre-money)",
            "value": "EBITDA Multiple (Pre-money)",
            "isChecked": true,
            "hasMore": false,
            "parent": "Valuation Info",
            "category": "Valuation Info",
            "values": [""],
            "member": "DealFlat.ebitdaMultiplePre",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true, "dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false, "dataType": "Numbers" }, { "Does not equal to": false, "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 29,
            "name": "PAT Multiple (Pre-money)",
            "value": "PAT Multiple (Pre-money)",
            "isChecked": true,
            "hasMore": false,
            "parent": "Valuation Info",
            "category": "Valuation Info",
            "values": [""],
            "member": "DealFlat.patMultiplePre",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true ,"dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false, "dataType": "Numbers" }, { "Does not equal to": false , "dataType": "Numbers"}, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 30,
            "name": "Post Money Valuation ",
            "value": "Post Money Valuation ",
            "isChecked": true,
            "hasMore": false,
            "parent": "Valuation Info",
            "category": "Valuation Info",
            "values": [""],
            "member": "DealFlat.postMoneyValuationInr",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true, "dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false, "dataType": "Numbers" }, { "Does not equal to": false, "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 31,
            "name": "Revenue Multiple (Post-money)",
            "value": "Revenue Multiple (Post-money)",
            "isChecked": true,
            "hasMore": false,
            "parent": "Valuation Info",
            "category": "Valuation Info",
            "values": [""],
            "member": "DealFlat.revenueMultiplePost",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true, "dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false, "dataType": "Numbers" }, { "Does not equal to": false, "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 32,
            "name": "EBITDA Multiple (Post-money)",
            "value": "EBITDA Multiple (Post-money)",
            "isChecked": true,
            "hasMore": false,
            "parent": "Valuation Info",
            "category": "Valuation Info",
            "values": [""],
            "member": "DealFlat.ebitdaMultiplePost",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true, "dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false, "dataType": "Numbers" }, { "Does not equal to": false, "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 33,
            "name": "PAT Multiple (Post-money)",
            "value": "PAT Multiple (Post-money)",
            "isChecked": true,
            "hasMore": false,
            "parent": "Valuation Info",
            "category": "Valuation Info",
            "values": [""],
            "member": "DealFlat.patMultiplePost",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true, "dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false, "dataType": "Numbers" }, { "Does not equal to": false, "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 34,
            "name": "Enterprise Valuation",
            "value": "Enterprise Valuation",
            "isChecked": true,
            "hasMore": false,
            "parent": "Valuation Info",
            "category": "Valuation Info",
            "values": [""],
            "member": "DealFlat.enterpriseValuationInr",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true, "dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false, "dataType": "Numbers" }, { "Does not equal to": false, "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 35,
            "name": "Revenue Multiple (EV)",
            "value": "Revenue Multiple (EV)",
            "isChecked": true,
            "hasMore": false,
            "parent": "Valuation Info",
            "category": "Valuation Info",
            "values": [""],
            "member": "DealFlat.revenueMultipleEv",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true, "dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false,"dataType": "Numbers" }, { "Does not equal to": false, "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 36,
            "name": "EBITDA Multiple (EV)",
            "value": "EBITDA Multiple (EV)",
            "isChecked": true,
            "hasMore": false,
            "parent": "Valuation Info",
            "category": "Valuation Info",
            "values": [""],
            "member": "DealFlat.ebitdaMultipleEv",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true, "dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false, "dataType": "Numbers" }, { "Does not equal to": false, "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 37,
            "name": "PAT Multiple (EV)",
            "value": "PAT Multiple (EV)",
            "isChecked": true,
            "hasMore": false,
            "parent": "Valuation Info",
            "category": "Valuation Info",
            "values": [""],
            "member": "DealFlat.patMultipleEv",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true, "dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false, "dataType": "Numbers" }, { "Does not equal to": false,  "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
    ],

    "Financials Info": [
        {
            "id": 38,
            "name": "Revenue (INR Cr)",
            "value": "Revenue (INR Cr)",
            "isChecked": true,
            "hasMore": false,
            "parent": "Financials Info",
            "category": "Financials Info",
            "values": [""],
            "member": "plstandard_latest_vw.OptnlIncome",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true, "dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false, "dataType": "Numbers" }, { "Does not equal to": false,  "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 39,
            "name": "EBITDA (INR Cr)",
            "value": "EBITDA (INR Cr)",
            "isChecked": true,
            "hasMore": false,
            "parent": "Financials Info",
            "category": "Financials Info",
            "values": [""],
            "member": "plstandard_latest_vw.EBITDA",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true, "dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false , "dataType": "Numbers" }, { "Does not equal to": false,  "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 40,
            "name": "PAT (INR Cr)",
            "value": "PAT (INR Cr)",
            "isChecked": true,
            "hasMore": false,
            "parent": "Financials Info",
            "category": "Financials Info",
            "values": [""],
            "member": "plstandard_latest_vw.PAT",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true, "dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false, "dataType": "Numbers" }, { "Does not equal to": false,  "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
    ],

    "Advisor Info": [
        {
            "id": 41,
            "name": "To Company",
            "value": "To Company",
            "isChecked": true,
            "hasMore": false,
            "parent": "Advisor Info",
            "category": "Advisor Info",
            "values": [""],
            "member": "advToCompaniesFilterFlat.Cianame",
            "filterData": {
                "apiEndPoint" : "/advanced/tocompanies/search",
                "searchType": [{ "Includes any": true, "dataType": "Select" }, {"Includes all": false, "dataType": "Select"}, { "Does not include": false, "dataType": "Select" }, {"Equals": false, "dataType": "Text" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": true,
                "multiple": true
            }
        },
        {
            "id": 42,
            "name": "To Investor",
            "value": "To Investor",
            "isChecked": true,
            "hasMore": false,
            "parent": "Advisor Info",
            "category": "Advisor Info",
            "values": [""],
            "member": "advToInvestorsFilterFlat.cianame",
            "filterData": {
                "apiEndPoint" : "/advanced/toinvestors/search",
                "searchType": [{ "Includes any": true, "dataType": "Select" }, {"Includes all": false, "dataType": "Select"}, { "Does not include": false, "dataType": "Select" }, {"Equals": false, "dataType": "Text" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": true,
                "multiple": true
            }
        },
    ],

    "Exits": [
        {
            "id": 43,
            "name": "Exits Deal Info",
            "value": "Exits Deal Info",
            "isChecked": false,
            "hasMore": true,
            "filterKey": "Exits Deal Info",
            "parent": "Exits",
            "category": "Exits",
            "values": [""],
        }, 
        {
            "id": 44,
            "name": "Returns",
            "value": "Returns",
            "isChecked": false,
            "hasMore": true,
            "filterKey": "Returns",
            "parent": "Exits",
            "category": "Exits",
            "values": [""],
        },
        {
            "id": 45,
            "name": "Exits Valuation Info",
            "value": "Exits Valuation Info",
            "isChecked": false,
            "hasMore": true,
            "filterKey": "Exits Valuation Info",
            "parent": "Exits",
            "category": "Exits",
            "values": [""],
        }, 
        {
            "id": 46,
            "name": "Exits Advisor Info",
            "value": "Exits Advisor Info",
            "isChecked": false,
            "hasMore": true,
            "filterKey": "Exits Advisor Info",
            "parent": "Exits",
            "category": "Exits",
            "values": [""],
        },    
    ],

    "Exits Deal Info": [
        {
            "id": 47,
            "name": "Deal Size ($M)",
            "value": "Deal Size ($M)",
            "isChecked": true,
            "hasMore": false,
            "parent": "Exits Deal Info",
            "category": "Exits Deal Info",
            "values": [""],
            "member": "exit_investment_flat.deal_amount",
            "filterData": {
                "searchType": [{"Range": true, "dataType": "Range"}, {"Greater than or equal to":false, "dataType": "Numbers"}, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false }, { "Does not equal to": false,  "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 48,
            "name": "Deal Period",
            "value": "Deal Period",
            "isChecked": true,
            "hasMore": false,
            "parent": "Exits Deal Info",
            "category": "Exits Deal Info",
            "values": [],
            "member": "exit_investment_flat.deal_date",
            "filterData": {
                "searchType": [{"Before": true, "dataType": "CalenderType" }, {"After": false, "dataType": "CalenderType" }, {"Between": false, "dataType": "MultiCalenderType" }, {"Equals": false, "dataType": "CalenderType" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "numeric",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 49,
            "name": "Exit Status",
            "value": "Exit Status",
            "isChecked": true,
            "hasMore": false,
            "parent": "Exits Deal Info",
            "category": "Exits Deal Info",
            "values": [""],
            "member": "exit_investment_flat.exit_status",
            "filterData": {
                "apiEndPoint" : "/advanced/exits/exitstatus/search",
                "searchType": [{ "Includes any": true, "dataType": "Select" }, { "Does not include": false, "dataType": "Select" }, {"Equals": false, "dataType": "Text" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": true,
                "multiple": true
            }
        },
        {
            "id": 50,
            "name": "Deal Type",
            "value": "Deal Type",
            "isChecked": true,
            "hasMore": false,
            "parent": "Exits Deal Info",
            "category": "Exits Deal Info",
            "values": [""],
            "member": "exit_investment_flat.deal_type",
            "filterData": {
                "apiEndPoint" : "/advanced/exits/dealtype/search",
                "searchType": [{ "Includes any": true, "dataType": "Select" }, {"Includes all": false, "dataType": "Select" }, { "Does not include": false, "dataType": "Select" }, {"Equals": false, "dataType": "Text" }],
                "dataType": "Text",
                "autoSuggest": true,
                "multiple": true
            }
        },
        {
            "id": 51,
            "name": "Type",
            "value": "Type",
            "isChecked": true,
            "hasMore": false,
            "parent": "Exits Deal Info",
            "category": "Exits Deal Info",
            "values": [""],
            "member": "exit_investment_flat.deal_sub_type",
            "filterData": {
                "apiEndPoint" : "/advanced/exits/typesearch",
                "searchType": [{ "Includes any": true, "dataType": "Select" }, { "Does not include": false, "dataType": "Select" }, {"Equals": false, "dataType": "Text" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": true,
                "multiple": true
            }
        },
    ],

    "Returns": [
        {
            "id": 52,
            "name": "Return Multiple",
            "value": "Return Multiple",
            "isChecked": true,
            "hasMore": false,
            "parent": "Returns",
            "category": "Returns",
            "values": [""],
            "member": "manda_investors.MultipleReturn",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true, "dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false, "dataType": "Numbers" }, { "Does not equal to": false,  "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": true,
                "multiple": true
            }
        },
        {
            "id": 53,
            "name": "IRR",
            "value": "IRR",
            "isChecked": true,
            "hasMore": false,
            "parent": "Returns",
            "category": "Returns",
            "values": [""],
            "member": "manda_investors.IRR",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true, "dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false, "dataType": "Numbers" }, { "Does not equal to": false,  "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": true,
                "multiple": true
            }
        },
    ],

    "Exits Valuation Info": [
        {
            "id": 54,
            "name": "Post Money Valuation ",
            "value": "Post Money Valuation ",
            "isChecked": true,
            "hasMore": false,
            "parent": "Exits Valuation Info",
            "category": "Exits Valuation Info",
            "values": [""],
            "member": "exit_investment_flat.company_valuation",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true,"dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false, "dataType": "Numbers" }, { "Does not equal to": false,  "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 55,
            "name": "Revenue Multiple (Post-money)",
            "value": "Revenue Multiple (Post-money)",
            "isChecked": true,
            "hasMore": false,
            "parent": "Exits Valuation Info",
            "category": "Exits Valuation Info",
            "values": [""],
            "member": "exit_investment_flat.revenue_multiple",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true,"dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false,"dataType": "Numbers" }, { "Does not equal to": false,  "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 56,
            "name": "EBITDA Multiple (Post-money)",
            "value": "EBITDA Multiple (Post-money)",
            "isChecked": true,
            "hasMore": false,
            "parent": "Exits Valuation Info",
            "category": "Exits Valuation Info",
            "values": [""],
            "member": "exit_investment_flat.ebitda_multiple",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true,"dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false,"dataType": "Numbers" }, { "Does not equal to": false,  "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 57,
            "name": "PAT Multiple (Post-money)",
            "value": "PAT Multiple (Post-money)",
            "isChecked": true,
            "hasMore": false,
            "parent": "Exits Valuation Info",
            "category": "Exits Valuation Info",
            "values": [""],
            "member": "exit_investment_flat.pat_multiple",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true, "dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false, "dataType": "Numbers" }, { "Does not equal to": false,  "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
    ],

    "Exits Advisor Info": [
        {
            "id": 58,
            "name": "To Company",
            "value": "To Company",
            "isChecked": true,
            "hasMore": false,
            "parent": "Exits Advisor Info",
            "category": "Exits Advisor Info",
            "values": [""],
            "member": "advToExitsFilterFlat.cianame",
            "filterData": {
                "apiEndPoint" : "/advanced/tocompanies/exits/search",
                "searchType": [{ "Includes any": true, "dataType": "Select" }, {"Includes all": false, "dataType": "Select"}, { "Does not include": false, "dataType": "Select" }, {"Equals": false, "dataType": "Text" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": true,
                "multiple": true
            }
        },
        {
            "id": 59,
            "name": "To Investor",
            "value": "To Investor",
            "isChecked": true,
            "hasMore": false,
            "parent": "Exits Advisor Info",
            "category": "Exits Advisor Info",
            "values": [""],
            "member": "advToInvestorsFilterFlat.cianame",
            "filterData": {
                "apiEndPoint" : "/advanced/toexits/investors/search",
                "searchType": [{ "Includes any": true, "dataType": "Select" }, {"Includes all": false, "dataType": "Select"}, { "Does not include": false, "dataType": "Select" }, {"Equals": false, "dataType": "Text" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": true,
                "multiple": true
            }
        },
    ]
};