import React, { useState, useMemo, useEffect, useContext } from "react";
import { useSearchParams, Routes, Route, useParams, NavLink, matchPath, useLocation } from 'react-router-dom';
import { useQuery } from "@apollo/client";
import sortingimg from '../../../images/up-down-arrow.png'
import moment from "moment"; 
import DataTable from '../../../Components/DataTables/DataTable';
import axios from "axios";
import { getRecentNewsUrl } from "../../../Config/config";
import { UserContext } from "../../../Contexts/UserContext";
import routePathSegments from "../../../routeSegments";
import Constants from "../../../Constants";
import { DataFoundContext } from "../../../Contexts/DataFoundContext";

/**
 * The `NewsActivity` component is a React functional component that displays recent news and activity
 * for a specific company.
 * @returns The NewsActivity component is returning a JSX element that represents the component's UI.
 * It includes a div container with a className of "mx-4" and two child elements. The first child
 * element is a div container with classNames and styles for styling the component's header. It
 * includes a paragraph element with text content "Recent News & Activity". The second child element is
 * a DataTable component with props for columns
 */
const NewsActivity = ({  menu }) => {


  // console.log(menu, "news");

  const [newsletter, setNewsletter] = useState([]);
  const { companyId } = useParams();
  const location = useLocation();
  const { getToken } = useContext(UserContext);
  const {newsAndActivityDataFound, setNewsAndActivityDataFound} = useContext(DataFoundContext);
 

/**
 * The function `getNews` makes a POST request to a specified URL with a company ID and type, and sets
 * the response data as the newsletter.
 */
  const getNews = () => {
   

    axios.post(getRecentNewsUrl, {
      "company_id": companyId,
      "type": Constants.primaryMasterIds.reId
    }, {
      headers: {
        'Authorization': getToken(),
      }
    }).then(response => {
      if (response.status === 200 && response.data?.data) {
        setNewsletter(response.data.data);
        setNewsAndActivityDataFound(true)
      } 
      else if(response.message === 'No Data Found'){
        setNewsAndActivityDataFound(false)
      }
    }).catch(error => {
        setNewsAndActivityDataFound(false)
    })
  }

  useEffect(() => { 
    
    getNews();
    return () => {
      setNewsAndActivityDataFound(false)
    }
  }, [companyId])

   
  
/* The `customStringSortMemo` is a memoized function that is used for sorting the data in the DataTable
component. It takes four parameters: `rowA`, `rowB`, `columnId`, and `desc`. */
  const customStringSortMemo = useMemo(() => {
    return (rowA, rowB, columnId, desc) => {
      const defaultVal = desc ? '--' : 'A';
      return (rowA?.values[columnId]?.news ?? defaultVal).localeCompare(rowB?.values[columnId]?.news ?? defaultVal);
    }
  }, []);

 /* The `dealDateSort` constant is a memoized function that is used for sorting the data in the
 DataTable component based on the "Release Date" column. It takes four parameters: `rowA`, `rowB`,
 `columnId`, and `desc`. */
  const dealDateSort = useMemo(() => {
    return (rowA, rowB, columnId, desc) => {
      return moment.utc(rowA?.values[columnId].date).diff(moment.utc(rowB?.values[columnId].date))
    }
  }, [])

/* The `columns` constant is an array of objects that define the columns for the DataTable component.
Each object represents a column and has properties such as `id`, `Header`, `accessor`, `className`,
`headerClassName`, `Cell`, and `sortType`. */
  const columns = useMemo(() => [
    {
      id: "news",
      Header: "News",
      accessor: "news",
      className: "text-[#333333] text-[12pt]  py-4 flex-wrap lg:text-[14px] lg:leading-[16px]",
      headerClassName: "w-[15%]",
      Cell: ({ value }) => {
        return <a href={value.link} target="_blank" rel="noreferrer">
          {value.news}
        </a>
      },
      sortType: customStringSortMemo,
    },
    {
      id: "releaseDate",
      Header: "Release Date",
      accessor: "releaseDate",
      className: "text-[#333333] pr-[3.5rem] py-4 text-right lg:float-right whitespace-nowrap lg:text-[14px] lg:leading-[16px]",
      headerClassName: "text-center float-right flex lg:px-10 px-8 py-3.5",
      Cell: ({ value }) => {
        return moment(value?.date).format('MMM DD, YYYY')
      },
      sortType: dealDateSort,
    }
  ], [])

/* The `rowData` constant is using the `useMemo` hook to memoize the creation of an array of objects.
It iterates over the `newsletter` array and pushes objects into the `rows` array. Each object has
two properties: `news` and `releaseDate`. The `news` property is an object with properties `news`
and `link`, which are extracted from the `newsData` object. The `releaseDate` property is also an
object with properties `date` and `news`, which are extracted from the `newsData` object. Finally,
the `rows` array is returned. The `useMemo` hook ensures that the `rowData` constant is only
recalculated when the `newsletter` array changes. */
  const rowData = useMemo(() => {
    const rows = [];

    if (newsletter) {
      newsletter?.forEach(newsData => {
        rows.push({
          news: { news: newsData?.news, link: newsData?.link },
          releaseDate: { date: newsData?.release_date, news: newsData?.news }
        })
      });
    }

    return rows;

  }, [newsletter])

/* The `initialSort` constant is using the `useMemo` hook to memoize the creation of an array with a
single object. The object represents the initial sorting configuration for the DataTable component. */
  const initialSort = useMemo(() => [
    {
      id: "releaseDate", 
      desc: true
    }
  ], []);
  return (<>
    {newsAndActivityDataFound ? (
      <div className="mx-4 ">
        <div className="bg-white border mb-[-1.75rem] overflow-hidden sm:rounded-lg shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
          <div className="pt-4  px-5">
            <p className="text-[14pt] leading-[19pt] lg:text-[#333333] lg:text-[17px] font-semibold  lg:leading-[22px] mb-3 ">
              Recent News & Activity
            </p>
          </div>
          <DataTable columns={columns} data={rowData}
            sortBy={initialSort}
          />
        </div>
        <br />
        <br />
      </div>
    ) : ("")}
    </>
  );
};

export default NewsActivity;
