import React, { useState, useEffect, useContext } from "react"
import {useLocation} from 'react-router-dom';
import MonthPickerComponent from '../../../Utils/MonthPickerComponent'
import xls from "../../../images/xls/png/Group 47.png"
import SavedFilterContent from "../../../Components/SavedFilter";
import ExportPopup from "../../../Components/ExportPopup/FundsExcel";
import { fundsExportData } from "../../../Components/ExportPopup/ExportData"
import { exportReFundsExcel } from "../../../Config/config";
import Constants from "../../../Constants";
import { PeFilterContext } from "../../../Contexts/PeFilterContext";
import { UserContext } from "../../../Contexts/UserContext";
import line from "../../../images/line.png";
import { FaFilter } from "react-icons/fa";
import { IoClose  } from "react-icons/io5";
/**
 * The MainMenu function is a React component that renders a menu with various options and buttons
 * based on the props and context values provided.
 * @param props - The `props` parameter is an object that contains the properties passed to the
 * `MainMenu` component. These properties can be accessed using dot notation, such as `props.active`,
 * `props.groupId`, etc.
 * @returns The function `MainMenu` returns JSX elements.
 */
function MainMenu(props) {

    const { save, showMonthPicker,refineSearchFilters ,groupId, isMobile,setOpen,open,setIsMobile} = useContext(PeFilterContext);
    const [openTab, setOpenTab] = React.useState(1);
    const [showPopup, setShowPopup] = useState(false);
    const {isTrialExport,isStudent} = useContext(UserContext)
    /* The `useEffect` hook is used to perform side effects in a functional component. In this case,
    the `useEffect` hook is used to update the value of `openTab` whenever the `props.active` value
    changes. */
    useEffect(() => {
        setOpenTab(props.active)
    }, [openTab])
    useEffect(()=>{
        if (window.innerWidth < 800) {
            setOpen(true);
            setIsMobile(true);
        }    },[])

    const {pathname}= useLocation()

    const isFromGlobalSearch = ()=> refineSearchFilters[pathname]?.globalSearch?.length > 0 ? true : false

    return (
        <div className="pt-0 pl-3 pr-4">

            <div className="scrollbar  rounded">
                <div className=" py-2 rounded-b-none bg-white block lg:flex flex-row justify-end shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
                    
                     
                {groupId?.myList?.length >0 ?<></>  :  
                <div className={"justify-between items-center px-2 py-2 space-x-4 "+(showMonthPicker!=0 ? 'md:flex':'flex')}>
                        <label htmlFor="" className="text-[#666666]">
                        <div className={showMonthPicker!=0 ?"":'flex'}>
                                <MonthPickerComponent />
                            </div>
                        </label>
                        <div className="justify-between items-center flex w-[25%] flex-row">
                        {isMobile && (
                            <>
                                <button
                                className="cursor-pointer lg:ml-7 ml-4 mt-2"
                                onClick={() => setOpen(!open)}
                                >
                                <FaFilter color="#bc8b20" size={21} />
                                {/* {!open && <IoClose color="#bc8b20" size={25} className="fixed z-50 top-16 right-[39vw] mb-2" />} */}
                                </button>
                                <a>
                                <img
                                    className="h-[24px] max-w-[2px] mt-1 "
                                    src={line}
                                />
                                </a>
                            </>
                            )}
                        <div className={showMonthPicker!=0 ?"hidden md:flex space-x-3":"flex space-x-3"}>   
                        {isTrialExport == 'false' || isStudent == 'true' ?<></>  : 
                            <>
                                <button>
                              <img
                                 src={xls}
                                className="max-h-[24px] mt-1 max-w-[21px]"
                                alt=""
                                onClick={() => setShowPopup(!showPopup)}
                              />
                              {showPopup && (
                                <ExportPopup 
                                modules="re-funds"
                                module_type="re"
                                // category="funds"
                                fileName={Constants.exportNames.re_funds}
                                url={exportReFundsExcel}
                                onClose={() => setShowPopup(false)} 
                                exportOptions={fundsExportData}
                                // investor_id ={[reinvestorId]}
                                 />
                              
                              )}
                            </button>
                            
                            </>
                            }                    
                        </div>
                        </div>

                </div>
                }
                </div>
                {isFromGlobalSearch() ? <></>  : save ? <SavedFilterContent data={save} /> : ''}
            </div>
        </div>
    )
}

export default MainMenu