import React, { useState } from "react";
import { logoUrl } from "../Config/config";
import RandomLogo from "./RandomLogo";

const NewLogo = (props) => {

    const [IsError, setIsError] = useState(false);

    if (IsError) {
      return <RandomLogo name={props.name} page={props.page} />;
    }
    
    let logo;

    if(props?.menu == 'cfs'){
        logo = `${logoUrl}/cprofile/${props?.id}.png`;
    }else if(props?.menu == 're'){
      logo = `${logoUrl}/recompany/${props?.id}.png`;
    }else logo = `${logoUrl}/pecompany/${props?.id}.png`;

  return (
    <>
      {props?.page === "detail page" ? (  
        <div className="h-[4rem] w-[4rem] p-[0.2rem] rounded-md bg-white flex items-center justify-center">
          <img
            alt=""
            onError={(e) => setIsError(true)}
            className="max-w-full max-h-full"
            src={logo}
          />
        </div>
        ) : (
          <div  style={{ display:"-webkit-inline-box", float:"left"}}>  
            <img 
              alt="" 
              onError={(e)=>setIsError(true)} 
              className="border-solid border-2 rounded-full mr-2 w-[32px] h-[32px] cursor-pointer" 
              src={logo}  
            />
          </div>
        )  
      }
    </>
  );
};

export default NewLogo;
