import { Select } from 'antd';
import React from 'react';
import { SteppedLineTo } from 'react-lineto';
const { Option } = Select;


/**
 * The CalenderComponent function is a React component that renders a calendar component with dropdown
 * menus for selecting a month and year.
 * @returns The CalenderComponent is being returned.
 */
const CalenderComponent = ({ child, years, months, selectedChild, setSelectedChild }) => {

  const childIndex = child.uniqueId;

  const lineColor = "#BDBDBD";

  console.log(child.values,"mjklmjklo");


   /**
    * The handleChangeMonth function updates the month value of a selected child in an array of child
    * objects.
    * @param event - The event parameter is the event object that is passed when the handleChangeMonth
    * function is called. It typically contains information about the event that triggered the
    * function, such as the target element or the value of an input field. In this case, it seems that
    * the event parameter is expected to contain the selected month
    */
    const handleChangeMonth = (event) => {
        const month = event;
    
        setSelectedChild((prevSelectedChild) =>
          prevSelectedChild.map((row, index) => {
            if (row.id === child.id && row.uniqueId === childIndex) {
              return {
                ...row,
                values: [month, child.values[1]] // Keep start year unchanged, update end year value
              };
            }
            return row;
          })
        );
      };


/**
 * The handleChangeYear function updates the start year value of a selected child in an array of child
 * objects.
 * @param event - The event parameter is the event object that is passed when the handleChangeYear
 * function is called. It contains information about the event that triggered the function, such as the
 * target element and the value of the element.
 */
  const handleChangeYear = (event) => {
    const year = event;
    // alert(event)

    setSelectedChild((prevSelectedChild, index) =>
      prevSelectedChild.map((row) => {
        if (row.id === child.id && row.uniqueId === childIndex) {
          return {
            ...row,
            values: [child.values[0], year] // Update start year value, keep end year unchanged
          };
        }
        return row;
      })
    );
  };


  

  return (
    <div className='flex space-x-2'>
      <div className={`singleSearch fromMonth${childIndex}`}>
      <SteppedLineTo within='query-builder' borderColor={lineColor} delay={100} 
       borderWidth={5} from={`fromMonth${childIndex}`} to={`toMonth${childIndex}`} orientation={"h"} />
    <Select
    value={child.values[0]}
    key={childIndex}
    showSearch
    placeholder="Select Month"
      name={child.id}
      onChange={handleChangeMonth}
      className="rounded focus:outline-none w-[7rem]"
    >
      {months.map((month, i) => (
        <Option key={month} value={i+1}>
          {month}
        </Option>
      ))}
    </Select>
    </div>
    <div className={`singleSearch toMonth${childIndex}`}>
    <Select
    value={child.values[1]}
    key={childIndex}
    showSearch
    placeholder="Select Year"
      name={child.id}
      onChange={handleChangeYear}
      className="rounded focus:outline-none w-[7rem]"
    >
      {years.map((year) => (
        <Option key={year} value={year}>
          {year}
        </Option>
      ))}
    </Select>
    </div>
    </div>
  );
};

export default CalenderComponent;
