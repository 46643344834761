import { React, useState, useMemo, useContext } from 'react'
import Table from './Table'
import { TableHeaderContext } from '../../Contexts/TableHeaderContext'

/**
 * The TableComponent function is a React component that renders a table based on the value of the
 * props.menu and displays the columns based on the value of InvestorLimitedPartner from the
 * TableHeaderContext.
 * @returns The code is returning a JSX element. It is rendering a `<div>` element with the class name
 * "pl-3 pr-2 lg:pr-2 md:pr-3 overflow-y-auto". Inside the `<div>`, there is a nested `<div>` with the
 * class name "App lg:relative". Inside this nested `<div>`, there is a `<Table>` component being
 * rendered.
 */
const TableComponent = (props) => {
  /* The code is using the `useContext` hook to access the value of `InvestorLimitedPartner` from the
  `TableHeaderContext`. The `TableHeaderContext` is a context object that provides the value of
  `InvestorLimitedPartner` to its child components. By using `useContext(TableHeaderContext)`, the
  code is able to retrieve the value of `InvestorLimitedPartner` and assign it to the constant
  `InvestorLimitedPartner`. */
  const {  
          InvestorLimitedPartner
        }
   = useContext(TableHeaderContext);

    const [investments, setInvestments] = useState([]);
    const [pagination, setPagination] = useState()

    const tableRowData = useMemo(() => investments, [investments]);  
    const paginationData = useMemo(() => pagination, pagination);   
  
    const columns = props.menu == "inv_l_partner" || 
                    props.menu == "inv_l_partner_vc" || 
                    props.menu == "inv_l_partner_exit" ||
                    props.menu == "exit-ma-pe-vc" ||
                    props.menu == "exit-ma-vc" || 
                    props.menu == "exit-pm-pe-vc" || 
                    props.menu == "exit-pm-vc"? InvestorLimitedPartner
          : ""
    return (
        <div className="pl-3 pr-2 lg:pr-2 md:pr-3 overflow-y-auto">
            {console.log('props?.menu',props?.menu)}
            <div className="App lg:relative">
            <Table menu={props?.menu} columns={columns} pagination = {paginationData} sub_type_master_id={props?.sub_type_master_id}/>
            </div>
        </div>
    )
}
export default TableComponent