import React from 'react'

/**
 * The `OutlineButton` renders a button with a specific styling and
 * behavior.
 * @returns a button element with the specified properties and children.
 */
function OutlineButton({ children, onClick }) {
    return (
        <button
            onClick={onClick}
            type="button"
            className="inline-flex w-full justify-center font-sans_bold_body rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
            {children}
        </button>
    )
}

export default OutlineButton