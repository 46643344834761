import React, { useContext, useEffect } from 'react'
import { PeFilterContext } from '../../Contexts/PeFilterContext'

/**
 * The YearFoundedFilter function is a React component that displays a filter for selecting the start
 * and end years of a company's founding.
 * @returns The YearFoundedFilter component is returning a JSX element.
 */
function YearFoundedFilter({
    EndYearOptions,
    setEndYearOptions,
    StartYearOptions,
    setStartYearOptions,
    customPathname }) {

    const { YearFounded, refineSearchFilters } = useContext(PeFilterContext);

    useEffect(() => {
        const start = refineSearchFilters[customPathname]?.yearFounded?.at(0) ?? null;
        const end = refineSearchFilters[customPathname]?.yearFounded?.at(1) ?? null;
        setStartYearOptions(YearFounded?.map(year => ({ ...year, isChecked: start?.name == year.name })))
        setEndYearOptions(YearFounded?.map(year => ({ ...year, isChecked: end?.name == year.name })))
    }, [YearFounded, customPathname, refineSearchFilters, setEndYearOptions, setStartYearOptions])

    const selectedEndYear = EndYearOptions?.filter(option => option.isChecked)?.at(0)
    const selectedStartYear = StartYearOptions?.filter(option => option.isChecked)?.at(0)


    // Start Founded year
    const handleStartYearFoundedChange = (e) => {
        // if (parseInt(e.target.value) > parseInt(selectedEndYear?.name ?? 0)) {
        //     setEndYearOptions(EndYearOptions?.map(option => ({ ...option, isChecked: e.target.value == option.name })))
        // }
        setStartYearOptions(StartYearOptions?.map(option => ({ ...option, isChecked: e.target.value == option.name })))
    }

    // End Founded year 
    const handleEndYearFoundedChange = (e) => {
        if (parseInt(e.target.value) < parseInt(selectedStartYear?.name ?? 0)) {
            // setStartYearFounded(e.target.value)
            alert('Year Before is not lesser than year After')
        } else {
            setEndYearOptions(EndYearOptions?.map(option => ({ ...option, isChecked: e.target.value == option.name })))
        }
    }

    return (
        <div className="border-b-[3px] border-[#E8E6E1]">
            <li
                className="relative px-3 py-2"
            >
                <p className="text-[14px] tracking-[-.3px]">Year Founded</p>
                <div className="flex mt-2">
                    <select value={selectedStartYear?.name ?? ""} name="" id="" onChange={handleStartYearFoundedChange}
                        className=" border border-[#D9BD7F] mr-3 rounded focus:outline-none bg-[#FFFFFF] lg:w-[5rem] w-[9.5rem] md:w-[16rem] lg:h-[1.6rem]">
                        <option value="" selected></option>
                        {StartYearOptions && StartYearOptions.length > 0 ? (
                            StartYearOptions.slice(0).reverse().map((year, index) => (
                                <option key={index} value={year.name ? year.name : ''}>{year.name ? year.name : ''}</option>
                            )))
                            :
                            (
                                <h1>No results found!</h1>
                            )}
                    </select>
                    <p className="text-sm mr-3">To</p>
                    <select value={selectedEndYear?.name ?? ""} name="" id="" onChange={handleEndYearFoundedChange}
                        className=" border border-[#D9BD7F] mr-3 rounded focus:outline-none bg-[#FFFFFF] lg:w-[5rem] w-[9.5rem] md:w-[16rem] lg:h-[1.6rem]">
                        <option value="" selected></option>
                        {EndYearOptions && EndYearOptions.length > 0 ? (
                            EndYearOptions.slice(0).reverse().map((year, index) => (
                                <option key={index} value={year.name ? year.name : ''}>{year.name ? year.name : ''}</option>
                            )))
                            :
                            (
                                <h1>No results found!</h1>
                            )}
                    </select>
                </div>

            </li>
        </div>
    )
}

export default YearFoundedFilter