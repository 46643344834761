import React, {useState, useMemo} from 'react'
import { useQuery } from '@apollo/client'
import pe_Icon from '../../../images/noun-investment-2180740_4/png/noun-investment-2180740.png';

/**
 * The function Incubated_Cos returns a React component that displays information about incubated
 * companies.
 * @returns JSX code, which represents a section of HTML elements.
 */
function Incubated_Cos() {
    return (
        <div className="flex-shrink-0">
            {/* ----card1----- */}
            <section >
                <div className="bg-white px-3 py-1 rounded-md  border border-gray-200 overflow-hidden card-animate">
                    <div className="grid grid-cols-6 ">
                        <div className="col-span-5 ">
                            <div className="text-[13px] leading-[17px] font-sans_book_body tracking-[-0.3px]">Incubated Cos.</div>
                            {/* {PeVcCardData? */}
                            <div className="flex space-x-1  tracking-[-0.3px]">
                                <div className="  ">
                                    <span
                                        className=' font-sans_bold_body  text-[17px]  leading-[23px] text-[#A5711A] '>1500 </span>
                                </div>
                            </div>
                        </div>
                        <div className="flex-shrink-0 flex items-center justify-center">
                            <a href="#">
                                <img className="h-[22px] max-w-[25px]" fill="currentColor"
                                    src={pe_Icon} />
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Incubated_Cos
