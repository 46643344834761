import { React, useState, useMemo, useContext } from 'react'
import Table from './Table'
import { TableHeaderContext } from '../../Contexts/TableHeaderContext'



/**
 * The TableComponent function renders a table based on the props passed to it, including the table
 * headers and data.
 * @returns The TableComponent is being returned.
 */
const TableComponent = (props) => {
    console.log(props.menu,"ll")
  const { InvestorPEVCTableHeader,
          InvestorVCTableHeader, 
          InvestorPEVCExitTableHeader, 
          InvestorPEVCExitMATableHeader, 
          InvestorPEVCExitPMTableHeader,
          InvestorVCExitMATableHeader, 
          InvestorVCExitPMTableHeader, 
          InvestorPEVCBackedIposTableHeader,
          InvestorVCBackedIpos , 
          InvestorAngelInvestmentsTableHeader, 
          InvestorIncubationTableHeader,
          InvestorLimitedPartner,
          investororderingColumn,
          incubatorOrderingColumn,
          investorPEVCExitMAorderingColumn,

          orderByInvestor,
          OrderByInvestorVCTableHeader,
          orderByInvestorExits,
          OrderByInvestorPEVCExitMATableHeader,
          OrderByInvestorPEVCExitPMTableHeader,
          OrderByInvestorVCExitMATableHeader ,
          OrderByInvestorVCExitPMTableHeader ,
          OrderByInvestorPEVCBackedIposTableHeader ,
          OrderByInvestorVCBackedIposTableHeader ,
          OrderByInvestorAngelInvestmentsTableHeader,
          OrderByInvestorIncubationTableHeader

        }
   = useContext(TableHeaderContext);
    const [investments, setInvestments] = useState([]);
    const [pagination, setPagination] = useState()

    const tableRowData = useMemo(() => investments, [investments]);  
    const paginationData = useMemo(() => pagination, pagination);   
    console.log(paginationData,"paginatorInfo")   
 
    // const order = props.menu == "investor" ? orderByInvestor
    //       :props.menu == "vcinvestor" ? OrderByInvestorVCTableHeader
    //       :props.menu == "pevcExit" ? orderByInvestorExits
    //       :props.menu == "maPeExit" ? OrderByInvestorPEVCExitMATableHeader
    //       :props.menu == "public_pevc" ? OrderByInvestorPEVCExitPMTableHeader 
    //       :props.menu == "ma_vc" ? OrderByInvestorVCExitMATableHeader 
    //       :props.menu == "public_vc" ? OrderByInvestorVCExitPMTableHeader 
    //       :props.menu == "backedipo" ? OrderByInvestorPEVCBackedIposTableHeader 
    //       :props.menu == "vcbackedipo" ? OrderByInvestorVCBackedIposTableHeader 
    //       :props.menu == "angel" ? OrderByInvestorAngelInvestmentsTableHeader
    //       :props.menu == "incubation" ? OrderByInvestorIncubationTableHeader
    //       :props.submenu == "inv_l_partner"? InvestorLimitedPartner
    //       : InvestorPEVCTableHeader

    const columns = props.menu == "investor" ? InvestorPEVCTableHeader
          :props.menu == "vcinvestor" ? InvestorVCTableHeader
          :props.menu == "pevcExit" ? InvestorPEVCExitTableHeader
          :props.menu == "maPeExit" ? InvestorPEVCExitMATableHeader
          :props.menu == "public_pevc" ? InvestorPEVCExitPMTableHeader 
          :props.menu == "ma_vc" ? InvestorVCExitMATableHeader 
          :props.menu == "public_vc" ? InvestorVCExitPMTableHeader 
          :props.menu == "backedipo" ? InvestorPEVCBackedIposTableHeader 
          :props.menu == "vcbackedipo" ? InvestorVCBackedIpos 
          :props.menu == "angel" ? InvestorAngelInvestmentsTableHeader
          :props.menu == "angel-vc" ? InvestorAngelInvestmentsTableHeader
          :props.menu == "incubation" ? InvestorIncubationTableHeader
          :props.menu == "incubation-vc" ? InvestorIncubationTableHeader
          :props.submenu == "inv_l_partner"? InvestorLimitedPartner
          :props.menu == "exits_vc"? InvestorPEVCExitTableHeader
          : InvestorPEVCTableHeader
    return (
        <div className="pl-3 pr-2 lg:pr-2 md:pr-3 overflow-y-auto">
            {/* {console.log('investments',tableRowData)} */}
            <div className="App lg:relative">
            <Table sub_type_master_id={props?.sub_type_master_id} menu={props?.menu}  orderBy={props.menu == "incubation" || props.menu === "incubation-vc" 
            ? incubatorOrderingColumn : props.menu === "pevcExit" ||props.menu == "exits_vc"  ? 
            orderByInvestorExits :props.menu==="maPeExit" || props.menu==="ma_vc" ? 
            OrderByInvestorPEVCExitMATableHeader :props.menu ==="public_pevc" ||props.menu == "public_vc" ?
            OrderByInvestorPEVCExitPMTableHeader :
            props.menu==="angel" || props.menu == "angel-vc" ? OrderByInvestorAngelInvestmentsTableHeader: investororderingColumn} 
             order= {props.menu=="backedipo" || props.menu == "investor" || props.menu == "vcinvestor" || props.menu == "vcbackedipo" ||
            props.menu == "incubation-vc"|| props.menu == "incubation" || props.menu ==="investor" ?"asc":"desc"} columns={columns} pagination = {paginationData}/>
            </div>
        </div>
    )
}
export default TableComponent