import { Badge } from 'antd';
import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router';
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import MultiSelectEntity from '../RefineSearch/MultiSelectEntity'
import FilterWrapper from './FilterWrapper';

/**
 * The `IndustryFilter` function is a React component that renders a filter for selecting industries,
 * with options that can be expanded or collapsed.
 * @returns a JSX element.
 */
function IndustryFilter({ open, accordionClick, IndustriesOptions, setIndustriesOptions, customPathname,label="Industry" }) {

    const { Industries, refineSearchFilters } = useContext(PeFilterContext);

    // useEffect(() => {
    //     setIndustriesOptions(Industries)
    // }, [Industries])

    useEffect(() => {
        const industryIds = refineSearchFilters[customPathname]?.industry?.map(value => value.id) ?? [];
        setIndustriesOptions(Industries?.map(industry => ({ ...industry, isChecked: industryIds.includes(industry.id) })))
    }, [Industries, customPathname, refineSearchFilters, setIndustriesOptions])



    const handleIndustryChange = ((e) => {
        const { name, checked, id } = e.target;
        let currentIndustry = [];

        if (name === "allSelect") {
            currentIndustry = IndustriesOptions.map((industry) =>
                ({ ...industry, isChecked: checked })
            );
            setIndustriesOptions(currentIndustry);
        } else {
            currentIndustry = IndustriesOptions.map((industry) =>
                industry.id === parseInt(id) ? { ...industry, isChecked: checked } : industry
            );
            setIndustriesOptions(currentIndustry);
        }
    });

    const selectedIndustryLength = IndustriesOptions?.filter(option => option.isChecked)?.length ?? 0


    return (
        <div className="border-b-[3px] border-[#E8E6E1]">
            <li className={open ? "bg-white relative px-3 py-1" : "relative px-3 py-1"}>
                <FilterWrapper
                    count={selectedIndustryLength}
                    isSelect={false}
                    label={label}
                    onClick={accordionClick}
                    open={open}
                />
                <div
                    className="relative overflow-hidden transition-all max-h-0 duration-700  overflow-y-auto no-scrollbar"
                    style={{ maxHeight: open ? "10rem" : "" }}
                >
                    {open === true ? <MultiSelectEntity
                        handleEntityChange={handleIndustryChange}
                        primaryEntity={IndustriesOptions}
                    /> : <></>}
                </div>
            </li>
        </div>
    )
}

export default IndustryFilter