import React from 'react'
import moment from 'moment'
import { newsLinkLimit } from "../../Config/config";
import MappleToolTip from "reactjs-mappletooltip";


/**
 * The `DealInfo` component renders a table with information about a deal, including the date,
 * incubator, status, angel/VC funding status, and news link.
 * @returns The DealInfo component is returning a JSX element, which represents the structure and
 * content of the component's output in the DOM.
 */
const DealInfo = (props) => {
    const newsLimit = newsLinkLimit
    const limit = (string ='', newsLimit =0) => {
      return string.substring(0, newsLimit)
    }
  
    const newlink = limit(props?.data?.investment?.Link, newsLimit)
    return (
        <div className="px-5 ">
            <p
                className=" text-[17px] text-[#333333]  font-sans_semi_bold leading-[21px]">
                Deal Info</p>
            <div
                className="overflow-x-auto scrollbar">
                 <table
                    className=" w-full  ">
                    <tr
                        className="border-b-[#E5E5E5]  font-sans_book_body space-x-6">
                        <td
                            className="py-2 leading-[21px]">
                            <p className="text-[14px] text-[#333333] whitespace-nowrap cursor-auto"
                                readonly>
                                 Date
                            </p>
                            <p className="text-[14px] text-[#333333] whitespace-nowrap cursor-auto font-semibold "
                                readonly>
                                
                                 Feb 2018
                            </p>
                        </td>
                        <td
                            className="py-2 pl-5 leading-[21px]">
                            <p className="text-[14px] text-[#333333] whitespace-nowrap cursor-auto"
                                readonly>
                                 Incubator</p>
                            <p className="text-[14px] text-[#333333] whitespace-nowrap cursor-auto font-semibold "
                                readonly>
                                 9Unicorns
                            </p>
                        </td>
                        <td
                            className="py-2 pl-5 leading-[21px]">
                            <p className="text-[14px] text-[#333333] whitespace-nowrap cursor-auto"
                                readonly>
                            Status
                                
                            </p>
                            <p className="text-[14px] text-[#333333] whitespace-nowrap cursor-auto font-semibold "
                                readonly>
                                 Graduated
                            </p>
                        </td>
                     <td
                            className="py-2 pl-5 leading-[21px]">
                            <p className="text-[14px] text-[#333333] whitespace-nowrap cursor-auto"
                                readonly>
                            Angel/VC Funded
                                
                            </p>
                            <p className="text-[14px] text-[#333333] whitespace-nowrap cursor-auto font-semibold "
                                readonly>
                                 Yes
                            </p>
                        </td>
                     <td
                            className="py-2 pl-5 leading-[21px]">
                            <p className="text-[14px] text-[#333333] whitespace-nowrap cursor-auto"
                                readonly>
                            News link
                                
                            </p>
                            <p className="text-[14px] text-[#333333] whitespace-nowrap cursor-auto font-semibold "
                                readonly>
                                 --
                            </p>
                        </td>
                    </tr>
                    
                   

                </table>
                
            </div>
        </div>
    )
}

export default DealInfo
