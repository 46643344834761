import React, { useState, useContext, useEffect } from "react";
import MainMenu from '../../Pages/RE/ReMainMenu'
import Card from '../../Components/RE/ReListCard/Card'
import FilterTag from "../../Components/Tag";
import Table from '../../Components/RE/TableComponent'
import RefineSearch from '../../Components/RefineSearchComponent'
import ReLayout from '../../Components/ReLayout'
import Constants from '../../Constants'
import SavedFilterContent from "../../Components/SavedFilter";
import MonthPickerComponent from "../../Utils/MonthPickerComponent";
import xls from "../../images/xls/png/Group 47.png";
import line from "../../images/line.png";
import FileIcon from "../../images/file.png";
import FileIconBlur from "../../images/fileblur.png";
import { useLocation } from "react-router";
import { PeFilterContext } from "../../Contexts/PeFilterContext";
import ReBackedIpoRefineSearch from '../../Components/RefineSearch/ReBackedIpoRefineSearch'
import { reBackedIpoExportData } from '../../Components/ExportPopup/ExportData'
import { reExportPDF } from "../../Config/config";
import ExportPopup from "../../Components/ExportPopup";
import { UserContext } from "../../Contexts/UserContext";
import ExcelDownloader from "../../Components/shared/ExcelDownloader";
import { TableDataContext } from "../../Contexts/TableDataContext";

/**
 * The `REBackedIpo` function is a React component that renders a page for displaying RE-backed IPOs
 * with filters and a table.
 * @param props - The `props` parameter is an object that contains the properties passed to the
 * `REBackedIpo` component. These properties can be accessed using dot notation, such as
 * `props.active`, `props.isStudent`, etc.
 * @returns a JSX element.
 */
function REBackedIpo(props) {
    const [showPopup, setShowPopup] = useState(false);

    const {save, setSave,refineSearchFilters,groupId} = useContext(PeFilterContext);
    const {globalSearchResult} = useContext(TableDataContext);
    const [openTab, setOpenTab] = React.useState(1);
    const savedList = () => {
        setSave(!save)
    }
    useEffect(() => {
        setOpenTab(props.active)
    }, [openTab])

    const {isStudent,isTrialExport} = useContext(UserContext)
    const location = useLocation();
    const {pathname} = useLocation();
    const isFromGlobalSearch = ()=> refineSearchFilters[pathname]?.globalSearch?.length > 0 ? true : false

    return (
        <>
            <ReLayout>
                <div className="flex min-h-screen h-screen flex-col">
                <Card />
                    <div className="flex min-h-0 flex-1 overflow-hidden">                        
                        <main className={`min-w-0 flex-1 md:border-t border-gray-200 flex ${isFromGlobalSearch() ? 'mt-[4.25rem] md:mt-[3rem]':''}`}>
                            <section className="flex h-full min-w-0 flex-1 flex-col lg:order-last bg-[#EDEDED]">
                                <FilterTag />
                                <div className="pl-3 pr-4 lg:pr-4 md:pr-[0.7rem]">
                                    <div className="scrollbar ">
                                        <div className="px-2  md:px-3 rounded-[6px] rounded-b-none bg-white block lg:flex flex-row justify-between  ">

                                            <MainMenu active={4} menu="re" subMenu="re-ipos" primaryTypeMasterId={Constants.primaryMasterIds.reId}
                                                subTypeMasterId={Constants.primaryMasterSubIds.re_backed_ipos_id}
                                                typeId={Constants.moduleIds.companiesId} />
                                            <div className="flex justify-between items-center px-5 lg:px-2 py-2">
                                                {!isFromGlobalSearch() && <label htmlFor="" className="text-[#666666] w-[45%] ">
                                                    <div className="flex">
                                                        <MonthPickerComponent />
                                                    </div>
                                                </label>}
                                                <div className="flex items-center">
                                                    {isStudent == 'true' || groupId?.myList?.length >0? <></> : <>
                                                    <a className=" px-4">
                                                            <button>
                                                            {
                                                        isTrialExport=='false' ?
                                                            <ExcelDownloader
                                                            url={reExportPDF}
                                                            fileName={Constants.exportNames.re_company_ipo}
                                                            data={{"type" : "re-ipo"}}  
                                                            children={<img
                                                                src={xls}
                                                                className="max-h-[24px] mt-1 max-w-[21px]"
                                                                alt=""
                                                            />}                   
                                                            />
                                                            :

                                                            <img
                                                            src={xls}
                                                            className="h-[24px] mt-1 w-[21px]"
                                                            alt=""
                                                            onClick={() => setShowPopup(!showPopup)}
                                                        />
                                                        }
                                                                
                                                                {showPopup && (
                                                                    <ExportPopup
                                                                        modules="re-company"
                                                                        fileName={Constants.exportNames.re_company_ipo}
                                                                        url={reExportPDF}
                                                                        onClose={() => setShowPopup(false)}
                                                                        exportOptions={reBackedIpoExportData}
                                                                        type="re-ipo"
                                                                    />

                                                                )}
                                                            </button>
                                                    </a>
                                                  <a><img className="h-[24px] w-[2px] mt-1 " src={line} /></a>
                                                    </>}

                                                    {groupId?.myList?.length >0 ?<></>:<a className="pl-4 group cursor-pointer">
                                                        <div className="cursor-pointer" onClick={savedList}>
                                                            <img src={save ? FileIcon : FileIconBlur}
                                                                className="h-[24px]  w-[21px]"
                                                                alt="" />
                                                        </div>
                                                    </a> }</div>
                                            </div>
                                            
                                        </div>
                                        {save ? <SavedFilterContent
                                            primaryTypeMasterId={Constants.primaryMasterIds.reId}
                                            subTypeMasterId={Constants.primaryMasterSubIds.re_backed_ipos_id}
                                            typeId={Constants.moduleIds.companiesId}
                                            show={save} /> : ''}
                                    </div>
                                </div>
                                <div className='overflow-y-auto'>
                                    <Table menu="re-ipo" sub_type_master_id={Constants.subCategoryIds.re_backed_ipos_id}/>
                                </div>
                            </section>
                            {/* Secondary column (hidden on smaller screens) */}
                            <aside className="order-first block flex-shrink-0">
                                <div className="relative flex h-full  flex-col border-r border-gray-200 bg-[#FAF5EA]">
                                    {/* Your content */}
                                    <RefineSearch >
                                        <ReBackedIpoRefineSearch customPathname={location.pathname}/>
                                    </RefineSearch>
                                </div>
                            </aside>
                        </main>
                    </div >
                    <br />

                </div >
                {/* </Layout> */}
            </ReLayout >
        </>
    )
}

export default REBackedIpo
