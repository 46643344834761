import React, { useState, useEffect } from 'react';
import { TagsInput } from "react-tag-input-component";


/* The `KeyCodes` object is defining key codes for the comma and enter keys. In this case,
`KeyCodes.comma` is set to `188` and `KeyCodes.enter` is set to `13`. These key codes are used as
delimiters in the `delimiters` array, which is later used in the `TagsInput` component to determine
when to add a new tag. */
const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

/**
 * The `TagComponent` function is a React component that renders a TagsInput component and handles the
 * selection and updating of tags.
 * @returns The component is returning a `<div>` element with a class name of 'company_tag_input'.
 * Inside the `<div>`, there is a `<TagsInput>` component.
 */
const TagComponent = ({ child, selectedChild, setSelectedChild,  selected, setSelected, data }) => {
    
     const childIndex = child.uniqueId;

    const [selectedd, setSelectedd] = useState(data);
    
    useEffect(() => {
      setSelectedd(data);
      setSelectedChild((prevSelectedChild) =>
        prevSelectedChild.map((prevChild) => {
          if (prevChild.id === child.id && prevChild.uniqueId == childIndex) {
            return { ...prevChild, values: data };
          }
          return prevChild;
        })
      );
      setSelected(data);
    },[data]);

   /**
    * The handleChange function updates the selected values for a specific child object in an array and
    * also updates the overall selected values.
    * @param selectedValues - The `selectedValues` parameter is an array that contains the values that
    * have been selected or changed by the user.
    */
    const handleChange = (selectedValues) => {
      setSelectedChild((prevSelectedChild) =>
        prevSelectedChild.map((prevChild) => {
          if (prevChild.id === child.id && prevChild.uniqueId == childIndex) {
            return { ...prevChild, values: selectedValues };
          }
          return prevChild;
        })
      );
      setSelected(selectedValues)
    };

    return (
      <div className='company_tag_input'>
      <TagsInput
          name={child.name}
          id={child.id}
          className="text-[#333333] AdvanceFilterSearch"
          value={selectedd}
          onChange={handleChange}
          bordered={false}
      />
      </div>
    );
        
}

export default TagComponent;
