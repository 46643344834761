import React from "react";
// import { useNavigate } from 'react-router-dom';
import mindTreeLogo from "../../images/mindtree-logo copy/png/mindtree-logo copy.png";
// import Pusher from 'pusher-js';
// import { UserContext } from '../../../src/Contexts/UserContext';
// import { getNotification, markAsReadNotification } from '../../../src/Config/config'
// import Constants from '../../../src/Constants';
// import { getDetailPageUrl } from "../../Utils/utils";

/* The above code is defining a JavaScript function called "Alert" that takes in a parameter called
"props". */
const Alert = (props) => {
  // const [activeNotify, setactiveNotify] = useState(1);
  // const { getToken} = useContext(UserContext);

  // const Navigate = Navigate();

  // const navigate = useNavigate();


  // function handleNotificationClick(entity) {

  //   handleNotificationClick(props.handleNotificationClick(entity,true))
  //   const url = getDetailPageUrl(entity.entity_type, entity);
  //   // window.location.href = url;
  //   // history.push(url);
  //   navigate(url);
  //   return false;

  //   // Make an API call to mark the notification as read

  //   axios(`${markAsReadNotification}`, {
  //     method: "POST",
  //     data: {
  //       id: entity.id
  //     },
  //     headers: {
  //       "Content-Type": "application/json",
  //       "Authorization": getToken()
  //     }      // console.log('Incremental Issue', i+1);

  //   })
  //     .then((response) => {
  //       // Navigate to the detail page after marking the notification as read
  //       const url = getDetailPageUrl(entity.entity_type, entity);
  //       // window.location.href = url;
  //       // history.push(url);
  //       navigate(url);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // }

  return (
    <div>
      {/* <div className="flex items-center justify-evenly">
        <a className={
          "cursor-pointer leading-[16px] text-[13px] tracking-[-0.28px]  group flex items-center  py-2 space-x-2 " +
          (activeNotify === 1
            ? "font-sans_bold_body font-medium font-medium text-sky-600 dark:text-sky-400"
            : "font-medium text-sky-500 dark:text-sky-400 font-sans_book_body")
        }
          onClick={() => {
            setactiveNotify(1);
          }}
          data-toggle="tab"
          role="tab1"
        >
          Read
        </a>
        <a className={
          "cursor-pointer leading-[16px] text-[13px] tracking-[-0.28px]  group flex items-center  py-2 space-x-2 " +
          (activeNotify === 2
            ? "font-sans_bold_body font-medium font-medium text-sky-600 dark:text-sky-400"
            : "font-medium text-sky-500 dark:text-sky-400 font-sans_book_body")
        }
          onClick={() => {
            setactiveNotify(2);
          }}
          data-toggle="tab"
          role="tab2"
        >Un Read</a>
      </div> */}

      <div className="">
        {/* <p className="bg-[#F5F5F5] text-[#333333] font-sans_book_body  text-[13px] leading-[16px] tracking-[-0.32px] py-1 pl-5  sticky top-0">
          Today
        </p> */}

        {/* <pre> { JSON.stringify(props.notificationData, null, 2) }</pre> */}

        {/* handleNotificationClick(data) */}

        <ul className="divide-y">


          {props.notificationData && props.notificationData.alert.unread.length < 1 ? (
            <li className="lg:text-[15px] text-[15px] text-[#333333] mt-[13rem] text-center">No unread records</li>
          ) : (
            props.notificationData.alert.unread.map((data, i) => (
              <li className="py-3 pl-[1.75rem] cursor-pointer" onClick={() => props.handleNotificationClick(data,false,'alert')} key={i}>
                <div className="flex space-x-2" >
                  <img
                    className="h-6 w-6 rounded-full mt-2"
                    src={data?.entity_logo ? `https://v2-logo.s3.ap-southeast-1.amazonaws.com${data.entity_logo}` : mindTreeLogo}
                    alt=""
                  />
                  <div className="flex-1 pt-2">
                    <div className="flex items-center justify-between">
                      <h3 className="flex text-[12px] text-[#888888] font-sans_book_body tracking-[-0.26px] leading-[14px]">
                        {data?.entity_type ? data?.entity_type : ''}
                      </h3>
                      <div className="flex px-4">
                        <p className="text-[12px] pl-2 leading-[14px] font-sans_book_body tracking-[-0.26px] text-[#888888]">
                          {data?.notified_at ? data?.notified_at : ''}
                        </p>
                      </div>
                    </div>
                    <p className="text-[15px] leading-[18px] font-sans_medium_body  text-[#1F2D3A]">
                      {data?.entity_name ? data?.entity_name : ''}
                    </p>
                    <p className="text-[14px] text-[#56626D] pt-1 leading-[18px] tracking-[-0.35px] font-sans_book_body ">
                    {data?.message ? data?.message : ''}
                    </p>
                  </div>
                </div>
              </li>
            )))}


          {/* {datas.map((data,i)=>{ */}
          {/* {props.notificationData && props.notificationData.unread.map((data,i)=>{
            return(
              <li className="py-3 pl-[1.75rem]" key={i}>
            <div className="flex space-x-2">
              <img className="h-6 w-6 rounded-full mt-2" src={mindTreeLogo} alt="" />
              <div className="flex-1 pt-2">
                <div className="flex items-center justify-between">
                  <h3 className=" flex text-[12px] text-[#888888] font-sans_book_body  tracking-[-0.26px] leading-[14px]">
                    {data?.entity_type ?  data?.entity_type : ''}
                  </h3>
                  <div className="flex px-4">
                    <p className="text-[12px] pl-2 leading-[14px] font-sans_book_body tracking-[-0.26px] text-[#888888]">
                     {data?.date ? data?.date :''}
                    </p>
                  </div>
                </div>
                <p className="text-[15px] leading-[18px] font-sans_medium_body  text-[#1F2D3A]">
                 {data?.entity_name ? data?.entity_name : ''}
                </p>
                <p className="text-[14px] text-[#56626D] pt-1 leading-[18px] tracking-[-0.35px] font-sans_book_body ">
                  {'New Deal'}
                </p>
              </div>
            </div>
            </li>
            )
          })}                                      */}
        </ul>
        {/* { activeNotify ==2 &&
        <ul className="divide-y overflow-y-scroll">
          <li className="py-3 pl-[1.75rem]">
            <div className="flex space-x-2">
              <img className="h-6 w-6 rounded-full mt-2" src={bhive} alt="" />
              <div className="flex-1 pt-2">
                <div className="flex items-center justify-between">
                  <h3 className=" flex text-[12px] text-[#888888] font-sans_book_body  tracking-[-0.26px] leading-[14px]">
                    Company
                  </h3>
                  <div className="flex px-4">
                    <p className="text-[12px] pl-2 leading-[14px] font-sans_book_body tracking-[-0.26px] text-[#888888]">
                      30m ago
                    </p>
                  </div>
                </div>
                <p className="text-[15px] leading-[18px] font-sans_medium_body  text-[#1F2D3A]">
                  Fanzstar
                </p>
                <p className="text-[14px] text-[#56626D] pt-1 leading-[18px] tracking-[-0.35px] font-sans_book_body ">
                  Valuation Updated
                </p>
              </div>
            </div>
          </li>
          <li className="py-3 pl-[1.75rem]">
            <div className="flex space-x-2">
              <img className="h-6 w-6 rounded-full mt-2" src={bhiveLogo} alt="" />
              <div className="flex-1 pt-2">
                <div className="flex items-center justify-between">
                  <h3 className=" flex text-[12px] text-[#888888]  font-sans_book_body tracking-[-0.26px] leading-[14px]">
                    Transaction Advisor
                  </h3>
                  <div className="flex px-4">
                    <p className="text-[12px] pl-2 leading-[14px] font-sans_book_body tracking-[-0.26px] text-[#888888]">
                      1hr ago
                    </p>
                  </div>
                </div>
                <p className="text-[15px] leading-[18px] font-sans_medium_body  text-[#1F2D3A]">
                  PWC
                </p>
                <p className="text-[14px] text-[#56626D] pt-1 leading-[18px] tracking-[-0.35px] font-sans_book_body ">
                  New deal(Existing Portfolio)
                </p>
              </div>
            </div>
          </li>
          <li className="py-3 pl-[1.75rem]">
            <div className="flex space-x-2">
              <img className="h-6 w-6 rounded-full mt-2" src={mindTreeLogo} alt="" />
              <div className="flex-1 pt-2">
                <div className="flex items-center justify-between">
                  <h3 className=" flex text-[12px] text-[#888888] font-sans_book_body  tracking-[-0.26px] leading-[14px]">
                    Investor
                  </h3>
                  <div className="flex px-4">
                    <p className="text-[12px] pl-2 leading-[14px] font-sans_book_body tracking-[-0.26px] text-[#888888]">
                      8 May 22
                    </p>
                  </div>
                </div>
                <p className="text-[15px] leading-[18px] font-sans_medium_body  text-[#1F2D3A]">
                  Accel India
                </p>
                <p className="text-[14px] text-[#56626D] pt-1 leading-[18px] tracking-[-0.35px] font-sans_book_body ">
                  New Company
                </p>
              </div>
            </div>
          </li>
          <li className="py-3 pl-[1.75rem]">
            <div className="flex space-x-2">
              <img className="h-6 w-6 rounded-full mt-2" src={bhive} alt="" />
              <div className="flex-1 pt-2">
                <div className="flex items-center justify-between">
                  <h3 className=" flex text-[12px] text-[#888888] font-sans_book_body  tracking-[-0.26px] leading-[14px]">
                     Transaction Advisor
                  </h3>
                  <div className="flex px-4">
                    <p className="text-[12px] pl-2 leading-[14px] font-sans_book_body tracking-[-0.26px] text-[#888888]">
                      24 Apr ago               </p>
                  </div>
                </div>
                <p className="text-[15px] leading-[18px] font-sans_medium_body   text-[#1F2D3A]">
                  PWC
                </p>
                <p className="text-[14px] text-[#56626D] pt-1 leading-[18px] tracking-[-0.35px] font-sans_book_body ">
                  New deal(Existing portfolio)
                </p>
              </div>
            </div>
          </li>
          <li className="py-3 pl-[1.75rem]">
            <div className="flex space-x-2">
              <img className="h-6 w-6 rounded-full mt-2" src={bhiveLogo} alt="" />
              <div className="flex-1 pt-2">
                <div className="flex items-center justify-between">
                  <h3 className=" flex text-[12px] text-[#888888] font-sans_book_body  tracking-[-0.26px] leading-[14px]">
                    Investor
                  </h3>
                  <div className="flex px-4">
                    <p className="text-[12px] pl-2 leading-[14px] font-sans_book_body tracking-[-0.26px] text-[#888888]">
                      12 Apr 22
                    </p>
                  </div>
                </div>
                <p className="text-[15px] leading-[18px]  font-sans_medium_body   text-[#1F2D3A]">
                  Olive Tree Capital
                </p>
                <p className="text-[14px] text-[#56626D] pt-1 leading-[18px] tracking-[-0.35px] font-sans_book_body ">
                  New Company
                </p>
              </div>
            </div>
          </li>
           <li className="py-3 pl-[1.75rem]">
            <div className="flex space-x-2">
              <img className="h-6 w-6 rounded-full mt-2" src={bhive} alt="" />
              <div className="flex-1 pt-2">
                <div className="flex items-center justify-between">
                  <h3 className=" flex text-[12px] text-[#888888] font-sans_book_body  tracking-[-0.26px] leading-[14px]">
                    Transaction Advisor
                  </h3>
                  <div className="flex px-4">
                    <p className="text-[12px] pl-2 leading-[14px] font-sans_book_body tracking-[-0.26px] text-[#888888]">
                     4 Apr ago
                    </p>
                  </div>
                </div>
                <p className="text-[15px] leading-[18px] font-sans_medium_body  text-[#1F2D3A]">
                  PWC
                </p>
                <p className="text-[14px] text-[#56626D] pt-1 leading-[18px] tracking-[-0.35px] font-sans_book_body ">
                  New deal(Existing portfolio)
                </p>
              </div>
            </div>
          </li>
          <li className="py-3 pl-[1.75rem]">
            <div className="flex space-x-2">
              <img className="h-6 w-6 rounded-full mt-2" src={mindTreeLogo} alt="" />
              <div className="flex-1 pt-2">
                <div className="flex items-center justify-between">
                  <h3 className=" flex text-[12px] text-[#888888] font-sans_book_body  tracking-[-0.26px] leading-[14px]">
                    Investor
                  </h3>
                  <div className="flex px-4">
                    <p className="text-[12px] pl-2 leading-[14px] font-sans_book_body tracking-[-0.26px] text-[#888888]">
                     10 Mar 22
                    </p>
                  </div>
                </div>
                <p className="text-[15px] leading-[18px] font-sans_medium_body  text-[#1F2D3A]">
                  Accel India
                </p>
                <p className="text-[14px] text-[#56626D] pt-1 leading-[18px] tracking-[-0.35px] font-sans_book_body ">
                  New Company
                </p>
              </div>
            </div>
          </li>
          <li className="py-3 pl-[1.75rem]">
            <div className="flex space-x-2">
              <img className="h-6 w-6 rounded-full mt-2" src={bhive} alt="" />
              <div className="flex-1 pt-2">
                <div className="flex items-center justify-between">
                  <h3 className=" flex text-[12px] text-[#888888] font-sans_book_body  tracking-[-0.26px] leading-[14px]">
                    Transaction Advisor
                  </h3>
                  <div className="flex px-4">
                    <p className="text-[12px] pl-2 leading-[14px] font-sans_book_body tracking-[-0.26px] text-[#888888]">
                     22 Feb 22
                    </p>
                  </div>
                </div>
                <p className="text-[15px] leading-[18px] font-sans_medium_body  text-[#1F2D3A]">
                  PWC
                </p>
                <p className="text-[14px] text-[#56626D] pt-1 leading-[18px] tracking-[-0.35px] font-sans_book_body ">
                  New deal(Existing portfolio)
                </p>
              </div>
            </div>
          </li>
          <li className="py-3 pl-[1.75rem]">
            <div className="flex space-x-2">
              <img className="h-6 w-6 rounded-full mt-2" src={bhiveLogo} alt="" />
              <div className="flex-1 pt-2">
                <div className="flex items-center justify-between">
                  <h3 className=" flex text-[12px] text-[#888888] font-sans_book_body  tracking-[-0.26px] leading-[14px]">
                    Investor
                  </h3>
                  <div className="flex px-4">
                    <p className="text-[12px] pl-2 leading-[14px] font-sans_book_body tracking-[-0.26px] text-[#888888]">
                     10 Feb 22
                    </p>
                  </div>
                </div>
                <p className="text-[15px] leading-[18px]  font-sans_medium_body  text-[#1F2D3A]">
                  Accel India
                </p>
                <p className="text-[14px] text-[#56626D] pt-1 leading-[18px] tracking-[-0.35px] font-sans_book_body ">
                  New Company
                </p>
              </div>
            </div>
          </li>          
        </ul>
      } */}
      </div>
    </div>
  );
};

export default Alert;
