import React from "react";
import { useTable } from "react-table";
import { FaLock } from "react-icons/fa";

function ExampleTable({ data }) {
  // const columns = React.useMemo(
  //   () => [
  //     {
  //       Header: "S.No",
  //       accessor: "sno",
  //     },
  //     {
  //       Header: "Name",
  //       accessor: "name",
  //     },
  //     {
  //       Header: (
  //         <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
  //           <FaLock style={{ marginRight: "4px" }} />
  //           <span>Count</span>
  //         </div>
  //       ),
  //       accessor: "count",
  //       Cell: ({ value }) => <span style={{ filter: "blur(4px)" }}>{value}</span>,
  //     },
  //   ],
  //   []
  // );
/* The `const columns` variable is defining the columns for the table in the `ExampleTable` component.
It is using the `useMemo` hook from React to memoize the columns array, which means that the array
will only be recalculated if the dependencies (in this case, an empty array) change. */

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Count",
        accessor: "count",
        Cell: ({ row }) => {
          const {
            index,
            cells: [{ column }],
            cells,
          } = row;
          if (index === 0) {
            const countCells = cells.filter(
              (cell) => cell.column.id === column.id
            );
            const firstCell = countCells[0];
            const lastCell = countCells[countCells.length - 1];
            const firstCellTop = firstCell.getCell().getBoundingClientRect().top;
            const lastCellTop = lastCell.getCell().getBoundingClientRect().top;
            const height =
              lastCellTop -
              firstCellTop +
              lastCell.getCell().getBoundingClientRect().height;
            return (
              <div
                style={{
                  position: "absolute",
                  top: firstCellTop,
                  height,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FaLock style={{ marginRight: "4px" }} />
                <span style={{ filter: "blur(4px)" }}>{row.original.count}</span>
              </div>
            );
          } else {
            return null;
          }
        },
      },
    ],
    []
  );
  
  
  
  

/* The `columns2` variable is defining an alternative set of columns for the table in the
`ExampleTable` component. It is using the `useMemo` hook from React to memoize the columns array,
which means that the array will only be recalculated if the dependencies (in this case, an empty
array) change. */
  const columns2 = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Count",
        accessor: "count",
        Cell: ({ value }) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <FaLock style={{ marginRight: "4px" }} />
            <span style={{ filter: "blur(4px)" }}>{value}</span>
          </div>
        ),
      },
    ],
    []
  );

  /* The `useTable` hook from the `react-table` library is being used to create a table instance. The
  `useTable` hook takes an object as an argument with the `columns` and `data` properties. */
  const tableInstance = useTable({ columns, data });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render("Header")}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default ExampleTable;