import React from "react";
import { Popover } from "antd";

/**
 * The ReFundDealInfo function renders a table with fund details based on the props passed to it.
 * @param props - The `props` parameter is an object that contains data passed to the `ReFundDealInfo`
 * component. It is used to access the data needed to render the component.
 * @returns The ReFundDealInfo component is returning a JSX element, which represents the structure and
 * content of the component's UI.
 */
const ReFundDealInfo = (props) => {

  const data =
    Object.keys(props?.data).length > 0 &&
    /* The above code is written in JavaScript and it is mapping over an object's entries. It checks
    for certain conditions and returns a JSX element if the conditions are met. */
    Object.entries(props?.data).map(([key, value]) => {
      return (
        value != "The fund detail has been viewed successfully" &&
        key != "status" &&
        value?.length > 0 &&
        value?.map((round, i) => {
         
          return Object.entries(round).map(([key1, value1]) => {
           
            return (
              <div className="">
                {value1?.selected == true ? (
                  <p
                    key={value1?.deal_id}
                    className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] cursor-auto font-semibold  "
                    readonly
                  >
                    {value1?.source
                      .split(";")
                      ?.filter((link) => link?.trim().length !== 0)
                      .map((link, index) => {
                        return (
                          <Popover
                            placement="topLeft"
                            className=" overflow text-[11pt]    leading-[18pt] lg:text-[13px] text-[#333333]  cursor-auto font-semibold hover:underline "
                            content={
                              <a
                                className=" overflow text-[11pt]  hover:text-[#F1CA75]  leading-[18pt] lg:text-[13px] text-[#333333]  cursor-auto font-semibold hover:underline "
                                href={link}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {link}
                              </a>
                            }
                          >
                            <a
                               className=" overflow text-[11pt]    leading-[18pt] lg:text-[13px] text-[#333333]  cursor-auto font-semibold hover:underline "
                              href={link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {link.length > 0 ? (
                                <span>
                                  Link {index + 1}
                                  {value1?.source
                                    .split(";")
                                    ?.filter(
                                      (link) => link?.trim().length !== 0
                                    ).length >
                                  index + 1
                                    ? ", "
                                    : ""}
                                </span>
                              ) : (
                                "--"
                              )}
                            </a>
                          </Popover>
                        );
                      })}
                  </p>
                ) : (
                  ""
                )}
              </div>
            );
          });
        })
      );
    });
  return (
    <div className="px-5 ">
      <p className="  text-[14pt] leading-[19pt] lg:text-[17px] text-[#333333] font-semibold lg:leading-[22px]">
        Fund Details
      </p>
      <div className="overflow-x-auto scrollbar">
        <table className=" w-full">
          <tr className="border-b-[#E5E5E5] border-b space-x-6">
            <td className="py-1 lg:leading-[21px] font-sans_book_body">
              <p
                className="text-[11pt]  leading-[18pt] lg:text-[13px]  text-[#333333] whitespace-nowrap cursor-auto"
                readonly
              >
                Sector
              </p>
              {Object.keys(props?.data).length > 0 &&
                Object.entries(props?.data).map(([key, value]) => {
                  return (
                    value != "The fund detail has been viewed successfully" &&
                    key != "status" &&
                    value?.length > 0 &&
                    value?.map((round, i) => {
                      // console.log(key, "getroundhier");
                      // console.log(value, "getroundhiervalue");
                      return Object.entries(round).map(([key1, value1]) => {
                        // console.log(key1, "getroundhier1");
                        // console.log(value1, "value1");
                        return (
                          <div className="">
                            {value1?.selected == true ? (
                              <p
                                key={value1?.deal_id}
                                className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto font-semibold  "
                                readonly
                              >
                                {value1?.sector? value1?.sector :'--'}
                              </p>
                            ) : (
                              " "
                            )}
                          </div>
                        );
                      });
                    })
                  );
                })}
            </td>
            <td className="py-1 pl-12 lg:leading-[21px] font-sans_book_body">
              <p
                className="text-[11pt]  leading-[18pt] lg:text-[13px]  text-[#333333] whitespace-nowrap cursor-auto"
                readonly
              >
              Size (US$ M)
              </p>
              {Object.keys(props?.data).length > 0 &&
                Object.entries(props?.data).map(([key, value]) => {
                  return (
                    value != "The fund detail has been viewed successfully" &&
                    key != "status" &&
                    value?.length > 0 &&
                    value?.map((round, i) => {
                      // console.log(key, "getroundhier");
                      // console.log(value, "getroundhiervalue");
                      return Object.entries(round).map(([key1, value1]) => {
                        // console.log(key1, "getroundhier1");
                        // console.log(value1, "value1");
                        return (
                          <div className="">
                            {value1?.selected == true ? (
                              <p
                                key={value1?.deal_id}
                                className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto font-semibold  "
                                readonly
                              >
                                {value1?.target_size != '$ 0 M' && value1?.target_size !=0 ? '$ '+value1?.target_size +' M' : "--"}
                              </p>
                            ) : (
                              " "
                            )}
                          </div>
                        );
                      });
                    })
                  );
                })}
            </td>
            <td className="py-1 pl-5 lg:leading-[21px] font-sans_book_body">
              <p
                className="text-[11pt]  leading-[18pt] lg:text-[13px]  text-[#333333] whitespace-nowrap cursor-auto"
                readonly
              >
                Date
              </p>
              {Object.keys(props?.data).length > 0 &&
                Object.entries(props?.data).map(([key, value]) => {
                  return (
                    value != "The fund detail has been viewed successfully" &&
                    key != "status" &&
                    value?.length > 0 &&
                    value?.map((round, i) => {
                      // console.log(key, "getroundhier");
                      // console.log(value, "getroundhiervalue");
                      return Object.entries(round).map(([key1, value1]) => {
                        // console.log(key1, "getroundhier1");
                        // console.log(value1, "value1");
                        return (
                          <div className="">
                            {value1?.selected == true ? (
                              <p
                                key={value1?.deal_id}
                                className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto font-semibold  "
                                readonly
                              >
                                {value1?.date ? value1?.date : "--"}
                              </p>
                            ) : (
                              " "
                            )}
                          </div>
                        );
                      });
                    })
                  );
                })}
            </td>
          </tr>
          <tr className="border-b-[#E5E5E5] border-b font-sans_book_body">
          <td className="py-2  lg:leading-[21px]">
              <p
                className="text-[11pt]  leading-[18pt] lg:text-[13px]  text-[#333333] whitespace-nowrap cursor-auto"
                readonly
              >
                Fund Manager
              </p>
              {Object.keys(props?.data).length > 0 &&
                Object.entries(props?.data).map(([key, value]) => {
                  return (
                    value != "The fund detail has been viewed successfully" &&
                    key != "status" &&
                    value?.length > 0 &&
                    value?.map((round, i) => {
                      
                      return Object.entries(round).map(([key1, value1]) => {
                       
                        return (
                          <div className="">
                            {value1?.selected == true ? (
                              <p
                                key={value1?.deal_id}
                                className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto font-semibold  "
                                readonly
                              >
                                {value1?.fundManager ? value1?.fundManager :"--"}
                              </p>
                            ) : (
                              " "
                            )}
                          </div>
                        );
                      });
                    })
                  );
                })}
            </td>
            <td className="py-2 pl-12 lg:leading-[21px]">
              <p
                className="text-[11pt]  leading-[18pt] lg:text-[13px]  text-[#333333] whitespace-nowrap cursor-auto"
                readonly
              >
                Fund Status
              </p>
              {Object.keys(props?.data).length > 0 &&
                Object.entries(props?.data).map(([key, value]) => {
                  return (
                    value != "The fund detail has been viewed successfully" &&
                    key != "status" &&
                    value?.length > 0 &&
                    value?.map((round, i) => {
                      // console.log(key, "getroundhier");
                      // console.log(value, "getroundhiervalue");
                      return Object.entries(round).map(([key1, value1]) => {
                        // console.log(key1, "getroundhier1");
                        // console.log(value1, "value1");
                        return (
                          <div className="">
                            {value1?.selected == true ? (
                              <p
                                key={value1?.deal_id}
                                className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto font-semibold  "
                                readonly
                              >
                                {value1?.fund_status ? value1?.fund_status :"--"}
                              </p>
                            ) : (
                              " "
                            )}
                          </div>
                        );
                      });
                    })
                  );
                })}
            </td>
            <td className="py-1 pl-5 lg:leading-[21px]">
              <p
                className="text-[11pt]  leading-[18pt]  lg:text-[14px] text-[#333333] whitespace-nowrap cursor-auto"
                readonly
              >
                Capital Source
              </p>
              {Object.keys(props?.data).length > 0 &&
                Object.entries(props?.data).map(([key, value]) => {
                  return (
                    value != "The fund detail has been viewed successfully" &&
                    key != "status" &&
                    value?.length > 0 &&
                    value?.map((round, i) => {
                      // console.log(key, "getroundhier");
                      // console.log(value, "getroundhiervalue");
                      return Object.entries(round).map(([key1, value1]) => {
                        // console.log(key1, "getroundhier1");
                        // console.log(value1, "value1");
                        return (
                          <div className="">
                            {value1?.selected == true ? (
                              <p
                                key={value1?.deal_id}
                                className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto font-semibold  "
                                readonly
                              >
                                {value1?.capital_source == null
                                  ? "--"
                                  : value1?.capital_source}
                              </p>
                            ) : (
                              " "
                            )}
                          </div>
                        );
                      });
                    })
                  );
                })}
            </td>
          </tr>
          <tr className="">
            <td className="py-2 lg:leading-[21px] font-sans_book_body">
              <p
                className="text-[11pt]  leading-[18pt] lg:text-[13px]  text-[#333333] whitespace-nowrap cursor-auto"
                readonly
              >
                Source
              </p>
              {data}
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
};

export default ReFundDealInfo;
