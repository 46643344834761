import React from 'react'
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

/**
 * The `PrimaryButton` function is a React component that renders a button with customizable text,
 * click event handler, disabled state, loading state, and styling.
 * - The button is of type "button".
 * - The disabled attribute is set to the value of the "disable" variable.
 * - The onClick event handler is set to the value of the "onClick" variable.
 * - The className attribute is set to the value of the "className" variable.
 */
function PrimaryButton({ children, onClick, disable, isLoading = false ,className}) {
    return (
        <button
            type="button"
            disabled={disable}
            onClick={onClick}
            className={`inline-flex items-center w-full justify-center rounded-md border border-transparent font-sans_bold_body   px-4 py-2 text-base font-medium text-white shadow-sm  focus:outline-none   focus:ring-offset-2 ml-3 sm:w-auto sm:text-sm  bg-[#BC8B20]  focus:outline-none hover:bg-[#a37517]${disable ? "cursor-not-allowed " : ""} ${className}`}>
            {isLoading ? <Spin indicator={<LoadingOutlined style={{ fontSize: 16, color: "white", marginRight: "4px" }} spin />} /> : ""} {children}
        </button>
    )
}

export default PrimaryButton