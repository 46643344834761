import React from 'react'

/**
 * The function `SavedFilterICON` returns a JSX element that renders an image with different sources
 * based on the `props.save` value, and triggers a function `props.savedList` when clicked.
 * @param props - The `props` parameter is an object that contains the properties passed to the
 * `SavedFilterICON` component. These properties can be accessed using dot notation, such as
 * `props.savedList`, `props.save`, `props.FileIcon`, and `props.FileIconBlur`.
 * @returns a JSX element.
 */
function SavedFilterICON(props) {
    return (
        <>
        <a className="group cursor-pointer">
                            <div className="cursor-pointer" onClick={props.savedList}>
                              <img src={props.save ? props.FileIcon : props.FileIconBlur}
                                className="max-h-[24px] mt-1 max-w-[21px]"
                                alt="" />
                            </div>
                          </a>
        </>
    )
}

export default SavedFilterICON
