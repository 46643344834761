import { React, useState,  useEffect, useContext } from 'react'
import {  useParams } from 'react-router-dom';
import ThousandSeperator from '../../Utils/ThousandSeperator';
import PE_VC from '../../images/noun-investment-2180740_4/png/noun-investment-2180740.png';
import TF_US from '../../images/Shape_4/png/Shape.png';
import TF_INR from '../../images/noun-investment-2180740_2/png/noun-investment-2180740.png';
import Company from '../../images/noun-investment-2180740_5/png/noun-investment-2180740.png';
import { dealPageIpo } from '../../Config/config';
import { UserContext } from '../../Contexts/UserContext';
import axios from "axios";
import useModule from '../../hooks/useModule';

/**
 * The function `DealCardInvestment` is a JavaScript function that fetches and sets the top card
 * details.
 */
const DealCardInvestment = () => {

  const [isProfileLoading, setIsProfileLoading] = useState(true);
  const { getToken } = useContext(UserContext);

  const [open, setOpen] = useState()
  const [Card, setCard] = useState([])
  const { companyId } = useParams();
  let companydealId = parseInt(companyId)
  const{primaryMasterId} = useModule()

  const topCardDetail = () => {
    axios.post(dealPageIpo, {
      "company_id": companydealId,
      "type": primaryMasterId

    }, {
      headers: { "Authorization": getToken() }
    }).then(response => {
      if (response.status == 200) {
        setCard(response.data?.topCard)

        setIsProfileLoading(false);
        setOpen(false)
      }
    }).catch(error => {

    })
  }

  useEffect(() => {
    topCardDetail();

  }, [companydealId])



  return (
    <div className="">
      <div className='sm:full mt-[4.25rem] md:mt-[3rem] h-full  bg-[#F2F2F2]'>
        {/* <div> */}
        <div className="overflow-x-auto scrollbar-remove lg:grid grid-cols-5 gap-4  px-4 py-[0.7rem] flex xl:overflow-hidden bg-[#EDEDED]">
          {/* ----InvestorCard1----- */}
          <section className="flex-shrink-0 ">
            <div className="bg-white px-3 py-1 rounded-md  border border-gray-200 overflow-hidden card-animate">
              <div className="grid grid-cols-5 ">
                <div className="col-span-4 ">
                  <div className="text-[13pt] leading-[17pt] lg:text-[13px] lg:leading-[17px] text-[#2B2A29] font-sans_book_body lg:tracking-[-0.3px]">Total Funding</div>
                  {!isProfileLoading ?
                    Card?.total_usd != null && Card?.total_usd != 0 && Card?.total_usd !='$ 0 M'?
                      <div className="flex space-x-1 flex items-center">
                        <span className=' text-[18pt] leading-[16pt] font-bold lg:text-[17px] lg:leading-[23px] tracking-[-0.3px] text-[#A5711A] '>
                          {Card?.total_usd ?? "--"}

                        </span>
                        </div>
                      : <div className="text-[18pt] leading-[16pt] font-bold lg:text-[17px] lg:leading-[23px] tracking-[-0.3px] text-[#A5711A] ">--</div>
                    :
                    <div className="w-full bg-[#FFFFFF]">
                      <div role="status" className="bg-white">
                        <svg className="relative top-[0rem] inline mr-2 w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-[#CA8A24]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                        </svg>
                      </div>
                    </div>
                  }
                </div>
                <div className="flex-shrink-0 pl-5 flex items-center justify-center">
                  <a href="#">
                    <img className="h-[22px] max-w-[25px] " fill="currentColor"
                      src={PE_VC} />
                  </a>
                </div>
              </div>
            </div>
          </section>
          {/* ----InvestorCard2----- */}
          <section className="flex-shrink-0">
            <div className=" bg-white px-3 py-1  rounded-md  border border-gray-200 overflow-hidden card-animate">
              <div className="grid grid-cols-5 ">
                <div className="col-span-4">
                  <div className="text-[13pt]  leading-[17pt] tracking-[-0.3pt] lg:text-[13px] text-[#2B2A29] font-sans_book_body lg:leading-[17px] lg:tracking-[-0.3px]">Lastest Round </div>
                  {!isProfileLoading ?
                  Card?.latest_date !=null && Card?.latest_date!=0 ?

                    < div className="flex space-x-1  flex items-center">
                  <span
                    className='text-[18pt] font-bold lg:text-[17px] leading-[16pt] lg:leading-[23px] tracking-[-0.3px] text-[#A5711A]'>
                    { Card?.latest_date ??"--"}

                  </span>
                </div>
                : <div className="text-[18pt] leading-[16pt] font-bold lg:text-[17px] lg:leading-[23px] tracking-[-0.3px] text-[#A5711A] ">--</div>
                :
                <div className="w-full bg-[#FFFFFF]">
                  <div role="status" className="bg-white">
                    <svg className="relative top-[0rem] inline mr-2 w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-[#CA8A24]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                    </svg>
                  </div>
                </div>
                    }
              </div>
              <div className="flex items-center pl-5 justify-center">
                <div className="flex-shrink-0 ">
                  <a href="#">
                    <img className="h-[24px] max-w-[25px] "
                      src={TF_US} />
                  </a>
                </div>
              </div>
            </div>
        </div>
      </section>
      {/* ----InvestorCard3----- */}
      <section className="flex-shrink-0 ">
        <div className=" bg-white px-3 py-1 rounded-md  border border-gray-200 overflow-hidden card-animate card-animate">
          <div className="grid grid-cols-5 ">
            <div className="col-span-4 ">
              <div className="text-[13pt]  leading-[17pt] tracking-[-0.3pt] lg:text-[13px] text-[#2B2A29] font-sans_book_body lg:leading-[17px] lg:tracking-[-0.3px]">Total Exits </div>
             {!isProfileLoading ?
             Card?.total_exits !=null && Card?.total_exits!=0 && Card?.total_exits!='$ 0 M' ?
              <div className="flex space-x-1 flex items-center">
                <span className=' text-[18pt] leading-[16pt] font-bold lg:text-[17px] lg:leading-[23px] tracking-[-0.3px] text-[#A5711A] '>
                  {Card?.total_exits??"--"}
                  
                </span>
              </div>
              : <div className="text-[18pt] leading-[16pt] font-bold lg:text-[17px] lg:leading-[23px] tracking-[-0.3px] text-[#A5711A] ">--</div>
              :
              <div className="w-full bg-[#FFFFFF]">
                <div role="status" className="bg-white">
                  <svg className="relative top-[0rem] inline mr-2 w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-[#CA8A24]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                  </svg>
                </div>
              </div>
              }
            </div>
            <div className="flex-shrink-0 pl-5 flex items-center justify-center">
              <a href="#">
                <img className="h-[26px] max-w-[25px]"
                  src={TF_INR} />
              </a>
            </div>
          </div>
        </div>
      </section>
      {/* ----InvestorCard4----- */}
      <section className="flex-shrink-0 ">
        <div className=" bg-white px-3 py-1 rounded-md  border border-gray-200 overflow-hidden card-animate card-animate">
          <div className="grid grid-cols-5 ">
            <div className="col-span-4 ">
              <div className="text-[13pt]  leading-[17pt] tracking-[-0.3pt] lg:text-[13px] text-[#2B2A29] font-sans_book_body lg:leading-[17px] lg:tracking-[-0.3px]"> Investors </div>
              {!isProfileLoading ?
              Card?.ipo_investors !=null && Card?.ipo_investors!=0 ?
              <div className="flex space-x-1 text-sm text-gray-500 tracking-[-0.3px]">
                <span
                  className='text-[18pt] font-bold lg:text-[17px] leading-[16pt] lg:leading-[23px] tracking-[-0.3px] text-[#A5711A] '>
                  {Card?.ipo_investors ?? "--"}
                  
                </span>
              </div>
              : <div className="text-[18pt] leading-[16pt] font-bold lg:text-[17px] lg:leading-[23px] tracking-[-0.3px] text-[#A5711A] ">--</div>
              :
              <div className="w-full bg-[#FFFFFF]">
                <div role="status" className="bg-white">
                  <svg className="relative top-[0rem] inline mr-2 w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-[#CA8A24]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                  </svg>
                </div>
              </div>
           }
            </div>
            <div className="flex-shrink-0 pl-5 flex items-center justify-center">
              <a href="#">
                <img className="h-[26px] max-w-[25px] "
                  src={Company} />
              </a>
            </div>
          </div>
        </div>
      </section>
      {/* ----InvestorCard5----- */}
      <section className="flex-shrink-0 ">
        <div className=" bg-white px-3 py-1 rounded-md  border border-gray-200 overflow-hidden card-animate card-animate">
          <div className="grid grid-cols-5 ">
            <div className="col-span-4 ">
              <div className="text-[13pt]  leading-[17pt] tracking-[-0.3pt] lg:text-[13px] text-[#2B2A29] font-sans_book_body lg:leading-[17px] lg:tracking-[-0.3px]">Revenue </div>
              {!isProfileLoading ?
              Card?.revenue!=null && Card?.revenue!=0 ?            
              <div className="flex space-x-1  flex items-center">
                <span
                  className='text-[18pt] leading-[16pt] font-bold  lg:text-[15px] lg:leading-[19px] text-[#A5711A] '>₹</span>
                <span
                  className=' text-[18pt] leading-[16pt] font-bold lg:text-[17px] lg:leading-[23px] tracking-[-0.3px] text-[#A5711A] '>
                  <ThousandSeperator  value= {Card?.revenue??"--"} />
                  
                </span>
                <span
                  className=' text-[15pt] leading-[16pt] font-bold lg:leading-[22px]  lg:text-[17px] text-[#A5711A] '>Cr</span>
              </div>
              : <div className="text-[18pt] leading-[16pt] font-bold lg:text-[17px] lg:leading-[23px] tracking-[-0.3px] text-[#A5711A] ">--</div>
              :
              <div className="w-full bg-[#FFFFFF]">
                <div role="status" className="bg-white">
                  <svg className="relative top-[0rem] inline mr-2 w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-[#CA8A24]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                  </svg>
                </div>
              </div>
           }
            </div>
            <div className="flex-shrink-0 pl-5 flex items-center justify-center">
              <a href="#">
                <img className="h-[26px] max-w-[25px]"
                  src={Company} />
              </a>
            </div>
          </div>
        </div>
      </section>

    </div>
        {/* </div> */ }
  <div className='border-b-2 border-[#e5e5e5]'></div>

  {/* <Enterprice/> */ }

      </div >
    </div >
  )
}

export default DealCardInvestment;