import React, { useContext, useEffect } from 'react'
import { PeFilterContext } from '../../Contexts/PeFilterContext'

/**
 * The `TransactionStatusFilter` function is a React component that renders a list of checkboxes for
 * selecting transaction statuses.
 * @returns a JSX element, specifically a `<div>` element with a border, a `<li>` element containing a
 * label and a list of checkboxes, and a `<label>` element for each checkbox.
 */
function TransactionStatusFilter({ TransactionStatus, setTransactionStatus, customPathname }) {
    const { refineSearchFilters } = useContext(PeFilterContext);
   
    /* The `useEffect` hook is used to perform side effects in a React component. In this case, the
    `useEffect` hook is used to update the `TransactionStatus` array with the `isChecked` property
    based on the `refineSearchFilters` and `customPathname` values. */
    useEffect(() => {
        const industryIds = refineSearchFilters[customPathname]?.transactionStatus?.map(value => value.id) ?? [];
        setTransactionStatus(TransactionStatus?.map(industry => ({ ...industry, isChecked: industryIds.includes(industry.id) })))
    }, [customPathname, refineSearchFilters, setTransactionStatus,customPathname])


    /**
     * The handleChange function updates the isChecked property of a transaction in the
     * TransactionStatus array based on the provided id.
     * @param id - The `id` parameter is the unique identifier of the transaction that needs to be
     * updated.
     */
    const handleChange = (id) => {
        const updatedTransactionStatus = TransactionStatus.map((transaction) => {
            if (transaction.id === id) {
                return {
                    ...transaction,
                    isChecked: !transaction.isChecked,
                };
            }
            return transaction;
        });
        setTransactionStatus(updatedTransactionStatus);
    };
    return (
        <div className="border-b-[3px] border-[#E8E6E1]">
            <li className={"relative px-3 py-2"}>
                <label className='text-[#333333] font-sans_book_body text-[14px] leading-[17px] tracking-[-0.3px]'>Transaction Status</label>

                <div className='flex gap-4 my-3'>
                    {TransactionStatus?.length > 0 &&
                    TransactionStatus.map((transaction,i) => {
                        return (
                            <div className="flex " key={i}>
                                <input id={transaction.value} type="checkbox" key={transaction.id} onChange={(e) => handleChange(transaction.id)}
                                    className="cursor-pointer mr-1"
                                    checked={transaction.isChecked} />
                                <label htmlFor={transaction.value} className="text-xs cursor-pointer whitespace-nowrap">{transaction.name}</label>
                            </div>

                        )
                    }
                    )}
                </div>

            </li>
        </div>
    )
}

export default TransactionStatusFilter