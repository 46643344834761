import React, { useState, useMemo, useEffect, useContext } from "react";
import { useParams, useLocation, matchPath } from 'react-router-dom';
import axios from 'axios'
import InvestmentTable from '../../../Components/RE/Detailpage/Funding/InvestmentTable';
import FundingREExits from './Funding/FundingREExits';
import OthermaTable from './Funding/OthermaTable';
import REBackedIpoTable from './Funding/REBackedIpoTable';
import { FundingContext } from "../../../Contexts/FundingDataProvider";
import Loader from "../../Loader";
import { UserContext } from "../../../Contexts/UserContext";
import { exportExcel, getFundingUrl } from "../../../Config/config";
import Constants from "../../../Constants";
import ExcelDownloader from "../../shared/ExcelDownloader";
import routePathSegments from "../../../routeSegments";


/**
 * The Funding component is a React functional component that displays different tabs of funding data
 * based on the selected tab and company ID.
 * @returns The component is returning a JSX element.
 */
const Funding = ({ menu, companyName }) => {
  const [openTab, setOpenTab] = React.useState();
  const { reData, reExistData, othermaData, reipoData, setEnableExport, enableExport } = useContext(FundingContext);
  const [showXlDownload, setshowXlDownload] = useState(false);
  const [xlDownloadRequest, setxlDownloadRequest] = useState({
    "type": 3,
    "export_from": "detail",
    "module_type": "investments",
    "section": "funding",
    "sub_section": "re-investments",
    "company_id": [] 

    
  });
  const [fundingData, setFundingData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const {isTrialExport, getToken,isStudent } = useContext(UserContext);
  const { pathname} = useLocation();
  const { companyId } = useParams();
  const isReCompanyMatch = matchPath({ path: `${routePathSegments.re}/*` }, pathname)

  useEffect(()=>{
    if(fundingData?.data?.pe_investments?.length >0 ){
      setOpenTab(1)
    }
    else if (fundingData?.data?.pe_exits?.length >0){
      setOpenTab(2)
    }
    else if (fundingData?.data?.other_manda?.length >0){
      setOpenTab(3)
    }
    else if (fundingData?.data?.pe_backed_ipo?.length >0){
      setOpenTab(4)
    }    
  },[fundingData])

  useEffect(() => {
    let sub_section = "re-investments";

    if (openTab === 2) {
      sub_section = "re-exits";
    } else if (openTab === 3) {
      sub_section = "other-manda ";
    }
    else if (openTab === 4) {
      sub_section = "re-ipo";
    }

    if (isReCompanyMatch !== null) {
      setxlDownloadRequest(prev => ({ ...prev, company_id: [companyId], type: Constants.primaryMasterIds.reId, sub_section: sub_section }))
    }
    else {
      setxlDownloadRequest(prev => ({ ...prev, company_id: [companyId], type: Constants.primaryMasterIds.reId, sub_section: sub_section }))
    }

  }, [openTab, companyId])


  useEffect(() => {
    if (openTab === 1) {
      if (reData != null && enableExport) {
        setshowXlDownload(true);
      } else {
        setshowXlDownload(false);
      }
    } else if (openTab === 2) {
      if (reExistData != null && enableExport) {
        setshowXlDownload(true);
      } else {
        setshowXlDownload(false);
      }
    }
    else if (openTab === 3) {
      if (othermaData != null && enableExport) {
        setshowXlDownload(true);
      } else {
        setshowXlDownload(false);
      }
    }
    else if (openTab === 4) {
      if (reipoData != null && enableExport) {
        setshowXlDownload(true);
      } else {
        setshowXlDownload(false);
      }
    }


  }, [openTab, reData, reExistData, othermaData, reipoData, enableExport])



/**
 * The function `getFundsData` makes a POST request to a funding URL with a company ID and type, and
 * then sets the funding data, loading state, and export enablement based on the response.
 */
  const getFundsData = () => {
    setIsLoading(true);
    axios.post(getFundingUrl, {
      "company_id": companyId,
      "type":isReCompanyMatch!= null? Constants.primaryMasterIds.reId
      :Constants.primaryMasterIds.reId
    }, {
      headers: {
        'Authorization': getToken(),
      }
    }).then(response => {
      if (response.status == 200) {
        setFundingData(response.data);
        setIsLoading(false);
        setEnableExport(response.data?.data?.isExport ?? true)
      }
    }).catch(error => {
      setFundingData(null);
      setIsLoading(false);
    })
  }

  useEffect(() => {
    getFundsData();
  }, [companyId])

/* The `fileName` variable is using the `useMemo` hook to memoize the value and only recompute it when
the dependencies (`companyName` and `openTab`) change. */
  const fileName = useMemo(() => {

    let module;

    if (isReCompanyMatch !== null) {
      module = "PE";
    } else  {
     module = "PE"
    }

  let fileName = Constants.exportNames.funding_re_investment(companyName, module);


  if (openTab === 2) {
    fileName = Constants.exportNames.funding_re_exit(companyName, module);
  } else if (openTab === 3) {
    fileName = Constants.exportNames.funding_re_otherma(companyName, module);
  } else if (openTab === 4) {
    fileName = Constants.exportNames.funding_re_ipo(companyName, module);
  } 
  return fileName;
}, [companyName, openTab])

/* The `allCountZero` variable is a boolean variable that is set to `true` if all the following
conditions are met:
- `isLoading` is `false`
- `fundingData?.data?.pe_investments?.length` is equal to `0`
- `fundingData?.data?.pe_exits?.length` is equal to `0`
- `fundingData?.data?.other_manda?.length` is equal to `0`
- `fundingData?.data?.pe_backed_ipo?.length` is equal to `0` */
const allCountZero = !isLoading && fundingData?.data?.pe_investments?.length ==0 && fundingData?.data?.pe_exits?.length ==0 && fundingData?.data?.other_manda?.length ==0 && fundingData?.data?.pe_backed_ipo?.length ==0 

  return (
    <>
      <div className="px-4 bg-[#EDEDED]">
        <div className="  bg-white border  shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] rounded-lg">
          <div className="lg:pl-6 lg:pt-4 lg:pr-2 pt-2 px-4 ">
            <div className="flex justify-between">
              <p className=" text-[14pt] leading-[19pt] text-[#333333] lg:text-[17px] font-semibold mb-3 lg:leading-[22px]">Funding</p>
             
              {(isTrialExport != 'false' && isStudent == 'false'  && showXlDownload) && <ExcelDownloader fileName={fileName} data={xlDownloadRequest} url={exportExcel} />}


            </div>
          </div>
          <div className={`flex flex-row overflow-x-auto space-x-9 scrollbar px-5 ${allCountZero ?'':'border-t border-t-[#F1F1F1]'}`}>
          {fundingData?.data?.pe_investments?.length > 0 &&
            <a
              className={
                "whitespace-nowrap  py-3 text-[12pt]  lg:text-[14px] lg:leading-[15px]    block leading-[18px] " +
                (openTab === 1
                  ? "text-[#2B2A29] border-b-[3px] border-[#F1CA75] font-semibold"
                  : " text-[#2B2A29] ")
              }
              onClick={e => {
                e.preventDefault();
                setOpenTab(1);
              }}
              data-toggle="tab"
              href="#link1"
              role="tablist"
            >
              PE-RE Investments
            </a>
          }
            {fundingData?.data?.pe_exits?.length >0 &&
            <a
              className={
                "whitespace-nowrap  py-3 text-[12pt]  lg:text-[14px] lg:leading-[15px]    block leading-[18px] " +
                (openTab === 2
                  ? "text-[#2B2A29] border-b-[3px] border-[#F1CA75] font-semibold"
                  : " text-[#2B2A29] ")
              }
              onClick={e => {
                e.preventDefault();
                setOpenTab(2);
              }}
              data-toggle="tab"
              href="#link2"
              role="tablist"
            >
              PE-RE Exits
            </a>
            }
            {fundingData?.data?.other_manda?.length >0 && 
            <a
              className={
                "whitespace-nowrap  py-3 text-[12pt]  lg:text-[14px] lg:leading-[15px]    block leading-[18px] " +
                (openTab === 3
                  ? "text-[#2B2A29] border-b-[3px] border-[#F1CA75] font-semibold"
                  : "text-[#2B2A29] ")
              }
              onClick={e => {
                e.preventDefault();
                setOpenTab(3);
              }}
              data-toggle="tab"
              href="#link3"
              role="tablist"
            >
              Other M&A
            </a>
            }
            { fundingData?.data?.pe_backed_ipo?.length >0 &&            
            <a
              className={
                "whitespace-nowrap  py-3 text-[12pt]  lg:text-[14px] lg:leading-[15px]    block leading-[18px] " +
                (openTab === 4
                  ? "text-[#2B2A29] border-b-[3px] border-[#F1CA75] font-semibold"
                  : "  text-[#2B2A29] ")
              }
              onClick={e => {
                e.preventDefault();
                setOpenTab(4);
              }}
              data-toggle="tab"
              href="#link4"
              role="tablist"
            >
              PE-RE Backed IPOs
            </a>
            }

          </div >
          <div className={isLoading ? "block" : "hidden"}>
            <div className="h-40 flex items-center justify-center">
              <Loader />
            </div>
          </div>
          {allCountZero &&
            <div className="h-[3rem] mx-auto  lg:text-[15px] text-[15px] text-[#333333] relative mt-[1rem] text-center">
              No data found
            </div> 
          }

          <div className={isLoading ? "hidden" : "block"}>
          {fundingData?.data?.pe_investments?.length >0 &&
            <div className={openTab === 1 ? "block" : "hidden"} id="link1">
              <InvestmentTable companyFundingId={fundingData?.data?.company_id} menu={menu} data={fundingData?.data?.pe_investments} />
            </div>
          }
          {fundingData?.data?.pe_exits?.length >0 &&
            <div className={openTab === 2 ? "block" : "hidden"} id="link2">
              <FundingREExits companyFundingId={fundingData?.data?.company_id} menu={menu} data={fundingData?.data?.pe_exits} />
            </div>
          }
          {fundingData?.data?.other_manda?.length >0 && 
            <div className={openTab === 3 ? "block" : "hidden"} id="link2">
              <OthermaTable companyFundingId={fundingData?.data?.company_id} menu={menu} data={fundingData?.data?.other_manda} />
            </div>
          }
          { fundingData?.data?.pe_backed_ipo?.length >0 && 
            <div className={openTab === 4 ? "block" : "hidden"} id="link3">
              <REBackedIpoTable companyFundingId={fundingData?.data?.company_id} menu={menu} data={fundingData?.data?.pe_backed_ipo} />
            </div>
          }
          </div>
        </div>
      </div>
    </>
  )
}

export default Funding