import { React, useState,  useEffect, useContext } from 'react'
import {  useParams } from 'react-router-dom';
import ThousandSeperator from '../../Utils/ThousandSeperator';
import PE_VC from '../../images/noun-investment-2180740_4/png/noun-investment-2180740.png';
import TF_US from '../../images/Shape_4/png/Shape.png';
import TF_INR from '../../images/noun-investment-2180740_2/png/noun-investment-2180740.png';
import Company from '../../images/noun-investment-2180740_5/png/noun-investment-2180740.png';
import { CompanydetailCard } from '../../Config/config'
import axios from "axios";
import { UserContext } from '../../Contexts/UserContext';
import useModule from '../../hooks/useModule';

/**
 * The function `DealCardExit` is a JavaScript function that fetches and sets the top card
 * details.
 */
const DealCardExit = (props) => {
  const [cardData, setCardData] = useState({});
  const { companyId } = useParams();
  let companydealId = parseInt(companyId)

  const [isProfileLoading, setIsProfileLoading] = useState(true);
  const { getToken } = useContext(UserContext);
  const{primaryMasterId} = useModule()
  const [open, setOpen] = useState()


  const topCardDetail = () => {
    axios.post(CompanydetailCard, {

      "company_id": companydealId,
      "type": primaryMasterId
    }, {
      headers: { "Authorization": getToken() }
    }).then(response => {
      if (response.status === 200) {
        setCardData(response.data?.topCard)

        setIsProfileLoading(false);
        setOpen(false)
      }
    }).catch(error => {

    })
  }

  useEffect(() => {
    topCardDetail();

  }, [companydealId])

  return (
    <div className="">
      <div className='sm:full mt-[4.25rem] md:mt-[3rem] h-full  bg-[#F2F2F2]'>
        {/* <div> */}
        <div className="overflow-x-auto scrollbar-remove lg:grid grid-cols-5 gap-4  px-4 py-[0.7rem] flex xl:overflow-hidden bg-[#EDEDED]">
          {/* ----InvestorCard1----- */}
          <section className="flex-shrink-0 ">
            <div className="bg-white px-3 py-1 rounded-md  border border-gray-200 overflow-hidden card-animate">
              <div className="grid grid-cols-5 ">
                <div className="col-span-4 ">
                  <div className="text-[13pt] leading-[17pt] lg:text-[13px] lg:leading-[17px] text-[#2B2A29] font-sans_book_body lg:tracking-[-0.3px]">Total Funding</div>
                 
                  {cardData?.totalFundingAmount != null && cardData?.totalFundingAmount != 0 && cardData?.totalFundingAmount != '$ 0 M' ? <div className="flex space-x-1 flex items-center">
                    <span className=' text-[18pt] leading-[16pt] font-bold lg:text-[17px] lg:leading-[23px] tracking-[-0.3px] text-[#A5711A] '>{cardData?.totalFundingAmount}</span>
                  </div> : "--"}
                </div>
                <div className="flex-shrink-0 pl-5 flex items-center justify-center">
                  <a href="#">
                    <img className="h-[22px] max-w-[25px] " fill="currentColor"
                      src={PE_VC} />
                  </a>
                </div>
              </div>
            </div>
          </section>
          {/* ----InvestorCard2----- */}
          <section className="flex-shrink-0">
            <div className=" bg-white px-3 py-1  rounded-md  border border-gray-200 overflow-hidden card-animate">
              <div className="grid grid-cols-5 ">
                <div className="col-span-4 ">
                  <div className="text-[13pt] leading-[17pt] lg:text-[13px] lg:leading-[17px] text-[#2B2A29] font-sans_book_body lg:tracking-[-0.3px]">Lastest Round</div>
                  {cardData?.latestRound != null && cardData?.latestRound != 0 ?
                    <div className="flex space-x-1 flex items-center">

                      <span className=' text-[18pt] leading-[11pt] font-bold lg:text-[17px] lg:leading-[23px] tracking-[-0.3px] text-[#A5711A] '>{cardData?.latestRound}</span>

                    </div> : "--"}
                </div>
                <div className="flex items-center pl-5 justify-center">
                  <div className="flex-shrink-0 ">
                    <a href="#">
                      <img className="h-[24px] max-w-[25px] "
                        src={TF_US} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* ----InvestorCard3----- */}
          <section className="flex-shrink-0 ">
            <div className=" bg-white px-3 py-1 rounded-md  border border-gray-200 overflow-hidden card-animate card-animate">
              <div className="grid grid-cols-5 ">
                <div className="col-span-4 ">
                  <div className="text-[13pt]  leading-[17pt] tracking-[-0.3pt] lg:text-[13px] text-[#2B2A29] font-sans_book_body lg:leading-[17px] lg:tracking-[-0.3px]">Total Exits </div>
                  {cardData?.totalExitsAmount != null && cardData?.totalExitsAmount != 0 && cardData?.totalExitsAmount != '$ 0 M' ? <div className="flex space-x-1 flex items-center">
                    <span className=' text-[18pt] leading-[16pt] font-bold lg:text-[17px] lg:leading-[23px] tracking-[-0.3px] text-[#A5711A] '>{cardData?.totalExitsAmount}</span>
                  </div> : "--"}
                </div>
                <div className="flex-shrink-0 pl-5 flex items-center justify-center">
                  <a href="#">
                    <img className="h-[26px] max-w-[25px]"
                      src={TF_INR} />
                  </a>
                </div>
              </div>
            </div>
          </section>
          {/* ----InvestorCard4----- */}
          <section className="flex-shrink-0 ">
            <div className=" bg-white px-3 py-1 rounded-md  border border-gray-200 overflow-hidden card-animate card-animate">
              <div className="grid grid-cols-5 ">
                <div className="col-span-4 ">
                  <div className="text-[13pt]  leading-[17pt] tracking-[-0.3pt] lg:text-[13px] text-[#2B2A29] font-sans_book_body lg:leading-[17px] lg:tracking-[-0.3px]"> Investors </div>
                  {cardData?.investorCount != null && cardData?.investorCount != 0 ? <div className="flex space-x-1 text-sm text-gray-500 tracking-[-0.3px]">
                    <span
                      className='text-[18pt] font-bold lg:text-[17px] leading-[16pt] lg:leading-[23px] tracking-[-0.3px] text-[#A5711A] '>{cardData?.investorCount}</span>
                  </div> : "--"}
                </div>
                <div className="flex-shrink-0 pl-5 flex items-center justify-center">
                  <a href="#">
                    <img className="h-[26px] max-w-[25px] "
                      src={Company} />
                  </a>
                </div>
              </div>
            </div>
          </section>
          {/* ----InvestorCard5----- */}
          <section className="flex-shrink-0 ">
            <div className=" bg-white px-3 py-1 rounded-md  border border-gray-200 overflow-hidden card-animate card-animate">
              <div className="grid grid-cols-5 ">
                <div className="col-span-4 ">
                  <div className="text-[13pt]  leading-[17pt] tracking-[-0.3pt] lg:text-[13px] text-[#2B2A29] font-sans_book_body lg:leading-[17px] lg:tracking-[-0.3px]">Revenue </div>
                  {cardData?.revenue != null && cardData?.revenue != 0 && cardData?.revenue != "--" ? <div className="flex space-x-1  flex items-center">
                    <span
                      className='text-[18pt] leading-[24pt] font-bold  lg:text-[15px] lg:leading-[19px] text-[#A5711A] '>₹</span>
                    <span
                      className=' text-[18pt] leading-[23pt] font-bold lg:text-[17px] lg:leading-[23px] tracking-[-0.3px] text-[#A5711A] '>
                      <ThousandSeperator value={cardData?.revenue} />
                      {/* {cardData?.revenue} */}
                    </span>
                    <span
                      className=' text-[15pt] leading-[16pt] font-bold lg:leading-[22px]  lg:text-[17px] text-[#A5711A] '>Cr</span>
                  </div> : "--"}
                </div>
                <div className="flex-shrink-0 pl-5 flex items-center justify-center">
                  <a href="#">
                    <img className="h-[26px] max-w-[25px]"
                      src={Company} />
                  </a>
                </div>
              </div>
            </div>
          </section>

        </div>
        {/* </div> */}
        <div className='border-b-2 border-[#e5e5e5]'></div>

        {/* <Enterprice/> */}

      </div >
    </div >
  )
}

export default DealCardExit;