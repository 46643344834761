import React, { useCallback, useState,useEffect,useRef } from "react";

/**
 * The getRandomColor function generates a random hexadecimal color code.
 * @returns The function `getRandomColor` returns a randomly generated color in the form of a
 * hexadecimal color code.
 */
const  getRandomColor=()=> {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 10)];
    }
    return color;
  }

/**
 * The `removeSpecialChar` function takes a string as input and removes any special characters,
 * returning the modified string.
 * @returns The function `removeSpecialChar` returns a string with all special characters removed,
 * except for letters (both uppercase and lowercase), numbers, and spaces.
 */
  const removeSpecialChar = (str)=>{
    if(str === "" || str == null || str === undefined){
      return str;
    }
    return str?.toString().replace(/[^a-zA-Z0-9 ]/g, "")?.trim()
  }

/**
 * The `RandomLogo` function is a React component that generates a random logo based on the provided
 * name and displays it as an image.
 */
function RandomLogo(props) {
    const colorRef = useRef(getRandomColor())
    const [textSrc, setTextSrc] = useState("")

      /** The `getInitials` function  generates the initials of a name and
      creates a logo image based on those initials. */
      const getInitials = useCallback((size, name, color) => {
        let initials;
        const nameSplit = name?.split(" ")

        const nameLength = nameSplit?.length;
        if (nameLength > 1) {
            initials =
                nameSplit[0].substring(0, 1) +
                nameSplit[nameLength - 1].substring(0, 1);
        } else if (nameLength === 1) {
            initials = nameSplit[0].substring(0, 2);
        } else return;
        let name1 = initials.toUpperCase();
        const canvas=document.createElement('canvas')
        const context=canvas.getContext('2d')
        canvas.width=canvas.height=size

        // context.fillStyle="#ffffff"
        // context.fillRect(0,0,size,size)

        context.fillStyle=`${color}`
        context.fillRect(0,0,size,size)

        context.fillStyle="white";
        context.textBaseline='middle'
        context.textAlign='center'
        context.font =props?.com==="coInv"?`${size/2.5}px Sequel-Sans-SemiBold, san-serif`:`${size/3}px Sequel-Sans-SemiBold, san-serif`
        context.fillText(name1,(size/2),(size/2))

        return canvas.toDataURL()
    },[props?.com]);

    useEffect(() => {
      const str = getInitials(500, removeSpecialChar(props?.name), colorRef?.current)
      setTimeout(()=>{

        setTextSrc(str);
      },100)
    }, [getInitials, props?.name])
    
	return (
        props.page === "detail page" ?
                <div className="h-[4rem] w-[4rem] p-[0.2rem] rounded-md bg-white">  
                <img alt=""  title={props?.name} className="w-[3.5rem] h-[3.5rem] cursor-auto" src={getInitials(500, removeSpecialChar(props?.name), colorRef?.current)}  />
                </div>
        : 
        <div  style={{ display:"-webkit-inline-box", float:"left"}}>  
            {props?.name &&
            <img alt="" title={props?.name} className=" rounded-full mr-2 w-[32px] h-[32px] cursor-pointer" src={getInitials(500, removeSpecialChar(props?.name), colorRef?.current)}  />
            }
        </div>
	);
}

export default RandomLogo;