import React from 'react';
import Incubated_Cos from './IncubatedCos';
import Follow_On from './FollowOn';
import MostFundedCard from './MostFunded';
import IncubatorsCard from './Incubators';
import MostActive from './MostActive';
/**
 * The `Card` function returns a JSX element that represents a card component with multiple
 * sub-components.
 * @returns The Card component is returning a JSX element.
 */
 const Card = () => {
  
  return (
    <div className="">
      <div className='sm:full  mt-[4.25rem] lg:mt-[3rem] h-full bg-[#EDEDED]'>
           <div className="overflow-x-auto lg:grid grid-cols-5 gap-4  px-4 py-[0.7rem] flex xl:overflow-hidden">
           {/* ----card1----- */}
           <Incubated_Cos/>
          {/* ----card2----- */}
          <Follow_On/>
          {/* ----card3----- */}
          <MostFundedCard/>
          {/* ----card4----- */}
          <IncubatorsCard/> 
          {/* card 5 */}
          <MostActive/>
            </div>
        <div className='border-b-2 border-[#e5e5e5]'></div>
      </div >
    </div >
  )
}

export default Card;
