import React, { useMemo } from "react";
import moment from "moment";
import DataTable from '../../Components/DataTables/DataTable';
import { useContext } from "react";
import { DataFoundContext } from "../../Contexts/DataFoundContext";


/**
 * The `AcquirerNewsActivity` component renders a DataTable component with custom sorting and displays
 * recent news and activity based on the provided newsletter data.
 * @returns The AcquirerNewsActivity component is returning a JSX element that represents the
 * component's UI. It includes a div container with a DataTable component inside, which displays recent
 * news and activity. The DataTable component is configured with columns, data, and initial sorting
 * options.
 */
const AcquirerNewsActivity = ({ newsletter }) => {

  const {newsAndActivityDataFound} = useContext(DataFoundContext);

  /* The `customStringSortMemo` constant is using the `useMemo` hook to memoize a custom sorting
  function for the DataTable component. */
  const customStringSortMemo = useMemo(() => {
    return (rowA, rowB, columnId, desc) => {
      const defaultVal = desc ? '--' : 'A';
      return (rowA?.values[columnId]?.news ?? defaultVal).localeCompare(rowB?.values[columnId]?.news ?? defaultVal);
    }
  }, []);

  /* The `dealDateSort` constant is using the `useMemo` hook to memoize a custom sorting function for
  the "Release Date" column in the DataTable component. */
  const dealDateSort = useMemo(() => {
    return (rowA, rowB, columnId, desc) => {
      return moment.utc(rowA?.values[columnId].date).diff(moment.utc(rowB?.values[columnId].date))
    }
  }, [])

  /* The `const columns` variable is an array of objects that define the columns for the DataTable
  component. Each object represents a column and contains properties such as `id`, `Header`,
  `accessor`, `className`, `headerClassName`, `Cell`, and `sortType`. */
  const columns = useMemo(() => [
    {
      id: "news",
      Header: "News",
      accessor: "news",
      className: "text-[#333333] text-[12pt]  py-4 flex-wrap lg:text-[14px] lg:leading-[16px]",
      headerClassName: "w-[15%]",
      Cell: ({ value }) => {
        return <a href={value.link} target="_blank" rel="noreferrer">
          {value.news}
        </a>
      },
      sortType: customStringSortMemo,
    },
    {
      id: "releaseDate",
      Header: "Release Date",
      accessor: "releaseDate",
      className: "text-[#333333] pr-[3.2rem] md:pr-[3.7rem] py-4 float-center lg:float-right whitespace-nowrap lg:text-[14px] lg:leading-[16px] text-right",
      headerClassName: "text-center float-right flex lg:px-10 px-8 py-3.5",
      Cell: ({ value }) => {
        return moment(value?.date).format('MMM DD, YYYY')
      },
      sortType: dealDateSort,
    }
  ], [])

 /* The `rowData` constant is using the `useMemo` hook to memoize the data that will be displayed in
 the DataTable component. */
  const rowData = useMemo(() => {
    const rows = [];

    if (newsletter) {
      newsletter?.forEach(newsData => {
        rows.push({
          news: { news: newsData?.news, link: newsData?.link },
          releaseDate: { date: newsData?.release_date, news: newsData?.news }
        })
      });
    }

    return rows;

  }, [newsletter])
  /* The `initialSort` constant is using the `useMemo` hook to memoize an array of sort options for the
  DataTable component. It specifies that the initial sorting should be done based on the
  "releaseDate" column in descending order (`desc: true`). The empty dependency array (`[]`) ensures
  that the sort options are only calculated once and not re-evaluated on subsequent renders. */
  const initialSort = useMemo(() => [
    {
      id: "releaseDate",
      desc: true
    }
  ], []);
  return (<>
    {newsAndActivityDataFound ? <div className="mx-4 ">
      <div className="bg-white border mb-[-1.75rem] overflow-hidden sm:rounded-lg shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
        <div className="pt-4  px-5">
          <p className="text-[14pt] leading-[19pt] lg:text-[#333333] lg:text-[17px] font-semibold  lg:leading-[22px] mb-3 ">
            Recent News & Activity
          </p>
        </div>
        <DataTable columns={columns} data={rowData}
          sortBy={initialSort} 
        />
      </div>
      <br />
      <br />
    </div> : ""}
    </>
  );
};

export default AcquirerNewsActivity;
