import React from 'react'
import CountUp from "react-countup";
// import ThousandSeperator from '../../Utils/ThousandSeperator';
import tfs_Icon from '../../images/Shape_4/png/Shape.png';

/**
 * The TotalFundingUSDCard function is a React component that displays the total funding amount in US
 * dollars.
 * @param props - The `props` parameter is an object that contains the data and other properties passed
 * to the `TotalFundingUSDCard` component.
 * @returns a JSX element, which represents a card component displaying the total funding in US
 * dollars. The card includes a title, a value (which is dynamically updated using the CountUp
 * component), and an icon.
 */
function TotalFundingUSDCard(props) {
  
    return (
        <div className="flex-shrink-0 card-animate">
            {/* ----card2----- */}
            <section>
                <div className=" bg-white px-3 py-1  rounded-md  border border-gray-200 overflow-hidden card-animate">
                    <div className="grid grid-cols-6 ">
                        <div className="col-span-5">
                            <div className="text-[13pt] leading-[16pt] tracking-[-0.3pt] lg:text-[13px] lg:leading-[16px] font-sans_book_body text-[#2B2A29] lg:tracking-[-0.3px]">Total Funding in (US$ M)</div>
                            {Object.keys(props?.data).length >0 ?
                                !props?.isDataLoad ?
                                    props?.data?.amount_usd!=null&&props?.data?.amount_usd!=0?
                            <div className="flex space-x-1  text-gray-500 tracking-[-0.3px]">
                                <div className="  text-[#A5711A] ">
                                    <span
                                        className=' text-[16pt] font-bold leading-[21pt] lg:leading-[23px] tracking-[-0.3px] lg:text-[17px] text-[#A5711A] '>
                                            <CountUp
                                                start={0}
                                                prefix={""}
                                                suffix={""}
                                                separator={","}
                                                end={props?.data?.amount_usd}
                                                decimals={0}
                                                duration={2}
                                            />  

                                {/* <br /> */}

                                {/* <ThousandSeperator value={props?.data?.amount_usd} /> */}
                                    </span>
                                </div>
                            </div>
                            :
                            <div className="flex space-x-1  text-gray-500 ">
                                <div className="  ">
                                    <span
                                        className='text-[16pt] leading-[23pt] font-sans_bold_body  lg:text-[17px] lg:leading-[23px] tracking-[-0.3px] text-[#A5711A] '>--</span>
                                </div>
                            </div>
                            :
                            // <br />
                            <div className="w-full bg-[#FFFFFF]">
                            <div role="status" className="bg-white">
                            <svg className="relative top-[0rem] inline mr-2 w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-[#CA8A24]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                            </svg>
                            </div>
                        </div>
                            :
                            // <br />
                            <div className="w-full bg-[#FFFFFF]">
                                <div role="status" className="bg-white">
                                <svg className="relative top-[0rem] inline mr-2 w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-[#CA8A24]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                </svg>
                                </div>
                            </div>
                            }
                        </div>
                        <div className="">
                            <div className="flex-shrink-0  pl-5">
                                 
                                    <img className=" pointer-events-none h-[24px] max-w-[25px] mt-2"
                                        src={tfs_Icon} />
                                 
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default TotalFundingUSDCard
