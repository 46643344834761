import React, { useContext, useEffect } from 'react'
import { PeFilterContext } from '../../Contexts/PeFilterContext'


/**
 * The ReturnMultipleFilter function is a React component that renders a form input for selecting a
 * range of return multiples.
 * @returns a JSX element, which represents a form input for filtering return multiples.
 */
function ReturnMultipleFilter({ customPathname, ReturnMultiple, setReturnMultiple }) {
    const { refineSearchFilters } = useContext(PeFilterContext);

    useEffect(() => {
        const from = refineSearchFilters[customPathname]?.returnMultiple?.at(0)?.name ?? "";
        const to = refineSearchFilters[customPathname]?.returnMultiple?.at(1)?.name ?? "";
        setReturnMultiple({ from, to })
    }, [customPathname, refineSearchFilters, setReturnMultiple])

    const handleChange = (e) => {
        const { name, value } = e.target;
        setReturnMultiple(prev => ({ ...prev, [name]: value }))
    }

    return (
        <div className="border-b-[3px] border-[#E8E6E1]">
            <li
                className={
                    "relative px-3 py-2"
                }
            >
                <p className="text-[14px] tracking-[-.3px]">Return Multiple</p>
                <div className="flex mt-2">
                    <input min={0} max={ReturnMultiple?.to} type="number" name="from" id="" onChange={handleChange}
                        className=" border border-[#BC8B20] mr-3 rounded focus:outline-none bg-[#FFF9EC] lg:w-[5rem] w-[9.5rem] md:w-[16rem] pl-2"
                        value={ReturnMultiple?.from ?? ""} placeholder="" />
                    <p className="text-sm mr-3">To</p>
                    <input min={ReturnMultiple?.from} type="number" name="to" id="" onChange={handleChange}
                        className="border border-[#BC8B20] rounded focus:outline-none bg-[#FFF9EC] lg:w-[5rem] w-[9.5rem] md:w-[16rem] pl-2"
                        value={ReturnMultiple?.to ?? ""} placeholder="" />
                </div>
            </li>
        </div>
    )
}

export default ReturnMultipleFilter