import axios from 'axios';
import React, { useState, useEffect, useContext, useCallback } from 'react'
import { AiFillCloseCircle } from 'react-icons/ai'

/**
 * The function `buildDealColumn` recursively builds an array of filters based on a given filter option
 * and filter data.
 * @param filterOption - The `filterOption` parameter is an object that represents the current filter
 * option. It may have the following properties:
 * @param filterData - The `filterData` parameter is an object that contains filter options and their
 * corresponding data. It is structured like this:
 * @returns The function `buildDealColumn` returns an array of filtered data.
 */
const buildDealColumn = (filterOption, filterData) => {
  let data = [];
  filterData[filterOption?.filterKey]?.filter(filter => filter.isChecked && parseInt(filter.id) !== 100)
    ?.forEach(filter => {
      if (filter.hasMore) {
        const nestedData = buildDealColumn(filter, filterData);
        data = [...data.concat(nestedData)];
      } else {
        data = [...data.concat([filter])];
      }
    })
  if (data.length === 0) {
    filterData[filterOption?.filterKey]?.filter(filter => parseInt(filter.id) !== 100)
      ?.forEach(filter => {
        if (filter.hasMore) {
          const nestedData = buildDealColumn(filter, filterData);
          data = [...data.concat(nestedData)];
        } else {
          data = [...data.concat([filter])];
        }
      })
  }
  return data;
}
/**
 * The function `Items` is a React component that renders a clickable div with a title, checkbox, and
 * an optional arrow icon.
 * @returns The `Items` component is returning a JSX element, specifically a `div` element with various
 * attributes and child elements.
 */
const Items = ({ title, hasMore, checked, onClick, selected, id, onCheckChange, disabled }) => {
  return <div onClick={onClick}
    className={` font-sans_medium_body justify-between cursor-pointer flex text-left  py-2 pr-2 pl-3 w-full font-medium text-gray-700 border-b border-gray-100 md:w-auto ${selected ? "bg-gray-200" : "hover:bg-gray-50"}`}>
    <span className='flex text-[13px] items-center'>
      {/* <input
        id={id}
        disabled={disabled}
        className='mr-2 cursor-pointer'
        checked={checked}
        onChange={onCheckChange}
        type="checkbox" /> */}
      <label htmlFor={id} className='cursor-pointer'>{title}</label>
    </span>
    {hasMore === true ? <svg className="w-6 h-6 cursor-pointer text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg> : <></>}
  </div>
}


/**
 * The function `InitialItems` is a React component that renders a clickable item with a title,
 * checkbox, and arrow icon if there are more items available.
 * @returns The `InitialItems` component is returning a JSX element, specifically a `div` element with
 * various attributes and child elements.
 */
const InitialItems = ({ title, hasMore, checked, onClick, onCheckChange, name, id, selected }) => {
  return <div onClick={onClick}
    className={`transition ease-in-out delay-150 justify-between cursor-pointer flex text-left  py-2 pr-2 pl-3 w-full font-sans_medium_body font-medium text-gray-700 border-b border-gray-100 md:w-auto ${selected ? "bg-gray-200" : "hover:bg-gray-50"}`}>
    <span className='flex text-[13px] items-center'>
      {/* <input
        id={id}
        className='mr-2'
        name={name}
        checked={checked}
        defaultChecked={checked}
        onChange={onCheckChange}
        type="radio" /> */}
      <label key={id} htmlFor={id} className='cursor-pointer'>{title}</label>
    </span>
    {hasMore === true ? <svg className="w-6 h-6 cursor-pointer text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg> : <></>}
  </div>
}

/**
 * The QueryBuilderPopup component is a JavaScript function that renders a popup window for editing
 * advanced search options.
 * @returns The QueryBuilderPopup component is being returned.
 */
const QueryBuilderPopup = ({ loadedView, loadedViewOption, loadedViewIndex, selectedChildListFunction, onClose, exportOptions, type, modules, module, url, module_type, fileName, category, advisor_type, props, setSelectedParent, setSelectedChild }) => {
  console.log(exportOptions, 'exportOptions');

  console.log('loadedViewOption', loadedViewOption);


  const [filterData, setfilterData] = useState(exportOptions);
  const [selectedLeft, setselectedLeft] = useState(filterData.initial?.at(0));
  const [filterLevel, setfilterLevel] = useState([]);
  const [showAnimation, setshowAnimation] = useState(false);
  const [showRightAnimation, setshowRightAnimation] = useState(false);
  const [animationCount, setanimationCount] = useState(0);
  const [open, setOpen] = useState(true)

 /**
  * The function `onBreadCrumbClick` updates the selected breadcrumb option, modifies the filter level
  * array, and triggers a right animation.
  * @param option - The "option" parameter represents the selected breadcrumb option that was clicked.
  * It could be a string, number, or any other data type depending on how the breadcrumb options are
  * implemented in your code.
  * @param index - The index parameter represents the index of the breadcrumb option that was clicked.
  */
  const onBreadCrumbClick = (option, index) => {
    let temp = [...filterLevel];
    console.log(option, 'tempoption');
    console.log(index, 'tempindex');
    setselectedLeft(option)
    temp.splice(index + 1 > 1 ? index + 1 : 0);
    setfilterLevel(temp);
    triggerRightAnimation();

  }




 /**
  * The function `selectLeftFilter` logs the selected option, updates the selectedLeft and
  * setSelectedParent variables, triggers a right animation, and updates the filterLevel array if it
  * has more than one element.
  * @param option - The `option` parameter is the selected option that will be used for filtering.
  */
  const selectLeftFilter = (option) => {
    console.log(option, 'select left');
    setselectedLeft(option);
    setSelectedParent(option)
    triggerRightAnimation();
    if (filterLevel?.length > 1) {
      let temp = [...filterLevel];
      temp[temp.length - 1] = option
      setfilterLevel([...temp])

    }
  }

 /**
  * The function `selectRightFilter` updates the selected filter option, triggers an animation, and
  * determines if the selected option has siblings or if it is a parent in the initial filter data.
  * @param option - The `option` parameter is an object that represents a selected filter option. It
  * contains properties such as `hasMore`, `parent`, and `name`.
  */
  const selectRightFilter = (option) => {
    console.log(option,filterLevel, 'select right');
    setselectedLeft(option);
    triggerAnimation();
    if (option.hasMore == false) {
      onClose();
    }

    if (filterLevel?.length > 1) {
      setfilterLevel(prev => ([...prev, option]));
    } else {
      setfilterLevel([selectedLeft, option]);
    }

    const parentName = option.parent;
    const parent = filterData[parentName];
    const hasSibling = parent.length > 1;
    const isParentInInitial = filterData.initial.some(item => item.name === parentName);

    console.log("Has Sibling:", hasSibling);
    console.log("Parent in Initial:", !isParentInInitial);

    option.hasSibling = hasSibling && !isParentInInitial;
    console.log("Updated Option:", option);
  };

 /**
  * The function `onInitialChange` updates the filter data and selects a left filter option based on
  * the user's initial change.
  * @param e - The parameter `e` is an event object that represents the event that triggered the
  * function. It is commonly used to access information about the event, such as the target element
  * that triggered the event.
  * @param option - The `option` parameter is an object that contains information about the selected
  * option. It may have properties such as `parent` and `id`.
  */
  const onInitialChange = (e, option) => {
    const { id } = e.target;
    setfilterData(filterData => {
      let temp = exportOptions[`${option?.parent}`].map(filter => ({ ...filter, isChecked: filter.id === id }));
      console.log("filterDatafilterData", temp, option.id);
      return { ...exportOptions, [`${option?.parent}`]: [...temp] }
    })
    selectLeftFilter({ ...option, isChecked: true });
  }

 /**
  * The function `changeChildFilterStatus` modifies a filter data object by changing the isChecked
  * property of a specific filter option and its child filters.
  * @param filterData - The `filterData` parameter is an object that contains filter information. It
  * likely has multiple properties, each representing a different filter option.
  * @param option - The `option` parameter is an object that contains information about the filter
  * option. It has the following properties:
  * @param isChecked - The `isChecked` parameter is a boolean value that indicates whether the filter
  * option should be checked or unchecked.
  * @returns The function `changeChildFilterStatus` returns the modified `filterData` object with
  * updated `isChecked` values for the specified `option`.
  */
  const changeChildFilterStatus = (filterData, option, isChecked) => {
    let modifiedFilterData = { ...filterData };
    let temp = filterData[option.filterKey]?.map(filter => {
      console.log(filter, 'tamil filter');
      if (filter.hasMore) {
        modifiedFilterData = changeChildFilterStatus(modifiedFilterData, filter, isChecked)
        return { ...filter, isChecked: isChecked }
      } else {
        return { ...filter, isChecked: isChecked }
      }
    })
    modifiedFilterData = { ...modifiedFilterData, [option.filterKey]: [...temp] }
    return modifiedFilterData;
  }

 /**
  * The `onCheckChange` function is used to handle the change event when a checkbox is checked or
  * unchecked, and it updates the filter data accordingly.
  * @param e - The parameter `e` is an event object that represents the event that triggered the
  * function. It is typically an event object from a checkbox change event.
  * @param option - The `option` parameter is an object that contains information about the filter
  * option. It may have the following properties:
  */
  const onCheckChange = (e, option) => {
    console.log(option, 'yuekvcieu');
    const { id, checked } = e.target;
    setfilterData(prevFilterData => {
      //Current data list
      let temp = prevFilterData[`${option?.parent}`]?.map(filter => {
        console.log(filter, 'yuekvcieu');
        let temp = { ...filter, isChecked: parseInt(filter.id) === parseInt(id) ? !filter.isChecked : filter.isChecked };
        return temp;
      })

      let isAllSelected = temp.filter(fill => !fill?.isChecked);

      if (isAllSelected?.length === 1) {
        temp[0] = { ...temp[0], isChecked: parseInt(isAllSelected[0].id) === 100 }
      }

      let modifiedFilterData = { ...prevFilterData, [`${option?.parent}`]: [...temp] };

      //Change all its child fiters to checked or unchecked if parent is checked or unchecked
      if (option.hasMore) {
        modifiedFilterData = changeChildFilterStatus(modifiedFilterData, option, checked)
      } else if (parseInt(option.id) === 100) {
        modifiedFilterData[option.parent].forEach(filter => {
          if (parseInt(filter.id) !== 100 && filter.hasMore) {
            modifiedFilterData = changeChildFilterStatus(modifiedFilterData, filter, checked)
          }
        })
      }


      //Parent data list of current data list
      filterLevel?.forEach((filterLev, index) => {
        if (index !== 0) {

          const checked = modifiedFilterData[filterLev.filterKey].filter(filter => filter.isChecked);
          console.log("filterLevfilterLev", filterLev);

          let temp = modifiedFilterData[filterLev.parent]?.map(filter => {
            return { ...filter, isChecked: filter.filterKey === filterLev.filterKey ? checked.length === 0 ? false : true : filter.isChecked }
          })


          let isAllSelected = temp.filter(fill => !fill?.isChecked);

          if (isAllSelected?.length === 1) {
            temp[0] = { ...temp[0], isChecked: parseInt(isAllSelected[0].id) === 100 }
          }
          modifiedFilterData = { ...modifiedFilterData, [`${filterLev.parent}`]: [...temp] }
        }
      })


      return { ...modifiedFilterData }
    })


  }


/**
 * The function `triggerAnimation` updates the state variables `showAnimation`, `showRightAnimation`,
 * and `animationCount` in order to trigger an animation.
 */
  const triggerAnimation = () => {
    setshowAnimation(false);
    setshowRightAnimation(false);
    setanimationCount(prev => prev + 1);
  }


 /**
  * The function `triggerRightAnimation` updates the state variables `showRightAnimation` and
  * `animationCount` in order to trigger a right animation.
  */
  const triggerRightAnimation = () => {
    setshowRightAnimation(false);
    setanimationCount(prev => prev + 1);
  }


  //   onBreadCrumbClick({
  //     "id": 13,
  //     "name": "Deal Info",
  //     "value": "Deal Info",
  //     "isChecked": false,
  //     "hasMore": true,
  //     "filterKey": "Deal Info",
  //     "parent": "Investments",
  //     "category": "Investments",
  //     "values": [
  //         ""
  //     ],
  //     "hasSibling": false
  // }, 1);

  useEffect(() => {
    if (loadedView) {
      const name = loadedViewOption.parent;
      // const name = "Deal Info";
      let foundItem = null;

      function searchObject(obj) {
        if (obj.name === name) {
          foundItem = obj;
          return;
        }

        for (const key in obj) {
          const value = obj[key];
          if (typeof value === "object") {
            searchObject(value);
          } else if (Array.isArray(value)) {
            value.forEach(item => searchObject(item));
          }
        }
      }

      searchObject(filterData);
      
      console.log("loadedView-data",foundItem ,filterData,loadedViewOption);
      if (foundItem) {
        // onBreadCrumbClick(foundItem, loadedViewIndex);
        const parent = filterData?.initial?.find(data=>data.name === foundItem.parent)
        setselectedLeft(foundItem);
        setfilterLevel([parent, foundItem]);
      }
    }
  }, [loadedView]);

  useEffect(() => {
    setshowAnimation(true)
    setshowRightAnimation(true);

  }, [animationCount])

  // useEffect(() => {
  //   filterLevel.length > 0 &&
  //   filterLevel?.forEach((filterLev, index) => {
  //     if (index !== 0) {

  //       const checked = filterData[filterLev.filterKey].filter(filter => filter.isChecked);

  //       let temp = filterData[filterLev.parent]?.map(filter => {
  //         return { ...filter, isChecked: filter.filterKey === filterLev.filterKey ? checked.length === 0 ? false : true : filter.isChecked }
  //       })


  //       let isAllSelected = temp.filter(fill => !fill?.isChecked);

  //       if (isAllSelected?.length === 1) {
  //         temp[0] = { ...temp[0], isChecked: parseInt(isAllSelected[0].id) === 100 }
  //       }

  //       setfilterData(prev => ({ ...prev, [`${filterLev.parent}`]: [...temp] }))

  //     }

  //   })
  // }, [filterLevel])
  // console.log('filterLevel-12', filterLevel);



  return (
    <>
      {open &&
        <div show={open} className='flex font-sans_book_body cursor-default justify-center items-center z-50 fixed left-0 top-0 w-screen h-screen  bg-gray-500 bg-opacity-75 transition-opacity'>
          <div className='flex flex-col w-[95%] lg:w-[35%] lg:h-[45%] w-[42%] h-[30%] bg-white rounded-lg overflow-hidden'>
            <div className='w-full flex flex-col '>
              <div className="px-4 bg-[#BC8B20]  flex items-center justify-start  w-full h-12">
                <div className="w-full px-5 md:px-0 py-1 text-lg font-medium font-sans_book_body text-[18px] leading-6 text-[#FFFFFF]">Edit Advanced Search</div>
                <AiFillCloseCircle className='cursor-pointer mt-1' size={24} color='white' onClick={onClose} />
              </div>
              <div className={`flex w-full p-3 overflow-y-auto no-scrollbar1 ${filterLevel.length > 0 ? 'border-b ':''}`}>
                {
                  filterLevel?.map((level, index) => <div className='flex justify-center items-center'>
                    <h6 onClick={() => index === filterLevel.length - 1 ? null : onBreadCrumbClick(level, index)} className={`${index === filterLevel.length - 1 ? "" : "cursor-pointer hover:font-bold"} text-[12px] text-gray-900 text-left whitespace-nowrap`}>{level?.name}</h6>
                    {(index === filterLevel.length - 1) ? <></> : <svg className="w-5 h-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>}
                  </div>
                  )
                }
              </div>
            </div>
            {/* Options Container */}
            <div className='h-full w-full flex flex-row overflow-auto pb-3'>

              <div className={`${showAnimation ? "w-[45%] transition-all duration-150" : "w-0"} h-full  text-left overflow-auto border-gray-200 border-r`}>
                {
                  filterData[`${selectedLeft?.parent}`]?.map(option => {
                    if (option.parent === "initial") {
                      return <InitialItems
                        title={option.name}
                        key={option.name}
                        onClick={() => selectLeftFilter(option)}
                        hasMore={option?.hasMore}
                        checked={option?.isChecked}
                        selected={option?.id === selectedLeft?.id}
                        name={option.parent}
                        id={option.id}
                        onCheckChange={(e) => onInitialChange(e, option)}
                      />
                    }
                    return <Items
                      key={option.name}
                      title={option?.name ?? ""}
                      id={option?.id}
                      checked={option?.isChecked}
                      selected={option?.id === filterLevel?.at(filterLevel?.length - 1)?.id}
                      onCheckChange={(e) => onCheckChange(e, option)}
                      onClick={() => selectLeftFilter(option)}
                      hasMore={option?.hasMore}
                      disabled={filterLevel?.at(0)?.isChecked === true ? false : true}
                    />
                  })
                }
              </div>
              <div className={`${showRightAnimation ? "w-[55%] transition-all duration-150" : "w-0"} h-full overflow-auto`}>

                {
                  filterData[`${selectedLeft?.filterKey}`]?.map(option => {
                    return <Items
                      key={option.name}
                      title={option?.name ?? ""}
                      id={option?.id}
                      disabled={selectedLeft?.parent === "initial" ? filterData?.initial?.find(filter => filter?.isChecked).filterKey !== option.parent : filterLevel?.at(0)?.isChecked === true ? false : true}
                      checked={option?.isChecked}
                      onCheckChange={(e) => onCheckChange(e, option)}
                      onClick={() => {
                        selectedChildListFunction({...option,filterLevel})
                        selectRightFilter(option)
                      }}
                      hasMore={option?.hasMore}
                    />
                  })
                }
              </div>
            </div>
          </div>
        </div>

      }
    </>


  )
}



export default QueryBuilderPopup
