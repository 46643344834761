import React, { useContext, useEffect } from 'react'
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import MultiSelectEntity from '../RefineSearch/MultiSelectEntity'
import FilterWrapper from './FilterWrapper';

/**
 * The `FollowExitStatusFilter` function is a React component that renders a dropdown menu for
 * selecting a follow on funding status option.
 * @returns a JSX element, specifically a `<div>` element with a `<li>` child element. Inside the
 * `<li>` element, there is a `<FilterWrapper>` component and a `<select>` element. The options for the
 * `<select>` element are dynamically generated based on the `FollowExitStatusOptions` array.
 */
function FollowExitStatusFilter({ open, accordionClick, FollowExitStatusOptions, setFollowExitStatusOptions, customPathname }) {

    const { FollowExitStatus, refineSearchFilters } = useContext(PeFilterContext);

    /* The `useEffect` hook is used to perform side effects in a React functional component. In this
    case, the `useEffect` hook is used to update the `FollowExitStatusOptions` state based on the
    values of `FollowExitStatus`, `customPathname`, and `refineSearchFilters`. */
    useEffect(() => {
        const industryIds = refineSearchFilters[customPathname]?.followexitStatus?.map(value => value.id) ?? [];
        setFollowExitStatusOptions(FollowExitStatus?.map(industry => ({ ...industry, isChecked: industryIds.includes(industry.id) })))
    }, [FollowExitStatus, customPathname, refineSearchFilters, setFollowExitStatusOptions])


    /**
     * The handleChange function updates the isChecked property of each object in the
     * FollowExitStatusOptions array based on the selected value from the event target.
     * @param e - The parameter `e` is an event object that is passed to the `handleChange` function.
     * It represents the event that triggered the function, such as a change event on an input element.
     */
    const handleChange = (e) => {

        const value = e.target.value
        // const values = companyType.filter(companyType => companyType.id === e.target.value);
        setFollowExitStatusOptions(FollowExitStatusOptions?.map(type => ({ ...type, isChecked: value === "" ? false : type.id === parseInt(value) })))
    };
    const selected = FollowExitStatusOptions?.filter(option => option.isChecked)?.at(0)

    return (
        <div className="border-b-[3px] border-[#E8E6E1]">
            <li
                className={
                    open
                        ? "bg-white relative px-3 py-1"
                        : "relative px-3 py-1"
                }
            >
                <FilterWrapper
                    isSelect={true}
                    label={selected?.name ? "Follow on Funding Status - " + selected?.name : "Follow on Funding Status"}
                    onClick={accordionClick}
                    open={open}
                />

                <div
                    className="relative overflow-hidden transition-all max-h-0 duration-700  overflow-y-auto no-scrollbar"
                    style={{ maxHeight: open ? "10rem" : "" }}
                >

                    <select name="" id="" value={selected?.value} onChange={handleChange}
                        className="my-2 p-2 border border-[#D9BD7F] mr-3 rounded focus:outline-none bg-white w-full">
                        <option value="">All</option>
                        {FollowExitStatusOptions && FollowExitStatusOptions.length > 0 ? (
                            FollowExitStatusOptions.map((exited, index) => (
                                <option key={exited.value} value={exited.id}>{exited.name}</option>
                            )))
                            :
                            (
                                <h1>No results found!</h1>
                            )}
                    </select>
                </div>

            </li>

        </div>
    )
}

export default FollowExitStatusFilter