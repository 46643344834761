import { Fragment, useState ,useContext,useEffect} from 'react'
import { Menu, Transition } from '@headlessui/react'
import SortIconAsc from 'mdi-react/ArrowDropDownIcon'
import {CurrencyContext} from '../../Contexts/CurrencyContext'
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

/* The `CurrencyComponent` function is a React component that renders a dropdown menu for selecting a
currency value. */
function CurrencyComponent() {
    const { currency, setCurrency,currencyType } = useContext(CurrencyContext);
    const [isInr, setisInr] = useState();

    useEffect(()=>{
      if(currencyType == 'INR'){
        setisInr(true)
      }
      else{
        setisInr(false)
      }
    },[currencyType])

    const inrLabel= currencyType==="INR" && currency=='C'?'Crores'
    :currencyType==="INR"&& currency=='A'?'Actual Value'
    :currencyType==="INR" && currency=='L'?'Lakhs'    
    :'Crores'
    const usdLabel= currencyType==="USD" && currency=='M'?'Millions'
    :currencyType==="USD"&& currency=='A'?'Actual Value'
    :'Millions'

    return (
        <>
        {isInr && currencyType==="INR"?
      <Menu as="div" className="relative inline-block text-left">
        
          <Menu.Button className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-[5px] pl-[9px] pr-[3px] text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none whitespace-nowrap ">
          {inrLabel}
            <SortIconAsc className="ml-0 md:ml-2 h-5 w-5" aria-hidden="true" />
          </Menu.Button>
       
  
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 md:w-40 w-24  origin-top-right rounded-md bg-white shadow-lg focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                   <button 
                   onClick={()=>setCurrency('C')}
                   className={classNames(
                     active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                     'block md:px-4 px-1 py-1 md:py-2 text-sm w-full md:text-left text-center font-normal'
                   )}>Crores</button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                   <button 
                   onClick={()=>setCurrency('A')}
                   className={classNames(
                     active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                     'block md:px-4 px-1 py-1 md:py-2 text-sm w-full md:text-left text-center font-normal'
                   )}>Actual Value</button>
                )}
              </Menu.Item>                            
                <Menu.Item>
                  {({ active }) => (
                    <button
                      type="submit"
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block md:px-4 px-1 py-1 md:py-2 text-sm w-full md:text-left text-center font-normal'
                      )}
                       onClick={()=>setCurrency('L')}
                    >
                      Lakhs
                    </button>
                  )}
                </Menu.Item>
              
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
       :
      <Menu as="div" className="relative inline-block text-left">
          <Menu.Button className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-[5px] pl-[9px] pr-[3px] font-medium text-[14px] text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none whitespace-nowrap ">
           {usdLabel}
            <SortIconAsc className="-mr-1 ml-0 md:ml-2 h-5 w-5" aria-hidden="true" />
          </Menu.Button>
  
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 md:w-40 w-24  origin-top-right rounded-md bg-white shadow-lg focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <button 
                  onClick={()=>setCurrency('M')}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-1 md:px-4 py-1 md:py-2 text-sm w-full md:text-left text-center font-normal'
                  )}>Millions</button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                   <button 
                   onClick={()=>setCurrency('A')}
                   className={classNames(
                     active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                     'block px-1 md:px-4 py-1 md:py-2 text-sm w-full md:text-left text-center font-normal'
                   )}>Actual Value</button>
                )}
              </Menu.Item>                                                          
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
        } 
      </>
    )
  }
 export default CurrencyComponent