import React from 'react'
import { useContext } from 'react'
import TextCard from '../../../../Components/ListPageCard/TextCard'
import { TableDataContext } from '../../../../Contexts/TableDataContext'
import CardWrapper from './CardWrapper'

/**
 * The ReInvestmentCard function returns a card component that displays information about PE-RE
 * investors and investments, with the option to show counts.
 * @returns The ReInvestmentCard component is returning a CardWrapper component that contains two
 * TextCard components. The TextCard components display information about PE-RE investors and PE-RE
 * investments. The subtitle of each TextCard component is determined by the data received from the
 * TableDataContext. The isLoading prop is used to indicate whether the data is still loading.
 */
function ReInvestmentCard() {
    const { data, isDataLoad } = useContext(TableDataContext);
    return (
        <CardWrapper>
            <TextCard
                title="PE-RE Investors"
                subtitle={data?.num_investors}
                isLoading={isDataLoad}
                showCount={true}
            />
            <TextCard
                title="PE-RE Investments"
                subtitle={data?.num_investments}
                isLoading={isDataLoad}
                showCount={true}
            />
            {/* <TextCard
                title="Most Active Investors"
                // subtitle="sssss"
                isLoading={isDataLoad}
                showCount={false}
            />
            <TextCard
                title="New Investors"
                // subtitle="sssss"
                isLoading={isDataLoad}
                showCount={false}
            /> */}
        </CardWrapper>
    )
}

export default ReInvestmentCard