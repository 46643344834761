import { Fragment, useState, useContext } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { SearchMenuContext } from "../Contexts/SearchMenuContext";
import { useNavigate } from "react-router-dom";
import closeIcon from "../images/search_close_button.png";

/** The above code is a React component called `ChatPopup`. It renders a chat popup window with a search
functionality. */
const ChatPopup = (props) => {
  const navigate = useNavigate();
  const [open] = useState(true);
  const PEHeaderSearchMenu = useContext(SearchMenuContext);
  const [value, setValue] = useState(PEHeaderSearchMenu);
  const [FilteredData, setFilteredData] = useState(PEHeaderSearchMenu);
 
  /**
   * The searchMenuSelect function navigates to a specific page based on the selected item's name and
   * id.
   */
  const searchMenuSelect = (item) => {
    navigate("/" + item.name.toLowerCase() + "/" + item.id);
  };

  /**
   * The function `onchangeSearchMenu` is used to filter data based on a search input value and update
   * the filtered data state.
   */
  const onchangeSearchMenu = (e) => {
    setValue(e.target.value);
    setFilteredData([]);
    let resultArray = [];
    PEHeaderSearchMenu?.data.map((arr, i) => {
      if (arr?.label.toLowerCase().match(e.target.value)) {
        resultArray.push({ id: arr.id, name: arr.name, label: arr.label });
      }
    });
    setFilteredData(resultArray);
  };

  const placeholder =  PEHeaderSearchMenu.placeholder

  /**
   * The function `resetInput` sets the value of an input field to an empty string.
   */
  const resetInput = () => {
    setValue(" ") 
  }


  // const clearSearchText = () => {
  //   setSearchText("")
  //   setValue("");
  //   document.getElementById("myInput").innerHTML("")
  // }

  
  return (
    <div className=" ">
      <div>
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => props.setShowChat(!props?.showChat)}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0  bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <div className=" fixed inset-0 z-10  md:mt-0 lg:mt-0">
              <div className="fl">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4  sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel
                    className="relative transform overflow-hidden rounded-lg bg-[#EAEAEA] md:hidden
               "
                  >
                    <form
                      
                    >
                      <div className="flex  bg-white ">
                        <button
                          onClick={() => props?.setShowChat(!props?.showChat)}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" fill="#bc8b21" viewBox="0 0 24 24" stroke-width="1.5" stroke="#bc8b21" className="w-6 h-6 ml-2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                          </svg>

                        </button>
                        <input
                          id="myInput"
                          type="text"
                          value={value}
                          className="w-full outline-none  p-3 "
                          onChange={onchangeSearchMenu}
                          placeholder={placeholder}
                        />
                        {value.length >= 3 && (
                        <button
                          type="button"
                          className=" m-3  bg-white  "
                          // onClick={myFunction}
                          onClick={resetInput}
                        >
                          <span className="sr-only">Close</span>
                          <img
                            src={closeIcon}
                            className="w-8 h-6  "
                            alt="closeIcon"
                          />
                        </button>
                        )}
                        <button className=" bg-[#bc8b21]">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" className="w-9 h-9 text-[white] p-2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 13.5V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 9.75V10.5" />
                          </svg>

                        </button>
                      </div>

                      {value.length >= 3 && (
                        <div className=" w-full bg-white grid border border-[#CCCCCC] ">
                          {FilteredData.map((item, i) => {
                            return (
                              <div
                                onClick={() => searchMenuSelect(item)}
                                className=" px-4 py-2 justify-between flex hover:bg-[#F2F2F2] text-[#333333] tracking-[-0.28px] leading-[18px] text-[15px]"
                              >
                                <div
                                  style={{
                                    background: i ? "" : "[#F2F2F2]",
                                  }}
                                  key={item.id}
                                >
                                  {item.label}
                                </div>
                                <div
                                  style={{
                                    background: i ? "" : "[#F2F2F2]",
                                  }}
                                  key={item.id}
                                >
                                  {item.name}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </div>
  );
};

export default ChatPopup;
