import { Fragment, React, useState, useEffect, useContext } from 'react'
import { alertListApi, createAlertListApi, updateAlertListApi } from '../../Config/config'
import axios from "axios";
import { toast } from "react-toastify";
import { Dialog, Transition } from "@headlessui/react";
import PrimaryButton from './PrimaryButton';
import { UserContext } from '../../Contexts/UserContext';



const AlertPopUp = (props) => {
    let userId = "10";
    const [alertList, setAlertList] = useState()
    const [createAlertList, setCreateAlertList] = useState()
    const [checked, setChecked] = useState(new Set())
    const [isUpdating, setIsUpdating] = useState(false);
    const {getToken} = useContext(UserContext);


    const onAddingAlert = (event) => {
        if (event.target.checked) {
            setChecked(prev => ([...prev, parseInt(event.target.id)]))
        } else {
            setChecked(prev => {
                let temp = new Set(prev)
                temp.delete(parseInt(event.target.id))
                return temp;
            })
        }
    };




    const listAlert = () => {
        axios(`${alertListApi}`, {
            method: "POST",
            data: {
                "user_id": userId,
                "type_id": props.type_id,
                "entity_id": props.cid
            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": getToken()
            },
        })
            .then((response) => {
                // console.log(response, 'alert list api response');
                if (response?.data?.success == true) {
                    setAlertList(response?.data)
                    let selectedAlerts = response?.data?.alerts?.filter(alert => alert.selected)?.map(sAlert => sAlert.id);
                    console.log(selectedAlerts, "alertcheck");
                    setChecked(selectedAlerts)
                }
            })
            .catch((error) => {
                console.log("API not working", error);
            });

    }

    useEffect(() => {
        listAlert()
    }, [props.show]);

    const saveAlert = () => {

        if (Array.from(checked).findIndex(value => value) === -1) {
            toast.warning("Please select alerts")
            return
        }
        axios(`${createAlertListApi}`, {
            method: "POST",
            data: {
                "user_id": userId,
                "alert_module_id": Array.from(checked),
                "entity_id": props.cid,
                "type_id": props.type_id
            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": getToken()
            },
        })
            .then((response) => {
                console.log(response, 'create alert list api response');
                if (response?.data?.success == true) {
                    setCreateAlertList(response?.data)
                    toast.success(response?.data?.message)
                    props.onClose(true);
                }

                else {
                    toast.warning(response?.data?.message)

                }
            })
            .catch((error) => {
                console.log("API not working", error);
            });

    }

    const updateAlert = () => {
        setIsUpdating(true);
        axios(`${updateAlertListApi}`, {
            method: "POST",
            data: {
                "user_id": userId,
                "alert_module_id": Array.from(checked),
                "entity_id": props.cid,
                "type_id": props.type_id
            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": getToken()
            },
        })
            .then((response) => {
                setIsUpdating(false);
                if (response?.data?.success == true) {
                    toast.success(response?.data?.message)
                    props.onClose(true);
                }
                else {
                    toast.warning(response?.data?.message)

                }
            })
            .catch((error) => {
                console.log("API not working", error);
            });


    }
    return (

        <Transition.Root show={props.show} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-10"
                onClose={props.onClose}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="items-center flex min-h-full  justify-center p-4 text-center ">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className="origin-top-right absolute z-10 mr-[5rem] lg:mr-[6rem] right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">

                                <div >
                                    <div className=" p-5 space-y-2 font-sans_book_body" onClose={props.onClose}>
                                        {alertList?.alerts?.length > 0 ?
                                            alertList?.alerts?.map((alert, i) => {
                                                return (
                                                    <div className="relative flex items-start" key={alert.id}>
                                                        <div className="flex h-5 items-center">
                                                            <input
                                                                id={alert.id}
                                                                aria-describedby="comments-description"
                                                                name="comments"
                                                                type="checkbox"
                                                                className="h-4 w-4 rounded border-gray-300 text-[#BC8B20]"
                                                                value={alert.alert_name}
                                                                defaultChecked={alert.selected}
                                                                onChange={onAddingAlert}
                                                            />
                                                        </div>
                                                        <div className="ml-3 text-sm">
                                                            <label htmlFor={alert.id} className=" ">
                                                                {alert.alert_name}
                                                            </label>
                                                        </div>
                                                    </div>
                                                )

                                            })
                                            : <div className="w-full bg-[#FFFFFF]">
                                                <center>
                                                    <div role="status" className=" h-[8rem]">
                                                        <svg className="relative top-[1rem] inline mr-2 w-12 h-12 text-gray-200 animate-spin dark:text-gray-600 fill-[#CA8A24]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                                        </svg>
                                                    </div>
                                                </center>
                                            </div>
                                        }
                                    </div>
                                    <div className="flex items-center justify-center mb-5">
                                        <button
                                            type="button"
                                            disabled={isUpdating}
                                            // disabled={alertList?.method === "update" ? false: Array.from(checked).findIndex(value => value) === -1 }
                                            className="bg-[#BC8B20] w-[122px] h-[31px] text-[#FFFFFF] rounded-[3px]  font-sans_roman_body leading-[17px] "
                                            onClick={() => alertList?.method === "update" ? updateAlert() : saveAlert()}>
                                            {alertList?.method === "update" ? "Update Alert" : "Set Alert"}
                                        </button>
                                    </div>
                                </div>

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>

    );
};
export default AlertPopUp;


/* The `AlertManager` is responsible for managing alerts for
a specific user and entity. */
const AlertManager = ({ cid, type_id, onClose, primaryMasterId }) => {
    const [checked, setChecked] = useState(new Set())
    const [alertList, setAlertList] = useState()
    const [isUpdating, setIsUpdating] = useState(false)
    const {getToken,getUser} = useContext(UserContext);
    
    let userId = getUser().id;

    /**
     * The function `onAddingAlert` updates the `checked` state by adding or removing an ID from the
     * array based on whether a checkbox is checked or unchecked.
     */
    const onAddingAlert = (event) => {
        if (event.target.checked) {
            setChecked(prev => ([...prev, parseInt(event.target.id)]))
        } else {
            setChecked(prev => {
                let temp = new Set(prev)
                temp.delete(parseInt(event.target.id))
                return temp;
            })
        }
    };

    const listAlert = () => {
        axios(`${alertListApi}`, {
            method: "POST",
            data: {
                "user_id": userId,
                "type_id": type_id,
                "entity_id": cid,
                primary_type_master_id:primaryMasterId,
            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": getToken()
            },
        })
            .then((response) => {
                // console.log(response, 'alert list api response');
                if (response?.data?.success == true) {
                    setAlertList(response?.data)
                    let selectedAlerts = response?.data?.alerts?.filter(alert => alert.selected)?.map(sAlert => sAlert.id);
                    console.log(selectedAlerts, "alertcheck");
                    setChecked(selectedAlerts)
                }
            })
            .catch((error) => {
                console.log("API not working", error);
            });

    }

    const updateAlert = () => {
        setIsUpdating(true);
        axios(`${updateAlertListApi}`, {
            method: "POST",
            data: {
                "user_id": userId,
                "alert_module_id": Array.from(checked),
                "entity_id": cid,
                "type_id": type_id,
                primary_type_master_id:primaryMasterId,
            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": getToken()
            },
        })
            .then((response) => {
                setIsUpdating(false);
                if (response?.data?.success == true) {
                    toast.success(response?.data?.message)
                    onClose(true);
                }
                else {
                    toast.warning(response?.data?.message)

                }
            })
            .catch((error) => {
                console.log("API not working", error);
            });


    }

    useEffect(() => {
        listAlert();
    }, [])


    return <div className=''>
        {alertList?.alerts?.length > 0 ?
            alertList?.alerts?.map((alert, i) => {
                return (
                    <div className="relative flex p-1 items-start" key={alert.id}>
                        <div className="flex h-5 items-center">
                            <input
                                id={alert.id}
                                aria-describedby="comments-description"
                                name="comments"
                                type="checkbox"
                                className="h-4 w-4 rounded border-gray-300 text-[#BC8B20]"
                                value={alert.alert_name}
                                defaultChecked={alert.selected}
                                onChange={onAddingAlert}
                            />
                        </div>
                        <div className="ml-3 text-sm">
                            <label htmlFor={alert.id} className=" ">
                                {alert.alert_name}
                            </label>
                        </div>
                    </div>
                )

            })
            : <div className="flex items-center justify-center w-40 bg-[#FFFFFF]">
                <div role="status" className=" h-[8rem]">
                    <svg className="relative top-[1rem] inline mr-2 w-12 h-12 text-gray-200 animate-spin dark:text-gray-600 fill-[#CA8A24]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                    </svg>
                </div>
            </div>
        }
        {alertList?.alerts?.length > 0
            ? <div className='w-full mt-2'>
                <PrimaryButton disable={isUpdating} onClick={() => updateAlert()} className="w-[7rem] md:w-[75%] lg:w-[75%]">
                    Update
                </PrimaryButton>
            </div>
            : <></>}
    </div>
}

export { AlertManager };