import React from 'react';

/* The code snippet is defining a functional component called `NumberComponent` in JavaScript using
React. */
const NumberComponent = ({ child, selectedChild, setSelectedChild }) => {
  const childIndex = child.uniqueId;
  console.log(child.values,selectedChild,"child.values");
 /**
  * The handleChange function updates the value of a selected child based on the entered value from an
  * event.
  * @param event - The event parameter is an object that represents the event that triggered the
  * handleChange function. It contains information about the event, such as the target element and the
  * value entered by the user.
  */
  const handleChange = (event) => {
    const enteredValue = event.target.value;
    const childIndexx = selectedChild.findIndex((selectedChild) => selectedChild.id === child.id && selectedChild.uniqueId === childIndex);
    const updatedChild = { ...selectedChild[childIndexx] };
    updatedChild.values = [enteredValue];
    const updatedSelectedChild = [...selectedChild];
    updatedSelectedChild[childIndexx] = updatedChild;
    setSelectedChild(updatedSelectedChild);
  };


/**
 * The function `blockInvalidCharForNumber` prevents the input of certain characters in a text field,
 * specifically 'e', 'E', '+', and '-'.
 * @param e - The parameter `e` is an event object that represents the event that triggered the
 * function. In this case, it is likely an event object related to a key press event.
 * @returns The function does not explicitly return anything.
 */
 const blockInvalidCharForNumber = (e) => {
  console.log(e.key,"kjmnkj");
  if(['e', 'E', '+', '-'].includes(e.key)){
      e.preventDefault()
  }
  else return
}


  return (
    <input
    key={childIndex}
      type="number"
      name={child.id}
      onChange={handleChange}
      onKeyDown={blockInvalidCharForNumber}
      value={child.values[0]}
      placeholder="Search..."
      className="p-2 border border-[#D9BD7F] rounded focus:outline-none bg-[#FFFFFF] w-[12rem]"
    />
  );
};

export default NumberComponent;
