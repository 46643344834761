import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import TextCard from '../../../../Components/ListPageCard/TextCard'
import { reInvestorTopcard } from '../../../../Config/config';
import Constants from '../../../../Constants';
import { UserContext } from '../../../../Contexts/UserContext';
import useModule from '../../../../hooks/useModule';

/**
 * The `ReInvestorDetailCard` function is a React component that fetches data from an API and displays
 * it in a card format.
 * @returns The function `ReInvestorDetailCard` returns JSX elements, specifically a `<div>` element
 * with nested elements inside.
 */
function ReInvestorDetailCard() {
    const { getToken, logout } = useContext(UserContext);
    const { primaryMasterId } = useModule()
    const { reinvestorId } = useParams()
    const [CardData, setCardData] = useState({})
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        const controller = new AbortController()
        setIsLoading(true);
        axios.post(reInvestorTopcard, {
            "investor_id": reinvestorId,
            "type": primaryMasterId,
        }, {
            signal: controller.signal,
            headers: {
                'Authorization': getToken(),
            }
        }).then(response => {
            setIsLoading(false);
            if (response.status === 200) {
                setCardData(response.data ?? {});
            }
        }).catch(error => {
            setIsLoading(false);
            if (error?.response?.status === Constants.sessionTerminatedStatus) {
                logout()
            }
        })

        return () => controller.abort()
    }, [getToken, logout, primaryMasterId, reinvestorId])

    return (
        <div className="">
            <div className='sm:full mt-[4.25rem] md:mt-[3rem] h-full  bg-[#F2F2F2]'>
                <div className="overflow-x-auto lg:grid grid-cols-5 gap-4  px-4 py-[0.7rem] flex xl:overflow-hidden">
                    <TextCard
                        title="Projects"
                        subtitle={CardData?.projects}
                        isLoading={isLoading}
                        showCount={true}
                    />
                    <TextCard
                        title="Total Funding"
                        subtitle={CardData?.total_funding !='$ 0 M' ? CardData?.total_funding :'--'}
                        isLoading={isLoading}
                        showCount={false}
                    />
                   
                    <TextCard
                        title="Latest Project"
                        subtitle={CardData?.latest_project}
                        isLoading={isLoading}
                        showCount={false}
                    />
                    <TextCard
                        title="Exits"
                        subtitle={CardData?.exits}
                        isLoading={isLoading}
                        showCount={true}
                    />
                    <TextCard
                        title="Latest Exit"
                        subtitle={CardData?.latest_exit}
                        isLoading={isLoading}
                        showCount={false}
                    />

                </div>
                <div className='border-b-2 border-[#e5e5e5]'></div>

            </div >
        </div >
    )
}

export default ReInvestorDetailCard