import { React, useState, useMemo } from 'react'
import { NavLink } from 'react-router-dom';
import { useQuery } from '@apollo/client'
import { useSearchParams, Routes, Route, useParams } from 'react-router-dom';
import { descriptionCharLimit } from "../../Config/config"
import DetailInformation from '../IncubationDealPage/IncubationCompanyDetailInformation';
import DealInfo from './IncubationDealInfo'
import ValuationInfo from './IncubationValuationInfo'
import InvestorInfo from './IncubationInvestorInfo'
 
import dataJson from './DealData.json'

/* The code defines a functional component called `IncubationCompanyDealDetail`. It takes a prop called
`COMPANY_PROFILE`. Inside the component, it initializes several state variables using the `useState`
hook. These state variables include `companyProfile`, `companyProfileTab`, `companyDealDetail`,
`companyDealDetailTab`, `showModal`, `isActive`, `showAdd`, `showPopupPDF`, and `showPopupXLS`. */
const IncubationCompanyDealDetail = ({ COMPANY_PROFILE }) => {

    const [companyProfile, setCompanyProfile] = useState({});
    const [companyProfileTab, setCompanyProfileTab] = useState({});
    const [companyDealDetail, setCompanyDealDetail] = useState(dataJson.data);
    const [companyDealDetailTab, setCompanyDealDetailTab] = useState();
    const [showModal, setShowModal] = useState(false);
    const [isActive, setIsActive] = useState(1);
     const [showAdd, setShowAdd] = useState(false)
     const [showPopupPDF, setShowPopupPDF] = useState(false);
     const [showPopupXLS, setShowPopupXLS] = useState(false);
     let longText = " BarRaiser, a Bengaluru ,based.AI powered smart Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat culpa unde nostrum vel nihil amet minus laboriosam voluptas placeat natus laudantium eligendi quam earum tempora temporibus, sapiente fuga Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat culpa unde nostrum vel nihil amet minus."
     const charLength = descriptionCharLimit;
    const [searchParams] = useSearchParams();
    const { companyId, dealId } = useParams();
    let companyProfileId = parseInt(companyId)
    /**
     * The function handles the callback response from a fetch request for company details and sets the
     * company profile state variable.
     */
    const handleFetchCompanyDetailRequestCallback = (response, requestSuccess) => {
        try {
            setCompanyProfile(response.company);
            console.log('companyProfile', companyProfile);
        } catch (e) {
            console.log('Error', e)
        }
    };
    /* The code snippet is using the `useQuery` hook from the `@apollo/client` library to make a
    GraphQL query to fetch data for the `COMPANY_PROFILE` query. */
    const { data, loading, error } = useQuery(COMPANY_PROFILE, {
        variables: {PECompanyId: companyProfileId},
        onCompleted: (response) => handleFetchCompanyDetailRequestCallback(response, true),
        onError: (response) => handleFetchCompanyDetailRequestCallback(response),
        refetch: COMPANY_PROFILE
    });

     


     
    
    const companyProfileData = useMemo(() => companyProfile, [companyProfile]);

 
 
    console.log(companyProfileData,"1234567890")
  

    
    return (
        <>
            <div className="bg-[#FFFFFF]">
                
                {/* <!-- BarRaiser--> */}
                <DetailInformation data={companyProfileData}/>
                
                 
            </div>
            <div className="bg-[#EDEDED] h-full">
                <div className="flex flex-row space-x-3 mt-3 justify-center lg:justify-start ml-6">
                    <a className={
                        "inline-block px-3 py-1 text-[13px] cursor-pointer leading-[16px]  rounded-[13.5px] " +
                        (isActive === 1
                            ? "text-[#FFFFFF] font-sans_semi_bold bg-[#BC8B20] border border-[#BC8B20]"
                            : "text-[#BC8B20] font-sans_medium_body border border-[#BC8B20]")
                    }
                        onClick={e => {
                            e.preventDefault();
                            setIsActive(1);
                        }}
                        data-toggle="tab"
                        href="#link1"
                        role="tablist">
                        Feb 2018
                    </a>
                    <a className={
                        "inline-block px-3 py-1 text-[13px] cursor-pointer leading-[16px]  rounded-[13.5px] " +
                        (isActive === 2
                            ? "text-[#FFFFFF] font-sans_semi_bold bg-[#BC8B20] border border-[#BC8B20]"
                            : "text-[#BC8B20] font-sans_medium_body border border-[#BC8B20]")
                    }
                        onClick={e => {
                            e.preventDefault();
                            setIsActive(2);
                        }}
                        data-toggle="tab"
                        href="#link2"
                        role="tablist">
                        Dec 2018
                    </a>
                </div>
                
               <div className="w-full lg:grid  gap-6 pl-6 pr-2 pt-0 lg:pt-4">
                    <div
                        className=" mt-3 lg:mt-0 rounded-[4px] lg:rounded w-full ">
                        <div
                            className="w-full lg:grid lg:grid-rows-8 gap-5">
                            <div
                                className="overflow-x-auto row-span-3 py-4 bg-[#FFFFFF] shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] rounded-[4px] mb-3">
                                <DealInfo />
                            </div>
                             
                        </div>
                    </div>
                     
                </div>
            </div>
        </>
    )

}

export default IncubationCompanyDealDetail