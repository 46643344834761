import axios from 'axios'
import React, { useState, useMemo, useEffect, useContext } from "react";
import ConfirmPopup from '../../Utils/ConfirmPopup'
import xls from "../../images/download-file-icon.png";
import Constants from '../../Constants';
import { UserContext } from "../../Contexts/UserContext";


/** The `ExcelDownloader` component that allows users to download an Excel file. */
function ExcelDownloader({ url, data, children, message, fileName = "file", type = "xlsx" }) {
    const [alert, setAlert] = useState({ show: false, message: message || Constants.excelPopupMessage })
    const [isDownloading, setIsDownloading] = useState(false);

    const { getToken, logout } = useContext(UserContext);
    const [reachedLimitPopup, setReachedLimitPopup] = useState({ show: false, message: "" })


    const onChange = async (flag) => {
        if (flag === 1) {
            const result = await downloadExcel()
            if (result === true) {
            } else {
                setReachedLimitPopup({ show: true, message: result })
            }
        }
        setAlert(prev => ({ ...prev, show: false }))
    }

    const downloadExcel = async() => {
        setIsDownloading(true);
        const result = await axios.post(url, data,
            {responseType: 'blob' ,
                headers: {
                    'Authorization': getToken(),
                }
            },
        ).then((response) => {
            setIsDownloading(false);
            if (response.status === 200) {
                console.log("haederhaederhaeder", response.headers);
                const href = URL.createObjectURL(response.data);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', `${fileName}.${type}`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
                return true
            }

        })
        .catch(async (error) => {
            setIsDownloading(false);
            console.log("API not working", error);
            if (error?.response?.status == Constants.sessionTerminatedStatus) {
                logout()
            } else if (error?.response?.status == 400) {
                const dataAsText = await error?.response?.data?.text()
                const parsedData = JSON.parse(dataAsText)
                return parsedData?.message
            }
        });
        return result

    }


    if (reachedLimitPopup.show) {
        return <div className='flex font-sans_book_body cursor-default justify-center items-center z-10 fixed left-0 top-0 w-screen h-screen  bg-gray-500 bg-opacity-75 transition-opacity'>
            <div className='relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 mx-4 md:mx-0 sm:w-full sm:max-w-lg'>
                <div className='w-full flex flex-col '>
                    <div className="mt-3 text-center sm:mt-0 px-5 py-3 sm:ml-4 sm:text-left">
                        <h1 className='text-left py-1 text-lg font-bold leading-6 text-gray-900'>Alert!</h1>
                        <div className="mt-4 ">
                            <p className="text-sm text-gray-500 text-start">{reachedLimitPopup.message}</p>
                        </div>
                    </div>
                </div>
                <div className='flex bg-gray-50 px-4 pb-3 flex-row-reverse'>
                    <button onClick={() => setReachedLimitPopup(prev => ({ ...prev, show: false }))} className='inline-flex w-full justify-center font-sans_bold_body rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none  sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'>
                        Close
                    </button>
                </div>
            </div>
        </div>
    }
    return (
        <div>
            <ConfirmPopup {...alert} onChange={onChange} icon={<></>} activeBtnName={isDownloading ? "Downloading" : "Download"} closeBtnName='Close' title='Export' disable={isDownloading}/>
            <span onClick={() => setAlert(prev => ({ ...prev, show: true }))}>
                {children || <img src={xls} className="cursor-pointer mr-1 md:mr-2 h-[26px] w-[24px]" alt="" />}
            </span>
        </div>
    )
}

export default ExcelDownloader