import { React, useState, useMemo, useContext } from 'react'
import Table from './Table'
import { TableHeaderContext } from '../../Contexts/TableHeaderContext'

/**
 * The TableComponent function returns a table component based on the props passed to it, with
 * different columns and pagination options depending on the menu and advisor type.
 * @param props - The `props` parameter is an object that contains the properties passed to the
 * `TableComponent` component. These properties can be accessed using dot notation, such as
 * `props.menu`, `props.advisor_type`, and `props.sub_type_master_id`.
 * @returns The TableComponent is returning a div element containing a Table component. The Table
 * component is being passed various props such as order, orderBy, menu, columns, advisor_type,
 * pagination, and sub_type_master_id.
 */

/**
 * The `TableComponent` function renders a table component based on the provided props, including the
 * table headers and pagination data.
 * @param props - The `props` parameter is an object that contains the properties passed to the
 * `TableComponent` component. These properties can be accessed using dot notation, such as
 * `props.menu`, `props.advisor_type`, and `props.sub_type_master_id`.
 * @returns The TableComponent is being returned.
 */
const TableComponent = (props) => {
   
const {
    PEREAdvisorTableHeadder,PEReAdvisorExitTableHeader,AdvisorOtherMATableHeader,
    advisororderingColumn,
}=useContext(TableHeaderContext)

// const [investments, setInvestments] = useState([]);
const [pagination, setPagination] = useState()

// const tableRowData = useMemo(() => investments, [investments]);  
const paginationData = useMemo(() => pagination, pagination); 
 

/* The code `const columns = props.menu == "re-ladvisor" ? PEREAdvisorTableHeadder 
    : props.menu == "re-advisorexit" ? PEReAdvisorExitTableHeader 
   
    : props.menu == "re-advisor-otherma" ? AdvisorOtherMATableHeader
   
    :PEREAdvisorTableHeadder` is using a ternary operator to assign a value to the `columns`
variable based on the value of `props.menu`. */
const columns = props.menu == "re-ladvisor" ? PEREAdvisorTableHeadder 
    : props.menu == "re-advisorexit" ? PEReAdvisorExitTableHeader 
   
    : props.menu == "re-advisor-otherma" ? AdvisorOtherMATableHeader
   
    :PEREAdvisorTableHeadder

    return (
        <div className="pl-3 pr-2 lg:pr-2 md:pr-3 overflow-y-auto ">
            <div className="App lg:relative">
            <Table order="asc" orderBy={advisororderingColumn} menu={props?.menu} columns={columns} advisor_type={props?.advisor_type} pagination = {paginationData} sub_type_master_id={props?.sub_type_master_id}/>
            </div>
        </div>
    )
}

export default TableComponent