import React, { useState, useContext, useEffect, useRef } from "react";
import { HeaderMenuContext } from "../../Contexts/HeaderMenuContext";
import { SearchMenuContext } from "../../Contexts/SearchMenuContext";
import { NavLink, useNavigate, useLocation, Outlet, useMatch } from "react-router-dom";
import v2logo from "../../images/Bitmap/png/Bitmap.png";
import shape from "../../images/Shape_13/png/Shape.png";
import logowidget from "../../images/images/png/images.png";
import MobileMenu from "../../Components/MobileMenu";
import Search from "../../images/Shape_13/png/Shape.png";
import Profile from "../../Components/Profile";
import Notifications from ".././NotificationSettings/NotificationsSetting";
import MobileSearch from ".././MobileSearch";
import PopOverSearch from "../../Components/PopOverSearch"
// import NotificationsSetting from "./NotificationSettings/NotificationsSetting";
import "../../Components/HeaderComponent.css";
import routePathSegments from "../../routeSegments";
import BellNotification from "./BellNotification";

/**
 * The `FaqNavbarWrapper` component is a React component that renders a navigation bar with various
 * functionalities such as search, menu, notifications, and user profile.
 * @returns The FaqNavbarWrapper component is returning a JSX fragment containing the navbar elements.
 */
const FaqNavbarWrapper = ({ children }) => {
    const navigate = useNavigate();
    const { headerMenu } = useContext(HeaderMenuContext);
    const PEHeaderSearchMenu = useContext(SearchMenuContext);
    const [value, setValue] = useState("");
    const [showText, setShowText] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const [FilteredData, setFilteredData] = useState(PEHeaderSearchMenu.data);
    const [popup, setPopup] = useState(false);
    const [popOver, setPopOver] = useState(false);
    const [currentTab, setcurrentTab] = useState();
    const [type, setType] = useState();
    const [searchLabel, setsearchLabel] = useState('Search for companies / Investors / Legal Advisors/...')
    const [searchInput, setSearchInput] = useState("");
    const location = useLocation();

    // console.log(FilteredData, "1234567890as");
    // console.log(PEHeaderSearchMenu, "1234567890as");

    /**
     * The searchMenuSelect function navigates to a specific page based on the selected item's name and
     * id.
     */
    const searchMenuSelect = (item) => {
        // console.log(item, "1234567890as");
        navigate("/" + item.name.toLowerCase() + "/" + item.id);
    };
    /**
     * The function `onchangeSearchMenu` updates the value of a search input, filters an array of data
     * based on the search value, and sets the filtered data in the state.
     */
    const onchangeSearchMenu = (e) => {
        setValue(e.target.value);
        setFilteredData([]);
        let resultArray = [];
        PEHeaderSearchMenu?.data.map((arr, i) => {
            if (arr?.label.toLowerCase().match(e.target.value)) {
                resultArray.push({ id: arr.id, name: arr.name, label: arr.label });
            }
        });
        console.log(resultArray, "qwertyuiop");
        setFilteredData(resultArray);
    };

    /* The `useEffect` hook is used to perform side effects in a React functional component. In this
    case, the `useEffect` hook is used to update the state variables `type` and `searchLabel` based
    on the value of `location.pathname`. */
    useEffect(() => {
        if (location.pathname.match("limited-partners")) {
            setType("limited_partners")
            setsearchLabel("Type LP or Investor name..");
        } else if (location.pathname.match("funds")) {
            setType("funds");
            setsearchLabel("Type Investor or Fund name..");
        } else {
            setsearchLabel("Search for companies / Investors / Legal Advisors/...");
            setType('pe_primary')
            if (location.pathname.match("compan")) {
                setcurrentTab('company')
            } else if (location.pathname.match("invest")) {
                setcurrentTab('investor')
            } else if (location.pathname.match("transaction")) {
                setcurrentTab('t_advisor')
            } else if (location.pathname.match("advisors")) {
                setcurrentTab('l_advisor')
            } else {
                setcurrentTab('company')
            }
        }
    }, [location.pathname])
    const menuRef = useRef();

    /* The `useEffect` hook is used to add an event listener to the document for the "mousedown" event.
    This event listener checks if the clicked target is outside the `menuRef` element. If it is
    outside, it sets the `showText` state to false. This is typically used to close a dropdown menu
    or hide a modal when the user clicks outside of it. */
    useEffect(() => {

        document.addEventListener("mousedown", (event) => {
            if (!menuRef?.current?.contains(event.target)) {
                setShowText(false)
                // setShowModal(false)

            }
        })
    })

    return (
        <>
            
            <PopOverSearch show={popOver} onClose={() => setPopOver(false)} type={type} searchFrom={currentTab} searchInput={searchInput} setSearchInput={setSearchInput}/>

            <div className=" lg:fixed lg:z-[99] w-full lg:left-0 relative bg-white">
                <div className=" bg-white">
                    <div className=" w-full fixed z-[10] ">
                        <div className="relative flex-shrink-0  bg-white border-b border-gray-200  ">
                            <div>
                                <div className="flex  lg:px-0 justify-between items-center lg:pr-2">
                                    <div className="flex">
                                        {/* <SideBarApp />   */}
                                        <div className="md:hidden ">
                                            <span className="sr-only">Open sidebar</span>
                                            <MobileMenu />
                                        </div>
                                        <div
                                            className="bg-white rounded-full px-2 py-1 flex text-sm  "
                                            id="user-menu-button"
                                            aria-expanded="false"
                                            aria-haspopup="true"
                                        >
                                            <img
                                                className="h-9 w-21 rounded-full"
                                                src={v2logo}
                                                alt=""
                                            />
                                        </div>

                                        <p className="border-r border-gray-200 h-[3rem]"></p>
                                        
                                    </div>

                                    <div>
                                        <div className=" md:flex items-center justify-between xl:pl-0">
                                            <nav className="hidden md:flex space-x-10 px-4">
                                                <section>
                                                    <div
                                                        className="hidden lg:flex flex-col items-stretch items-center  w-full "
                                                        x-data="{tab: 1}"
                                                    >
                                                        <div className="flex flex-row items-center gap-5 w-full">
                                                            {children}
                                                        </div>
                                                    </div>
                                                </section>
                                            </nav>
                                            <div className="flex space-x-4 mr-3 items-center">
                                                <p className="md:border-r border-gray-200 h-[3rem] "></p>
                                                <div className="  md:hidden py-5">
                                                    <div className="">
                                                        <div className="">
                                                        <img
                                                            className=" text-gray-500 w-[1.5rem] float-right"
                                                            src={shape}
                                                            onClick={() => setPopOver(!popOver)}
                                                        />                              
                                                        </div>
                                                    </div>
                                                    </div>
                                                    <BellNotification />                       
                                                    <div className="" ref={menuRef}>
                                                    <Profile />
                                                    </div>
                                            </div>
                                        </div>

                                        {/* profile and notification end */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};
export default FaqNavbarWrapper;
