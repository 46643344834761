import React, {useEffect, useState } from 'react'
import ArchieveContent from "./ArchieveContent";
import Loader from "../Loader";

const ArchieveSideMenu=({archiveData,isLoading})=> {
  const [openTab, setOpenTab] = useState(0);
  const [openTab1, setOpenTab1] = useState(0);
  const [sideMenu, setSideMenu] = useState(0);
  const [sideMenu1, setSideMenu1] = useState(0);
  const [sideMenuHeader, setSideMenuHeader] = useState(0);
  const [activeTab, setActiveTab] = React.useState(0);
  const [select, setSelected] = useState(1);
  const [show, setShow] = useState(false);

 /**
  * The function `accordionClick` is used to handle the click event on an accordion component and
  * update the selected value based on the clicked value.
  */
  const accordionClick = (val) => {
    // setShow(!show);
    if (val == select) {
      setSelected(0);
    } else {
      if(val == 1){
        if(sideMenu == 0){
          setSideMenuHeaderData('annualReport','PrivateEquityReports');
        }else{
          setSideMenuHeaderData('annualReport',sideMenu);
        }
      }else{
        if(sideMenu1 == 0){
          setSideMenuHeaderData('quarterlyReport','PrivateEquityReports');
        }
        else{
          setSideMenuHeaderData('quarterlyReport',sideMenu1);
        }
      }
      setSelected(val);
    }
  };

  useEffect(() => {
   setOpenTab(0);
   setSideMenuHeader(archiveData?.annualReport?.PrivateEquityReports);
   setSelected(1);
  },[archiveData])

/**
 * The function `setSideMenuHeaderData` sets the side menu header data based on the input values `val`
 * and `datas`.
 */
const setSideMenuHeaderData = (val,datas) => {
  if (val == 'annualReport') {
    if(datas == 'PrivateEquityReports'){
      setSideMenuHeader(archiveData.annualReport.PrivateEquityReports);
    }else if(datas == 'PrivateEquityRealEstateReports'){
      setSideMenuHeader(archiveData.annualReport.PrivateEquityRealEstateReports);
    }else if(datas == 'VentureCapitalReports'){
      setSideMenuHeader(archiveData.annualReport.VentureCapitalReports);
    }else if(datas == 'SocialVCReports'){
      setSideMenuHeader(archiveData.annualReport.SocialVCReports);
    }else if(datas == 'PrivateEquityExitReports'){
      setSideMenuHeader(archiveData.annualReport.PrivateEquityExitReports);
    }
  } 
  else if(val == 'quarterlyReport'){
    if(datas == 'PrivateEquityReports'){
      setSideMenuHeader(archiveData.quarterlyReport.PrivateEquityReports);
    }else if(datas == 'PrivateEquityRealEstateReports'){
      setSideMenuHeader(archiveData.quarterlyReport.PrivateEquityRealEstateReports);
    }else if(datas == 'VentureCapitalReports'){
      setSideMenuHeader(archiveData.quarterlyReport.VentureCapitalReports);
    }else if(datas == 'SocialVCReports'){
      setSideMenuHeader(archiveData.quarterlyReport.SocialVCReports);
    }else if(datas == 'PrivateEquityExitReports'){
      setSideMenuHeader(archiveData.quarterlyReport.PrivateEquityExitReports);
    }
  }
};

/**
 * The function `onAccordionClick` toggles the value of `openTab` or `openTab1` based on the `isFrom`
 * parameter.
 */

const onAccordionClick = (isFrom,val) => {
  if(isFrom =='anual'){
  if (val == openTab) {
    setOpenTab(null);
  } else {
    setOpenTab(val);
  }}
  else{
    if (val == openTab1) {
      setOpenTab1(null);
    } else {
      setOpenTab1(val);
    }}
};

  return (
    <>
    <div className="col-span-2">
      <div className="bg-white md:bg-[#FFFAEF]  h-full border-l border-t border-b-2  ">
        <div className="w-full border-r-1  px-0 items-center">
          <div className="hidden lg:grid">
            <div className="border-b tab">
              <div className="relative">
                <div >                  
                    <header  onClick={() => accordionClick(1)}
                      className={
                        select == 1
                          ? " bg-white text-[#2B2A29] font-medium flex justify-between items-center px-5 pt-5  cursor-pointer select-none tab-label"
                          : " bg-[#FFFAEF] flex justify-between items-center px-5 py-5  cursor-pointer select-none tab-label "
                      }
                    >

                      <button
                      >
                        <div>
                          <span
                            className="text-[#2B2A29]   font-sans_book_body  tracking-[-0.34px] leading-[19px]  ml-3 text-16px">
                            Annual Reports
                          </span>
                        </div>
                      </button>
                      <div
                        type="button"
                        className="flex"                       
                      >
                        {select == 1 ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-[17px] w-[17px] rotate-180 cursor-pointer transition-transform duration-200 mt-3 top-1/2 transform -translate-y-1/2 right-3"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M19 9l-7 7-7-7"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-[17px] w-[17px] rotate-0 cursor-pointer transition-transform duration-200 mt-4 top-1/2 transform -translate-y-1/2 right-3"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M19 9l-7 7-7-7"
                            />
                          </svg>
                        )}
                      </div>

                    </header>                  
                  <div className="relative overflow-hidden transition-all max-h-0 duration-700"
                    style={{ maxHeight: select == 1 ? "fit-content" : "" }}
                  >{isLoading ?
                  <div className="h-40 flex items-center justify-center">
                    <Loader />
                  </div> :
                    <ul className="bg-white pl-12 pr-18 pb-5 text-[#2B2A29]">
                     {archiveData?.annualReport?.annualReportHeader.map((data,i)=>{
                        return   <div className={`${archiveData?.annualReport?.annualReportHeader.length-1==i ? '':'border-b '} ml-4`}>
                          <li className={""+ (openTab === i? "pb-2 pt-3 border-r-[3px] border-[#F1CA75] ml-4 font-semibold cursor-pointer text-left": "pb-2 pt-3 ml-4 cursor-pointer text-left")}        
                      onClick={e => {
                        e.preventDefault();
                        setOpenTab(i);
                        setSideMenu(data.toString().replace(/ /g, ''));
                        setSideMenuHeaderData('annualReport',data.toString().replace(/ /g, ''));
                      }} key={i}>
                        <span
                          className=" font-sans_book_body  text-[15px] tracking-[-0.32px] leading-[18px] h-[76px] w-[154px]"
                        >{data}</span>
                      </li></div>
                      })}
                     
                    </ul>
                    
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="border-b tab">
              <div className="relative">
                <div >                  
                    <header onClick={() => accordionClick(2)}
                      className={
                        select == 2
                          ? " bg-white font-medium flex justify-between items-center px-5 pt-5  cursor-pointer select-none tab-label"
                          : " bg-[#FFFAEF] flex justify-between items-center px-5 py-5  cursor-pointer select-none tab-label "
                      }
                    >

                      <button
                      >
                        <div>
                          <span
                            className="text-[#2B2A29]   font-sans_book_body  tracking-[-0.34px] leading-[19px]  ml-3 text-16px">
                            Quarterly Reports
                          </span>
                        </div>
                      </button>
                      <div
                        type="button"
                        className="flex"                        
                      >
                        {select == 2 ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-[17px] w-[17px] rotate-180 cursor-pointer transition-transform duration-200 mt-3 top-1/2 transform -translate-y-1/2 right-3"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M19 9l-7 7-7-7"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-[17px] w-[17px] rotate-0 cursor-pointer transition-transform duration-200 mt-4 top-1/2 transform -translate-y-1/2 right-3"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M19 9l-7 7-7-7"
                            />
                          </svg>
                        )}
                      </div>

                    </header>                 
                  <div className="relative overflow-hidden transition-all max-h-0 duration-700"
                    style={{ maxHeight: select == 2 ? "fit-content" : "" }}
                  >
                    <ul className=" bg-white pl-12 pr-18 pb-5 text-[#2B2A29]">
                    {archiveData?.quarterlyReport?.quarterlyReportHeader.map((data,i)=>{
                       return <div className={`${archiveData?.quarterlyReport?.quarterlyReportHeader.length-1==i ?'':'border-b'} ml-4`} key={i}><li className={""
                       + (openTab1 === i
                         ? "pb-2 border-r-[3px] border-[#F1CA75] ml-4 pt-3 font-semibold cursor-pointer text-left"
                         : "pb-2 pt-3 ml-4 cursor-pointer text-left")
                     } onClick={e => {
                       e.preventDefault();
                       setOpenTab1(i);
                       setSideMenu1(data.toString().replace(/ /g, ''));
                       setSideMenuHeaderData('quarterlyReport',data.toString().replace(/ /g, ''));
                     }} key={i}>
                       <span
                         className=" font-sans_book_body  text-[15px] tracking-[-0.32px] leading-[18px] h-[76px] w-[154px]">{data}</span>
                     </li></div>
                    })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='lg:hidden'>
          <div className="flex items-center justify-center p-3 bg-white">
              <button
                className={
                  " border border-[#BC8B20] rounded-[20px] font-sans_medium_body tracking-[-0.34px] leading-[19px] ml-3 text-[16px] px-4 py-2 whitespace-nowrap " +
                  (select == 1
                    ? "text-white bg-[#BC8C22] "
                    : "text-[#BC8B20] bg-white")
                }
                onClick={(e) => {
                  e.preventDefault();
                  accordionClick(1)
                }}
                data-toggle="tab"
                href=""
                role="tablist"
              >
                Annual Reports
              </button>
              <button
                className={
                  " border border-[#BC8B20] rounded-[20px] font-sans_medium_body tracking-[-0.34px] leading-[19px] ml-3 text-[16px] px-4 py-2 whitespace-nowrap " +
                  (select == 2
                    ? "text-white bg-[#BC8C22]"
                    : "text-[#BC8B20] bg-white")
                }
                onClick={(e) => {
                  e.preventDefault();
                  accordionClick(2)
                }}
                data-toggle="tab"
                href=""
                role="tablist"
              >
               Quarterly Reports
              </button>
          </div>
            {isLoading ?
              <div className="h-40 flex items-center justify-center">
                <Loader />
              </div> :
              select ==1 ?
              <div className='divide-y'>
                {archiveData?.annualReport?.annualReportHeader?.map((data,i)=>{
                return(
                <div key={i} >            
                  <div className="relative" x-data="{ open: false }">
                    <header
                      className={
                        openTab == i
                          ? " bg-[#FDF4E3] font-medium flex justify-between font-sans_book_body text-[15px] tracking-[-0.32px] leading-[18px] px-5 py-3"
                          : " bg-white flex justify-between font-sans_book_body text-[15px] tracking-[-0.32px] leading-[18px] px-5 py-3"
                      }
                      onClick={e => {
                        e.preventDefault();
                        onAccordionClick('anual',i);
                        setSideMenu(data.toString().replace(/ /g, ''));
                        setSideMenuHeaderData('annualReport',data.toString().replace(/ /g, ''));
                      }}                   
                    >
                      <button>
                        <div>
                          <span className="text-[#2B2A29] font-sans_book_body tracking-[-0.34px] leading-[19px] ml-3 text-16px">
                          {data}
                          </span>
                        </div>
                      </button>
                      <div>
                        {openTab == i ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-[17px] w-[17px] rotate-180 cursor-pointer transition-transform duration-200 mt-3 top-1/2 transform -translate-y-1/2 right-3"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M19 9l-7 7-7-7"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-[17px] w-[17px] rotate-0 cursor-pointer transition-transform duration-200 mt-3 top-1/2 transform -translate-y-1/2 right-3"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M19 9l-7 7-7-7"
                            />
                          </svg>
                        )}
                      </div>
                    </header>
                    <div
                      className="relative overflow-hidden transition-all max-h-0 duration-700"
                      style={{
                        maxHeight: openTab == i ? "fit-content" : "",
                      }}
                    >
                      <div className="bg-[#FDF4E3] text-[#4b5563] font-sans_book_body transitionfont-semibold sectorContent">
                      <ArchieveContent 
                          currentTabHeader={sideMenuHeader}
                          openTab={openTab}
                          isLoading={isLoading}
                        />
                      </div>
                    </div>
                  </div>            
                </div>)})
              }
              </div>
              :
              <div className='divide-y'>
              {archiveData?.quarterlyReport?.quarterlyReportHeader?.map((data,i)=>{
              return(
              <div key={i} >            
                <div className="relative" x-data="{ open: false }">
                  <header
                    className={
                      openTab1 == i
                        ? " bg-[#FDF4E3] font-medium flex justify-between font-sans_book_body text-[15px] tracking-[-0.32px] leading-[18px] px-5 py-3"
                        : " bg-white flex justify-between font-sans_book_body text-[15px] tracking-[-0.32px] leading-[18px] px-5 py-3"
                    }
                    onClick={e => {
                      e.preventDefault();
                      onAccordionClick('quarter',i);
                      setSideMenu1(data.toString().replace(/ /g, ''));
                        setSideMenuHeaderData('quarterlyReport',data.toString().replace(/ /g, ''));
                    }}                   
                  >
                    <button>
                      <div>
                        <span className="text-[#2B2A29] font-sans_book_body tracking-[-0.34px] leading-[19px] ml-3 text-16px">
                        {data}
                        </span>
                      </div>
                    </button>
                    <div>
                      {openTab1 == i ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-[17px] w-[17px] rotate-180 cursor-pointer transition-transform duration-200 mt-3 top-1/2 transform -translate-y-1/2 right-3"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="2"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-[17px] w-[17px] rotate-0 cursor-pointer transition-transform duration-200 mt-3 top-1/2 transform -translate-y-1/2 right-3"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="2"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      )}
                    </div>
                  </header>
                  <div
                    className="relative overflow-hidden transition-all max-h-0 duration-700"
                    style={{
                      maxHeight: openTab1 == i ? "fit-content" : "",
                    }}
                  >
                    <div className="bg-[#FDF4E3] text-[#4b5563] font-sans_book_body transitionfont-semibold sectorContent">
                    <ArchieveContent 
                        currentTabHeader={sideMenuHeader}
                        openTab={openTab1}
                        isLoading={isLoading}
                      />
                    </div>
                  </div>
                </div>            
              </div>)})
            }
              </div>
            }
          </div>
        </div>        
      </div>
    </div>
     <div className="hidden lg:block lg:col-span-5 ">
     <ArchieveContent 
       currentTabHeader={sideMenuHeader}
       openTab={openTab}
       isLoading={isLoading}
     />
   </div>
   </>
  )
}

export default ArchieveSideMenu