import React from 'react'
// import Layout from '../../Components/Layout'
// import MainMenu from '../../Components/REInvestor/MainMenu'
import MainMenu from '../../Components/REInvestor/ReMainMenu'
import Card from '../../Components/RE/ReListCard/Card'
import FilterTag from '../../Components/REInvestor/Tag'
import Table from '../../Components/REInvestor/TableComponent'
import RefineSearch from '../../Components/REInvestor/RefineSearchComponent'
import ReLayout from '../../Components/ReLayout'
import ChatPopup from '../../Components/ChatPopup'
import chatPopupIcon from '../../images/chatPopupIcon.png'
import Constants from '../../Constants'
/**
 * The REAngelInvestment function is a React component that renders a layout with a primary column and
 * a secondary column, along with various child components such as Card, FilterTag, MainMenu, Table,
 * and RefineSearch.
 * @returns a JSX element.
 */
function REAngelInvestment() {
    const [showModal, setShowModal] = React.useState(false);
    return (
        <ReLayout>
        <Card />
                <div className="h-full min-w-0 flex-1 border-t border-gray-200 lg:flex">
                    {/* Primary column */}
                    <section
                    aria-labelledby="primary-heading"
                    className="flex h-full min-w-0 flex-1 flex-col overflow-y-auto lg:order-last"
                    >
                    <FilterTag/>
                    <MainMenu active={3} menu="re" primaryTypeMasterId={Constants.primaryMasterIds.reId}
                                subTypeMasterId={Constants.primaryMasterSubIds.re_others_ma_id}
                                typeId={Constants.moduleIds.investorsId}/>
                    {/* <Table PE_TABLE_QUERY = {PE_TABLE_QUERY}/> */}
                    <Table menu="re"/>

                    {/* <Outlet /> */}
                    {/* Your content */}
                    </section>

                    {/* Secondary column (hidden on smaller screens) */}
                    <aside className="hidden lg:order-first lg:block lg:flex-shrink-0">
                    <div className="relative flex h-[100%] overflow-y-auto flex-col  border-r border-gray-200 bg-[#FAF5EA]">
                        {/* Your content */}
                        <RefineSearch/>
                    </div>
                    </aside>
                    
                </div>
        </ReLayout>
    )
}

export default REAngelInvestment
