import React, {useContext} from 'react';
import {TableDataContext} from '../../../Contexts/TableDataContext'
import ToCompanies from './ToCompanies';
import ToAcquirers from './ToAcquirers';
import MostActiveAdvisor from './MostActiveAdvisor';
import MostActiveAdvisorsCount from './MostActiveAdvisorsCount';

/**
 * The `Card` component renders four different cards based on the data and loading status received from
 * the `TableDataContext`.
 * @returns The Card component is returning a JSX element.
 */
 const Card = () => {
  const { data, isLoad, isDataLoad ,menu ,props} = useContext(TableDataContext);

  return (
    <div className="">
      <div className='sm:full mt-[4.25rem] md:mt-[3rem] h-full  bg-[#F2F2F2]'>
           <div className="overflow-x-auto lg:grid grid-cols-4 gap-4  px-4 py-[0.7rem] flex xl:overflow-hidden scrollbar-remove ">
           {/* ----card1----- */}
           <ToCompanies data={data} isLoad={isLoad} isDataLoad={isDataLoad}/>
          {/* ----card2----- */}
          <ToAcquirers data={data} isLoad={isLoad} isDataLoad={isDataLoad}/>          
          {/* ----card3----- */}
          <MostActiveAdvisor menu={props?.menu} data={data} isLoad={isLoad} isDataLoad={isDataLoad}/>          
          {/* ----card4----- */}
          <MostActiveAdvisorsCount menu={props?.menu} data={data} isLoad={isLoad} isDataLoad={isDataLoad}/>
            </div>
        <div className='border-b-2 border-[#e5e5e5]'></div>
      </div >
    </div >
  )
}

export default Card;
