import React, { useContext, useEffect, useState } from 'react'
import axios from "axios";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from 'react-router-dom';
import { UserContext } from '../Contexts/UserContext'
import Logo from '../images/vi-logo.png';
import Laptop from '../images/laptop.png';
import RingTop from '../images/ring1.png';
import RingBottom from '../images/ring3.png';
import Mail from '../images/mailicon.png';
import Lock from '../images/lock-icon.png';
import { routeNames } from '../routeSegments';
import LogoutPopup from '../Components/Login/LogoutPopup';
import Loader from '../Components/Loader';
import { createDeviceAuthUrl,getPECompaniesApi,ipLoginApi } from '../Config/config';
import Constants from '../Constants';

/**
 * The Login function is a React component that handles the login process, including making API calls,
 * displaying loading states, and redirecting the user based on the response.
 * @returns The Login component is returning a JSX element, which consists of a div containing other
 * elements such as images, text, and a LogoutPopup component.
 */
const Login = () => {
    let redirectTo = useNavigate();
    const location = useLocation();
    const [user, setUser] = useState({ name: 'aa', isAuth: true });
    const { login, isLogging } = useContext(UserContext);
    const [loginErrorMsg, setLoginErrorMsg] = useState();
    const [selectedProduct, setSelectedProduct] = useState("1");
    const [logoutPopupData, setLogoutPopupData] = useState({ show: false, browserList: [], onClick: () => { } })
    const [isLoading, setIsLoading] = useState(false);

    /* The `useEffect` hook in the code snippet is used to perform side effects in a functional
    component. In this case, it is used to make an API call to the `ipLoginApi` endpoint when the
    component mounts. */
    useEffect(() => {
        if (isLogging) {
            return;
        }
        // login(formData.email, formData.password, selectedProduct)
        setIsLoading(true);
        axios(`${ipLoginApi}`, {
            method: "POST"
        })
        .then((data) => {
            setIsLoading(false);
            console.log(data?.data?.data,"hi");
            localStorage.setItem(Constants.localStorageKeys.studentLogin,data?.data?.data?.studentLogin)
            if (data?.data?.success === true) {
                if (data?.data?.data?.show === true) {
                    console.log("popup");
                    setLogoutPopupData({
                        show: true,
                        browserList: data?.data?.data?.deviceDetails ?? [],
                        onClick: (selected) => {
                            console.log(selected);
                            if (selected == null) {
                                setLogoutPopupData({ show: false })
                            } else {
                                createDeviceAuth(data.data?.data, selected)
                            }
                        }
                    });
                }else if(data?.data?.data?.showAuthorize === true){
                    console.log("initial login");
                    redirectTo(routeNames.device_authorization, {
                        state: {
                            // deviceId: id,
                            // password: data.password,
                            loginFrom: selectedProduct,
                            authCode: data?.data?.data?.authCode,
                            email: data?.data?.data?.emailId,
                        }
                    })
                }else if(data?.data?.data?.showDefaultLogin === true){
                    console.log("login redirect");
                    redirectTo(routeNames.login);
                } else {
                    console.log("login-ip");
                    localStorage.setItem(Constants.localStorageKeys.loggedInEmail, data.data?.data?.emailId)
                    localStorage.setItem(Constants.localStorageKeys.accessToken, data.data.data?.access_token)
                    localStorage.setItem(Constants.localStorageKeys.userId, data.data.data?.user_id)
                    localStorage.setItem('csrf_token', data.data.data?.csrf_token)
                    localStorage.setItem(Constants.localStorageKeys.allowedModules, JSON.stringify(data.data.data?.allowed_modules))
                    setUser((user) => ({
                        name: data.data?.data?.emailId,
                        isAuth: true,
                    }));
                    redirectTo(data?.data?.data?.redirectUrl)
                }
            } else {
                setLoginErrorMsg(data?.data.message)
            }
        })
    }, []);

    /**
     * The function `createDeviceAuth` takes in `data` and `id` as parameters, logs `data` to the
     * console, and makes a POST request to `createDeviceAuthUrl` with the `loginFrom` and
     * `auth_device_id` properties. If the response status is 200, it redirects to a specific route
     * with the `deviceId`, `loginFrom`, `authCode`, and `email` properties.
     * @param data - The `data` parameter is not used in the `createDeviceAuth` function. It is only
     * logged to the console for debugging purposes.
     * @param id - The `id` parameter is the unique identifier of the device for which you want to
     * create device authorization. It could be a string or a number, depending on how you are
     * generating device IDs in your system.
     */
    const createDeviceAuth = (data, id) => {
        console.log(data,"datadata-");
        axios.post(createDeviceAuthUrl, {
            // "email": "Test2@gmail1.com",
            // "password": "pra12",
            "loginFrom": selectedProduct,
            "auth_device_id": id
        }).then(response => {
            if (response.status === 200) {
                redirectTo(routeNames.device_authorization, {
                    state: {
                        deviceId: id,
                        // password: "pra12",
                        loginFrom: selectedProduct,
                        authCode: response.data?.data?.authCode,
                        email: response.data?.data?.emailId,
                    }
                })
            }
        }).catch(error => {

        })
    }

    console.log("selectedProduct12", selectedProduct);
    return (
        <div>
            <div className="inset-0 h-full w-full object-cover">
                <div className="h-max ">
                    {/* <!-- left side--> */}
                    <div className="grid grid-cols-2">
                        <div className=" lg:col-span-1 hidden lg:block h-screen  lg:bg-[#DBB25B]">
                            <div className="relative">
                                <img src={RingTop} className="absolute w-[130px] ml-[62%] xl:ml-[65.25%] xl:mt-[10%] mt-[9%]" alt="" />
                                <img src={RingBottom} className="absolute mt-[48%] ml-[12.5%] w-[60px]" alt="" />
                                <img src={Laptop} className="absolute m-[16%] " alt="" />
                            </div>
                        </div>
                        {/* <!--right side--> */}
                        <div className="lg:col-span-1 col-span-2 mt-[3rem] px-6 md:px-12 lg:px-22 xl:px-24 mt-[33%]">
                            <div className="mx-auto w-full max-w-screen-sm lg:w-96 lg:px-8">
                                <div className="">
                                    <img className="w-60 mx-auto" src={Logo} alt="vi" />
                                    <p className="text-center text-[#333333] mt-6 text-[18px] lg:text-[12px] tracking-[-0.24pt] lg:text-[#969696] font-sans_book_body "></p>
                                    {isLoading && 
                                        <div className="text-center mx-auto flex justify-center"><Loader sizeClassName='w-[3rem] h-[3rem]'/></div>
                                    }
                                    <h2 className=" text-[24px] lg:text-[16px] text-center tracking-[-0.31pt] font-sans_book_body font-semibold leading-[26.4px] text-[#333333]">IP login in Progress ...</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {logoutPopupData.show ? <LogoutPopup {...logoutPopupData} /> : <></>}
        </div>
    )
}

export default Login
