import React from 'react'
import { Link } from 'react-router-dom';
import sorting from '../../../../images/up-down-arrow.png';

/**
 * The function `AngelInvestmentTable` returns a React component that renders a table based on the
 * provided `mcaDataIndex` data.
 * @returns The function `AngelInvestmentTable` returns a JSX element, which represents a table
 * component in React.
 */
const AngelInvestmentTable = (mcaDataIndex) => {
    return (
        <div className="space-y-6 px-5 lg:mx-1211 overflow-x-auto scrollbar-remove">
      <div className="bg-white pb-5 ml-0"> 
      <table className="table w-full p-1 bg-grey-50 divide-y" width="50%">
        <thead className="bg-[#EBEBEB] text-[11pt] leading-[16pt] lg:text-[12px] lg:leading-[15px] font-sans_semi_bold text-[#666666]  text-left">
        {mcaDataIndex?.data?.indexOfCharges?.header?.map((singleData,i) => { 
            return (
                <th className="px-5 py-5" key={i}>
                <span className='flex'> {singleData} </span>
              </th>
            ); 
        })} 
        </thead>
        {mcaDataIndex?.data?.indexOfCharges?.value?.map((singleData) => {
            console.log('singleData', singleData);
          for (const property in singleData) {
            return (
              <tr>
                <td className="px-5 py-3  leading-[2rem] text-[14px] font-sans_book_body items-center justify-center card-animate">
                  { singleData['sno']}
                </td> 
                <td className="px-5 py-3  leading-[2rem] text-[14px] font-sans_book_body items-center justify-center card-animate">
                  { singleData['srn']}
                </td> 
                <td className="px-5 py-3  leading-[2rem] text-[14px] font-sans_book_body items-center justify-center card-animate">
                  { singleData['charge_id']}
                </td> 
                <td className="px-5 py-3  leading-[2rem] text-[14px] font-sans_book_body items-center justify-center card-animate">
                  { singleData['charge_holder_name']}
                </td>
                <td className="px-5 py-3  leading-[2rem] text-[14px] font-sans_book_body items-center justify-center card-animate">
                  { singleData['created_date']}
                </td> 
                <td className="px-5 py-3  leading-[2rem] text-[14px] font-sans_book_body items-center justify-center card-animate">
                  { singleData['modified_date']}
                </td> 
                <td className="px-5 py-3  leading-[2rem] text-[14px] font-sans_book_body items-center justify-center card-animate">
                  { singleData['satisfaction_date']}
                </td> 
                <td className="px-5 py-3  leading-[2rem] text-[14px] font-sans_book_body items-center justify-center card-animate">
                  { singleData['amount']}
                </td> 
                <td className="px-5 py-3  leading-[2rem] text-[14px] font-sans_book_body items-center justify-center card-animate">
                  { singleData['address']}
                </td> 
              </tr>
            );
          }
        })}
        </table>
      </div>
    </div>
    )
}

export default AngelInvestmentTable
