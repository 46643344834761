import React, { useState, useEffect } from 'react';
import moment from 'moment';

//input type components
import SelectComponent from './Components/SelectComponent';
import TagComponent from './Components/TagComponent';
import { Tooltip } from "antd"; 
import NumberYearsComponent from './Components/NumberYearsComponent';
import BetweenYearsComponent from './Components/BetweenYearsComponent';
import TextComponent from './Components/TextComponent';
import NumberComponent from './Components/NumberComponent';
import RangeComponent from './Components/RangeComponent';
import BlankComponent from './Components/BlankComponent';
import StartWithTextComponent from './Components/StartWithTextComponent';
import CalenderComponent from "./Components/CalenderComponent";
import MultiCalenderComponent from './Components/MultiCalenderComponent';
import CancelImg from '../../images/Group 25.png';
import Papa from "papaparse";
import {UploadOutlined} from '@ant-design/icons';

/**
 * The `DynamicFilterComponent` function is a React component that renders a specific component based
 * on the value of the `dataType` variable.
 * @returns The DynamicFilterComponent is returning a JSX component based on the value of the dataType
 * variable. The returned component depends on the value of dataType and includes various child
 * components such as TagComponent, SelectComponent, NumberYearsComponent, BetweenYearsComponent,
 * TextComponent, StartWithTextComponent, BlankComponent, NumberComponent, RangeComponent,
 * CalenderComponent, and MultiCalenderComponent.
 */
const DynamicFilterComponent = ({ child, filterData, id, selectedChild, setSelectedChild, selected, setSelected, uniqueId }) => {
  const [dataType, setDataType] = useState(filterData?.dataType);
  console.log(filterData,"selectedchild1");
  console.log(uniqueId,'key from dynamic' );
  
  const [file, setFile] = useState("");
  const [data, setData] = useState([]);
  const [isSelectCsvFile, setIsSelectCsvFile] = useState(false);

 /**
  * The `handleFileChange` function is used to handle the change event when a file is selected for
  * upload, parse the file using Papa.parse, and update the state variables `file`, `data`,
  * `isSelectCsvFile`, and call the `companyDatatypeChange` function.
  * @param event - The event parameter is an object that represents the event that triggered the file
  * change. In this case, it is the change event that occurs when a file is selected in an input
  * element of type "file".
  */
  const handleFileChange = (event) => {
    const uploadfile = event.target.files[0];
    setFile(uploadfile);

    Papa.parse(event.target.files[0], {
      header: false,
      skipEmptyLines: true,
      complete: function (results) {
        let newArray = [];
        let company = Object.values(results.data);
        company.forEach(element => {
          newArray.push(element[0]);
        });
        setData(newArray);
      },
    });
    setIsSelectCsvFile(true);
    companyDatatypeChange();
  };

 /**
  * The function `companyDatatypeChange` updates the `values` property of a selected child object in an
  * array.
  */
  const companyDatatypeChange = () => {
    setSelectedChild((prevSelectedChild) =>
      prevSelectedChild.map((prevChild) => {
        if (prevChild.id === child.id) {
          return { ...prevChild, values: [""] };
        }
        return prevChild;
      })
    );
  };

  useEffect(() => { 
    //
    const searchType = filterData?.searchType;
    const selectedType = searchType.find((item) => Object.values(item)[0]);
    // alert(selectedType.dataType)
    if (selectedType) {
      setDataType(selectedType.dataType);
    }
  }, [filterData, id, setSelectedChild]);

  /**
   * The function `renderComponent` returns a specific component based on the value of the `dataType`
   * variable.
   * @returns The `renderComponent` function returns a JSX component based on the value of the
   * `dataType` variable. The returned component depends on the value of `dataType` and includes
   * various child components such as `TagComponent`, `SelectComponent`, `NumberYearsComponent`,
   * `BetweenYearsComponent`, `TextComponent`, `StartWithTextComponent`, `BlankComponent`,
   * `NumberComponent`, `RangeComponent`, `
   */
  const renderComponent = () => {
    // alert(dataType)
    if (dataType === 'Select') {
      const options = ['Option 1', 'Option 2', 'Option 3'];
      if(child.name === 'Company Name'){
        if(isSelectCsvFile){
          return (
            <div className='flex'>
                <TagComponent
                  key={child.id}
                  child={child}
                  selectedChild={selectedChild}
                  setSelectedChild={setSelectedChild}
                  options={options}
                  selected={selected}
                  setSelected={setSelected}
                  data = {data}
                />
              <div className='px-3' 
                    onClick={() => {
                      setIsSelectCsvFile(false)
                      companyDatatypeChange()
                    }}>
                <div>
                <Tooltip title="Close">
                  <img src={ CancelImg } className="max-h-[24px] mt-1 max-w-[21px]" alt="" />
                </Tooltip>
                </div>
              </div>
            </div>
          );
        }else{
          return (
            <div className='flex items-center'>
            <SelectComponent
              key={uniqueId}
              child={child}
              selectedChild={selectedChild}
              setSelectedChild={setSelectedChild}
              options={options}
              selected={selected}
              setSelected={setSelected}
              childIndex={uniqueId}
            />
            <div className='px-3'>
            <Tooltip title="Upload File">
                <input type="file" id="CSVfile" onChange={handleFileChange} className='hidden' accept=".csv"/>
                <label for="CSVfile" className="max-h-[24px] max-w-[21px] block cursor-pointer" >
                <UploadOutlined />
                </label>
            </Tooltip>
            </div>
            </div>
          );
        }
      }else{
        return (
          <SelectComponent
            key={uniqueId}
            childIndex={uniqueId}
            child={child}
            selectedChild={selectedChild}
            setSelectedChild={setSelectedChild}
            options={options}
            selected={selected}
            setSelected={setSelected}
          />
        );
      }
    }

    if (dataType === 'Before' || dataType === 'After' || dataType == "number-years") {
        const currentYear = new Date().getFullYear();
        const startYear = 1998;
        const years = Array.from({ length: currentYear - startYear + 1 }, (_, index) => startYear + index).reverse();
        return (
          <NumberYearsComponent
            key={uniqueId}
            childIndex={uniqueId}
            selectedChild={selectedChild}
            setSelectedChild={setSelectedChild}
            child={child}
            years={years}
          />
        );
    }

    if (dataType === 'Between') {
      const currentYear = new Date().getFullYear();
      const startYear = 1998;
      const years = Array.from({ length: currentYear - startYear + 1 }, (_, index) => startYear + index).reverse();
      return (
        <BetweenYearsComponent
        key={uniqueId}
            childIndex={uniqueId}
          selectedChild={selectedChild}
          setSelectedChild={setSelectedChild}
          child={child}
          startYear={startYear}
          endYear={currentYear}
          years={years}
        />
      );
    }
    


    if (dataType === 'Text') {
      return (
        <TextComponent
        key={uniqueId}
            childIndex={uniqueId}
          selectedChild={selectedChild}
          setSelectedChild={setSelectedChild}
          child={child}
        />
      );
    }


    if (dataType === 'StartWithSelect' ) {
      return (
        <StartWithTextComponent
        key={uniqueId}
            childIndex={uniqueId}
          selectedChild={selectedChild}
          setSelectedChild={setSelectedChild}
          child={child}
        />
      );
    }

    if (dataType === 'blank') {
      const options = ['True', 'False'];
      return (
        <BlankComponent
        key={uniqueId}
            childIndex={uniqueId}
          selectedChild={selectedChild}
          setSelectedChild={setSelectedChild}
          child={child}
          options={options}
        />
      );   
    }

    //integer
    if(dataType === "Numbers"){
      return (
        <NumberComponent
        key={uniqueId}
            childIndex={uniqueId}
          selectedChild={selectedChild}
          setSelectedChild={setSelectedChild}
          child={child}
        />
      );
    }

    //range
    if(dataType === "Range"){
      return (
        <RangeComponent
        key={uniqueId}
            childIndex={uniqueId}
          selectedChild={selectedChild}
          setSelectedChild={setSelectedChild}
          child={child}
        />
      );
    }

    //calender
    if(dataType === "CalenderType"){
      const currentYear = new Date().getFullYear();
      const startYear = 1998;
      const monthList = moment.monthsShort()
      const years = Array.from({ length: currentYear - startYear + 1 }, (_, index) => startYear + index).reverse();
      return (
        <CalenderComponent
        key={uniqueId}
            childIndex={uniqueId}
          selectedChild={selectedChild}
          setSelectedChild={setSelectedChild}
          child={child}
          years={years}
          months = {monthList}
        />
      );
    }

     //multi calender
     if(dataType === "MultiCalenderType"){
      const currentYear = new Date().getFullYear();
      const startYear = 1998;
      const monthList = moment.monthsShort()
      const years = Array.from({ length: currentYear - startYear + 1 }, (_, index) => startYear + index).reverse();
      return (
        <MultiCalenderComponent
        key={uniqueId}
            childIndex={uniqueId}
          selectedChild={selectedChild}
          setSelectedChild={setSelectedChild}
          child={child}
          years={years}
          months = {monthList}
        />
      );
    }

    return <div>No component available for dataType: {dataType}</div>;
  };

  return <div className="dynamic-filter-component">{renderComponent()}</div>;
};

export default DynamicFilterComponent;
