import { useEffect, useState , useContext} from 'react'
import ProfileImage from '../../images/userAvatar.png'
import { UserContext } from "../../Contexts/UserContext";
import {getUserDetailsAPi} from "../../Config/config"
import Constants from '../../Constants';
import axios from 'axios';

/* The code is defining a functional component called `ProfilePage` and exporting it as the default
export. */
export default function ProfilePage() {
    const [userProfileData, setUserProfileData] = useState({})
    const {getToken, logout } = useContext(UserContext);


    useEffect(()=>{
        axios(`${getUserDetailsAPi}`, {
            method: "POST",           
            headers: {
                "Content-Type": "application/json",
                "Authorization": getToken()
              },
        })
        .then((response) => {
            if (response?.data?.status == 200) { 
                console.log(response, 'user profile response'); 
                setUserProfileData(response?.data?.user)                            
            }
          })
          .catch((error) => {
            if (error?.response?.status == Constants.sessionTerminatedStatus) {
              logout()
            }
            console.log("API not working", error);
          });

    },[])


    return (
        <div>
            <div className="bg-white lg:shadow rounded-[4px] lg:mt-5">
                <p
                    className="hidden lg:block py-4 px-4  font-sans_medium_body text-[16px] text-[#2B2A29] leading-[19px] tracking-[-0.34px]">
                    Profile</p>
                <div className="flex px-4">
                    <div className="flex-shrink-0 ">
                        <img className="h-[80px] w-[85px] rounded-full "
                            src={userProfileData?.logo!='' ? userProfileData?.logo: ProfileImage} alt="" />
                    </div>
                    <div className="mt-7">
                        <a href="#"
                            className="px-4  font-sans_book_body  text-[#BC8B20] leading-[17px] tracking-[-0.3px] text-[14px]">
                            Change Profile
                        </a>
                    </div>
                </div>
                <div className="border-t border-[#E3E3E3] mt-4 "></div>

                <div className="mt-4 ">

                    <div className="gird gird-cols-6">
                        <div className="min-w-full mt-2 px-4">

                            <div className='flex py-3  grid grid-cols-10'>
                                <div
                                    className="col-span-2   font-sans_book_body text-[#666666] text-[14px] tracking-[-0.3px] leading-[17px]">
                                    Organization Name</div>
                                <div
                                    className=" col-span-4  font-sans_medium_body  text-[#333333] text-[14px] tracking-[-0.3px] leading-[17px]">
                                    {userProfileData?.organization_name ? userProfileData?.organization_name : "--"}</div>

                                <div className=' col-span-4'>
                                    <div className="text-right ">
                                        <a href="#"
                                            className="  font-sans_book_body text-[#BC8B20] text-[14px] leading-[17px] tracking-[-0.3px]">
                                            Edit</a>


                                        <a href="#"
                                            className="text-[#9F9F9F] font-sans_book_body px-3 text-[14px] leading-[17px] tracking-[-0.3px]">
                                            Save</a>

                                    </div>
                                </div>
                            </div>
                            <div className='flex py-3 grid grid-cols-5 '>
                                <div
                                    className=" font-sans_book_body text-[#666666] text-[14px] tracking-[-0.3px] leading-[17px]">
                                    Name</div>
                                <div
                                    className=" font-sans_medium_body  text-[#333333] text-[14px] tracking-[-0.3px] leading-[17px]">
                                    {userProfileData?.name ?? "--"}</div>
                            </div>
                            <div className='flex py-3 grid grid-cols-5 '>

                                <div
                                    className="  font-sans_book_body text-[#666666] text-[14px] tracking-[-0.3px] leading-[17px]">
                                    Email Address</div>
                                <div
                                    className=" font-sans_medium_body  text-[#333333] text-[14px] tracking-[-0.3px] leading-[17px]">
                                    {userProfileData?.email ? userProfileData?.email : "--"}</div>
                            </div>
                            <div className='flex py-3 grid grid-cols-5 '>


                                <div
                                    className=" font-sans_book_body text-[#666666] text-[14px] tracking-[-0.3px] leading-[17px]">
                                    City</div>
                                <div
                                    className=" font-sans_medium_body  text-[#333333] text-[14px] tracking-[-0.3px] leading-[17px]">
                                    {userProfileData?.city ? userProfileData?.city: "--"}</div>
                            </div>
                            <div className='flex py-3 grid grid-cols-5 '>

                                <div
                                    className="  font-sans_book_body text-[#666666] text-[14px] tracking-[-0.3px] leading-[17px]">
                                    Country</div>
                                <div
                                    className=" font-sans_medium_body  text-[#333333] text-[14px] tracking-[-0.3px] leading-[17px]">
                                    {userProfileData?.country ? userProfileData?.country: "--"}</div>
                            </div>
                            <div className='flex py-3 grid grid-cols-5 '>

                                <div
                                    className=" font-sans_book_body text-[#666666] text-[14px] tracking-[-0.3px] leading-[17px]">
                                    Phone Number</div>
                                <div
                                    className=" font-sans_medium_body  text-[#333333] text-[14px] tracking-[-0.3px] leading-[17px]">
                                    {userProfileData?.phone_number ? userProfileData?.phone_number: "--"}</div>
                            </div>



                        </div>
                    </div>
                    <div className="border-t  border-[#E3E3E3]  "></div>


                        <div className=' px-4'>
                            <div className='flex grid grid-cols-10 py-3'>
                                <div
                                    className="col-span-2 whitespace-nowrap font-sans_book_body text-[#666666] text-[14px] tracking-[-0.3px] leading-[17px]">
                                    Password

                                </div>
                                <div className='col-span-5'>
                                    <p
                                        className="whitespace-nowrap  font-sans_medium_body  text-[#333333] text-[14px] tracking-[-0.3px] leading-[17px]">
                                        {userProfileData?.password_updated_on ? userProfileData?.password_updated_on: "--"}
                                    </p>
                                </div>
                                <div className='col-span-3'>
                                    <div className='text-right'>
                                        <a href="#"
                                            className="  whitespace-nowrap  font-sans_book_body text-[#BC8B20] text-[14px] leading-[17px] tracking-[-0.3px] ">Change
                                            Password</a>
                                    </div>
                                </div>

                            </div>




                        </div>

                 

                </div>
            </div>

        </div>






    )
}

