import React, { useContext, useState } from 'react'
import delIcon from '../../images/Combined Shape_6/png/Combined Shape.png';
import ConfirmPopup from '../../Utils/ConfirmPopup';
import axios from 'axios';
import { deleteItemFromGroupApi } from '../../Config/config';
import { UserContext } from '../../Contexts/UserContext';
import { toast } from 'react-toastify';


const DeleteItemsFromGroup = ({id,group_id,type_id,setReload}) => {

    const { getToken } = useContext(UserContext);
    const [open, setOpen] = useState(false)

    const deleteItemFromGroup = () => {
        axios(`${deleteItemFromGroupApi}`, {
            method: "POST",
            data: {
                id:group_id,
                type_id: type_id

            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": getToken()
            },
        })
            .then((response) => {
                console.log(response?.data, "list delete api response");
                if (response?.data?.success == true) {
                    toast.success(response?.data?.message); 
                    setReload(prev=>{
                        return !prev
                    })           
                }
            })
            .catch((error) => {
                console.log("API not working", error);
            });
    };

    const onConfirmation = (flag) => {
        if (flag === 1) {
            deleteItemFromGroup()
            setOpen(!open)
        } else {
            setOpen(false)
        }
    
      }
    

  return (
    <>
    {open && <ConfirmPopup show={true} message="Are you sure you want to delete?" onChange={onConfirmation}/>}
    <div className='flex items-center justify-center'>
    <img src={delIcon} alt="delete item" className='cursor-pointer' onClick={()=>setOpen(true)} key={id}/>
    </div>
    </>
  )
}

export default DeleteItemsFromGroup
