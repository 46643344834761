import { Fragment, useState } from 'react'
import { Transition } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/outline'
import { BanIcon } from '@heroicons/react/solid'
import { XIcon } from '@heroicons/react/solid'
import './Notification.css'

/**
 * The `Notification` function is a React component that renders a notification panel with a message
 * and an optional success or error icon.
 * @param props - The `props` parameter is an object that contains the properties passed to the
 * `Notification` component. These properties can be accessed using dot notation, such as
 * `props.message` and `props.success`.
 * @returns a React component that renders a notification panel. The notification panel is displayed
 * based on the value of the `show` state variable. The content of the notification panel is determined
 * by the `props` passed to the component. The panel can display either a success message or an error
 * message, along with an icon and a close button. The panel is animated using the `Transition`
 * component
 */
export default function Notification(props) {
  const [show, setShow] = useState(true)

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start mt-[3.5rem]"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-center">
                {props?.message!="The list has been already exists" 
                && props?.message!="The alert has been already set" 
                && props.message!="The list name is required"
                && props.success!=false
                ?
                  <div className="flex-shrink-0">
                    <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
                  </div>
                  :
                  <div className="flex-shrink-0">
                    <BanIcon className="h-6 w-6 text-red-400" aria-hidden="true" />
                  </div>
                }
                  {props?.message!="The list has been already exists" 
                  && props?.message!="The alert has been already set"
                  && props.message!="The list name is required"
                  && props.success!=false
                  ?
                  <div className="ml-3 w-0 flex-1 flex items-center">
                    <p className="text-sm font-medium text-gray-900 whitespace-nowrap">{props?.message}</p>
                  </div>
                  :
                  <div className="ml-3 w-0 flex-1">
                    <p className="text-sm font-medium text-gray-900 whitespace-nowrap">{props?.message}</p>
                  </div>
                  }
                  <div className="ml-3 flex-shrink-0 flex">
                    <button
                      type="button"
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={() => {
                        setShow(false)
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  )
}