import React, { useState, useEffect } from 'react'
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../../../Components/Common/ChartsDynamicColor";
import {NavLink } from 'react-router-dom';
import Loader from '../../../../Components/Loader';
import LatestActivity from './LatestActivity';
import { initialDateRange } from '../../../../Queries/CubeQueries';
import { cubejsApi } from '../../../..';
import useModule from '../../../../hooks/useModule';

/**
 * The DashboardChart component is a JavaScript function that displays a chart with data fetched from
 * an API and allows users to view more details.
 * @returns The DashboardChart component is returning a JSX element.
 */
const DashboardChart = ({ dataColors }) => {

    // chartData
    const {allowedModulesList} = useModule()
    const [isLoading, setIsLoading] = useState(true);
    const [chartData, setChartData] = useState({
        deals: [],
        amount: [],
        years: [],
    });


    /**
     * The function "numberWithCommas" takes a number as input and returns a string representation of
     * that number with commas separating every three digits.
     * @param x - The parameter "x" is a number that you want to format with commas.
     * @returns the input number with commas added as thousands separators.
     */
    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    useEffect(() => {
        const query = {
            "dimensions": ["DateMaster.cy"],
            "timeDimensions": [],
            "order": [["DateMaster.cy", "desc"]],
            "measures": ["DealFlat.deal_id", "DealFlat.AmountM"],
            "segments":["DealFlat.standard","DealFlat.industry_seg"],
            "filters": [{
                "member": "DealFlat.date",
                "operator": "inDateRange",
                "values": initialDateRange
            }],
              "limit": 5
        };

        /**
         * The fetchData function is an asynchronous function that fetches data from an API, processes
         * it, and sets the processed data in the state.
         */
        const fetchData = async () => {
            try {
                const result = await cubejsApi.load(query)
                const data = result.rawData();

                const deals = [];
                const amount = [];
                const years = [];

                data.forEach(item => {
                    deals.push(item['DealFlat.deal_id']);
                    amount.push(item['DealFlat.AmountM']);
                    years.push(item['DateMaster.cy'].toString());
                });
                setChartData({
                    deals,
                    amount,
                    years
                });

                setIsLoading(false);
            } catch (error) {
                console.error(error);
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    // if (isLoading) {
    //     return <div>Loading...</div>;
    // }

    const { deals, amount, years } = chartData;

    const alignedData = years.map((year, index) => ({
        year,
        deals: deals[index],
        amount: amount[index],
    })).reverse();


    var chartMultiColors = getChartColorsArray(dataColors);

    
    const series = [
        {
            name: "No of Deals",
            type: "line",
            data: alignedData.map(item => item.deals) //[1.4, 2, 2.5, 1.5, 2.5, 2.8, 3.8, 4.6],
        },
        {
            name: "Amount (US$ M)",
            type: "bar",
            data: alignedData.map(item => item.amount) //[20, 29, 37, 36, 44, 45, 50, 58],
        },
    ];

    var options = {
        chart: {
            type: 'bar',
            stacked:true,
            // stacked: !1,
            toolbar: {
                show: !1,
            },
            zoom:{
                enabled:false
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '30%',
                borderRadius: 6
            }
        },
        dataLabels: {
            enabled: !1,
        },
        stroke: {
            width: [1, 1, 1],
        },
        
        xaxis: {
            categories: alignedData.map(item => item.year),
            tooltip: {
                enabled: false,
            },
        },
        yaxis: [
            {
                tickAmount: 3,
                axisTicks: {
                    show: !0,
                },
                axisBorder: {
                    show: !0,
                    color: "#474747",
                },
                labels: {
                    formatter: (value) => Math.floor(value),
                    style: {
                        colors: "#474747",
                    },
                },
                title: {
                    text: "No of Deals",
                    style: {
                        color: "#474747",
                        fontWeight: 600,
                    },
                },
                tooltip: {
                    enabled: false,
                },
            },

            {
                seriesName: "Amount",
                opposite: !0,
                axisTicks: {
                    show: !0,
                },
                axisBorder: {
                    show: !0,
                    color: "#BC8B20",
                },
                labels: {
                    formatter: (value) => '$ '+ numberWithCommas(Math.floor(value)),
                    style: {
                        colors: "#BC8B20",
                    },
                },
                tickAmount: 5,
                max: 70000,
                title: {
                    text: "Amount (US$ M)",
                    style: {
                        color: "#BC8B20",
                        fontWeight: 600,
                    },
                },
            },
        ],
        tooltip: {
            fixed: {
                enabled: !0,
                position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
                offsetY: 30,
                offsetX: 60,
            },
        },
        legend: {
            horizontalAlign: "left",
            offsetX: 40,
        },
        colors: chartMultiColors,
    };

    return (
        <React.Fragment>
            <div className='lg:grid lg:grid-cols-12'>
                
                <div className="block border border-[#CECECE] rounded-[4px] m-2 p-1 md:p-2 bg-[#EBEBEB] col-span-6">
                    <div className="landscape:hidden lg:grid lg:grid-cols-6 px-2 pb-1 md:pb-2 justify-between flex  space-x-5 border-b-[#CCCCCC] border-b border-x-1 ">
                        {/* <div className="contents space-x-3 items-center justify-between"> */}
                           <div className='lg:col-span-2'> </div>
                            <div className='lg:col-span-2 lg:w-max lg:grid lg:place-content-center '><p className="text-[#333333] contents  text-[14px] md:text-[16px] leading-[17px] font-semibold ">Private Equity Investments</p></div>
                            <div className='lg:col-span-2 grid place-content-end '>

                                <NavLink to="/reports"><button disabled={(allowedModulesList.includes('cfs') || allowedModulesList.includes('vc')) && !allowedModulesList.includes('pe') && !allowedModulesList.includes('ma') && !allowedModulesList.includes('re')  
}
                                className={`${(allowedModulesList.includes('cfs') || allowedModulesList.includes('vc')) && !allowedModulesList.includes('pe') && !allowedModulesList.includes('ma') && !allowedModulesList.includes('re') ? 'bg-[#C4C4C4] cursor-not-allowed':'bg-[#BC8B20] cursor-pointer'} px-1 py-[0.3rem] text-[#FFFFFF] rounded-[3px] font-sans_book_body leading-[17px] text-[13px] `}>View More</button></NavLink></div>
                        {/* </div> */}
                    </div>
                    <div className="portrait:hidden  px-2 pb-1 md:pb-2 justify-between flex  space-x-5 border-b-[#CCCCCC] border-b border-x-1 ">
                        {/* <div className="contents space-x-3 items-center justify-between"> */}
                           <div className='lg:col-span-2'> </div>
                            <div className='lg:col-span-2 lg:w-max lg:grid lg:place-content-center '><p className="text-[#333333] contents  text-[14px] md:text-[16px] leading-[17px] font-semibold ">Private Equity Investments</p></div>
                            <div className='lg:col-span-2 grid place-content-end '>

                                <NavLink to="/reports"><button disabled={(allowedModulesList.includes('cfs') || allowedModulesList.includes('vc')) && !allowedModulesList.includes('pe') && !allowedModulesList.includes('ma') && !allowedModulesList.includes('re')  
}
                                className={`${(allowedModulesList.includes('cfs') || allowedModulesList.includes('vc')) && !allowedModulesList.includes('pe') && !allowedModulesList.includes('ma') && !allowedModulesList.includes('re') ? 'bg-[#C4C4C4] cursor-not-allowed':'bg-[#BC8B20] cursor-pointer'} px-1 py-[0.3rem] text-[#FFFFFF] rounded-[3px] font-sans_book_body leading-[17px] text-[13px] `}>View More</button></NavLink></div>
                        {/* </div> */}
                    </div>
                    {isLoading
                    ? <div className='bg-white w-full flex items-center justify-center h-[90%]'>
                        <Loader />
                    </div>
                    :
                    <div className='bg-[#ffffff]'>
                        <ReactApexChart
                            className="apex-charts"
                            options={options}
                            series={series}
                            type="line"
                            height={350}
                        />
                    </div>  
                    }
                </div> 
                {/* <SearchTrend />  */}
                <LatestActivity />
                  
            </div>   
        </React.Fragment>
    );
};


export {
    DashboardChart
};