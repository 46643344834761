import { React, useState, useEffect, useMemo, useContext, useCallback  } from 'react'
import { useSearchParams, Routes, Route, useParams, Link, useLocation, matchPath } from "react-router-dom";
import axios from "axios"
import { useQuery } from '@apollo/client'
import sorting from "../../images/up-down-arrow.png";
import {TableDataContext} from '../../Contexts/TableDataContext'
import { getLimitedPartnersInvestor } from "../../Config/config"
import CustomNavigation from "../../Utils/CustomNavigation" 
import { routeNames } from "../../routeSegments";
import DataTable from '../../Components/DataTables/DataTable';
import CompanyLogo from '../CompanyLogo';
import { FundingContext } from '../../Contexts/FundingDataProvider';
import RandomLogo from '../RandomLogo';
import OverflowToolTip from '../shared/OverflowToolTip';
import Constants from '../../Constants';
 
import routePathSegments from "../../routeSegments";

/* The above code is a React component written in JavaScript. It is a functional component called
"Investments" that renders a table of investment data. */
const Investments = ({setshowXlDownload,menu}) => {
     
    const {  data, setData, isLoad, setIsLoad, isDataLoad, setIsDataLoad} = useContext(TableDataContext);

    const [currentPage, setCurrentPage] = useState(1);
    const [dataPerPage, setDataPerPage] = useState(20)
    const [investment, setInvestment] = useState([]);
    const {setpevcData} = useContext(FundingContext);

    const { partnerId } = useParams();
    let lpId = parseInt(partnerId)
    console.log(lpId,"idname");
    const location = useLocation();

     useEffect(() => {
      investmentRender()
      setIsLoad(true) 
      setIsDataLoad(true)
      
     }, []);

      /* The `investmentRender` function is a callback function that is used to fetch investment data
      from an API and update the state variables `isLoad`, `isDataLoad`, `investment`, and `data`
      based on the API response. */
      const investmentRender =  useCallback(() => {
      axios(`${getLimitedPartnersInvestor}`, {
      method: "POST",
      data: {
        "lpid": lpId,
        "category": "pe_vc_investment" 
    },
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if(response?.data?.status == 200){
          console.log(response?.data,"response")
          setIsLoad(false)
          setIsDataLoad(false) 
          setInvestment([])
          setInvestment(response?.data?.pevcinvestors)
          setData(response?.data)
        }
      })
      .catch((error) => {
        console.log("API not working", error);
      });
    },[]);

    console.log(investment,"investments1");
    
    const peMatch  =matchPath(`${routePathSegments.pe}/*`,location.pathname);

    const pageNumbers = []
 
    for (let i = 1; i <= Math.ceil(investment?.length / dataPerPage); i++) {
        pageNumbers.push(i);
    }
    console.log(pageNumbers,"pagination");
    const setPage = (pageNum) => {
        setCurrentPage(pageNum)

    }
    /* The `customStringSortMemo` constant is a memoized function that is used for custom sorting in
    the table. It is created using the `useMemo` hook to memoize the function and prevent
    unnecessary re-renders. */
    const customStringSortMemo = useMemo(() => {
      return (rowA, rowB, columnId, desc) => {
          const defaultVal = desc ? '--' : 'A';
          return (rowA?.values[columnId]?.name ?? defaultVal).localeCompare(rowB?.values[columnId]?.name ?? defaultVal);
      }
  }, []);

  /* The `portfolioSort` constant is a custom sorting function that is used to sort the "Top portfolio
  cos based on investment size" column in the table. It is created using the `useMemo` hook to
  memoize the function and prevent unnecessary re-renders. */
  const portfolioSort = useMemo(() => {
    return (rowA, rowB, columnId, desc) => {
        const defaultVal = desc ? '--' : 'A';

        const valueA = rowA?.values[columnId]?.name?.map(data => data.Companyname).join("")
        const valueB = rowB?.values[columnId]?.name?.map(data => data.Companyname).join("")

        return (valueA ?? defaultVal).localeCompare(valueB ?? defaultVal);
    }
}, [])

/**
 * The addCommasToNumber function takes a number as input and returns a string representation of the
 * number with commas added for thousands separators.
 * @returns The function `addCommasToNumber` returns a string representation of the input number with
 * commas added to separate thousands.
 */
const addCommasToNumber = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

   /* The `columns` constant is an array of objects that define the columns for the table in the
   Investments component. Each object represents a column and has properties such as `Header` (the
   column header text), `accessor` (the key to access the data for that column), `className` (the
   CSS class for the column), and `Cell` (a custom component to render the cell content). */
    const columns = useMemo(() => [
      {
          id:"investor",
          Header: "PE-VC Firm",
          accessor: "investor",
          className:"clickable w-[40%]",
          Cell: ({ value }) => {
              return <div className=' leading-[1.8rem] lg:leading-[2rem] font-sans_book_body items-center justify-center'>
                {value?.logo_path=="default.png" || value?.logo_path=="/default.png" || !value?.logo_path?
                <RandomLogo name={value.name} page="company page"/>
                :
                <CompanyLogo name={value.name} page="company page" logo={value?.logo_path}/>           
            }
            {/* <OverflowToolTip component={<Link state={{ prevPath: location.state?.prevPath }} to={routeNames.investorById(value?.id)}>
              {value.name ?? "--"}
            </Link> } /> */}

            <OverflowToolTip className="w-[10rem] lg:w-auto" component={
            
            <Link className=" text-start flex lg:inline hover:text-[#BC8B20]" 
            state={{ cameFrom: location.state?.prevPath }}
             to={peMatch!=null?routeNames.peInvestorById(value?.id,value?.dealId) :routeNames.vcInvestorById(value?.id,value?.dealId)}>
            {value.name ?? "--"}
          </Link> } />
            </div>

          },
          sortType: customStringSortMemo,
      },
      {
          Header: "Portfolio",
          accessor: "portfolio",
          className:"text-right pr-[2.25rem] lg:pr-[2.25rem] w-[10%]"   ,
          headerspanClassName:"ml-auto"      
      },
      {
          Header: "Top portfolio cos based on investment size",
          accessor: "topPortfolio",
          className:"ml-auto  w-[20%]" ,  
                Cell:({value}) => {

                  return <OverflowToolTip className="w-[18rem] lg:w-auto max-w-[20rem]" component={
                      value?.name?.map((inv, index) => (
                      <Link  className=' hover:text-[#BC8B20]' 
                      state={{ cameFrom: location.pathname }} to={peMatch!=null?routeNames.peCompanyFundById(inv.PECompanyId):routeNames.vcCompanyFundById(inv.PECompanyId)}>
                          {`${inv.Companyname}${index === value.name?.length - 1 ? "" : ", "}`}
                      </Link>))

                  } page="lp"/>
              },
                sortType: portfolioSort,
      },
      {
          Header: "No. of Funds",
          accessor: "noOfFunds",
          className:"text-right pr-[2.25rem] lg:pr-[2.5rem] w-[10%]" ,
          headerspanClassName:"ml-auto" ,
      },
      {
          Header: "Total Invested (US$ M)",
          accessor: "totalInvested",
          className:"text-right pr-[2.75rem] lg:pr-[2.25rem] w-[10%]" ,
          headerspanClassName:"ml-auto" ,
          Cell: (cell) => {
            return addCommasToNumber(cell?.value)
        },
      },
     
  ], []);
  
  /* The `rowData` constant is using the `useMemo` hook to memoize a function that transforms the
  `investment` data into a format that can be used by the `DataTable` component. */
  const rowData = useMemo(() => {
          const rows = [];
 
          investment.forEach(company => {
            console.log(company,"company");
            const sample = company?.top_portfolio;
            const companyName = sample.map((number) => number.Companyname);
            let companies = companyName.join(', ');
            // const companyId = sample.map((number) => number.PECompanyId)
            // var newArr = companyId.join();
            // console.log(newArr,"comma");
              rows.push({
                  investor: { name: company?.investor, id: company?.investor_id, logo_path:company?.logo_path},
                  portfolio: company?.portfolio_count,
                  topPortfolio:  { name:company.top_portfolio},
                  noOfFunds: company?.fund_count,
                  totalInvested: company?.total_invested,
              })
          });
          setshowXlDownload(rows.length!==0)
          return rows;
      }, [investment])
      /* The `initialSort` constant is using the `useMemo` hook to memoize an array of objects. Each
      object represents a column in the table and specifies the initial sorting configuration for
      that column. In this case, the `investor` column is set to be sorted in ascending order
      (`desc: false`) initially. This configuration is used by the `DataTable` component to
      determine the initial sorting of the table data. */
      const initialSort = useMemo(() => [
        {
            id: 'investor',
            desc: false
        }
    ], []);
  return (
    <>
      <div className="space-y-6">
        <div className="bg-white">
          <div className="space-y-6">
          <div className="bg-white pb-2 rounded-[6px]">
            <DataTable columns={columns} data={rowData} sortBy={initialSort}/>
          </div>
        </div>
        </div>
      </div>
    </>
  );
};

export default Investments;
