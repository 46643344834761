import React, {useContext} from 'react'; 
import ToCompanyInvestments from './ToCompanyInvestments';
import ToCompanyExists from './ToExits';
import ToInvestors from './ToInvestors';
import ToAcquirer from './ToAquirer';
import {TableDataContext} from '../../../Contexts/TableDataContext' 
 
/**
 * The `Card` component renders four different cards based on the `data` and `isDataLoad` values
 * received from the `TableDataContext`.
 * @returns The Card component is returning a JSX element.
 */
 const Card = () => {

  const { data, isDataLoad } = useContext(TableDataContext);
     
  return (
    <div className=""> 
      <div className='sm:full mt-[4.25rem] md:mt-[3rem] h-full  bg-[#F2F2F2]'>
           <div className="overflow-x-auto lg:grid grid-cols-4 gap-4  px-4 py-[0.7rem] flex xl:overflow-hidden scrollbar-remove ">
           {/* ----card1----- */}
           <ToCompanyInvestments data={data} isDataLoad={isDataLoad}/>
          {/* ----card2----- */}
          <ToCompanyExists data={data} isDataLoad={isDataLoad}/>
          {/* ----card3----- */}
          <ToInvestors data={data} isDataLoad={isDataLoad}/>
          {/* ----card4----- */}
          <ToAcquirer data={data} isDataLoad={isDataLoad}/> 
            </div>
        <div className='border-b-2 border-[#e5e5e5]'></div>
      </div >
    </div >
  )
}

export default Card;
