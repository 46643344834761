import React, { useContext } from 'react'
import { useEffect } from 'react';
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import MultiSelectEntity from '../RefineSearch/MultiSelectEntity'
import FilterWrapper from './FilterWrapper';

/**
 * The `CapitalSourceFilter` function is a React component that renders a filter for selecting capital
 * source options, and updates the selected options based on user input.
 * @returns a JSX element.
 */
function CapitalSourceFilter({ open, accordionClick, CapitalSourceOptions, setCapitalSourceOptions, customPathname }) {

    const { CapitalSource, refineSearchFilters } = useContext(PeFilterContext);

    useEffect(() => {
        const industryIds = refineSearchFilters[customPathname]?.capitalSource?.map(value => value.id) ?? [];
        setCapitalSourceOptions(CapitalSource?.map(industry => ({ ...industry, isChecked: industryIds.includes(parseInt(industry.id)) })))
    }, [CapitalSource, customPathname, refineSearchFilters, setCapitalSourceOptions])

  /* The `handleCapitalSourceChange` function is a callback function that is triggered when the user
  selects or deselects a capital source option. It receives an event object `e` as a parameter. */
    const handleCapitalSourceChange = ((e) => {
        const { name, checked, id } = e.target;

        if (name === "allSelect") {
            let currentCapitalSource = CapitalSourceOptions.map((capital) =>
                ({ ...capital, isChecked: checked })
            );
            setCapitalSourceOptions(currentCapitalSource);
        } else {
            let currentCapitalSource = CapitalSourceOptions.map((capital) =>
                capital.id === parseInt(id) ? { ...capital, isChecked: checked } : capital
            );
            setCapitalSourceOptions(currentCapitalSource);
        }

    });

   /* The line `const len = CapitalSourceOptions?.filter(option => option.isChecked)?.length ?? 0` is
   calculating the length of the filtered `CapitalSourceOptions` array. */
    const len = CapitalSourceOptions?.filter(option => option.isChecked)?.length ?? 0

    return (
        <div className="border-b-[3px] border-[#E8E6E1]">
            <li
                className={
                    open
                        ? "bg-white relative px-3 py-1"
                        : "relative px-3 py-1"
                }
            >
                <FilterWrapper
                    count={len}
                    isSelect={false}
                    label="Capital Source"
                    onClick={accordionClick}
                    open={open}
                />

                <div
                    className="relative overflow-hidden transition-all max-h-0 duration-700  overflow-y-auto no-scrollbar"
                    style={{ maxHeight: open ? "10rem" : "" }}
                >

                    {open === true ? <MultiSelectEntity
                        handleEntityChange={handleCapitalSourceChange}
                        primaryEntity={CapitalSourceOptions}
                    /> : <></>}

                </div>

            </li>

        </div>
    )
}

export default CapitalSourceFilter