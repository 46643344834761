import { useState } from "react";
import menuOpen from '../../images/Group 12.png';
import MultiSelectAll from '../../Utils/MultiSelectAllFile'

/**
 * The Sidebar function is a React component that renders a sidebar with a toggle button to open and
 * close it, and a section for refining search options.
 * @returns The Sidebar component is returning JSX elements. It consists of a div with conditional
 * styling based on the "open" state, a heading, a button to toggle the "open" state, and a div with
 * conditional display based on the "open" state. There is also an empty container div at the end.
 */
export default function Sidebar() {
    const [open, setOpen] = useState(true);
    return (
        <> 
            <div
                className={` ${
                    open ? "w-12" : "w-60 "
                } flex flex-col h-screen   shadow duration-300`}
            >
                <div className="space-y-3">
                    <div className="flex items-center px-3 pt-3 justify-between">
                        <h2 className="text-xl font-bold text-white">
                            <div style={{display:!open?"block":"none"}} className="text-[16px] tracking-[-.34px] font-semibold text-[#BC8B20]">Refine Search</div>
                        </h2>
                        <button onClick={() => setOpen(!open)}>
                            {!open?<img src={menuOpen} className="w-[25px] h-[25px]"/>:<img src={menuOpen} className="w-[25px] h-[25px] rotate-180"/>}
                        </button>
                    </div>
                    
                    
                    <div className="flex-1 " style={{display:!open?"block":"none"}}>
                        <p>Refine search</p>
                        {/* <MultiSelectAll /> */}
                    </div>
                </div>
            </div>
            <div className="container mx-auto mt-12">
            </div>
        </>
    );
}