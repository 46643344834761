import React, { useState, useContext, useEffect, useRef } from "react";
import { IoMdArrowDropdown, IoMdArrowDropright } from "react-icons/io";
import { FaFilter } from "react-icons/fa";
import { IoClose  } from "react-icons/io5";
import { PeFilterContext } from '../Contexts/PeFilterContext';
import { useLocation } from "react-router-dom";
import { BsArrowRightSquareFill } from "react-icons/bs";

export default function Sidebar({ children }) {
    
    const {dropdown,setDropdown ,setOpen,open,isMobile,setIsMobile} = useContext(PeFilterContext);
    const location = useLocation(); 
    const hasNumberInURLRef = useRef(false);

    useEffect(() => {
        const hasNumberInURL = /\d/.test(location.pathname);

        // If the URL contains a number and the sidebar has not been opened due to a number yet, open the sidebar
        if (hasNumberInURL && !hasNumberInURLRef.current) {
            setOpen(true);
            hasNumberInURLRef.current = true;
        } 
        // If the URL does not contain a number, close the sidebar and reset the ref
        else if (!hasNumberInURL) {
            setOpen(false);
            hasNumberInURLRef.current = false;
        }
    }, [location.pathname]);

    const handleToggle = () => {
        setDropdown(!dropdown);
    };
    useEffect(()=>{
        if (window.innerWidth < 800) {
            setOpen(true);
            setIsMobile(true);
        }    },[])

        return (
            <>
                <div
                    className={`${
                        open ? (isMobile ? "hidden" : "w-12") : (isMobile ? 'w-[245px]' : "w-[100vw] md:w-60" ) 
                    } flex flex-col h-full shadow duration-300`}
                >
                    <div className="space-y-3 h-screen">
                        <div className="flex items-center pl-1 pr-3 pt-3 justify-between">
                            <h2 className="text-xl font-bold text-white">
                                <div
                                    style={{ display: !open ? "flex" : "none" }}
                                    className="text-[16px] tracking-[-.34px] leading-[19px] font-sans_semi_bold text-[#BC8B20] cursor-pointer flex-row"
                                    onClick={handleToggle}
                                >
                                    <span className="mt-[2px]">
                                        {dropdown ? <IoMdArrowDropdown /> : <IoMdArrowDropright />}
                                    </span>
                                    <span className="ml-1">REFINE SEARCH</span>
                                </div>
                            </h2>
                            <button onClick={() => setOpen(!open)}>
                        {!isMobile && (
                                <BsArrowRightSquareFill
                                    style={{
                                        transform: !open ? "rotate(180deg)" : "none",
                                        height: 24,
                                        width: 24,
                                        color: "#bc8b20",
                                        borderRadius: 4,
                                    }}
                                />
                        )}
                        {!open && isMobile && <IoClose color="#bc8b20" size={25} className="" />}
                            </button>
                        </div>

                        <div className="flex-1" style={{ display: !open ? "block" : "none" }}>
                            {children}
                        </div>
                    </div>
                </div>

                {/* {isMobile && (
                    <button
                        className={`fixed z-50 bottom-16 right-3 mb-2 drop-shadow-md rounded-full bg-[#bc8b20] ${!open ? 'p-[12px]' : 'p-[14px]'}`}
                        onClick={() => setOpen(!open)}
                    >
                    {!open ? <IoClose   color="#ffffff" size={25} /> : <FaFilter   color="#ffffff" size={21} />}
                    </button>
                )} */}

                {!isMobile && <div className="container mx-auto mt-12"></div>}
            </>

        );
}
