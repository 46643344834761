import Header from './CfsHeader'
import SideMenu from './SideMenu'
  
export default function Layout({children}) {
  return children;
 
  return (
    <>
      <div className="bg-gray-100">
        <div className="min-h-screen flex flex-col">
            {/* Top nav*/}
            <Header />
            {/* Bottom section */}
            <div className=" min-h-0 overflow-hidden">
            {/* Narrow sidebar*/}
            <div className="hidden md:block	">
                <SideMenu  />
            </div>
            
            {/* Main area */}
            <main className="lg:ml-[5rem] md:ml-[5.25rem]">
               {children}
            </main>
            
            </div>
        </div>
      </div>
    </>
  )
}
