import React, { useState, useMemo, useEffect, useContext } from "react";
import {  useParams,  useLocation } from 'react-router-dom';
import moment from "moment"; 
import DataTable from '../../Components/DataTables/DataTable';
import axios from "axios";
import { getRecentNewsUrl } from "../../Config/config";
import { UserContext } from "../../Contexts/UserContext";
import useModule from "../../hooks/useModule"; 
import { DataFoundContext } from "../../Contexts/DataFoundContext";

/**
 * The `NewsActivity` function is a React component that fetches recent news and activity data for a
 * specific company and displays it in a table format.
 * @returns The NewsActivity component is being returned.
 */
const NewsActivity = () => {

  const [newsletter, setNewsletter] = useState([]);

  const { companyId } = useParams();
  const { getToken } = useContext(UserContext);
  const {primaryMasterId} = useModule();
  const { newsAndActivityDataFound, setNewsAndActivityDataFound } = useContext(DataFoundContext);
  // let companyName = menu =="companydetail"?"%" + COMPANY_DETAIL?.companyname + "%"
  //                   :menu =="investorDetail"? "%" + INVESTOR_PROFILE_TABLE_QUERY?.Investor + "%"
  //                   :" ";
  // let companyName ="%" + COMPANY_DETAIL?.companyname + "%"
  // console.log(companyName,"companyname");
  // let investor = "%" + INVESTOR_PROFILE_TABLE_QUERY?.Investor + "%";
  // console.log(investor,"investorname");
  // const handleFetchNewsletterRequestCallback = (response, requestSuccess) => {
  //   try {
  //     setNewsletter(response);
  //     console.log("newsletter123", response);
  //   } catch (e) {
  //     console.log('Error', e)
  //   }
  // };

  // const { data, loading, error } = useQuery(NEWSLETTER, {
  //   variables: { TagName: companyName },
  //   onCompleted: (response) => handleFetchNewsletterRequestCallback(response, true),
  //   onError: (response) => handleFetchNewsletterRequestCallback(response),
  //   refetch: NEWSLETTER

  // })
  // const pageNumbers = []
  // const NewsLetterData = useMemo(() => newsletter, [newsletter]);
  // const indexOfLastPost = currentPage * dataPerPage;
  // const indexOfFirstPost = indexOfLastPost - dataPerPage;

  const getNews = () => {

    axios.post(getRecentNewsUrl, {
      "company_id": companyId,
      "type": primaryMasterId
    }, {
      headers: {
        'Authorization': getToken(),
      }
    }).then(response => {
      if (response?.data?.message === "Successfully Data Fetched" && response?.data?.data) {
        setNewsAndActivityDataFound(true);
        setNewsletter(response.data.data);
      } else if (response?.data?.message === "No Data Found") {
        setNewsAndActivityDataFound(false);
      }
    }).catch(error => {
      setNewsAndActivityDataFound(false);
    })
  }
  // console.log(newsAndActivityDataFound, 'from useEffect');

  useEffect(() => {
    // console.log("newsletter123", newsletter)


    // setCurrentData(NewsLetterData?.newsletter?.slice(indexOfFirstPost, indexOfLastPost));

    // for (let i = 1; i <= Math.ceil(NewsLetterData?.newsletter?.length / dataPerPage); i++) {
    //   pageNumbers.push(i);
    // }
    getNews();
    return () => {
      setNewsAndActivityDataFound(false)
    } 
  },
  [companyId]
  )
  // console.log("newsletter12345", NewsLetterData?.newsletter)
  // const setPage = (pageNum) => {
  //   setCurrentPage(pageNum)
  // }

  //sorting
  // const sorting = (col) => {
  //   const numAscending = [...NewsLetterData?.newsletter].sort((a, b) =>
  //     col == "heading" ?
  //       order ?
  //         a.heading > b.heading ? 1 : -1
  //         :
  //         a.heading < b.heading ? 1 : -1
  //       :
  //       order ?
  //         a.published_at > b.published_at ? 1 : -1
  //         :
  //         a.published_at < b.published_at ? 1 : -1
  //   );
  // setOrder(!order)
  // setCurrentData(numAscending?.slice(indexOfFirstPost, indexOfLastPost));

  // for (let i = 1; i <= Math.ceil(numAscending?.length / dataPerPage); i++) {
  //   pageNumbers.push(i);
  // }
  // };
  const customStringSortMemo = useMemo(() => {
    return (rowA, rowB, columnId, desc) => {
      const defaultVal = desc ? '--' : 'A';
      return (rowA?.values[columnId]?.news ?? defaultVal).localeCompare(rowB?.values[columnId]?.news ?? defaultVal);
    }
  }, []);
  const dealDateSort = useMemo(() => {
    return (rowA, rowB, columnId, desc) => {
        return moment.utc(rowA?.values[columnId].date).diff(moment.utc(rowB?.values[columnId].date))
    }
}, [])
  const columns = useMemo(() => [
    {
      id: "news",
      Header: "News",
      accessor: "news",
      className: "text-[#333333] text-[12pt]  py-4 flex-wrap lg:text-[14px] lg:leading-[16px]",
      headerClassName: "lg:w-[15%]",
      Cell: ({ value }) => {
        return <a href={value.link} target="_blank" rel="noreferrer">
          {value.news}
        </a>
      },
      sortType: customStringSortMemo,
    },
    {
      id: "releaseDate",
      Header: "Release Date",
      accessor: "releaseDate",
      className: "text-[#333333] pr-[3.2rem] md:pr-[3.5rem] py-4 float-center lg:float-right whitespace-nowrap lg:text-[14px] lg:leading-[16px] text-right",
      headerClassName: "text-center float-right flex lg:px-10 px-8 py-3.5",
      Cell: ({ value }) => {
        const date =value?.date.trim()
        return moment(date).format('MMM DD, YYYY')
      },
      sortType: dealDateSort,
    }
  ], [])

  const rowData = useMemo(() => { 
    const rows = [];

    if (newsletter) {
      newsletter?.forEach(newsData => {
        rows.push({
          news: { news: newsData?.news, link: newsData?.link },
          releaseDate: { date: newsData?.release_date, news: newsData?.news }
        })
      });
    }

    return rows;

  }, [newsletter])
  const initialSort = useMemo(() => [
    {
      id: "releaseDate", 
      desc: true
    }
  ], []);
  return (
    <>
      {newsAndActivityDataFound ? (
        <div className="mx-4 ">
          <div className="bg-white border mb-[-1.75rem] overflow-hidden sm:rounded-lg shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
            <div className="pt-4  px-5">
              <p className="text-[14pt] leading-[19pt] lg:text-[#333333] lg:text-[17px] font-semibold  lg:leading-[22px] mb-3 ">
                Recent News & Activity
              </p>
            </div>
            <DataTable columns={columns} data={rowData}
              sortBy={initialSort}
            />
          </div>
          <br />
          <br />
        </div>
      ) : (
        ""
      )} 
    </>
  );
};

export default NewsActivity;
