import React, {useContext} from 'react';
import {TableDataContext} from '../../../Contexts/TableDataContext'
import DealValueInr from '../../RE/ReListCard/DealValueInr'
import DealValueUsd from '../../RE/ReListCard/DealValueUsd'
import InvestmentCard from '../../RE/ReListCard/InvestmentCard'
import AllDealCard from '../../RE/ReListCard/AllDealCard'

/**
 * The `Card` component renders four different cards based on the data received from the
 * `TableDataContext`.
 * @returns The Card component is returning a JSX element.
 */
 const Card = () => {
  const { data, isLoad, isDataLoad } = useContext(TableDataContext);
  return (
    <div className=""> 
      <div className='sm:full md:mt-[3rem] mt-[4.25rem] h-full bg-[#F2F2F2]'>
           <div className="overflow-x-auto lg:grid grid-cols-4 gap-4  px-4 py-[0.7rem] flex xl:overflow-hidden scrollbar-remove ">
           {/* ----card1----- */}
          <DealValueUsd data={data} isLoad={isLoad} isDataLoad={isDataLoad} />
          {/* ----card2----- */}
          <DealValueInr data={data} isLoad={isLoad} isDataLoad={isDataLoad}/>
          {/* ----card3----- */}
          <InvestmentCard data={data} isLoad={isLoad} isDataLoad={isDataLoad}/> 
          {/* ----card4----- */}
          <AllDealCard data={data} isLoad={isLoad} isDataLoad={isDataLoad}/>

            </div>
        <div className='border-b-2 border-[#e5e5e5]'></div>
      </div >
    </div >
  )
}

export default Card;
