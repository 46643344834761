import React from "react";
import mindTreeLogo from "../../images/mindtree-logo copy/png/mindtree-logo copy.png";

/**
 * The above code is a React component called `Notify` that renders a list of notifications based on
 * the `notificationData` prop.
 * @param props - The `props` parameter is an object that contains the properties passed to the
 * `Notify` component. These properties can be accessed using dot notation, such as
 * `props.notificationData` and `props.handleNotificationClick`.
 * @returns The component is returning a JSX structure that renders a list of notifications. If there
 * are no unread notifications, it displays a message saying "No unread records". Otherwise, it maps
 * through the `props.notificationData.unread` array and renders a list item for each notification.
 * Each list item contains information such as the entity type, notified date, and message of the
 * notification.
 */
const Notify = (props) => {

  return (
    <div className="">
      <ul className="divide-y">
        {props.notificationData && props.notificationData.unread.length < 1 ? (
          <li className="lg:text-[15px] text-[15px] text-[#333333] mt-[14rem] text-center">No unread records</li>
        ) : (
          props.notificationData.unread.map((data, i) => (
            <li className="pt-2 pl-[1.75rem] cursor-pointer hover:bg-gray-200" onClick={() => props.handleNotificationClick(data,false,'general')} key={i}>
              <div className="flex space-x-2">
                <img
                  className="h-6 w-6 rounded-full mt-2"
                  src={mindTreeLogo}
                  alt=""
                />
                {/* <div className="flex-1 pt-2">
                  <div className="flex items-center justify-between">
                    <h3 className=" flex text-[14px] text-[#333333]  font-sans_medium_body tracking-[-0.26px] leading-[17px]">
                      {data.entity_name}                      
                    </h3>                   
                  </div>
                  <p className="text-[13px] font-sans_book_body leading-[16px] tracking-[0.32px]  text-[#888888]">
                    {data.date}
                  </p>
                  <em className="text-[13px] font-sans_bookObl_body text-[#333333] pt-1 leading-[18px] tracking-[-0.32px]">
                    {data.message}
                  </em>
                </div> */}
                 <div className="flex-1 pt-2">
                    <div className="flex items-center justify-between">
                      <h3 className="flex text-[12px] text-[#888888] font-sans_book_body tracking-[-0.26px] leading-[14px]">
                        {data?.entity_type ? data?.entity_type : ''}
                      </h3>
                      <div className="flex px-4">
                        <p className="text-[12px] pl-2 leading-[14px] font-sans_book_body tracking-[-0.26px] text-[#888888]">
                          {data?.notified_at ? data?.notified_at : ''}
                        </p>
                      </div>
                    </div>
                    {/* <p className="text-[15px] leading-[18px] font-sans_medium_body  text-[#1F2D3A]">
                      {data.entity_name ? data.entity_name : ''}
                    </p> */}
                    <p className="text-[13px] text-[#56626D] pt-1 pb-2 leading-[18px] tracking-[-0.35px] font-sans_book_body ">
                    {data?.message ? data?.message : ''}
                    </p>
                  </div>
              </div>
            </li>
          ))
        )}
      </ul>
    </div>
  );
};

export default Notify;
