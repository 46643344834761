import React, { useContext } from 'react';
import PeVcInvestors from './PeVcInvestors';
import PeVcInvestments from './PeVcInvestments';
import ActiveInvestors from './ActiveInvestors';
import NewInvestors from './NewInvestors';
// import { PE_VC_INVESTOR_CARD_QUERY, PE_VC_INVESTMENT_CARD_QUERY } from '../../../Queries/Pe'
import { TableDataContext } from '../../../Contexts/TableDataContext';
import { Tooltip } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import useModule from '../../../hooks/useModule';
import Constants from '../../../Constants';
import routePathSegments, { routeNames } from '../../../routeSegments';
import { PeFilterContext } from '../../../Contexts/PeFilterContext';

/**
 * The Card component is a functional component in JavaScript that renders a investors cards.
 * @returns The Card component is returning a JSX element.
 */
const Card = (props) => {
  const { data, isDataLoad } = useContext(TableDataContext);
  const {monthPickerValue} = useContext(PeFilterContext)
  console.log("props?.data?.num_investments", monthPickerValue);
  const navigateTo = useNavigate();
  const { primaryMasterId } = useModule();
  const location = useLocation();
  const pathSegs = location.pathname.split('/');
  let isIpo = true;
  let isExits = false;
  let isExits_ma = false;
  let isExits_pm = false;
  let isAngel = false;
  let isIncubation = false;

  if (pathSegs.includes(routePathSegments.backed_ipos)) {
    isIpo = true;
  } else {
    isIpo = false;
  }  

  if (pathSegs.includes(routePathSegments.ma)) {
    isExits_ma = true;
  } else {
    isExits_ma = false;
  }

  if (pathSegs.includes(routePathSegments.pm)) {
    isExits_pm = true;
  } else {
    isExits_pm = false;
  }

  if (pathSegs.includes(routePathSegments.exists)) {
    isExits = true;
  } else {
    isExits = false;
  }

  if (pathSegs.includes(routePathSegments.angel_invesments)) {
    isAngel = true;
  } else {
    isAngel = false;
  }

  if (pathSegs.includes(routePathSegments.incubation)) {
    isIncubation = true;
  } else {
    isIncubation = false;
  }



  return (
    <div className="">
      <div className='sm:full  mt-[4.25rem] lg:mt-[3rem] h-full bg-[#EDEDED]'>
        <div className="overflow-x-auto lg:grid grid-cols-4 gap-4  px-4 py-[0.7rem] flex xl:overflow-hidden scrollbar-remove ">
          {/* ----card1----- */}
          <PeVcInvestors data={data} isDataLoad={isDataLoad} card1={props.card1} />
          {/* ----card2----- */}
          <PeVcInvestments data={data} isDataLoad={isDataLoad} card2={props.card2} />
          {/* ----card3----- */}
          <Tooltip title="Investor who has made more than 3 investments in the chosen period">
            <span onClick={() => {
              if (!isIpo) {
                let route = "";
                if (primaryMasterId == Constants.primaryMasterIds.peId) {

                  if (isExits_ma) {
                    route = routeNames.pe_active_investors_exits_ma;
                  }
                  else if (isExits_pm) {
                    route = routeNames.pe_active_investors_exits_pm;
                  }
                  else if (isExits) {
                    route = routeNames.pe_active_investors_exits;
                  } else if (isAngel) {
                    route = routeNames.pe_active_investors_angel;
                  } else if (isIncubation) {
                    route = routeNames.pe_active_investors_incubation
                  } else {
                    route = routeNames.pe_active_investors_investments
                  }
                } else {
                  if (isExits_ma) {
                    route = routeNames.vc_active_investors_exits_ma;
                  }
                  else if (isExits_pm) {
                    route = routeNames.vc_active_investors_exits_pm;
                  }
                  else if (isExits) { 
                    route = routeNames.vc_active_investors_exits
                  } else if (isAngel) {
                    route = routeNames.vc_active_investors_angel
                  } else if (isIncubation) {
                    route = routeNames.vc_active_investors_incubation
                  } else {
                    route = routeNames.vc_active_investors_investments
                  }
                }
                navigateTo(route, { state: { prevPath: location.pathname , date:monthPickerValue} })
              }

            }} className='cursor-pointer flex-shrink-0'>
              <ActiveInvestors data={data} isDataLoad={isDataLoad} card3={props.card3} />
            </span>
          </Tooltip>
          {/* ----card4----- */}

          <span onClick={() => {
            if (!isIpo) {
              let route = "";
              if (primaryMasterId == Constants.primaryMasterIds.peId) {
                if (isExits_ma) {
                  route = routeNames.pe_new_investors_exits_ma;
                }
                else if (isExits_pm) {
                  route = routeNames.pe_new_investors_exits_pm;
                }
                else if (isExits) { 
                  route = routeNames.pe_new_investors_exits
                } else if (isAngel) {
                  route = routeNames.pe_new_investors_angel
                } else if (isIncubation) {
                  route = routeNames.pe_new_investors_incubation
                } else {
                  route = routeNames.pe_new_investors_investments
                }
              } else {
                if (isExits_ma) {
                  route = routeNames.vc_new_investors_exits_ma;
                }
                else if (isExits_pm) {
                  route = routeNames.vc_new_investors_exits_pm;
                }
                else if (isExits) { 
                  route = routeNames.vc_new_investors_exits
                } else if (isAngel) {
                  route = routeNames.vc_new_investors_angel
                } else if (isIncubation) {
                  route = routeNames.vc_new_investors_incubation
                } else {
                  route = routeNames.vc_new_investors_investments
                }
              }
              navigateTo(route, { state: { prevPath: location.pathname, date:monthPickerValue } })
            }
          }} className='cursor-pointer flex-shrink-0'>
            <NewInvestors data={data} isDataLoad={isDataLoad} card4={props.card4} />
          </span>

        </div>
        <div className='border-b-2 border-[#e5e5e5]'></div>
      </div >
    </div >
  )
}

export default Card;
