import React from 'react'
import { Fragment, useState ,useContext,useEffect} from 'react'
import { Menu, Transition } from '@headlessui/react'
import SortIconAsc from 'mdi-react/ArrowDropDownIcon'
import {CurrencyContext} from '../../Contexts/CurrencyContext'
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

/** The `CurrencyTypeComponent`  component  is responsible for
rendering a dropdown menu for selecting a currency type. */
const CurrencyTypeComponent = () => {
    const { currencyType, setCurrencyType,setCurrency} = useContext(CurrencyContext);    
            
    return (
    <Menu as="div" className="relative inline-block text-left" on>
        <div>
        <Menu.Button className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-[5px] pl-[9px] pr-[3px] text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none whitespace-nowrap ">
        {({ open }) => ( 
            <>
            <span>{currencyType}</span>
            <SortIconAsc className="ml-0 md:ml-2 h-5 w-5" aria-hidden="true" /> 
            </>
        )}                  
        </Menu.Button>
        </div>

        <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"        
        >
        <Menu.Items className="absolute right-0 z-10 mt-2 md:w-40 w-24  origin-top-right rounded-md bg-white shadow-lg focus:outline-none">
            <div className="py-1">
            <Menu.Item>
                {({ active }) => (
                <button 
                onClick={()=>{setCurrencyType('INR') ;setCurrency('C')}}
                className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block md:px-4 px-1 py-1 md:py-2 text-sm w-full md:text-left text-center'
                )}>INR</button>
                )}
            </Menu.Item>
            <Menu.Item>
                {({ active }) => (
                    <button 
                    onClick={()=>{setCurrencyType('USD');setCurrency('M')}}
                    className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-1 md:px-4 py-1 md:py-2 text-sm w-full md:text-left text-center'
                    )}>USD</button>
                
                )}
            </Menu.Item>            
            </div>
        </Menu.Items>
        </Transition>
    </Menu>
    )
    }


export default CurrencyTypeComponent
