import React, {useContext} from 'react'
import { CompareCompanyContext } from '../../Contexts/CompareCompanyContext'


/* The `StaticContent` function is a React component that renders a list of `<p>` elements based on the
data provided by the `companyHeader` variable from the `CompareCompanyContext` context. */
function StaticContent() {
    const { companyHeader } = useContext(CompareCompanyContext);
    return (

        <div className=""> 
         <ul className="list-none divide-y">
        <div className="pt-1 font-sans_book_body"> 
        {companyHeader?.map((header, i) => { 
            return(
                header?.display_mode != "none" ?
                header?.dynamic_styling?
                <p
                    className={" leading-[24px] text-[14px] text-[#333333] pl-4 py-4 h-[4rem]"+
                    (
                        header?.display_bold ? " font-bold bg-[#ebebeb]" :" border-b "
                    )}>
                    {header?.label}
                </p>
            :
                <p
                    className={" leading-[24px] text-[14px] text-[#333333] pl-4 py-4 h-[3rem]"+
                    (
                        header?.display_bold ? " font-bold bg-[#ebebeb]" :" border-b "
                    )}>
                    {header?.label}
                </p>
                :""
            )
        })}  
        </div>
        </ul>   
                
            
        </div>

    )
}

export default StaticContent