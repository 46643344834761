import { React, useState, useMemo, useContext } from 'react'
import Table from './Table'
import { TableHeaderContext } from '../../Contexts/TableHeaderContext'


/**
 * The TableComponent function is a React component that renders a table based on the menu prop and
 * uses the TableHeaderContext for column headers and pagination data.
 * @param props - The `props` parameter is an object that contains the properties passed to the
 * `TableComponent` component. These properties can be accessed using dot notation, such as
 * `props.menu` to access the `menu` property.
 * @returns The TableComponent is being returned.
 */
const TableComponent = (props) => {
   
/* The code is using the `useContext` hook to access the values from the `TableHeaderContext`. It is
destructuring the values from the context and assigning them to the corresponding variables. */
const {
    PERETableHeadder,PEReExitTableHeader,OtherMATableHeader,PEREBackedIposHeader,
    reOrderingColumn,reIpoOrderingColumn
}=useContext(TableHeaderContext)

const [pagination, setPagination] = useState()
 
const paginationData = useMemo(() => pagination, pagination);    

/* The code is using a conditional (ternary) operator to assign a value to the `columns` variable based
on the value of the `props.menu` property. */
const columns = props.menu == "re" ? PERETableHeadder 
    : props.menu == "re-exit" ? PEReExitTableHeader 
   
    : props.menu == "re-ipo" ? PEREBackedIposHeader
    : props.menu == "re-ma" ? OtherMATableHeader
   
    :PERETableHeadder

   /* The code is assigning a value to the `orderBy` variable based on the value of the `props.menu`
   property. */
    const orderBy = props.menu == "re-ipo" ? reIpoOrderingColumn
    : reOrderingColumn
   
    return (
        <div className="pl-3 pr-4 lg:pr-2 ">
            <div className="App">
            <Table order="desc" orderBy={orderBy} menu={props?.menu} columns={columns} pagination = {paginationData} sub_type_master_id={props?.sub_type_master_id}/>
            </div>
        </div>
    )
}

export default TableComponent