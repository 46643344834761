import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ApolloProvider } from "@apollo/client";
import { CubeProvider } from '@cubejs-client/react';
import UserProvider from './Contexts/UserContext'
import PeFilterProvider from './Contexts/PeFilterContext'
import CompareCompanyContext from './Contexts/CompareCompanyContext';
import AddCompanyContext from './Contexts/AddCompanyContext';
import TableDownloadContext from './Contexts/TableDownloadContext';
import HeaderMenuContext from './Contexts/HeaderMenuContext';
import SearchMenuContext from './Contexts/SearchMenuContext';
import TableHeaderContext from './Contexts/TableHeaderContext';
import TableDataContext from './Contexts/TableDataContext';
import SearchExportContext from './Contexts/SearchExportContext';
import RaiseRequestContext from './Contexts/RaiseRequestContext';
import { cubeBaseUrl, client } from './Config/config'
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import cubejs from '@cubejs-client/core';
import FaqContextProvider from './Contexts/FaqContext';
import DataFoundProvider from './Contexts/DataFoundContext';
export const cubejsApi = cubejs(
  'bad7a9f9e002af792f6787265459e32f',
  { apiUrl: cubeBaseUrl }
);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React>
  <DataFoundProvider>
  <BrowserRouter>
    <ApolloProvider client={client}>
      <CubeProvider cubejsApi={cubejsApi}>
        <UserProvider>
          <AddCompanyContext>
            <CompareCompanyContext>
              <TableDownloadContext>
                <HeaderMenuContext>
                  <SearchMenuContext>
                    <TableHeaderContext>
                      <TableDataContext>
                        <SearchExportContext>
                          <FaqContextProvider>
                            <RaiseRequestContext>
                              <ConfigProvider
                                theme={{
                                  token: {
                                    colorPrimary: "#BC8B20"
                                  }
                                }}>
                                <App />
                              </ConfigProvider>
                            </RaiseRequestContext>
                          </FaqContextProvider>
                        </SearchExportContext>
                      </TableDataContext>
                    </TableHeaderContext>
                  </SearchMenuContext>
                </HeaderMenuContext>
              </TableDownloadContext>
            </CompareCompanyContext>
          </AddCompanyContext>
        </UserProvider>
      </CubeProvider>
    </ApolloProvider>
  </BrowserRouter>
  </DataFoundProvider>
  // {/* </React> */}
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
