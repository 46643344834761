import React from 'react'
import Layout from '../Components/Layout'


/**
 * The CFS function returns a JSX element that renders a layout with a centered paragraph displaying
 * the text "This is CFS Page".
 * @returns a JSX element.
 */
function CFS() {
    return (
        <div>
            <Layout>
                <center><p className="mt-[20rem]">This is CFS Page</p></center>
            </Layout>
        </div>
    )
}

export default CFS
