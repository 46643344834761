import React from 'react';
import classnames from 'classnames';
import { usePagination, DOTS } from './usePagination';
import rightArrow from '../images/Group 53/png/Group 53.png';
import leftArrow from '../images/Group 53 Copy/png/Group 53 Copy.png';
import './pagination.css';
/**
 * The `Pagination` function is a component that renders a pagination UI with previous and next
 * buttons, page numbers, and dots for indicating skipped pages.
 * @returns The Pagination component is returning a JSX element that represents a pagination container.
 * It includes a list of pagination items, including previous and next buttons, and a range of page
 * numbers. The selected page is highlighted with a different style.
 */
const Pagination = props => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  const onNext = () => {
    onPageChange(parseInt(currentPage) + 1);
  };

  const onPrevious = () => {
    onPageChange(parseInt(currentPage) - 1);
  };
  console.log( currentPage,
    totalCount,
    siblingCount,
    pageSize,"paginationRange")

  let lastPage = paginationRange?.at(paginationRange?.length - 1);
  return (
    <center>
    <ul
      className={classnames('pagination-container ', { [className]: className })}
    >
    {currentPage === 1?
      <li
      className={"cursor-not-allowed pagination-item"}
    >
        <img src={leftArrow} className="arrow-diable"/>
      </li>
      :
      <li
      className={"cursor-pointer pagination-item"}
      onClick={onPrevious}
    >
        <img src={leftArrow} className="arrow-default"/>
      </li>
      }
      {paginationRange.map(pageNumber => {
        // alert(currentPage)
        console.log(currentPage)
        if (pageNumber === DOTS) {
          return <li className="pagination-item dots">&#8230;</li>;
        }

        return (
          <li
            className={classnames('pagination-item', {
              selected: pageNumber === currentPage
            })+ "m-1 py-1 px-2 cursor-pointer" +(currentPage==pageNumber?" bg-white":"")}
            
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </li>
        );
      })} 
      {currentPage === lastPage?
      <li
        className={"cursor-not-allowed pagination-item"}
      >
        <img src={rightArrow} className="arrow-diable" />
      </li>
      :
      <li
        className={"cursor-pointer pagination-item"}
        onClick={onNext}
      >
        <img src={rightArrow} className="right-arrow-default" />
      </li>
      }
    </ul>
  </center>
  );
};

export default Pagination;
