import React, { useState, useEffect, useContext } from 'react'
import PE_VC from '../../images/noun-investment-2180740_4/png/noun-investment-2180740.png';
import TF_US from '../../images/Shape_4/png/Shape.png';
import TF_INR from '../../images/noun-investment-2180740_2/png/noun-investment-2180740.png';
import Company from '../../images/noun-investment-2180740_5/png/noun-investment-2180740.png';
import axios from 'axios';
import { dashboardTopCardApi } from '../../Config/config';
import { UserContext } from '../../Contexts/UserContext';
import { Tooltip } from 'antd';

const Card = () => {
    const [dashboardTopData, setDashboardTopData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const {getToken} = useContext(UserContext);

  /**
   * The function `getDashboardTopCard` makes a POST request to an API endpoint, passing in an
   * authorization token, and sets the response data to the `dashboardTopData` state variable.
   */
    const getDashboardTopCard = () => {
        setIsLoading(true);
        axios.post(dashboardTopCardApi, {

        },{
            headers:{
                "Authorization": getToken()
            }
        }).then(response => {
            setIsLoading(false);
            if (response.status === 200) {
                setDashboardTopData(response.data)
            }
        })
    }

    useEffect(() => {
        getDashboardTopCard();
    },[])

   /**
    * The function `addCommasToNumber` takes a number as input and returns a string representation of
    * the number with commas added for thousands separators.
    * @param number - The `number` parameter is the number to which you want to add commas.
    * @returns The function `addCommasToNumber` returns a string representation of the input number
    * with commas added for every three digits.
    */
    const addCommasToNumber = (number) => {
        return Math.round(number)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      };
    return (
            <div className=" mt-[3.5rem] sm:w-full h-full">
                <div className=" overflow-x-auto scrollbar-remove lg:grid grid-cols-4 px-4 gap-2 py-4 flex w-full xl:overflow-hidden border bg-[#EDEDED]">
                    <Tooltip title="Latest one year data">
                    <section className="flex-shrink-0 ">
                        <div className="bg-white p-3 rounded-md  border border-gray-200 overflow-hidden">
                        <div className="grid grid-cols-5 ">

                        <div className="col-span-4 ">

                            <div className="text-[13pt] leading-[16pt] tracking-[-0.3pt] lg:text-[13px] lg:leading-[16px] font-sans_book_body text-[#2B2A29] lg:tracking-[-0.3px]">PE-VC </div>
                            {isLoading ?
                            <span role="status">
                                <svg className="  mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-[#CA8A24]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                </svg>
                            </span> :
                            <div className="flex space-x-1 text-sm text-gray-500 tracking-[-0.3px]">
                                <span className='text-[18pt] leading-[19pt] font-bold lg:text-[16px] lg:leading-[23px] tracking-[-0.3px] text-[#A5711A]   '>
                                    $ {addCommasToNumber(dashboardTopData?.pe_amount_usd)} M / {addCommasToNumber(dashboardTopData?.pe_total_companies)} Cos
                                </span>
                                
                            </div>}

                            </div>

                            <div className="flex-shrink-0 pl-5 items-center">                           
                                <img className="h-[23px] w-[33px] mt-2" fill="currentColor" alt="img1"
                                src={PE_VC} />                           
                            </div>


                        </div>

                        </div>

                    </section>
                    </Tooltip>
                    <Tooltip title="Latest one year data">
                    <section className="flex-shrink-0">
                        <div className=" bg-white p-3  rounded-md  border border-gray-200 overflow-hidden">
                        <div className="grid grid-cols-5 ">

                            <div className="col-span-4 ">

                            <div className="text-[13pt] leading-[16pt] tracking-[-0.3pt] lg:text-[13px] lg:leading-[16px] font-sans_book_body text-[#2B2A29] lg:tracking-[-0.3px]">PE-RE </div>
                            {isLoading ?
                            <span role="status">
                                <svg className="  mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-[#CA8A24]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                </svg>
                            </span> :
                            <div className="flex space-x-1 text-sm text-gray-500 tracking-[-0.3px]">
                                <span className='text-[18pt] leading-[19pt] font-bold lg:text-[16px] lg:leading-[23px] tracking-[-0.3px] text-[#A5711A]   '>
                                    $ {addCommasToNumber(dashboardTopData?.re_amount_usd)} M / {addCommasToNumber(dashboardTopData?.re_total_companies)} Cos</span>
                                
                            </div>}

                            </div>
                            <div className="flex-shrink-0 pl-5 items-center">                               
                                <img className="h-[27px] w-[30px] mt-2" src={TF_US} alt="img2" />                            
                            </div>

                        </div>

                        </div>
                    </section>
                    </Tooltip>
                    <Tooltip title="Latest one year data">
                    <section className="flex-shrink-0 ">
                        <div className=" bg-white p-3  rounded-md  border border-gray-200 overflow-hidden">
                        <div className="grid grid-cols-5  ">

                             <div className="col-span-4 ">

                            <div className="text-[13pt] leading-[16pt] tracking-[-0.3pt] lg:text-[13px] lg:leading-[16px] font-sans_book_body text-[#2B2A29] lg:tracking-[-0.3px]">M&A</div>
                            {isLoading ?
                            <span role="status">
                                <svg className="  mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-[#CA8A24]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                </svg>
                            </span> :
                            <div className="flex space-x-1 text-sm text-gray-500 tracking-[-0.3px]">
                                <span className='text-[18pt] leading-[19pt] font-bold lg:text-[16px] lg:leading-[23px] tracking-[-0.3px] text-[#A5711A]   '>
                                    $ {addCommasToNumber(dashboardTopData?.ma_amount_usd)} M / {addCommasToNumber(dashboardTopData?.ma_total_companies)} Cos</span>
                               
                            </div>}

                            </div>

                            <div className="flex-shrink-0 pl-5 items-center">                            
                                <img className="h-[32px] w-[30px] mt-1" src={TF_INR} alt="img3" />                            
                            </div>


                        </div>

                        </div>

                    </section>
                    </Tooltip>
                    <Tooltip title="Total no.of companies">
                    <section className="flex-shrink-0 ">
                        <div className=" bg-white p-3 rounded-md  border border-gray-200 overflow-hidden">
                        <div className="grid grid-cols-5 ">

                             <div className="col-span-4">

                            <div className="text-[13pt] leading-[16pt] tracking-[-0.3pt] lg:text-[13px] lg:leading-[16px] font-sans_book_body text-[#2B2A29] lg:tracking-[-0.3px]">CFS </div>
                            {isLoading ?
                            <span role="status">
                                <svg className="  mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-[#CA8A24]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                </svg>
                            </span> :
                            <div className="flex space-x-1 text-sm text-gray-500 tracking-[-0.3px]">
                                <span className='text-[18pt] leading-[19pt] font-bold lg:text-[16px] lg:leading-[23px] tracking-[-0.3px] text-[#A5711A]   '>
                                    {addCommasToNumber(dashboardTopData?.cfs_total_companies)} Cos</span>                                
                            </div>}

                            </div>

                            <div className="flex-shrink-0 pl-5 items-center">
                                <img className="h-[30px] w-[27px] mt-2" src={Company} alt="img4"/>
                            </div>


                        </div>

                        </div>
                    </section>
                    </Tooltip>
                </div>
            </div>
    )
}
export default Card
