import React, { useState, useMemo} from "react";
import moment from "moment";
import DataTable from '../../../Components/DataTables/DataTable';
import { useContext } from "react";
import { DataFoundContext } from "../../../Contexts/DataFoundContext";


/**
 * The `AdvisorNewsActivity` component renders a table of recent news and activities, with columns for
 * the news heading and release date.
 * @param props - The `props` parameter is an object that contains the properties passed to the
 * `AdvisorNewsActivity` component. These properties can be accessed using dot notation, such as
 * `props.newsActivity`.
 * @returns The `AdvisorNewsActivity` component is returning a JSX structure that includes a div
 * containing a DataTable component. The DataTable component is rendered with the `columns` and
 * `rowData` as props, and it is wrapped in a div with some styling and a heading.
 */
const AdvisorNewsActivity = (props) => {
  const [newsletter, setNewsletter] = useState({});
  const { newsAndActivityDataFound } = useContext(DataFoundContext);
 
//   const customStringSortMemo = useMemo(() => {
//     return (rowA, rowB, columnId, desc) => {
//         const defaultVal = desc ? '--' : 'A';
//         return (rowA?.values[columnId]?.heading ?? defaultVal).localeCompare(rowB?.values[columnId]?.heading ?? defaultVal);
//     }
// }, []);

/* The `dealDateSort` constant is using the `useMemo` hook to memoize a sorting function. This sorting
function is used to sort the data in the "Release Date" column of the DataTable component. */
const dealDateSort = useMemo(() => {
  return (rowA, rowB, columnId, desc) => {
      return moment.utc(rowA?.values[columnId].date).diff(moment.utc(rowB?.values[columnId].date))
  }
}, [])

/* The `columns` constant is an array of objects that define the columns for the DataTable component.
Each object represents a column and contains properties such as `Header`, `accessor`, `className`,
`headerClassName`, `Cell`, and `sortType`. */
  const columns = useMemo(() => [
    {
      Header: "News",
      accessor: "news",
      className: "text-[#333333] w-[14%] text-[12pt] px-6 py-4 flex-wrap lg:text-[14px] lg:leading-[16px]",
      headerClassName:"w-[15%]",
      Cell: ({ value }) => {
        return <a href={"https://news.ventureintelligence.com/" + (value?.category?.slug) + '/' + value?.slug} target="_blank" rel="noreferrer">
          {value?.heading}
        </a>
      }
    },
    {
      id: 'releaseDate',
      Header: "Release Date",
      accessor: "releaseDate",
      className: "text-[#333333] lg:px-12 px-3 py-4 float-center lg:float-right whitespace-nowrap lg:text-[14px] lg:leading-[16px]",
      headerClassName: "text-center float-right flex lg:px-10 px-4 py-3.5",
      Cell: ({ value }) => {
        return moment(value?.date).format('MMM DD, YYYY')
      },
      sortType: dealDateSort,
    }
  ], [])
  
/* The `rowData` constant is using the `useMemo` hook to memoize a function that generates the data for
the DataTable component. */
  const rowData = useMemo(() => {
    const rows = [];
  
    if (newsletter) {
      props.newsActivity?.forEach(newsletter => {
        rows.push({
          news: newsletter,
          releaseDate: {date:newsletter?.published_at,news:newsletter}
        })
      });
    }
  
    return rows;
  
  }, [props.newsActivity])
 /* The `initialSort` constant is using the `useMemo` hook to memoize an array that specifies the
 initial sorting configuration for the DataTable component. */
  const initialSort = useMemo(() => [
    {
        id: 'releaseDate',
        desc: true
    }
  ], []);
  return (
    <>
      { newsAndActivityDataFound ? (
        <div className="pb-5 px-4 bg-[#EDEDED] w-[100%]">
          <div className="bg-white overflow-hidden sm:rounded-lg shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
            <div className=" pt-4 px-5 border-b border-b-[#F1F1F1] ">
              <p className="text-[14pt] leading-[19pt] mb-3 lg:text-[17px] lg:leading-[22px] font-semibold text-[#333333]">
                Recent News & Activities
              </p>
            </div>
            <DataTable columns={columns} data={rowData} sortBy={initialSort}/>        
          </div>
        </div>
      ) : ("")}
    </>
  );
};

export default AdvisorNewsActivity;
