import React, { useContext, useEffect } from 'react'
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import FilterWrapper from './FilterWrapper';

/**
 * The `ExitedFilter` function is a React component that renders a dropdown filter for selecting an
 * "Exited" option.
 * @returns JSX elements
 */
function ExitedFilter({ open, accordionClick, ExitedOptions, setExitedOptions, customPathname }) {
    const { Exited, refineSearchFilters } = useContext(PeFilterContext);

    useEffect(() => {
        const industryIds = refineSearchFilters[customPathname]?.exited?.map(value => value.value) ?? [];
        setExitedOptions(Exited?.map(industry => ({ ...industry, isChecked: industryIds.includes(industry.value) })))
    }, [Exited, customPathname, refineSearchFilters, setExitedOptions])


    const handleChange = (e) => {

        const value = e.target.value
        // const values = companyType.filter(companyType => companyType.id === e.target.value);
        setExitedOptions(ExitedOptions?.map(type => ({ ...type, isChecked: value === "" ? false : type.value === value })))
    };
    const selected = ExitedOptions?.filter(option => option.isChecked)?.at(0)
    return (
        <div className="border-b-[3px] border-[#E8E6E1]">
            <li
                className={
                    open
                        ? "bg-white relative px-3 py-1"
                        : "relative px-3 py-1"
                }
            >
                <FilterWrapper
                    isSelect={true}
                    label={selected?.name ? "Exited - " + selected?.name : "Exited"}
                    onClick={accordionClick}
                    open={open}
                />

                <div
                    className="relative overflow-hidden transition-all max-h-0 duration-700  overflow-y-auto no-scrollbar"
                    style={{ maxHeight: open ? "10rem" : "" }}
                >

                    <select name="" id="" value={selected?.value} onChange={handleChange}
                        className="my-2 p-2 border border-[#D9BD7F] mr-3 rounded focus:outline-none bg-white w-full">
                        {ExitedOptions && ExitedOptions.length > 0 ? (
                            ExitedOptions.map((exited, index) => (
                                <option key={exited.value} value={exited.value}>{exited.name}</option>
                            )))
                            :
                            (
                                <h1>No results found!</h1>
                            )}
                    </select>
                </div>

            </li>

        </div>
    )
}

export default ExitedFilter