import React from 'react'
import MALayout from '../../../Components/M&ALayout'
import Card from '../../../Components/MA/DetailCard/Card'
import AdvisorDetail from '../../../Components/MaAdvisors/AdvisorsDetail'
import RefineSearch from '../../../Components/RE/RefineSearchComponent'
import ChatIcon from '../../../images/Group 13/png/Group 13.png';
import chatPopupIcon from '../../../images/chatPopupIcon.png'
import ChatPopup from '../../../Components/ChatPopup'

/**
 * The DetailPage function is a React component that renders a layout with a primary and secondary
 * column, along with a card, advisor detail, and refine search components.
 * @returns The DetailPage component is returning a JSX element.
 */
function DetailPage() {
    const [showModal, setShowModal] = React.useState(false);
    return (
        <div>
            <MALayout>
                <div className="flex h-screen flex-col">
                    <Card />
                    <div className="flex min-h-0 flex-1 overflow-hidden">
                        {/* Primary column */}
                        <section
                            aria-labelledby="primary-heading"
                            className="flex h-full min-w-0 flex-1 flex-col overflow-y-auto lg:order-last">
                            <AdvisorDetail />

                            {/* <Outlet /> */}
                            {/* Your content */}
                        </section>
                        {/* Secondary column (hidden on smaller screens) */}
                        <aside className="hidden lg:order-first lg:block lg:flex-shrink-0">
                            <div className="relative flex h-full  flex-col overflow-y-auto border-r border-gray-200 bg-[#FAF5EA]">
                                {/* Your content */}
                                <RefineSearch />
                            </div>
                        </aside>
                    </div>
                    <br/>
                    
                </div>
            </MALayout>
        </div>
    )
}

export default DetailPage
