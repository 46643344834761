import React, { useState, useContext } from 'react';
import pe_Icon from '../../images/noun-investment-2180740_4/png/noun-investment-2180740.png';
import tfs_Icon from '../../images/Shape_4/png/Shape.png';
import tfIn_Icon from '../../images/noun-investment-2180740_2/png/noun-investment-2180740.png';
import companyIcon from '../../images/noun-investment-2180740_5/png/noun-investment-2180740.png';
import TextCard from '../../Components/ListPageCard/TextCard';
import { UserContext } from '../../Contexts/UserContext';
import { useParams } from 'react-router-dom';
import useModule from '../../hooks/useModule';
import { AcquirerDetailtopCard } from '../../Config/config';
import axios from 'axios';
import { useEffect } from 'react';
import moment from "moment";
import { PeFilterContext } from '../../Contexts/PeFilterContext';


/**
 * The `Card` component is a React component that displays various text cards with information about a
 * company's acquisitions.
 * @returns The Card component is returning a JSX element that represents a div containing four
 * TextCard components.
 */
const Card = () => {

  const [detailCard, setDetailCard] = useState();
  const { acquirerId } = useParams();
  let companyProfileId = parseInt(acquirerId)
  const { monthPickerValue } = useContext(PeFilterContext);

  const { getToken } = useContext(UserContext);
  const [isLoading, setisLoading] = useState(false);
  const { primaryMasterId } = useModule() 


  useEffect((month) => { 
    const abortController = new AbortController()
    setisLoading(true);
    axios.post(AcquirerDetailtopCard, {
      "acquirer_id": companyProfileId,
      "type": primaryMasterId,
      "date_range": {
        "from_date": monthPickerValue[0],
        "to_date": monthPickerValue[1]
    }

    }, {
      signal: abortController.signal,
      headers: {
        "Authorization": getToken()
      }
    }).then(response => {
      setisLoading(false);
      if (response.status === 200) {
        setDetailCard(response.data?.topCard)

      }
    }).catch(error => {
      setisLoading(false);
    })

    return () => abortController.abort();
  }, [companyProfileId, getToken, primaryMasterId])

  return (
    <div className="">
      <div className='sm:full mt-[4.25rem] md:mt-[3rem] h-full  bg-[#F2F2F2]'>
        <div className="overflow-x-auto lg:grid grid-cols-4 gap-4  px-4 py-[0.7rem] flex xl:overflow-hidden scrollbar-remove ">
          <TextCard
            image={pe_Icon}
            isLoading={isLoading}
            showCount={true}
            subtitle={detailCard?.announced_value_usd}
            title="Announced Value (US$ M)"
          />
          <TextCard
            image={tfs_Icon}
            isLoading={isLoading}
            showCount={true}
            subtitle={detailCard?.announced_value_inr}
            title="Announced Value (INR Cr)"
          />
          <TextCard
            image={companyIcon}
            isLoading={isLoading}
            showCount={true}
            subtitle={detailCard?.no_of_acquisitions}
          title="No of Acquisitions (With deal value)"
            subtext=" (with deal value)"
          />
          <TextCard
            image={tfIn_Icon}
            isLoading={isLoading}
            showCount={true}
            subtitle={detailCard?.total_acquisitions}
            title="Total Acquisitions"
          />          
        </div>
        <div className='border-b-2 border-[#e5e5e5]'></div>
      </div >
    </div >
  )
}

export default Card;
