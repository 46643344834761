import React, { useState, useEffect } from 'react';
import { Select } from 'antd';

const { Option } = Select;

/* The code is defining a functional component called `BlankComponent` that takes in four props:
`child`, `selectedChild`, `setSelectedChild`, and `options`. These props are destructured from the
component's props object in the function's parameter list. */
const BlankComponent = ({ child, selectedChild, setSelectedChild, options }) => {

  const childIndex = child.uniqueId;

/**
 * The handleChange function updates the value of a selected child based on the entered value.
 * @param event - The event parameter is the event object that is passed when the handleChange function
 * is called. It contains information about the event that triggered the function, such as the target
 * element and the value entered by the user.
 */
  const handleChange = (event) => {
    const enteredValue = event;
    // const childIndex = selectedChild.findIndex((selectedChild, index) => selectedChild.id === child.id );
    const childIndexx = selectedChild.findIndex((selectedChild, index) => selectedChild.uniqueId === childIndex );
    const updatedChild = { ...selectedChild[childIndexx] };
    updatedChild.values = [enteredValue];
    const updatedSelectedChild = [...selectedChild];
    updatedSelectedChild[childIndexx] = updatedChild;
    setSelectedChild(updatedSelectedChild);
  };

  
  return (
    <div className='singleSearch'>
  <Select
                key = {childIndex}
                name = "blank"
                id="blank"
                placeholder="Select"
                showSearch
                onChange={handleChange}
                className="rounded focus:outline-none w-[15rem]"
              >
                {/* <Option value="" disabled selected hidden>Select</Option> */}
                {options.map((option,i) => (
                  <Option value={option}>{option}</Option>
                ))}
              </Select>
              </div>
  );
};

export default BlankComponent;
