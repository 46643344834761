import React, {useEffect, useState ,useContext} from 'react';
import axios from 'axios'
// import ThousandSeperator from '../../Utils/ThousandSeperator';
// import PE_VC from '../../images/noun-investment-2180740_4/png/noun-investment-2180740.png';
// import TF_US from '../../images/Shape_4/png/Shape.png';
// import TF_INR from '../../images/noun-investment-2180740_2/png/noun-investment-2180740.png';
// import Company from '../../images/noun-investment-2180740_5/png/noun-investment-2180740.png';
import { IncubationDealCard } from '../../Config/config';
import { PeFilterContext } from '../../Contexts/PeFilterContext';
// import OverflowToolTip from '../shared/OverflowToolTip';

/**
 * The function `DealCardIncubation` is a JavaScript function that fetches and sets the top card
 * details.
 */
 const DealCardIncubation = () => {
  const [cardData, setCardData ] = useState({})
  const { monthPickerValue } = useContext(PeFilterContext);


  useEffect (()=> {
    axios(`${IncubationDealCard}`, {
      method: "POST",
      data: {
        "from_date": monthPickerValue[0],
        "to_date": monthPickerValue[1]
     },
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == "200") {
          console.log(response.data, "dealCarddata ");
          setCardData(response.data)
        }
      })
      .catch((error) => {
        console.log("API not working - dealCarddata", error);
      });
  },[]);

  return (
    <div className=""> 
      {/* <div className='sm:full mt-[4.25rem] md:mt-[3rem] h-full  bg-[#F2F2F2]'>
           <div className="overflow-x-auto lg:grid grid-cols-5 gap-4  px-4 py-[0.7rem] flex xl:overflow-hidden bg-[#EDEDED]">

          <section className="flex-shrink-0 ">
            <div className="bg-white px-3 py-1 rounded-md  border border-gray-200 overflow-hidden card-animate">
              <div className="grid grid-cols-5 ">
                <div className="col-span-4 ">
                  <div className="text-[13pt] leading-[17pt] lg:text-[13px] lg:leading-[17px] text-[#2B2A29] font-sans_book_body lg:tracking-[-0.3px]">Incubated Backed Cos.</div>
                  <div className="flex space-x-1 flex items-center">                    
                      <span className=' text-[18pt] leading-[23pt] font-sans_bold_body lg:text-[17px] lg:leading-[23px] tracking-[-0.3px] text-[#A5711A] '>{cardData?.incubation_top_card_count}</span>
                  </div>
                </div>
                <div className="flex-shrink-0 pl-5 flex items-center justify-center">
                  <a href="#">
                    <img className="h-[22px] max-w-[25px] " fill="currentColor"
                      src={PE_VC} />
                  </a>
                </div>
              </div>
            </div>
          </section>

          <section className="flex-shrink-0">
            <div className=" bg-white px-3 py-1  rounded-md  border border-gray-200 overflow-hidden card-animate">
              <div className="grid grid-cols-5 ">
                <div className="col-span-4">
                  <div className="text-[13pt]  leading-[17pt] tracking-[-0.3pt] lg:text-[13px] text-[#2B2A29] font-sans_book_body lg:leading-[17px] lg:tracking-[-0.3px]">Follow on %</div>
                  <div className="flex space-x-1  flex items-center">                    
                      <span
                        className='text-[18pt] font-sans_bold_body lg:text-[17px] leading-[23pt] lg:leading-[23px] tracking-[-0.3px] text-[#A5711A]'>{cardData?.follow_on}</span>
                  </div>                  
                </div>
                <div className="flex items-center pl-5 justify-center">
                  <div className="flex-shrink-0 ">
                    <a href="#">
                      <img className="h-[24px] max-w-[25px] "
                        src={TF_US} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="flex-shrink-0 ">
            <div className=" bg-white px-3 py-1 rounded-md  border border-gray-200 overflow-hidden card-animate card-animate">
              <div className="grid grid-cols-5 ">
                <div className="col-span-4 ">
                  <div className="text-[13pt]  leading-[17pt] tracking-[-0.3pt] lg:text-[13px] text-[#2B2A29] font-sans_book_body lg:leading-[17px] lg:tracking-[-0.3px]">Most Funded</div>
                  <div className="flex space-x-1 flex items-center  max-w-[13rem]">  
                  <OverflowToolTip className="text-[#A5711A] " key={cardData?.most_funded}
                      originalContent={                  
                          <a className='whitespace-nowrap text-[18pt] leading-[23pt] font-sans_bold_body lg:text-[17px] lg:leading-[23px] tracking-[-0.3px] text-[#A5711A] hover:text-[#A5711A]'>{cardData?.most_funded}</a>
                          } 
                      component={<a className='whitespace-nowrap cursor-text text-[13pt] leading-[18pt] font-sans_book_body lg:text-[13px] lg:leading-[18px] tracking-[-0.3px] text-[#333333] hover:text-[#000000]'>{cardData?.most_funded}</a>
                    }    
                  />
                  </div>
                </div>
                <div className="flex-shrink-0 pl-5 flex items-center justify-center">
                  <a href="#">
                    <img className="h-[26px] max-w-[25px]"
                      src={TF_INR} />
                  </a>
                </div>
              </div>
            </div>
          </section>

          <section className="flex-shrink-0 ">
            <div className=" bg-white px-3 py-1 rounded-md  border border-gray-200 overflow-hidden card-animate card-animate">
              <div className="grid grid-cols-5 ">
                <div className="col-span-4 ">
                  <div className="text-[13pt]  leading-[17pt] tracking-[-0.3pt] lg:text-[13px] text-[#2B2A29] font-sans_book_body lg:leading-[17px] lg:tracking-[-0.3px]"> Incubators </div>
                  <div className="flex space-x-1 text-sm text-gray-500 tracking-[-0.3px]">
                      <span
                        className='text-[18pt] font-sans_bold_body lg:text-[17px] leading-[23pt] lg:leading-[23px] tracking-[-0.3px] text-[#A5711A] '>{cardData?.angel_investor_count}</span>                  
                  </div>
                </div>
                <div className="flex-shrink-0 pl-5 flex items-center justify-center">
                  <a href="#">
                    <img className="h-[26px] max-w-[25px] "
                      src={Company} />
                  </a>
                </div>
              </div>
            </div>
          </section>

          <section className="flex-shrink-0 ">
            <div className=" bg-white px-3 py-1 rounded-md  border border-gray-200 overflow-hidden card-animate card-animate">
              <div className="grid grid-cols-5 ">
                <div className="col-span-4 ">
                  <div className="text-[13pt]  leading-[17pt] tracking-[-0.3pt] lg:text-[13px] text-[#2B2A29] font-sans_book_body lg:leading-[17px] lg:tracking-[-0.3px]">Most Active </div>
                  <div className="flex space-x-1  flex items-center whitespace-nowrap">
                  {cardData?.most_active_incubator ?
                    <OverflowToolTip className="text-[#A5711A] " key={cardData?.most_active_incubator} 
                    originalContent={
                        <a className=' text-[18pt] leading-[23pt] font-sans_bold_body lg:text-[17px] lg:leading-[23px] tracking-[-0.3px] text-[#A5711A] hover:text-[#A5711A]'>{cardData?.most_active_incubator}</a> }
                        component={
                        <a className='cursor-text text-[13pt] leading-[18pt] font-sans_book_body lg:text-[13px] lg:leading-[18px] tracking-[-0.3px] text-[#333333] hover:text-[#000000]'>{cardData?.most_active_incubator}</a> }
                    />
                  :''}
                  </div>
                   
                </div>
                <div className="flex-shrink-0 pl-5 flex items-center justify-center">
                  <a href="#">
                    <img className="h-[26px] max-w-[25px]"
                      src={Company} />
                  </a>
                </div>
              </div>
            </div>
          </section>
            
            </div>
        <div className='border-b-2 border-[#e5e5e5]'></div>
       

      </div > */}
    </div >
  )
}

export default DealCardIncubation;