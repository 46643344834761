import React, { useState } from 'react'

/* The code `export const FundingContext = React.createContext({ ... })` is creating a context object
called `FundingContext`. */
export const FundingContext = React.createContext({
    pevcData: null,
    setpevcData: () => { },
    existData: null,
    setexistData: () => { },
    ipoData: null,
    setipoData: () => { },
    angelData: null,
    setangelData: () => { },
    incubationData: null,
    setincubationData: () => { },
    enableExport: true,
    setEnableExport: () => { },
    reData: null,
    setreData: () => { },
    reExistData: null,
    setreExistData: () => { },
    reipoData: null,
    setreipoData: () => { },
    othermaData:null,
    setothermaData:() =>{ }
});

/**
 * The FundingDataProvider function is a React component that provides state and context values to its
 * children components.
 * @returns The FundingDataProvider component is being returned.
 */
function FundingDataProvider({ children }) {
    const [pevcData, setpevcData] = useState();
    const [existData, setexistData] = useState();
    const [ipoData, setipoData] = useState();
    const [angelData, setangelData] = useState();
    const [incubationData, setincubationData] = useState();
    const [reData, setreData] = useState();
    const [reExistData, setreExistData] = useState();
    const [reipoData, setreipoData] = useState();
    const [othermaData,setothermaData]=useState()

    const [enableExport, setEnableExport] = useState(true);

    return (
        <FundingContext.Provider value={{reData, setreData , reipoData, setreipoData,reExistData, setreExistData, pevcData, setpevcData, existData, setexistData, ipoData, setipoData, angelData, setangelData, incubationData, setincubationData, enableExport, setEnableExport ,othermaData,setothermaData}}>
            {children}
        </FundingContext.Provider>
    )
}

export default FundingDataProvider