import { Pagination } from 'antd'
import React from 'react'

/* The code defines a functional component called `DashboardPagination` that takes in four props:
`current`, `onChange`, `total`, and `pageSize`. */
function DashboardPagination({ current, onChange, total,pageSize }) {
    return (
        <Pagination
            current={current}
            onChange={onChange}
            total={total}
            pageSize={pageSize}
            showSizeChanger={false}
            // hideOnSinglePage
            itemRender={(page, type, element) => {
                if (type === "prev") {
                    return <div className="flex-1 flex justify-end  h-full">
                        <span
                            className="border-transparent pr-1/2 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
                            <svg className="mr-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fillRule="evenodd"
                                    d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                                    clipRule="evenodd" />
                            </svg>
                        </span>
                    </div>
                } else if (type === "next") {
                    return <div className="flex h-full">
                        <span
                            className="border-transparent pl-1/2 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
                            {/* <!-- Heroicon name: solid/arrow-narrow-right --> */}
                            <svg className="ml-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fillRule="evenodd"
                                    d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                                    clipRule="evenodd" />
                            </svg>
                        </span>
                    </div>
                }  else {
                    return element;
                }
            }} />
    )
}

export default DashboardPagination