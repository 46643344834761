import React, { useState,  useContext } from "react";
import { useParams} from "react-router-dom";
import axios from "axios";
import CompanyMasterData from "../CFSFinancial/CompanyMasterData";
import BoardOfDirectors from "../CFSFinancial/BoardOfDirectors";
import IndexOfCharges from "../CFSFinancial/IndexOfCharges";
import Loadpage from "../CFSFinancial/LoadPage";
import { fetchMCAInfoAPI } from "../../../../Config/config";
import { UserContext } from "../../../../Contexts/UserContext";
import useModule from "../../../../hooks/useModule";

const MCASiteMessage = ({ title, subtitle }) => {
  return <div className="space-y-6 px-5 lg:mx-1211 max-w-[40rem] mx-auto">
    <div className="bg-white py-5">
      <div className="border">
        <div className="border-b">
          <p className="text-[#BC8B20] text-[17px] py-2 px-5">{title}</p>
        </div>
        <div className="py-2.5">
          <p className="text-center font-sans_semi_bold text-[#333333] text-[14px]">
            {subtitle}
          </p>
        </div>
      </div>
    </div>
  </div>
}

/** This code is a component called `FormMCSite`. It is responsible for rendering different
tabs for displaying company master data, board of directors, and index of charges. */
const FormMCSite = () => {
  const [openTab, setOpenTab] = useState(1);
  const [showPopup, setShowPopup] = useState(false);
  const [mcaDataNew, setMcaDataNew] = useState({
    status: 200,
    success: true,
    bordMembers: {
      header: ["DIN/PAN", "Name", "Begin date", "End date", "Surrendered DIN"],
      value: [
        {
          pan: "02294316",
          name: "VISHWANATH SHIDRAMAPPA KOLHAR",
          begin_date: "17/01/2023",
          end_date: "-",
          surrendered_dIN: "",
        },
        {
          pan: "07850158",
          name: "PRABHU BALASRINIVASAN",
          begin_date: "03/07/2018",
          end_date: "-",
          surrendered_dIN: "",
        },
        {
          pan: "09136934",
          name: "YOGESH GUPTA",
          begin_date: "11/05/2022",
          end_date: "-",
          surrendered_dIN: "",
        },
      ],
    },
    companyMasterData: [
      {
        "CIN ": "U51909KA2011PTC060489",
      },
      {
        "Company Name": "FLIPKART INDIA PRIVATE LIMITED",
      },
      {
        "ROC Code": "RoC-Bangalore",
      },
      {
        " Registration Number": " 060489",
      },
      {
        " Company Category": "Company limited by Shares",
      },
      {
        " Company SubCategory": "Non-govt company",
      },
      {
        " Class of Company ": "Private",
      },
      {
        "Authorised Capital(Rs)": "14000000",
      },
      {
        "Paid up Capital(Rs)": "13450965",
      },
      {
        "Number of Members(Applicable in case of company without Share Capital)":
          "0",
      },
      {
        " Date of Incorporation": "19/09/2011",
      },
      {
        " Registered Address":
          "Buildings Alyssa, Begonia & Clover, Embassy Tech Village, Outer Ring Road, Devarabeesanahalli Village Bengaluru Bangalore KA 560103 IN ",
      },
      {
        "Address other than R/o where all or any books of account and papers are maintained":
          "-",
      },
      {
        "Email Id": "regulatory@flipkart.com",
      },
      {
        "Whether Listed or not": "Unlisted",
      },
      {
        "ACTIVE compliance": "ACTIVE compliant",
      },
      {
        "Suspended at stock exchange": " -",
      },
      {
        " Date of last AGM": "29/09/2022",
      },
      {
        " Date of Balance Sheet": "31/03/2022",
      },
      {
        " Company Status(for efiling)": "Active",
      },
    ],
  });
  const [mcaData, setMcaData] = useState({});
  const [mcaDataIndex, setMcaDataIndex] = useState({});
  const { getToken } = useContext(UserContext);

  const { companyId } = useParams();
  const { primaryMasterId } = useModule();
  let companyProfileId = parseInt(companyId);


  const fetchMCAInfo = (id, category, type) => {

    setShowPopup(!showPopup);

    if (category === "companyboard") {
      axios(`${fetchMCAInfoAPI}`, {
        method: "post",
        data: {
          company_id: id,
          category: category,
          type: type,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: getToken(),
        },
      })
        .then((response) => {
          setShowPopup(false);
          if (response?.data?.success === true) {
            setMcaData(response?.data);
          } else if (response?.data?.status === 440) {
            setMcaData(440);
          } else if (response?.data?.status === 403) {
            setMcaData(403);
          }
        })
        .catch((error) => {
          setMcaData(403);
          console.log("API not working", error);
        });
    } else {
      axios(`${fetchMCAInfoAPI}`, {
        method: "post",
        data: {
          company_id: id,
          category: category,
          type: type,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: getToken(),
        },
      })
        .then((response) => {
          setShowPopup(false);
          if (response?.data?.success === true) {
            if ((response?.data?.indexOfCharges?.header?.length ?? 0) === 0) {
              setMcaDataIndex(440);
            } else {
              setMcaDataIndex(response?.data);
            }
          } else if (response?.data?.status === 440) {
            setMcaDataIndex(440);
          } else if (response?.data?.status === 403) {
            setMcaDataIndex(403);
          }
        })
        .catch((error) => {
          setMcaDataIndex(403);
          console.log("API not working", error);
        });
    }
  };


/** The above code is a JavaScript React component that renders a placeholder UI for displaying company
master data, board of directors, or index of charges. It includes a button that triggers a function
called `fetchMCAInfo` when clicked. The function fetches data from an external web site (Ministry Of
Corporate Affairs). If a popup is shown, it renders a `Loadpage` component.  */
  const renderPlaceHolder = (target, companyID, type) => {
    var category = "companyboard"
    if (target === "company" || target === "director") {
      category = 'companyboard';
    } else {
      category = 'index';
    }
    return (
      <div className="space-y-6 lg:mx-12">
        <div className="bg-white py-5 pt-0 ml-0 lg:pl-5">
          <div className="mx-5 lg:mx-0  md:w-[41rem] lg:w-[50rem] text-center bg-white border rounded-md ">
            <p className="py-3 text-left  px-5   text-[14pt]  leading-[17pt] lg:text-[13px] lg:leading-[17px] font-sans_semi_bold  text-[#2B2A29] tracking-[-0.3px] ">
              {target === 'company' ? "Company Master Data " : target === 'director' ? 'Board of Directors' : "Index of Charges"}
            </p>
            <p className="mb-5 px-4 lg:px-0 font-sans_semi_bold  border-t text-[9pt] lg:text-[13px]  py-1 text-[#333333]">
              This data is being fetched from an external web site (Ministry Of
              Corporate Affairs).
            </p>
            <div className="items-center justify-center space-y-4 sm:space-y-0 sm:space-x-4">
              <button
                // onClick={() => setShowPopup(!showPopup)}
                onClick={() => fetchMCAInfo(companyID, category, type)}
                className="bg-[#BC8B20] px-2 h-[28px] text-[#FFFFFF] rounded-[5px] text-[12px] lg:mb-5 leading-[17px]"
              >
                OK, Please Load.
              </button>
              {showPopup && (
                <div>
                  <Loadpage target={target} showPop={showPopup} setShowPop={setShowPopup} />
                </div>
              )}
              <p className="py-3  text-[9pt]  leading-[17pt] lg:text-[13px] lg:leading-[17px] font-sans_book_body  text-[#2B2A29] tracking-[-0.3px] ">
                It can be upto 2 minutes to load.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="space-y-6" x-show="activeTab === 1">
      <div className="" x-show="activeTab === 1">
        <div className="">
          <div className=" flex flex-row overflow-x-auto scrollbar  space-x-6 py-4 px-6 pt-4 cursor-pointer scrollbar-remove">
            <a
              className={
                "inline-block px-3 py-1 whitespace-nowrap text-[9pt] lg:text-[13px] cursor-pointer leading-[16px]  rounded-[13.5px] " +
                (openTab === 1
                  ? "text-[#FFFFFF] bg-[#BC8B20] border border-[#BC8B20] font-sans_medium_body"
                  : "text-[#BC8B20] border border-[#BC8B20] font-sans_book_body")
              }
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(1);
              }}
              data-toggle="tab"
              href="#link1"
              role="tablist"
            >
              Company Master Data
            </a>
            <a
              className={
                "inline-block px-3 py-1 whitespace-nowrap text-[9pt] lg:text-[13px] cursor-pointer leading-[16px]  rounded-[13.5px] " +
                (openTab === 2
                  ? "text-[#FFFFFF] bg-[#BC8B20] border border-[#BC8B20] font-sans_medium_body"
                  : "text-[#BC8B20] border border-[#BC8B20] font-sans_book_body")
              }
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(2);
              }}
              data-toggle="tab"
              href="#link2"
              role="tablist"
            >
              Board of Directors
            </a>
            <a
              className={
                "inline-block px-3 py-1 whitespace-nowrap text-[9pt] lg:text-[13px] cursor-pointer leading-[16px]  rounded-[13.5px] " +
                (openTab === 3
                  ? "text-[#FFFFFF] bg-[#BC8B20] border border-[#BC8B20] font-sans_medium_body"
                  : "text-[#BC8B20] font-sans_book_body border border-[#BC8B20]")
              }
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(3);
              }}
              data-toggle="tab"
              href="#link2"
              role="tablist"
            >
              Index of Charges
            </a>
          </div>
          <div className={openTab === 1 ? "block" : "hidden"} id="link1">
            {Object.keys(mcaData).length > 0 ? (
              <CompanyMasterData data={mcaData} />
            ) : mcaData === 440 ? (
              <MCASiteMessage subtitle="No records found!" title="COMPANY MASTER DATA" />
            ) : mcaData === 403 ? (
              <MCASiteMessage subtitle="The MCA site is not responding, please try again later" title="COMPANY MASTER DATA" />
            ) : (
              renderPlaceHolder('company', companyProfileId, primaryMasterId)
            )}
          </div>
          <div className={openTab === 2 ? "block" : "hidden"} id="link2">
            {Object.keys(mcaData).length > 0 ? (
              //   <CompanyMasterData data={mcaData} />
              <BoardOfDirectors data={mcaData} />
            ) : mcaData === 440 ? (
              <MCASiteMessage subtitle="No records found!" title="BOARD OF DIRECTORS" />
            ) : mcaData === 403 ? (
              <MCASiteMessage subtitle="The MCA site is not responding, please try again later" title="BOARD OF DIRECTORS" />
            ) : (
              renderPlaceHolder('director', companyProfileId, primaryMasterId)
            )}
          </div>
          <div className={openTab === 3 ? "block" : "hidden"} id="link3">
            {Object.keys(mcaDataIndex).length > 0 ? (
              <IndexOfCharges data={mcaDataIndex} />
            ) : mcaDataIndex === 440 ? (
              <MCASiteMessage subtitle="No records found!" title="INDEX OF CHARGES" />
            ) : mcaDataIndex === 403 ? (
              <MCASiteMessage subtitle="The MCA site is not responding, please try again later" title="INDEX OF CHARGES" />
            ) : (
              renderPlaceHolder('ioc', companyProfileId, primaryMasterId)
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormMCSite;
