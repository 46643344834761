import React, { useContext, useEffect, useRef, useState } from 'react';
import { getSearchResultIdsAll, performSearch, TsSearchSchema } from '../Components/useGlobalSearch';
import { useLocation, useNavigate, useParams } from 'react-router';
import { SearchResult } from '../Components//SearchPopup/NewSearchColumn';
import { SearchMenuContext } from '../Contexts/SearchMenuContext';
import { TableDataContext } from '../Contexts/TableDataContext';
import { routeNames } from '../routeSegments';
import useModule from '../hooks/useModule';
import { HeaderMenuContext } from '../Contexts/HeaderMenuContext';
import { PeFilterContext } from '../Contexts/PeFilterContext';
import moment from "moment";
import SliderLabels from './SliderLabels';

const collectionNameMapHuman = {
  pe_company: 'PE-VC Backed Company',
  re_company: 'PE-RE Backed Company',
  cfs_company: 'Private Company Financials (CFS)',
  pe_investor: 'PE-VC Investors',
  angel_investor: 'Angel Investor',
  pe_fund: 'PE-VC Fund Raising',
  re_investor: 'PE-RE Investors',
  pe_acquirer: 'Acquirer (M&A)',
  pe_advisor: 'To PE-VC / M&A Transactions',
  re_advisor: 'To  PE-RE Transactions',
  pe_investment: 'PE-VC Investments',
  re_investment: 'PE-RE Investments',
  angel: 'Angel Investments',
  incubation: 'Incubation',
  merger_acquisition: 'M&A Deals',
  pe_exit: 'PE-VC Exits',
  re_exit: 'PE-RE Exits',
  pe_ipo: 'PE-VC Backed IPOs',
  re_ipo: 'PE-RE Backed IPOs',
  re_funds: 'PE-RE Fund Raising'
}

const NewSearchResult = ({menu}) => {
    const { setGlobalSearchResult } = useContext(TableDataContext);
    const [searchResult, setSearchResult] = useState([]);
    const [selectedResult, setSelectedResult] = useState();
    const [selectedStaticHeaders, setSelectedStaticHeaders] = useState("Deals");
    const [selected, setSelected] = useState("pe_investment");
    const [check,setCheck] = useState(true)
    const location = useLocation();
    const [isloading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const { staticHeaders, peStaticHeaders, vcStaticHeaders, reStaticHeaders, maStaticHeaders, cfsStaticHeaders } = useContext(SearchMenuContext);
    const navigate = useNavigate();
    const { isVC } = useModule();
    const { type, input } = useParams();
    const searchType = parseInt(type);
    const [searchInput, setSearchInput] = useState(input)
    const { setSearchText } = useContext(HeaderMenuContext);
    const { setMonthPickerValue, setDealsView } = useContext(PeFilterContext);
    const [gsExpand , setgsExpand] = useState(false)

    const viewMoreHandler = async (page, collection_name) => {
      console.log(page, "from child")
      setPage(page)
      const schema = TsSearchSchema.filter((schema) => schema.collection === collection_name)
        .map((queryConf) => ({ 
          q: searchInput, page: page,
          per_page: 5,
          snippet_threshold: 20,
          num_typos:  searchType === 0 ? 2 : 0,
          typo_tokens_threshold: searchType === 0 ? 10 : 0,
          split_join_tokens: searchType === 0 ? "fallback" : "off",
          drop_tokens_threshold: searchType === 0 ? 1 : 0,
          ...queryConf 
        }));

      const response = await performSearch(schema);
      
      const updatedResults = searchResult.reduce((acc, cur) => {
        if(cur.request_params.collection_name === response.results[0]?.request_params.collection_name){
          response.results[0].grouped_hits ? cur.grouped_hits.push(...response.results[0].grouped_hits) : cur.hits.push(...response.results[0].hits)
          cur.page = response.results[0].page;
          setCheck(!check)

        }
        acc.push(cur);
        return acc
      }, [])

      setSearchResult(updatedResults);
  };



  const fetchResults = async (searchInput) => {
    if (searchInput.length < 3) { return null }

    const searchParameters = TsSearchSchema.map((queryConf) => ({
      q: searchType === 1 ? `"${searchInput}"` : searchInput,
      page: 1,
      per_page: 5,
      snippet_threshold: 20,
      num_typos:  searchType === 0 ? 2 : 0,
      typo_tokens_threshold: searchType === 0 ? 10 : 0,
      split_join_tokens: searchType === 0 ? "fallback" : "off",
      drop_tokens_threshold: searchType === 0 ? 1 : 0,
      ...queryConf,
    }));


    const searchParameters1 = TsSearchSchema.map((queryConf) => ({
      q: searchInput,
      page: 1,
      per_page: 10,
      num_typos:  2,
      typo_tokens_threshold: 10,
      ...queryConf,
    }));

    try {
        console.log(searchType, "type")
      const results = await performSearch(searchParameters);

      // Check if all found values are 0
      const allResultsEmpty = results.results.every(result => result.found === 0);

      if (allResultsEmpty) {
        setSearchResult([])
        console.log("No results found for the query.");
        setIsLoading(false);
        return;
      } else {
          setIsLoading(false)
          setSearchResult(results.results);
          // setSelectedResult(results.results?.filter(result => result?.request_params?.collection_name === "pe_investment"));
      } 
    } catch (error) {
      console.error("An error occurred while fetching results:", error);
    }
  };

  useEffect(() => { fetchResults(searchInput) }, [searchInput, searchType]);

  useEffect(() => {

    if (["pe", "vc", "re", "ma"].includes(menu)) {
      setSelectedStaticHeaders("Deals");
  
      if (menu === "pe" || menu === "vc") {
        const accessor = Object.values(peStaticHeaders)[0][0]?.accessor;
        setSelected(accessor);

      } else if (menu === "re") {
        const accessor = Object.values(reStaticHeaders)[0][0]?.accessor;
        setSelected(accessor);


      } else if (menu === "ma") {
        const accessor = Object.values(maStaticHeaders)[0][0]?.accessor;
        setSelected(accessor);


      }
      } else if (menu === "cfs") {
        setSelectedStaticHeaders("Company Financials");
        const accessor = Object.values(cfsStaticHeaders)[0][0]?.accessor;
        setSelected(accessor);


      }
  }, [])
  

    const onOptionClick = () => {
      setSearchResult([]);
      setPage(1);
    }

    useEffect(() => {
      onOptionClick();
    }, [location.pathname]);

    const columnsLinks = [
      // {accessor: "pe_company", link: (!isVC) ? routeNames.pe_companies_pe_vc_investments  : routeNames.vc_companies_vc_investments, dealsView: false},
      // {accessor:"re_company", link: routeNames.re_companies_re_investments},
      {accessor:"cfs_company", link: routeNames.cfs},

      {accessor: "pe_investor", link: (!isVC) ? routeNames.pe_investors_pe_vc_investments : routeNames.vc_investors_vc_investments},
      {accessor: "angel_investor", link: (!isVC) ? routeNames.pe_investors_angel_investments : routeNames.vc_investors_angel_investments},
      {accessor: "pe_fund", link: routeNames.pe_investors_pe_vc_funds},
      {accessor:"re_investor", link: routeNames.re_investors_re_investments},
      {accessor:"pe_advisor", link: routeNames.pe_advisors_pe_vc_advisors},
      {accessor:"re_advisor", link: routeNames.re_advisors_re_investments},

      {accessor:"pe_acquirer", link: routeNames.ma_acquires},
      {accessor: "pe_investment", link: (!isVC) ? routeNames.pe_companies_pe_vc_investments : routeNames.vc_companies_vc_investments, dealsView: true},
      {accessor: "pe_exit", link: (!isVC) ? routeNames.pe_companies_exists_pe_vc_investments : routeNames.vc_companies_exists_vc_investments, dealsView: true},
      {accessor: "pe_ipo", link: (!isVC) ? routeNames.pe_companies_backed_ipos_pe_vc_investments : routeNames.vc_companies_backed_ipos_vc_investments, dealsView: true},
      {accessor: "angel", link: (!isVC) ? routeNames.pe_companies_angel_investments : routeNames.vc_companies_angel_investments, dealsView: true},
      {accessor: "incubation", link: (!isVC) ? routeNames.pe_companies_incubation : routeNames.vc_companies_incubation, dealsView: true},
      {accessor:"merger_acquisition", link: routeNames.ma_companies},
      {accessor:"re_investment", link: routeNames.re_companies_re_investments},
      {accessor:"re_exit", link: routeNames.re_companies_re_exits},
      {accessor:"re_ipo", link: routeNames.re_companies_re_ipo},
      
    ];
    
    const foundMap = searchResult.reduce((acc, result) => {
      if (result.request_params) {
        const accessor = result.request_params.collection_name;
        acc[accessor] = result.found;
      }
      return acc;
    }, {});

    const resultsContainerRef = useRef(null);

    const scrollToTop = () => {
        if (resultsContainerRef.current) {
            resultsContainerRef.current.scrollTop = 0;
        }
    };

    const handleHeaderClick = (headerKey, accessor) => {
        setSelectedStaticHeaders(headerKey);
        setSelected(accessor);
        
        setTimeout(() => {
            if (headerKey === "More Results From Other Database") {
                scrollToTop();
            } else {
                const element = document.getElementById(accessor);
                if (element) {
                    element.scrollIntoView({ behavior: "smooth" });
                }
            }
        }, 100);
    };

    const RenderHeaders = ({headers}) => {
        return (
            <div className='md:pb-2 sticky'>
                <div className='pl-4 mb-2 flex flex-row items-center gap-6 bg-white'>
                    {Object.keys(headers).map((headerKey, i) => {
                        const totalFound = headers[headerKey]?.reduce((sum, h) => sum + (foundMap[h.accessor] || 0), 0);
                        if (totalFound === 0) {
                            return null;
                        }

                        return (
                            <div 
                                key={i} 
                                className={`py-1 px-4 text-black text-[15px] ${
                                    headerKey === selectedStaticHeaders ? 
                                    "border-b-[3px] border-b-[#F1CA75] font-semibold" : 
                                    "cursor-pointer"
                                }`} 
                                onClick={() => handleHeaderClick(headerKey, headers[headerKey][0].accessor)}
                            >
                                {headerKey}
                                <span className="text-xs ml-1">({addCommasToNumber(totalFound)})</span>
                            </div>
                        );
                    })}
                </div>
                <div className={`flex flex-row-reverse items-start  border-b ${gsExpand ? 'px-2 ' : 'p-2'}`}>
                    <RenderLabels header={headers[selectedStaticHeaders]} />
                </div>
            </div>
        );
    };


    const RenderLabels = ({ header }) => {
      if(header?.filter(h => foundMap[h.accessor] > 0).length > 7){
        setgsExpand(true)
      }else{
          setgsExpand(false)
      }
      return (
        <div className={`w-full px-2 rounded ${gsExpand ? 'my-2' : ''}`}>
          <SliderLabels 
            header={header}
            selected={selected}
            setSelected={setSelected}
            foundMap={foundMap}
            addCommasToNumber={addCommasToNumber}
            gsExpand={gsExpand}
          />
        </div>
      );
    };

    const viewAllHandler = async (collection, labelState) => {

      const searchResultsId = await getSearchResultIdsAll(searchInput, searchResult, searchType);
      console.log(searchResultsId, "==.");

      setGlobalSearchResult(searchResultsId);
      const matchedLink = columnsLinks.find(link => link.accessor === collection);

      if (matchedLink) {
        setDealsView(matchedLink?.dealsView);
        navigate(matchedLink.link, { state: labelState });
        setSearchText("");
        setMonthPickerValue(labelState.globalMonthRange)
      } else {
        console.warn('No matching link found for the provided collection:', collection);
      }
    };

    const addCommasToNumber = (number) => {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };
  
    return (
      <>
        <div className="ml-[75px] fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity h-screen">
          <div className="w-full mt-[3rem] overflow-hidden">
            <div className="w-full h-full max-h-[98vh] border bg-[#ebebeb] relative flex flex-col">
              {!isloading ? (
                menu === "pe" ? 
                  <RenderHeaders headers={peStaticHeaders} />
                  : menu === "vc" ?
                  <RenderHeaders headers={vcStaticHeaders} />
                  : menu === "ma" ?
                    <RenderHeaders headers={maStaticHeaders} />
                  : menu === "re" ?
                    <RenderHeaders headers={reStaticHeaders} />
                  : menu === "cfs" ?
                    <RenderHeaders headers={cfsStaticHeaders} />
                  :
                  <RenderHeaders headers={staticHeaders} />
              ) : null}
              <div ref={resultsContainerRef} className="flex-1 overflow-y-auto overflow-x-auto px-2 scrollbar-remove no-scrollbar w-full mx-auto">
                {isloading ? (
                  <h1 className="mx-auto font-semibold text-center">Loading...</h1>
                ) : (!isloading && searchResult.length === 0) ? (
                  <div className="h-40 flex flex-col justify-center items-center">
                    <h1 className="mx-auto font-semibold text-center">No Results Found</h1>
                    {searchType !== 0 && (
                      <h2 className="mx-auto font-semibold text-center">
                        You may change the search to <span className="font-semibold">broad match </span> and check again.
                      </h2>
                    )}
                  </div>
                ) : (
                  <div className="mx-auto w-full gap-y-6 sm:gap-6 md:pb-2 max-h-full">
                    {(() => {
                      let orderedResults = [...searchResult];

                      if (menu === "re") {
                        orderedResults = [...(selectedStaticHeaders !== "More Results From Other Database" ? [searchResult[11], searchResult[12], searchResult[13],searchResult[14],searchResult[15]] : [...searchResult.filter((_, idx) =>  idx !== 11 && idx !== 12 && idx !== 13 && idx !== 14 && idx !== 15 )]),];
                      } else if (menu === "ma") {
                        orderedResults = [...(selectedStaticHeaders !== "More Results From Other Database" ? [searchResult[9], searchResult[10]] : [...searchResult.filter((_, idx) => idx !== 9 && idx !== 10)]),];
                      } else if (menu === "cfs") {
                        orderedResults = [...(selectedStaticHeaders !== "More Results From Other Database" ? [searchResult[16]] : [...searchResult.filter((_, idx) => idx !== 16 )]),];
                      } else if (menu === "pe" || menu === "vc") {
                        orderedResults = [...(selectedStaticHeaders !== "More Results From Other Database" ? [searchResult[0], searchResult[1], searchResult[2],searchResult[3],searchResult[4],searchResult[5],searchResult[6],searchResult[7],searchResult[8]] : [...searchResult.filter((_, idx) =>  idx !== 0 && idx !== 1 && idx !== 2 && idx !== 3  && idx !== 4 && idx !== 5 && idx !== 6 && idx !== 7 && idx !== 8)]),];
                      }

                      return orderedResults.map((result, index) => (
                        result.found !== 0 ? (
                          <div key={index}  className={`border-t rounded py-2 bg-white ${gsExpand ? 'mx-6 mb-6' : 'm-6 md:mt-2 '}`} id={result?.request_params?.collection_name}>
                            <h1 className="md:text-base font-semibold md:pl-4 mb-2">
                              {collectionNameMapHuman[result?.request_params?.collection_name]}
                            </h1>
                            <SearchResult result={result} searchResult={searchResult} check={check} />
                            <div className='flex gap-2 justify-center items-center'>
                              {result?.found >= 5 && result?.found !== result?.hits?.length && result?.hits?.length !== 0 && (
                                <h3
                                  className="ml-8 mt-2 text-right cursor-pointer bg-[#BC8B20] text-white rounded px-4 py-1"
                                  onClick={() => viewMoreHandler(page + 1, result?.request_params?.collection_name)}
                                >
                                  View more
                                </h3>
                              )}
                              <h3
                                className="ml-8 mt-2 text-right cursor-pointer bg-[#BC8B20] text-white rounded px-4 py-1"
                                onClick={() => viewAllHandler(result?.request_params?.collection_name, {
                                  globalSearch: [{ id: 0, name: `searched for ${searchInput}`, searchTerm: searchInput }],
                                  globalMonthRange: ["1998-01-01", moment().startOf('second').format("YYYY-MM-DD")]
                                })}
                              >
                                View all
                              </h3>
                            </div>
                          </div>
                        ) : null
                      ));
                    })()}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
    </>
    
    )
}

export default NewSearchResult;