import React from "react";
import Loader from "../Loader";
import OverflowToolTip from "../shared/OverflowToolTip";


/**
 * The AcquirerOverview component displays the profile information of an acquirer, including their
 * website, city, country, and industry.
 * @returns The AcquirerOverview component is being returned.
 */
const AcquirerOverview = ({ profile, isLoading }) => {
  return (
    <div>
      <div className="space-y-6 px-4 bg-[#EDEDED]">
        <div className="border ">
          <div
            className="lg:mt-4 lg:bg-white  rounded-[6px]"
            x-show="tab === 1"
          >
            {isLoading
              ? <div className="w-full h-40 flex items-center justify-center">
                <Loader />
              </div>
              : <div className="w-full lg:grid lg:grid-cols-6  shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
                <div className=" mt-3 lg:mt-0 rounded-lg lg:rounded lg:col-span-6 lg:border-r bg-white ">
                  <div className="px-5 py-4 ">
                    <p className="  text-[14pt] leading-[19pt] lg:text-[17px] text-[#333333] font-semibold lg:leading-[22px]">
                      Profile
                    </p>
                    <div className="overflow-x-auto scrollbar mt-1">
                      <div className=" w-full bg-[#FFFFFF] font-sans_book_body ">
                        <div className="grid grid-cols-4 space-x-7 pt-2">
                          <div className=" lg:leading-[21px]">
                            <p
                              className="text-[11pt]  leading-[18pt]  lg:text-[14px]  text-[#333333] whitespace-nowrap cursor-auto"
                              readOnly
                            >
                              Website
                            </p>
                            <div className="lg:leading-[21px]">
                              {profile?.acquirer_website
                                ? <a href={profile?.acquirer_website} target="_blank" className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap font-semibold " rel="noreferrer">
                                    <OverflowToolTip component={profile?.acquirer_website ? profile?.acquirer_website : "--"} />
                                  </a>
                                : "--"
                              }

                            </div>

                          </div>
                          <div className=" lg:leading-[21px]">
                            <p
                              className="text-[11pt]  leading-[18pt]  lg:text-[14px]  text-[#333333] whitespace-nowrap cursor-auto"
                              readOnly
                            >
                              City
                            </p>
                            <div className="  lg:leading-[21px]">

                              <p className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto font-semibold ">
                                {profile?.acquirer_city ? profile?.acquirer_city : "--"}
                              </p>

                            </div>
                          </div>
                          <div className=" lg:leading-[21px] pl-12 md:pl-0 ">
                            <p
                              className="text-[11pt]  leading-[18pt]  lg:text-[14px]  text-[#333333] whitespace-nowrap cursor-auto"
                              readOnly
                            >
                              Country
                            </p>
                            <div className=" lg:leading-[21px]">

                              <p className=" text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto font-semibold ">
                                {profile?.acquirer_country ? profile?.acquirer_country : "--"}
                              </p>

                            </div>
                          </div>
                          <div className=" lg:leading-[21px] pl-12 md:pl-0">
                            <p
                              className="text-[11pt]  leading-[18pt]  lg:text-[14px]  text-[#333333] whitespace-nowrap cursor-auto"
                              readOnly
                            >
                              Industry (Existing Targets)
                            </p>
                            <div className=" lg:leading-[21px]">

                              <p className=" text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto font-semibold ">
                                {profile?.tagrget_industry ? profile?.tagrget_industry : "--"}
                              </p>

                            </div>
                          </div>
                        </div>


                      </div>
                    </div>
                  </div>
                </div>
              </div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AcquirerOverview;