import React, { useState } from 'react'
import { AiFillCloseCircle } from 'react-icons/ai'

function LogoutPopup({ onClick, browserList, show }) {

    const [selectedDevice, setSelectedDevice] = useState();

    /**
     * The function `onDeviceChange` updates the selected device based on the value of the event
     * target.
     * @param event - The event parameter is an object that represents the event that occurred. In this
     * case, it is likely an event object related to a device change event, such as a change in the
     * selected device in a dropdown menu or a change in the connected devices.
     */
    const onDeviceChange = (event) => {
        setSelectedDevice(event.target.value);
    }

    /**
     * The `onSubmit` function checks if a `selectedDevice` is not null and calls the `onClick`
     * function with the `selectedDevice` as an argument.
     * @param e - The parameter "e" is an event object that is passed to the onSubmit function. It
     * typically represents an event that has occurred, such as a button click or form submission.
     */
    const onSubmit = (e) => {
        if (selectedDevice != null) {
            onClick(selectedDevice)
        }
    }

    return (
        <div className="relative z-20 font-sans_book_body" aria-labelledby="modal-title" role="dialog" aria-modal="true">

            <div onClick={() => onClick(null)} className="h-screen w-screen fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

            <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">

                    <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all mx-4  sm:my-8 sm:w-full sm:max-w-lg">
                        <div className='flex justify-end mt-2 mr-2'>
                            <AiFillCloseCircle className='cursor-pointer ' size={24} color='black' onClick={() => onClick(null)} />
                        </div>
                        <div className="bg-white px-4  pb-4  sm:pb-4">
                            <div className="sm:flex sm:items-start">
                                {/* {icon || <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                        <svg className="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 10.5v3.75m-9.303 3.376C1.83 19.126 2.914 21 4.645 21h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 4.88c-.866-1.501-3.032-1.501-3.898 0L2.697 17.626zM12 17.25h.007v.008H12v-.008z" />
                                        </svg>
                                    </div>} */}
                                <div className=" text-center sm:mt-0 sm:ml-4 sm:text-left">
                                    <h3 className="text-[14px] font-sans_medium_body font-medium leading-6 text-center text-gray-900" id="modal-title">
                                        Your Login is already authorized in the following 2 Browsers*.
                                        Please select one of them to deauthorize
                                    </h3>
                                    <div className="mt-2 w-full py-4 flex justify-center">
                                        <div className='flex flex-col'>
                                            {
                                                browserList?.map((device,i) => {
                                                    return <div className='flex items-center p-1'>
                                                        <input
                                                            type="radio"
                                                            className='mr-1 cursor-pointer'
                                                            name='device'
                                                            onChange={onDeviceChange}
                                                            id={device.id}
                                                            value={device.id}
                                                            checked={selectedDevice == device.id} />
                                                        <label for={device.id} className='text-[12px]  font-sans_medium_body cursor-pointer'>{device?.device_details}</label>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-gray-50 py-3 flex px-6">
                            <p className='text-[12px]  font-sans_medium_body  text-gray-700'>* Note: Updated versions of your browser get counted as a "new" browser. </p>
                            <button
                                onClick={onSubmit}
                                type="button"
                                className="whitespace-nowrap  mt-3 inline-flex w-full justify-center font-sans_bold_body rounded-md text-white px-4 py-2 font-medium shadow-sm   focus:ring-offset-2 sm:mt-0 ml-3 sm:w-auto text-sm bg-[#BC8B20]  focus:outline-none hover:bg-[#a37517]">
                                Submit
                            </button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LogoutPopup