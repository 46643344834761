import React from 'react'
import Card from '../../Components/RE/Detailpage/Card'
import CompanyDetail from '../../Components/RE/Detailpage/CompanyDetail'
import RefineSearch from '../../Components/RefineSearchComponent'
import ReLayout from '../../Components/ReLayout'
import CurrencyContextProvider from '../../Contexts/CurrencyContext';
import ReInvestmentRefineSearch from '../../Components/RefineSearch/ReInvestmentRefineSearch'
import ReExitRefineSearch from '../../Components/RefineSearch/ReExitRefineSearch'
import ReBackedIpoRefineSearch from '../../Components/RefineSearch/ReBackedIpoRefineSearch'
import ReOtermaRefineSearch from '../../Components/RefineSearch/ReOthermaRefineSearch'
import Constants from '../../Constants'
import { routeNames } from '../../../src/routeSegments'
import { useSearchParams } from 'react-router-dom'

/**
 * The DetailPage function is a React component that renders a page with a primary column and a
 * secondary column, and displays different content in the secondary column based on the value of the
 * "section" prop.
 * @returns The DetailPage component is returning a JSX structure that includes various components and
 * elements. The main structure includes a Card component, a primary column section, and a secondary
 * column aside. The primary column section contains a CompanyDetail component and potentially other
 * content. The secondary column aside contains a RefineSearch component, which renders different
 * refine search components based on the value of the "section" prop.
 */
function DetailPage({menu}) {


    const [URLSearchParams, SetURLSearchParams] = useSearchParams({ section: Constants.primaryMasterSubIds.re_investments_id })

   const section = parseInt(URLSearchParams.get('section'))

   console.log(section,"sections");

    return ( 
        <div>
            <CurrencyContextProvider>
                <ReLayout>
                    <div className="flex h-screen flex-col">
                        <Card />
                        <div className="flex min-h-0 flex-1 overflow-hidden">
                            {/* Primary column */}
                            <section
                                aria-labelledby="primary-heading"
                                className="flex h-full min-w-0 flex-1 flex-col overflow-y-auto lg:order-last">
                                <CompanyDetail menu={menu}  section={section} />

                                {/* <Outlet /> */}
                                {/* Your content */}
                            </section>
                            {/* Secondary column (hidden on smaller screens) */}
                            <aside className="hidden lg:order-first lg:block lg:flex-shrink-0">
                                <div className="relative flex h-full  flex-col border-r border-gray-200 bg-[#FAF5EA]">
                                    {/* Your content */}
                                    <RefineSearch >
                                        {
                                            section === Constants.primaryMasterSubIds.re_investments_id
                                                ? <ReInvestmentRefineSearch customPathname={routeNames.re_companies_re_investments} />
                                                : section === Constants.primaryMasterSubIds.re_exits_id
                                                    ? <ReExitRefineSearch customPathname={routeNames.re_companies_re_exits} />

                                                    : section === Constants.primaryMasterSubIds.re_backed_ipos_id
                                                        ? <ReBackedIpoRefineSearch customPathname={routeNames.re_companies_re_ipo} />

                                                        : section === Constants.primaryMasterSubIds.re_others_ma_id
                                                            ? <ReOtermaRefineSearch customPathname={routeNames.re_companies_other_ma} />

                                                            : <ReInvestmentRefineSearch customPathname={routeNames.re_companies_re_investments} />
                                        }
                                    </RefineSearch>
                                </div>
                            </aside>
                        </div>
                        <br />

                    </div>
                </ReLayout>
            </CurrencyContextProvider>
        </div>
    )
}

export default DetailPage
