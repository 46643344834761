import { React, useState, useMemo, useContext } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom';
import moment from "moment";
import { routeNames } from '../../routeSegments';
import { FundingContext } from '../../Contexts/FundingDataProvider';
import OverflowToolTip from '../shared/OverflowToolTip';
import Constants from '../../Constants';
import Paginate from '../Paginate';
import ConfirmPopup from '../../Utils/ConfirmPopup';
import { useSortBy, useTable, usePagination } from 'react-table';
import SortAscendingIcon from 'mdi-react/SortAscendingIcon';
import SortDescendingIcon from 'mdi-react/SortDescendingIcon';
import SortIcon from 'mdi-react/SortIcon';
import LockIcon from 'mdi-react/LockIcon';
import useModule from '../../hooks/useModule';
import { TableDataContext } from '../../Contexts/TableDataContext';
import { UserContext } from '../../Contexts/UserContext';


/* The above code is a React component written in JavaScript. It is a table component that displays
funding data for a company. The component receives props such as `menu` and `data`. */
const FundingTableExit = ({ menu, data }) => {
    const { setexistData } = useContext(FundingContext);
    const { isMA } = useModule();
    const location = useLocation();
    const [alert, setalert] = useState({ message: "", show: false });
    const { companyId } = useParams();
    const { forExMonthlyRate } = useContext(TableDataContext);
    const { isDirectoryUser } = useContext(UserContext);

    /**
     * The function `onConfirmation` sets the state of `alert` to hide the alert message.
     */
    const onConfirmation = (flag) => {
        setalert({ show: false, message: "" })
    }

    /**
     * The function `onBlurColumnClick` sets the state of an alert to show a message with a close
     * button.
     */
    const onBlurColumnClick = (cell) => {
   
        setalert({ show: true, message: Constants.peSubscriptionMsg, isAlertOnly: true, closeBtnName: "OK" })
    }

    /* The above code is defining a custom sorting function using the `useMemo` hook in React. */
    const customStringSortMemo = useMemo(() => {
        return (rowA, rowB, columnId, desc) => {
            const defaultVal = desc ? '--' : 'A';
            return (rowA?.values[columnId]?.type ?? defaultVal).localeCompare(rowB?.values[columnId]?.type ?? defaultVal);
        }
    }, []);
    /* The above code is defining a custom sorting function called `dealDateSort` using the `useMemo`
    hook in React. This sorting function is used to compare two rows (`rowA` and `rowB`) based on a
    specific column (`columnId`) containing date values. */
    const dealDateSort = useMemo(() => {
        return (rowA, rowB, columnId, desc) => {
            return moment.utc(rowA?.values[columnId].date).diff(moment.utc(rowB?.values[columnId].date))
        }
    }, [])
    /* The above code is defining a custom sorting function called `investorSort` using the `useMemo`
    hook in React. This sorting function takes in two rows (`rowA` and `rowB`), a column ID, and a
    boolean flag `desc` indicating whether the sorting should be in descending order. */
    const investorSort = useMemo(() => {
        return (rowA, rowB, columnId, desc) => {
            const defaultVal = desc ? '--' : 'A';
            const valueA = rowA?.values[columnId].map(data => data.investor_name).join("")
            const valueB = rowB?.values[columnId].map(data => data.investor_name).join("")

            return (valueA ?? defaultVal).localeCompare(valueB ?? defaultVal);
        }
    }, [])

    
    const getForExRate = (dealDate) => {
        const dealMoment = moment(dealDate, "MM-YYYY");
        let rate = forExMonthlyRate[0]?.value; // Default to the earliest rate if no match found
        forExMonthlyRate?.forEach((forex) => {
            const forexMoment = moment(forex.date, "YYYY-MM-DD").format("MM-YYYY");
            if (forexMoment === dealMoment.format("MM-YYYY")) {
                rate = forex.value;
            }
        });
        return parseFloat(rate);
    };

    // Function to calculate post-money valuation in USD
    const calculatePostMoneyUsd = (companyValuationPostMoney, date) => {
        const forex = getForExRate(date);
        return (companyValuationPostMoney / forex).toFixed(2); // Converting and formatting to 2 decimal places
    };

    /* The above code is defining an array of columns for a table in a React component. The columns
    have various properties such as Header (column name), accessor (data key), className (CSS
    classes), Cell (rendering component for cell values), sortType (custom sorting function), and
    more. The code also includes conditional rendering and logic based on the values of certain
    variables and localStorage data. */
    const columns = useMemo(() => {
        let blurColumns = true;

        const allowedModulesStr = localStorage.getItem(Constants.localStorageKeys.allowedModules);
        const allowedModulesList = JSON.parse(allowedModulesStr) ?? [];
        if (allowedModulesList.includes("pe")) {
            blurColumns = false;
        } else {
            blurColumns = true;
        }

        const addCommasToNumber = (number) => {
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          };

        return [
            {
                Header: "Deal Type",
                accessor: "dealType",
                className: "clickable w-[15%] text-left sticky left-0  lg:relative w-[3rem] bg-white",
                headerClassName: "text-left sticky left-0  lg:relative w-[3rem] bg-[#EDEDED] ",
               
                Cell: ({ value }) => {
                    return <><OverflowToolTip className="w-[8rem] lg:w-auto" key={value} component={<Link
                        onClick={(e) => {
                            if (menu == "cfs") {
                                if (!allowedModulesList.includes("pe") && !allowedModulesList.includes("vc")) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onBlurColumnClick();
                                }
                            }
                        }}
                        rel="noopener noreferrer"
                        state={{ prevPath: location.state?.prevPath ?? "" }} to={
                            menu == "cfs"
                                ? allowedModulesList.includes("pe")
                                    ? routeNames.peCompanyExitsDealById(companyId, value.id)
                                    : routeNames.vcCompanyExitsDealById(companyId, value.id)
                                : menu == "vc"
                                    ? routeNames.vcCompanyExitsDealById(companyId, value.id)
                                    : menu== "ma"
                                        ? routeNames.peCompanyExitsDealById(companyId, value.id)
                                        : routeNames.peCompanyExitsDealById(companyId, value.id)}>
                        {value.type ?? "--"}
                    </Link>} />
                    </>
                },
                sortType: customStringSortMemo,
            },
            {
                id: "dealDate",
                Header: "Deal Date",
                accessor: "dealDate",
                className: "w-[15%]",
                Cell: ({ value }) => {
                    const date = value?.date ? moment(value.date).format('MMM YYYY') : ""; // Check if date is not null

                    return <Link
                    className='hover:text-[#9d6c01]'
                        onClick={(e) => {
                            if (menu == "cfs") {
                                if (!allowedModulesList.includes("pe") && !allowedModulesList.includes("vc")) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onBlurColumnClick();
                                }
                            }
                        }}
                        state={{ prevPath: location.state?.prevPath ?? "" }}
                        to={
                            menu == "cfs"
                                ? allowedModulesList.includes("pe")
                                    ? routeNames.peCompanyExitsDealById(companyId, value.id)
                                    : routeNames.vcCompanyExitsDealById(companyId, value.id)
                                : menu == "vc"
                                    ? routeNames.vcCompanyExitsDealById(companyId, value.id)
                                    : menu == "ma"
                                        ? routeNames.peCompanyExitsDealById(companyId, value.id)
                                        : routeNames.peCompanyExitsDealById(companyId, value.id)}>
                        {date}
                    </Link>
                },
                sortType: dealDateSort
            },
            {
                Header: "Investors",
                accessor: "investors",
                className: "clickable w-[20%]",
                Cell: (cell) => {
                    if (cell.value.length > 0)
                        return <OverflowToolTip className="w-[20rem] lg:w-auto max-w-[15rem] 2xl:max-w-[25rem] lg:overflow-x-clip lg:overflow-ellipsis" component={
                            cell.value.map((inv, index) => (<Link
                                onClick={(e) => {
                                    if (menu == "cfs" || menu == "ma") {
                                        if (!allowedModulesList.includes("pe") && !allowedModulesList.includes("vc")) {
                                            e.preventDefault();
                                            e.stopPropagation(); 
                                            onBlurColumnClick();
                                        }
                                    } 
                                }}
                                rel="noopener noreferrer"
                                className={`${inv.id === 9 ? "cursor-default" : 'hover:text-[#BC8B20]'}` }
                                state={{ cameFrom: location.pathname }} to={menu == "cfs" || menu == "ma"
                                ? allowedModulesList.includes("pe")
                                    ? inv.id === 9 ? null : routeNames.peInvestorById(inv.id)
                                    : inv.id === 9 ? null : routeNames.vcInvestorById(inv.id)
                                : menu == "vc"
                                    ? inv.id === 9 ? null : routeNames.vcInvestorById(inv.id)
                                    : inv.id === 9 ? null : routeNames.peInvestorById(inv.id)}>
                            {`${inv.name}${index == cell.value?.length - 1 ? "" : ", "}`}
                        </Link>))

                        } />
                },
                sortType: investorSort
            },
            {
                Header: "Amount Realized (US$ M)",
                accessor: "amountRealizedInUS",
                className: "text-right w-[10%]",
                headerspanClassName: "ml-auto",
                Cell: (cell) => {
                    return addCommasToNumber(cell?.value)
                },
            },
            {
                Header: "Exit Status",
                accessor: "existStatus",
                className: " text-left w-[10%]",
            },

            ...!isMA ? [{
                Header: "Post Money Valuation (INR Cr)/($M)",
                accessor: "postMoneyValuation",
                className: `text-right  w-[10%] `,

                headerspanClassName: "ml-auto",
                Cell: (cell) => {
                    return addCommasToNumber(cell?.value)
                },
                blurColumns: blurColumns,
                disableSortBy: blurColumns,

            }] : [],
            // ...!isMA ? [{
            //     Header: "($M)",
            //     accessor: "postmoneyValuation",
            //     className: `text-right  w-[5%]`,
            //     headerspanClassName: "ml-auto",
            //     // blurColumns: blurColumns,
            //     // disableSortBy: blurColumns,
            // }] : [],
            {
                Header: "Revenue Multiple",
                accessor: "returnMultiple",
                headerspanClassName: "ml-auto",
                className: `text-right`,
                Cell: (cell) => {
                    return addCommasToNumber(cell?.value)
                },
                blurColumns: blurColumns,
                // disableSortBy: blurColumns,
                // Cell: (cell) => {
                //     if (cell.value?.length !== 0) {
                //         return cell.value.map((inv, index) => (`${inv.MultipleReturn}`))
                //     } else {
                //         return "--"
                //     }
                // }
            },
        ];
    }, [companyId, menu])

    /* The above code is a JavaScript React code snippet. It defines a function that uses the `useMemo`
    hook to memoize the result of a data transformation. */
    const rowData = useMemo(() => {
        let rows = [];
    

        data?.forEach(exits => {
            const dealDate = exits?.date ? moment(exits.date).format("MM-YYYY") : null;
            const postMoneyValuationINR = parseFloat(exits?.valuation) || 0;
            const postMoneyValuationUSD = postMoneyValuationINR ? calculatePostMoneyUsd(postMoneyValuationINR, dealDate) : '';

            const sortedInvestors = exits?.exit_investors?.sort((a, b) => {
                if (a.name === 'Others') return 1; // 'Others' should come last
                if (b.name === 'Others') return -1; // 'Others' should come last
                return a.name.localeCompare(b.name); // Assuming a.name and b.name exist
            }) ?? [];

            rows.push({
                dealType: { type: exits?.deal_type, id: exits.id },
                dealDate: { date: exits.date, id: exits.id },
                investors: sortedInvestors,
                amountRealizedInUS:exits.amount == 0 || exits.is_hide_amount ? "" : exits.amount,
                existStatus: exits.exit_status,
                postMoneyValuation: isDirectoryUser ? Constants.restrictedPlaceholder : exits.valuation === "0.00" ? "" : `${exits.valuation} (${postMoneyValuationUSD})`,
                returnMultiple: isDirectoryUser ? Constants.restrictedPlaceholder : exits?.revenue_multiple == 0 ? "" : exits?.revenue_multiple,
            })
        });

        setexistData(rows.length == 0 ? null : rows);

        return rows;

    }, [data])
    /* The above code is using the `useMemo` hook in React to create an initial sort configuration. It
    is creating an array with a single object that has two properties: `id` and `desc`. The `id`
    property is set to 'dealDate' and the `desc` property is set to `true`. This configuration is
    used for sorting data, with the `dealDate` property being sorted in descending order. The empty
    array `[]` passed as the second argument to `useMemo` ensures that the initial sort
    configuration is only created once, when the component is first rendered. */
    const initialSort = useMemo(() => [
        {
            id: 'dealDate',
            desc: true
        }
    ], []);

    /* The above code is using the `useTable` hook from the React-Table library to create a table
    instance. */
    const tableInstance = useTable({
        columns,
        data: rowData,
        disableSortRemove: true,
        initialState: {
            pageSize: Constants.noOfRowPerPage,
            pageIndex: 0,
            sortBy: initialSort,
        },
    },
        useSortBy,
        usePagination,
    );

    /* The above code is using destructuring assignment to extract specific properties and methods from
    the `tableInstance` object. These extracted properties and methods are then being used in a
    JavaScript React component. */
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        page,
        prepareRow,
        gotoPage,
        state: { pageIndex, pageSize }
    } = tableInstance;

    return (
        <div className="space-y-6">
            <div className="bg-white">
                <>

                    <div className='overflow-x-auto scrollbar relative'>
                        {rows?.length > 0 ?
                        <table className=" datatable w-[100%] " {...getTableProps()}>

                            <thead className="text-[12pt] leading-[15pt] bg-[#EDEDED] lg:text-[13px] lg:leading-[15px] text-[#666666] font-semibold py-4 px-0 text-center ">
                                {
                                    headerGroups.map(headerGroup => (

                                        <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                                            {
                                                headerGroup.headers.map(column => (
                                                    <th {...column.getHeaderProps([
                                                        {
                                                            className: `py-3.5 px-5 whitespace-nowrap ${column.headerClassName}`,
                                                            style: column.headerStyle,
                                                        },
                                                        column.getSortByToggleProps(),
                                                    ])} title=""
                                                        console={console.log(column, "frcdf")}
                                                    >
                                                        <div className="flex">
                                                        <span
                                                                {...column.getHeaderProps([
                                                                    {
                                                                        className: `mr-1 ${column.headerspanClassName}`,
                                                                    },

                                                                ])}
                                                            >{column.render('Header')}</span>
                                                            {column.disableSortBy ? "" : column.isSorted
                                                                ? column.isSortedDesc
                                                                    ? <SortAscendingIcon size={16} className="mt-[0.15rem] lg:mt-0" />
                                                                    : <SortDescendingIcon size={16} className="mt-[0.15rem] lg:mt-0" />
                                                                : <SortIcon size={16} className="mt-[0.15rem] lg:mt-0" />}
                                                        </div>
                                                    </th>
                                                ))
                                            }
                                        </tr>
                                    ))
                                }
                            </thead>

                            <tbody {...getTableBodyProps()} className="relative text-[12pt] leading-[16pt] lg:text-[14px] font-sans_book_body text-left">

                                {page?.length == 0
                                    ? <tr>
                                        <td colspan="8">
                                            <div className="h-[4rem] mx-auto  lg:text-[15px] text-[15px] text-[#333333] relative mt-[3rem] text-center">
                                                No data found
                                            </div>
                                        </td>
                                    </tr>
                                    : page?.map(row => {
                                        prepareRow(row)
                                        return (
                                            <tr {...row.getRowProps()} className="border-b">
                                                {

                                                    row.cells.map(cell => {
                                                        console.log(cell, "celll");
                                                        if (cell.column.blurColumns || cell.value == Constants.restrictedPlaceholder) {
                                                            return (<td onClick={onBlurColumnClick} className=' relative whitespace-nowrap cursor-pointer' {...cell.getCellProps()}>
                                                                <div className='blur-sm bg-[#DCDCDC] select-none w-full h-full px-5 py-4 text-center'>{Constants.restrictedPlaceholder}</div>
                                                                <div className='absolute h-full w-full flex items-center justify-center top-0 bottom-0'>
                                                                    <LockIcon size={16} color='#696969'/>
                                                                </div>
                                                            </td>)
                                                        }

                                                        return (
                                                            <td colSpan={cell.column.colspan} rowSpan={cell.column.rowspan} {...cell.getCellProps([
                                                                {
                                                                    className: `data-td-animate lg:whitespace-nowrap px-5 py-4 pr-[2.7rem]  ${cell.column.className} `,
                                                                    style: cell.column.style,
                                                                },
                                                            ])}>
                                                                {
                                                                    cell.value ? cell.render('Cell') : " "
                                                                }
                                                            </td>


                                                        )
                                                    })}
                                            </tr>

                                        )
                                    })}
                            </tbody>
                        </table>
                        : 
                        <>
                            <div className="h-[3rem] mx-auto  lg:text-[15px] text-[15px] text-[#333333] relative mt-[1rem] text-center">
                                No data found
                            </div>
                        </>
                        }
                    </div>
                    {rows?.length <= Constants.noOfRowPerPage
                        ? <></>
                        : <Paginate
                            currentPage={pageIndex + 1}
                            totalCount={rows?.length}
                            pageSize={pageSize}
                            onPageChange={(page) => {
                                gotoPage(page - 1);
                            }}
                        />}
                    {<ConfirmPopup {...alert} onChange={onConfirmation} />}
                </>
            </div>
        </div>
    )
}

export default FundingTableExit