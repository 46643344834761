import {React, useState, createContext} from 'react'
import barraiser from '../images/download_1/png/download.png';
import kanara from '../images/kinara-logo.png';
import apnaclub from '../images/logo2/png/logo2.png';

export const CompareCompanyContext = createContext('');

/**
 * The code defines a React component called CompareCompanyProvider that provides a context for
 * comparing company details and manages state for company lists, company details, and current page.
 * @returns The code is returning a component called `CompareCompanyProvider`. This component wraps its
 * children with a `CompareCompanyContext.Provider` component and passes down some state values and
 * setter functions as the value prop of the provider.
 */
const CompareCompanyProvider = ({children}) => {
    const [compareCompanyId,setCompareCompanyId] = useState([])
    const [currentPage, setCurrentPage] = useState('')
    const [companyList, setCompanyList] = useState(
        [
            { label: ' HDFC', name: 'Company', id:"3609" },
            { label: ' Kanara Capital', name: 'Company', id:"3609" },
            { label: ' ApnaKlub', name: 'Company', id:"3" },
            { label: 'Bizzo', name: 'Company', id:"4" },
            { label: 'BlissCLub', name: 'Company', id:"5" },
            { label: 'Excitel', name: 'Investor', id:"6" },
            { label: ' Fanztar', name: 'Investor', id:"7" }
        ]
    )
    const [companyDetail, setCompanyDetail] = useState([]);
    const [companyHeader, setCompanyHeader] = useState([]);

    const compareCompanyDetail = { companyDetail, setCompanyDetail, companyHeader, setCompanyHeader, compareCompanyId, setCompareCompanyId, companyList, currentPage, setCurrentPage }

    return (
        <CompareCompanyContext.Provider value={compareCompanyDetail}>
            {children}
        </CompareCompanyContext.Provider>
    )
}

export default CompareCompanyProvider
