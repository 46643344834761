import React, { useState, useMemo, useEffect } from "react";
import { useSearchParams, Routes, Route, useParams } from 'react-router-dom';
import { invIncOverview } from '../../../Config/config'
import axios from 'axios'
import OverflowToolTip from "../../shared/OverflowToolTip";
import Loader from "../../Loader";

/**
 * The function `InvestorIncubationProfileHighlight` renders a profile highlight section for an
 * investor incubation profile, displaying profile information and handling loading states.
 * @returns The code is returning a JSX element, which represents the structure and content of a React
 * component.
 */
const InvestorIncubationProfileHighlight = ({ profileHighlights, isLoading }) => {

  return (
    <div>
      <div className="space-y-6 px-4 bg-[#EDEDED]">
        <div className="">
          <div
            className="lg:mt-4 lg:bg-white  rounded-[6px]"
            x-show="tab === 1"
          >
            <div className="w-full lg:grid lg:grid-cols-6  shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
              <div className=" mt-3 lg:mt-0 rounded-lg lg:rounded lg:col-span-6 lg:border-r bg-white ">
                <div className="px-5 py-4 ">
                  <p className=" text-[14pt] leading-[19pt] lg:text-[17px] text-[#333333] font-semibold lg:leading-[22px]">
                    Profile
                  </p>
                  {!isLoading
                    ? <div className="grid grid-cols-4 ">
                      {profileHighlights?.map((highlight,i) => {

                        return (
                          <div className={"profiletable  "+(i>3 ? '':'border-b') }>
                            <div className=" leading-[21px] font-sans_book_body ">
                              <OverflowToolTip component={
                                <p className=" py-1 text-[11pt]   leading-[18pt] lg:text-[13px] text-[#333333]  cursor-auto"
                                  readOnly >
                                  {highlight.label}
                                </p>
                              } />
                              <p className=" mb-2 mr-3  text-[11pt] break-all leading-[18pt] lg:text-[13px] text-[#333333] cursor-pointer font-semibold "
                                readOnly >

                              {highlight.value ? highlight.label =="Website" ? <a target="_blank" href={highlight.value}>{highlight.value}</a> : highlight.value == 0 ? "--" :highlight.value: "--"}
                              </p>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                    : <div className="h-[10rem] w-full bg-[#FFFFFF] flex items-center justify-center ">
                      <Loader />
                    </div>}
                </div>
              </div>
              {/* <!--first half end--> */}

              {/* <!--end--> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvestorIncubationProfileHighlight;