import React, { useContext, useEffect, createContext, useRef, useState, useMemo } from 'react';
import axios from 'axios';
import Tab from '../shared/Tab';
import addImg from '../../images/addQuery.png'
import QueryBuilderPopup from './QueryBuilderPopup';
import { data } from "./PopupDataInvestor"
import CompaniesLogo from '../../images/noun-investment-2180740_4/png/noun-investment-2180740.png';
import InvestorLogo from '../../images/Shape_4/png/Shape.png';
import AdvisorLogo from '../../images/noun-investment-2180740_2/png/noun-investment-2180740.png';
import TransactionLogo from '../../images/noun-investment-2180740_5/png/noun-investment-2180740.png';
import closeBtn from "../../images/CloseBtn.svg"
import { Select } from 'antd';
import { Option } from 'antd/es/mentions';
import useModule from "../../hooks/useModule";
import { UserContext } from '../../Contexts/UserContext';
import { advanceSearchCountryUrl, filterLocationUrl, tagSearchUrl } from '../../Config/config';
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useCubeQuery } from '@cubejs-client/react'
import { advanceSearchQuery } from '../../Queries/CubeQueries'
import {InvestorsTable} from './TableContainer';
import { cubejsApi } from '../..';
import DynamicFilterComponent from './DynamicFilterComponent';
import SavedFilterPopUp from '../SavedFilter/SavedFilterPopUp';
import moment from "moment"
import Loader from '../Loader';
import { TableDataContext } from '../../Contexts/TableDataContext';
import { Tooltip } from "antd";
import { groupBy } from 'lodash';
import LineTo,{ SteppedLineTo, } from 'react-lineto';
import { nanoid } from 'nanoid'
import routePathSegments, { routeNames } from '../../routeSegments';
import OverflowToolTip from '../shared/OverflowToolTip';

const lineColor = "#BDBDBD";

const generateId = ()=>nanoid()

/**
 * The `tagRender` function is a React component that renders a tag with a label, value, and an
 * optional close button.
 * @returns The `tagRender` function returns a JSX element.
 */
const tagRender = (props) => {





  const { label, value, closable, onClose } = props;
  return (
    <div className="mx-1 my-1 text-[12px] flex items-stretch justify-between font-bold  px-1 py-1 rounded-sm bg-slate-100 text-black border ">
      <div>
        {label}
      </div>

      <div onClick={onClose} className='px-1 flex items-center justify-center cursor-pointer'>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="black" className="w-3 h-3">
          <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </div>


    </div>
  );
};

export default function AdvanceInvestorsSearch() {
    const { advancedSearchCurrentPage, setAdvancedSearchCurrentPage } = useContext(TableDataContext)
    const { pathname } = useLocation();
    const {
      refineSearchFilters, setrefineSearchFilters } = useContext(PeFilterContext);
    const { getToken } = useContext(UserContext);
    const [isActive, setIsActive] = useState('Investors')
    const [resultSet, setResultSet] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [popUpOpen, setPopUpOpen] = useState(false)
    const [popUpOpenOption, setPopUpOpenoption] = useState({show:false,child:null})
    const [selectedParent, setSelectedParent] = useState(data.initial?.at(0))
    const [selectedChild, setSelectedChild] = useState([])
    const [openQbPopup, setOpenQbPopup] = useState()
    const [selected, setSelected] = useState()
    const [filterList, setFilterList] = useState()
  
    const [selectedOperator, setSelectedOperator] = useState([
      {
        "Company Name": "",
        "Country": "Includes any",
        "State": "",
        "Region": "",
        "City": "",
        "Tags": ""
      }
    ])
    const [searchOptions, setSearchOptions] = useState(
      {
        "Company Name": [],
        "Country": [],
        "State": [],
        "Region": [],
        "City": [],
        "Tags": []
      }
    )
    const [searchText, setSearchText] = useState("");
    const [fetching, setFetching] = useState(false);
    const [options, setOptions] = useState([]);
    const [open, setOpen] = useState(false);
    const firstRender = useRef(false);
    const { primaryMasterId } = useModule();
    const [columnData, setColumnData] = useState([]);
    const [companyData, setCompanyData] = useState();
    const [showViewAllList, setShowViewAllList] = useState(false)
    const [companyDataQuery, setCompanyDataQuery] = useState([]);
  
    const [searchButtonClicked, setSearchButtonClicked] = useState(false);
    const [subBtnhover, setSubBtnHover] = useState(false);
    const [totalInvestor, setTotalInvestor] = useState(null)
    const [urlId,setUrlId] =useState()
    const navigate = useNavigate()
    const onSubBtnHover = () => {
      setSubBtnHover(true);
    };
  
    /**
     * The function `onSubBtnLeave` sets the state variable `subBtnHover` to `false`.
     */
    const onSubBtnLeave = () => {
      setSubBtnHover(false);
    };
  
    useEffect(() => {
      serachResult()
    }, [advancedSearchCurrentPage])
  
    /**
     * The function `findTotalCount` retrieves the total count of investors using a query and sets the
     * value to the state variable `totalInvestor`.
     */
    const findTotalCount = () => {
      const query0 = totalCount();

      // setIsLoading(true);
      cubejsApi.load(query0
      )
        .then((result) => {
          setIsLoading(false)
          // console.log('result', result.loadResponse.results[0].data[0]["DealFlat.count_of_investor"]);
          result.loadResponse.results[0].data.length==0?
            setTotalInvestor(0)
            :
          setTotalInvestor(result.loadResponse.results[0].data[0]["peinvestors.count_of_investor"]);
        }) 
  }
  
 /**
  * The function `totalCount()` returns a cube query object with filters and segments based on the
  * selectedChild data.
  * @returns The function `totalCount()` returns the `cubeQuery` object.
  */
  function totalCount() {
    const cubeQuery = {
        timeDimensions: [],
        measures: [
          "peinvestors.count_of_investor"
        ],
        filters: [],
        segments:["peinvestors.investors_seg"]
        // limit: 10*advancedSearchCurrentPage,
        // offset: advancedSearchCurrentPage=="1"?0:10*(advancedSearchCurrentPage-1)
      };
  
      selectedChild?.forEach((child) => {
        const operatorObject = child.filterData.searchType.find((type) => Object.values(type)[0]);
        if (operatorObject) {
          const { member, values } = child;
          console.log(child, "valuesvaluesvalues");
          const operator = Object.keys(operatorObject)[0];
          if (operator === "Includes all"){
            cubeQuery.filters.push({
              and: 
                values.map((value, i) =>(
                {
                    member,
                    operator: mapOperator("Equals"),
                    values: [value],
                }
              ))
            })
          }
          else if (child.name === "Deal Involving") {
            if (values?.length > 1) {
              if(operator !== "Includes any"){
                cubeQuery.filters.push({
                  member: "peinvestments_investors.newinvestor",
                  operator: mapOperator(operator),
                  values: ["1"],
                });
                cubeQuery.filters.push({
                  member: "peinvestments_investors.existinvestor",
                  operator: mapOperator(operator),
                  values: ["1"],
                });
            }
            else{
              cubeQuery.filters.push({
                or: [
                  {
                    member: "peinvestments_investors.newinvestor",
                    operator: mapOperator(operator),
                    values: ["1"],
                  },
                  {
                    member: "peinvestments_investors.existinvestor",
                    operator: mapOperator(operator),
                    values: ["1"],
                  }
                ]
              });
            }
            }
            else {
              if(operator !== "Includes any"){
                if (values[0].toLowerCase() === "new investor") {
                  cubeQuery.filters.push({
                    member: "peinvestments_investors.newinvestor",
                    operator: mapOperator(operator),
                    values: ["1"],
                  });
                }
                else if (values[0].toLowerCase() === "existing investor") {
                  cubeQuery.filters.push({
                    member: "peinvestments_investors.existinvestor",
                    operator: mapOperator(operator),
                    values: ["1"],
                  });
                }
                else {
                  cubeQuery.filters.push({
                    member: "peinvestments_investors.existinvestor",
                    operator: mapOperator(operator),
                    values: ["2"],
                  });
                }
              }
              else{
                if (values[0].toLowerCase() === "new investor") {
                  cubeQuery.filters.push({
                    or: [
                      {
                        member: "peinvestments_investors.newinvestor",
                        operator: mapOperator(operator),
                        values: ["1"],
                      }
                    ]
                  });
                }
                else if (values[0].toLowerCase() === "existing investor") {
                  cubeQuery.filters.push({
                    or: [
                      {
                        member: "peinvestments_investors.existinvestor",
                        operator: mapOperator(operator),
                        values: ["1"],
                      }
                    ]
                  });
                }
                else {
                  cubeQuery.filters.push({
                    member: "peinvestments_investors.existinvestor",
                    operator: mapOperator(operator),
                    values: ["2"],
                  });
                }
              }
              
            }
          }
          else if (child.name === "Deal Type") {
            if (values[0].toLowerCase() === "equity only") {
              cubeQuery.filters.push({
                member,
                operator: mapOperator(operator),
                values: ["0"],
              });
            }
            else if (values[0].toLowerCase() === "debt only") {
              cubeQuery.filters.push({
                member,
                operator: mapOperator(operator),
                values: ["1"],
              });
            }
            else {
              cubeQuery.filters.push({
                member,
                operator: mapOperator(operator),
                values: ["2"],
              });
            }
            
          }
          else if (operator === "Range") {
            cubeQuery.filters.push({
              member,
              operator: mapOperator("Greater than or equal to"),
              values: [values[0]],
            });
            cubeQuery.filters.push({
              member,
              operator: mapOperator("Less than or equal to"),
              values: [values[1]],
            });
          }
          else if (operator === "Blanks") {
            // if value true
            if (child.values[0] == "True") {
              cubeQuery.filters.push({
                member,
                operator: "notSet",
                values: undefined
              });
            }
            if (child.values[0] == "False") {
              // if value false
              cubeQuery.filters.push({
                member,
                operator: "set",
                values: undefined
              });
  
            }
          }
          else if (operatorObject["dataType"] === "Between" && operator === "Between") {
            console.log(values, "hkjihjkjkl");
            let valueFrom = [values[0]]
            let valueTo = [values[1]]
            cubeQuery.filters.push({
              member,
              operator: mapOperator("Greater than or equal to"),
              values: [values[0]],
            });
            cubeQuery.filters.push({
              member,
              operator: mapOperator("Less than or equal to"),
              values: [values[1]],
            });
          }
          else if (operatorObject["dataType"] === "CalenderType") {
            console.log(values, "operatorObjectoperatorObject");
            if(operator == "Equals"){
              let days = moment(`${values[1]}-${values[0]}`, "YYYY-MM").daysInMonth() // 29
              let value = ""
              if (parseInt(values[0]) < 10) {
                value = [values[1] + "-0" + values[0] + "-" + days]
              }
              else {
                value = [values[1] + "-" + values[0] + "-" + days]
              }
              cubeQuery.filters.push({
                member,
                operator:mapOperator("Greater than or equal to"),
                values: [values[1] + "-0" + values[0] + "-" + "01"],
              });
              cubeQuery.filters.push({
                member,
                operator: mapOperator("Less than or equal to"),
                values: value,
              });
            }
            else{
              let days = moment(`${values[1]}-${values[0]}`, "YYYY-MM").daysInMonth() // 29
              let value = ""
              if (parseInt(values[0]) < 10) {
                value = [values[1] + "-0" + values[0] + "-" + days]
              }
              else {
                value = [values[1] + "-" + values[0] + "-" + days]
              }
              cubeQuery.filters.push({
                member,
                operator: mapOperator(operator),
                values: value,
              });
            }
          }
          else if (operatorObject["dataType"] === "MultiCalenderType") {
            console.log(values, "operatorObjectoperatorObject");
            let days = ""
            let value = []
            //from
            days = moment(`${values[1]}-${values[0]}`, "YYYY-MM").daysInMonth() // 29
            if (parseInt(values[0]) < 10) {
              value.push(values[1] + "-0" + values[0] + "-" + "01")
            }
            else {
              value.push(values[1] + "-" + values[0] + "-" + days)
            }
            //to
            days = moment(`${values[3]}-${values[2]}`, "YYYY-MM").daysInMonth() // 29
            if (parseInt(values[2]) < 10) {
              value.push(values[3] + "-0" + values[2] + "-" + days)
            }
            else {
              value.push(values[3] + "-" + values[2] + "-" + days)
            }
            cubeQuery.filters.push({
              member,
              operator: mapOperator(operator),
              values: value,
            });
          }
          else if (operator === "Includes all") {
            cubeQuery.filters.push({
              member,
              operator: mapOperator(operator),
              values,
            });
          }
          else {
            cubeQuery.filters.push({
              member,
              operator: mapOperator(operator),
              values,
            });
          }
        }
      });
      cubeQuery['segments'].push(
        "peinvestors.investors_seg"
      );

      // if(cubeQuery.filters.find(o => o.member === 'DealFlat.SPV') || cubeQuery.filters.find(o => o.member === 'DealFlat.investors' ) || cubeQuery.filters.find(o => o.member === 'DealFlat.Amountm') || cubeQuery.filters.find(o => o.member === 'DealFlat.Amount_inr') || cubeQuery.filters.find(o => o.member === 'DealFlat.amountM') || cubeQuery.filters.find(o => o.member === 'DealFlat.amountInr') || cubeQuery.filters.find(o => o.member === 'DealFlat.date') || cubeQuery.filters.find(o => o.member === 'DealFlat.exitStatus') || cubeQuery.filters.find(o => o.member === 'DealFlat.stage') || cubeQuery.filters.find(o => o.member === 'DealFlat.round') || cubeQuery.filters.find(o => o.member === 'DealFlat.priceToBook') || cubeQuery.filters.find(o => o.member === 'DealFlat.preMoneyValuationInr') || cubeQuery.filters.find(o => o.member === 'DealFlat.revenueMultiplePre') || cubeQuery.filters.find(o => o.member === 'DealFlat.ebitdaMultiplePre') || cubeQuery.filters.find(o => o.member === 'DealFlat.patMultiplePre') || cubeQuery.filters.find(o => o.member === 'DealFlat.postMoneyValuationInr') || cubeQuery.filters.find(o => o.member === 'DealFlat.revenueMultiplePost') || cubeQuery.filters.find(o => o.member === 'DealFlat.ebitdaMultiplePost') || cubeQuery.filters.find(o => o.member === 'DealFlat.patMultiplePost') || cubeQuery.filters.find(o => o.member === 'DealFlat.enterpriseValuationInr') || cubeQuery.filters.find(o => o.member === 'DealFlat.revenueMultipleEv') || cubeQuery.filters.find(o => o.member === 'DealFlat.ebitdaMultipleEv') || cubeQuery.filters.find(o => o.member === 'DealFlat.patMultipleEv')){
      //   cubeQuery['segments'].push(
      //     "Pecompanies.dealtype"
      //   )
      // }
      // else if(cubeQuery.filters.find(o => o.member === 'exit_investment_flat.deal_amount') || cubeQuery.filters.find(o => o.member === 'exit_investment_flat.deal_date') || cubeQuery.filters.find(o => o.member === 'exit_investment_flat.exit_status') || cubeQuery.filters.find(o => o.member === 'exit_investment_flat.deal_type') || cubeQuery.filters.find(o => o.member === 'exit_investment_flat.deal_sub_type') || cubeQuery.filters.find(o => o.member === 'exit_investment_flat.company_valuation') || cubeQuery.filters.find(o => o.member === 'exit_investment_flat.revenue_multiple') || cubeQuery.filters.find(o => o.member === 'exit_investment_flat.ebitda_multiple') || cubeQuery.filters.find(o => o.member === 'exit_investment_flat.pat_multiple')){
      //   cubeQuery['segments'].push(
      //     "Pecompanies.exitsOnly"
      //   )
      // }
      // else if(cubeQuery.filters.find(o => o.member === 'peinvestments_investors.count_of_leadinvestor')){
      //   cubeQuery['segments'].push(
      //     "peinvestments_investors.lead_seg"
      //   )
      // }

    return cubeQuery;
  }
  
    /**
     * The `searchResult` function performs a search operation by generating a cube query, setting the
     * query in local storage, loading the query using the cubejs API, and updating the state with the
     * search results.
     */
    const serachResult = () => {
      setIsLoading(true);
      findTotalCount()
      // setrefineSearchFilters(1)
      setCompanyData()
      setSearchButtonClicked(!searchButtonClicked);
      const query0 = generateCubeQuery(selectedChild);
      const queryId = generateId()
      setUrlId(queryId)
      localStorage.setItem(queryId,JSON.stringify(query0?.filters))  
      setCompanyDataQuery(query0);
  
      // setIsLoading(true);
      // cubejsApi.load(query0
      // )
      //   .then((result) => {
      //     console.log('result', result.loadResponse.results[0].data);
      //     setCompanyData(result.loadResponse.results[0].data);
      //     setIsLoading(false)
      //     // setPopOverAdvance(false)
      //     // navigate("/query-builder",{state:{result:result.loadResponse.results[0].data}})
      //   })
  
      // Navigate('/query-builder')
  
  
    }
  
   /**
    * The function `viewMore` sets `showViewAllList` to `false` and navigates to the "/query-builder"
    * route with the `filterList` as a state.
    */
    const viewMore = () => {
      setShowViewAllList(false)
          navigate("/query-builder",{state:{ filter: filterList}})
    }
  
  
  
   /* The above code is defining a React hook called `pvConfig` using the `useMemo` hook. The `useMemo`
   hook is used to memoize the result of a function so that it is only recomputed when its
   dependencies change. */
    const pvConfig = useMemo(() => {
      if (resultSet?.query()?.dimensions?.length === 0) {
        return {
          fillMissingDates: true,
          x: [`${resultSet?.query()?.timeDimensions[0]?.dimension}.${resultSet?.query()?.timeDimensions[0]?.granularity}`],
          y: ['measures']
        }
      }
      return {
        fillMissingDates: true,
        x: resultSet?.query()?.dimensions,
        y: [`${resultSet?.query()?.timeDimensions[0]?.dimension}.${resultSet?.query()?.timeDimensions[0]?.granularity}`, 'measures']
      }
    }, [resultSet])
  
  
  
    const tableColumns = resultSet?.tableColumns(pvConfig) ?? []
    const tableData = resultSet?.tablePivot(pvConfig) ?? []
  
  
    /**
     * The function `selectedChildList` checks if an option has siblings and logs a message
     * accordingly, and if the option does not have siblings, it logs the parent and adds the option to
     * the selected child list.
     */
    const selectedChildList = (option) => {
  
      if (option.hasSiblings) {
        // Option has siblings
        console.log(option, 'selected child with siblings');
      } else {
        // Option does not have siblings
        console.log(option, 'selected child without siblings');
      }
  
  
      if (option.hasMore == false) {
        console.log(data[option.parent], 'selected child parent');
        // var filtered = selectedChild.filter(function (el) { return el.parent == option.parent; });
        const hasSibling = option?.filterLevel?.find(data=>data?.name === option.parent)?.hasMore??false
        const temp = {...option,uniqueId: generateId(),hasSibling}
        setSelectedChild(prev => [...prev, temp])
      }
    }
  
  
    /**
     * The function `removeSelectedChild` removes a child element from an array called `selectedChild`
     * based on its unique ID.
     */
    const removeSelectedChild = (id, index) => {
      // var filtered = selectedChild.filter(function (el) { return el.id != id; });
      const filtered = selectedChild.filter((el, i) => el.uniqueId !== id)
  
      console.log('filtered-09', filtered);
  
      setSelectedChild(filtered)
    }
  
    /**
     * The function `onTabClick` sets the active tab index.
     */
    const onTabClick = (tabIndex) => {
      setIsActive(tabIndex);
    }
    /**
     * The function toggles the state of a pop-up window.
     */
    const isPopUpOpen = () => {
      setPopUpOpen(!popUpOpen)
    }
  
    /**
     * The function toggles the state of the "openQbPopup" variable.
     */
    const isQbPopup = () => {
      setOpenQbPopup(!openQbPopup)
    }
  
    /**
     * The handleChange function updates the selected operator and triggers a search type update based
     * on the provided parameters.
     */
    const handleChange = (index, e, name, id, uniqueId) => {
      // 
      console.log('idonchange', index, e, name, id, uniqueId);
      const value = e;
      // alert(value);
      setSelected(null)
      setSelectedOperator(prev => [{ ...prev, [name]: e }])
      updateSearchType(index, id, value, uniqueId)
    }
  
  
    /**
     * The function updates the search type of a selected child based on the provided index, id, search
     * type text, and unique id.
     */
    function updateSearchType(index, id, searchTypeText, uniqueId) {
  
      const updatedSelectedChild = selectedChild && selectedChild?.map((row,i) => {
        if (row.uniqueId === uniqueId) {
          console.log(row.uniqueId, uniqueId,"row.uniqueId === uniqueId");
          const updatedSearchType = row.filterData.searchType.map((item) => {
            const key = Object.keys(item)[0];
            const dataType = item.dataType || row.filterData.dataType;
            // Retain the existing dataType if it exists
            return { [key]: key === searchTypeText, dataType };
          });
          row.values = []
          return {
            ...row,
            filterData: {
              ...row.filterData,
              searchType: updatedSearchType,
            },
          };
        }
        return row;
      });
  
      setSelectedChild(updatedSelectedChild);
    }
  
  
  
  
    /**
     * The handleChangeSearch function updates the search options, closes the search dropdown, clears
     * the search text, and clears the options.
     */
    const handleChangeSearch = (value, id, name) => {
      // console.log("optionsoptionsoptions", value, id, name);
      setSearchOptions(prev => [{ ...prev, [name]: id?.map(option => ({ id: option.key, name: option.value })) }])
      setOpen(false)
      setSearchText("");
      setOptions([]);
      // clearOptions()
    };
    
    /**
     * The function "clearOptions" clears the search options and search text.
     */
    const clearOptions = () => {
      setSearchOptions([])
      setSearchText("");
    }
  
    /**
     * The clearFilters function clears the selected parent and child filters.
     */
    const clearFilters = () => {
      setSelectedParent({})
      setSelectedChild([])
    }
  
    /**
     * The function `getSearchTags` is an asynchronous function that takes in a `searchText` and
     * `child` parameter, and performs different actions based on the value of `child`.
     * @returns The function does not have a return statement.
     */
    const getSearchTags = async (searchText, child) => {
      setSearchText(searchText)
      if (searchText !== "") {
        setOptions([]);
        setFetching(true);
        if (child == "Country" || child == "Region" || child == "State" || child == "City") {
          const countryIds = refineSearchFilters[pathname]?.countries?.map(value => value.id) ?? [];
          const regionIds = refineSearchFilters[pathname]?.region?.map(value => value.id) ?? [];
          const stateIds = refineSearchFilters[pathname]?.state?.map(value => value.id) ?? [];
          const cityIds = refineSearchFilters[pathname]?.city?.map(value => value.id) ?? [];
          try {
            const response = await axios.post(advanceSearchCountryUrl, {
              search_key: searchText
            }, {
              headers: {
                "Authorization": getToken()
              }
            });
  
            if (response.status === 200) {
              let result = ""
              // console.log(response.data.result, "response.data");
              result = response.data.result
              setOptions(result);
              setFetching(false);
            } else {
              return [];
            }
          } catch (error) {
            return [];
          }
        }
        else if (child == "Tags") {
          // alert("12")
        }
  
  
      }
      else {
        setOptions([]);
      }
    }
  
    /**
     * The function `generateCubeQuery` generates a cube query object based on the selected filters and
     * dimensions.
     * @returns The function `generateCubeQuery` returns the `cubeQuery` object.
     */
    function generateCubeQuery(selectedChild) {
      const cubeQuery = {
        timeDimensions: [],
        // measures: [
        //   "DealFlat.count_of_investor"
        // ],
        order: {
          "peinvestors.investor": "asc",
        },
        dimensions: [
          "peinvestors.investor",
          "peinvestors.email",
          "peinvestors.city",
          "peinvestors.yearfounded",
          "peinvestors.description",
          "peinvestor_logo.icon_path"
        ],
        segments:["peinvestors.investors_seg"],
        filters: [],
        limit: 5
      };
  
      selectedChild?.forEach((child) => {
        const operatorObject = child.filterData.searchType.find((type) => Object.values(type)[0]);
        if (operatorObject) {
          const { member, values } = child;
          console.log(child, "valuesvaluesvalues");
          const operator = Object.keys(operatorObject)[0];
          if (operator === "Includes all"){
            cubeQuery.filters.push({
              and: 
                values.map((value, i) =>(
                {
                    member,
                    operator: mapOperator("Equals"),
                    values: [value],
                }
              ))
            })
          }
          else if (child.name === "Deal Involving") {
            if (values?.length > 1) {
              if(operator !== "Includes any"){
                cubeQuery.filters.push({
                  member: "peinvestments_investors.newinvestor",
                  operator: mapOperator(operator),
                  values: ["1"],
                });
                cubeQuery.filters.push({
                  member: "peinvestments_investors.existinvestor",
                  operator: mapOperator(operator),
                  values: ["1"],
                });
            }
            else{
              cubeQuery.filters.push({
                or: [
                  {
                    member: "peinvestments_investors.newinvestor",
                    operator: mapOperator(operator),
                    values: ["1"],
                  },
                  {
                    member: "peinvestments_investors.existinvestor",
                    operator: mapOperator(operator),
                    values: ["1"],
                  }
                ]
              });
            }
            }
            else {
              if(operator !== "Includes any"){
                if (values[0].toLowerCase() === "new investor") {
                  cubeQuery.filters.push({
                    member: "peinvestments_investors.newinvestor",
                    operator: mapOperator(operator),
                    values: ["1"],
                  });
                }
                else if (values[0].toLowerCase() === "existing investor") {
                  cubeQuery.filters.push({
                    member: "peinvestments_investors.existinvestor",
                    operator: mapOperator(operator),
                    values: ["1"],
                  });
                }
                else {
                  cubeQuery.filters.push({
                    member: "peinvestments_investors.existinvestor",
                    operator: mapOperator(operator),
                    values: ["2"],
                  });
                }
              }
              else{
                if (values[0].toLowerCase() === "new investor") {
                  cubeQuery.filters.push({
                    or: [
                      {
                        member: "peinvestments_investors.newinvestor",
                        operator: mapOperator(operator),
                        values: ["1"],
                      }
                    ]
                  });
                }
                else if (values[0].toLowerCase() === "existing investor") {
                  cubeQuery.filters.push({
                    or: [
                      {
                        member: "peinvestments_investors.existinvestor",
                        operator: mapOperator(operator),
                        values: ["1"],
                      }
                    ]
                  });
                }
                else {
                  cubeQuery.filters.push({
                    member: "peinvestments_investors.existinvestor",
                    operator: mapOperator(operator),
                    values: ["2"],
                  });
                }
              }
              
            }
          }
          else if (child.name === "Deal Type") {
            if (values[0].toLowerCase() === "equity only") {
              cubeQuery.filters.push({
                member,
                operator: mapOperator(operator),
                values: ["0"],
              });
            }
            else if (values[0].toLowerCase() === "debt only") {
              cubeQuery.filters.push({
                member,
                operator: mapOperator(operator),
                values: ["1"],
              });
            }
            else {
              cubeQuery.filters.push({
                member,
                operator: mapOperator(operator),
                values: ["2"],
              });
            }
            
          }
          else if (operator === "Range") {
            cubeQuery.filters.push({
              member,
              operator: mapOperator("Greater than or equal to"),
              values: [values[0]],
            });
            cubeQuery.filters.push({
              member,
              operator: mapOperator("Less than or equal to"),
              values: [values[1]],
            });
          }
          else if (operator === "Blanks") {
            // if value true
            if (child.values[0] == "True") {
              cubeQuery.filters.push({
                member,
                operator: "notSet",
                values: undefined
              });
            }
            if (child.values[0] == "False") {
              // if value false
              cubeQuery.filters.push({
                member,
                operator: "set",
                values: undefined
              });
  
            }
          }
          else if (operatorObject["dataType"] === "Between" && operator === "Between") {
            console.log(values, "hkjihjkjkl");
            let valueFrom = [values[0]]
            let valueTo = [values[1]]
            cubeQuery.filters.push({
              member,
              operator: mapOperator("Greater than or equal to"),
              values: [values[0]],
            });
            cubeQuery.filters.push({
              member,
              operator: mapOperator("Less than or equal to"),
              values: [values[1]],
            });
          }
          else if (operatorObject["dataType"] === "CalenderType") {
            console.log(values, "operatorObjectoperatorObject");
            let days = moment(`${values[1]}-${values[0]}`, "YYYY-MM").daysInMonth() // 29
            let value = ""
            if (parseInt(values[0]) < 10) {
              value = [values[1] + "-0" + values[0] + "-" + days]
            }
            else {
              value = [values[1] + "-" + values[0] + "-" + days]
            }
            cubeQuery.filters.push({
              member,
              operator: mapOperator(operator),
              values: value,
            });
          }
          else if (operatorObject["dataType"] === "MultiCalenderType") {
            console.log(values, "operatorObjectoperatorObject");
            let days = ""
            let value = []
            //from
            days = moment(`${values[1]}-${values[0]}`, "YYYY-MM").daysInMonth() // 29
            if (parseInt(values[0]) < 10) {
              value.push(values[1] + "-0" + values[0] + "-" + "01")
            }
            else {
              value.push(values[1] + "-" + values[0] + "-" + days)
            }
            //to
            days = moment(`${values[3]}-${values[2]}`, "YYYY-MM").daysInMonth() // 29
            if (parseInt(values[2]) < 10) {
              value.push(values[3] + "-0" + values[2] + "-" + days)
            }
            else {
              value.push(values[3] + "-" + values[2] + "-" + days)
            }
            cubeQuery.filters.push({
              member,
              operator: mapOperator(operator),
              values: value,
            });
          }
          else if (operator === "Includes all") {
            cubeQuery.filters.push({
              member,
              operator: mapOperator(operator),
              values,
            });
          }
          else {
            cubeQuery.filters.push({
              member,
              operator: mapOperator(operator),
              values,
            });
          }
        }
      });
      if(cubeQuery.filters.find(o => o.member === 'DealFlat.SPV') || cubeQuery.filters.find(o => o.member === 'DealFlat.investors' ) || cubeQuery.filters.find(o => o.member === 'DealFlat.Amountm') || cubeQuery.filters.find(o => o.member === 'DealFlat.Amount_inr') || cubeQuery.filters.find(o => o.member === 'DealFlat.amountM') || cubeQuery.filters.find(o => o.member === 'DealFlat.amountInr') || cubeQuery.filters.find(o => o.member === 'DealFlat.date') || cubeQuery.filters.find(o => o.member === 'DealFlat.exitStatus') || cubeQuery.filters.find(o => o.member === 'DealFlat.stage') || cubeQuery.filters.find(o => o.member === 'DealFlat.round') || cubeQuery.filters.find(o => o.member === 'DealFlat.priceToBook') || cubeQuery.filters.find(o => o.member === 'DealFlat.preMoneyValuationInr') || cubeQuery.filters.find(o => o.member === 'DealFlat.revenueMultiplePre') || cubeQuery.filters.find(o => o.member === 'DealFlat.ebitdaMultiplePre') || cubeQuery.filters.find(o => o.member === 'DealFlat.patMultiplePre') || cubeQuery.filters.find(o => o.member === 'DealFlat.postMoneyValuationInr') || cubeQuery.filters.find(o => o.member === 'DealFlat.revenueMultiplePost') || cubeQuery.filters.find(o => o.member === 'DealFlat.ebitdaMultiplePost') || cubeQuery.filters.find(o => o.member === 'DealFlat.patMultiplePost') || cubeQuery.filters.find(o => o.member === 'DealFlat.enterpriseValuationInr') || cubeQuery.filters.find(o => o.member === 'DealFlat.revenueMultipleEv') || cubeQuery.filters.find(o => o.member === 'DealFlat.ebitdaMultipleEv') || cubeQuery.filters.find(o => o.member === 'DealFlat.patMultipleEv')){
        cubeQuery['segments'].push(
          "Pecompanies.dealtype"
        )
      }
      else if(cubeQuery.filters.find(o => o.member === 'exit_investment_flat.deal_amount') || cubeQuery.filters.find(o => o.member === 'exit_investment_flat.deal_date') || cubeQuery.filters.find(o => o.member === 'exit_investment_flat.exit_status') || cubeQuery.filters.find(o => o.member === 'exit_investment_flat.deal_type') || cubeQuery.filters.find(o => o.member === 'exit_investment_flat.deal_sub_type') || cubeQuery.filters.find(o => o.member === 'exit_investment_flat.company_valuation') || cubeQuery.filters.find(o => o.member === 'exit_investment_flat.revenue_multiple') || cubeQuery.filters.find(o => o.member === 'exit_investment_flat.ebitda_multiple') || cubeQuery.filters.find(o => o.member === 'exit_investment_flat.pat_multiple')){
        cubeQuery['segments'].push(
          "Pecompanies.exitsOnly"
        )
      }
      else if(cubeQuery.filters.find(o => o.member === 'peinvestments_investors.count_of_leadinvestor')){
        cubeQuery['segments'].push(
          "peinvestments_investors.lead_seg"
        )
      }
    setFilterList(cubeQuery.filters)
    localStorage.setItem("investorFilterList", JSON.stringify(cubeQuery.filters))
  
      return cubeQuery;
    }
  
  
  
    /**
     * The function `mapOperator` maps a given operator to its corresponding operator in a different
     * format.
     * @returns a corresponding operator based on the input operator.
     */
    function mapOperator(operator) {
      switch (operator) {
        case "Includes any":
          return "contains"; // dont let user to type  // or // operator equal
        case "Does not include":
          return "notEquals"; // dont let user to type  
        case "Starts with":
          return "startsWith"; // "startsWith";  
        case "Contains":
          return "contains";   // dont let user to select
        case "Does not contains":
          return "notContains"; // dont let user to select
        case "Blanks":
          return "set"; // is not set, or notset or notequal spave
        case "Includes all":
          return "equals"; // and // operator equal
        case "Equals":
          return "equals";
        case "Equals to":
          return "equals";
        case "Does not equal to":
          return "notEquals";
        case "Before":
          return "lt"; // beforeDate 
        case "After":
          return "gt";
        case "Between":
          return "inDateRange";
        case "Greater than or equal to":
          return "gte";
        case "Less than or equal to":
          return "lte";
        default:
          return operator;
      }
    }
  
    useEffect(() => {
      setSelectedChild(selectedChild)
    }, [selectedChild],[removeSelectedChild]);
  
  
    /**
     * The function `openFilterPopup` sets the state to show a popup with a child component.
     */
    const openFilterPopup = (child)=>{
      setPopUpOpenoption({show:true,child})
    }
  
    /**
     * The closeFilterPopup function sets the state of the PopUpOpenoption to hide the popup and remove
     * its child component.
     */
    const closeFilterPopup = ()=>{
      setPopUpOpenoption({show:false,child:null})
    }
  
    /* The above code is using the `useMemo` hook in React to create a memoized version of the
    `filtersView` variable. The `useMemo` hook takes a function and an array of dependencies as
    arguments. The function is called only when one of the dependencies changes. In this case, the
    function is creating a new array by grouping the `selectedChild` array by its "category"
    property. This memoized version of `filtersView` will only be recalculated when the
    `selectedChild` array changes. */
    const filtersView = useMemo(() => {
      return groupBy(selectedChild,"category");
    }, [selectedChild])
  
    console.log("filtersView-09",filtersView,selectedChild);
  return (
    <div>
       <div className=''>
      <div className=' mt-4 pl-1 '>
        {
          Object.keys(filtersView).map((keyStr, i) => {
            const haveNext = (Object.keys(filtersView).length - (i + 1) !== 0)
            const key = keyStr?.split(" ").join("")
            let nextKey = "";
            let nextKeyClass = "";
            if (haveNext) {
              nextKey = Object.keys(filtersView).at(i + 1);
              nextKeyClass=Object.keys(filtersView).at(i + 1)?.split(" ").join("");
            }
            return <div className={`flex `}>
              <div className='z-10'>
                <LineTo within='query-builder' borderColor={lineColor} toAnchor='left' fromAnchor="left" delay={100}  borderWidth={5} from={key} to={`${key}start`} orientation={"v"} />
                <div className={`w-[12rem] flex justify-end ${key}`}>
                  <div className={`${key}start`} />
                  <button className={` overflowText p-2 my-2 text-[15px] rounded-md border border-[#D9BD7F] rounded focus:outline-none bg-[#FFFFFF] h-[2.5rem] w-[11rem] whitespace-nowrap overflow-hidden text-left`}>
                    {keyStr}
                  </button>
                </div>
                {
                  haveNext
                    ? null
                    : <>
                      <LineTo within='query-builder' borderColor={lineColor} toAnchor='left' fromAnchor="left" delay={100}  borderWidth={5} from={`startBtn`} to={`startBtnStart`} orientation={"v"} />
                      <div className={`w-[12rem] flex justify-end startBtn `}>
                        <div className={`startBtnStart`} />
                        <button onClick={() => {setPopUpOpen(true);setShowViewAllList(false)}} className={` my-2 mx-[0rem] h-[2.5rem] w-[11rem] text-sm flex items-center gap-1 font-sans_medium_body bg-[#d7d7d7] px-2 py-1  text-black shadow-sm hover:bg-[#cba043] rounded-md`}>
                          <img alt='' src={addImg} className='w-5 h-5' /> <OverflowToolTip component={ `Add ${isActive} Filter `} isTopCard={true} />
                        </button>
                      </div>
                    </>
                }
                {/* <button onClick={() => openFilterPopup("child")} className='m-0 ml-[1.7rem] absolute flex items-center gap-2 font-sans_medium_body bg-[#d7d7d7] px-[1rem] py-[0.4rem] text-base text-black shadow-sm hover:bg-[#cba043] rounded-md' onMouseEnter={onSubBtnHover} onMouseLeave={onSubBtnLeave} style={{ top: `${14.2 + ((i + 1) * 4.5)}rem` }}>
                  <img alt='' src={addImg} className='w-5 h-5' /> {subBtnhover && "Add " + isActive + " filter"}
                </button> */}
              </div>
              <div className='flex flex-col ml-10 '>
                {
                  filtersView[keyStr].map((fil, i) => {
                    let selectedType = fil?.filterData?.searchType?.map((type, i) =>{
                      if((Object.values(type))[0] == true){
                        console.log((Object.keys(type))[0],"jkljljlll")
                        return(
                          (Object.keys(type))[0]
                        )
                      }
                    })
                    const keyClass = fil.name?.split(" ")?.join("")
                    let showSubBtn = false;
                    if (filtersView[keyStr].length === (i + 1)) {
                      showSubBtn = true
                    }
                    console.log(i,"jkijmkijml");
                    
                    return <div className='flex flex-col '>
                      <div className='flex'>
                        <button className={`${key}${i} z-10 overflowText my-2 p-2 text-[15px] rounded-md border border-[#D9BD7F] rounded focus:outline-none bg-[#FFFFFF] h-[2.5rem] w-[11rem] whitespace-nowrap overflow-hidden text-left`}>
                          {fil.name}
                        </button>
                        <SteppedLineTo within='query-builder'  borderColor={lineColor} delay={100}  borderWidth={5} from={key} to={`${key}${i}`} orientation={i === 0 ? "v" : "h"} />
                        <div className={`singleSearch my-2 ml-10 z-10 ${keyClass}${i}`}>
                          <Select
                            showSearch
                            name={fil.id}
                            id={fil.id}
                            uniqueId={i}
                            console={console.log((Object.keys(fil?.filterData?.searchType[0])[0]),"fil?.filterData?.searchType")}
                            value={{value:selectedType, label: selectedType}}
                            key={i}
                            onChange={(event) => handleChange(i, event, fil.name, fil.id, fil.uniqueId)}
                            className="rounded focus:outline-none w-[10rem] select"
                          >
                            {fil?.filterData?.searchType?.map((item, index) => {
                              const type = Object.keys(item)[0];
                              console.log(type, "typeee");
                              return (
                                <Option
                                  value={type}
                                  dataIndex={index}
                                // onClick={() => updateSearchType(fil.id, type)}
                                >
                                  {type}
                                </Option>
                              );
                            })}
                          </Select>
                        </div>
                        <SteppedLineTo within='query-builder' borderColor={lineColor} delay={100}  borderWidth={5} from={`${key}${i}`} to={`${keyClass}${i}`} orientation={i === 0 ? "v" : "h"} />
                        <div className={`h-[2.5rem]  my-2 ml-10 z-10 ${keyClass}${i}input`}>
                          <DynamicFilterComponent
                            selected={selected}
                            setSelected={setSelected}
                            selectedOperator={selectedOperator}
                            child={fil}
                            uniqueId={i}
                            formatData={fil?.filterData}
                            filterData={fil?.filterData}
                            console={console.log(i,"jkijkij")}
                            id={fil.id}
                            selectedChild={selectedChild}
                            setSelectedChild={setSelectedChild}
                            searchOptions={[]}
                          />
                        </div>
                        <SteppedLineTo within='query-builder' borderColor={lineColor} delay={100}  borderWidth={5} from={`${keyClass}${i}`} to={`${keyClass}${i}input`} orientation={i === 0 ? "v" : "h"} />
                        <div className='m-2 z-10  flex items-center'>
                          <button className='no-shadow' onClick={() => {removeSelectedChild(fil.uniqueId, i);setTotalInvestor('');setIsLoading(false)}}>
                            <img alt='' src={closeBtn} className='  w-[24px] h-[24px] mt-2 mx-2' />
                          </button>
                        </div>
                      </div>
                      {(fil.hasSibling && showSubBtn) &&
                        <>
                          <SteppedLineTo within='query-builder' borderColor={lineColor} delay={100}  borderWidth={5} from={`${key}`} to={`${key}secBtn`} orientation={"h"} />
                         <div className={`w-[11rem] ${key}secBtn z-10 bg-white`}>
                         <button onClick={() => openFilterPopup(fil)} className={` my-2 mx-[0rem] h-[2.5rem] w-[36px] overflow-hidden hover:overflow-clip hover:w-[11rem] text-sm flex items-center gap-2 font-sans_medium_body bg-[#d7d7d7] px-2 py-1  text-black shadow-sm hover:bg-[#cba043] rounded-md`}>
                            <img alt='' src={addImg} className='w-5 h-5' />  <OverflowToolTip component={ `Add ${keyStr} Filter `} isTopCard={true} />
                          </button>
                         </div>
                        </>
                      }
                    </div>
                  })
                }
              </div>
              {haveNext
                ? <LineTo within='query-builder' borderColor={lineColor} className='mr-10' toAnchor='left' fromAnchor="left"  delay={100}  borderWidth={5} from={key} to={`${nextKeyClass}`} orientation={"h"} />
                : <LineTo within='query-builder' borderColor={lineColor} className='mr-10' toAnchor='left' fromAnchor="left"  delay={100}  borderWidth={5} from={key} to={`startBtn`} orientation={"h"} />}
            </div>
          })
        }
      </div>

      

      {popUpOpenOption.show &&
        <QueryBuilderPopup loadedView={true} loadedViewOption={popUpOpenOption.child} loadedViewIndex={1} selectedChildListFunction={(option) => selectedChildList(option)} exportOptions={data} onClose={closeFilterPopup} setSelectedParent={setSelectedParent} setSelectedChild={setSelectedChild}
        />
      }



      {selectedChild?.length == 0 &&
        <button onClick={() => setPopUpOpen(true)} className='my-[2rem] mx-[0rem] flex items-center gap-2 font-sans_medium_body bg-[#d7d7d7] px-2 py-1 text-base text-black shadow-sm hover:bg-[#cba043] rounded-md'>
          <img src={addImg} className='w-5 h-5' />  Add {isActive} Filter
        </button>
      }
      {popUpOpen &&
        <QueryBuilderPopup selectedChildListFunction={(option) => selectedChildList(option)} exportOptions={data} onClose={isPopUpOpen} setSelectedParent={setSelectedParent} setSelectedChild={setSelectedChild}
        />
      }
      {selectedChild?.length > 0 &&
      <div className='flex items-stretch justify-between'>
        <button onClick={()=>{
                  setShowViewAllList(true);
                  serachResult()}
          } className='my-[2rem] mx-[0rem] flex items-center gap-2 font-sans_medium_body bg-[#a37517] px-[2rem] py-[0.5rem] text-base text-white shadow-sm hover:bg-[#a37517] rounded-md' disabled={isLoading}>
          {isLoading ?
            <span className='flex items-center '>
              <span role="status">
                <svg className="  mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-[#CA8A24]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                </svg>
              </span>
              Searching
            </span>
            : "Search"}
        </button>
        {(showViewAllList && !isLoading && totalInvestor !=null) &&
        <div className='flex advanceSearch'>
          <span className='flex items-center gap-2 font-sans_medium_body text-base text-black text-[13.5px]'>Found {totalInvestor} Investors,</span>
        <a target='_blank' href={`/${routePathSegments.pe}/${routePathSegments.query_builder}/${routePathSegments.investors}?id=${urlId}`} className={`flex items-center gap-2 font-sans_medium_body text-base text-[#A3751F] text-[13.5px] font-bold px-2 ${totalInvestor==0 ?'pointer-events-none':''}`} disabled={isLoading || totalInvestor==0}>
          View More
        </a>
        </div>        
      }
        </div>
        }

      {/* <button menu="QbClick"  onClick={() => setOpenQbPopup(true)}  className='my-3 flex items-center gap-2 font-sans_medium_body bg-[#BC8B20] px-2 py-1 text-base text-white shadow-sm hover:bg-[#a37517] rounded-md'>
        Click Here 
      </button> */}

      {openQbPopup &&
        <SavedFilterPopUp menu="QbClick" onClose={isQbPopup} />

      }

      
{/* <hr /> */}
    {/* <p>
        <pre> {JSON.stringify(selectedChild, null, 2)}  </pre>
      </p> */}

      {/* <hr />
      <p>
        <pre> {JSON.stringify(companyDataQuery, null, 2)}  </pre>
      </p> */}

      {/* <hr /> */}
      {/* {selectedChild?.length > 0 &&
      <>
      <p>
        <pre> {JSON.stringify(selectedChild, null, 2)}  </pre>
      </p>
      <hr />
      <p>
        <pre> {JSON.stringify(companyDataQuery, null, 2)}  </pre>
      </p>
      </>
      } */}

      {/* {companyData.length > 0 ? ( */}
      {/* <InvestorsTable rowData={companyData} tableHeight={selectedChild?.length}/> */}
      {/* ) : (
  searchButtonClicked && <p><Loader /> </p>
)} */}

      {/* {companyData && companyData.length > 0 ?        
      <InvestorsTable
        columnData={tableColumns}
        rowData={companyData}
        isLoading={isLoading}
        pvConfig={pvConfig}
        selectedViewBy={"periodWise"}
      /> : 'Loading ...'
      }   */}
    </div>
    </div>
  )
}
