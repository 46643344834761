import { useState, React } from "react";
import { slide as Menu } from "react-burger-menu";
import DashBoardImg from '../images/dashboard_image.png';
import PeImg from '../images/Shape_1/png/Shape.png';
import ReImg from '../images/noun-investment-2180740/png/noun-investment-2180740.png';
import MaImg from '../images/Shape/png/Shape.png';
import CfsImg from '../images/Group/png/Group.png';
import ReportsImg from '../images/Combined Shape/png/Combined Shape.png';
import FaqImg from '../images/Combined Shape_1/png/Combined Shape.png';
import NotesImg from '../images/Combined Shape_2/png/Combined Shape.png';
import Logo from '../images/VI.jpg'
// import v2logo from './images/Bitmap/png/Bitmap.png';
import MobileCloseMenu from '../images/Group 10 Copy/png/Group 10 Copy.png'
import { NavLink } from "react-router-dom";
import { Nav } from "reactstrap";
import { matchPath, useLocation, useMatch } from 'react-router-dom';

import routePathSegments, { routeNames } from '../routeSegments';


export default (props) => {
    const [openTab, setOpenTab] = useState(0);
    const location = useLocation();
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    const isPeCompanyMatch = matchPath({ path: `${routePathSegments.pe}/*` }, location.state?.prevPath ?? "")
    const isVcCompanyMatch = matchPath({ path: `${routePathSegments.vc}/*` }, location.state?.prevPath ?? "")
    const isReCompanyMatch = matchPath({ path: `${routePathSegments.re}/*` }, location.state?.prevPath ?? "")
    const isMaCompanyMatch = matchPath({ path: `${routePathSegments.ma}/*` }, location.state?.prevPath ?? "")
    const cfsMatch = matchPath({ path: `${routePathSegments.cfs}` }, location.state?.prevPath ?? "")
    const [select, setSelected] = useState(0);
    // const [show, setShow] = useState(false);
    /**
     * The function `accordionClick` updates the selected value based on the input value.
     * @param val - The `val` parameter is the value that is passed to the `accordionClick` function.
     * It is used to determine which accordion item should be selected.
     */
    const accordionClick = (val) => {
        // setShow(!show);
        if (val == select) {
            setSelected(0);
        } else {
            setSelected(val);
        }
    };

    return (
        <div className="bg-[#EDEDED]">
            <Menu>
                <div className=" flex items-center bg-white sticky top-0 z-10">
                    <div className="flex justify-between">
                        <div href="#">
                            <img className="h-16 w-auto" src={Logo} alt="Workflow" />
                        </div>
                    </div>
                </div>

                <div className="" >
                    <ul className=" space-y-0.5 bg-[#474747]  h-screen">
                        <li className="before:content-none pl-0 menu-item">
                            
                            <div className="">
                                <div className="relative">
                                    <div className={"border-b-2  border-[#3B3838] " + (select == 1
                                        ? "bg-[#BC8B20] text-white py-3 "
                                        : "bg-[#474747] text-[#F1CA75]")
                                    }>
                                        <NavLink to="/dashboard"
                                            className={({ isActive }) => {
                                                if (isActive) {
                                                    return 'flex justify-between menuimg bg-[#BC8B20] text-white w-full';
                                                }

                                                return 'w-full text-[#F1CA75] ';
                                            }}>
                                            <header className={select == 1
                                                ? "   font-sans_semi_bold flex hover:bg-[#BC8B21]  justify-between   pl-5 pr-5  cursor-pointer select-none tab-label"
                                                : "  font-sans_roman_body flex justify-between  py-4 pl-5 pr-5  cursor-pointer select-none tab-label "
                                            }
                                            >


                                                <button>
                                                    <a href="#"
                                                        className="flex  whitespace-nowrap text-[14pt] hover:text-[14pt]">
                                                        <img className="mr-5 mt-1 whitespace-nowrap text-[16pt] hover:text-[14pt]  "
                                                            src={DashBoardImg} />
                                                        Dashboard
                                                    </a>
                                                </button>

                                            </header>


                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <div className="relative">
                            <div className={"border-b-2  border-[#3B3838] " + (select == 2
                                ? "bg-[#BC8B20] text-white py-3 "
                                : "bg-[#474747] text-[#F1CA75]")
                            }>
                                <div className="flex justify-between">
                                    <NavLink to={routePathSegments.pe}
                                        className={({ isActive }) => {
                                            if (isActive) {
                                                return 'flex justify-between menuimg bg-[#BC8B20] text-white w-full';
                                            }
                                            if (isPeCompanyMatch !== null) {
                                                return 'flex justify-between menuimg bg-[#BC8B20] text-white w-full';
                                            }

                                            return 'w-full text-[#F1CA75] flex justify-between';
                                        }}>
                                    
                                        <header className={select == 2
                                            ? "   font-sans_semi_bold flex hover:bg-[#BC8B21]  justify-between   pl-5 pr-5  cursor-pointer select-none tab-label"
                                            : "  font-sans_roman_body flex justify-between  py-2 pl-5 pr-5  cursor-pointer select-none tab-label "
                                        }
                                        >


                                            <button>
                                                <div className="flex">

                                                    <img

                                                        src={PeImg} />
                                                    <span
                                                        className="ml-5  whitespace-nowrap text-[14pt] hover:text-[14pt]  ">
                                                        PE
                                                    </span>

                                                </div>
                                            </button>

                                        </header>

                                        <div type="button" className="p-3 mr-2" onClick={() => accordionClick(2)}
                                        >
                                            {select == 2 ? (
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                    className="h-[17px] w-[17px] rotate-180 cursor-pointer transition-transform duration-200 mt-3 top-1/2 transform -translate-y-1/2 right-3"
                                                    fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                                                </svg>
                                            ) : (
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                    className="h-[17px] w-[17px] rotate-0 cursor-pointer transition-transform duration-200 mt-3 top-1/2 transform -translate-y-1/2 right-3"
                                                    fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                                                </svg>
                                            )}
                                        </div>
                                    </NavLink>
                                </div>
                                <div className="relative overflow-hidden transition-all max-h-0 duration-700" style={{
                                    maxHeight: select == 2 ? "fit-content" : ""
                                }}>
                                    <ul className=" bg-[#BC8B21] space-y-4 ">
                                        <li className="before:content-none pl-0 ml-[5rem] text-[14pt] font-sans_medium_body">
                                            <NavLink to={routeNames.PE_home}
                                               
                                                className={() => {
                                                    const pathWithoutLastPart =
                                                        location.pathname.slice(
                                                            0,
                                                            location.pathname.lastIndexOf("/")
                                                        );
                                                    const pathWithoutLastPartLevel1 =
                                                        location.pathname.indexOf("/pe/companies/pe-vc-investments");
                                                    const pathWithoutLastPartLevel2 =
                                                        location.pathname.indexOf("/pe/company");
                                                    // return pathWithoutLastPartLevel2;
                                                    if (
                                                        pathWithoutLastPartLevel1 > -1 ||
                                                        pathWithoutLastPartLevel2 > -1
                                                        ) {
                                                            return "relative top-[2px] pb-3 pt-2 text-[14pt] font-sans_medium_body hover:bg-[#BC8B21] block border-b border-[##FFFFFF]  text-[#FFFFFF]  ";
                                                        } else {
                                                            return "relative top-[2px] pb-3  pt-2 text-[14pt]  hover:bg-[#BC8B21] block border-b border-[##FFFFFF]  text-[#FFFFFF]   font-sans_medium_body opacity-[.8]";
                                                        }
                                                }}
                                                >
                                                <a href=""
                                                >Companies

                                                </a>

                                            </NavLink>
                                            < NavLink to="/pe/investors/pe-vc-investments"
                                                
                                                className={() => {
                                                    const pathWithoutLastPart =
                                                        location.pathname.slice(
                                                            0,
                                                            location.pathname.lastIndexOf("/")
                                                        );
                                                    const pathWithoutLastPartLevel1 =
                                                        location.pathname.indexOf("/pe/investors/pe-vc-investments");
                                                    const pathWithoutLastPartLevel2 =
                                                        location.pathname.indexOf("/re/investor");
                                                    // return pathWithoutLastPartLevel2;
                                                    if (
                                                        pathWithoutLastPartLevel1 > -1 ||
                                                        pathWithoutLastPartLevel2 > -1
                                                        ) {
                                                            return "relative top-[2px] pb-3 pt-2 text-[14pt] font-sans_medium_body hover:bg-[#BC8B21] block border-b border-[##FFFFFF]  text-[#FFFFFF]  ";
                                                        } else {
                                                            return "relative top-[2px] pb-3  pt-2 text-[14pt]  hover:bg-[#BC8B21] block border-b border-[##FFFFFF]  text-[#FFFFFF]   font-sans_medium_body opacity-[.8]";
                                                        }
                                                }}
                                            >
                                                <a href=""
                                                >Investors

                                                </a>
                                            </NavLink>
                                            
                                            <NavLink to={routeNames.pe_advisors_pe_vc_advisors}
                                                              
                                                className={() => {
                                                    const pathWithoutLastPart =
                                                        location.pathname.slice(
                                                            0,
                                                            location.pathname.lastIndexOf("/")
                                                        );
                                                    const pathWithoutLastPartLevel1 =
                                                        location.pathname.indexOf("/pe/advisors/pe-vc-advisors");
                                                    const pathWithoutLastPartLevel2 =
                                                        location.pathname.indexOf("/pe/advisor");
                                                    // return pathWithoutLastPartLevel2;
                                                    if (
                                                        pathWithoutLastPartLevel1 > -1 ||
                                                        pathWithoutLastPartLevel2 > -1
                                                        ) {
                                                            return "relative top-[2px] pb-3 pt-2 text-[14pt] font-sans_medium_body hover:bg-[#BC8B21] block border-b border-[##FFFFFF]  text-[#FFFFFF]  ";
                                                        } else {
                                                            return "relative top-[2px] pb-3  pt-2 text-[14pt]  hover:bg-[#BC8B21] block border-b border-[##FFFFFF]  text-[#FFFFFF]   font-sans_medium_body opacity-[.8]";
                                                        }
                                                }}                             >
                                                <a href=""
                                                >
                                                    Legal Advisors

                                                </a>
                                            </NavLink>
                                            <NavLink
                                                
                                                className={() => {
                                                    const pathWithoutLastPart =
                                                        location.pathname.slice(
                                                            0,
                                                            location.pathname.lastIndexOf("/")
                                                        );
                                                    const pathWithoutLastPartLevel1 =
                                                        location.pathname.indexOf("/pe/transaction-advisors/pe-vc-advisors");
                                                    const pathWithoutLastPartLevel2 =
                                                        location.pathname.indexOf("/pe/transaction-advisor");
                                                    // return pathWithoutLastPartLevel2;
                                                    if (
                                                        pathWithoutLastPartLevel1 > -1 ||
                                                        pathWithoutLastPartLevel2 > -1
                                                        ) {
                                                            return "relative top-[2px] pb-3 pt-2 text-[14pt] font-sans_medium_body hover:bg-[#BC8B21] block border-b border-[##FFFFFF]  text-[#FFFFFF]  ";
                                                        } else {
                                                            return "relative top-[2px] pb-3  pt-2 text-[14pt]  hover:bg-[#BC8B21] block border-b border-[##FFFFFF]  text-[#FFFFFF]   font-sans_medium_body opacity-[.8]";
                                                        }
                                                }} 
                                                
                                                to={routeNames.pe_transaction_advisors_pe_vc_advisors}
                                            >
                                                Transaction Advisor

                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="relative">
                            <div className={"border-b-2  border-[#3B3838] " + (select == 3
                                ? "bg-[#BC8B20] text-white py-3 "
                                : "bg-[#474747] text-[#F1CA75]")
                            }>
                                <div className="flex justify-between">
                                    <NavLink to={routePathSegments.vc}
                                        className={({ isActive }) => {
                                            if (isActive) {
                                                return 'flex justify-between menuimg bg-[#BC8B20] text-white w-full';
                                            }
                                            if (isVcCompanyMatch !== null) {
                                                return 'flex justify-between menuimg bg-[#BC8B20] text-white w-full';
                                            }

                                            return 'w-full text-[#F1CA75] flex justify-between ';
                                        }}>
                                        <header className={select == 3
                                            ? "   font-sans_semi_bold flex hover:bg-[#BC8B21]  justify-between   pl-5 pr-5  cursor-pointer select-none tab-label"
                                            : "  font-sans_roman_body flex justify-between  py-2 pl-5 pr-5  cursor-pointer select-none tab-label "
                                        }
                                        >

                                            <button>
                                                <div className="flex">
                                                    <img

                                                        src={ReImg} />
                                                    <span
                                                        className="ml-3  whitespace-nowrap text-[14pt] hover:text-[14pt] ">
                                                        VC
                                                    </span>
                                                </div>
                                            </button>


                                        </header>


                                        <div type="button" className="p-3 mr-2" onClick={() => accordionClick(3)}
                                        >
                                            {select == 3 ? (
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                    className="h-[17px] w-[17px] rotate-180 cursor-pointer transition-transform duration-200 mt-3 top-1/2 transform -translate-y-1/2 right-3"
                                                    fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                                                </svg>
                                            ) : (
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                    className="h-[17px] w-[17px] rotate-0 cursor-pointer transition-transform duration-200 mt-3 top-1/2 transform -translate-y-1/2 right-3"
                                                    fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                                                </svg>
                                            )}
                                        </div>
                                    </NavLink>
                                </div>
                                <div className="relative overflow-hidden transition-all max-h-0 duration-700" style={{
                                    maxHeight: select == 3 ? "fit-content" : ""
                                }}>
                                    <ul className=" bg-[#BC8B21] space-y-4 ">
                                        <li className="before:content-none pl-0 ml-[5rem] text-[14pt] font-sans_medium_body">
                                            <NavLink to={routePathSegments.vc}
                                                
                                                className={() => {
                                                    const pathWithoutLastPart =
                                                        location.pathname.slice(
                                                            0,
                                                            location.pathname.lastIndexOf("/")
                                                        );
                                                    const pathWithoutLastPartLevel1 =
                                                        location.pathname.indexOf("/vc/companies/vc-investments");
                                                    const pathWithoutLastPartLevel2 =
                                                        location.pathname.indexOf("/vc/company");
                                                    // return pathWithoutLastPartLevel2;
                                                    if (
                                                        pathWithoutLastPartLevel1 > -1 ||
                                                        pathWithoutLastPartLevel2 > -1
                                                        ) {
                                                            return "relative top-[2px] pb-3 pt-2 text-[14pt] font-sans_medium_body hover:bg-[#BC8B21] block border-b border-[##FFFFFF]  text-[#FFFFFF]  ";
                                                        } else {
                                                            return "relative top-[2px] pb-3  pt-2 text-[14pt]  hover:bg-[#BC8B21] block border-b border-[##FFFFFF]  text-[#FFFFFF]   font-sans_medium_body opacity-[.8]";
                                                        }
                                                }} 
                                                >
                                                <a href=""
                                                >Companies

                                                </a>

                                            </NavLink>
                                            < NavLink to="/vc/investors/vc-investments"
                                                
                                                className={() => {
                                                    const pathWithoutLastPart =
                                                        location.pathname.slice(
                                                            0,
                                                            location.pathname.lastIndexOf("/")
                                                        );
                                                    const pathWithoutLastPartLevel1 =
                                                        location.pathname.indexOf("/vc/investors/vc-investments");
                                                    const pathWithoutLastPartLevel2 =
                                                        location.pathname.indexOf("/vc/investor");
                                                    // return pathWithoutLastPartLevel2;
                                                    if (
                                                        pathWithoutLastPartLevel1 > -1 ||
                                                        pathWithoutLastPartLevel2 > -1
                                                        ) {
                                                            return "relative top-[2px] pb-3 pt-2 text-[14pt] font-sans_medium_body hover:bg-[#BC8B21] block border-b border-[##FFFFFF]  text-[#FFFFFF]  ";
                                                        } else {
                                                            return "relative top-[2px] pb-3  pt-2 text-[14pt]  hover:bg-[#BC8B21] block border-b border-[##FFFFFF]  text-[#FFFFFF]   font-sans_medium_body opacity-[.8]";
                                                        }
                                                }} 
                                            >
                                                <a href=""
                                                >Investors

                                                </a>
                                            </NavLink>
                                            {/* <NavLink to="/acquirer" className="block border-b border-[##FFFFFF] py-2   font-medium text-[#FFFFFF]   hover:bg-[#BC8B21] ">
                                                <a href=""

                                                >

                                                    Acquirers
                                                </a>
                                            </NavLink> */}
                                            <NavLink to="/vc/advisors/vc-advisors"
                                                // className={({ isActive }) => {

                                                //     if (isActive) {
                                                //         return "relative top-[2px]  text-[14pt] font-sans_medium_body hover:bg-[#BC8B21] block border-b border-[##FFFFFF]   py-3   text-[#FFFFFF]  ";
                                                //     } else {
                                                //         return "relative top-[2px] text-[14pt]  hover:bg-[#BC8B21] block border-b border-[##FFFFFF]  py-3  text-[#FFFFFF]  font-sans_medium_body opacity-[.8]";
                                                //     }
                                                // }}        
                                                className={() => {
                                                    const pathWithoutLastPart =
                                                        location.pathname.slice(
                                                            0,
                                                            location.pathname.lastIndexOf("/")
                                                        );
                                                    const pathWithoutLastPartLevel1 =
                                                        location.pathname.indexOf("/vc/advisors/vc-advisors");
                                                    const pathWithoutLastPartLevel2 =
                                                        location.pathname.indexOf("/vc/advisor");
                                                    // return pathWithoutLastPartLevel2;
                                                    if (
                                                        pathWithoutLastPartLevel1 > -1 ||
                                                        pathWithoutLastPartLevel2 > -1
                                                        ) {
                                                            return "relative top-[2px] pb-3 pt-2 text-[14pt] font-sans_medium_body hover:bg-[#BC8B21] block border-b border-[##FFFFFF]  text-[#FFFFFF]  ";
                                                        } else {
                                                            return "relative top-[2px] pb-3  pt-2 text-[14pt]  hover:bg-[#BC8B21] block border-b border-[##FFFFFF]  text-[#FFFFFF]   font-sans_medium_body opacity-[.8]";
                                                        }
                                                }}                                     >
                                                <a href=""
                                                >
                                                    Legal Advisors

                                                </a>
                                            </NavLink>
                                            <NavLink to={routeNames.vc_transaction_advisors_vc_advisors}
                                                // className={({ isActive }) => {

                                                //     if (isActive) {
                                                //         return "relative top-[2px] text-[14pt] font-sans_medium_body hover:bg-[#BC8B21] block  py-3  text-[#FFFFFF]  ";
                                                //     } else {
                                                //         return "relative top-[2px] text-[14pt]  hover:bg-[#BC8B21]    text-[#FFFFFF] py-3 font-sans_medium_body opacity-[.8]";
                                                //     }
                                                // }} 
                                                className={() => {
                                                    const pathWithoutLastPart =
                                                        location.pathname.slice(
                                                            0,
                                                            location.pathname.lastIndexOf("/")
                                                        );
                                                    const pathWithoutLastPartLevel1 =
                                                        location.pathname.indexOf("/vc/transaction-advisors/vc-advisors");
                                                    const pathWithoutLastPartLevel2 =
                                                        location.pathname.indexOf("/vc/transaction-advisor");
                                                    // return pathWithoutLastPartLevel2;
                                                    if (
                                                        pathWithoutLastPartLevel1 > -1 ||
                                                        pathWithoutLastPartLevel2 > -1
                                                        ) {
                                                            return "relative top-[2px] pb-3 pt-2 text-[14pt] font-sans_medium_body hover:bg-[#BC8B21] block border-b border-[##FFFFFF]  text-[#FFFFFF]  ";
                                                        } else {
                                                            return "relative top-[2px] pb-3  pt-2 text-[14pt]  hover:bg-[#BC8B21] block border-b border-[##FFFFFF]  text-[#FFFFFF]   font-sans_medium_body opacity-[.8]";
                                                        }
                                                }}    >
                                                <a href=""
                                                >
                                                    Transaction Advisor
                                                </a>
                                            </NavLink>
                                        </li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                        <div className="relative">
                            <div className={"border-b-2  border-[#3B3838] " + (select == 4
                                ? "bg-[#BC8B20] text-white py-3 "
                                : "bg-[#474747] text-[#F1CA75]")
                            }>
                                <div className="flex justify-between">
                                    <NavLink to={routePathSegments.re}
                                        className={({ isActive }) => {
                                            if (isActive) {
                                                return 'flex justify-between menuimg bg-[#BC8B20] text-white w-full';
                                            }
                                            if (isReCompanyMatch !== null) {
                                                return 'flex justify-between menuimg bg-[#BC8B20] text-white w-full';
                                            }

                                            return 'w-full text-[#F1CA75] flex justify-between ';
                                        }}>
                                        <header className={select == 4
                                            ? "   font-sans_semi_bold flex hover:bg-[#BC8B21]  justify-between   pl-5 pr-5  cursor-pointer select-none tab-label"
                                            : "  flex justify-between font-sans_roman_body  py-2 pl-5 pr-5  cursor-pointer select-none tab-label "
                                        }>
                                            <button>
                                                <div className="flex">
                                                    <img

                                                        src={ReImg} />
                                                    <span
                                                        className="ml-3  whitespace-nowrap text-[14pt] hover:text-[14pt] ">
                                                        RE
                                                    </span>
                                                </div>
                                            </button>


                                        </header>


                                        <div type="button" className="p-3 mr-2" onClick={() => accordionClick(4)}
                                        >
                                            {select == 4 ? (
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                    className="h-[17px] w-[17px] rotate-180 cursor-pointer transition-transform duration-200 mt-3 top-1/2 transform -translate-y-1/2 right-3"
                                                    fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                                                </svg>
                                            ) : (
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                    className="h-[17px] w-[17px] rotate-0 cursor-pointer transition-transform duration-200 mt-3 top-1/2 transform -translate-y-1/2 right-3"
                                                    fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                                                </svg>
                                            )}
                                        </div>
                                    </NavLink>
                                </div>
                                <div className="relative overflow-hidden transition-all max-h-0 duration-700" style={{
                                    maxHeight: select == 4 ? "fit-content" : ""
                                }}>
                                    <ul className=" bg-[#BC8B21] space-y-4 ">
                                        <li className="before:content-none pl-0 ml-[5rem] font-sans_medium_body text-[14pt]">
                                            <NavLink to={routePathSegments.re}
                                                 
                                                className={() => {
                                                    const pathWithoutLastPart =
                                                        location.pathname.slice(
                                                            0,
                                                            location.pathname.lastIndexOf("/")
                                                        );
                                                    const pathWithoutLastPartLevel1 =
                                                        location.pathname.indexOf("/re/companies/re-investments");
                                                    const pathWithoutLastPartLevel2 =
                                                        location.pathname.indexOf("/re/company");
                                                    // return pathWithoutLastPartLevel2;
                                                    if (
                                                        pathWithoutLastPartLevel1 > -1 ||
                                                        pathWithoutLastPartLevel2 > -1
                                                    ) {
                                                        return "relative top-[2px] pb-3 pt-2 text-[14pt] font-sans_medium_body hover:bg-[#BC8B21] block border-b border-[##FFFFFF]  text-[#FFFFFF]  ";
                                                    } else {
                                                        return "relative top-[2px] pb-3  pt-2 text-[14pt]  hover:bg-[#BC8B21] block border-b border-[##FFFFFF]  text-[#FFFFFF]   font-sans_medium_body opacity-[.8]";
                                                    }
                                                }}                                        >
                                                <a href=""
                                                >Companies

                                                </a>

                                            </NavLink>
                                            < NavLink to="/re/investors/re-investments"
                                                      
                                                className={() => {
                                                    const pathWithoutLastPart =
                                                        location.pathname.slice(
                                                            0,
                                                            location.pathname.lastIndexOf("/")
                                                        );
                                                    const pathWithoutLastPartLevel1 =
                                                        location.pathname.indexOf("/re/investors/re-investments");
                                                    const pathWithoutLastPartLevel2 =
                                                        location.pathname.indexOf("/re/investor");
                                                    // return pathWithoutLastPartLevel2;
                                                    if (
                                                        pathWithoutLastPartLevel1 > -1 ||
                                                        pathWithoutLastPartLevel2 > -1
                                                    ) {
                                                        return "relative top-[2px] pb-3 pt-2 text-[14pt] font-sans_medium_body hover:bg-[#BC8B21] block border-b border-[##FFFFFF]  text-[#FFFFFF]  ";
                                                    } else {
                                                        return "relative top-[2px] pb-3 pt-2  text-[14pt]  hover:bg-[#BC8B21] block border-b border-[##FFFFFF]  text-[#FFFFFF]   font-sans_medium_body opacity-[.8]";
                                                    }
                                                }}                                      >
                                                <a href=""
                                                >Investors

                                                </a>
                                            </NavLink>
                                            
                                            <NavLink to="/re/advisors/re-investments"                                                
                                                className={() => {
                                                    const pathWithoutLastPart =
                                                        location.pathname.slice(
                                                            0,
                                                            location.pathname.lastIndexOf("/")
                                                        );
                                                    const pathWithoutLastPartLevel1 =
                                                        location.pathname.indexOf("/re/advisors/re-investments");
                                                    const pathWithoutLastPartLevel2 =
                                                        location.pathname.indexOf("/re/advisor");
                                                    // return pathWithoutLastPartLevel2;
                                                    if (
                                                        pathWithoutLastPartLevel1 > -1 ||
                                                        pathWithoutLastPartLevel2 > -1
                                                    ) {
                                                        return "relative top-[2px] pb-3 pt-2 text-[14pt] font-sans_medium_body hover:bg-[#BC8B21] block border-b border-[##FFFFFF]  text-[#FFFFFF]  ";
                                                    } else {
                                                        return "relative top-[2px] pb-3 pt-2 text-[14pt]  hover:bg-[#BC8B21] block border-b border-[##FFFFFF]  text-[#FFFFFF]   font-sans_medium_body opacity-[.8]";
                                                    }
                                                }}                                            >
                                                <a href=""
                                                >
                                                    Legal Advisors

                                                </a>
                                            </NavLink>
                                            <NavLink to="/re/transaction-advisor/re-investments"                                                   
                                                className={() => {
                                                    const pathWithoutLastPart =
                                                        location.pathname.slice(
                                                            0,
                                                            location.pathname.lastIndexOf("/")
                                                        );
                                                    const pathWithoutLastPartLevel1 =
                                                        location.pathname.indexOf("/re/transaction-advisor/re-investments");
                                                    const pathWithoutLastPartLevel2 =
                                                        location.pathname.indexOf("/re/transaction-advisor");
                                                    // return pathWithoutLastPartLevel2;
                                                    if (
                                                        pathWithoutLastPartLevel1 > -1 ||
                                                        pathWithoutLastPartLevel2 > -1
                                                    ) {
                                                        return "relative top-[2px] pb-3 pt-2 text-[14pt] font-sans_medium_body hover:bg-[#BC8B21] block border-b border-[##FFFFFF]  text-[#FFFFFF]  ";
                                                    } else {
                                                        return "relative top-[2px] pb-3 pt-2 text-[14pt]  hover:bg-[#BC8B21] block border-b border-[##FFFFFF]  text-[#FFFFFF]   font-sans_medium_body opacity-[.8]";
                                                    }
                                                }}                                            >
                                                <a href=""
                                                >
                                                    Transaction Advisor
                                                </a>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="relative">
                            <div className={"border-b-2  border-[#3B3838] " + (select == 5
                                ? "bg-[#BC8B20] text-white py-3 "
                                : "bg-[#474747] text-[#F1CA75]")
                            }>
                                <div className="flex justify-between">
                                    <NavLink to={routePathSegments.ma}
                                        className={({ isActive }) => {
                                            if (isActive) {
                                                return 'flex justify-between menuimg bg-[#BC8B20] text-white w-full';
                                            }
                                            if (isMaCompanyMatch !== null) {
                                                return 'flex justify-between menuimg bg-[#BC8B20] text-white w-full';
                                            }

                                            return 'w-full text-[#F1CA75] flex justify-between ';
                                        }}>
                                        <header className={select == 5
                                            ? "   font-sans_semi_bold flex hover:bg-[#BC8B21]  justify-between  py-3 pl-5 pr-5  cursor-pointer select-none tab-label"
                                            : "  flex justify-between  font-sans_roman_body py-3 pl-5 pr-5  cursor-pointer select-none tab-label "
                                        }>
                                            <button>
                                                <div className="flex">
                                                    <img

                                                        src={MaImg} />
                                                    <span
                                                        className="ml-5  whitespace-nowrap text-[14pt] hover:text-[14pt]  ">
                                                        M & A
                                                    </span>
                                                </div>
                                            </button>
                                        </header>

                                        <div type="button" className="p-3 mr-2" onClick={() => accordionClick(5)}
                                        >
                                            {select == 5 ? (
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                    className="h-[17px] w-[17px] rotate-180 cursor-pointer transition-transform duration-200 mt-3 top-1/2 transform -translate-y-1/2 right-3"
                                                    fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                                                </svg>
                                            ) : (
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                    className="h-[17px] w-[17px] rotate-0 cursor-pointer transition-transform duration-200 mt-3 top-1/2 transform -translate-y-1/2 right-3"
                                                    fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                                                </svg>
                                            )}
                                        </div>
                                    </NavLink>
                                </div>
                                <div className="relative overflow-hidden transition-all max-h-0 duration-700" style={{
                                    maxHeight: select == 5 ? "fit-content" : ""
                                }}>
                                    <ul className=" bg-[#BC8B21] space-y-4 ">
                                        <li className="before:content-none pl-0 ml-[5rem] font-sans_medium_body text-[14pt]">
                                            <NavLink to='/ma/companies'                                                
                                                className={() => {
                                                    const pathWithoutLastPart =
                                                        location.pathname.slice(
                                                            0,
                                                            location.pathname.lastIndexOf("/")
                                                        );
                                                    const pathWithoutLastPartLevel1 =
                                                        location.pathname.indexOf("/ma/companies");
                                                    const pathWithoutLastPartLevel2 =
                                                        location.pathname.indexOf("/ma/company");
                                                    // return pathWithoutLastPartLevel2;
                                                    if (
                                                        pathWithoutLastPartLevel1 > -1 ||
                                                        pathWithoutLastPartLevel2 > -1
                                                    ) {
                                                        return "relative top-[2px] pb-3 pt-2 text-[14pt] font-sans_medium_body hover:bg-[#BC8B21] block border-b border-[##FFFFFF]  text-[#FFFFFF]  ";
                                                    } else {
                                                        return "relative top-[2px] pb-3  pt-2 text-[14pt]  hover:bg-[#BC8B21] block border-b border-[##FFFFFF]  text-[#FFFFFF]   font-sans_medium_body opacity-[.8]";
                                                    }
                                                }}                                            >
                                                <a href=""
                                                >Companies

                                                </a>

                                            </NavLink>
                                            < NavLink to="/ma/acquirers"                                                
                                                className={() => {
                                                    const pathWithoutLastPart =
                                                        location.pathname.slice(
                                                            0,
                                                            location.pathname.lastIndexOf("/")
                                                        );
                                                    const pathWithoutLastPartLevel1 =
                                                        location.pathname.indexOf("/ma/acquirers");
                                                    const pathWithoutLastPartLevel2 =
                                                        location.pathname.indexOf("/ma/acquirer");
                                                    // return pathWithoutLastPartLevel2;
                                                    if (
                                                        pathWithoutLastPartLevel1 > -1 ||
                                                        pathWithoutLastPartLevel2 > -1
                                                    ) {
                                                        return "relative top-[2px] pb-3 pt-2 text-[14pt] font-sans_medium_body hover:bg-[#BC8B21] block border-b border-[##FFFFFF]  text-[#FFFFFF]  ";
                                                    } else {
                                                        return "relative top-[2px] pb-3  pt-2 text-[14pt]  hover:bg-[#BC8B21] block border-b border-[##FFFFFF]  text-[#FFFFFF]   font-sans_medium_body opacity-[.8]";
                                                    }
                                                }}                                            >
                                                <a href=""
                                                >Acquirers

                                                </a>
                                            </NavLink>

                                            <NavLink to="/ma/advisors"                                                              
                                                className={() => {
                                                    const pathWithoutLastPart =
                                                        location.pathname.slice(
                                                            0,
                                                            location.pathname.lastIndexOf("/")
                                                        );
                                                    const pathWithoutLastPartLevel1 =
                                                        location.pathname.indexOf("/ma/advisors");
                                                    const pathWithoutLastPartLevel2 =
                                                        location.pathname.indexOf("/ma/advisor");
                                                    // return pathWithoutLastPartLevel2;
                                                    if (
                                                        pathWithoutLastPartLevel1 > -1 ||
                                                        pathWithoutLastPartLevel2 > -1
                                                    ) {
                                                        return "relative top-[2px] pb-3 pt-2 text-[14pt] font-sans_medium_body hover:bg-[#BC8B21] block border-b border-[##FFFFFF]  text-[#FFFFFF]  ";
                                                    } else {
                                                        return "relative top-[2px] pb-3  pt-2 text-[14pt]  hover:bg-[#BC8B21] block border-b border-[##FFFFFF]  text-[#FFFFFF]   font-sans_medium_body opacity-[.8]";
                                                    }
                                                }}                            >
                                                <a href=""
                                                >
                                                    Legal Advisors

                                                </a>
                                            </NavLink>
                                            <NavLink to="/ma/transaction-advisors"                                               
                                                className={() => {
                                                    const pathWithoutLastPart =
                                                        location.pathname.slice(
                                                            0,
                                                            location.pathname.lastIndexOf("/")
                                                        );
                                                    const pathWithoutLastPartLevel1 =
                                                        location.pathname.indexOf("/ma/transaction-advisors");
                                                    const pathWithoutLastPartLevel2 =
                                                        location.pathname.indexOf("/ma/transaction-advisor");
                                                    // return pathWithoutLastPartLevel2;
                                                    if (
                                                        pathWithoutLastPartLevel1 > -1 ||
                                                        pathWithoutLastPartLevel2 > -1
                                                    ) {
                                                        return "relative top-[2px] pb-3 pt-2 text-[14pt] font-sans_medium_body hover:bg-[#BC8B21] block border-b border-[##FFFFFF]  text-[#FFFFFF]  ";
                                                    } else {
                                                        return "relative top-[2px] pb-3  pt-2 text-[14pt]  hover:bg-[#BC8B21] block border-b border-[##FFFFFF]  text-[#FFFFFF]   font-sans_medium_body opacity-[.8]";
                                                    }
                                                }}                                             >
                                                <a href=""
                                                >
                                                    Transaction Advisor
                                                </a>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="relative">
                            <div className={"border-b-2  border-[#3B3838] " + (select == 6
                                ? "bg-[#BC8B20] text-white py-3 "
                                : "bg-[#474747] text-[#F1CA75]")
                            }>
                                <div className="flex justify-between">
                                    <NavLink to={routePathSegments.cfs}
                                        className={({ isActive }) => {
                                            if (isActive) {
                                                return 'flex justify-between menuimg bg-[#BC8B20] text-white w-full';
                                            }
                                            if (cfsMatch !== null) {
                                                return 'flex justify-between menuimg bg-[#BC8B20] text-white w-full';
                                            }

                                            return 'w-full text-[#F1CA75] flex justify-between ';
                                        }}>
                                        <header className={select == 6
                                            ? "  text-white  font-sans_semi_bold flex hover:bg-[#BC8B21]  justify-between   pl-5 pr-5  cursor-pointer select-none tab-label"
                                            : "  font-sans_roman_body flex justify-between  py-3 pl-5 pr-5  cursor-pointer select-none tab-label "
                                        }>

                                            <button>
                                                <div className="flex">
                                                    <img

                                                        src={CfsImg} />
                                                    <span
                                                        className="ml-5  whitespace-nowrap text-[14pt] hover:text-[14pt]  ">
                                                        CFS
                                                    </span>
                                                </div>
                                            </button>
                                        </header>                                       
                                    </NavLink>
                                </div>
                                <div className="relative overflow-hidden transition-all max-h-0 duration-700" style={{
                                    maxHeight: select == 6 ? "fit-content" : ""
                                }}>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="relative">
                            <div className={"border-b-2  border-[#3B3838] " + (select == 7
                                ? "bg-[#BC8B20] text-white py-3 "
                                : "bg-[#474747] text-[#F1CA75]")
                            }>
                                <li className="font-sans_roman_body flex justify-between    cursor-pointer select-none tab-label before:content-none pl-0 menu-item">                                    
                                    <NavLink to={routePathSegments.reports}
                                        className={({ isActive }) => {
                                            if (isActive) {
                                                return 'flex justify-between menuimg bg-[#BC8B20] text-white w-full';
                                            }


                                            return 'w-full text-[#F1CA75] ';
                                        }}>
                                        <header className={select == 7
                                            ? "   font-sans_semi_bold flex hover:bg-[#BC8B21]  justify-between   pl-5 pr-5  cursor-pointer select-none tab-label"
                                            : "  font-sans_roman_body flex justify-between  py-3 pl-5 pr-5  cursor-pointer select-none tab-label "
                                        }
                                        >


                                            <button>
                                                <a href="#"
                                                    className="flex  whitespace-nowrap text-[14pt] hover:text-[14pt]">
                                                    <img className="mr-5 flex-shrink-0 h-6 w-6 text-[#887D5E] whitespace-nowrap text-[16pt] hover:text-[14pt]  "
                                                        src={ReportsImg} />
                                                    Reports
                                                </a>
                                            </button>

                                        </header>


                                    </NavLink>
                                </li>
                            </div>
                        </div>
                        <div className="relative">
                            <div className={"border-b-2  border-[#3B3838] " + (select == 8
                                ? "bg-[#BC8B20] text-white py-3 "
                                : "bg-[#474747] text-[#F1CA75]")
                            }>
                                <li className="font-sans_roman_body flex justify-between  cursor-pointer select-none tab-label before:content-none pl-0 menu-item">
                                    <NavLink
                                        to={routePathSegments.faq}
                                        className={({ isActive }) => {
                                            if (isActive) {
                                                return 'flex justify-between menuimg bg-[#BC8B20] text-white w-full';
                                            }


                                            return 'w-full text-[#F1CA75] ';
                                        }}>
                                        <header className={select == 8
                                            ? "   font-sans_semi_bold flex hover:bg-[#BC8B21]  justify-between   pl-5 pr-5  cursor-pointer select-none tab-label"
                                            : "  font-sans_roman_body flex justify-between  py-3 pl-5 pr-5  cursor-pointer select-none tab-label "
                                        }
                                        >


                                            <button>
                                                <a href="#"
                                                    className="flex  whitespace-nowrap text-[14pt] hover:text-[14pt]">
                                                    <img className="mr-5 flex-shrink-0 h-6 w-6 text-[#887D5E] whitespace-nowrap text-[16pt] hover:text-[14pt]  "
                                                        src={FaqImg} />
                                                    FAQs
                                                </a>
                                            </button>

                                        </header>
                                    </NavLink>
                                </li>
                            </div>
                        </div>
                        <div className="relative">
                            <div className={"" + (select == 9
                                ? "bg-[#BC8B20] text-white py-3 "
                                : "bg-[#474747] text-[#F1CA75]")
                            }>
                                <li className="font-sans_roman_body flex justify-between    cursor-pointer select-none tab-label before:content-none pl-0 menu-item ">
                                    <NavLink
                                        to={routePathSegments.notes}
                                        className={({ isActive }) => {
                                            if (isActive) {
                                                return 'flex justify-between menuimg bg-[#BC8B20] text-white w-full';
                                            }


                                            return 'w-full text-[#F1CA75] ';
                                        }}>
                                        <header className={select == 9
                                            ? "   font-sans_semi_bold flex hover:bg-[#BC8B21]  justify-between   pl-5 pr-5  cursor-pointer select-none tab-label"
                                            : "  font-sans_roman_body flex justify-between  py-3 pl-5 pr-5  cursor-pointer select-none tab-label "
                                        }
                                        >


                                            <button>
                                                <a href="#"
                                                    className="flex  whitespace-nowrap text-[14pt] hover:text-[14pt]">
                                                    <img className="mr-5 flex-shrink-0 h-6 w-6 text-[#887D5E] whitespace-nowrap text-[16pt] hover:text-[14pt]  "
                                                        src={NotesImg} />
                                                    Notes
                                                </a>
                                            </button>

                                        </header>

                                    </NavLink>
                                </li>
                            </div>
                        </div>
                    </ul>
                </div>
            </Menu >           
        </div >
    );
};