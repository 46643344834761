import React from 'react'
import CompanyDetail from './RequestCompanyDetail'
import TopSection from '../../Components/InvestorLimitedpartnerDetail/TopSection';

/**
 * The DetailPage function returns a React component that displays a layout with a top section, a
 * primary column, and a secondary column.
 * @returns The DetailPage component is returning a JSX structure that represents the layout of a
 * detail page. It consists of a main container div with two sections: a primary column and a secondary
 * column. The primary column contains a CompanyDetail component, while the secondary column is hidden
 * on smaller screens. The primary column has a flex layout and is scrollable vertically. The secondary
 * column has a fixed width and a border,
 */
function DetailPage() {
    return (
        <div>
                <div className="flex h-screen flex-col">
                    <TopSection />
                    <div className="flex min-h-0 flex-1 overflow-hidden">
                        {/* Primary column */}
                        <section
                            aria-labelledby="primary-heading"
                            className="flex h-full min-w-0 flex-1 flex-col overflow-y-auto lg:order-last">
                            <CompanyDetail />

                        </section>
                        {/* Secondary column (hidden on smaller screens) */}
                        <aside className="hidden lg:order-first lg:block lg:flex-shrink-0">
                            <div className="relative flex h-full  flex-col overflow-y-auto border-r border-gray-200 bg-[#FAF5EA]">
                                
                            </div>
                        </aside>
                    </div>
                    <br/>
                    
                </div>
        </div>
    )
}

export default DetailPage
