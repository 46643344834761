/**
 * The function `getSection` returns a string with a query parameter for a given section.
 * @param from - The "from" parameter is a string that represents the section from which you want to
 * retrieve data.
 * @returns a string that includes the section parameter with the value of the "from" argument.
 */
export const getSection = (from) => {
    return `?section=${from}`
}

/**
 * The function `checkValue` checks if a value is null, 0, or NaN and returns null if any of these
 * conditions are met, otherwise it returns the value itself.
 * @param value - The `value` parameter is the value that needs to be checked.
 * @returns The function `checkValue` returns the value if it is not null, not equal to 0, and is a
 * valid number. Otherwise, it returns null.
 */
export const checkValue = (value) => {
    if (value == null) {
        return null
    }
    if (parseInt(`${value}`) === 0 || isNaN(parseInt(`${value}`))) {
        return null;
    }
    return value
}

/**
 * The function `getDetailPageUrl` takes an `entityType` and an `entity` object as parameters and
 * returns a URL based on the `entityType` and properties of the `entity` object.
 * @param entityType - The `entityType` parameter is a string that represents the type of entity. It
 * can be one of the following values: 'Company', 'Requests', or any other entity type that you may add
 * in the future.
 * @param entity - The `entity` parameter is an object that contains information about the entity. It
 * has the following properties:
 * @returns The function `getDetailPageUrl` returns a URL based on the given `entityType` and `entity`.
 */
export const getDetailPageUrl = (entityType, entity) => {
    let url = '';
    switch(entityType) {
      case 'Company':
        if (entity.notification_type === 'New Deal' && entity.module_type === 'pe') {
          url = `/pe/${entity.entity_type.toLowerCase()}/pe-vc-investments/${entity.entity_id}/${entity.deal_id}`;
        } else if (entity.notification_type === 'Valuation Update' && entity.module_type === 'pe') {
          url = `/pe/${entity.entity_type.toLowerCase()}/pe-vc-investments/${entity.entity_id}/${entity.deal_id}`;
        } else if (entity.notification_type === 'Captable') {
          url = `/pe/${entity.entity_type.toLowerCase()}/${entity.entity_id}`;
        } else {
          url = `/pe/`;
        }
        break;
      case 'Requests':
        url = `/raise-request/${entity.entity_id}/`;
        break;
      // Add cases for other entity types here
      default:
        url = '/';
    }
    return url;
  }

/**
 * The `debounceFn` function is a utility function  that takes a function `f` and a delay
 * `delay` as parameters, and returns a new function that will execute `f` after the specified delay,
 * but only if the new function is not called again within that delay period.
 * @param f - The parameter "f" is a function that you want to debounce. It is the function that will
 * be called after the debounce delay has passed.
 * @param delay - The `delay` parameter is the amount of time in milliseconds that the function should
 * wait before executing after the last invocation.
 * @returns The debounceFn function returns a new function that will execute the provided function (f)
 * after a specified delay.
 */
  export function debounceFn(f, delay) {
    let timer = 0;
    return function(...args) {
        clearTimeout(timer);
        timer = setTimeout(() => f.apply(this, args), delay);
    }
}