import { Fragment, useState, useContext } from "react";
import { useParams } from 'react-router-dom';
import { exportExcel } from "../../Config/config";
import axios from "axios";
import { Dialog, Transition } from "@headlessui/react";
import closeIcon from "../../images/search_close_button.png";
import { TableDataContext } from "../../Contexts/TableDataContext";

const ExcelPopup = (props) => {
  const { companiesid, setCompaniesId } = useContext(TableDataContext);
console.log(props.openTab, "opentab");
  console.log(companiesid, "allcompanyxl");
  console.log(props.menu, "merger");

  const [percentage, setPercentage] = useState(0);
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(true);
  const [selectedExportType, setSelectedExportType] = useState();
  const [progress, setProgress] = useState(null);
  const { companyId } = useParams();
  let companyFundingId = parseInt(companyId)
  const { dealId } = useParams();
  let dealDetailId = parseInt(dealId)

  /**
   * The function updates the selected export type based on the value of the file export input.
   */
  const onFileExportChanged = (e) => {
    setSelectedExportType(e.target.value);
  };
  
  /**
   * The `downloadExcel` function sends a POST request to a server to export data as an Excel file and
   * then downloads the file.
   */
  const downloadExcel = () => {
    axios(`${exportExcel}`, {
      method: "POST",
      data: {
        export_from: "detail",
        with_deals: selectedExportType == "1" ? false : true,
        category: "investments",
        section:props.menu == "merger" ? "merger_acquisition"
                 : props.menu == "funding"?"funding"
                  : props.menu == "similar"? "similar_companies"
                   :props.menu == "dealpage"? "deal_details"
                    :" ",
        sub_section: props.openTab == 1? "investments"
                    : props.openTab == 2? "exits"
                     : props.openTab == 3? "angel"
                      : props.openTab == 4? "incubation"
                       :" "  ,
        profile_columns: ["ALL"],
        deal_columns: ["ALL"],
        with_agg_hide: 0,
        company_id: [companyFundingId],
        deal_id:props.menu == "dealpage"? dealDetailId
                :""
      },
      headers: {
        "Content-Type": "application/json",
       },
      responseType: "blob",
    //     onDownloadProgress(progressEvent) {
    //       progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);

    //       setPercentage(progress);

    //       documentStyles.setProperty('--progress', `${progress}%`);
    //   }
    })
      .then((response) => {
        // setProgress('finished');
        const url = window.URL.createObjectURL(new Blob([response.data]));
        console.log(response.headers['content-disposition'],"companyheader");
        //  console.log(response.headers["content-disposition"],"headers");
        //  console.log(response.headers['content-disposition']('filename=')[1], "headers");
        // console.log(new Blob([response.type]),"filename");
        // console.log(new Blob([response]),"blob");
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log("API not working - getRoundHierarchy", error);
      });
  };
  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => props.setShowPop(!props?.showPop)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="items-center flex min-h-full  justify-center p-4 text-center ">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="absolute top-0 right-0  pt-4 pr-4 ">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none  "
                      onClick={() => props?.setShowPop(!props?.showPop)}
                    >
                      <span className="sr-only">Close</span>
                      {/* <XMarkIcon className="h-6 w-6" aria-hidden="true" /> */}
                      <img src={closeIcon} className="h-8 w-8 " />
                    </button>
                  </div>
                  {/* {!show && <div> */}
                  <div className="text-left px-1 md:px-0 md:text-center font-sans_semi_bold text-[#333333] text-[15px] ">
                    Export
                  </div>
                  
                  {/* </div>} */}
                  

                  <div>
                    {/* <div className={`progress-button ${progress}`}>
            <span className="loading-text">Loading</span>
                <button className="download-button" onClick={downloadExcel}>
                    <span className="button-text">Download</span>
                    <span className="button-text">{progress === 'finished' ? '🎉 Done' : 'Download'}</span>
                </button>
            <span className="percentage">{percentage}%</span>
        </div> */}

                    <div className="text-left  mt-2 font-[Squel Sans] text-[#333333] text-[13px] font-Medium ">
                      © TSJ Media Pvt. Ltd. This data is meant for the internal
                      and non-commercial use of the purchaser and cannot be
                      resold, rented, licensed or otherwise transmitted without
                      the prior permission of TSJ Media. Any unauthorized
                      redistribution will constitute a violation of copyright
                      law.
                    </div>
                  </div>
                  <div className="items-center justify-center sm:flex">
                    <button
                      type="button"
                      className={
                        "  mt-5 inline-flex w-full justify-center font-[Squel Sans]   border border-transparent px-4 py-1 text-base font-medium text-white bg-[#BC8B20] shadow-sm  focus:outline-none focus:ring-2 sm:ml-3 sm:w-auto sm:text-sm  " +
                        (!selectedExportType && "cursor-pointer")
                      }
                      onClick={downloadExcel}
                    >
                      Download
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default ExcelPopup;
