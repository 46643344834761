import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'

/**
 * The `ModalPopup` function is a React component that renders a modal popup with a backdrop and
 * content.
 * @returns a JSX element that represents a modal popup.
 */
export default function ModalPopup({ isOpen, onClose, children, containerClassName, outerClassName }) {

    return (<Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className={`relative z-10 ${outerClassName}`} onClose={onClose}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
                <div className={`flex min-h-full items-center justify-center ${containerClassName}`}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        {children}
                    </Transition.Child>
                </div>
            </div>
        </Dialog>
    </Transition>
    )
}
