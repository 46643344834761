/* eslint-disable eqeqeq */

/**
 * The function checks if an object is empty by returning true if it has no properties, and false
 * otherwise.
 * @param [obj] - The `obj` parameter is an object that is being checked for emptiness. If no object is
 * provided, an empty object `{}` is used as the default value.
 * @returns a boolean value indicating whether the given object is empty or not.
 */
export function isEmpty(obj = {}) {
  return Object.keys(obj).length === 0
}

/**
 * The function checks if a value is a string.
 * @param value - The parameter "value" is the value that you want to check if it is a string or not.
 * @returns The function isString returns a boolean value. It returns true if the value is a string or
 * an instance of the String object, and false otherwise.
 */
export function isString(value) {
  return typeof value === 'string' || value instanceof String
}

/**
 * The function checks if a value is a number and not NaN.
 * @param value - The parameter "value" is the value that we want to check if it is a number or not.
 * @returns a boolean value. It will return true if the value is a number and not NaN (not a number),
 * and false otherwise.
 */
export function isNumber(value) {
  return typeof value == 'number' && !isNaN(value)
}

/**
 * The function checks if a value is a boolean.
 * @param value - The parameter "value" is a variable that represents any value that we want to check
 * if it is a boolean.
 * @returns a boolean value, true or false, indicating whether the input value is a boolean or not.
 */
export function isBoolean(value) {
  return value === true || value === false
}

/**
 * The function checks if a value is undefined or null.
 * @param value - The parameter "value" is a variable that can hold any value. It is used to check if
 * the value is undefined or null.
 * @returns The function isNil is returning a boolean value. It returns true if the value is undefined
 * or null, and false otherwise.
 */
export function isNil(value) {
  return typeof value === 'undefined' || value === null
}

/**
 * The function checks if a given value is a valid date string in the format "dd-mm-yyyy".
 * @param value - The `value` parameter is the input string that we want to check if it is a valid date
 * string in the format "dd-mm-yyyy".
 * @returns a boolean value. It will return true if the value is a string that matches the format of a
 * date string in the format "dd-mm-yyyy", and false otherwise.
 */
export function isDateString(value) {
  if (!isString(value)) return false

  return value.match(/^\d{2}-\d{2}-\d{4}$/)
}

/**
 * The function `convertDateString` takes a date string in the format "dd-mm-yyyy" and converts it to
 * "yyyymmdd".
 * @param value - The `value` parameter in the `convertDateString` function is a string representing a
 * date in the format "dd-mm-yyyy".
 * @returns a converted date string in the format "YYYYMMDD".
 */
export function convertDateString(value) {
  return value.substr(6, 4) + value.substr(3, 2) + value.substr(0, 2)
}

/**
 * The function converts a string to lowercase if the input is a string, otherwise it returns the input
 * value.
 * @param value - The `value` parameter is the input value that you want to convert to lowercase.
 * @returns the lowercase version of the input value if it is a string. If the input value is not a
 * string, it is returned as is.
 */
export function toLower(value) {
  if (isString(value)) {
    return value.toLowerCase()
  }
  return value
}

/**
 * The function `convertType` converts a value to a string if it is a number, converts a date string to
 * a specific format, converts a boolean value to either '1' or '-1', and returns the value as is if
 * none of the conditions are met.
 * @param value - The `value` parameter in the `convertType` function can be any data type, such as a
 * number, string, boolean, or any other value. The function checks the type of the `value` and
 * performs specific conversions based on the type.
 * @returns the converted value based on its type. If the value is a number, it is converted to a
 * string and returned. If the value is a date string, it is converted using the `convertDateString`
 * function and returned. If the value is a boolean, it is converted to either '1' or '-1' based on its
 * truthiness and returned. If the value is none
 */
export function convertType(value) {
  if (isNumber(value)) {
    return value.toString()
  }

  if (isDateString(value)) {
    return convertDateString(value)
  }

  if (isBoolean(value)) {
    return value ? '1' : '-1'
  }

  return value
}

/**
 * The `filterRows` function filters an array of objects based on the provided filters.
 * @param rows - An array of objects representing rows of data.
 * @param filters - The `filters` parameter is an object that contains the filter criteria. Each key in
 * the `filters` object represents a property of the `row` object, and the corresponding value
 * represents the filter value for that property.
 * @returns The function `filterRows` returns a filtered array of rows based on the provided filters.
 * If the `filters` object is empty, it returns the original `rows` array. Otherwise, it filters the
 * `rows` array based on the conditions specified in the `filters` object and returns the filtered
 * array.
 */
export function filterRows(rows, filters) {
  if (isEmpty(filters)) return rows

  return rows.filter((row) => {
    return Object.keys(filters).every((accessor) => {
      const value = row[accessor]
      const searchValue = filters[accessor]

      if (isString(value)) {
        return toLower(value).includes(toLower(searchValue))
      }

      if (isBoolean(value)) {
        return (searchValue === 'true' && value) || (searchValue === 'false' && !value)
      }

      if (isNumber(value)) {
        return value == searchValue
      }

      return false
    })
  })
}

/**
 * The function sorts an array of rows based on a specified sort order and column.
 * @param rows - An array of objects representing the rows of a table. Each object in the array
 * represents a row and contains properties that correspond to the columns of the table.
 * @param sort - The `sort` parameter is an object that contains two properties: `order` and `orderBy`.
 * @returns a sorted array of rows based on the provided sort object.
 */
export function sortRows(rows, sort) {
  return [...rows].sort((a, b) => {
    const { order, orderBy } = sort

    if (isNil(a[orderBy])) return 1
    if (isNil(b[orderBy])) return -1

    const aLocale = convertType(a[orderBy])
    const bLocale = convertType(b[orderBy])

    if (order === 'asc') {
      return aLocale.localeCompare(bLocale, 'en', { numeric: isNumber(b[orderBy]) })
    } else {
      return bLocale.localeCompare(aLocale, 'en', { numeric: isNumber(a[orderBy]) })
    }
  })
}

/**
 * The `paginateRows` function takes an array of sorted rows, an active page number, and the number of
 * rows per page, and returns a new array containing the rows for the specified page.
 * @param sortedRows - The sortedRows parameter is an array of rows that have been sorted in a specific
 * order.
 * @param activePage - The activePage parameter represents the current page number that the user is on.
 * It is used to determine which subset of rows should be returned for that page.
 * @param rowsPerPage - The `rowsPerPage` parameter specifies the number of rows to be displayed per
 * page in a paginated table or list.
 * @returns a new array that contains a subset of the `sortedRows` array. The subset includes the rows
 * that correspond to the current `activePage` and `rowsPerPage` values.
 */
export function paginateRows(sortedRows, activePage, rowsPerPage) {
  return [...sortedRows].slice((activePage - 1) * rowsPerPage, activePage * rowsPerPage)
}
