import React, { useState, useEffect, useContext } from "react"
import { Link, NavLink, useNavigate } from 'react-router-dom';
import MonthPickerComponent from '../../../Utils/MonthPickerComponent'
import { TableDownloadContext } from '../../../Contexts/TableDownloadContext'
import moment from "moment"
import line from "../../../images/line.png"
import xls from "../../../images/xls/png/Group 47.png"
import FileIcon from "../../../images/file.png"
import FileIconBlur from "../../../images/fileblur.png"
import ExcelPopup from "../../../Components/ExcelPopup";
// import '../MainMenu.css'

import SavedFilterContent from "../../../Components/SavedFilter";
import SavedFilterICON from "../../../Components/SavedFilter/SavedFilterICON";
import ExportPopup from "../../../Components/ExportPopup/FundsExcel";
import { data ,fundsExportData } from "../../../Components/ExportPopup/ExportData"
import { exportFundsExcel } from "../../../Config/config";
import { PeFilterContext } from "../../../Contexts/PeFilterContext";
import { UserContext } from "../../../Contexts/UserContext";
function MainMenu(props) {

    // const [save,setSave]=useState(false)
    const {save, setSave} = useContext(PeFilterContext);
    const { handleDownloadExcel } = useContext(TableDownloadContext)
    const [openTab, setOpenTab] = React.useState(1);
    const [showPopup, setShowPopup] = useState(false);
    const [date, setDate] = useState([
        moment().startOf("month").subtract(12, "months").format("YYYY-MM-DD"),
        moment().endOf("month").format("YYYY-MM-DD"),
      ]);
    useEffect(() => {
        setOpenTab(props.active)
    }, [openTab])

          /**
           * The function `savedList` toggles the value of the `save` variable.
           */
          const savedList = () => {
    setSave(!save)
  }
  const { isStudent} = useContext(UserContext)



   /**
    * The `download` function calls the `handleDownloadExcel` function.
    */
    const download = () => {
        handleDownloadExcel()
    }
    return (
        <div className="pt-0 pl-3 pr-4">

            <div className="scrollbar  rounded">
                <div className="px-2  py-2 rounded-b-none bg-white block lg:flex flex-row justify-end shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
                    
                    
                    
                    <div className="flex justify-between py-2 lg:py-0 px-5 lg:px-0 items-center">
                        <label htmlFor="" className="text-[#666666] w-[45%] ">
                            <div className="flex">
                                <MonthPickerComponent />
                            </div>
                        </label>
                        <div className="flex">
                            {isStudent == 'true' ? <></> :
                            <a className=" px-4">
                                {/* <button onClick={download}> */}
                                <button>
                              <img
                                src={xls}
                                className="h-[24px] mt-1 w-[21px]"
                                alt=""
                                onClick={() => setShowPopup(!showPopup)}
                              />
                              {showPopup && (
                                <ExportPopup 
                                modules="fund"
                                module_type="vc"
                                // category="funds"
                                fileName={props.exportFileName}
                                url={exportFundsExcel}
                                onClose={() => setShowPopup(false)} 
                                exportOptions={fundsExportData} 
                                 />
                                // <div>
                                //   <ExcelPopup
                                //     menu="investments"
                                //     subMenu="pevc"
                                //     showPop={showPopup}
                                //     setShowPop={setShowPopup}
                                //   />{" "}
                                // </div>
                              )}
                            </button>
                            </a>}
                            {/* <a> 
                                <img className="h-[24px] w-[2px] mt-1 " src={line}/>
                            </a> */}
                             {/* <SavedFilterICON  
                          save={save} 
                          FileIcon={FileIcon} 
                          FileIconBlur={FileIconBlur}   
                          savedList={savedList}  />  */}

                        </div>

                    </div>
                </div>
                {save ? <SavedFilterContent data={save} /> : ''}
            </div>
        </div>
    )
}

export default MainMenu