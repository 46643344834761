import { React, useState, useMemo, useContext } from 'react'
import Table from './Table'
import { TableHeaderContext } from '../../Contexts/TableHeaderContext'

/**
 * The TableComponent function renders a table component with specified table headers, ordering, menu
 * options, pagination, and sub-type master ID.
 * @param props - The `props` parameter is an object that contains the properties passed to the
 * `TableComponent` component. These properties can be accessed using dot notation, for example
 * `props.menu` and `props.sub_type_master_id`.
 * @returns The TableComponent is returning a JSX element.
 */
const TableComponent = (props) => {   

    const { maTableHeader, orderBy, maOrderingColumn}= useContext(TableHeaderContext);
    const [pagination, setPagination] = useState()
    const paginationData = useMemo(() => pagination, pagination);   

    const columns = maTableHeader

    return (
        <div className="pl-3 pr-2 ">
            <div className="App">
                <Table order={orderBy} columns={columns} orderBy={maOrderingColumn=='t' ? 'target_company' :maOrderingColumn} menu={props?.menu} pagination = {paginationData} sub_type_master_id={props?.sub_type_master_id}/>
            </div>
        </div>
    )
}

export default TableComponent 





 