import React from 'react'
import FilterListDataTable from './FilterListDataTable'
import SearchTrend from '../../Pages/Charts/dashboard/deals/SearchTrend';

/**
 * The TableComponent function returns a div element containing two child components,
 * FilterListDataTable and SearchTrend, wrapped in a flex container.
 * @returns The TableComponent is returning a JSX element.
 */
const TableComponent = () => {
  return (
    <div className='p-2 w-[100%] '>
      <div className='w-full lg:flex lg:flex-nowrap'>
          <FilterListDataTable />
          <SearchTrend />
      </div>        
    </div>
  )
}

export default TableComponent