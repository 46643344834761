import React, { useContext } from 'react'
import { Navigate,useLocation } from 'react-router-dom';
// import { UserContext } from '../Contexts/UserContext'



/**
 * The PublicRoute function checks the current location and redirects to the login page if the current
 * path is "/", otherwise it renders the children components.
 * @returns The code is returning the `children` component if the `currentLocation.pathname` is not
 * equal to "/", otherwise it is returning a `Navigate` component with a `to` prop set to "/login".
 */
function PublicRoute({ children }) {
  var currentLocation = useLocation();
  console.log(currentLocation)
  
    // if (currentLocation.pathname == "/") {
        
        return <Navigate to="/login" />
    // }
    return children;
}
export default PublicRoute;