const isValidValue = (value) => {
    return value !== "0" && value !== "0.00" && value !== Infinity && !isNaN(value);
};

const calc_current_ratio = (bs) => {
    if (bs.total_current_liabilities <= 0) {
        return '';
    }
    const curr_ratio = (bs.total_current_assets / bs.total_current_liabilities).toFixed(2);
    return isValidValue(curr_ratio) ? curr_ratio : '';
};

const calc_quick_ratio = (bs) => {
    if (bs.total_current_liabilities <= 0) {
        return '';
    }
    const quick_ratio = ((bs.total_current_assets - bs.inventories) / bs.total_current_liabilities).toFixed(2);
    return isValidValue(quick_ratio) ? quick_ratio : '';
};

const calc_debt_equity_ratio = (bs) => {
    if (bs.total_shareholders_funds <= 0) return '';

    const debt_equity_ratio = (
        (bs.long_term_borrowings + bs.short_term_borrowings) /
        bs.total_shareholders_funds
    ).toFixed(2);
    return isValidValue(debt_equity_ratio) ? debt_equity_ratio : '';
};

const calc_roe = (bs, pl) => {
    if (bs.total_shareholders_funds <= 0) {
        return '';
    }
    const roe = ((pl.pat * 100) / bs.total_shareholders_funds).toFixed(2);
	console.log(roe, "roe")
    return isValidValue(roe) ? `${roe}%` : '';
};

const calc_roa = (bs, pl) => {
    if (bs.total_assets <= 0) {
        return '';
    }
    const roa = ((pl.pat * 100) / bs.total_assets).toFixed(2);
    return isValidValue(roa) ? `${roa}%` : '';
};

const calc_asset_turnover_ratio = (bs, pl) => {
    if (bs.total_assets <= 0) {
        return '';
    }
    const asset_turnover_ratio = (pl.operational_income / bs.total_assets).toFixed(2);
    return isValidValue(asset_turnover_ratio) ? asset_turnover_ratio : '';
};

const calc_ebitda_margin = (pl) => {
    const margin = ((pl.ebitda * 100) / pl.total_income).toFixed(2);
    return isValidValue(margin) ? `${margin}%` : '';
};

const calc_pat_margin = (pl) => {
    const margin = ((pl.pat * 100) / pl.total_income).toFixed(2);
    return isValidValue(margin) ? `${margin}%` : '';
};

const calc_contribution_margin = (pl) => {
    const {
        total_income,
        cost_of_materials_consumed = 0,
        purchases_of_stock_in_trade = 0,
        changes_in_inventories = 0,
    } = pl;

    if (total_income > 0 && 
        (cost_of_materials_consumed > 0 || 
         purchases_of_stock_in_trade > 0 || 
         changes_in_inventories > 0)) {    
        
        const margin = (
            ((total_income - cost_of_materials_consumed - purchases_of_stock_in_trade - changes_in_inventories) / total_income) * 100
        ).toFixed(2);
        
        return isValidValue(margin) ? `${margin}%` : ''; // Return the formatted margin string
    } else {
        return ""; // If total_income is zero or undefined, return an empty string
    }
};

const calc_operating_margin = (pl) => {
    const margin = ((((pl.ebt + pl.interest)/ pl.total_income))*100).toFixed(2);
    return isValidValue(margin) ? `${margin}%` : '';
};

const calc_roce = (pl, bs) => {
    const margin = (((pl.ebt + pl.interest)/(bs.total_shareholders_funds + bs.total_non_current_liabilities))*100).toFixed(2);
    return isValidValue(margin) ? `${margin}%` : '';
};

export const fy_sorting_algorithm = (a, b) => {
	// First sort by fy in descending order
	const fyComparison = b.fy.localeCompare(a.fy, undefined, { numeric: true });

	if (fyComparison !== 0) {
		return fyComparison;
	}

	// If fy is the same, sort by fin_type
	if (a.fin_type === 'Consolidated' && b.fin_type === 'Standalone') return -1;
	if (a.fin_type === 'Standalone' && b.fin_type === 'Consolidated') return 1;

	return 0; // If both fy and fin_type are equal
};

export const useGetRatio = (financials) => {
	let ratioObj = {
		Standalone: {
			fy_years: [],
			current_ratio: [],
			quick_ratio: [],
			debt_equity_ratio: [],
			roe: [],
			roa: [],
			asset_turnover_ratio: [],
			ebitda_margin: [],
			pat_margin: [],
			contribution_margin: [],
			operating_margin: [],
			roce:[]
		},
		Consolidated: {
			fy_years: [],
			current_ratio: [],
			quick_ratio: [],
			debt_equity_ratio: [],
			roe: [],
			roa: [],
			asset_turnover_ratio: [],
			ebitda_margin: [],
			pat_margin: [],
			contribution_margin: [],
			operating_margin: [],
			roce:[]
		}
	};

	const collateRatios = (bs) => {
		const pl = financials.profit_loss.find((p) => p.fy == bs.fy && p.fin_type == bs.fin_type);

		const fy = bs.fy;
		const current_ratio = calc_current_ratio(bs);
		const quick_ratio = calc_quick_ratio(bs);
		const debt_equity_ratio = calc_debt_equity_ratio(bs);
		const roe = pl ? calc_roe(bs, pl) : '';
		const roa = pl ? calc_roa(bs, pl) : '';
		const asset_turnover_ratio = pl ? calc_asset_turnover_ratio(bs, pl) : '';
		const ebitda_margin = pl ? calc_ebitda_margin(pl) : '';
		const pat_margin = pl ? calc_pat_margin(pl) : '';
		const contribution_margin = pl ? calc_contribution_margin(pl) : '';
		const operating_margin = pl ? calc_operating_margin(pl) : '';
		const roce = pl ? calc_roce(pl, bs) : '';


		ratioObj[bs.fin_type].fy_years.push(fy);
		ratioObj[bs.fin_type].current_ratio.push(current_ratio);
		ratioObj[bs.fin_type].quick_ratio.push(quick_ratio);
		ratioObj[bs.fin_type].debt_equity_ratio.push(debt_equity_ratio);
		ratioObj[bs.fin_type].roe.push(roe);
		ratioObj[bs.fin_type].roa.push(roa);
		ratioObj[bs.fin_type].asset_turnover_ratio.push(asset_turnover_ratio);
		ratioObj[bs.fin_type].ebitda_margin.push(ebitda_margin);
		ratioObj[bs.fin_type].pat_margin.push(pat_margin);
		ratioObj[bs.fin_type].contribution_margin.push(contribution_margin);
		ratioObj[bs.fin_type].operating_margin.push(operating_margin);
		ratioObj[bs.fin_type].roce.push(roce);


	};

	financials?.balance_sheet?.forEach(collateRatios);
	console.log(ratioObj, 'ratiooo')
	return ratioObj;
}

export const useGetRatioHighlights = (financials) => {
	console.log(financials, "finan for")
	financials.balance_sheet?.sort(fy_sorting_algorithm)
	const GetRatio = (bs) => {
		const pl = financials.profit_loss.find((p) => p.fy == bs.fy && p.fin_type == bs.fin_type);
		return {
			fy: bs.fy,
			current_ratio: calc_current_ratio(bs),
			quick_ratio: calc_quick_ratio(bs),
			debt_equity_ratio: calc_debt_equity_ratio(bs),
			roe: pl ? calc_roe(bs, pl) : '',
			roa: pl ? calc_roa(bs, pl) : '',
			asset_turnover_ratio: pl ? calc_asset_turnover_ratio(bs, pl) : '',
			ebitda_margin: pl ? calc_ebitda_margin(pl) : '',
			pat_margin: pl ? calc_pat_margin(pl) : '',
			contribution_margin: pl ? calc_contribution_margin(pl) : ''
		}

	};
	return financials.balance_sheet.length > 0 ? GetRatio(financials.balance_sheet[0]) : {}
}


export const useLatestFinancialHighlights = (financials) => {
    if (!financials || !financials.profit_loss || financials.profit_loss.length === 0) {
        return {
            fy: "--",
            fin_type: "--",
            total_income: "--",
            ebitda: "--",
            pat: "--"
        }
    }

    financials.profit_loss.sort(fy_sorting_algorithm);
    const PL = financials.profit_loss[0];

    return {
        fy: PL.fy,
        fin_type: PL.fin_type,
        total_income: `₹ ${(PL.total_income / 1e7).toFixed(2)} Cr`,
        ebitda: `₹ ${(PL.ebitda / 1e7).toFixed(2)} Cr`,
        pat: `₹ ${(PL.pat / 1e7).toFixed(2)} Cr`
    };
}
