
import { React, useState, useMemo, useContext } from 'react'
import Table from './Table'
import { TableHeaderContext } from '../../Contexts/TableHeaderContext'

/**
 * The TableComponent function is a React component that renders a table with specified columns,
 * ordering, menu, pagination, and sub_type_master_id props.
 * @returns The TableComponent is being returned.
 */
const TableComponent = (props) => {   

    const { maAdvisorTableHeader, orderBy, maAdvisororderingColumn}= useContext(TableHeaderContext);
    const [pagination, setPagination] = useState()
    const paginationData = useMemo(() => pagination, pagination);   

    const columns = maAdvisorTableHeader

    return (
        <div className="pl-3 pr-2">
            <div className="App">
                <Table order="desc" columns={columns} orderBy={maAdvisororderingColumn} menu={props?.menu} pagination = {paginationData} sub_type_master_id={props?.sub_type_master_id}/>
            </div>
        </div>
    )
}

export default TableComponent 





 





 