import React, {useContext} from "react";
import { Fragment} from "react";
import { Dialog, Transition } from "@headlessui/react";
import alertIcon from "../../images/alert.png";
import reportIcon from "../../images/report.png";
import bellIcon from "../../images/bellicon1.png";
import searchClose from "../../images/search_close_button.png";
import Notify from "./NotifyDeatils";
import Request from "./ReportDetails";
import Alert from "./Alert";
import Header from "../HeaderComponent";
import { PeFilterContext } from "../../Contexts/PeFilterContext";
import { NavLink } from "react-router-dom";
import routePathSegments from "../../routeSegments";

/**
 * The NotificationsSetting function is a React component that manages the state of active
 * notifications and provides a way to update the notification settings.
 * @param props - props is an object that contains the properties passed to the NotificationsSetting
 * component.
 */
const NotificationsSetting = (props) => {

  const [activeNotify, setactiveNotify] = React.useState(1);  
  const {setIsNotification} = useContext(PeFilterContext)

   return (
    <div>
      <Transition.Root show={props.showNot} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={()=>{props.setShowNot(!props.showNot); setIsNotification(false)}} >
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 w-[100px] lg:w-[25rem] overflow-hidden">
            <div className="absolute inset-0  overflow-hidden">
              <div className="pointer-events-none fixed w-[28rem] inset-y-0 right-0 flex max-w-full lg:pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="text-black md:hidden ">
                      {" "}
                      <Header className="" />
                    </div>
                    <div className="flex h-full flex-col overflow-y-hidden  overflow-x-hidden bg-white pt-16 md:pt-5 lg:pt-6 shadow-xl">
                      <div className=" px-4 sm:t-16 ">
                        <div className="flex space-x-2 md:flex justify-between">
                          {/* <Dialog.Title className="text-lg font-medium text-white"> */}
                            {/* <form className="flex items-center w-full ">
                              <label for="simple-search" className="sr-only">
                                Search
                              </label>
                              <div className="relative  w-full">
                                <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                  <img className="w-5 h-5" src={searchIcon} />
                                </div>
                                <input
                                  type="text"
                                  id="simple-search"
                                  className="w-full border border-[#D3D3D3] text-[#989898] focus:outline-[#F1CA75] text-[14px] rounded-[5px] leading-[17px] tracking-[-0.3px] block  pl-10 p-2 "
                                  placeholder="Search"
                                  required=""
                                />
                              </div>
                            </form> */}
                            <h1 className="font-semibold font-sans_bold_body text-[#333333] text-[19px]">Notifications</h1>
                          {/* </Dialog.Title> */}
                          <div className="  flex  items-center">
                            <button
                              type="button"
                              className="flex bg-white  hover:text-gray-500 focus:outline-none "
                              onClick={() => {props.setShowNot(!props.showNot); setIsNotification(false)}} >
                              <span className="sr-only">Close panel</span>
                              <img
                                src={searchClose}
                                className=" h-[26px] w-[27px] "
                                alt="searchClose"
                              />
                            </button>
                          </div>
                        </div>
                        {/* Menu */}
                        <section>
                              <div className="flex flex-col items-stretch justify-between w-full  border-b-[1px] border-[#D3D3D3]">
                                <div className=" flex flex-row items-center mx-auto gap-7">
                                  <a
                                    className={
                                      "cursor-pointer leading-[16px] text-[13px] tracking-[-0.28px] group flex items-center  py-2 space-x-2 " +
                                      (activeNotify === 1
                                        ? "border-b-4 border-[#F1CA75]  font-sans_medium_body text-[#333333] "
                                        : " text-[#888888] font-sans_book_body")
                                    }
                                    onClick={() => {
                                      setactiveNotify(1);
                                    }}
                                    data-toggle="tab"
                                    role="tab1"
                                  >   <img
                                      className=" md:h-[15px] w-[15px] mr-1"
                                      src={reportIcon}
                                      alt="report"
                                    />  
                                    Requests
                                    {/* <span className=" h-4 w-4 bg-[#EDEDED]  rounded-[3px] text-[#FFFFFF]  leading-[13px] text-[11px] flex justify-center items-center items" 
                                       >
                                      <span className="text-[#333333]">8</span>
                                    </span> */}

                                    <span className=" h-4 w-4 rounded-[3px] bg-[#BC8B20] text-[#333333]  leading-[13px] text-[11px] flex justify-center items-center items">
                                      <span className="text-white">{props.notificationData.request.unread_count}</span>
                                    </span>

                                  </a>
                                  <a
                                    className={
                                      "cursor-pointer leading-[16px] text-[13px] tracking-[-0.28px]  group flex items-center  py-2 space-x-2 " +
                                      (activeNotify === 2
                                        ? "border-b-4 border-[#F1CA75]  font-sans_medium_body text-[#333333]"
                                        : "text-[#888888] font-sans_book_body")
                                    }
                                    onClick={() => {
                                      setactiveNotify(2);
                                    }}
                                    data-toggle="tab"
                                    role="tab2"
                                  >
                                    <img
                                      className="text-[#F1CA75] group-hover:text-[#F1CA75]  h-[15px] w-[15px] mr-1"
                                      src={alertIcon}
                                      alt="alert"
                                    />
                                    Alerts
                                    <span className=" h-4 w-4 rounded-[3px] bg-[#BC8B20] text-[#333333]  leading-[13px] text-[11px] flex justify-center items-center items">
                                      <span className="text-white">{props.notificationData.alert.unread_count}</span>
                                    </span>
                                  </a>
                                  <a
                                    className={
                                      "cursor-pointer leading-[16px] text-[13px] tracking-[-0.28px]  group flex items-center  py-2 space-x-2 " +
                                      (activeNotify === 3
                                        ? "border-b-4 border-[#F1CA75]  font-sans_medium_body text-[#333333]"
                                        : "text-[#888888] font-sans_book_body")
                                    }
                                    onClick={() => {
                                      setactiveNotify(3);
                                    }}
                                    data-toggle="tab"
                                    role="tab3"
                                  >
                                    <img
                                      className="text-[#F1CA75]   h-[15px] w-[15px] mr-1"
                                      src={bellIcon}
                                    />
                                    Notifications
                                    <span className=" h-4 w-4 rounded-[3px] bg-[#BC8B20] text-[#333333]  leading-[13px] text-[11px] flex justify-center items-center items">
                                      <span className="text-white">{props.notificationData.general.unread_count}</span>
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </section>
                      </div>
                      <div className="relative flex-1 px-4 sm:px-6 overflow-y-auto">
                        <div className="absolute inset-0 px-4 sm:px-6">
                          <div className="absolute  inset-0  sm:px-0">
                            {/* reports alerts notification */}
                            
                            {/* Notificationpage */}

                            {/* Menu Condition */}
                            <div>
                              <div className={activeNotify === 1 ? "block" : "hidden"}>
                                <div className="overflow-y-hidden">
                                  <Request
                                  handleNotificationClick={props.handleNotificationClick}
                                  notificationData={props.notificationData.request} />
                                </div>
                              </div>
                              <div className={ activeNotify === 2 ? "block" : "hidden"}>
                                <div className="overflow-y-hidden">
                                  <Alert 
                                    handleNotificationClick={props.handleNotificationClick}
                                    notificationData={props.notificationData}
                                />
                                </div>
                              </div>
                              <div className={ activeNotify === 3 ? "block" : "hidden"}>
                                <div className="overflow-hidden">
                                  <Notify
                                   handleNotificationClick={props.handleNotificationClick}
                                   notificationData={props.notificationData.general}
                                    />
                                    {/* <Report
                                  handleNotificationClick={props.handleNotificationClick}
                                  notificationData={props.notificationData.general} /> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <center className="border-2 border-t-[#E5E5E5]">
                        <div className="bg-white py-2 z-10">
                          <NavLink to={activeNotify===1 ? routePathSegments.requests : activeNotify===2 ? routePathSegments.alerts : routePathSegments.notifications } state={{from:activeNotify}} className="my-1">
                            <span className="font-sans_bold_body px-4 py-2 text-sm font-medium text-[#BC8B20] focus:outline-none mx-auto w-fit">View All</span>
                          </NavLink>
                        </div>
                      </center>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default NotificationsSetting;
