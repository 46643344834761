import { React, useState, useEffect, useCallback, useContext } from "react";
import { useParams } from "react-router-dom";
import FilingsFinancialTable from '../CFS/DetailsPage/CFSFinancial/FilingsFinancialTable'


/**
 * The component  displays filing data for a company, with the
 * option to show all filings or only the first five.
 * The FilingsFinancialTable component contains a table of
 * filings data. The table is conditionally rendered based on the isLoading and fillingsData variables.
 * If isLoading is true, a loader component is displayed. If fillingsData is empty or the companyName
 * is null or empty, a message is displayed indicating that the data is not found. Otherwise, the table
 * is rendered 
 */
const FinancialTabs = ({ companyName }) => {

  return (
    <>
      <div className="space-y-6 px-4 py-3 bg-[#EDEDED]">
        <div className=" bg-white border  overflow-hidden sm:rounded-lg shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
          <div className="py-3 px-5  border-b border-b-[#F1F1F1]">
            <div className="mt-3 lg:mt-0 flex justify-between ">
              <h3 className="text-[14pt] leading-[19pt] text-[#333333] lg:text-[17px] font-semibold  lg:leading-[22px] lg:mt-3">
                Filings
              </h3>
            </div>
          </div>
          <FilingsFinancialTable companyName={companyName} showAll/>
        </div>
      </div>
    </>
  );
};

export default FinancialTabs;
