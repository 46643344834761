import React, { useState, useEffect, useContext, Fragment,useRef } from "react";
import { Disclosure, Menu } from '@headlessui/react'
import { HiChevronUp, HiChevronDown } from "react-icons/hi";
import delIcon from '../../images/Combined Shape_6/png/Combined Shape.png';
import NotesDetailView from "./NotesDetailView";
import { listNotesApi,notesOptionsApi } from '../../Config/config'
import axios from 'axios'
import Loader from "../Loader";
import CompanyLogo from "../CompanyLogo";
import RandomLogo from "../RandomLogo";
import { UserContext } from "../../Contexts/UserContext";
import '../../index.css'
import moment from 'moment';
import useModule from "../../hooks/useModule";
import ConfirmPopup from "../../Utils/ConfirmPopup";
import { toast } from "react-toastify";
import { singleNoteApi, deleteNoteApi, updateNotesApi } from '../../Config/config';
import editIcon from '../../images/combined-shape.png';
import { Dialog, Transition } from '@headlessui/react';
import MyStatefulEditor from "../../Components/NotesPopup/Rtetest"
import PrimaryButton from '../shared/PrimaryButton';
import OutlineButton from '../shared/OutlineButton';
import {getTextAlignStyles} from "react-rte/lib/lib/blockStyleFunctions";

/**
 * The above code is a JavaScript function that manages a list of notes. It uses various state
 * variables to keep track of the current open tab, single note open, list of notes, loading status,
 * refresh count, selected option, alert message, popup visibility, note value, and more. It also
 * includes functions for toggling the disclosure of notes, fetching the list of notes, deleting a
 * note, confirming note deletion, updating a note, and fetching the details of a single note. The code
 * also includes a ref for calculating the height of a panel.
 */
const Notes = () => {
  
  const [openTab, setOpenTab] = useState();
  const [singleNoteOpen, setSingleNoteOpen] = useState();  
  const [listNotes, setListNotes] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [refreshCount, setrefreshCount] = useState(0);
  const {getToken} = useContext(UserContext);
  const [moduleValues, setModuleValues] =useState([])
  const [selectedOption, setselectedOption] = useState()
  const {allowedModulesList} = useModule()
  const [alert, setalert] = useState({ show: false, message: "", note: {} });
  const [showPopup, setShowPopup] = useState(false);
  const [open, setOpen] = useState(false);
  const [Note, setNote] = useState([]);
  const [noteValue, setnoteValue] = useState("");
  const [isLoad, setIsLoad] = useState(false);
  const [openDisclosureIndex, setOpenDisclosureIndex] = useState("");
  const [rawValue, setrawValue] = useState();
  const [val, setVal] = useState('');
  const [isOpen, setIsOpen] = useState(false);


 /**
  * The function `toggleDisclosure` toggles the open state of a disclosure element identified by its
  * `id`.
  * @param id - The `id` parameter is the identifier of the disclosure element that needs to be
  * toggled.
  */
  const toggleDisclosure = (id) => {
    setOpenDisclosureIndex((prevIndex) => (prevIndex === id ? "" : id));
  };

  //  ------Webclamp---------
  // const [truncate, setToggleTruncate] = React.useState(true);
  // const textStyle = {
  //   maxWidth: '310px',
  //   display: '-webkit-box',
  //   WebkitBoxOrient: 'vertical',
  //   WebkitLineClamp: 2,
  //   overflow: 'hidden',
  //   textOverflow: 'ellipsis',
  // };
  // function toggleTruncate() {
  //   setToggleTruncate(!truncate);
  // }

  // function calculateTextStyle() {
  //   return truncate ? textStyle : null;
  // }
 /**
  * The function `notesOption` makes a POST request to an API endpoint, passing the necessary headers
  * and authorization token, and then sets the module values based on the response data if the status
  * is 200.
  */
 const notesOption = ()=>{
  axios(`${notesOptionsApi}`, {
    method: "POST",    
    headers: {
      "Content-Type": "application/json",
      "Authorization": getToken()
    },
  })
    .then((response) => {
      if(response?.data?.status === 200){
        setModuleValues(response?.data?.notesOptions)
      }
    })
    .catch((error) => {
      console.log("API not working", error);
    });
 }
  useEffect(()=>{
    notesOption()
  },[])
  
  useEffect(() => {    
    noteList()
  }, [selectedOption?.value, refreshCount]);

  useEffect(()=>{    
    setselectedOption(moduleValues?.at(0))
  },[moduleValues])
  
 /**
  * The `noteList` function makes a POST request to a specified API endpoint, passing in a noteable_id
  * value and authorization token, and then handles the response by setting state variables and logging
  * the response.
  */
  const noteList = () => {
    setisLoading(true);
    axios(`${listNotesApi}`, {
      method: "POST",
      data: {
        "noteable_id": selectedOption?.value,
      },
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken()
      },
    })
      .then((response) => {
        setisLoading(false);
        console.log(response, 'List Note response');
        if (response?.data?.status === 200) {
          setListNotes(response?.data)
          setOpenTab(response?.data?.notes[0]?.id)
          setSingleNoteOpen(response?.data?.notes[0]?.id)
        }
        else {
          setListNotes(response?.data?.message)
        }
      })
      .catch((error) => {
        console.log("API not working", error);
      });

  }

/**
 * The `deleteNote` function sends a POST request to a specified API endpoint to delete a note, and
 * updates the UI accordingly.
 * @param note - The `note` parameter is an object that contains the following properties:
 */
  const deleteNote = (note) => {

    axios(`${deleteNoteApi}`, {
        method: "POST",
        data: {
            "noteable_id": note?.noteable_id,
            "noteable_sub_type_id": note?.noteable_sub_type_id,
            "notes_id": note?.id
        },
        headers: {
            "Content-Type": "application/json",
            "Authorization": getToken()
        },
    })
        .then((response) => {
            if (response?.data?.status === 200) {
              setrefreshCount(prev => prev + 1)
                toast.success(response?.data?.message)
            }
            else {
                toast.error(response?.data?.message)
            }
        })
        .catch((error) => {
            console.log("API not working", error);
        });

}

 /**
  * The DeleteNotes function sets an alert with a message asking the user if they want to delete a
  * specific note.
  * @param note - The "note" parameter is the note object that you want to delete. It contains the
  * information about the note, such as its title, content, and any other relevant details.
  */
  const DeleteNotes = (note) => {
    setalert({ show: true, message: "Are you sure you want to delete this note?", note: note })
}

/**
 * The function `onConfirmation` checks if the flag is equal to 1, and if so, it deletes a note and
 * resets the alert state.
 * @param flag - The `flag` parameter is a variable that is used to determine the condition inside the
 * `if` statement. It is compared to the value `1` to check if it is equal.
 */
const onConfirmation = (flag) => {
  if (flag === 1) {
      deleteNote(alert.note)
  }
  setalert({ show: false, message: "", note: {} })
}

/**
 * The function `onUpdateNotes` updates the state variables `open` and `alert` based on the value of
 * the `flag` parameter.
 * @param flag - The `flag` parameter is a variable that is used to determine the behavior of the
 * `onUpdateNotes` function. It is expected to be a number, specifically `1` in this case.
 */
const onUpdateNotes = (flag) => {
  if (flag === 1) {
    setOpen(!open)
  }
  setalert({ show: false, message: "" });
}

/**
 * The `onChange` function takes a value as input and updates the `val` and `rawValue` variables based
 * on the value's HTML representation.
 * @param value - The `value` parameter in the `onChange` function is the updated value of the editor
 * content. It is typically an object that represents the state of the editor, including the current
 * content, selection, and other properties. In this case, it seems that the `value` object is an
 * instance of
 */
const onChange = (value) => {
  // console.log("valuevaluevaluevaluevalue", value?.getEditorState()?.getCurrentContent()?.hasText(), !!value.getEditorState().getCurrentContent().getPlainText().trim());
  setVal(value.toString("html", {
    blockStyleFn: getTextAlignStyles,
 }));
  setrawValue(value)
};

// const onClose =()=>{
//   setalert({ show: true, message: "Are you sure you want to leave?" })
// }
/**
 * The function `validate` checks if a text input is empty and displays a warning message if it is.
 * @returns a boolean value. If the notes are not empty, it will return true. Otherwise, it will return
 * false.
 */

const validate = () => {
  let isEmpty = !!rawValue?.getEditorState()?.getCurrentContent()?.getPlainText()?.trim();
  if (!isEmpty) {
      toast.warning("Notes must not be empty");
      return false;
  }
  return true;
}
/**
 * The `updateNote` function sends a POST request to update a note with the provided content and
 * primary type master ID.
 * @param note - The `note` parameter is an object that contains information about the note. It has the
 * following properties:
 * @param primary_type_master_id - The `primary_type_master_id` parameter is the ID of the primary type
 * master for the note. It is used to specify the primary type of the note when updating it.
 * @returns The function `updateNote` does not explicitly return a value.
 */

const updateNote = (note,primary_type_master_id) => {
  if (!validate()) {
      return;
  }
  axios(updateNotesApi, {
      method: "POST",
      data: {
          "noteable_id": note.noteable_id,
          "noteable_sub_type_id": note.noteable_sub_type_id,
          "content": val,
          "primary_type_master_id":primary_type_master_id
      },
      headers:{
          "Authorization": getToken()
      }
  })
      .then((response) => {
          if (response?.data?.success === true) {
            setrefreshCount(prev => prev + 1);
            toast.success("The notes has been updated");
            setShowPopup(false);
          }
          else {
              toast.warning(response?.data?.message)
          }
      })
      .catch((error) => {
          console.log("API not working", error);
      });
}

/**
 * The function `singleNoteDetail` is used to fetch a single note's details from an API and perform
 * different actions based on the `type` parameter.
 * @param note_id - The `note_id` parameter is the ID of the note that you want to retrieve the details
 * for. It is used to identify the specific note in the API request.
 * @param type - The "type" parameter is used to determine the action to be performed after retrieving
 * the single note detail. It can have two possible values: "edit" or any other value. If the type is
 * "edit", the note content is set as the value for editing, and a popup is opened.
 */
const singleNoteDetail = (note_id,type) => {
  setIsLoad(false)
  axios(`${singleNoteApi}`, {
      method: "POST",
      data: {
          "notes_id": note_id
      },
      headers: {
          "Content-Type": "application/json",
          "Authorization": getToken()
      },
  })
      .then((response) => {
          // console.log(response, 'single note List response');

          if (response?.data?.status === 200) {
            // console.log(response?.data, 'single note list')
            if(type === "edit"){
                setnoteValue(response?.data?.notes[0]?.content.toString("html", {
                  blockStyleFn: getTextAlignStyles,
               }));
                setOpen(!open);
                setShowPopup(true);
              }else{
                setnoteValue(response?.data?.notes[0]?.content);
                setIsLoad(true);
              }
          }
      })
      .catch((error) => {
          console.log("API not working", error);
      });

}
/* The above code is written in JavaScript and it is using the useRef hook from React. It declares a
constant variable called panelRef and assigns it the value of useRef(null). The useRef hook is used
to create a mutable reference that can be used to store a value that persists across renders. In
this case, panelRef is being used to reference a DOM element, which is initially set to null. */

const panelRef = useRef(null);

  useEffect(() => {
    if (panelRef.current) {
      // Calculate the content height
      const contentHeight = panelRef.current.scrollHeight;
      
      // Set the calculated content height
      panelRef.current.style.height = `${contentHeight}px`;
      console.log(noteValue,"noteValue")
    }
  }, [noteValue]);


  return (
    <>
     <ConfirmPopup show={alert.show} message={alert.message} 
     onChange={(e) =>{
      if(alert.message.includes("delete")){
        onConfirmation(e)
      }else {
        onUpdateNotes(e);
      }
     }} />
     {showPopup && 
      
      <Transition.Root show={open} as={Fragment}    onClose={() => null} >
          <Dialog as="div" className="relative z-10" 
          onClose={() => null}
          onClick={() => setOpen(false)}
          >
            <Transition.Child
              as={Fragment}
              onClose={() => null}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0  z-10 lg:overflow-hidden	overflow-y-auto">
              <div className="mt-[8rem] min-h-full  p-4">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="addnote rounded-lg bg-white  shadow-xl  md:ml-[4.75rem] md:mr-[2.5rem] lg:ml-[7.25rem] lg:mr-[3.25rem]"  >
                    <div className='flex pt-3  py-1 justify-between px-4'>
                      <div className='px-2 md:px-0 pt-1 text-lg font-medium leading-6 text-gray-900 '>
                        Update Notes
                      </div>
                      <div className='flex space-x-3 pt-1'>
                        <div>
                          <button type="button"
                            onClick={() => {
                              setOpen(!open);
                              setShowPopup(false);
                            }}
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none ">
                            <span className="sr-only">Close</span>
                            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="text-left py-1 px-4">
                    <MyStatefulEditor className="wrapper-class " markup={noteValue ?? ""}  
                      onChange={onChange}
                      />
                    </div>
                    <div className='bg-gray-50 py-3 flex flex-row-reverse px-6 z-[1] relative rounded-lg'>                     
                      <PrimaryButton
                        onClick={() => updateNote(Note,Note.primary_type_master_id)}>
                          Update
                      </PrimaryButton>
                      <OutlineButton onClick={() => {setOpen(!open);setShowPopup(false)}}>
                        Close
                      </OutlineButton>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>

            </div>
          </Dialog>
        </Transition.Root>}
    <div>
      <div className="hidden lg:block fixed w-screen pb-[3.3rem] pr-[5.2rem] h-screen">
        <div className="flex w-full h-full">
          <div className="w-[25%] flex bg-[#FAF5EA] flex-col border">
            <p className=" px-3 mb-3  mt-3 font-sans_medium_body text-[#2B2A29] text-[18px] text-left tracking-[-0.39px] leading-[22px] ">
              Notes
            </p>
            <Menu as="div" className="relative inline-block text-left w-full">
              <Menu.Button className="hidden lg:text-left w-full lg:flex justify-between items-start text-[#2B2A29] focus:outline-noene">
                {({ open }) => (
                  <>
                    <span className="text-[#2B2A29] flex text-[14px] leading-[18pt] tracking-[-0.32pt] lg:tracking-[-0.39px] lg:leading-[22px] lg:text-[14px] mt-1 md:pl-0  mb-2 mx-3 cursor-pointer">
                      {selectedOption?.title}
                    </span>
                    {allowedModulesList.includes('cfs') && !allowedModulesList.includes('vc') && !allowedModulesList.includes('pe') && !allowedModulesList.includes('ma') && !allowedModulesList.includes('re')  ? <></> 
                    :                  
                    <div className="hidden lg:flex space-x-2 mt-1 mr-2  ">
                      {open ? (
                        <HiChevronUp className="h-6 w-5 text-[#2B2A29] " aria-hidden="true" />
                      ) : (
                        <HiChevronDown className="h-6 w-5 text-[#2B2A29] " aria-hidden="true" />
                      )}
                    </div>
                    }
                  </>
                )}
              </Menu.Button>
              {allowedModulesList.includes('cfs') && !allowedModulesList.includes('vc') && !allowedModulesList.includes('pe') && !allowedModulesList.includes('ma') && !allowedModulesList.includes('re')  ? <></>
              :

              <Menu.Items className="absolute  left-0  w-60 pb-2 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {
                  moduleValues?.map(items => (
                    <Menu.Item>
                      {({ close }) => (
                        <li
                          onClick={() => {
                            setselectedOption(items)
                            close();
                          }}
                          key={items?.title}
                          className={`${selectedOption?.value === items?.value ? "bg-[#BC8B20] text-white  hover:bg-[#BC8B20]" : " hover:bg-gray-100"} block py-1 px-4 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer`}>
                          <span className="px-2 whitespace-nowrap font-sans_medium_body text-[13px]">{items?.title}</span>
                        </li>
                      )}
                    </Menu.Item>
                  ))
                }
              </Menu.Items>}

            </Menu>
              <div className="h-full overflow-y-auto">             
                <div className=" lg:flex lg:border-b"></div>
                {!isLoading ?
                  listNotes?.notes?.length !== 0 ?
                    listNotes?.notes?.map((note, i) => {
                      const formatedDate = moment(new Date(note.created_at));
                      console.log(note,'note id')              
                      return (
                        <div className=" cursor-pointer border-b bg-[#FFF9EC] lg:flex w-full block" key={note?.id} >
                          <div className={"items-center  py-3 " +
                            (openTab === note?.id
                              ? "border-r-[3px] border-[#F1CA75] bg-white text-[#F1CA75] w-full"
                              : "yellow-500 text-[#333333] w-full")
                          }
                            onClick={e => {
                              e.preventDefault();
                              setOpenTab(note?.id);
                              setSingleNoteOpen(note?.id)
                              console.log(openTab, 'onclick open tab');
                            }}
                            data-toggle="tab"
                            href="#link1"
                            role="tab1">

                            {/* <NavLink to=""> */}
                            {/* <a className=''> */}
                            <div className='flex items-center'>
                              {/* <img className="flex-shrink-0 mx-2 mr-1 h-[24px] w-[24px] rounded-full text-[#F1CA75]" src={barRaiserImg} alt="" /> */}
                              <span className="ml-2 flex items-center">
                              {note?.logo_path ? 
                                <CompanyLogo name={note?.entity_name} id={note?.id} page="company page" logo={note?.logo_path} />
                                :
                                <RandomLogo name={note.entity_name} />
                              }
                                </span>
                              <span className="font-sans_medium_body text-left text-[#333333] text-[15px] tracking-[-0.32px] leading-[18px]">
                                {note.entity_name}
                              </span>
                            </div>
                            <div className='mt-1 px-2 w-full'>
                              <p
                                dangerouslySetInnerHTML={{ __html: note.content }} className="  font-sans_book_body  text-left text-[#333333] text-[13px] tracking-[-0.28px] leading-[17px]" />
                              <p className=" font-sans_book_body text-left text-[#888888] text-[13px] tracking-[-0.28px] leading-[17px] py-2 pb-1">
                                {formatedDate?.isValid() ? formatedDate.format('DD MMM yyyy hh:mm A') :''}
                              </p>
                            </div>
                            {/* </a> */}
                            {/* </NavLink> */}

                          </div>
                        </div>
                      )
                    })
                    : <div className="h-full pt-[3rem] w-full text-center">No Notes Found</div>
                  : <div className="w-full h-full pt-[3rem]">
                    <center>
                      <Loader />
                    </center>
                  </div>
                }
              </div>
          </div>
          <div className="grow w-[75%]">
            <div className={openTab === singleNoteOpen ? "block h-full" : "hidden"} id="link1">
              <NotesDetailView notes_id={singleNoteOpen} onRefresh={() => setrefreshCount(prev => prev + 1)} />
            </div>
          </div>
        </div>
      </div>


      {/* ---------Mobile view------------ */}

      <div className="bg-[#F5F5F5] lg:hidden h-[calc(100vh_-_3rem)] pb-16">
        <div className="grid grid-cols-2 gap-4 lg:hidden">
          <div
            className="px-4 mt-5  font-sans_medium_body text-[#2B2A29] text-[18px] text-left tracking-[-0.39pt] leading-[22pt]  ">
            Notes</div>
          <div className="col-end-7 col-span-3 mt-4 mr-[1.7rem] md:mr-[2.3rem]">
            <div className="flex">              
              <div className="relative py-0 ">
                <div
                  className=" justify-between  flex  text-[#2B2A29]  font-sans_book_body  pt-1 ">
                  <div className="flex ">
                    <dl className=" space-y-2 divide-y-2  divide-gray-200">                      
                      <Menu as="div" className="relative inline-block text-left">
                      <Menu.Button className="flex lg:hidden items-start text-[#2B2A29]">
                      {({ open }) => (
                        <>
                          <span className="text-[#2B2A29] flex-shrink-0 text-[14px] leading-[18pt] tracking-[-0.32pt] mt-1 md:pl-0 mb-2 mx-3 whitespace-nowrap">
                            {selectedOption?.title}
                          </span>
                          {allowedModulesList.includes('cfs') && !allowedModulesList.includes('vc') && !allowedModulesList.includes('pe') && !allowedModulesList.includes('ma') && !allowedModulesList.includes('re') ? (
                            <></>
                          ) : (
                            <div className="lg:hidden flex-shrink-0 flex space-x-2 mt-1 mr-0 md:-mr-[.5rem]">
                              {open ? (
                                <HiChevronUp className="h-6 w-5 text-[#2B2A29] " aria-hidden="true" />
                              ) : (
                                <HiChevronDown className="h-6 w-5 text-[#2B2A29] " aria-hidden="true" />
                              )}
                            </div>
                          )}
                        </>
                      )}
                    </Menu.Button>

                        {allowedModulesList.includes('cfs') && !allowedModulesList.includes('vc') && !allowedModulesList.includes('pe') && !allowedModulesList.includes('ma') && !allowedModulesList.includes('re')  ? <></>
                        :
                        <Menu.Items className="absolute right-0 w-40 md:w-48 pb-2 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10 overflow-y-auto md:h-[10rem] md:-right-[.8rem]">
                          {
                            moduleValues?.map(items => (
                              <Menu.Item>
                                {({ close }) => (
                                  <li
                                    onClick={() => {
                                      setselectedOption(items)
                                      close();
                                    }}
                                    key={items?.title}
                                    className={`${selectedOption?.value === items?.value ? "bg-[#BC8B20] text-white  hover:bg-[#BC8B20]" : " hover:bg-gray-100"} block py-1 px-4 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer`}>
                                    <span className="px-2 whitespace-nowrap font-sans_medium_body text-[13px]">{items?.title}</span>
                                  </li>
                                )}
                              </Menu.Item>
                            ))
                          }
                        </Menu.Items>}
                      </Menu>
                    </dl>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
          {/* <!-- card1------------- --> */}
         <div className="h-full overflow-y-scroll overflow-x-hidden">
         
        {!isLoading ?
          listNotes?.notes?.length !== 0 ?
            listNotes?.notes?.map((note, i) => {
              const formatedDate = moment(new Date(note.created_at));
              console.log(note,"lll")
              return ( 
                <div>
                  <div className="lg:hidden bg-white mx-4 px-3 py-4 mt-3">
                    <div className="flex-shrink-0 self-center flex">
                      <div className="min-w-0 flex-1 mt-1">
                        <div className="flex space-x-2">
                          <div className="flex-shrink-0">
                            {note?.logo_path ? 
                                <CompanyLogo name={note?.entity_name} id={note?.id} page="company page" logo={note?.logo_path} />
                                :
                                <RandomLogo name={note.entity_name} />
                              }
                          </div>
                          <div className="">
                            <p className=" font-sans_medium_body text-left text-[#333333] text-[16px] tracking-[-0.34px] leading-[19px] mt-1 whitespace-nowrap">{note.entity_name}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="relative  inline-block text-left">
                        <dl className=" flex justify-between font-sans_book_body ">
                            <Disclosure as="div" key={note?.id} className=" ">
                              {({ open }) => (
                                <>
                                  <div className="mt-2">
                                    <Disclosure.Button className="text-left w-full flex justify-between items-start text-[#2B2A29] md:flex md:place-content-end">
                                      <span className={"text-[#2B2A29] text-[14px]  leading-[18pt] tracking-[-0.32pt] lg:tracking-[-0.39px] lg:leading-[22px] lg:text-[14px]  mb-3 " }
                                        onClick={() => {
                                          toggleDisclosure(note?.id)
                                          setOpenTab(note?.id);
                                          setIsOpen(open);
                                          if(!open){
                                            setnoteValue("")
                                            singleNoteDetail(note?.id,"toggle");
                                          }
                                        }}
                                      >
                                        {open && openDisclosureIndex === note?.id ? (
                                          <HiChevronUp className="h-5 w-full flex justify-between  text-[#2B2A29] " aria-hidden="true" />
                                        ) : (
                                          <HiChevronDown className="h-5 w-full  flex justify-between  text-[#2B2A29]" aria-hidden="true" />
                                        )}
                                      </span>
                                    </Disclosure.Button>
                                  </div>
                                  
                                </>
                              )}
                            </Disclosure>
                        </dl>
                      </div>
                    </div>
                    <div className='text-left bg-white text-[#2B2A29] text-[14px]  font-sans_book_body'>
                    <div key={note?.id} className={`${openDisclosureIndex === note?.id ? 'hidden' : 'block'} `} dangerouslySetInnerHTML={{ __html: note?.content }} />
                    {!isOpen && openDisclosureIndex === note?.id && (
                                  // <Disclosure.Panel as="div" className=" -mx-3 text-left bg-white text-[#2B2A29] text-[14px]" >
                                  isLoad ?
                                   <div className="border-[1px] border-[#e5e7eb] h-[12rem] overflow-y-auto scrollbar-remove">
                                       <p dangerouslySetInnerHTML={{ __html: noteValue }} className="text-left md:text-start md:mt-0 indent-4 py-[10px] pl-3 " /> 
                                    </div>
                                    : 
                                    <center>
                                      <Loader sizeClassName="w-8 h-8"/>
                                    </center>
                                  // {/* </Disclosure.Panel> */}
                                  )
                                  }
                    </div>
                    <div className="flex-shrink-0 self-center mt-2 flex">
                      <div className="min-w-0 flex-1 mt-1">
                        <p
                          className=" text-[13px] font-sans_book_body text-left text-[#888888] tracking-[-0.28px] leading-[16px]">
                          {formatedDate?.isValid() ? formatedDate.format('DD MMM yyyy hh:mm A') :''}</p>
                      </div>
                      <div className="relative  mt-1 inline-block text-left" >
                        <img alt="" src={editIcon} className="h-[18px] cursor-pointer mr-4" 
                        onClick={() => { 
                          setNote(note);
                          singleNoteDetail(note?.id,"edit");
                          }}/>
                      </div>
                      <div className="relative  mt-1 inline-block text-left" >
                        <img src={delIcon} className="h-[18px] cursor-pointer" alt="" onClick={() => { DeleteNotes(note) }}/>
                      </div>
                        
                    </div>
                  </div>
                </div>
              )
          })
          : <div className="h-full pt-[3rem] w-full text-center">No Notes Found</div>
        :
        <div className="w-full h-full pt-[3rem]">
          <center>
            <Loader />
          </center>
        </div>
        }
         
        </div>
              </div>
    </div>
    </>
  )
}

export default Notes
