import React from 'react'
import { Link, NavLink, useNavigate } from "react-router-dom";
import { routeNames } from '../../routeSegments';


/**
 * The LimitedPartBreadCrum function is a React component that renders a breadcrumb navigation for a
 * specific submenu.
 * @returns The LimitedPartBreadCrum component is returning a JSX element.
 */
const LimitedPartBreadCrum = (props) => {
  return (
    <div className={props.subMenu=="requests"?' rounded-t-[6px]'
                   :"pl-3 md:pr-4 pr-3"}
    >
        <nav className="flex p-1 border-b bg-white rounded-t-[6px] pl-3 " aria-label="Breadcrumb">
                    <ol role="list" className="flex items-center space-x-1">
                        <li className='flex items-center'>
                            <div className='flex items-center'>
                            <NavLink
                             to={
                                props?.menu =="investorvc"? routeNames.vc_investors_vc_investments
                                :props?.menu =="advisor"? routeNames.pe_advisors_pe_vc_advisors 
                                :props?.menu =="advisorvc"? routeNames.vc_advisors_vc_advisors
                                :props?.menu =="tadvisor"? routeNames.pe_transaction_advisors_pe_vc_advisors    
                                :props?.menu =="tadvisorvc"? routeNames.vc_transaction_advisors_vc_advisors 
                                :props?.menu =="requests" ? routeNames.pe_companies_pe_vc_investments
                                :props?.menu == "detailrequest" ? routeNames.pe_investors_pe_vc_limited_partners
                                :routeNames.pe_investors_pe_vc_investments} className="ml-2 text-[11pt] lg:text-[12px] leading-[14px] text-[#666666] font-sans_book_body">
                                    <span className="">
                                            {props.subMenu == "vc" ? "Investors" 
                                            :props.subMenu == "advisor"? "Advisor"
                                            :props.subMenu == "tadvisor"? "Transaction-Advisor"
                                            :props.subMenu == "requests"? "Home"
                                            :props.subMenu == "detailRequest" ? "limitedpartner"
                                            :""}
                                        </span>
                                     
                                </NavLink>
                            </div>
                        </li>
                        <li>
                            <div className="flex items-center">
                                <span className=" text-[12px] text-[#666666] ">/</span>
                                <a href="#" className="text-[11pt] lg:text-[12px] pl-1  text-[#666666] leading-[14px] font-sans_book_body font-semibold"
                                    aria-current="page">
                                        {props.subMenu =="requests"?"Requests"
                                        :"Limited Partners"
                                        }
                                       
                                      </a>
                            </div>
                        </li>
                        {/* <li>
                            <div className="flex items-center">
                                <span className=" text-[12px] text-[#666666] ">/</span>
                                <a href="#" className="text-[11pt] lg:text-[12px] pl-1  text-[#666666] leading-[14px] font-sans_book_body font-semibold"
                                    aria-current="page">
                                        {props.subMenu =="detailRequest"?"DetailRequest"
                                        :"Limited Partners"
                                        }
                                       
                                      </a>
                            </div>
                        </li> */}
                    </ol>
                </nav>
    </div>
  )
}

export default LimitedPartBreadCrum