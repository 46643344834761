import React, { useContext} from 'react';
import InvestmentDealCard from './DealCardInvestment'
import ExitDealCard from './DealCardExit'
import BackedIpoCard from "./DealCardBackedIpo"
import AngelDealCard from './DealCardAngel'
import IncubationDealCard from './DealCardIncubation'
import { TableDataContext } from '../../Contexts/TableDataContext'



/**
 * The `Dealcard` function renders a specific type of deal card based on the active tab selected.
 * @returns JSX element.
 */
const Dealcard = () => {
  const { activeTabDeal, setActiveTabDeal } = useContext(TableDataContext);

 
  return (

    <div className="">
      <div className='sm:full md:mt-[3rem] mt-[4.25rem] h-full bg-[#F2F2F2]'>
        {activeTabDeal === "investments" ?
          <InvestmentDealCard />
          : activeTabDeal === "exits" ?
            <ExitDealCard />
            : activeTabDeal === "angels" ?
              <AngelDealCard />
              : activeTabDeal === "incubations" ?
                <IncubationDealCard />
                :
                <BackedIpoCard />
         
        }

      </div>
      <div className='border-b-2 border-[#e5e5e5]'></div>
      {/* </div > */}
    </div >
  )
}

export default Dealcard;