import React, { useState, useEffect, useContext } from "react"
import { Link, NavLink, useNavigate } from 'react-router-dom';
import MonthPickerComponent from '../../Utils/MonthPickerComponent'
import { TableDownloadContext } from '../../Contexts/TableDownloadContext'
import moment from "moment"
import line from "../../images/line.png"
import xls from "../../images/xls/png/Group 47.png"
import FileIcon from "../../images/file.png"
import FileIconBlur from "../../images/fileblur.png"
import ExcelPopup from "../../Components/ExcelPopup";
import '../MainMenu.css'
import { routeNames } from "../../routeSegments";
import Switch from '../../Components/CFS/DetailsPage/CFSFinancial/ToggleSwitch'
import ExportPopup from "../../Components/ExportPopup/FundsExcel";
import { data, limitedPartnerProfileExportData, limitedPartnerexitExportData } from "../../Components/ExportPopup/ExportData"
import { exportLPExcel } from "../../Config/config";
import { TableDataContext } from '../../Contexts/TableDataContext'
import { UserContext } from "../../Contexts/UserContext";
import { useLocation } from "react-router";
import { PeFilterContext } from "../../Contexts/PeFilterContext";

function MainMenu(props) {
    const { handleDownloadExcel } = useContext(TableDownloadContext)
    const {totalNoOfCompanies, setTotalNoOfCompanies } = useContext(TableDataContext);
    const {refineSearchFilters,groupId} =useContext(PeFilterContext)
    const [openTab, setOpenTab] = React.useState(1);
    const [showPopup, setShowPopup] = useState(false);
    const { pathname } = useLocation(); 
    const [date, setDate] = useState([
        moment().startOf("month").subtract(12, "months").format("YYYY-MM-DD"),
        moment().endOf("month").format("YYYY-MM-DD"),
      ]);
      const { isStudent,isTrialExport} = useContext(UserContext)

    useEffect(() => {
        setOpenTab(props.active)
    }, [openTab])
 

    /**
     * The function `download` calls the `handleDownloadExcel` function.
     */
    const download = () => {
        handleDownloadExcel() 
    }
   
    const isFromGlobalSearch = ()=> refineSearchFilters[pathname]?.globalSearch?.length > 0 ? true : false


    return (
        <div className="pt-0 pl-3 md:pr-4 pr-3">

            <div className="scrollbar  rounded">
                <div className="px-2 items-center rounded-b-none bg-white block lg:flex flex-row justify-between shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
                    {
                        props.menu == "investor" ? (
                            <div className="px-2 flex flex-row space-x-5 overflow-x-auto lg:overflow-hidden scrollbar-remove">
                                <div className="py-4 ">
                                    <NavLink className={"whitespace-nowrap lg:text-[14px] lg:leading-[17px] text-[12pt] leading-[17pt]" +
                                        (openTab === 1 ? " font-semibold text-[#2B2A29]  border-b-[3px] border-[#F1CA75] py-4 pt-[1.1rem]"
                                            : "whitespace-nowrap font-sans_medium_body text-[#333333] opacity-[.4] hover:opacity-100 ")
                                    }
                                        data-toggle="tab"
                                        to={props.menu == "vcinvestor" ? routeNames.vc_investors_vc_limited_partners : routeNames.pe_investors_pe_vc_limited_partners}
                                        role="tablist"
                                    >
                                        <div className="group inline-block">
                                            <button
                                                className="outline-none w-36 focus:outline-none py-1 bg-white rounded-sm flex items-center "
                                            >
                                                <span className=" flex-1 text-[14px] leading-[15px]">
                                                    {props.menu == "vcinvestor" ? "VC Investments" : "PE-VC Investments"}
                                                </span>

                                            </button>

                                        </div>
                                    </NavLink>
                                    {/* </div>
                            </NavLink> */}
                                </div>
                                <div className="py-4 pl-2 lg:pl-0">
                                    <NavLink className={" whitespace-nowrap text-[12pt] leading-[17pt] lg:text-[13px] lg:leading-[17px] pl-2" + (openTab === 2 ? " font-semibold   text-[#2B2A29]  border-b-[3px] border-[#F1CA75] py-4 pt-[1.1rem]"
                                        : " whitespace-nowrap font-sans_medium_body text-[#333333] opacity-[.4] hover:opacity-100 ")
                                    }
                                        to={props.subMenu == "maPeExit" ? routeNames.pe_investors_exists_ma_pe_vc_limited_partners :
                                            props.subMenu == "public_pevc" ? routeNames.pe_investors_exists_pm_pe_vc_limited_partners :
                                                props.subMenu == "ma_vc" ? routeNames.vc_investors_exists_ma_vc_limited_partners :
                                                    props.subMenu == "public_vc" ? routeNames.vc_investors_exists_pm_vc_limited_partners :
                                                        routeNames.pe_investors_exists_pe_vc_limited_partners}
                                        data-toggle="tab"
                                        role="tablist"
                                    >
                                      { isFromGlobalSearch() ?
                                       <div className="group inline-block">
                                       <button
                                           className="outline-none w-30  justify-between focus:outline-none py-1 bg-white rounded-sm flex items-center "
                                       >
                                           <span className="flex lg:text-[14px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                               {props.subMenu == "maPeExit" ? "via M&A (PE-VC)" :
                                                   props.subMenu == "public_pevc" ? "via Public Market (PE-VC)" :
                                                       props.subMenu == "ma_vc" ? "M&A (VC)" :
                                                           props.subMenu == "public_vc" ? "Public Market (VC)" : "PE-VC Exits"}</span>
                                          
                                       </button>

                                  
                                   </div>
                                      :


                                        <div className="group inline-block">
                                            <button
                                                className="outline-none w-30  justify-between focus:outline-none py-1 bg-white rounded-sm flex items-center "
                                            >
                                                <span className="flex lg:text-[14px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                                    {props.subMenu == "maPeExit" ? "via M&A (PE-VC)" :
                                                        props.subMenu == "public_pevc" ? "via Public Market (PE-VC)" :
                                                            props.subMenu == "ma_vc" ? "M&A (VC)" :
                                                                props.subMenu == "public_vc" ? "Public Market (VC)" : "PE-VC Exits"}</span>
                                                <span>
                                                    <svg
                                                        className="fill-current h-4 w-4 transform group-hover:-rotate-180
                                            transition duration-150 ease-in-out"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 20 20"
                                                    >
                                                        <path
                                                            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                                                        />
                                                    </svg>
                                                </span>
                                            </button>

                                            <ul
                                                className="mt-2 bg-white rounded-lg shadow-lg lg:ring-1 ring-black ring-opacity-5 transform scale-0 group-hover:scale-100 z-10 absolute 
                                    transition duration-150 ease-in-out origin-top min-w-[7.5rem]  lg:min-w-[12.5rem]"
                                            >
                                                {
                                                    props.subMenu == "maPeExit" ?
                                                        <ul className=" bg-white  py-1 rounded-lg shadow-lg lg:ring-1 ring-black ring-opacity-5 absolute top-0 md:right-0 right-[3%]  transition duration-150 ease-in-out origin-top-left min-w-32">
                                                            <li className="rounded-sm font-sans_book_body px-6 py-2 relative hover:bg-gray-100 ">
                                                                <NavLink to={routeNames.pe_investors_exists_pe_vc_limited_partners}
                                                                    className=" navlink w-full text-left flex items-center outline-none focus:outline-none">
                                                                    <span className=" pr-1  flex-1text-[#333333]  text-[13px] leading-[15px]">PE-VC Exits
                                                                    </span>

                                                                </NavLink>
                                                            </li>


                                                            <li className="rounded-sm font-sans_book_body px-6 py-2 relative  hover:bg-gray-100  ">
                                                                <NavLink to={routeNames.pe_investors_exists_pm_pe_vc_limited_partners}
                                                                    className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                                                >
                                                                    <span className="pr-1 flex-1 z-30 text-[#333333]  text-[13px] leading-[15px]">via Public Market (PE-VC)</span>
                                                                    <span className="mr-auto">

                                                                    </span>
                                                                </NavLink>


                                                            </li>
                                                        </ul>
                                                        :
                                                        props.subMenu == "public_pevc" ?
                                                            <ul className=" bg-white  py-1 rounded-lg shadow-lg lg:ring-1 ring-black ring-opacity-5 absolute top-0 md:right-0 right-[3%]  transition duration-150 ease-in-out origin-top-left min-w-32">
                                                                <li className="rounded-sm relative font-sans_book_body  px-6 py-2 hover:bg-gray-100  ">
                                                                    <NavLink to={routeNames.pe_investors_exists_pe_vc_limited_partners}
                                                                        className=" navlink w-full text-left flex items-center outline-none focus:outline-none">
                                                                        <span className=" pr-1  flex-1 text-[#333333]  text-[13px] leading-[15px]">PE-VC Exits
                                                                        </span>

                                                                    </NavLink>
                                                                </li>


                                                                <li className="rounded-sm font-sans_book_body px-6 py-2 relative  hover:bg-gray-100 ">
                                                                    <NavLink to={routeNames.pe_investors_exists_ma_pe_vc_limited_partners}
                                                                        className=" navlink w-full text-left flex items-center z-30 outline-none focus:outline-none"
                                                                    >
                                                                        <span className="pr-1 z-30 flex-1 text-[#333333]  text-[13px] leading-[15px]">via M&A (PE-VC)</span>
                                                                        <span className="mr-auto">

                                                                        </span>
                                                                    </NavLink>

                                                                </li>

                                                            </ul>


                                                            :
                                                            props.subMenu == "ma_vc" ?
                                                                <ul className="bg-white  py-3 rounded-lg shadow-lg lg:ring-1 ring-black ring-opacity-5 absolute top-0 md:right-0 right-[3%]  transition duration-150 ease-in-out origin-top-left min-w-32">
                                                                    <li className="rounded-sm font-sans_book_body px-6 py-2 relative  hover:bg-gray-100">
                                                                        <NavLink to={routeNames.pe_investors_exists_pe_vc_limited_partners}
                                                                            className="navlink w-full text-left flex items-center outline-none focus:outline-none">
                                                                            <span className="pr-1  flex-1 text-[#333333]  text-[13px] leading-[15px]">PE-VC Exits
                                                                            </span>

                                                                        </NavLink>
                                                                    </li>

                                                                    <li className="rounded-sm font-sans_book_body px-6 py-2  relative  hover:bg-gray-100 ">
                                                                        <NavLink to={routeNames.pe_investors_exists_ma_pe_vc_limited_partners}
                                                                            className="navlink w-full text-left flex items-center z-30 outline-none focus:outline-none"
                                                                        >
                                                                            <span className="pr-1 z-30 flex-1 text-[#333333]  text-[13px] leading-[15px] ">via M&A (PE-VC)</span>
                                                                            <span className="mr-auto">

                                                                            </span>
                                                                        </NavLink>

                                                                    </li>
                                                                    <li className="rounded-sm font-sans_book_body px-6 py-2 relative  hover:bg-gray-100">
                                                                        <NavLink to={routeNames.pe_investors_exists_pm_pe_vc_limited_partners}
                                                                            className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                                                        >
                                                                            <span className="pr-1 flex-1 z-30 text-[#333333]  text-[13px] leading-[15px]">via Public Market (PE-VC)</span>
                                                                            <span className="mr-auto">

                                                                            </span>
                                                                        </NavLink>

                                                                    </li>
                                                                </ul>


                                                                :
                                                                props.subMenu == "public_vc" ?
                                                                    <ul className="bg-white  py-3 rounded-lg shadow-lg lg:ring-1 ring-black ring-opacity-5 absolute top-0 md:right-0 right-[3%]  transition duration-150 ease-in-out origin-top-left min-w-32">
                                                                        <li className="rounded-sm font-sans_book_body px-6 py-2 relative  hover:bg-gray-100">
                                                                            <NavLink to={routeNames.pe_investors_exists_pe_vc_limited_partners}
                                                                                className="navlink w-full text-left flex items-center outline-none focus:outline-none">
                                                                                <span className="pr-1  flex-1 text-[#333333]  text-[13px] leading-[15px]">PE-VC Exits
                                                                                </span>

                                                                            </NavLink>
                                                                        </li>

                                                                        <li className="rounded-sm font-sans_book_body px-6 py-2  relative  hover:bg-gray-100 ">
                                                                            <NavLink to={routeNames.pe_investors_exists_ma_pe_vc_limited_partners}
                                                                                className="navlink w-full text-left flex items-center z-30 outline-none focus:outline-none"
                                                                            >
                                                                                <span className="pr-1 z-30 flex-1 text-[#333333]  text-[13px] leading-[15px] ">via M&A (PE-VC)</span>
                                                                                <span className="mr-auto">

                                                                                </span>
                                                                            </NavLink>

                                                                        </li>
                                                                        <li className="rounded-sm font-sans_book_body px-6 py-2 relative  hover:bg-gray-100">
                                                                            <NavLink to={routeNames.pe_investors_exists_pm_pe_vc_limited_partners}
                                                                                className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                                                            >
                                                                                <span className="pr-1 flex-1 z-30 text-[#333333]  text-[13px] leading-[15px]">via Public Market (PE-VC)</span>
                                                                                <span className="mr-auto">

                                                                                </span>
                                                                            </NavLink>

                                                                        </li>
                                                                    </ul>
                                                                    :
                                                                    props.subMenu == "maPeExit" ?
                                                                        <ul className="bg-white  py-3 rounded-lg shadow-lg lg:ring-1 ring-black ring-opacity-5 absolute top-0 md:right-0 right-[3%]  transition duration-150 ease-in-out origin-top-left min-w-32">
                                                                            <li className="rounded-sm font-sans_book_body px-6 py-2 relative  hover:bg-gray-100">
                                                                                <NavLink to={routeNames.pe_investors_exists_pe_vc_limited_partners}
                                                                                    className="navlink w-full text-left flex items-center outline-none focus:outline-none">
                                                                                    <span className="pr-1  flex-1 text-[#333333]  text-[13px] leading-[15px]">PE-VC Exits
                                                                                    </span>

                                                                                </NavLink>
                                                                            </li>

                                                                            <li className="rounded-sm font-sans_book_body px-6 py-2  relative  hover:bg-gray-100 ">
                                                                                <NavLink to={routeNames.pe_investors_exists_ma_pe_vc_limited_partners}
                                                                                    className="navlink w-full text-left flex items-center z-30 outline-none focus:outline-none"
                                                                                >
                                                                                    <span className="pr-1 z-30 flex-1 text-[#333333]  text-[13px] leading-[15px] ">via M&A (PE-VC)</span>
                                                                                    <span className="mr-auto">

                                                                                    </span>
                                                                                </NavLink>

                                                                            </li>
                                                                            <li className="rounded-sm font-sans_book_body px-6 py-2 relative  hover:bg-gray-100">
                                                                                <NavLink to={routeNames.pe_investors_exists_pm_pe_vc_limited_partners}
                                                                                    className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                                                                >
                                                                                    <span className="pr-1 flex-1 z-30 text-[#333333]  text-[13px] leading-[15px]">via Public Market (PE-VC)</span>
                                                                                    <span className="mr-auto">

                                                                                    </span>
                                                                                </NavLink>

                                                                            </li>
                                                                        </ul>
                                                                        :
                                                                        <ul className="  bg-white py-1 rounded-lg shadow-lg lg:ring-1 ring-black ring-opacity-5 absolute top-0 md:right-0 right-[3%]  transition duration-150 ease-in-out origin-top-left min-w-32">
                                                                            <li className="rounded-sm font-sans_book_body px-6 py-2 relative  hover:bg-gray-100 ">
                                                                                <NavLink
                                                                                    to={routeNames.pe_investors_exists_ma_pe_vc_limited_partners}
                                                                                    className=" navlink w-full text-left flex items-center z-30 outline-none focus:outline-none"
                                                                                >
                                                                                    <span className="pr-1 z-30 flex-1 text-[#333333]  text-[13px] leading-[15px]">
                                                                                        via M&A (PE-VC)
                                                                                    </span>
                                                                                    <span className="mr-auto">

                                                                                    </span>
                                                                                </NavLink>

                                                                            </li>
                                                                            <li className="rounded-sm font-sans_book_body px-6 py-2 relative  hover:bg-gray-100 ">
                                                                                <NavLink
                                                                                    to={routeNames.pe_investors_exists_pm_pe_vc_limited_partners}
                                                                                    className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                                                                >
                                                                                    <span className="pr-1 flex-1 z-30 text-[#333333]  text-[13px] leading-[15px]">
                                                                                        via Public Market (PE-VC)
                                                                                    </span>
                                                                                    <span className="mr-auto">

                                                                                    </span>
                                                                                </NavLink>

                                                                            </li>
                                                                        </ul>

                                                }

                                            </ul>
                                        </div> }
                                    </NavLink>
                                </div>




                            </div>
                        )                           
                                : ""
                    }

                    {
                        props.menu == "investor" ? (
                            <div className="flex justify-end py-2 lg:py-0  lg:px-2 items-center">


                                <div className="flex">
                                    <a className=" pr-2">
                                        {/* <button onClick={download}> */}
                                        {isTrialExport =='false' || isStudent == 'true' || isFromGlobalSearch() || groupId?.myList?.length > 0 ? <></>  : 
                                        <button >
                                            <img src={xls} className="h-[24px] mt-1 w-[21px]" alt=""

                                                onClick={() => setShowPopup(!showPopup)}
                                            />
                                            {/* {showPopup && (
                                                <div>
                                                    <ExcelPopup showPop={showPopup} setShowPop={setShowPopup} />{" "}
                                                </div>
                                            )} */}
                                            {showPopup && (
                                                <ExportPopup
                                                    modules="lp_advisor"
                                                    module_type="pe"
                                                    fileName={props.exportFileName}
                                                    url={exportLPExcel}
                                                    onClose={() => setShowPopup(false)}
                                                    exportOptions={props.subMenu === "investor" ? limitedPartnerProfileExportData : limitedPartnerexitExportData} type={
                                                        props.subMenu === "investor" ? "pe_vc_investment" : props.subMenu === "maPeExit" ? "pe_ma_exit" : props.subMenu === "public_pevc" ? "pe_pm_exit" : "pe_vc_exit"
                                                    }
                                                />

                                            )}

                                        </button>
                                        }
                                    </a>


                                </div>

                            </div>
                        )
                            :
                            ''
                    }


                </div>
            </div>
        </div>
    )
}

export default MainMenu