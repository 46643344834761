import React from 'react'
import Layout from '../../Components/Layout'
import AdminFilterListDataTable from '../../Components/Admin/AdminFilterTable'


/**
 * The Reports function returns a div element containing a Layout component and an
 * AdminFilterListDataTable component.
 * @returns a JSX element.
 */
function Reports() {
    return (
        <div>
            <Layout>
                <div className="mt-[3.5rem]">
                    <AdminFilterListDataTable />
                </div>
            </Layout>
        </div>
    )
}

export default Reports
