import React from 'react'
import { NavLink } from 'react-router-dom';


/**
 * The function `BlankPage` returns a React component that displays an error message and a button to
 * navigate to a specified page.
 * @returns a JSX element.
 */
function BlankPage({to}) {
    return (
        <div className="mt-[8rem]">
            <center>
                <h2>SOMETHING WENT WRONG</h2><br/>
                <NavLink to={to}><button type="submit" className="bg-[#BC8B20] w-[152px] h-[31px] text-[#FFFFFF] rounded-[3px] font-[SquelSans] leading-[17px] ">
                    Go to back page
                </button></NavLink>
            </center>
        </div>
    )
}

export default BlankPage
