import React, { useContext, useEffect } from 'react'
import { PeFilterContext } from '../../Contexts/PeFilterContext'

/**
 * The `ExcludeDefunctFilter` function is a React component that renders a checkbox input and label for
 * excluding defunct companies.
 * @returns a JSX element.
 */
function ExcludeDefunctFilter({ isExclude, setisExclude, customPathname }) {
    const { refineSearchFilters } = useContext(PeFilterContext);

    useEffect(() => {
        setisExclude(refineSearchFilters[customPathname]?.excludeDefunctCos?.at(0)?.value ?? false)
    }, [customPathname, refineSearchFilters, setisExclude])


    const onChange = (e) => {
        setisExclude(e.target.checked);
    }
    return (
        <div className="border-b-[3px] border-[#E8E6E1]">
            <li
                className={
                    "relative px-3 py-2"
                }
            >
                <div className="flex ">
                    <input id='ExcludeDefunctFilter' type="checkbox" onChange={onChange}
                        className="cursor-pointer mr-3"
                        checked={isExclude} />
                    <label htmlFor='ExcludeDefunctFilter' className="text-sm cursor-pointer">Exclude Defunct Cos</label>
                </div>
            </li>
        </div>
    )
}

export default ExcludeDefunctFilter