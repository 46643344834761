import { Modal } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { Link, useParams } from "react-router-dom";
import { fetchMCAInfoAPI } from "../../../../Config/config";
import { UserContext } from "../../../../Contexts/UserContext";
import useModule from "../../../../hooks/useModule";
import sorting from "../../../../images/up-down-arrow.png";
import Loader from "../../../Loader";
import ModalPopup from "../../../shared/ModalPopup";
import Loadpage from "../CFSFinancial/LoadPage";

/* The above code is a React component called "BoardOfDirectors". It is responsible for rendering a
table of board members' data. */
const BoardOfDirectors = (mcaData) => {
  const [open, setOpen] = useState(false);

  const { companyId } = useParams();
  const { primaryMasterId } = useModule();
  const { getToken } = useContext(UserContext)
  const [dinData, setDinData] = useState([])
  const [isLoading, setIsLoading] = useState(true)

 /**
  * The function `getMasterData` makes a POST request to an API endpoint with specified parameters and
  * headers, and handles the response and error accordingly.
  */
  const getMasterData = (dinNumber) => {
    setIsLoading(true)
    axios(`${fetchMCAInfoAPI}`, {
      method: "post",
      data: {
        din: dinNumber,
        company_id: companyId,
        category: "directormaster",
        type: primaryMasterId
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: getToken(),
      },
    })
      .then((response) => {
        setIsLoading(false);
        setDinData(response.data?.directorMaster)
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("API not working", error);
      });
  }


/**
 * The function `onDinClick` calls the `getMasterData` function with a `dinNumber` parameter and sets
 * the `open` state to true.
 */
  const onDinClick = (dinNumber) => {
    getMasterData(dinNumber)
    setOpen(true)
  }

  /**
   * The function `onClose` sets the value of `open` to `false`.
   */
  const onClose = () => {
    setOpen(false)
  }

  return (
    <div className="space-y-6 px-5 lg:mx-1211 overflow-x-auto scrollbar-remove">
      <div className="bg-white pb-5 ml-0">
        <table className="table w-full p-1 bg-grey-50 divide-y" width="50%">
          <thead className="bg-[#EBEBEB] text-[11pt] leading-[16pt] lg:text-[12px] lg:leading-[15px] font-sans_semi_bold text-[#666666]  text-left">
            {mcaData?.data?.bordMembers?.header?.map((singleData, i) => {
              return (
                <th className="px-5 py-5" key={i}>
                  <span className='flex'> {singleData} </span>
                </th>
              );
            })}
          </thead>
          {mcaData?.data?.bordMembers?.value?.map((singleData) => {
            console.log('singleData', singleData);
            for (const property in singleData) {
              return (
                <tr>
                  <td className="px-5 py-3  leading-[2rem] text-[14px] font-sans_book_body items-center justify-center card-animate">
                    <div className="cursor-pointer" onClick={(e) => onDinClick(singleData['pan'])}>
                      {singleData['pan']}
                    </div>
                  </td>
                  <td className="px-5 py-3  leading-[2rem] text-[14px] font-sans_book_body items-center justify-center card-animate">
                    {singleData['name']}
                  </td>
                  <td className="px-5 py-3  leading-[2rem] text-[14px] font-sans_book_body items-center justify-center card-animate">
                    {singleData['begin_date']}
                  </td>
                  <td className="px-5 py-3  leading-[2rem] text-[14px] font-sans_book_body items-center justify-center card-animate">
                    {singleData['end_date']}
                  </td>
                  <td className="px-5 py-3  leading-[2rem] text-[14px] font-sans_book_body items-center justify-center card-animate">
                    {singleData['surrendered_dIN']}
                  </td>
                </tr>
              );
            }
          })}
        </table>
      </div>

      <ModalPopup
        isOpen={open}
        onClose={onClose}>
        <div className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
          <div className="bg-[#BC8B20] text-[#FFFFFF] flex items-center justify-between font-sans_book_body text-[16px] w-full h-12 px-4">
            Director Master
            <AiFillCloseCircle className='cursor-pointer' size={24} color='white' onClick={onClose} />
          </div>
          {isLoading
            ? <div className="h-28 w-full flex items-center justify-center">
              <Loader />
            </div>
            : <div className="overflow-y-scroll max-h-96 ">
              <div className="m-4">
                <table className="table w-full bg-grey-50 divide-y border" width="50%">
                  <thead className="bg-[#EBEBEB] text-[11pt] leading-[16pt] lg:text-[12px] lg:leading-[15px] font-sans_semi_bold text-[#666666]  text-left">
                    <td colSpan={2} className="py-1 text-center">View Director Master Data</td>
                  </thead>
                  <tbody>
                    {dinData?.masterProfile?.map((profile) => {
                      const key = Object.keys(profile)?.at(0);
                      return (
                        <tr>
                          <td className="px-5  leading-[2rem] text-[12px] font-sans_book_body items-center justify-center card-animate">
                            {key}
                          </td>
                          <td className="px-5  leading-[2rem] text-[12px] font-sans_book_body items-center justify-center card-animate">
                            {profile[key]}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>



              <div className="m-4">
                <h3 className="mb-2 font-sans_semi_bold">List of companies</h3>
                <table className="table w-full bg-grey-50 divide-y border" width="50%">
                  <thead className="bg-[#EBEBEB] text-[11pt] leading-[16pt] lg:text-[12px] lg:leading-[15px] font-sans_semi_bold text-[#666666]  text-left">
                    <tr>
                      {
                        dinData?.companiesList?.headers?.map(header => {
                          return <th className=" text-center">{header}</th>
                        })
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {(dinData?.companiesList?.list?.length ?? 0) === 0
                      ? <tr><td className="text-center  text-[12px] font-sans_book_body" colSpan={dinData?.companiesList?.headers?.length ?? 4}>No data</td></tr>
                      : dinData?.companiesList?.list?.map((companyData) => {
                        return (
                          <tr>
                            <td className="text-center   leading-[2rem] text-[12px] font-sans_book_body items-center justify-center card-animate">
                              {companyData?.cin}
                            </td>
                            <td className="text-center   leading-[2rem] text-[12px] font-sans_book_body items-center justify-center card-animate">
                              {companyData?.company_name}
                            </td>
                            <td className="text-center   leading-[2rem] text-[12px] font-sans_book_body items-center justify-center card-animate">
                              {companyData?.begin_date}
                            </td>
                            <td className="text-center   leading-[2rem] text-[12px] font-sans_book_body items-center justify-center card-animate">
                              {companyData?.end_date}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>

              <div className="m-4">
                <h3 className="mb-2 font-sans_semi_bold">List of LLP</h3>
                <table className="table w-full bg-grey-50 divide-y border" width="50%">
                  <thead className="bg-[#EBEBEB] text-[11pt] leading-[16pt] lg:text-[12px] lg:leading-[15px] font-sans_semi_bold text-[#666666]  text-left">
                    <tr>
                      {
                        dinData?.llpCompaniesList?.headers?.map(header => {
                          return <th className="py-1 text-center">{header}</th>
                        })
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {(dinData?.llpCompaniesList?.llpCompanies?.length ?? 0) === 0
                      ? <tr><td className="text-center  text-[12px] font-sans_book_body" colSpan={dinData?.llpCompaniesList?.headers?.length ?? 4}>No data</td></tr>
                      : dinData?.llpCompaniesList?.llpCompanies?.map((companyData) => {
                        return (
                          <tr>
                            <td className="text-center  leading-[2rem] text-[12px] font-sans_book_body items-center justify-center card-animate">
                              {companyData?.cin}
                            </td>
                            <td className="text-center  leading-[2rem] text-[12px] font-sans_book_body items-center justify-center card-animate">
                              {companyData?.company_name}
                            </td>
                            <td className="text-center  leading-[2rem] text-[12px] font-sans_book_body items-center justify-center card-animate">
                              {companyData?.begin_date}
                            </td>
                            <td className="text-center  leading-[2rem] text-[12px] font-sans_book_body items-center justify-center card-animate">
                              {companyData?.end_date}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>

            </div>}
        </div>
      </ModalPopup>
    </div>
  );
};

export default BoardOfDirectors;
