import { Select } from 'antd';
import React, { useState } from 'react';
import { SteppedLineTo } from 'react-lineto';
const { Option } = Select;


const BetweenYearsComponent = ({ selectedChild, setSelectedChild, child, years }) => {

  const childIndex = child.uniqueId;
  const [startYear, setStartYear] = useState() ;
  const [endYear, setEndYear] = useState() ;

  const lineColor = "#BDBDBD";
 /**
  * The function `handleStartYearChange` updates the start year value in a selected child object, and
  * displays an alert if the start year is not greater than the end year.
  * @param event - The `event` parameter is an object that represents the event that triggered the
  * change in the start year. It typically contains information about the event, such as the target
  * element, the type of event, and any additional data related to the event. In this case, it is used
  * to extract the new
  */
  const handleStartYearChange = (event) => {
    setStartYear(event)
    if(endYear && (event > endYear)){
      alert('Start year is not greater than end year')
      setSelectedChild((prevSelectedChild) =>
      prevSelectedChild.map((row, index) => {
        if (row.id === child.id && child.uniqueId === childIndex) {
          return {
            ...row,
            values: ['', row.values[1]] // Update start year value, keep end year unchanged
          };
        }
        return row;
      })
    );
    }
    else{
    setSelectedChild((prevSelectedChild) =>
      prevSelectedChild.map((row, index) => {
        if (row.id === child.id && child.uniqueId === childIndex) {
          return {
            ...row,
            values: [startYear, row.values[1]] // Update start year value, keep end year unchanged
          };
        }
        return row;
      })
    );}
  };

/**
 * The function `handleEndYearChange` updates the end year value based on user input, while also
 * checking if the end year is greater than the start year and displaying an alert if it is not.
 * @param event - The `event` parameter is the event object that is triggered when the end year value
 * is changed. It contains information about the event, such as the target element and the new value of
 * the end year.
 */
  const handleEndYearChange = (event) => {
    console.log(event, startYear,'startYear');
     setEndYear(event)
  if(startYear < event){
    setSelectedChild((prevSelectedChild) =>
      prevSelectedChild.map((row, index) => {
        if (row.id === child.id && child.uniqueId === childIndex) {
          return {
            ...row,
            values: [row.values[0], endYear] // Keep start year unchanged, update end year value
          };
        }
        return row;
      })
    );
  }
  else{
    alert('End year is not lesser than start year')
    setSelectedChild((prevSelectedChild) =>
      prevSelectedChild.map((row, index) => {
        if (row.id === child.id && child.uniqueId === childIndex) {
          return {
            ...row,
            values: [row.values[0], ''] // Keep start year unchanged, update end year value
          };
        }
        return row;
      })
    );
  }
}

  return (
    <div className='flex space-x-2'>
      <div className={`singleSearch from${childIndex}`}>
      <SteppedLineTo within='query-builder' borderColor={lineColor} delay={100} borderWidth={5} from={`from${childIndex}`} to={`to${childIndex}`} orientation={"h"} />
        <Select value={child.values[0]} onChange={handleStartYearChange}
        showSearch
        key={childIndex}
        placeholder="From Year"
        className="rounded focus:outline-none w-[7rem]">
          {years.map((year) => (
            <Option key={year} value={year}>
              {year}
            </Option>
          ))}
        </Select>
       </div>
      <div className={`singleSearch to${childIndex}`}>
        <Select value={child.values[1]} onChange={handleEndYearChange}
        showSearch
        key={childIndex}
        placeholder="To Year"
        className="rounded focus:outline-none w-[7rem]">
          {years.map((year) => (
            <Option key={year} value={year}>
              {year}
            </Option>
          ))}
        </Select>
       </div>
    </div>
  );
};

export default BetweenYearsComponent;
