import React,{useContext} from 'react'
import Table from '../components/Table'
import ReInvestmentCard from '../components/ReInvestmentCard'
import RefineSearch from '../../../../Components/RefineSearchComponent'
import FilterTag from '../../../../Components/Investor/Tag'
import Tabbar from '../components/Tabbar'
import Constants from '../../../../Constants'
import ReInvestorExitRefineSearch from '../../../../Components/RefineSearch/ReInvestorExitRefineSearch'
import { useLocation } from 'react-router-dom'
import { PeFilterContext } from '../../../../Contexts/PeFilterContext'
 
const ReInvestmentsColumns = [
    { accessor: 'Investor', label: 'Investor' },
    { accessor: 'exited_project_count', label: 'Exited Projects (Partial/Full)' },
    { accessor: 'top_exits', label: 'Top exits based on transaction size' },
    { accessor: 'last_invested_company_sort', label: 'Last Exited in' },
    { accessor: 'add', label: 'Add' }, 
]


/**
 * The `ReInvestorExits` function is a React component that renders a page for displaying and filtering
 * real estate investor exits.
 * @param props - The `props` parameter is an object that contains any additional data or functions
 * that are passed to the `ReInvestorExits` component from its parent component. It is used to access
 * and utilize any data or functions that are needed within the component.
 * @returns a JSX element, which represents the structure and content of a React component.
 */
function ReInvestorExits(props) {
    const location = useLocation();
    const {refineSearchFilters} = useContext(PeFilterContext);
    const { pathname } = useLocation(); 
    const isFromGlobalSearch = ()=> refineSearchFilters[pathname]?.globalSearch?.length > 0 ? true : false

    return (
        <div className='flex min-h-screen h-screen flex-col'>
            <ReInvestmentCard />
            <div className={`h-full min-w-0 flex-1 border-t border-gray-200 flex`}>
                <section aria-labelledby="primary-heading" className="flex h-full min-w-0 flex-1 flex-col lg:order-last bg-[#EDEDED]">
                    <FilterTag  menu="re-fund"/>
                    <Tabbar
                        primaryTypeMasterId={Constants.primaryMasterIds.reId}
                        subTypeMasterId={Constants.primaryMasterSubIds.re_investor_exits_id}
                        typeId={Constants.moduleIds.investorsId}
                        exportFileName={Constants.exportNames.re_investor_exits}
                        menu="re-exits"
                    />
                    <div className='overflow-y-auto'>
                        <Table order="asc" orderBy="Investor" columns={ReInvestmentsColumns} category="re-exits" sub_type_master_id={Constants.subCategoryIds.re_investor_exits_id}/>
                    </div>
                </section>
                <aside className=" order-first block flex-shrink-0">
                    <div className="relative flex h-[100%] mb-[4.25rem] flex-col  border-r border-gray-200 bg-[#FAF5EA]">
                        {/* Your content */}
                        <RefineSearch>
                            <ReInvestorExitRefineSearch customPathname={location.pathname} dataPath={props?.dataPath}/>
                        </RefineSearch>
                    </div>
                </aside>

            </div>
        </div>
    )
}

export default ReInvestorExits