import React, { useState, useContext, useEffect } from "react";
import { HeaderMenuContext } from "../Contexts/HeaderMenuContext";
import { SearchMenuContext } from "../Contexts/SearchMenuContext";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import v2logo from "../images/Bitmap/png/Bitmap.png";
import shape from "../images/Shape_13/png/Shape.png";
import logowidget from "../images/images/png/images.png";
import MobileMenu from "../Components/MobileMenu";
import Search from "../images/Shape_13/png/Shape.png";
import Profile from "../Components/Profile";
import MobileSearch from "./MobileSearch";
import PopOverSearch from "../Components/PopOverSearch"
import "../Components/HeaderComponent.css";
import { routeNames } from "../routeSegments";

/**
 * The MAHeader function is a React component that renders a header component for a web application,
 * including a search bar, navigation links, and user profile and notification icons.
 * @param props - The `props` parameter is an object that contains any properties passed to the
 * `MAHeader` component. These properties can be accessed using dot notation, such as
 * `props.propertyName`.
 * @returns The MAHeader component is returning a JSX fragment containing a div element with nested
 * elements.
 */
const MAHeader = (props) => {
  const navigate = useNavigate();
  const { notificationCount } = useContext(HeaderMenuContext);
  const PEHeaderSearchMenu = useContext(SearchMenuContext);
  const [value, setValue] = useState("");
  const [showText, setShowText] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [FilteredData, setFilteredData] = useState(PEHeaderSearchMenu.data);
  const [popup, setPopup] = useState(false);
  const [popOver, setPopOver] = useState(false);
  const [notificationMobileCount,setNotificationMobileCount] = useState(notificationCount)
  const location = useLocation();

  useEffect(()=>{
    setNotificationMobileCount(notificationCount)
  },[setNotificationMobileCount,notificationCount])

  const searchMenuSelect = (item) => {
    navigate("/" + item.name.toLowerCase() + "/" + item.id);
  };
  /**
   * The function `onchangeSearchMenu` filters an array of data based on a search input value and
   * updates the filtered data state.
   * @param e - The parameter `e` is an event object that represents the event that triggered the
   * `onchangeSearchMenu` function. In this case, it is likely an event object related to a change
   * event, such as a user typing in an input field.
   */
  const onchangeSearchMenu = (e) => {
    setValue(e.target.value);
    setFilteredData([]);
    let resultArray = [];
    PEHeaderSearchMenu?.data.map((arr, i) => {
      if (arr?.label.toLowerCase().match(e.target.value)) {
        resultArray.push({ id: arr.id, name: arr.name, label: arr.label });
      }
    });
    setFilteredData(resultArray);
  };

  return (
    <>
      {popOver ? <PopOverSearch /> : ''}
      <div>
        <div className="bg-[#EDEDED]">
          <div className="relative bg-white">
            <div className="">
              {/* --------New code----- */}
              <div className="w-full fixed z-[10]">
                <div className="relative z-10 flex-shrink-0  bg-white border-b border-gray-200 ">
                  <div>
                    <div className="flex justify-between items-center h-[3.5rem] lg:pr-2">
                      <div className="flex h-[3.5rem]">
                        {/* <SideBarApp />   */}
                        <div className="md:hidden ">
                          <span className="sr-only">Open sidebar</span>
                          <MobileMenu />
                        </div>
                        <div
                          className="bg-white rounded-full px-2 py-1 flex text-sm  "
                          id="user-menu-button"
                          aria-expanded="false"
                          aria-haspopup="true"
                        >
                          <img
                            className="h-9 w-21 rounded-full"
                            src={v2logo}
                            alt=""
                          />
                        </div>

                        <p className="border-r border-gray-200 h-[3rem]"></p>
                        <div className="hidden md:flex pl-4">
                          <div className="relative lg:w-[28rem] md:w-[28.5rem] pl-[2.5rem]  grid  rounded-[18px]  ">
                            <div className=" flex absolute z-10 inset-y-0 left-0 pl-3 items-center text-[#989898] pointer-events-none">
                              <img className="h-[17px] w-[17px]" src={Search} alt="search"/>
                            </div>
                            <input
                              type="text"
                              onKeyUp={onchangeSearchMenu} onClick={() => setPopOver(!popOver)}
                              className="w-[96%] text-[15px] outline-none shadow-none bg-inherit text-[#333333] opacity-[.55] leading-[17px] tracking-[-0.28] font-sans_book_body"
                              placeholder={PEHeaderSearchMenu.placeholder}
                            />
                            {value.length >= 3 && (
                              <div className="lg:w-[24rem] md:w-[28.5rem] bg-white grid border border-[#CCCCCC] ">
                                {FilteredData.length > 0 ? (
                                  FilteredData.map((item, i) => {
                                    return (
                                      <div
                                        onClick={() => searchMenuSelect(item)}
                                        className=" px-4 py-2 font-sans_book_body justify-between flex hover:bg-[#F2F2F2] text-[#333333] tracking-[-0.28px] leading-[18px] text-[15px]"
                                      >
                                        <div
                                          style={{
                                            background: i ? "" : "[#F2F2F2]",
                                          }}
                                          key={item.id}
                                        >
                                          {item.label}
                                        </div>
                                        <div
                                          style={{
                                            background: i ? "" : "[#F2F2F2]",
                                          }}
                                          key={item.id}
                                        >
                                          {item.name}
                                        </div>
                                      </div>
                                    );
                                  })
                                ) : (
                                  <p className="px-4 py-1 font-sans_book_body">
                                    No Data Found
                                  </p>
                                )}
                              </div>
                            )}
                            {/* <Autocomplete
                                  // Items is the list of suggestions
                                  // displayed while the user type

                                  items={PEHeaderSearchMenu.data}
                                  shouldItemRender={(item, value) =>
                                    item.label
                                      .toLowerCase()
                                      .indexOf(value.toLowerCase()) > -1
                                  }
                                  getItemValue={(item) => item.label}
                                  renderItem={(item, isHighlighted) => (
                                    <div className=" px-4 py-2 justify-between flex hover:bg-[#F2F2F2] text-[#333333] tracking-[-0.28px] leading-[18px] text-[15px]">
                                      <div
                                        style={{
                                          background: isHighlighted
                                            ? ""
                                            : "[#F2F2F2]",
                                        }}
                                        key={item.id}
                                      >
                                        {item.label}
                                      </div>
                                      <div
                                        style={{
                                          background: isHighlighted
                                            ? ""
                                            : "[#F2F2F2]",
                                        }}
                                        key={item.id}
                                      >
                                        {item.name}
                                      </div>
                                    </div>
                                  )}
                                  value={value}
                                  key={PEHeaderSearchMenu.data}
                                  onChange={(e) => setValue(e.target.value)}
                                  onSelect={searchMenuSelect}
                                  // onSelect={(val) => setValue(val)}
                                  inputProps={{
                                    style: {
                                      width: "96%",
                                      fontSize: "14px",
                                      outline: "none",
                                      padding: "1%",
                                      boxShadow: "none",
                                      background: "none",
                                    },
                                    placeholder:PEHeaderSearchMenu.placeholder,
                                    type: "search",
                                    id: "searchBarBrowse",
                                    value: "Search",
                                  }}
                                /> */}
                          </div>
                        </div>
                      </div>

                      <div>
                        <div className=" md:flex items-center justify-between xl:pl-0">
                          <nav className="hidden md:flex space-x-10 px-4">
                            <section>
                              <div
                                className="hidden lg:flex flex-col items-stretch items-center  w-full "
                                x-data="{tab: 1}"
                              >
                                <div className="flex flex-row items-center gap-5">
                                  <NavLink
                                    end
                                    className={({ isActive }) => {
                                      if (isActive) {
                                        return "relative top-[8px] mb-[8px] text-[15px] font-semibold  text-[#2B2A29]  border-b-[3px] border-[#F1CA75] py-2 menu-item";
                                      } else {
                                        return "relative top-[2px] text-[15px] text-[#2B2A29] font-sans_book_body opacity-[.8] menu-item";
                                      }
                                    }}
                                    to="companies"
                                  >
                                    Companies
                                  </NavLink>

                                  <NavLink
                                    className={({ isActive }) => {

                                      if (isActive) {
                                        return "relative top-[8px] mb-[8px] text-[15px] font-semibold  text-[#2B2A29]  border-b-[3px] border-[#F1CA75] py-2 menu-item";
                                      } else {
                                        return "relative top-[2px] text-[15px] text-[#2B2A29] font-sans_book_body opacity-[.8] menu-item";
                                      }
                                    }}
                                    to="investors"
                                  >
                                    Investors
                                  </NavLink>

                                  {/* <NavLink className={({ isActive }) => isActive ? '   relative top-[8px] mb-[8px] text-[15px] font-semibold  text-[#2B2A29]  border-b-[3px] border-[#F1CA75] py-2 menu-item' : ' relative top-[2px] text-[15px] text-[#2B2A29] font-sans_book_body opacity-[.8] menu-item' }  to="/investors/pe-vc-investments" >Investors</NavLink> */}
                                  <NavLink
                                    className={() => {
                                      // const pathWithoutLastPart =
                                      //   location.pathname.slice(
                                      //     0,
                                      //     location.pathname.lastIndexOf("/")
                                      //   );
                                      const pathWithoutLastPartLevel1 =
                                        location.pathname.indexOf("/advisors");
                                      const pathWithoutLastPartLevel2 =
                                        location.pathname.indexOf("/advisor");
                                      // return pathWithoutLastPartLevel2;
                                      if (
                                        pathWithoutLastPartLevel1 > -1 ||
                                        pathWithoutLastPartLevel2 > -1
                                      ) {
                                        return "relative top-[8px] mb-[8px] text-[15px] font-semibold  text-[#2B2A29]  border-b-[3px] border-[#F1CA75] py-2 menu-item";
                                      } else {
                                        return "relative top-[2px] text-[15px] text-[#2B2A29] font-sans_book_body opacity-[.8] menu-item";
                                      }
                                    }}
                                    // className={({ isActive }) => isActive ? ' relative top-[8px] mb-[8px] text-[15px] font-semibold  text-[#2B2A29]  border-b-[3px] border-[#F1CA75] py-2 menu-item' : ' relative top-[2px] text-[15px] text-[#2B2A29] font-sans_book_body opacity-[.8] menu-item'}

                                    to={routeNames.pe_advisors_pe_vc_advisors}
                                  >
                                    Legal Advisors
                                  </NavLink>

                                  <NavLink
                                    className={() => {
                                      // const pathWithoutLastPart =
                                      //   location.pathname.slice(
                                      //     0,
                                      //     location.pathname.lastIndexOf("/")
                                      //   );
                                      const pathWithoutLastPartLevel1 =
                                        location.pathname.indexOf("/transaction-advisor");
                                      const pathWithoutLastPartLevel2 =
                                        location.pathname.indexOf("/transaction-advisors");
                                      // return pathWithoutLastPartLevel2;
                                      if (
                                        pathWithoutLastPartLevel1 > -1 ||
                                        pathWithoutLastPartLevel2 > -1
                                      ) {
                                        return "relative top-[8px] mb-[8px] text-[15px] font-semibold  text-[#2B2A29]  border-b-[3px] border-[#F1CA75] py-2 menu-item";
                                      } else {
                                        return "relative top-[2px] text-[15px] text-[#2B2A29] font-sans_book_body opacity-[.8] menu-item";
                                      }
                                    }}
                                    // className={({ isActive }) =>
                                    //   isActive
                                    //     ? " relative top-[8px] mb-[8px] text-[15px] font-semibold  text-[#2B2A29]  border-b-[3px] border-[#F1CA75] py-2 menu-item"
                                    //     : " relative top-[2px]  text-[15px] text-[#2B2A29] font-sans_book_body opacity-[.8]"
                                    // }
                                    to={routeNames.pe_transaction_advisors_pe_vc_advisors}
                                  >
                                    Transaction Advisors
                                  </NavLink>
                                </div>
                              </div>
                            </section>
                          </nav>
                          <div className="flex space-x-4 mr-3 items-center">
                            <p className="md:border-r border-gray-200 h-[3rem] "></p>
                            {/* <img
                        className="md:hidden w-6 h-6 mt-4 ml-5"
                        src={Group66}
                      ></img> */}
                            <div className="  md:hidden py-5">
                              <div className="">
                                <div className="">
                                  <img
                                    className=" text-gray-500 w-[1.5rem] float-right"
                                    src={shape}
                                    onClick={() => setPopup(!popup)}
                                    alt="shape"
                                  />
                                  {popup && (
                                    <div className=" ">
                                      <MobileSearch
                                        showChat={popup}
                                        setShowChat={setPopup}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div
                              className="cursor-pointer pt-1"
                              onClick={() =>
                                setShowNotification(!showNotification)
                              }
                            >
                              <span className="relative  md:flex text-[#666666]">
                                <svg
                                  className="h-6 w-6 text-[#666666]"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth="2"
                                  stroke="currentColor"
                                  aria-hidden="true"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                                  />
                                </svg>
                                <span className="absolute top-[2px] left-[2px] inline-flex items-center justify-center h-1 w-1 p-2 text-xs font-bold leading-none text-white transform translate-x-1/2 -translate-y-1/2 bg-[#BC8B21] rounded-full">
                                  {notificationMobileCount}
                                </span>
                              </span>
                            </div>
                            {/* <BellNotification /> */}
                            <div className="">
                              <button
                                type="button"
                                onClick={() => setShowText(!showText)}
                                className="bg-white rounded-full flex text-sm focus:outline-none "
                                id="user-menu-button"
                                aria-expanded="false"
                                aria-haspopup="true"
                              >
                                <span className="sr-only">Open user menu</span>
                                <img
                                  className="h-8 w-8 rounded-full"
                                  src={logowidget}
                                  alt=""
                                />
                              </button>
                              {showText && (
                                <div>
                                  <Profile />{" "}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        {/* profile and notification end */}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <Outlet /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MAHeader;
