import { useState, useMemo, useEffect, useContext, useRef } from 'react'
import axios from "axios";
import { NavLink, useParams, useSearchParams } from 'react-router-dom';
import compare from '../../images/compare.png'
// import pdf from '../../images/pdf.png'
import { useLocation } from "react-router-dom";
// import xls from '../../../images/download-file-icon.png'
// import atom from '../../images/atom.png'
import addNoted from "../../images/AtomFavourite Copy/png/AtomFavourite Copy.png";
import addNotedActive from '../../images/AtomFavourite Copy 3/png/AtomFavourite Copy 3.png';
// import addNoted from "../../../images/AtomFavourite Copy/png/AtomFavourite Copy.png";
// import edit from '../../images/combined-shape.png'
// import ReactReadMoreReadLess from "react-read-more-read-less";
import { descriptionCharLimit } from "../../Config/config"
// import barRaiser from '../../images/barraiser-logo.png'
import Overview from './OverView'
import LimitedPartnersin from './LimitedPartnerIn'
import RecentNewsActivity from './RecentNewsActivity'
// import ExcelPopup from "../../../Components/ExcelPopup";
import { getLimitedPartnersDetail } from "../../Config/config";
import AlertPopUp from "../DetailPage/AlertPopUp";
import AddNote from "../../Pages/addNote";
import SendDealPopUp from "../SendDealPopUp";
import ComparePopUp from '../../Pages/ComparePopUp'
// import Warning from '../../images/warning-btn.png'
import { LP_NEWSLETTER } from '../../Queries/Pe';
import { CompareCompanyContext } from '../../Contexts/CompareCompanyContext'
// import OutsideClickHandler from 'react-outside-click-handler';
import PENotesPopup from '../../Components/NotesPopup/PENotesPopup'
import FundingDataProvider from '../../Contexts/FundingDataProvider';
import CompanyLogo from '../CompanyLogo';
import { exportPdf } from '../../Config/config'
import PdfDownloader from "../shared/PdfDownloader";
import Constants from '../../Constants';
// import { Tooltip } from 'antd';
import RandomLogo from '../RandomLogo';
import  { routeNames } from '../../routeSegments';
import AddNotePopover from '../shared/AddNotePopover';
import Tab from '../shared/Tab';
import { debounce, delay } from 'lodash';
import { useInView } from 'react-intersection-observer';
import { PeFilterContext } from '../../Contexts/PeFilterContext';


const InvestorLimitedPartnerDetail = (props) => {
  const overview = () => { document.getElementById("profile").scrollIntoView({ behavior: 'smooth' });; }
  const investments = () => { document.getElementById("investments").scrollIntoView({ behavior: 'smooth' });; }
  const similarCompanies = () => { document.getElementById("news").scrollIntoView(); }
  const [showModal, setShowModal] = useState(false);
  const [showNote, setShowNote] = useState(false)
  const [isActive, setIsActive] = useState(1);
  const {groupId ,setGroupId } = useContext(PeFilterContext);
  const {  companyDetail,setCompanyDetail } = useContext(CompareCompanyContext);
  const { companyId } = useParams();
  let companyProfileId = parseInt(companyId)
  const [note,
    //  setNote
    ] = useState(false)
  const location = useLocation();
  const { partnerId } = useParams();
  let investorIdPE = parseInt(partnerId)
  const [post, setPost] = useState([]);
  const [skipScroll, setSkipScroll] = useState(false)
  const [overviewEl, isOverviewVisible,  overviewEntry ] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })
  const [limitedpartnersEl, islimitedpartnersVisible, limitedpartnersEntry ] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })
  const [newsEl, isnewsVisible,   newsEntry] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })


  /**
   * The function `onTabClick` is used to handle tab clicks and perform different actions based on the
   * selected tab index.
   */
  const onTabClick = (tabIndex) => {
    setSkipScroll(true);
        delay(()=>{
            setSkipScroll(false);
        },500)
    if (tabIndex === 1) {
        overview();
    } else if (tabIndex === 2) {
        investments();
    
    } else if (tabIndex === 3) {
        similarCompanies()
   
    }

    setIsActive(tabIndex);
}

/* The above code is using the `useMemo` hook in React to create a memoized version of the
`setIsActiveOnScroll` function. The `setIsActiveOnScroll` function is created using the `debounce`
function, which is a utility function that limits the rate at which a function can be called. In
this case, the `setIsActiveOnScroll` function takes an `index` parameter and calls the `setIsActive`
function with that index. The `setIsActive` function is likely a state update function that updates
the state of a component. The `100` parameter passed */
const setIsActiveOnScroll = useMemo(() => {
  return debounce(index => setIsActive(index), 100)
}, [])

useEffect(() => {

  if (isOverviewVisible) {
      setIsActiveOnScroll(1);
  } else if (islimitedpartnersVisible) {
      setIsActiveOnScroll(2);      
  } else if (isnewsVisible) {
      setIsActiveOnScroll(3);
  }
}, [isOverviewVisible, isnewsVisible, islimitedpartnersVisible,setIsActiveOnScroll])


  const menuRef = useRef();
  useEffect(() => {
    axios.get(`${getLimitedPartnersDetail}/${investorIdPE}`)
      .then((response) => {
        if (response?.data?.message == "Limited partner found") {
          console.log(response?.data, "axios api response detail")
          setPost([])
          setPost(response?.data?.profile);
        }
      });
  }, []);
  

  // console.log(post, "checking");

  const overviewsection = useMemo(() => post, [post]);

  const getURL = localStorage.getItem("currenturl");
  const breadcrumb = (getURL == '/investors/pe-vc-limited-partners') ? 'PE-VC Investments'
    : (getURL == '/investors/vc-limited-partners') ? 'VC Investments'
      : (getURL == '/investors/exists/pe-vc-limited-partners') ? 'PE-VC Exits'
        : (getURL == '/investors/exists/ma/pe-vc-limited-partners') ? 'via M&A (PE-VC)'
          : (getURL == '/investors/exists/pm/pe-vc-limited-partners') ? 'via Public Market (PE-VC)'
            : (getURL == '/investors/exists/ma/vc-limited-partners') ? 'M&A (VC)'
              : (getURL == '/investors/exists/pm/vc-limited-partners') ? 'Public Market (VC)'
                : ''

  const pathSeg = location.state?.prevPath?.split('/') ?? [];
  const exportFileName = pathSeg.includes('exits')
    ? Constants.exportNames.limitedPartnerExits(post?.at(0)?.lpName)
    : Constants.exportNames.limitedPartnerInvestments(post?.at(0)?.lpName)

    const [URLSearchParams] = useSearchParams({ section: 1 })
    const section = URLSearchParams.get('section');
    console.log(section,'sectionssss');
    
    /**
     * The function `subTypeMasterId` returns a sub category ID based on the value of the `section`
     * variable.
     * @returns The function `subTypeMasterId` returns the sub category ID based on the value of the
     * `section` variable.
     */
    const subTypeMasterId =() =>{
      
      if(section == Constants.primaryMasterSubIds.pe_vc_limited_partners_id){
        return Constants.subCategoryIds.pe_lp_investments_id
      }
      else if(section == Constants.primaryMasterSubIds.pe_vc_exits_limited_partners_id){
        return Constants.subCategoryIds.pe_lp_exits_id
      }
      else if(section == Constants.primaryMasterSubIds.pe_vc_ma_limited_partners_id){
        return Constants.subCategoryIds.pe_lp_exitsma_id
      }
      else if(section == Constants.primaryMasterSubIds.pe_vc_pm_limited_partners_id){
        return Constants.subCategoryIds.pe_lp_exitspm_id
      }
      
    }

  return (
    <>
      <div className="bg-[#FFFFFF]">

        {/* <!-- BarRaiser--> */}
        <nav className="flex p-1 lg:px-1 px-3 border-b " aria-label="Breadcrumb">
          <ol role="list" className="flex items-center space-x-1">
            <li className='flex items-center'>
              <div className='flex items-center'>
                {/* <NavLink to={getURL} className="ml-2 text-[11pt] lg:text-[12px] text-[#666666] font-sans_book_body">
                  {breadcrumb}</NavLink> */}
                <NavLink 
                onClick={()=>setGroupId({})}
                to={props.menu == "vc" ? routeNames.vc_investors_vc_investments
                  : routeNames.pe_investors_pe_vc_investments} className="ml-2 text-[11pt] lg:text-[12px] text-[#666666] font-sans_book_body">
                  Investors</NavLink>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <span className=" text-[12px] text-[#666666] mr-1">/</span>
                <a className=" text-[11pt] lg:text-[12px] text-[#666666] font-semibold"
                  aria-current="page">
                  {/* {post?.lpName} */}
                  <NavLink to={location.state?.prevPath != null
                    ? location.state?.prevPath
                    : props.menu == "vc" ? routeNames.vc_investors_vc_limited_partners
                      : routeNames.pe_investors_pe_vc_limited_partners} className=" text-[11pt] lg:text-[12px] text-[#666666] font-sans_book_body whitespace-nowrap">
                    Limited Partners</NavLink>
                </a>
              </div>
            </li>
            <li className='flex items-center'>
              {post.length > 0 ?
                post?.map((row, i) => {
                  return (
                    <div className="flex items-center">
                      <span className=" text-[12px] text-[#666666] mr-1">/</span>
                      <a className=" text-[11pt] lg:text-[12px] text-[#666666] font-semibold"
                        aria-current="page">{row.lpName ? row.lpName : "--"}</a>
                    </div>
                  )
                }) : "--"
              }
            </li>
          </ol>
        </nav>
        {post.length > 0 ?
          post?.map((row, i) => {
            return (
              // {console.log(post.length,"data")}
              <div className="px-5 ">
                <div className="lg:hidden mt-3  grid  md:grid-cols-8  grid-cols-10  items-center mb-4">
                  <div className=" md:col-span-6 col-span-5" title="Compare Company">
                    <button
                      className={
                        showModal
                          ? "bg-[#BC8B20] text-white text-[14px] py-1 lg:py-1 px-2 lg:px:4 border border-[#DDDDDD] rounded-[4px] inline-flex items-center font-sans_book_body"
                          : "bg-[#FFFFFF] text-[#666666] text-[14px] py-1 lg:py-1 px-2 lg:px:4 border border-[#DDDDDD] rounded-[4px] inline-flex items-center font-sans_book_body"
                      }
                      type="button"
                      onClick={() => {
                        setShowModal(!showModal)
                        setCompanyDetail([])
                      }
                      }
                    >
                      <img
                        className={
                          showModal ? "pr-1 brightness-0 invert-[1]" : "pr-1"
                        }
                        src={compare}
                        alt=""
                      />
                      <span>Compare</span>
                    </button>
                  </div>
                  <div className="flex md:col-span-2 col-span-5 space-x-3">
                    <div >
                      <AlertPopUp cid={props.id} type_id={Constants.limitedPartnerId} page="detailviewLp" />
                    </div>
                    <div >
                      {/* <OutsideClickHandler onOutsideClick={() => { setShowNote(false); }} > */}
                      <span >
                        <img className=" cursor-pointer w-[24px] h-[22px]"
                          src={showNote ? addNotedActive : addNoted} onClick={() => setShowNote(!showNote)}
                          alt=""
                        />
                        {showNote ?
                          <AddNote module="limited" page="detailviewLp" type_id={Constants.limitedPartnerId} cname={row?.lpName} cid={partnerId} sub_type_master_id={subTypeMasterId()}/> : ""}
                      </span>

                      {/* </OutsideClickHandler> */}
                    </div>


                    <div className="col-span-1">
                      <PENotesPopup note={note} cid={partnerId} type_id={Constants.limitedPartnerId} />
                    </div>
                    <div className="col-span-1">
                      <SendDealPopUp data={props?.data?.companyname} />
                    </div>

                    <div className="col-span-1">
                    <PdfDownloader
                        url={exportPdf}
                        fileName={row?.lpName?.replaceAll(/\s/g, '')}
                        data={{
                          entity_id: partnerId,
                          type_id: Constants.moduleIds.limitedPartnerId,
                        }} />
                    </div>
                  </div>
                </div>
                <div className="  bg-[#FFFFFF]">

                  <div className=' pb-4 pt-6 bg-[#FFFFFF]'>
                    <ul role="list" >
                      <li className="w-full flex ">
                        <div className=" rounded-md">
                          {props?.page == "dealPage" ?
                            row?.logo_path == "default.png" || row?.logo_path == "/default.png" || !row?.logo_path ?
                              <RandomLogo name={row.lpName} page="detail page" />
                              :
                              <CompanyLogo name={row.lpName} id={props?.id} page="detail page" logo={props?.data?.logo_path} />

                            :
                            row?.logo_path == "default.png" || row?.logo_path == "/default.png" || !row?.logo_path ?
                              <RandomLogo name={row.lpName} page="detail page" />
                              :
                              <CompanyLogo name={row.lpName} id={props?.id} page="detail page" logo={row?.logo_path} />

                          }
                          {/* <CompanyLogo  page="detail page"  id={props.id} /> */}

                        </div>

                        <div className="ml-4 flex flex-1 flex-col">
                          <a className="text-[#333333] text-[24px] font-semibold leading-[29px]" >{row.lpName ? row.lpName : "--"}</a>
                          {console.log(row.lpName, "dataname")}
                          <span className=" text-[12pt] leading-[16pt] text-[#333333]  lg:text-[14px] lg:leading-[17px] font-sans_roman_body ">{row.institutionType ? row.institutionType : ""}</span>
                          {/* <span className="text-[11pt] leading-[11pt] text-[#777777] lg:text-[14px] font-sans_book_body lg:leading-[21px]">Enterprise Software (SaaS - HR Services - Recuirement)</span> */}
                        </div>
                        <div className="hidden lg:grid grid-cols-8 gap-2">
                          <div className="col-span-3 mt-2">
                            {/* <Tooltip title="Compare Company">
                            <button
                            
                              className={
                                showModal
                                  ? "bg-[#BC8B20] text-white text-[13px] py-1 lg:py-1 px-2 lg:px:4 border border-[#BC8B20] rounded-[4px] inline-flex items-center font-sans_book_body"
                                  : "bg-[#FFFFFF] text-[#666666] text-[13px] py-1 lg:py-1 px-2 lg:px:4 border border-[#DDDDDD] rounded-[4px] inline-flex items-center font-sans_book_body"
                              }
                              type="button"
                              onClick={() => {
                                setShowModal(!showModal)

                              }
                              }
                            >
                              <img
                                className={
                                  showModal
                                    ? "brightness-0 invert-[1] w-[20px] h-[18px] pr-1"
                                    : "w-[20px] h-[18px] pr-1 blur-btn"
                                }
                                src={compare}
                                alt=""
                              />
                              <span>Compare</span>
                            </button>
                            </Tooltip> */}
                          </div>

                          <div className="col-span-5 mt-[5px]">
                            <div className="grid grid-cols-5 gap-2 flex items-center">
                              <div className="col-span-1 ">
                                <AlertPopUp cid={partnerId} type_id={Constants.moduleIds.limitedPartnerId} page="detailviewLp" />{" "}
                              </div>

                              <div className="col-span-1" ref={menuRef}>
                                <AddNotePopover
                                  title="Save List"
                                  companyId={partnerId}
                                  companyName={row.lpName}
                                  type_id={Constants.moduleIds.limitedPartnerId}
                                  sub_type_master_id={subTypeMasterId()}
                                  IsDetailed={true} />
                                {/* <Tooltip title="Save List">
                                  <img
                                    className="w-[22px] h-[22px] cursor-pointer"
                                    src={showNote ? addNotedActive : addNoted}
                                    // onClick={() => setShowNote(!showNote)}
                                    onClick={() => setShowNote((showNote) => !showNote)}
                                    alt=""
                                  /> */}
                                {/* module="limited" type_id={9} cname={row.InstitutionName} page="limitedpartner" cid={row.LPId} */}
                                {/* {showNote ?
                                    <AddNote module="limited" page="detailviewLp" type_id={Constants.moduleIds.limitedPartnerId} cname={row.lpName} cid={partnerId} /> : ""} */}
                                {/* </Tooltip> */}
                              </div>
                              <div className="col-span-1 mt-1">
                                <PENotesPopup note={note} cid={partnerId} type_id={Constants.moduleIds.limitedPartnerId} />
                              </div>
                              <div className="col-span-1">
                                <SendDealPopUp data={props?.data?.companyname} />
                              </div>
                              <div className="col-span-1">
                                <PdfDownloader
                                  url={exportPdf}
                                  fileName={row?.lpName?.replaceAll(/\s/g, '')}
                                  data={{
                                    entity_id: partnerId,
                                    type_id: Constants.moduleIds.limitedPartnerId,
                                  }} />
                                {/* <img className="w-[25px] h-[27px]" src={pdf} alt="" /> */}


                              </div>

                            </div>
                          </div>

                        </div>

                      </li>
                    </ul>
                  </div>
                </div>
                {/* <!-- tags--> */}
                <div className="bg-[#FFFFFF]">




                </div>

              </div>
            )
          })
          :
          <table className="h-[10rem] w-full bg-[#FFFFFF] ">
            <center><div role="status" className="bg-white  h-[16rem]">
              <svg className="relative top-[6rem] inline mr-2 w-12 h-12 text-gray-200 animate-spin dark:text-gray-600 fill-[#CA8A24]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
              </svg>
            </div></center>
          </table>

        }
       

      </div>
      <div className="bg-[#EDEDED]">
        <div className="flex flex-row overflow-x-auto scrollbar space-x-6 bg-white px-6 pt-1  border-b border-[#D7D7D7] cursor-pointer sticky top-0  custom-z-index scrollbar-remove">
          <Tab active={isActive === 1} label={"Overview"} onClick={() => onTabClick(1)} />
          <Tab active={isActive === 2} label={"Limited Partners in"} onClick={() => onTabClick(2)} />

          <Tab active={isActive === 3} label={"Recent News & Activity"} onClick={() => onTabClick(3)} />
        </div>
        <div className="space-y-5">
          <section ref={overviewEl} className="scroll-mt-14" id="profile"><Overview Overview={overviewsection} /></section>
          <section ref={limitedpartnersEl} className="scroll-mt-14" id="investments"><FundingDataProvider><LimitedPartnersin menu={props.menu} exportFileName={exportFileName} /></FundingDataProvider> </section>
          <section ref={newsEl} className="scroll-mt-14" id="news"><RecentNewsActivity Lparray={overviewsection} LP_NEWSLETTER={LP_NEWSLETTER} /></section>
        </div>
      </div>
      {showModal ? (<ComparePopUp onClose={(e) => setShowModal(false)} />) : null}
    </>
  )

}

export default InvestorLimitedPartnerDetail