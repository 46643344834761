import React, { useState, useEffect, useContext } from "react"
import { Link, NavLink, useNavigate } from 'react-router-dom';
import MonthPickerComponent from '../../Utils/MonthPickerComponent'
import { TableDownloadContext } from '../../Contexts/TableDownloadContext'
import line from "../../images/line.png"
import xls from "../../images/xls/png/Group 47.png"
import FileIcon from "../../images/file.png"
import FileIconBlur from "../../images/fileblur.png"
import ExcelPopup from "../../Components/ExcelPopup";
import moment from "moment";
import '../MainMenu.css'
import { routeNames } from "../../routeSegments";
import ExportPopup from "../../Components/ExportPopup/FundsExcel";
import { data, vclimitedPartnerProfileExportData ,vclimitedPartnerexitExportData } from "../../Components/ExportPopup/ExportData"
import { exportLPExcel } from "../../Config/config";
import { UserContext } from "../../Contexts/UserContext";
   
function MainMenu(props) {
    console.log(props, "menuprops");
    const { handleDownloadExcel } = useContext(TableDownloadContext)
    const [openTab, setOpenTab] = React.useState(1);
    const [showPopup, setShowPopup] = useState(false);
    const [date, setDate] = useState([moment().subtract(12, 'months'), moment().startOf('second')])
    const { isStudent} = useContext(UserContext)


    useEffect(() => {
        setOpenTab(props.active)
    }, [openTab])


    const download = () => {
        handleDownloadExcel()
    } 
    return (
        <div className="pt-0 pl-3 pr-4">

            <div className="scrollbar rounded  ">
                <div className="px-2  rounded-b-none bg-white block lg:flex flex-row justify-between shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
                    <div className="flex flex-row space-x-5 ">
                        <div className="py-4  pl-3">
                            <NavLink className={"whitespace-nowrap text-[12pt] leading-[17px] lg:text-[14px] lg:leading-[17pt]" + 
                            (openTab === 1 ? " font-semibold  border-b-[3px] border-[#F1CA75] py-4 pt-[1.1rem]"
                                : "whitespace-nowrap font-sans_medium_body text-[#333333] opacity-[.4] hover:opacity-100")
                            }
                                data-toggle="tab"
                                to={props.menu == "vcinvestor" ? routeNames.vc_investors_vc_limited_partners : routeNames.vc_investors_vc_limited_partners}
                                role="tablist"
                            >
                                <div className="group inline-block">
                                    <button
                                        className="outline-none w-[7rem] focus:outline-none py-1 bg-white rounded-sm flex items-center"
                                    >
                                        <span className="  flex-1 text-[14px] leading-[15px]">
                                            {props.menu == "vc" ? "VC Investments" : "VC Investments"}
                                        </span>
                                        {/* <span>
                                            <svg
                                                className="fill-current h-4 w-4 transform group-hover:-rotate-180 
                                            transition duration-150 ease-in-out"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 20 20"
                                            >
                                                <path
                                                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                                                />
                                            </svg>
                                        </span> */}
                                    </button>
                                    {/* {
                                            props.menu == "vcinvestor" ?
                                    <ul
                                        className=" border border-gray-300   absolute  mt-1 bg-white rounded-sm transform scale-0 group-hover:scale-100 z-10 absolute 
                                    transition duration-150 ease-in-out origin-top min-w-32"
                                    >
                                        <NavLink to='/investors/pe-vc-investments' className="rounded-sm px-3 py-1 hover:bg-gray-100 text-[#A5711A] text-[13px] leading-[15px] text-[13px] leading-[15px]">PE-VC Investments</NavLink>
                                    </ul>
                                    :
                                    ""
                                     } */} 
                                </div>
                            </NavLink> 
                            {/* ma_vc /investors/exists/ma/vc-limited-partners
                                       public_vc /investors/exists/pm/vc-limited-partners
                                       /investors/exists/pe-vc-limited-partners */}
                        </div>
                        <div className="py-4 pl-2 lg:pl-0">
                            <NavLink className={" whitespace-nowrap text-[12pt] leading-[17pt] lg:text-[13px] lg:leading-[17px] pl-2" + (openTab === 2 ? "text-[#333333] font-semibold  border-b-[3px] border-[#F1CA75] py-4 pt-[1.1rem]"
                                : " whitespace-nowrap font-sans_medium_body text-[#333333] opacity-[.4] hover:opacity-100 ")
                            }
                                to={ 
                                        props.menu == "ma_vc" ? routeNames.vc_investors_exists_ma_vc_limited_partners :
                                            props.menu == "public_vc" ? routeNames.vc_investors_exists_pm_vc_limited_partners :
                                            routeNames.vc_investors_exists_vc_limited_partners}
                                data-toggle="tab"
                                role="tablist"
                            >
                                <div className="group inline-block">
                                    <button
                                        className="outline-none w-30  justify-between focus:outline-none py-1 bg-white rounded-sm flex items-center "
                                    >
                                        <span className="flex lg:text-[14px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                            {
                                                    props.menu == "ma_vc" ? "M&A (VC)" :
                                                        props.menu == "public_vc" ? "Public Market (VC)" : "VC Exits"}</span>
                                        <span>
                                            <svg
                                                className="fill-current h-4 w-4 transform group-hover:-rotate-180
                                            transition duration-150 ease-in-out"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 20 20"
                                            >
                                                <path
                                                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                                                />
                                            </svg>
                                        </span>
                                    </button>

                                    <ul
                                        className="mt-1 bg-white rounded-sm transform scale-0 group-hover:scale-100 z-10  absolute 
                                    transition duration-150 ease-in-out origin-top min-w-[8rem]"
                                    >
                                        {
                                            
                                                    props.menu == "ma_vc" ?
                                                       

                                                            <ul
                                                                className="bg-white  bg-white py-1 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 absolute top-0 right-0  transition duration-150 ease-in-out origin-top-left min-w-32"
                                                            >
                                                                 <li className="rounded-sm font-sans_book_body px-6 py-2 relative hover:bg-gray-100  ">
                                                            <NavLink to={routeNames.vc_investors_exists_vc_limited_partners}
                                                                className=" navlink w-full text-left flex items-center outline-none focus:outline-none">
                                                                <span className=" flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">VC Exits
                                                                </span>
                                                                {/* <span className="mr-auto pl-16">
                                                                    <svg
                                                                        className="fill-current h-4 w-4  transition duration-150 ease-in-out"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 20 20"
                                                                    >
                                                                        <path
                                                                            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                                                                        />
                                                                    </svg>
                                                                </span> */}
                                                            </NavLink>
                                                            </li>
                                                                 
                                                                <li className="rounded-sm font-sans_book_body px-6 py-2 relative hover:bg-gray-100  ">
                                                                    <NavLink to={routeNames.vc_investors_exists_pm_vc_limited_partners}
                                                                        className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                                                    >
                                                                        <span className="pr-1 flex-1 text-[#333333]  text-[13px] leading-[15px]">Public Market (VC)</span>
                                                                        <span className="mr-auto">
                                                                            {/* <svg
                                                                                className="fill-current h-4 w-4  transition duration-150 ease-in-out"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                viewBox="0 0 20 20"
                                                                            >
                                                                                <path
                                                                                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                                                                                />
                                                                            </svg> */}
                                                                        </span>
                                                                    </NavLink>
                                                                    {/* <ul
                                                                        className="bg-white border border-gray-300 rounded-sm absolute top-0 right-0  transition duration-150 ease-in-out origin-top-left   min-w-32 "
                                                                    >
                                                                        <li className="pr-16 py-1 px-3 hover:bg-gray-100 text-[#A5711A] text-[13px] leading-[15px]"><NavLink to='/investors/exists/pm/vc-limited-partners'>Public Market (VC)</NavLink></li>
                                                                    </ul> */}
                                                                </li>
                                                            </ul>

                                                      
                                                        :
                                                        props.menu == "public_vc" ?
                                                            

                                                                <ul
                                                                    className="bg-white  bg-white py-1 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 absolute top-0 right-0  transition duration-150 ease-in-out origin-top-left min-w-32"
                                                                >
                                                                    <li className="rounded-sm font-sans_book_body px-6 py-2 relative hover:bg-gray-100  ">
                                                                <NavLink to={routeNames.vc_investors_exists_vc_limited_partners}
                                                                    className=" navlink w-full text-left flex items-center outline-none focus:outline-none">
                                                                    <span className=" flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">VC Exits
                                                                    </span>
                                                                    {/* <span className="mr-auto pl-16">
                                                                        <svg
                                                                            className="fill-current h-4 w-4    transition duration-150 ease-in-out"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 20 20"
                                                                        >
                                                                            <path
                                                                                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                                                                            />
                                                                        </svg>
                                                                    </span> */}
                                                                </NavLink>
                                                                </li>
                                                                    <li className="rounded-sm font-sans_book_body px-6 py-2 relative hover:bg-gray-100  ">
                                                                        <NavLink to={routeNames.vc_investors_exists_ma_vc_limited_partners}
                                                                            className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                                                        >
                                                                            <span className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">Via M&A (VC)</span>
                                                                            <span className="mr-auto">
                                                                                {/* <svg
                                                                                    className="fill-current h-4 w-4    transition duration-150 ease-in-out"
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    viewBox="0 0 20 20"
                                                                                >
                                                                                    <path
                                                                                        d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                                                                                    />
                                                                                </svg> */}
                                                                            </span>
                                                                        </NavLink>
                                                                        {/* <ul
                                                                            className="bg-white border border-gray-300 rounded-sm absolute top-0 right-0     transition duration-150 ease-in-out origin-top-left    min-w-32    "
                                                                        >
                                                                            <li className="pr-28 py-1 px-3 mr-2 hover:bg-gray-100 text-[#A5711A] text-[13px] leading-[15px]"><NavLink to='/investors/exists/ma/vc-limited-partners'>M&A (VC)</NavLink></li>
                                                                        </ul> */}
                                                                    </li>
                                                                    
                                                                </ul>

                                                           
                                                            :
                                                            props.menu == "exit-ma-pe-vc" ?
                                                               

                                                                    <ul
                                                                        className="bg-white  bg-white py-1 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 absolute top-0 right-0  transition duration-150 ease-in-out origin-top-left min-w-32"
                                                                    >
                                                                         <li className="rounded-sm font-sans_book_body px-6 py-2 relative hover:bg-gray-100 ">
                                                                    <NavLink to={routeNames.vc_investors_exists_vc_limited_partners}
                                                                        className=" navlink w-full text-left flex items-center outline-none focus:outline-none">
                                                                        <span className=" flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">PE-VC Exits
                                                                        </span>
                                                                        {/* <span className="mr-auto pl-16">
                                                                            <svg
                                                                                className="fill-current h-4 w-4    transition duration-150 ease-in-out"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                viewBox="0 0 20 20"
                                                                            >
                                                                                <path
                                                                                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                                                                                />
                                                                            </svg>
                                                                        </span> */}
                                                                    </NavLink>
                                                                    </li>
                                                                        <li className="rounded-sm font-sans_book_body px-6 py-2 relative hover:bg-gray-100  ">
                                                                            <NavLink to={routeNames.vc_investors_exists_ma_vc_limited_partners}
                                                                                className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                                                            >
                                                                                <span className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">Via M&A (VC)</span>
                                                                                <span className="mr-auto">
                                                                                    {/* <svg
                                                                                        className="fill-current h-4 w-4    transition duration-150 ease-in-out"
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        viewBox="0 0 20 20"
                                                                                    >
                                                                                        <path
                                                                                            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                                                                                        />
                                                                                    </svg> */}
                                                                                </span>
                                                                            </NavLink>
                                                                            {/* <ul
                                                                                className="bg-white border border-gray-300 rounded-sm absolute top-0 right-0   transition duration-150 ease-in-out origin-top-left  min-w-32   "
                                                                            >
                                                                                <li className="pr-28 py-1 px-3 mr-2 hover:bg-gray-100 text-[#A5711A]"><NavLink to='/investors/exists/ma/vc-limited-partners'>M&A (VC)</NavLink></li>
                                                                            </ul> */}
                                                                        </li>
                                                                        <li className="rounded-sm font-sans_book_body px-6 py-2 relative hover:bg-gray-100  ">
                                                                            <NavLink to={routeNames.pe_companies_exists_pm_pe_vc_investments}
                                                                                className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                                                            >
                                                                                <span className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">Via Public Market (VC)</span>
                                                                                <span className="mr-auto">
                                                                                    {/* <svg
                                                                                        className="fill-current h-4 w-4    transition duration-150 ease-in-out"
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        viewBox="0 0 20 20"
                                                                                    >
                                                                                        <path
                                                                                            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                                                                                        />
                                                                                    </svg> */}
                                                                                </span>
                                                                            </NavLink>
                                                                            {/* <ul
                                                                                className="bg-white border border-gray-300 rounded-sm absolute top-0 right-0    transition duration-150 ease-in-out origin-top-left  min-w-32 "
                                                                            >
                                                                                <li className="pr-16 py-1 px-3 hover:bg-gray-100 text-[#A5711A] text-[13px] leading-[15px]"><NavLink to='/investors/exists/pm/vc-limited-partners'>Public Market (VC)</NavLink></li>
                                                                            </ul> */}
                                                                        </li>
                                                                    </ul>

                                                               
                                                                :
                                                                <ul
                                                                    className="bg-white  bg-white py-1 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 absolute top-0 right-0  transition duration-150 ease-in-out origin-top-left min-w-32"
                                                                >
                                                                    <li className="rounded-sm font-sans_book_body px-6 py-2 relative hover:bg-gray-100  ">
                                                                        <NavLink to={routeNames.vc_investors_exists_ma_vc_limited_partners}
                                                                            className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                                                        >
                                                                            <span className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">Via M&A (VC)</span>
                                                                            <span className="mr-auto">
                                                                                {/* <svg
                                                                                    className="fill-current h-4 w-4  transition duration-150 ease-in-out"
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    viewBox="0 0 20 20"
                                                                                >
                                                                                    <path
                                                                                        d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                                                                                    />
                                                                                </svg> */}
                                                                            </span>
                                                                        </NavLink>
                                                                        {/* <ul
                                                                            className="bg-white border border-gray-300 rounded-sm absolute top-0 right-0  transition duration-150 ease-in-out origin-top-left  min-w-32 "
                                                                        >
                                                                            <li className="pr-28 py-1 px-3 mr-2 hover:bg-gray-100 text-[#A5711A] text-[13px] leading-[15px]"><NavLink to='/investors/exists/ma/vc-limited-partners'>M&A (VC)</NavLink></li>
                                                                        </ul> */}
                                                                    </li>
                                                                    <li className="rounded-sm font-sans_book_body px-6 py-2 relative hover:bg-gray-100  ">
                                                                        <NavLink to={routeNames.vc_investors_exists_pm_vc_limited_partners}
                                                                            className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                                                        >
                                                                            <span className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">Via Public Market (VC)</span>
                                                                            <span className="mr-auto">
                                                                                {/* <svg
                                                                                    className="fill-current h-4 w-4 transition duration-150 ease-in-out"
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    viewBox="0 0 20 20"
                                                                                >
                                                                                    <path
                                                                                        d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                                                                                    />
                                                                                </svg> */}
                                                                            </span>
                                                                        </NavLink>
                                                                        {/* <ul
                                                                            className="bg-white border border-gray-300 rounded-sm absolute top-0 right-0  transition duration-150 ease-in-out origin-top-left  min-w-32 "
                                                                        >
                                                                            <li className="pr-16 py-1 px-3 hover:bg-gray-100 text-[#A5711A] text-[13px] leading-[15px]"><NavLink to='/investors/exists/pm/vc-limited-partners'>Public Market (VC)</NavLink></li>
                                                                        </ul> */}
                                                                    </li>
                                                                </ul>
                                        }

                                    </ul>
                                </div>
                            </NavLink>
                        </div>
                         

                        {/* <div className="lg:py-4 py-5 pl-3 lg:pl-2 ">
                            <NavLink className={"whitespace-nowrap text-[14pt] leading-[17pt] lg:text-[13px] lg:leading-[15px] " + (openTab === 4 ? "text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt] font-semibold border-t-2 border-yellow-500 pt-[1.1rem]"
                                : " whitespace-nowrap font-sans_medium_body text-[#333333] opacity-[.4] hover:opacity-100  ")
                            }
                                // onClick={e=> {
                                //     e.preventDefault();
                                //     setOpenTab(4)
                                // }}
                                data-toggle="tab"
                                to='/investors/angel-invesments'
                                role="tablist"
                            >
                                Angel Investments
                            </NavLink>

                        </div>
                        <div className="lg:py-4 lg:pl-1 pl-2 py-5">
                            <NavLink className={"whitespace-nowrap text-[14pt] leading-[17pt] lg:text-[13px] lg:leading-[15px]" + (openTab === 5 ? "text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt] font-semibold border-t-2 border-yellow-500 pt-[1.1rem]"
                                : "whitespace-nowrap font-sans_medium_body text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt] opacity-[.4] hover:opacity-100    ")
                            }
                                // onClick={e=> {
                                //     e.preventDefault();
                                //     setOpenTab(5)
                                // }}
                                data-toggle="tab"
                                to='/investors/incubation'
                                role="tablist"
                            >
                                Incubation
                            </NavLink>
                        </div> */}
                    </div>
                    <div className="flex justify-between px-5 lg:px-2 items-center">
                        {/* <label htmlFor="" className="text-[#666666] w-[45%] ">
                            <div className="flex">
                                <MonthPickerComponent date={date}/>
                            </div>
                        </label> */}
                        <div className="flex">
                            {isStudent == 'true' ? <></> :
                            <a className=" px-4">
                                <button >
                                    <img src={xls} className="h-[24px] mt-1 w-[21px]" alt=""
                                        onClick={() => setShowPopup(!showPopup)}
                                    />
                                   {showPopup && (
                                                <ExportPopup
                                                    modules="lp_advisor"
                                                    module_type="vc"
                                                   fileName={props.exportFileName}
                                                    url={exportLPExcel}
                                                    onClose={() => setShowPopup(false)}
                                                    exportOptions={props.menu === "vcinvestor" ? vclimitedPartnerProfileExportData  : vclimitedPartnerexitExportData} type={
                                                                       props.menu === "vcinvestor" ? "vc_investment"  :  props.menu === "ma_vc" ? "vc_ma_exit" :props.menu === "public_vc" ? "vc_pm_exit" : "pe_vc_exit" 
                                                                  }
                                                />
                                               
                                            )}
                                </button>
                            </a>
                            }
                            {/* <a> 
                                <img className="h-[24px] w-[2px] mt-1 " src={line}/>
                            </a> */}
                            {/* <a className="pl-4 group">

                            <div className="group-hover:hidden"><img src={FileIconBlur} className="h-[24px] mt-1 w-[21px]" alt="" /></div>

                            <div className="hidden group-hover:flex"><img src={FileIcon} className="h-[24px] mt-1 w-[21px]" alt="" /></div>

                                
                            </a> */}

                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainMenu