import React from 'react'
import Layout from '../Components/Layout'
import CompareCompanyHeader from '../Components/CompareCompanies/CompanyComapareHeader'
import CompareCompanySearch from '../Components/CompareCompanies/CompanyCompareSearch'
import CompanyCompareDetail from '../Components/CompareCompanies/Company'
import menuOpen from '../images/Group 12.png';
import {  useNavigate} from "react-router-dom";
import '../index.css'
/**
 * The `CompareCompany` function is a React component that renders a layout for comparing company
 * profiles.
 * @returns The component is returning a JSX element, which represents the structure and content of the
 * component's output.
 */
const CompareCompany = ({ module }) => {
    // const { compareCompanyId, currentPage } = useContext(CompareCompanyContext);
    // const { companyId, investorId } = useParams();
    // let companyProfileId = parseInt(companyId)
    // let investorProfileId = parseInt(investorId)
    const navigate = useNavigate();



    return (
        <div>
            <>
                <Layout>
                    <div className='flex h-screen flex-col'>
                        <div className="flex mt-[3.5rem] justify-between lg:px-4 lg:pt-2 lg:pb-3 items-center">
                            <div >
                                <button
                                    onClick={(e) => navigate(-1)}
                                    // to={state?.prevPath != null ? state?.prevPath
                                    //     : module == "company"
                                    //         ? routeNames.pe_companies_pe_vc_investments
                                    //         : module == "investor"
                                    //             ? routeNames.pe_investors_pe_vc_investments
                                    //             : module == "l_advisor"
                                    //                 ? routeNames.pe_advisors_pe_vc_advisors
                                    //                 : routeNames.pe_transaction_advisors_pe_vc_advisors}
                                                    >
                                    <img src={menuOpen} className="w-[25px] h-[25px] cursor-pointer" alt="back" />
                                </button>
                            </div>
                            <div className=''>
                                <CompareCompanySearch />
                            </div>
                        </div>
                        <div className="flex lg:block lg:mx-4 lg:mb-4 bg-white h-full min-w-0 flex-1 flex-col overflow-y-auto lg:order-last ">
                            <div className='flex'>
                                <div className="min-w-[15rem]" style={{ border: "1px solid rgba(151, 151, 151, 0.5)", borderRightStyle: "none" }}>
                                    <CompareCompanyHeader />
                                </div>
                                <div className="flex overflow-x-auto bg-white divide-x  scrollbar-none" style={{ border: "1px solid rgba(151, 151, 151, 0.5)" }}>
                                    <CompanyCompareDetail />
                                </div>
                            </div>
                        </div>
                    </div>
                </Layout>
            </>
        </div>
    )
}

export default CompareCompany
