import React, {useState, useMemo} from 'react'
import { useQuery } from '@apollo/client'
import companyIcon from '../../../images/noun-investment-2180740_5/png/noun-investment-2180740.png';

/**
 * The Incubator function is a React component that renders a card displaying the number of incubators
 * and an icon.
 * @returns The Incubator component is returning a JSX element, which represents the structure and
 * content of the component's output.
 */
function Incubator({TOTAL_COMPANIES_CARD_QUERY}) {
    return (
        <div className="flex-shrink-0">
            {/* ----card4----- */}
            <section >
                <div className=" bg-white px-3 py-1 rounded-md  border border-gray-200 overflow-hidden card-animate card-animate">
                    <div className="grid grid-cols-6 ">
                        <div className="col-span-5 ">
                            <div className="text-[13px] leading-[17px] font-sans_book_body tracking-[-0.3px]">Incubators</div>
                            {/* {TotalCompaniesCardData? */}
                            <div className="flex space-x-1  tracking-[-0.3px]">
                                <div className="  ">
                                    <span
                                        className=' leading-[23px] font-sans_bold_body text-[17px] text-[#A5711A] '>100</span>
                                </div>
                            </div>
                        </div>
                        <div className="flex-shrink-0 flex items-center justify-center">
                            <a href="#">
                                <img className="h-[26px] max-w-[25px]"
                                    src={companyIcon} />
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Incubator
