import React, { useState, useEffect, useContext } from "react"
import { NavLink } from 'react-router-dom';
import MonthPickerComponent from '../../Utils/MonthPickerComponent'
import { TableDownloadContext } from '../../Contexts/TableDownloadContext'
import moment from 'moment';
import line from "../../images/line.png"
import xls from "../../images/xls/png/Group 47.png"
import FileIcon from "../../images/file.png"
import FileIconBlur from "../../images/fileblur.png"
// import ExcelPopup from "../../Components/ExcelPopup";
import '../MainMenu.css'
import { routeNames } from "../../routeSegments";
import ExportPopup from "../ExportPopup";
import { vcInvestmentExportData, vcExitExportData, vcbackedIPOExportData, vcangelInvestmentExportData, vcincubationExportData } from "../ExportPopup/ExportData";

import SavedFilterContent from "../../Components/SavedFilter";
import SavedFilterICON from "../../Components/SavedFilter/SavedFilterICON";
import { exportPDF } from "../../Config/config";
import { TableDataContext } from "../../Contexts/TableDataContext";
import { PeFilterContext } from "../../Contexts/PeFilterContext";
import Constants from "../../Constants";
import { UserContext } from "../../Contexts/UserContext";
import { useLocation } from "react-router";
import ExcelDownloader from "../shared/ExcelDownloader";
import { FaFilter } from "react-icons/fa";
import { IoClose  } from "react-icons/io5";


function MainMenu(props) {
    console.log(props, "menuprops");
    // const [save, setSave] = useState(false);
    const {save, setSave,showMonthPicker ,refineSearchFilters,groupId, dealsView, isMobile,setOpen,open,setIsMobile} = useContext(PeFilterContext);
    const {globalSearchResult} = useContext(TableDataContext);
    const { pathname } = useLocation();
    const { handleDownloadExcel } = useContext(TableDownloadContext)
    const {setCurrentPage} =useContext(TableDataContext);

    const [openTab, setOpenTab] = React.useState(1);
    const [showPopup, setShowPopup] = useState(false);
    const [date, setDate] = useState([
        moment().startOf("month").subtract(12, "months").format("YYYY-MM-DD"),
        moment().endOf("month").format("YYYY-MM-DD"),
    ]);
    /**
     * The function `savedList` toggles the value of the `save` variable.
     */
    const savedList = () => {
        setSave(!save)
    }
    const { isStudent,isTrialExport} = useContext(UserContext);

    const category =
      props.menu == "vc"
        ? "pevc"
        : props.menu == "exit-vc"
        ? "exit"
        : props.menu == "backedIpos-pe-vc" || props.menu == "VC-IPOs"
        ? "ipo"
        : props.menu == "angel" || props.menu == "vc-angel"
        ? "angel"
        : props.menu == "incubation" || props.menu == "vc-incubation"
        ? "incubation"
        : "";

        const menuTitles = {
          "vc": "VC Companies VC Investment - Venture Intelligence",
          "exit-vc": "VC Companies VC Exits - Venture Intelligence",
          "exit-ma-vc": "VC Companies Exits Via M&A (VC) - Venture Intelligence",
          "exit-pm-vc": "VC Companies Exits Via Public Market (VC) - Venture Intelligence",
          "VC-IPOs": "VC Companies VC Backed IPOs - Venture Intelligence",
          "angel": "VC Companies Angel Investments - Venture Intelligence",
          "incubation": "VC Companies Incubation - Venture Intelligence"
        };
        
        document.title = menuTitles[props.menu] || "Venture Intelligence";
        

        // console.log(props.menu , "ccccc")


    /* The above code is a React useEffect hook that sets the value of the "openTab" state variable to
    the value of the "props.active" prop. It is triggered whenever the "openTab" state variable
    changes. */
    useEffect(() => {
        setOpenTab(props.active)
    }, [openTab])

    useEffect(()=>{
      if (window.innerWidth < 800) {
          setOpen(true);
          setIsMobile(true);
      }    },[])


    // const download = () => {
    //     handleDownloadExcel()
    // }
    const isFromGlobalSearch = ()=> refineSearchFilters[pathname]?.globalSearch?.length > 0 ? true : false

    return (
      <div className="pt-0 pl-3 pr-4">
        <div className="scrollbar  rounded">
          <div className="px-2  md:px-3 rounded-[6px] rounded-b-none bg-white block lg:flex flex-row justify-between shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
            <div className="flex flex-row  space-x-5 overflow-x-auto scrollbar-remove">
              <div className="py-4 px-2 font-[Squeal Sans]">
                <NavLink
                  className={
                    "whitespace-nowrap text-[12pt] leading-[17px] lg:text-[14px] lg:leading-[17pt]" +
                    (openTab === 1
                      ? " font-bold  border-b-[3px] border-[#F1CA75] py-4 pt-[1.1rem]"
                      : "whitespace-nowrap font-sans_medium_body text-[#2B2A29] opacity-[.8] hover:opacity-100")
                  }
                  data-toggle="tab"
                  to={
                    props.menu == "vc"
                      ? routeNames.vc_companies_vc_investments
                      : routeNames.vc_companies_vc_investments
                  }
                  role="tablist"
                >
                  <div className="group inline-block">
                    <button
                      onClick={() => setCurrentPage(1)}
                      className="outline-none w-[7rem] focus:outline-none py-1 bg-white rounded-sm flex items-center "
                    >
                      <span className="  flex lg:text-[14px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                        {props.menu == "vc"
                          ? "VC Investments"
                          : "VC Investments"}
                      </span>
                    </button>
                    {/* {
                                            props.menu == "vcinvestor" ?
                                    <ul
                                        className=" border border-gray-300   absolute  mt-1 bg-white rounded-sm transform scale-0 group-hover:scale-100 z-10 absolute 
                                    transition duration-150 ease-in-out origin-top min-w-32"
                                    >
                                        <NavLink to='/companies/pe-vc-investments' className="rounded-sm px-3 py-1 hover:bg-gray-100 text-[#A5711A] text-[13px] leading-[15px] text-[13px] leading-[15px]">PE-VC Investments</NavLink>
                                    </ul>
                                    :
                                    ""
                                     } */}
                  </div>
                </NavLink>
              </div>
              {isFromGlobalSearch() && !dealsView ? 
                ( <></> ) : (
                <>
                  <div className="py-4 ">
                    <NavLink
                      className={
                        " whitespace-nowrap text-[12pt] leading-[17pt] lg:text-[13px] lg:leading-[17px] " +
                        (openTab === 2
                          ? "text-[#333333] font-bold  border-b-[3px] border-[#F1CA75] py-4 pt-[1.1rem]"
                          : " whitespace-nowrap font-sans_medium_body text-[#2B2A29] opacity-[.8] hover:opacity-100 ")
                      }
                      to={
                        props.menu == "exit-ma-vc"
                          ? routeNames.vc_companies_exists_ma_vc_investments
                          : props.menu == "exit-pm-vc"
                          ? routeNames.vc_companies_exists_pm_vc_investments
                          : routeNames.vc_companies_exists_vc_investments
                      }
                      data-toggle="tab"
                      role="tablist"
                    >
                      {isFromGlobalSearch() ? (
                        <div className="group inline-block">
                          <button
                            onClick={() => setCurrentPage(1)}
                            className="outline-none w-30  justify-between focus:outline-none py-1 bg-white rounded-sm flex items-center "
                          >
                            <span className="  flex lg:text-[14px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                              {props.menu == "exit-ma-vc"
                                ? "M&A (VC)"
                                : props.menu == "exit-pm-vc"
                                ? "Public Market (VC)"
                                : "VC Exits"}
                            </span>
                          </button>
                        </div>
                      ) : (
                        <div className="group inline-block">
                          <button
                            onClick={() => setCurrentPage(1)}
                            className="outline-none w-30  justify-between focus:outline-none py-1 bg-white rounded-sm flex items-center "
                          >
                            <span className="  flex lg:text-[14px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                              {props.menu == "exit-ma-vc"
                                ? "M&A (VC)"
                                : props.menu == "exit-pm-vc"
                                ? "Public Market (VC)"
                                : "VC Exits"}
                            </span>
                            <span>
                              <svg
                                className="fill-current h-4 w-4 transform group-hover:-rotate-180
                                                transition duration-150 ease-in-out"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                              >
                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                              </svg>
                            </span>
                          </button>

                          <ul
                            className="mt-2 bg-white rounded-lg shadow-lg lg:ring-1 ring-black ring-opacity-5 transform scale-0 group-hover:scale-100 z-10 absolute 
                                        transition duration-150 ease-in-out origin-top min-w-[9rem] md:min-w-[12.5rem]"
                          >
                            {props.menu == "exit-ma-vc" ? (
                              <ul className=" bg-white  py-1 rounded-lg shadow-lg lg:ring-1 ring-black ring-opacity-5 absolute top-0 md:right-0 right-[26px] transition duration-150 ease-in-out origin-top-left min-w-32">
                                <li className="rounded-sm font-sans_book_body px-6 py-2 relative hover:bg-gray-100  ">
                                  <NavLink
                                    to={
                                      routeNames.vc_companies_exists_vc_investments
                                    }
                                    className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                  >
                                    <span
                                      onClick={() => setCurrentPage(1)}
                                      className=" flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]"
                                    >
                                      VC Exits
                                    </span>
                                  </NavLink>
                                </li>

                                <li className="rounded-sm font-sans_book_body px-6 py-2 relative hover:bg-gray-100  ">
                                  <NavLink
                                    to={
                                      routeNames.vc_companies_exists_pm_vc_investments
                                    }
                                    className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                  >
                                    <span
                                      onClick={() => setCurrentPage(1)}
                                      className="pr-1 flex-1 text-[#333333]  text-[13px] leading-[15px]"
                                    >
                                      Via Public Market (VC)
                                    </span>
                                    <span className="mr-auto"></span>
                                  </NavLink>
                                </li>
                              </ul>
                            ) : props.menu == "exit-pm-vc" ? (
                              <ul className="bg-white  bg-white py-1 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 absolute top-0 lg:right-0  right-[3%] md:right-[2rem]-0  transition duration-150 ease-in-out origin-top-left min-w-32">
                                <li className="rounded-sm font-sans_book_body px-6 py-2 relative hover:bg-gray-100  ">
                                  <NavLink
                                    to={
                                      routeNames.vc_companies_exists_vc_investments
                                    }
                                    className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                  >
                                    <span
                                      onClick={() => setCurrentPage(1)}
                                      className=" flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]"
                                    >
                                      VC Exits
                                    </span>
                                  </NavLink>
                                </li>
                                <li className="rounded-sm font-sans_book_body px-6 py-2 relative hover:bg-gray-100  ">
                                  <NavLink
                                    to={
                                      routeNames.vc_companies_exists_ma_vc_investments
                                    }
                                    className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                  >
                                    <span
                                      onClick={() => setCurrentPage(1)}
                                      className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]"
                                    >
                                      Via M&A (VC)
                                    </span>
                                    <span className="mr-auto"></span>
                                  </NavLink>
                                </li>
                              </ul>
                            ) : (
                              <ul className="bg-white  bg-white py-1 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 absolute top-0 lg:right-0  right-[3%] md:right-[2rem]-0  transition duration-150 ease-in-out origin-top-left min-w-32">
                                <li className="rounded-sm font-sans_book_body px-6 py-2 relative hover:bg-gray-100  ">
                                  <NavLink
                                    to={
                                      routeNames.vc_companies_exists_ma_vc_investments
                                    }
                                    className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                  >
                                    <span
                                      onClick={() => setCurrentPage(1)}
                                      className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]"
                                    >
                                      Via M&A (VC)
                                    </span>
                                    <span className="mr-auto">
                                      {/* <svg
                                                                                        className="fill-current h-4 w-4  transition duration-150 ease-in-out"
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        viewBox="0 0 20 20"
                                                                                    >
                                                                                        <path
                                                                                            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                                                                                        />
                                                                                    </svg> */}
                                    </span>
                                  </NavLink>
                                  {/* <ul
                                                                                className="bg-white border border-gray-300 rounded-sm absolute top-0 lg:right-0  right-[3%] md:right-[2rem]-0  transition duration-150 ease-in-out origin-top-left  min-w-32 "
                                                                            >
                                                                                <li className="pr-28 py-1 px-3 mr-2 hover:bg-gray-100 text-[#A5711A] text-[13px] leading-[15px]"><NavLink to='/companies/exists/ma/vc-investments'>M&A (VC)</NavLink></li>
                                                                            </ul> */}
                                </li>
                                <li className="rounded-sm font-sans_book_body px-6 py-2 relative hover:bg-gray-100  ">
                                  <NavLink
                                    to={
                                      routeNames.vc_companies_exists_pm_vc_investments
                                    }
                                    className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                  >
                                    <span
                                      onClick={() => setCurrentPage(1)}
                                      className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]"
                                    >
                                      Via Public Market (VC)
                                    </span>
                                    <span className="mr-auto">
                                      {/* <svg
                                                                                        className="fill-current h-4 w-4 transition duration-150 ease-in-out"
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        viewBox="0 0 20 20"
                                                                                    >
                                                                                        <path
                                                                                            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                                                                                        />
                                                                                    </svg> */}
                                    </span>
                                  </NavLink>
                                  {/* <ul
                                                                                className="bg-white border border-gray-300 rounded-sm absolute top-0 lg:right-0  right-[3%] md:right-[2rem]-0  transition duration-150 ease-in-out origin-top-left  min-w-32 "
                                                                            >
                                                                                <li className="pr-16 py-1 px-3 hover:bg-gray-100 text-[#A5711A] text-[13px] leading-[15px]"><NavLink to='/companies/exists/pm/vc-investments'>Public Market (VC)</NavLink></li>
                                                                            </ul> */}
                                </li>
                              </ul>
                            )}
                          </ul>
                        </div>
                      )}
                    </NavLink>
                  </div>
                  <div className="py-4 ">
                    <NavLink
                      className={
                        " whitespace-nowrap  text-[12pt] leading-[17pt] lg:text-[14px] lg:leading-[17px] " +
                        (openTab === 3
                          ? "text-[#333333] font-bold border-b-[3px] border-[#F1CA75] py-4 pt-[1.1rem]"
                          : "  whitespace-nowrap font-sans_medium_body text-[#2B2A29] opacity-[.8] hover:opacity-100  ")
                      }
                      to={
                        props.menu == "vc"
                          ? routeNames.vc_companies_backed_ipos_vc_investments
                          : routeNames.vc_companies_backed_ipos_vc_investments
                      }
                      data-toggle="tab"
                      role="tablist"
                    >
                      <div className="group inline-block">
                        <button
                          onClick={() => setCurrentPage(1)}
                          className="outline-none  focus:outline-none py-1 bg-white rounded-sm flex items-center "
                        >
                          <span className="  flex lg:text-[14px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                            {props.menu == "vc"
                              ? "VC Backed IPOs"
                              : "VC Backed IPOs"}
                          </span>
                        </button>
                      </div>
                    </NavLink>
                  </div>

                  <div className="lg:py-4 py-4 pl-3 lg:pl-2 ">
                    <NavLink
                      className={
                        " whitespace-nowrap  text-[12pt] leading-[17pt] lg:text-[14px] lg:leading-[17px] " +
                        (openTab === 4
                          ? "text-[#333333] font-bold border-b-[3px] border-[#F1CA75] py-4 pt-[1.1rem]"
                          : "  whitespace-nowrap font-sans_medium_body text-[#2B2A29] opacity-[.8] hover:opacity-100  ")
                      }
                      // onClick={e=> {
                      //     e.preventDefault();
                      //     setOpenTab(4)
                      // }}
                      data-toggle="tab"
                      to={routeNames.vc_companies_angel_investments}
                      role="tablist"
                    >
                      <span
                        className=" flex-1 lg:text-[14px] lg:leading-[15px] text-[12pt] leading-[14pt]"
                        onClick={() => setCurrentPage(1)}
                      >
                        {" "}
                        Angel Investments
                      </span>
                    </NavLink>
                  </div>
                  <div className="lg:py-4 lg:pl-1 pl-2 py-4">
                    <NavLink
                      className={
                        " whitespace-nowrap  text-[12pt] leading-[17pt] lg:text-[14px] lg:leading-[17px] " +
                        (openTab === 5
                          ? "text-[#333333] font-bold border-b-[3px] border-[#F1CA75] py-4 pt-[1.1rem]"
                          : "  whitespace-nowrap font-sans_medium_body text-[#2B2A29] opacity-[.8] hover:opacity-100  ")
                      }
                      // onClick={e=> {
                      //     e.preventDefault();
                      //     setOpenTab(5)
                      // }}
                      data-toggle="tab"
                      to={routeNames.vc_companies_incubation}
                      role="tablist"
                    >
                      <span
                        className=" flex-1 lg:text-[14px] lg:leading-[15px] text-[12pt] leading-[14pt]"
                        onClick={() => setCurrentPage(1)}
                      >
                        Incubation
                      </span>
                    </NavLink>
                  </div>
                </>
              )}
            </div>

            <div
                className={
                  "md:flex justify-between items-center px-3 lg:px-2 py-2 space-x-4 " +
                  (showMonthPicker != 0 ? "" : "flex")
                }
              >
                <label htmlFor="" className="text-[#666666] ">
                  <div className={showMonthPicker != 0 ? "" : "flex"}>
                    <MonthPickerComponent />
                  </div>
                </label>
                <div
                  className={
                    showMonthPicker != 0
                      ? "hidden md:flex space-x-3"
                      : "flex space-x-3"
                  }
                >
                  {isMobile && (
                  <>
                    <button
                      className="cursor-pointer lg:ml-7 ml-4 mt-2"
                      onClick={() => setOpen(!open)}
                    >
                      <FaFilter color="#bc8b20" size={21} />
                      {/* {!open && <IoClose color="#bc8b20" size={25} className="fixed z-50 top-16 right-[39vw] mb-2" />} */}
                    </button>
                    <a>
                      <img
                        className="h-[24px] max-w-[2px] mt-1 "
                        src={line}
                      />
                    </a>
                  </>
                )}
                  {isStudent == "true" ? (
                    <></>
                  ) : (
                    <>
                      <button>
                        {isTrialExport == "false" &&
                        props.typeId == Constants.moduleIds.companiesId ? (
                          <ExcelDownloader
                            url={exportPDF}
                            fileName={props?.exportFileName}
                            data={{
                              type:
                                props.menu === "vc"
                                  ? "vc_investment"
                                  : props.menu === "VC-IPOs"
                                  ? "vcipo"
                                  : props.menu === "angel"
                                  ? "angel"
                                  : props.menu === "incubation"
                                  ? "incubation"
                                  : props.menu === "exit-ma-vc"
                                  ? "vc_ma_exit"
                                  : props.menu === "exit-vc"
                                  ? "vc_exit"
                                  : props.menu === "exit-pm-vc"
                                  ? "vc_pm_exit"
                                  : "pe_vc_exit",
                            }}
                            children={
                              <img
                                src={xls}
                                className="max-h-[24px] mt-1 max-w-[21px]"
                                alt=""
                              />
                            }
                          />
                        ) : isTrialExport == "true" ? (
                          <img
                            src={xls}
                            className="h-[24px] mt-1 max-w-[21px]"
                            alt=""
                            onClick={() => setShowPopup(!showPopup)}
                          />
                        ) : (
                          <></>
                        )}
                        {showPopup && (
                          <ExportPopup
                            onClose={() => setShowPopup(false)}
                            modules="company"
                            url={exportPDF}
                            fileName={props.exportFileName}
                            module_type={
                              props.primaryTypeMasterId ===
                              Constants.primaryMasterIds.vcId
                                ? "vc"
                                : "pe"
                            }
                            exportOptions={
                              props.menu === "vc"
                                ? vcInvestmentExportData
                                : props.menu === "VC-IPOs"
                                ? vcbackedIPOExportData
                                : props.menu === "angel"
                                ? vcangelInvestmentExportData
                                : props.menu === "incubation"
                                ? vcincubationExportData
                                : vcExitExportData
                            }
                            type={
                              props.menu === "vc"
                                ? "vc_investment"
                                : props.menu === "VC-IPOs"
                                ? "vcipo"
                                : props.menu === "angel"
                                ? "angel"
                                : props.menu === "incubation"
                                ? "incubation"
                                : props.menu === "exit-ma-vc"
                                ? "vc_ma_exit"
                                : props.menu === "exit-vc"
                                ? "vc_exit"
                                : props.menu === "exit-pm-vc"
                                ? "vc_pm_exit"
                                : "pe_vc_exit"
                            }
                          />
                        )}
                      </button>
                      <a>
                        <img
                          className="h-[24px] max-w-[2px] mt-1 "
                          src={line}
                        />
                      </a>
                    </>
                  )}
                  <SavedFilterICON
                    save={save}
                    FileIcon={FileIcon}
                    FileIconBlur={FileIconBlur}
                    savedList={savedList}
                  />
                </div>
              </div>
          </div>
          {save ? (
            <SavedFilterContent
              primaryTypeMasterId={props.primaryTypeMasterId}
              subTypeMasterId={props.subTypeMasterId}
              typeId={props.typeId}
              show={save}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    );
}

export default MainMenu
