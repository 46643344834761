import React, {useContext,useEffect} from 'react';
import {TableDataContext} from '../../../Contexts/TableDataContext'
import AnnouncedValueM from './AnnouncedValueM';
import NoOfAquestions from './NoOfAquestions';
import AnnouncedValueCr from './AnnouncedValueCr';
import TotalAquestions from './TotalAquestions';

/**
 * The `Card` component renders four cards with different data based on the `data`, `isLoad`, and
 * `isDataLoad` values from the `TableDataContext`.
 * @returns The Card component is returning a JSX element.
 */
const Card = () => {
  const { data, isLoad, isDataLoad } = useContext(TableDataContext);
  useEffect(()=>{},[data, isLoad, isDataLoad])
  return (
    <div className=""> 
      <div className='sm:full md:mt-[3rem] mt-[4.25rem] h-full bg-[#F2F2F2]'>
           <div className="overflow-x-auto lg:grid grid-cols-4 gap-4  px-4 py-[0.7rem] flex xl:overflow-hidden scrollbar-remove ">
           {/* ----card1----- */}
          <AnnouncedValueM data={data} isLoad={isLoad} isDataLoad={isDataLoad} />
          {/* ----card2----- */}
          <AnnouncedValueCr data={data} isLoad={isLoad} isDataLoad={isDataLoad}/>
          {/* ----card3----- */}
          <NoOfAquestions data={data} isLoad={isLoad} isDataLoad={isDataLoad}/> 
          {/* ----card4----- */}
          <TotalAquestions data={data} isLoad={isLoad} isDataLoad={isDataLoad}/>

            </div>
        <div className='border-b-2 border-[#e5e5e5]'></div>
      </div >
    </div >
  )
}
export default Card;

