import React, { useContext, useEffect } from 'react'
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import MultiSelectEntity from '../RefineSearch/MultiSelectEntity';
import FilterWrapper from './FilterWrapper';

function DealTypeFilter({ open,
    TypeOption,
    setTypeOption,
    customPathname,
    accordionClick,
    dataPath,
    isMultiSelect = false,
    showAll = true,
    label = "Type"
}) {

    const { typeFilterResponse, refineSearchFilters } = useContext(PeFilterContext);
    /* The `useEffect` hook is used to perform side effects in a functional component. In this case,
    the effect is triggered whenever the dependencies (`typeFilterResponse`, `customPathname`,
    `refineSearchFilters`, `setTypeOption`, `dataPath`) change. */
    useEffect(() => {
        const industryIds = refineSearchFilters[customPathname]?.type?.map(value => value.id) ?? [];
        setTypeOption(typeFilterResponse[dataPath]?.map(industry => ({ ...industry, isChecked: industryIds.includes(industry.id) })))
    }, [typeFilterResponse, customPathname, refineSearchFilters, setTypeOption, dataPath])


    /**
     * The function `handleDealTypeChange` handles the change event for a deal type selection, updating
     * the state based on the selected options.
     * @param e - The parameter `e` is an event object that is passed to the `handleDealTypeChange`
     * function. It represents the event that triggered the function, such as a click or change event.
     */
    const handleDealTypeChange = (e) => {
        if (isMultiSelect) {
            const { name, checked, id } = e.target;
            let currentDeals = [];
            if (name === "allSelect") {
                currentDeals = TypeOption.map((industry) =>
                    ({ ...industry, isChecked: checked })
                );
                setTypeOption(currentDeals);
            } else {
                currentDeals = TypeOption.map((industry) =>
                    industry.id === parseInt(id) ? { ...industry, isChecked: checked } : industry
                );
                setTypeOption(currentDeals);
            }
        } else {
            const value = e.target.value
            setTypeOption(TypeOption?.map(type => ({ ...type, isChecked: value === "" ? false : type.id === parseInt(value) })))
        }
    };

    const selected = TypeOption?.filter(option => option.isChecked)?.at(0)
console.log(selected,'selected selected');
    return (
        <div className="border-b-[3px] border-[#E8E6E1]">
            <li
                className={
                    open
                        ? "bg-white relative px-3 py-1"
                        : "relative px-3 py-1"
                }
            >
                <FilterWrapper
                    isSelect={true}
                    label={isMultiSelect ? label : selected?.name ? `${label} - ` + selected?.name : label}
                    onClick={accordionClick}
                    open={open}
                />

                <div
                    className="relative overflow-hidden transition-all max-h-0 duration-700  overflow-y-auto no-scrollbar"
                    style={{ maxHeight: open ? "10rem" : "" }}
                >
                    {
                        isMultiSelect
                            ? open === true
                                ? <MultiSelectEntity
                                    handleEntityChange={handleDealTypeChange}
                                    primaryEntity={TypeOption}
                                    showAll={showAll}
                                />
                                : <></>
                            : <select name="" id="" value={selected?.id ?? ""} onChange={handleDealTypeChange}
                                className="my-2 p-2 border border-[#D9BD7F] mr-3 rounded focus:outline-none bg-white w-full">
                                <option >All</option>
                                {TypeOption && TypeOption.length > 0 ? (
                                    TypeOption.map((dealType, index) => (
                                        <option key={dealType.id} value={dealType.id}>{dealType.name}</option>
                                    )))
                                    :
                                    (
                                        <h1>No results found!</h1>
                                    )}
                            </select>
                    }
                </div>

            </li>

        </div>
    )
}

export default DealTypeFilter