import LockIcon from 'mdi-react/LockIcon';
import SortAscendingIcon from 'mdi-react/SortAscendingIcon';
import SortDescendingIcon from 'mdi-react/SortDescendingIcon';
import SortIcon from 'mdi-react/SortIcon';
import React from 'react'
import { useSortBy, useTable, usePagination } from 'react-table';
import Constants from '../../../Constants';
import Paginate from '../../Paginate';

/**
 * The `FundTable` function is a React component that renders a table with sortable columns,
 * pagination, and the ability to handle click events on specific cells.
 * @returns The function `FundTable` returns a JSX element, which represents the table component.
 */
function FundTable({ initialSort, columns, data, onBlurColumnClick }) {

    /* The `useTable` hook is used to create a table instance with the specified columns and data. It
    takes an object as its first argument, which includes the columns and data properties. The
    `disableSortRemove` property is set to true to prevent the removal of sorting when clicking on a
    sorted column. */
    const tableInstance = useTable({
        columns: columns,
        data: data,
        disableSortRemove: true,
        initialState: {
            pageSize: Constants.noOfRowPerPage,
            pageIndex: 0,
            sortBy: initialSort,
        },
    },
        useSortBy,
        usePagination,
    );

    /* The code is using object destructuring to extract specific properties from the `tableInstance`
    object returned by the `useTable` hook. */
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        page,
        prepareRow,
        gotoPage,
        state: { pageIndex, pageSize }
    } = tableInstance;


    return (
        <div className="space-y-6">
            <div className="bg-white">
                <>

                    <div className='overflow-x-auto scrollbar relative'>
                        {/* <div className='table absolute w-full h-full'>
                            <div className='table-row '>
                                <div className='table-cell invisible pointer-events-none whitespace-nowrap px-5 py-4 w-[10%]'></div>
                                <div className='table-cell bg-slate-300'>
                                    <LockIcon />
                                </div>
                            </div>
                        </div> */}
                        {/* <div className='w-full h-full flex absolute justify-end'>
                            <div className='w-[40%] bg-slate-200'>

                            </div>
                        </div> */}
                        { rows?.length > 0 ? 
                        <table className=" datatable w-full" {...getTableProps()}>

                            <thead className="text-[12pt] leading-[15pt] bg-[#EDEDED] lg:text-[13px] lg:leading-[15px] text-[#666666] font-semibold py-4 px-0 text-center ">
                                {
                                    headerGroups.map(headerGroup => (

                                        <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                                            {
                                                headerGroup.headers.map(column => (
                                                    <th {...column.getHeaderProps([
                                                        {
                                                            className: `py-3.5 px-5 whitespace-nowrap ${column.headerClassName}`,
                                                            style: column.headerStyle,
                                                        },
                                                        column.getSortByToggleProps(),
                                                    ])} title="">
                                                        <div className="flex">
                                                            <span
                                                                {...column.getHeaderProps([
                                                                    {
                                                                        className: `mr-1 ${column.headerspanClassName}`,
                                                                    },

                                                                ])}
                                                            >{column.render('Header')}</span>
                                                            {/* <span className='mr-1'>{column.render('Header')}</span> */}
                                                            {column.disableSortBy ? "" : column.isSorted
                                                                ? column.isSortedDesc
                                                                    ? <SortAscendingIcon size={16} className="mt-[0.15rem] lg:mt-0" />
                                                                    : <SortDescendingIcon size={16} className="mt-[0.15rem] lg:mt-0" />
                                                                : <SortIcon size={16} className="mt-[0.15rem] lg:mt-0" />}
                                                        </div>
                                                    </th>
                                                ))
                                            }
                                        </tr>
                                    ))
                                }
                            </thead>

                            <tbody {...getTableBodyProps()} className="relative text-[12pt] leading-[16pt] lg:text-[14px] font-sans_book_body text-left">

                                {page.length === 0
                                    ? <tr>
                                        <td colspan="8">
                                            <div className="h-[4rem] mx-auto  lg:text-[15px] text-[15px] text-[#333333] relative mt-[3rem] text-center">
                                                No data found
                                            </div>
                                        </td>
                                    </tr>
                                    : page.map(row => {
                                        prepareRow(row)
                                        return (
                                            <tr {...row.getRowProps()} className="border-b">
                                                {

                                                    row.cells.map(cell => {
                                                        console.log("cell.column.renderCol", cell.value);
                                                        if (cell.column.blurColumns || cell.value == Constants.restrictedPlaceholder) {
                                                            return (<td onClick={onBlurColumnClick} className=' relative whitespace-nowrap cursor-pointer' {...cell.getCellProps()}>
                                                                <div className='blur-sm bg-[#C9C9C9] select-none w-full h-full px-5 py-4'>{Constants.restrictedPlaceholder}</div>
                                                                <div className='absolute h-full w-full flex items-center justify-center top-0 bottom-0'>
                                                                    <LockIcon size={16} />
                                                                </div>
                                                            </td>)
                                                        }

                                                        return (
                                                            <td colSpan={cell.column.colspan} rowSpan={cell.column.rowspan} {...cell.getCellProps([
                                                                {
                                                                    className: `data-td-animate whitespace-nowrap px-5 py-4 ${cell.column.className}`,
                                                                    style: cell.column.style,
                                                                },
                                                            ])}>
                                                                {
                                                                    cell.value ? cell.render('Cell') : " "
                                                                }
                                                            </td>


                                                        )
                                                    })}
                                            </tr>

                                        )
                                    })}
                            </tbody>
                        </table>
                        :
                        <div className="h-[3rem] mx-auto  lg:text-[15px] text-[15px] text-[#333333] relative mt-[1rem] text-center">
                            No data found
                        </div>
                        }
                    </div>
                    {rows?.length <= Constants.noOfRowPerPage
                        ? <></>
                        : <Paginate
                            currentPage={pageIndex + 1}
                            totalCount={rows?.length}
                            pageSize={pageSize}
                            onPageChange={(page) => {
                                gotoPage(page - 1);
                            }}
                        />}

                </>
            </div>
        </div>
    )
}

export default FundTable