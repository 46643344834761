import {React, useState, createContext} from 'react'
import { downloadExcel } from "react-export-table-to-excel";


export const TableDownloadContext = createContext('');

/**
 * The `TableDownloadProvider` component is a context provider that allows its children components to
 * access the functionality of downloading a table as an Excel file.
 * @returns The TableDownloadProvider component is being returned.
 */
const TableDownloadProvider = ({children}) => {
    const [isDownload, setIsDownload] = useState(false);

      /**
       * The function `handleDownloadExcel` is used to download an Excel file with a specified file
       * name, sheet name, and table data.
       */
      const handleDownloadExcel = () => {
        downloadExcel({
          fileName: "download",
          sheet: "react-export-table-to-excel",
          tablePayload: {
            header,
            body: rows
          }
        });
      }
      const columns = [
        { accessor: 'name', label: 'Company' },
        { accessor: 'sector', label: 'Sector' },
        { accessor: 'total_funding', label: 'Total Funding (US$ M)' },
        { accessor: 'latest_round', label: 'Latest Round' },
        { accessor: 'latest_deal_date', label: 'Latest Deal Date' },
        { accessor: 'latest_deal_amount', label: 'Latest Deal Amount (US$ M)' },
        { accessor: 'city', label: 'City' },
        { accessor: 'add', label: 'Add' },
      ]
    
      const rows = [
        { id: 1, name: 'BarRaiser', sector: 'E-Commerce', total_funding: '10.00', latest_round: '3', latest_deal_date: 'May 2022', latest_deal_amount:'8.00', city:'Bangalore', add:'false'},
        { id: 2, name: 'Kanara Capital', sector: 'E-Commerce', total_funding: '10.00', latest_round: '3', latest_deal_date: 'May 2022', latest_deal_amount:'8.00', city:'Bangalore', add:'false'},
        { id: 3, name: 'Excitel', sector: 'E-Commerce', total_funding: '10.00', latest_round: '3', latest_deal_date: 'May 2022', latest_deal_amount:'8.00', city:'Bangalore', add:'false'},
        { id: 4, name: 'ApnaKlub', sector: 'E-Commerce', total_funding: '10.00', latest_round: '3', latest_deal_date: 'May 2022', latest_deal_amount:'8.00', city:'Bangalore', add:'false'},
        { id: 5, name: 'ApnaKlub5', sector: 'E-Commerce', total_funding: '10.00', latest_round: '3', latest_deal_date: 'May 2022', latest_deal_amount:'8.00', city:'Bangalore', add:'false'},
        { id: 6, name: 'ApnaKlub6', sector: 'E-Commerce', total_funding: '10.00', latest_round: '3', latest_deal_date: 'May 2022', latest_deal_amount:'8.00', city:'Bangalore', add:'false'},
        { id: 7, name: 'ApnaKlub7', sector: 'E-Commerce', total_funding: '10.00', latest_round: '3', latest_deal_date: 'May 2022', latest_deal_amount:'8.00', city:'Bangalore', add:'false'},
        { id: 8, name: 'ApnaKlub8', sector: 'E-Commerce', total_funding: '10.00', latest_round: '3', latest_deal_date: 'May 2022', latest_deal_amount:'8.00', city:'Bangalore', add:'false'},
        { id: 9, name: 'ApnaKlub9', sector: 'E-Commerce', total_funding: '10.00', latest_round: '3', latest_deal_date: 'May 2022', latest_deal_amount:'8.00', city:'Bangalore', add:'false'},
        { id: 10, name: 'ApnaKlub10', sector: 'E-Commerce', total_funding: '10.00', latest_round: '3', latest_deal_date: 'May 2022', latest_deal_amount:'8.00', city:'Bangalore', add:'false'}
      ]
      const header = ["Id", "Company", "Sector", "Total Funding (US$ M)", "Latest Round", "Latest Deal Date", "Latest Deal Amount (US$ M)", "City", "Add"];

    const downloadDetail = { isDownload, handleDownloadExcel, columns, rows, header }
    console.log(isDownload,"1234567890")

    return (
        <TableDownloadContext.Provider value={downloadDetail}>
            {children}
        </TableDownloadContext.Provider>
    )
}

export default TableDownloadProvider
