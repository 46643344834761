
import React, { useState, useContext, useEffect } from "react";
import MA_MainMenu from '../MA_MainMenu'
import Card from '../../../Components/Acquirer/MaListCard/Card'
import FilterTag from "../../../Components/Tag";
import Table from '../../../Components/MaTransaction/TableComponent'
import RefineSearch from '../../../Components/RefineSearchComponent'
import MALayout from '../../../Components/M&ALayout'
import xls from "../../../images/xls/png/Group 47.png";
import line from "../../../images/line.png";
import moment from "moment";
import FileIcon from "../../../images/file.png";
import FileIconBlur from "../../../images/fileblur.png";
import MonthPickerComponent from "../../../Utils/MonthPickerComponent";
import Constants from '../../../Constants'
import ExcelPopup from "../../../Components/ExcelPopup";
import SavedFilterContent from "../../../Components/SavedFilter";
import { TableDataContext } from '../../../Contexts/TableDataContext'
import { maCompanyListExcelExport } from "../../../Config/config";
import PdfDownloader from "../../../Components/shared/PdfDownloader";
import { PeFilterContext } from "../../../Contexts/PeFilterContext";
import ExportPopup from "../../../Components/ExportPopup";
import { data, maTAdvisorProfileExportData,  } from "../../../Components/ExportPopup/ExportData"
import {  MaAdvisorExcel} from "../../../Config/config";
import MaAdvisorRefineSearch from "../../../Components/RefineSearch/MaAdvisorRefineSearch";
import { useLocation } from "react-router-dom";
import { UserContext } from "../../../Contexts/UserContext";
import { FaFilter } from "react-icons/fa";
import { IoClose  } from "react-icons/io5";
/* The above code is a React component called "TransactionAdvisors" that renders a page for displaying
transaction advisors. It includes various functionalities such as filtering, sorting, exporting
data, and saving filters. The component is part of a larger application and is wrapped in a layout
component. It also includes a sidebar for refining search options and a table for displaying the
transaction advisor data. */
function TransactionAdvisors(props) {
    const location = useLocation();
    
    const [showPopup,setShowPopup]=useState()
    const [showExcelPopup,setshowExcelPopup] =useState()
    const [openTab, setOpenTab] = React.useState(1);
    const [date, setDate] = useState([
        moment().startOf("month").subtract(12, "months").format("YYYY-MM-DD"),
        moment().endOf("month").format("YYYY-MM-DD"),
      ]);
    // const [save, setSave] = useState(false);
    const savedList = () => {
        setSave(!save)
    }
    useEffect(() => {
        setOpenTab(props.active)
    }, [openTab])
    const { showMonthPicker,save, setSave,refineSearchFilters ,groupId, isMobile,setOpen,open,setIsMobile} = useContext(PeFilterContext);
    const {totalNoOfCompanies, setTotalNoOfCompanies,tableCompanyId, globalSearchResult} = useContext(TableDataContext);
    const { pathname } = useLocation();
    const {isTrialExport,isStudent} = useContext(UserContext)

    useEffect(()=>{
        if (window.innerWidth < 800) {
            setOpen(true);
            setIsMobile(true);
        }    },[])
  
    /**
     * The function checks if there are any global search filters applied 
     */
    const isFromGlobalSearch = ()=> refineSearchFilters[pathname]?.globalSearch?.length > 0 ? true : false

    return (
        <div>
        <MALayout menu="ma">
            <div className="flex min-h-screen h-screen flex-col">
                {/* Top nav*/}
                 {isFromGlobalSearch() ? <></> :
                <Card  menu="t-ma"/>
    }
                {/* Bottom section */}
                <div className={isFromGlobalSearch() ? "flex min-h-0 mt-[4rem] flex-1 overflow-hidden": "flex min-h-0 flex-1 overflow-hidden"}>
                    {/* Narrow sidebar*/}
                    {/* Main area */}
                    <main className="min-w-0 flex-1 border-t border-gray-200 flex">
                        {/* Primary column */}                    
                        <section className="flex h-full min-w-0 flex-1 flex-col lg:order-last bg-[#EDEDED]">
                            {isMobile ? (<FilterTag />) : null }    
                                    {/* Your content */}
                                    <div className="m-4 rounded-t-md bg-white flex justify-between items-center">
                                    <div className="pl-3 pr-4 lg:pr-4 md:pr-[0.7rem] scrollbar w-[100%]">
                                            {isFromGlobalSearch() ? globalSearchResult?.t_advisor?.count !== 0 ?
                                                <div className="block lg:flex flex-row justify-between">
                                                {!isMobile ? (<div className="mt-1">
                                                <FilterTag />  
                                            </div>) : null }
                                                {/* <MainMenu menu="ma" primaryTypeMasterId={Constants.primaryMasterIds.mANDaId}
                                                    subTypeMasterId={Constants.primaryMasterSubIds.re_investments_id}
                                                    typeId={Constants.moduleIds.l_advisor_id}/> */}
                                                    <div className={"justify-between items-center px-2 py-2 space-x-4 "+(showMonthPicker!=0 ? 'md:flex':'flex')}>
                                                
                                                {!isFromGlobalSearch() && <label htmlFor="" className="text-[#666666]">
                                                    <div className={showMonthPicker!=0 ?"":'flex'}>
                                                        <MonthPickerComponent />
                                                    </div>
                                                </label>}

                                                <div className={showMonthPicker!=0 ?"hidden md:flex space-x-3":"flex space-x-3"}>     
                                                    {isMobile && (
                                                    <>
                                                        <button
                                                        className="cursor-pointer lg:ml-7 ml-4 mt-1"
                                                        onClick={() => setOpen(!open)}
                                                        >
                                                        <FaFilter color="#bc8b20" size={21} />
                                                        {/* {!open && <IoClose color="#bc8b20" size={25} className="fixed z-50 mt-[88px] top-16 right-[39vw] mb-2" />} */}
                                                        </button>
                                                        <a>
                                                        <img
                                                            className="h-[24px] max-w-[2px] mt-1 "
                                                            src={line}
                                                        />
                                                        </a>
                                                    </>
                                                    )}
                                                            {isTrialExport == 'false'|| isStudent == 'true' ? <></> : <>                                                       
                                                                <button>
                                                                    <img
                                                                        src={xls}
                                                                        className="h-[24px] mt-1 w-[21px]"
                                                                        alt=""
                                                                        onClick={() => setShowPopup(!showPopup)}
                                                                    />
                                                                    {showPopup && (
                                                                        <ExportPopup
                                                                            modules="ma-advisor"
                                                                            fileName={Constants.exportNames.ma_t_advisor}
                                                                            url={MaAdvisorExcel}
                                                                            onClose={() => setShowPopup(false)}
                                                                            exportOptions={maTAdvisorProfileExportData}
                                                                            advisor_type= "transaction_advisors"
                                                                            module="T"
                                                                        
                                                                        
                                                                            />
                                                                    
                                                                    )}
                                                                </button>                                                                                                                
                                                                {groupId?.myList?.length >0 ?<></>:<a>
                                                                <img className="h-[24px] w-[2px] mt-1 " src={line} />
                                                            </a>}
                                                            </>}
                                                            
                                                            <a className="group cursor-pointer">
                                                                <div className="cursor-pointer" onClick={savedList}>
                                                                    <img src={save ? FileIcon : FileIconBlur}
                                                                        className="max-h-[24px] mt-1 max-w-[21px]"
                                                                        alt="" />
                                                                </div>
                                                            </a>

                                                            </div>
                                                    </div>
                                                </div>
                                                : <></> :
                                                <div className="block lg:flex flex-row justify-between">
                                                {!isMobile ? (<div className="mt-1">
                                                <FilterTag />  
                                            </div>) : null }
                                                {/* <MainMenu menu="ma" primaryTypeMasterId={Constants.primaryMasterIds.mANDaId}
                                                    subTypeMasterId={Constants.primaryMasterSubIds.re_investments_id}
                                                    typeId={Constants.moduleIds.l_advisor_id}/> */}
                                                    <div className={"justify-between items-center px-2 py-2 space-x-4 "+(showMonthPicker!=0 ? 'md:flex':'flex')}>
                                                
                                                {!isFromGlobalSearch() && <label htmlFor="" className="text-[#666666]">
                                                    <div className={showMonthPicker!=0 ?"":'flex'}>
                                                        <MonthPickerComponent />
                                                    </div>
                                                </label>}

                                                <div className={showMonthPicker!=0 ?"hidden md:flex space-x-3":"flex space-x-3"}>     
                                                    {isMobile && (
                                                    <>
                                                        <button
                                                        className="cursor-pointer lg:ml-7 ml-4 mt-1"
                                                        onClick={() => setOpen(!open)}
                                                        >
                                                        <FaFilter color="#bc8b20" size={21} />
                                                        {/* {!open && <IoClose color="#bc8b20" size={25} className="fixed z-50 mt-[88px] top-16 right-[39vw] mb-2" />} */}
                                                        </button>
                                                        <a>
                                                        <img
                                                            className="h-[24px] max-w-[2px] mt-1 "
                                                            src={line}
                                                        />
                                                        </a>
                                                    </>
                                                    )}
                                                            {isTrialExport == 'false'|| isStudent == 'true' ? <></> : <>                                                       
                                                                <button>
                                                                    <img
                                                                        src={xls}
                                                                        className="h-[24px] mt-1 w-[21px]"
                                                                        alt=""
                                                                        onClick={() => setShowPopup(!showPopup)}
                                                                    />
                                                                    {showPopup && (
                                                                        <ExportPopup
                                                                            modules="ma-advisor"
                                                                            fileName={Constants.exportNames.ma_t_advisor}
                                                                            url={MaAdvisorExcel}
                                                                            onClose={() => setShowPopup(false)}
                                                                            exportOptions={maTAdvisorProfileExportData}
                                                                            advisor_type= "transaction_advisors"
                                                                            module="T"
                                                                        
                                                                        
                                                                            />
                                                                    
                                                                    )}
                                                                </button>                                                                                                                
                                                                {groupId?.myList?.length >0 ?<></>:<a>
                                                                <img className="h-[24px] w-[2px] mt-1 " src={line} />
                                                            </a>}
                                                            </>}
                                                            
                                                            <a className="group cursor-pointer">
                                                                <div className="cursor-pointer" onClick={savedList}>
                                                                    <img src={save ? FileIcon : FileIconBlur}
                                                                        className="max-h-[24px] mt-1 max-w-[21px]"
                                                                        alt="" />
                                                                </div>
                                                            </a>

                                                            </div>
                                                    </div>
                                                </div>
                                            }
                                            {save ? <SavedFilterContent
                                                primaryTypeMasterId={Constants.primaryMasterIds.mANDaId}
                                                 subTypeMasterId={Constants.primaryMasterSubIds.ma_t_advisor}
                                                typeId={Constants.moduleIds.t_advisor_id}
                                                show={save} /> : ''}
    
    
    
                                    </div>
                                    </div>
                                    <div className='overflow-y-auto'>
                                        <Table menu="ma" sub_type_master_id={Constants.subCategoryIds.ma_t_advisor_id}/>
                                    </div>
                                </section>
                        {/* Secondary column (hidden on smaller screens) */}
                        <aside className="order-first block flex-shrink-0">
                            <div className="relative flex h-full  flex-col  border-r border-gray-200 bg-[#FAF5EA]">
                                {/* Your content */}
                                <RefineSearch>
                                <MaAdvisorRefineSearch customPathname={location.pathname} adviosrType="T"/>
                                </RefineSearch>
                            </div>
                        </aside>
                    </main>
                </div>
               
            </div> 
        </MALayout>
    </div>
      )
}

export default TransactionAdvisors
