import React, { useState, useMemo, useEffect, useContext } from "react";
import { useSearchParams, Routes, Route, useParams, NavLink } from 'react-router-dom';
import { useQuery } from "@apollo/client";
import sorting from '../../../images/up-down-arrow.png'
import moment from "moment";
import DataTable from '../../../Components/DataTables/DataTable';
import { DataFoundContext } from "../../../Contexts/DataFoundContext";

 
const TransactionAdvisorNewsActivity = ({newsActivity}) => {

  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(20)
  const [newsletter, setNewsletter] = useState({});

  const { companyId } = useParams();
  let advisorProfileId = parseInt(companyId);
  const {newsAndActivityDataFound} = useContext(DataFoundContext);
// let advisor = "%" + T_LEGAL_ADVISOR_PROFILE?.cianame + "%";
  // const handleFetchNewsletterRequestCallback = (response, requestSuccess) => {
  //   try {
  //     setNewsletter(response);
  //     console.log("newsletter123", response);
  //   } catch (e) {
  //     console.log('Error', e)
  //   }
  // };

  // const { data, loading, error } = useQuery(T_ADVISORNEWSLETTER, {
  //   variables: { TagName: advisor },
  //   onCompleted: (response) => handleFetchNewsletterRequestCallback(response, true),
  //   onError: (response) => handleFetchNewsletterRequestCallback(response),
  //   refetch: T_ADVISORNEWSLETTER

  // })
  // console.log("newsletter123", newsletter)
  
  const NewsLetterData = useMemo(() => newsletter, [newsletter]);
  console.log("newsletter123", NewsLetterData)

  const indexOfLastPost = currentPage * dataPerPage;
  const indexOfFirstPost = indexOfLastPost - dataPerPage;
  const currentData = NewsLetterData?.newsletter?.slice(indexOfFirstPost, indexOfLastPost);
  // alert(currentData)
  const pageNumbers = []

  for (let i = 1; i <= Math.ceil(newsletter?.length / dataPerPage); i++) {
    pageNumbers.push(i);
  }
  /**
   * The function `setPage` sets the current page to the specified page number.
   * @param pageNum - The pageNum parameter is the number of the page that you want to set as the
   * current page.
   */
  const setPage = (pageNum) => {
    setCurrentPage(pageNum)

  }
 /* The `customStringSortMemo` constant is a custom sorting function that is memoized using the
 `useMemo` hook. It is used to sort the data in the DataTable component based on the values in a
 specific column. */
  const customStringSortMemo = useMemo(() => {
    return (rowA, rowB, columnId, desc) => {
        const defaultVal = desc ? '--' : 'A';
        return (rowA?.values[columnId]?.heading ?? defaultVal).localeCompare(rowB?.values[columnId]?.heading ?? defaultVal);
    }
}, []);
/* The `dealDateSort` constant is a custom sorting function that is used to sort the data in the
"Release Date" column of the DataTable component. */
const dealDateSort = useMemo(() => {
  return (rowA, rowB, columnId, desc) => {
      return moment.utc(rowA?.values[columnId].date).diff(moment.utc(rowB?.values[columnId].date))
  }
}, [])
  /* The `const columns` is an array of objects that defines the columns for the DataTable component.
  Each object represents a column and contains properties such as `id`, `Header`, `accessor`,
  `className`, `Cell`, and `sortType`. */
  const columns = useMemo(() => [
      {
        id:"news",
        Header: "News",
        accessor: "news",
        headerClassName:"w-[18%]",
        className: "text-[#333333] text-[12pt] px-6 py-4 flex-wrap lg:text-[14px] lg:leading-[16px]",
        Cell: ({ value }) => {
          return <a href={value?.link} target="_blank" rel="noreferrer">
              {value?.news}
            </a>
        },
        sortType:customStringSortMemo
      },
      {
        id: 'releaseDate',
        Header: "Release Date",
        accessor: "releaseDate",
        className: "text-[#333333] pr-[3.5rem] py-4 float-center lg:float-right whitespace-nowrap lg:text-[14px] lg:leading-[16px]",
        headerClassName: "text-center float-right flex lg:px-10 px-8 py-3.5",
        Cell: ({ value }) => {
          return moment(value?.date).format('MMM DD, YYYY')
        },
        sortType:dealDateSort
      }
    ], [])
  
 /* The `rowData` constant is using the `useMemo` hook to memoize the data that will be displayed in
 the DataTable component. */
  const rowData = useMemo(() => {
    const rows = [];
  
    if (newsActivity) {
      newsActivity?.forEach((newsData) => {
        rows.push({
          news: { news: newsData?.heading, link: newsData?.link },
          releaseDate: { date: newsData?.release_date, news: newsData?.news },
        });
      });
    }

    return rows;
  }, [newsActivity]);

  /* The `initialSort` constant is using the `useMemo` hook to memoize an array of objects. Each object
  represents a sorting configuration for a column in the DataTable component. In this case, the
  `initialSort` array contains a single object with the following properties: */
  const initialSort = useMemo(() => [
    {
        id: 'releaseDate',
        desc: true
    }
  ], []);
  return (
    <>
      {newsAndActivityDataFound ? (
        <div className=" px-4 bg-[#EDEDED] w-[100%] mb-6">
          <div className="  bg-white border  overflow-hidden sm:rounded-lg shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
            <div className="pt-4 px-5">
              <p className="text-[14pt] leading-[19pt] mb-3 lg:text-[17px] lg:leading-[22px] font-semibold text-[#333333]">
                Recent News & Activities
              </p>
            </div>
            <DataTable columns={columns} data={rowData} sortBy={initialSort}/>
          </div>
        </div>
      ) : ""}
    </>
  );
};

export default TransactionAdvisorNewsActivity;
