import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import sorting from "../../../../images/up-down-arrow.png";
import Loadpage from "../CFSFinancial/LoadPage";

/**
 * The `AngelInvestmentTable` function is a React component that renders a table based on the `mcaData`
 * prop.
 * @returns The code is returning a JSX element. It is rendering a table with data from the `mcaData`
 * object. Each property of the `companyMasterData` array is mapped and rendered as a row in the table.
 * The property name is displayed in the first column and the corresponding value is displayed in the
 * second column.
 */
const AngelInvestmentTable = (mcaData) => {
  // console.log("mcaData", mcaData);
  // const [openTab, setOpenTab] = useState(1);
  // const [showPopup, setShowPopup] = useState(false);
  // const [companyMasterData, setCompanyMasterData] = useState(mcaData);

  return (
    <>
      <div className="space-y-6 px-5 lg:mx-1211 overflow-x-auto scrollbar-remove">
        {" "}
        <div className="bg-white pb-5 ml-0">
          {mcaData?.data?.companyMasterData?.map((singleData) => {
            for (const property in singleData) {
              return (
                <tr>
                  <td className="px-5  bg-[#f3f3f3] leading-[2rem] text-[14px] font-sans_book_body items-center justify-center card-animate">
                    {property}{" "}
                  </td>
                  <td className="px-5  leading-[2rem] text-[14px] font-sans_book_body items-center justify-center card-animate">
                    {singleData[property]}{" "}
                  </td>
                </tr>
              );
            }
          })}
        </div>
      </div>
    </>
  );
};

export default AngelInvestmentTable;
