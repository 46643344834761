import React from 'react'
import Layout from '../Components/Layout'
import CompanyDetail from '../Components/DetailPage/FillingDetail'

/**
 * The DetailPage function is a React component that renders a layout with a primary and secondary
 * column, and includes a CompanyDetail component.
 * @returns The DetailPage component is returning a JSX element.
 */
function DetailPage(props) {
    return ( 
        <div> 
            <Layout>
            <div className="flex h-screen flex-col">
                {/* {props.menu == "ma" ? <MaTopCard /> :  <Card DEATAILPAGE_CARD={DEATAILPAGE_CARD}/>} */}
                    
                    <div className="flex min-h-0 flex-1 mt-[3.5rem]">
                        {/* Primary column */}
                        <section
                            aria-labelledby="primary-heading"
                            className="flex h-full min-w-0 flex-1 flex-col overflow-y-auto lg:order-last">
                            <CompanyDetail menu={props.menu} />
                            
                        {/* <Outlet /> */}
                        {/* Your content */}
                        </section>
                        {/* Secondary column (hidden on smaller screens) */}
                        {/* <aside className="hidden lg:order-first lg:block lg:flex-shrink-0">
                            <div className="relative flex h-full  flex-col overflow-y-auto border-r border-gray-200 bg-[#FAF5EA]">
                                <RefineSearch />
                            </div>
                        </aside> */}
                    </div>
                  
                    {/* <div className="fixed  right-1 bottom-0">          
                    <img className="float-right w-[4rem] h-[4rem] cursor-pointer" src={chatPopupIcon} /> 
                    </div>  */}
                   <br/>
                       
                </div>
            </Layout>
        </div>
    )
}

export default DetailPage