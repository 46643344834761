import { React, useState, useMemo, useContext } from 'react'
import Table from './Table'
import { TableHeaderContext } from '../../Contexts/TableHeaderContext'


/**
 * The TableComponent function is a React component that renders a table based on the provided props,
 * including the menu type and column headers.
 * @param props - props is an object that contains the properties passed to the TableComponent. These
 * properties can include menu, advisor_type, and sub_type_master_id.
 * @returns The TableComponent is being returned.
 */
const TableComponent = (props) => {
   
const {
    PEREAdvisorTableHeadder,PEReAdvisorExitTableHeader,AdvisorOtherMATableHeader,
    advisororderingColumn,
}=useContext(TableHeaderContext)

// const [investments, setInvestments] = useState([]);
const [pagination, setPagination] = useState()

// const tableRowData = useMemo(() => investments, [investments]);  
const paginationData = useMemo(() => pagination, pagination); 
 

const columns = props.menu == "re-ladvisor" ? PEREAdvisorTableHeadder 
    : props.menu == "re-advisorexit" ? PEReAdvisorExitTableHeader 
   
    : props.menu == "re-advisor-otherma" ? AdvisorOtherMATableHeader
   
    :PEREAdvisorTableHeadder

    return (
        <div className="pl-3 pr-2 lg:pr-2 md:pr-3 overflow-y-auto">
            <div className="App lg:relative">
            <Table order="asc" orderBy={advisororderingColumn} menu={props?.menu} columns={columns} advisor_type={props?.advisor_type} pagination = {paginationData} sub_type_master_id={props?.sub_type_master_id}/>
            </div>
        </div>
    )
}

export default TableComponent