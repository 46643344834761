import React, { useContext, useEffect, createContext, useRef, useState, useMemo } from 'react';
import axios from 'axios';
import Tab from '../shared/Tab';
import addImg from '../../images/addQuery.png'
import QueryBuilderPopup from './QueryBuilderPopup';
import { data } from "./PopupData"
import CompaniesLogo from '../../images/noun-investment-2180740_4/png/noun-investment-2180740.png';
import InvestorLogo from '../../images/Shape_4/png/Shape.png';
import AdvisorLogo from '../../images/noun-investment-2180740_2/png/noun-investment-2180740.png';
import TransactionLogo from '../../images/noun-investment-2180740_5/png/noun-investment-2180740.png';
import closeBtn from "../../images/CloseBtn.svg"
import { Select } from 'antd';
import { Option } from 'antd/es/mentions';
import useModule from "../../hooks/useModule";
import { UserContext } from '../../Contexts/UserContext';
import { advanceSearchCountryUrl, filterLocationUrl, tagSearchUrl } from '../../Config/config';
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import { Navigate, useLocation, useNavigate,NavLink } from 'react-router-dom';
import { useCubeQuery } from '@cubejs-client/react'
import { advanceSearchQuery } from '../../Queries/CubeQueries'
import TableTabTable from './TableContainer';
import { cubejsApi } from '../..';
import DynamicFilterComponent from './DynamicFilterComponent';
import SavedFilterPopUp from '../SavedFilter/SavedFilterPopUp';
import moment from "moment"
import Loader from '../Loader';
import { TableDataContext } from '../../Contexts/TableDataContext';
import { Tooltip } from "antd";
import { groupBy } from 'lodash';
import LineTo,{ SteppedLineTo, } from 'react-lineto';
import { nanoid } from 'nanoid'
import routePathSegments, { routeNames } from '../../routeSegments';
import AdvanceCompaniesSearch from './AdvanceCompaniesSearch';
import AdvanceInvestorsSearch from './AdvanceInvestorsSearch ';
import AdvanceLegalAdvisorsSearch from './AdvanceLegalAdvisorsSearch ';
import AdvanceTransactionAdvisorsSearch from './AdvanceTransactionAdvisorsSearch ';

const lineColor = "#BDBDBD";

/**
 * The generateId function generates a unique identifier using the nanoid library.
 */
const generateId = ()=>nanoid()

/**
 * The `tagRender` function is a JavaScript function that renders a tag component with a label, value,
 * and optional close button.
 * @param props - The `props` parameter is an object that contains the properties passed to the
 * `tagRender` component. These properties include:
 * @returns The code is returning a JSX element, specifically a `<div>` element with some nested
 * elements inside.
 */
const tagRender = (props) => {





  const { label, value, closable, onClose } = props;
  // const onPreventMouseDown = (event) => {
  //   event.preventDefault();
  //   event.stopPropagation();
  // };
  // console.log(props, "label-8");
  return (
    <div className="mx-1 my-1 text-[12px] flex items-stretch justify-between font-bold  px-1 py-1 rounded-sm bg-slate-100 text-black border ">
      <div>
        {label}
      </div>

      <div onClick={onClose} className='px-1 flex items-center justify-center cursor-pointer'>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="black" className="w-3 h-3">
          <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </div>


    </div>
  );
};


const QueryBuilderTabs = ({ setPopOverAdvance }) => {
  const { advancedSearchCurrentPage, setAdvancedSearchCurrentPage } = useContext(TableDataContext)
  const { pathname } = useLocation();
  const {
    refineSearchFilters, setrefineSearchFilters } = useContext(PeFilterContext);
  const { getToken } = useContext(UserContext);
  const [isActive, setIsActive] = useState('Companies')
  const [resultSet, setResultSet] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [popUpOpen, setPopUpOpen] = useState(false)
  const [popUpOpenOption, setPopUpOpenoption] = useState({show:false,child:null})
  const [selectedParent, setSelectedParent] = useState(data.initial?.at(0))
  const [selectedChild, setSelectedChild] = useState([])
  const [openQbPopup, setOpenQbPopup] = useState()
  const [selected, setSelected] = useState()
  const [filterList, setFilterList] = useState()

  const [selectedOperator, setSelectedOperator] = useState([
    {
      "Company Name": "",
      "Country": "Includes any",
      "State": "",
      "Region": "",
      "City": "",
      "Tags": ""
    }
  ])
  const [searchOptions, setSearchOptions] = useState(
    {
      "Company Name": [],
      "Country": [],
      "State": [],
      "Region": [],
      "City": [],
      "Tags": []
    }
  )
  const [searchText, setSearchText] = useState("");
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const firstRender = useRef(false);
  const { primaryMasterId } = useModule();
  const [columnData, setColumnData] = useState([]);
  const [companyData, setCompanyData] = useState();
  const [showViewAllList, setShowViewAllList] = useState(false)
  const [companyDataQuery, setCompanyDataQuery] = useState([]);

  const [searchButtonClicked, setSearchButtonClicked] = useState(false);
  const [subBtnhover, setSubBtnHover] = useState(false);
  const navigate = useNavigate()
  /**
   * The function `onSubBtnHover` sets the `subBtnHover` variable to `true` when called.
   */
  const onSubBtnHover = () => {
    setSubBtnHover(true);
  };

  /**
   * The function onSubBtnLeave sets the value of the variable subBtnHover to false.
   */
  const onSubBtnLeave = () => {
    setSubBtnHover(false);
  };

  useEffect(() => {
    serachResult()
  }, [advancedSearchCurrentPage])

 


 /**
  * The function `searchResult` performs a search operation by generating a cube query, loading the
  * query using the cubejsApi, and updating the company data with the search results.
  */
  const serachResult = () => {
    // setrefineSearchFilters(1)
    setCompanyData()
    setIsLoading(true);
    setSearchButtonClicked(!searchButtonClicked);
    const query0 = generateCubeQuery(selectedChild);


    setCompanyDataQuery(query0);

    // setIsLoading(true);
    cubejsApi.load(query0
    )
      .then((result) => {
        console.log('result', result.loadResponse.results[0].data);
        setCompanyData(result.loadResponse.results[0].data);
        setIsLoading(false)
        // setPopOverAdvance(false)
        // navigate("/query-builder",{state:{result:result.loadResponse.results[0].data}})
      })

    // Navigate('/query-builder')


  }

 /**
  * The function `viewMore` navigates to the "/query-builder" page with the state containing the
  * `filterList` data.
  */
  const viewMore = () => {
    setShowViewAllList(false)
    // const query0 = generateCubeQuery(selectedChild);


    // setCompanyDataQuery(query0);

    // cubejsApi.load(query0
    // )
    //   .then((result) => {
    //     console.log('result', result.loadResponse.results[0].data);
    //     setCompanyData(result.loadResponse.results[0].data);
    //     setIsLoading(false)
    //     setPopOverAdvance(false)
    //     navigate("/query-builder",{state:{result:result.loadResponse.results[0].data}})
    //   })
      // setPopOverAdvance(false)
        navigate("/query-builder",{state:{ filter: filterList}})
  }



  /* The above code is defining a `pvConfig` object using the `useMemo` hook in JavaScript. */
  const pvConfig = useMemo(() => {
    if (resultSet?.query()?.dimensions?.length === 0) {
      return {
        fillMissingDates: true,
        x: [`${resultSet?.query()?.timeDimensions[0]?.dimension}.${resultSet?.query()?.timeDimensions[0]?.granularity}`],
        y: ['measures']
      }
    }
    return {
      fillMissingDates: true,
      x: resultSet?.query()?.dimensions,
      y: [`${resultSet?.query()?.timeDimensions[0]?.dimension}.${resultSet?.query()?.timeDimensions[0]?.granularity}`, 'measures']
    }
  }, [resultSet])



  const tableColumns = resultSet?.tableColumns(pvConfig) ?? []
  const tableData = resultSet?.tablePivot(pvConfig) ?? []


  /**
   * The function `selectedChildList` checks if an option has siblings and logs a message accordingly,
   * and if the option does not have siblings, it logs the parent and adds the option to the
   * `selectedChild` array with a unique ID and a flag indicating if it has siblings.
   * @param option - The `option` parameter is an object that represents a selected child. It contains
   * the following properties:
   */
  const selectedChildList = (option) => {

    if (option.hasSiblings) {
      // Option has siblings
      console.log(option, 'selected child with siblings');
    } else {
      // Option does not have siblings
      console.log(option, 'selected child without siblings');
    }


    if (option.hasMore == false) {
      console.log(data[option.parent], 'selected child parent');
      // var filtered = selectedChild.filter(function (el) { return el.parent == option.parent; });
      const hasSibling = option?.filterLevel?.find(data=>data?.name === option.parent)?.hasMore??false
      const temp = {...option,uniqueId: generateId(),hasSibling}
      setSelectedChild(prev => [...prev, temp])
    }
  }


  /**
   * The function `removeSelectedChild` removes a child element from an array called `selectedChild`
   * based on its unique ID.
   * @param id - The `id` parameter represents the unique identifier of the child element that needs to
   * be removed.
   * @param index - The `index` parameter represents the index of the element in the `selectedChild`
   * array that you want to remove.
   */
  const removeSelectedChild = (id, index) => {
    // var filtered = selectedChild.filter(function (el) { return el.id != id; });
    const filtered = selectedChild.filter((el, i) => el.uniqueId !== id)

    console.log('filtered-09', filtered);

    setSelectedChild(filtered)
  }

  /**
   * The function onTabClick sets the active tab index.
   * @param tabIndex - The `tabIndex` parameter represents the index of the tab that was clicked.
   */
  const onTabClick = (tabIndex) => {
    setIsActive(tabIndex);
  }
  /**
   * The function `isPopUpOpen` toggles the state of a pop-up window.
   */
  const isPopUpOpen = () => {
    setPopUpOpen(!popUpOpen)
  }

 /**
  * The function `isQbPopup` toggles the value of `openQbPopup`.
  */
  const isQbPopup = () => {
    setOpenQbPopup(!openQbPopup)
  }

  /**
   * The handleChange function updates the selected operator and triggers a search type update based on
   * the provided parameters.
   * @param index - The index parameter represents the index of the element being changed in an array
   * or list. It is used to identify the specific element that is being modified.
   * @param e - The parameter `e` is typically used to represent an event object. In this case, it
   * seems to be used to capture the value of an input field or the selected option of a dropdown menu.
   * @param name - The `name` parameter is a string that represents the name of the input field that
   * triggered the change event.
   * @param id - The `id` parameter is a unique identifier for the element being changed.
   * @param uniqueId - The uniqueId parameter is a unique identifier for the element being changed. It
   * is used to distinguish between different elements when handling their changes.
   */
  const handleChange = (index, e, name, id, uniqueId) => {
    // 
    console.log('idonchange', index, e, name, id, uniqueId);
    const value = e;
    // alert(value);
    setSelected(null)
    setSelectedOperator(prev => [{ ...prev, [name]: e }])
    updateSearchType(index, id, value, uniqueId)
  }


  /**
   * The function updates the search type of a selected child based on the provided index, id, search
   * type text, and unique id.
   * @param index - The index parameter represents the index of the selected child in the selectedChild
   * array.
   * @param id - The `id` parameter is used to identify the specific row or item that needs to be
   * updated. It is likely used as a unique identifier for the row in a table or list.
   * @param searchTypeText - The `searchTypeText` parameter is a string that represents the new search
   * type that needs to be updated.
   * @param uniqueId - The uniqueId parameter is a unique identifier for a specific row or item in the
   * selectedChild array. It is used to identify the specific row that needs to be updated in the
   * selectedChild array.
   */
  function updateSearchType(index, id, searchTypeText, uniqueId) {

    const updatedSelectedChild = selectedChild && selectedChild?.map((row,i) => {
      if (row.uniqueId === uniqueId) {
        console.log(row.uniqueId, uniqueId,"row.uniqueId === uniqueId");
        const updatedSearchType = row.filterData.searchType.map((item) => {
          const key = Object.keys(item)[0];
          const dataType = item.dataType || row.filterData.dataType;
          // Retain the existing dataType if it exists
          return { [key]: key === searchTypeText, dataType };
        });
        row.values = []
        return {
          ...row,
          filterData: {
            ...row.filterData,
            searchType: updatedSearchType,
          },
        };
      }
      return row;
    });

    setSelectedChild(updatedSelectedChild);
  }




  /**
   * The handleChangeSearch function updates search options, closes the search dropdown, clears the
   * search text, and clears the options.
   * @param value - The value parameter represents the selected value from the search options.
   * @param id - The `id` parameter is a value that represents the ID of the selected option.
   * @param name - The `name` parameter is a string that represents the name of the search option.
   */
  const handleChangeSearch = (value, id, name) => {
    // console.log("optionsoptionsoptions", value, id, name);
    setSearchOptions(prev => [{ ...prev, [name]: id?.map(option => ({ id: option.key, name: option.value })) }])
    setOpen(false)
    setSearchText("");
    setOptions([]);
    // clearOptions()
  };

  // console.log(selectedParent, 'selectedParent');

  /**
   * The clearOptions function clears the search options and search text.
   */
  const clearOptions = () => {
    setSearchOptions([])
    setSearchText("");
  }

  /**
   * The clearFilters function clears the selected parent and child filters.
   */
  const clearFilters = () => {
    setSelectedParent({})
    setSelectedChild([])
  }

  /**
   * The function `getSearchTags` is an asynchronous function that takes in a `searchText` and `child`
   * parameter, sets the `searchText` state, and makes an API call to retrieve search tags based on the
   * `child` parameter.
   * @param searchText - The searchText parameter is a string that represents the text entered by the
   * user for searching. It is used to filter the search results based on this text.
   * @param child - The "child" parameter is a string that represents the type of search being
   * performed. It can have the following values: "Country", "Region", "State", "City", or "Tags".
   * @returns The function does not have a return statement.
   */
  const getSearchTags = async (searchText, child) => {
    setSearchText(searchText)
    if (searchText !== "") {
      setOptions([]);
      setFetching(true);
      if (child == "Country" || child == "Region" || child == "State" || child == "City") {
        const countryIds = refineSearchFilters[pathname]?.countries?.map(value => value.id) ?? [];
        const regionIds = refineSearchFilters[pathname]?.region?.map(value => value.id) ?? [];
        const stateIds = refineSearchFilters[pathname]?.state?.map(value => value.id) ?? [];
        const cityIds = refineSearchFilters[pathname]?.city?.map(value => value.id) ?? [];
        try {
          const response = await axios.post(advanceSearchCountryUrl, {
            search_key: searchText
          }, {
            headers: {
              "Authorization": getToken()
            }
          });

          if (response.status === 200) {
            let result = ""
            // console.log(response.data.result, "response.data");
            result = response.data.result
            // if(child == "Country"){
            //     result =  response.data?.countries?.map(option => ({ ...option, isChecked: countryIds.includes(option.id) }))
            // }
            // else if(child == "city"){
            //     result =  response.data?.city?.map(option => ({ ...option, isChecked: cityIds.includes(option.id) }))
            // }
            // else if(child == "region"){
            //     result =  response.data?.region?.map(option => ({ ...option, isChecked: regionIds.includes(option.id) }))
            // }
            // else{
            //     result =  response.data?.state?.map(option => ({ ...option, isChecked: stateIds.includes(option.id) }))
            // }
            // console.log(result, "newOptionsnewOptions");
            setOptions(result);
            setFetching(false);
          } else {
            return [];
          }
        } catch (error) {
          return [];
        }
      }
      else if (child == "Tags") {
        // alert("12")
      }


    }
    else {
      setOptions([]);
    }
  }

  /**
   * The function `generateCubeQuery` generates a cube query object based on the selected filters and
   * dimensions.
   * @param selectedChild - An array of objects representing the selected filters. Each object has the
   * following properties:
   * @returns the `cubeQuery` object.
   */
  function generateCubeQuery(selectedChild) {
    const cubeQuery = {
      timeDimensions: [],
      measures: [
        "Pecompanies.count_of_companyname"
      ],
      order: {
        "Pecompanies.companyname": "asc",
      },
      dimensions: [
        "Pecompanies.companyname",
        "Pecompanies.yearfounded",
        "PecompanyLogo.iconPath",
        "profile_flat.business_desc",
        "profile_flat.industry",
        "profile_flat.sector",
        // "DealFlat.amountM",
        // "PecompanyTagCrosstabVw.tags",
        // "DealFlat.SPV"
      ],
      filters: [],
      limit: 5
      // limit: 10*advancedSearchCurrentPage,
      // offset: advancedSearchCurrentPage=="1"?0:10*(advancedSearchCurrentPage-1)
    };

    selectedChild?.forEach((child) => {
      const operatorObject = child.filterData.searchType.find((type) => Object.values(type)[0]);
      if (operatorObject) {
        const { member, values } = child;
        console.log(child, "valuesvaluesvalues");
        const operator = Object.keys(operatorObject)[0];
        if (operator === "Includes all"){
          cubeQuery.filters.push({
            and: 
              values.map((value, i) =>(
              {
                  member,
                  operator: mapOperator("Equals"),
                  values: [value],
              }
            ))
          })
        }
        else if (child.name === "Deal Involving") {
          if (values?.length > 1) {
            if(operator !== "Includes any"){
              cubeQuery.filters.push({
                member: "peinvestments_investors.newinvestor",
                operator: mapOperator(operator),
                values: ["1"],
              });
              cubeQuery.filters.push({
                member: "peinvestments_investors.existinvestor",
                operator: mapOperator(operator),
                values: ["1"],
              });
          }
          else{
            cubeQuery.filters.push({
              or: [
                {
                  member: "peinvestments_investors.newinvestor",
                  operator: mapOperator(operator),
                  values: ["1"],
                },
                {
                  member: "peinvestments_investors.existinvestor",
                  operator: mapOperator(operator),
                  values: ["1"],
                }
              ]
            });
          }
          }
          else {
            if(operator !== "Includes any"){
              if (values[0].toLowerCase() === "new investor") {
                cubeQuery.filters.push({
                  member: "peinvestments_investors.newinvestor",
                  operator: mapOperator(operator),
                  values: ["1"],
                });
              }
              else if (values[0].toLowerCase() === "existing investor") {
                cubeQuery.filters.push({
                  member: "peinvestments_investors.existinvestor",
                  operator: mapOperator(operator),
                  values: ["1"],
                });
              }
              else {
                cubeQuery.filters.push({
                  member: "peinvestments_investors.existinvestor",
                  operator: mapOperator(operator),
                  values: ["2"],
                });
              }
            }
            else{
              if (values[0].toLowerCase() === "new investor") {
                cubeQuery.filters.push({
                  or: [
                    {
                      member: "peinvestments_investors.newinvestor",
                      operator: mapOperator(operator),
                      values: ["1"],
                    }
                  ]
                });
              }
              else if (values[0].toLowerCase() === "existing investor") {
                cubeQuery.filters.push({
                  or: [
                    {
                      member: "peinvestments_investors.existinvestor",
                      operator: mapOperator(operator),
                      values: ["1"],
                    }
                  ]
                });
              }
              else {
                cubeQuery.filters.push({
                  member: "peinvestments_investors.existinvestor",
                  operator: mapOperator(operator),
                  values: ["2"],
                });
              }
            }
            
          }
        }
        else if (child.name === "Deal Type") {
          if (values[0].toLowerCase() === "equity only") {
            cubeQuery.filters.push({
              member,
              operator: mapOperator(operator),
              values: ["0"],
            });
          }
          else if (values[0].toLowerCase() === "debt only") {
            cubeQuery.filters.push({
              member,
              operator: mapOperator(operator),
              values: ["1"],
            });
          }
          else {
            cubeQuery.filters.push({
              member,
              operator: mapOperator(operator),
              values: ["2"],
            });
          }
          
        }
        else if (operator === "Range") {
          cubeQuery.filters.push({
            member,
            operator: mapOperator("Greater than or equal to"),
            values: [values[0]],
          });
          cubeQuery.filters.push({
            member,
            operator: mapOperator("Less than or equal to"),
            values: [values[1]],
          });
        }
        else if (operator === "Blanks") {
          // if value true
          if (child.values[0] == "True") {
            cubeQuery.filters.push({
              member,
              operator: "notSet",
              values: undefined
            });
          }
          if (child.values[0] == "False") {
            // if value false
            cubeQuery.filters.push({
              member,
              operator: "set",
              values: undefined
            });

          }
        }
        else if (operatorObject["dataType"] === "Between" && operator === "Between") {
          console.log(values, "hkjihjkjkl");
          let valueFrom = [values[0]]
          let valueTo = [values[1]]
          cubeQuery.filters.push({
            member,
            operator: mapOperator("Greater than or equal to"),
            values: [values[0]],
          });
          cubeQuery.filters.push({
            member,
            operator: mapOperator("Less than or equal to"),
            values: [values[1]],
          });
        }
        else if (operatorObject["dataType"] === "CalenderType") {
          console.log(values, "operatorObjectoperatorObject");
          let days = moment(`${values[1]}-${values[0]}`, "YYYY-MM").daysInMonth() // 29
          let value = ""
          if (parseInt(values[0]) < 10) {
            value = [values[1] + "-0" + values[0] + "-" + days]
          }
          else {
            value = [values[1] + "-" + values[0] + "-" + days]
          }
          cubeQuery.filters.push({
            member,
            operator: mapOperator(operator),
            values: value,
          });
        }
        else if (operatorObject["dataType"] === "MultiCalenderType") {
          console.log(values, "operatorObjectoperatorObject");
          let days = ""
          let value = []
          //from
          days = moment(`${values[1]}-${values[0]}`, "YYYY-MM").daysInMonth() // 29
          if (parseInt(values[0]) < 10) {
            value.push(values[1] + "-0" + values[0] + "-" + "01")
          }
          else {
            value.push(values[1] + "-" + values[0] + "-" + days)
          }
          //to
          days = moment(`${values[3]}-${values[2]}`, "YYYY-MM").daysInMonth() // 29
          if (parseInt(values[2]) < 10) {
            value.push(values[3] + "-0" + values[2] + "-" + days)
          }
          else {
            value.push(values[3] + "-" + values[2] + "-" + days)
          }
          cubeQuery.filters.push({
            member,
            operator: mapOperator(operator),
            values: value,
          });
        }
        else if (operator === "Includes all") {
          cubeQuery.filters.push({
            member,
            operator: mapOperator(operator),
            values,
          });
        }
        else {
          cubeQuery.filters.push({
            member,
            operator: mapOperator(operator),
            values,
          });
        }
      }
    });
    if(cubeQuery.filters.find(o => o.member === 'DealFlat.SPV' ) || cubeQuery.filters.find(o => o.member === 'DealFlat.investors' )){
    cubeQuery['segments'] = [
      "Pecompanies.dealtype"
    ];
  }
  setFilterList(cubeQuery.filters)
  localStorage.setItem("filterList", JSON.stringify(cubeQuery.filters))

    return cubeQuery;
  }



  /**
   * The function `mapOperator` maps a given operator to its corresponding operator in a different
   * format.
   * @param operator - The parameter "operator" is a string that represents an operator used in a
   * mapping function.
   * @returns The function `mapOperator` returns a corresponding operator based on the input `operator`
   * value.
   */
  function mapOperator(operator) {
    switch (operator) {
      case "Includes any":
        return "contains"; // dont let user to type  // or // operator equal
      case "Does not include":
        return "notEquals"; // dont let user to type  
      case "Starts with":
        return "startsWith"; // "startsWith";  
      case "Contains":
        return "contains";   // dont let user to select
      case "Does not contains":
        return "notContains"; // dont let user to select
      case "Blanks":
        return "set"; // is not set, or notset or notequal spave
      case "Includes all":
        return "equals"; // and // operator equal
      case "Equals":
        return "equals";
      case "Equals to":
        return "equals";
      case "Does not equal to":
        return "notEquals";
      case "Before":
        return "lt"; // beforeDate 
      case "After":
        return "gt";
      case "Between":
        return "inDateRange";
      case "Greater than or equal to":
        return "gte";
      case "Less than or equal to":
        return "lte";
      default:
        return operator;
    }
  }

  useEffect(() => {
    setSelectedChild(selectedChild)
  }, [selectedChild],[removeSelectedChild]);


  /**
   * The function `openFilterPopup` sets the `show` property of `PopUpOpenoption` to `true` and assigns
   * the `child` parameter to the `child` property.
   * @param child - The `child` parameter is a variable that represents the child component that will
   * be rendered inside the filter popup.
   */
  const openFilterPopup = (child)=>{
    setPopUpOpenoption({show:true,child})
  }

  /**
   * The closeFilterPopup function sets the show property of the PopUpOpenoption object to false and
   * sets the child property to null.
   */
  const closeFilterPopup = ()=>{
    setPopUpOpenoption({show:false,child:null})
  }

 /* The above code is using the `useMemo` hook in React to create a memoized version of the
 `filtersView` variable. It is grouping the `selectedChild` array by the "category" property and
 returning the result. The `useMemo` hook will only recompute the `filtersView` value if the
 `selectedChild` array changes. */
  const filtersView = useMemo(() => {
    return groupBy(selectedChild,"category");
  }, [selectedChild])

  // console.log("filtersView-09",selectedChild);

  return (
    <div className='qb-container '>
      <div className='flex space-x-5'>
        {/* <h1 className='font-bold capitalize text-[22px] py-2'>Build Query : {isActive} </h1> */}       
      </div>
      <div className="flex flex-row overflow-x-auto scrollbar space-x-4 lg:space-x-6 bg-white lg:px-2 lg:py-2 px-0 py-4  border-b border-[#D7D7D7] cursor-pointer sticky top-0   scrollbar-remove z-[11]">
        <Tab active={isActive === 'Companies'} label={"Companies"} onClick={() => onTabClick('Companies')} className={'border-none flex items-center'} activeclassName={'bg-gray-200 pl-2 pr-3 py-2 rounded-md'} logo={CompaniesLogo} />
        <Tab active={isActive === 'Investors'} label={"Investors"} onClick={() => onTabClick('Investors')} className={'border-none flex items-center'} activeclassName={'bg-gray-200 pl-2 pr-3 py-2 rounded-md'} logo={InvestorLogo} />
        <Tab active={isActive === 'Legal Advisors'} label={"Legal Advisors"} onClick={() => onTabClick('Legal Advisors')} className={'border-none flex items-center'} activeclassName={'bg-gray-200 pl-2 pr-3 py-2 rounded-md'} logo={AdvisorLogo} />
        <Tab active={isActive === 'Transaction Advisors'} label={"Transaction Advisors"} onClick={() => onTabClick('Transaction Advisors')} className={'border-none flex items-center'} activeclassName={'bg-gray-200 pl-2 pr-3 py-2 rounded-md'} logo={TransactionLogo} />
      </div>
      {isActive == "Companies"?
        <AdvanceCompaniesSearch />
        :isActive == "Investors"?
        <AdvanceInvestorsSearch />
        :isActive == "Legal Advisors"?
        <AdvanceLegalAdvisorsSearch />
        :<AdvanceTransactionAdvisorsSearch />
      }
       
    </div>
  )
}

export default QueryBuilderTabs
