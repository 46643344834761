import React from "react";
import mindTreeLogo from "../../images/mindtree-logo copy/png/mindtree-logo copy.png";

const Notify = (props) => {

  // function handleNotificationClick(entity) {

  //   handleNotificationClick(props.handleNotificationClick(entity))
  //   return false;
  // }

/**
 * The function `getStatusBackgroundColor` takes a status parameter and returns a corresponding
 * background color based on the status value.
 * @param status - The `status` parameter is a string that represents the status of something.
 * @returns a string representing the background color based on the given status.
 */
  function getStatusBackgroundColor(status) {
      if (status === 'work-in-progress') {
          return 'bg-[#F5A623]';
      }else if (status === 'open' || status === '' || status === null) {
          return 'bg-[#4A90E2]';
      }else if (status === 're-opened') {
          return 'bg-[#EF4E3E]';
      }else if (status === 'closed') {
          return 'bg-[#38B449]';
      }else{
          return 'bg-transparent';
      }      
  }
 /**
  * The function takes a status parameter and returns a corresponding status label.
  * @param status - The parameter "status" is a string that represents the current status of a task or
  * issue.
  * @returns the status of a task or issue. If the status is 'work-in-progress', it will return
  * 'In-progress'. If the status is 'open', '', or null, it will return 'Open'. If the status is
  * 're-opened', it will return 'Reopen'. If the status is 'closed', it will return 'Closed'. If none
  * of these conditions are
  */
  function getStatus(status) {
      if (status === 'work-in-progress') {
          return 'In-progress';
      }else if (status === 'open' || status === '' || status === null) {
          return 'Open';
      }else if (status === 're-opened') {
          return 'Reopen';
      }else if (status === 'closed') {
          return 'Closed';
      }else{
          return '';
      }      
  }

  return (
    <div className="">
      <ul className="divide-y">
        {props.notificationData && props.notificationData.unread.length < 1 ? (
          <li className="lg:text-[15px] text-[15px] text-[#333333] relative mt-[14rem] text-center">No unread records</li>
        ) : (
          props.notificationData.unread.map((data, i) => (
            <li className="py-2 pl-[1.75rem] cursor-pointer hover:bg-gray-200" onClick={() => props.handleNotificationClick(data,false,'request')} key={i}>
              <div className="flex space-x-2">
                <img
                  className="h-6 w-6 rounded-full mt-2"
                  src={mindTreeLogo}
                  alt=""
                />
                <div className="flex-1 pt-2">
                  <div className="flex items-center justify-between">
                    <h3 className=" flex text-[14px] text-[#333333] font-SequelSans tracking-[-0.26px] leading-[17px]">
                      {/* Fanzstar */}
                      {data.entity_name}
                      <p className="pl-1 text-[11px]  font-SequelSans text-[#888888] font-medium leading-[12px] tracking-[-0.24px]" style={{paddingTop:"0.1rem"}}>
                          {data.notified_at}
                      </p>
                    </h3>
                    <div className="flex px-4">
                      {}
                  <span
                    className={`mt-2 flex-shrink-0 rounded-full ${getStatusBackgroundColor(data.status)}  top-0  inline-flex items-center justify-center h-1 w-1 p-1 text-xs  transform translate-x-1/2 -translate-y-1/2`}
                    aria-hidden="true"
                  ></span>
                  <p className="text-[14px] font-SequelSans pl-2 leading-[17px] font-SequelSans tracking-[-0.26px] text-[#333333]">
                    {getStatus(data.status)}
                  </p>
                </div>
                  </div>
                  <p className="text-[13px] font-SequelSans leading-[16px] tracking-[0.32px]  text-[#888888]">
                    {/* Sanjay Kapoor */}
                    {/* {data.date} */}
                    {data.sent_by}
                  </p>
                  <em className="text-[13px] font-SequelSans text-[#333333] pt-1 leading-[18px] tracking-[-0.32px]">
                    {/* Request for more deal data */}
                    {data.entity_content}
                  </em>
                </div>
              </div>
            </li>
          ))
        )}
      </ul>
    </div>
  );
};

export default Notify;
