import React from 'react'
import { NavLink } from 'react-router-dom'
import Constants from '../../Constants'

/**
 * The function `SubscribeProductPage` returns a component that displays a message and a button to
 * navigate back to a specified page.
 * @returns a JSX element.
 */
function SubscribeProductPage({ to }) {
    return (
        <div className='h-screen w-full flex justify-center items-center'>
            <center>
                <h2>{Constants.subscribeProductMsg}</h2><br />
                <NavLink to={to}><button type="submit" className="bg-[#BC8B20] w-[50px] h-[28px] text-[#FFFFFF] rounded-[3px] font-[SquelSans] leading-[17px] ">
                     Back
                </button></NavLink>
            </center>
        </div>
    )
}

export default SubscribeProductPage