import { React, useContext } from 'react'
import Table from './Table'
import { TableHeaderContext } from '../../../Contexts/TableHeaderContext'

/* The code is defining a functional component called `TableComponent` that takes in `props` as its
parameter. */
const TableComponent = (props) => {
    const { NewInvestor, ActiveInvestor,AngelActiveInvestor, IncubatorNewInvestor, IncubatorActiveInvestor } = useContext(TableHeaderContext);

    const columns = props.menu == "new-investor"
        ? props.submenu == "incubation" ? IncubatorNewInvestor : NewInvestor
        : props.submenu == "incubation" ? IncubatorActiveInvestor 
        : props.submenu === "angels"?AngelActiveInvestor: ActiveInvestor
    return (
        <div className="pl-3 pr-2 lg:pr-2 md:pr-3 overflow-y-auto">
            <div className="App lg:relative">
                <Table submenu={props.submenu} menu={props?.menu} columns={columns} primaryTypeMasterId={props.primaryTypeMasterId} primaryMasterSubId={props.primaryMasterSubId} />
            </div>
        </div>
    )
}
export default TableComponent