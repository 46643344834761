import React, { useContext, useEffect } from 'react'
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import FilterWrapper from './FilterWrapper';

/**
 * The function `InvestorIpoSaleFilter` is a React component that renders a filter for selecting
 * investor sale options in an IPO.
 * @returns a JSX element, which represents a section of a user interface.
 */
function InvestorIpoSaleFilter({ open, accordionClick, InvestorSaleOptions, setInvestorSaleOptions, customPathname }) {
    const { InvestorSaleIPO, refineSearchFilters } = useContext(PeFilterContext);

    useEffect(() => {
        const industryIds = refineSearchFilters[customPathname]?.investorSaleInipo?.map(value => value.value) ?? [];
        setInvestorSaleOptions(InvestorSaleIPO?.map(industry => ({ ...industry, isChecked: industryIds.includes(industry.value) })))
    }, [InvestorSaleIPO, customPathname, refineSearchFilters, setInvestorSaleOptions])


    const handleInvestorTypeChange = (e) => {

        const value = e.target.value
        // const values = companyType.filter(companyType => companyType.id === e.target.value);
        setInvestorSaleOptions(InvestorSaleOptions?.map(type => ({ ...type, isChecked: value === "" ? false : type.value === value })))
    };

    const selected = InvestorSaleOptions?.filter(option => option.isChecked)?.at(0)

    return (
        <div className="border-b-[3px] border-[#E8E6E1]">
            <li
                className={
                    open
                        ? "bg-white relative px-3 py-1"
                        : "relative px-3 py-1"
                }
            >
                <FilterWrapper
                    isSelect={true}
                    label={selected?.name ? "Investor Sale in IPO? - " + selected?.name : "Investor Sale in IPO?"}
                    onClick={accordionClick}
                    open={open}
                />

                <div
                    className="relative overflow-hidden transition-all max-h-0 duration-700  overflow-y-auto no-scrollbar"
                    style={{ maxHeight: open ? "10rem" : "" }}
                >

                    <select name="" id="" value={selected?.value ?? ""} onChange={handleInvestorTypeChange}
                        className="my-2 p-2 border border-[#D9BD7F] mr-3 rounded focus:outline-none bg-white w-full">
                        {InvestorSaleOptions && InvestorSaleOptions.length > 0 ? (
                            InvestorSaleOptions.map((investorIpo, index) => (
                                <option key={investorIpo.value} value={investorIpo.value}>{investorIpo.name}</option>
                            )))
                            :
                            (
                                <h1>No results found!</h1>
                            )}
                    </select>
                </div>

            </li>

        </div>
    )
}

export default InvestorIpoSaleFilter