import { useQuery, gql } from "@apollo/client";
// totalFunding

// (orderBy:[{column:COMPANYNAME,order:ASC}],first:20,page:1)
export const PE_TABLE_QUERY = gql`
  query ($page: Int!) {
    companies(first: 10, page: $page) {
      data {
        PECompanyId
        companyname
        city
        sector_business
        totalFundingUSD
        latestInvestmentRound
        latestInvestmentDate
        latestInvestmentAmount
      }
      paginatorInfo {
        currentPage
        lastPage
      }
    }
  }
`;

export const PE_VC_TABLE_QUERY = gql`
  query ($page: Int!) {
    companies(first: 10, page: $page) {
      data {
        companyname
        sector_business
        getPeVcInvestments {
          investors {
            Investor
          }
          dates
          exitStatus {
            status
          }
          amount
        }
      }
      paginatorInfo {
        currentPage
        lastPage
      }
    }
  }
`;

export const PE_VC_Exit_TABLE_QUERY = gql`
  query ($page: Int!) {
    companies(first: 10, page: $page) {
      data {
        companyname
        sector_business
        getMAPEExitsInvestments {
          investors {
            Investor
          }
          DealDate
          DealAmount
          mandaExitStatus
        }
      }
      paginatorInfo {
        currentPage
        lastPage
      }
    }
  }
`;

export const COMPANY_PROFILE = gql`
  query ($PECompanyId: Int!) {
    company(PECompanyId: $PECompanyId) {
      companyname
      tags
      industry {
        industry
      }
      sector_business
      moreInformation
      cprofileCompanyName
      pecompanyLogo{
        icon_path
      }
    }
  }
`;

export const INVESTOR_COMPANY_PROFILE = gql`
  query ($InvestorId: ID!) {
    peInvestor(InvestorId: $InvestorId) {
      City
      Zip
      yearfounded
      Telephone
      Address1
      Email
      website
      focuscapsource
      Assets_mgmt
      AlreadyInvested
      LimitedPartners
      Description
      Stage
      linkedIn
      drypowder
      PEinvestor {
        mandaInvestorsvw {
          total_amount
        }
        investorManagement {
          executives {
            ExecutiveName
            Designation
          }
        }
      }
    }
  }
`;

export const CO_INVESTOR_TAB = gql`
  query ($InvestorId: ID!) {
    coinvestor(InvestorId: $InvestorId) {
      company {
      PECompanyId
      companyname
      pecompanyLogo{
        icon_path
      }
    }
    coinvestor
  }
  }
`;

export const DEAL_DETAIL_TAB = gql`
  query ($PECompanyId: Int!) {
    company(PECompanyId: $PECompanyId) {
      investments {
        PEId
        roundmap {
          new_round
        }
      }
    }
  }
`;
export const ANGEL_DEAL_INFO = gql`
  query ($InvesteeId: Int!, $AngelDealId: ID!) {
    angelinvestment(
      InvesteeId: $InvesteeId
      AngelDealId: $AngelDealId
      where: { column: DELETED, operator: EQ, value: 0 }
    ) {
      Date
      RoundMultiple
      FollowFund
      AngelExited
      Link
      angelInvestors {
        InvestorId
        InvestorName
        logo_path
      }
    }
  }
`;

export const INCUBATION_DEAL_INFO = gql`
  query ($IncubateeId: Int!, $IncDealId: ID!) {
    incubatordeal(
      IncubateeId: $IncubateeId
      IncDealId: $IncDealId
      where: { column: DELETED, operator: EQ, value: 0 }
    ) {
      date_month_year
      incubator {
        Incubator
      }
      incstatus {
        Status
      }
      Fund
    }
  }
`;

export const TAB_DEAL_DETAIL = gql`
  query ($PEId: ID!, $PECompanyId: Int!) {
    investment(
      PEId: $PEId
      PECompanyId: $PECompanyId
      where: { column: DELETED, operator: EQ, value: 0 }
    ) {
      PEId
      dates
      amount
      Amount_INR
      exitStatus {
        status
      }
      stakepercentage
      stage {
        Stage
      }
      roundmap {
        new_round
      }
      Link
      price_per_share
      book_value_per_share
      price_to_book
      investors {
        Amount_INR
        Amount_M
        Investor
        InvestorId
      }
      Company_Valuation
      Company_Valuation_EV
      Company_Valuation_pre
      Revenue_Multiple
      Revenue_Multiple_pre
      Revenue_Multiple_EV
      EBITDA_Multiple
      EBITDA_Multiple_pre
      EBITDA_Multiple_EV
      PAT_Multiple
      PAT_Multiple_pre
      PAT_Multiple_EV
      Valuation
      Revenue
      EBITDA
      PAT
      Total_Debt
      Cash_Equ
      advisorsForCompany {
        CompanyAdvisorName
        CompanyAdvisorType
      }
      advisorsForInvestor {
        InvestorAdvisorName
        InvestorAdvisorType
      }
      MoreInfor
    }
  }
`;

export const EXITS_TAB_DETAILS = gql`
  query ($MandAId: ID!, $PECompanyId: Int!) {
    exitinvestment(
      MandAId: $MandAId
      PECompanyId: $PECompanyId
      where: { column: DELETED, operator: EQ, value: 0 }
    ) {
      MandAId
      DealAmount
      DealDate
      dealtype {
        DealType
      }
      stakeSold
      Link
      acquirerGroup
      targetIndustry
      acquirerIndustry
      targetCountry
      acquirerCountry
      targetCity
      acquirerCity
      targetWebsite
      acquirerWebsite
      Company_Valuation
      Revenue_Multiple
      EBITDA_Multiple
      PAT_Multiple
      MoreInfor
      Revenue
      EBITDA
      PAT
      acquirers {
        exitManda {
          DealDate
          DealAmount
          targetCompanyName
        }
      }
      companyAdvisor {
        CompanyAdvisorName
        CompanyAdvisorType
      }
      peExitAdvisorAcquirer {
        AcquirerAdvisorName
        AcquirerAdvisorType
      }
    }
  }
`;

export const SHAREHOLDING_PATTERN_DEAL_DETAIL = gql`
  query ($PEId: ID!, $PECompanyId: Int!) {
    investment(PEId: $PEId, PECompanyId: $PECompanyId) {
      peshp {
        pe_shp_investor_total
        pe_shp_promoters_total
        ESOP
        Others
      }
      peshpinvestor {
        investor_name
        stake_held
      }
      peshppromoter {
        promoters_name
        stake_held
      }
      peshpother {
        others_name
        stake_held
      }
    }
  }
`;

export const SHAREHOLDING_PATTERN_DEAL_DETAIL_EXIT = gql`
  query ($MandAId: ID!, $PECompanyId: Int!) {
    exitinvestment(MandAId: $MandAId, PECompanyId: $PECompanyId) {
      peshp {
        pe_shp_investor_total
        pe_shp_promoters_total
        ESOP
        Others
      }
      peshppromoter {
        promoters_name
        stake_held
      }
      peshpinvestor {
        investor_name
        stake_held
      }
      peshpother {
        others_name
        stake_held
      }
    }
  }
`;

export const PE_VC_CARD_QUERY = gql`
  {
    totalVCFunding
  }
`;

export const TOTAL_FUNDING_USD_CARD_QUERY = gql`
  {
    totalFundingUSD
  }
`;

export const TOTAL_FUNDING_INR_CARD_QUERY = gql`
  {
    totalFundingINR
  }
`;

export const TOTAL_COMPANIES_CARD_QUERY = gql`
  {
    totalCompanies
  }
`;

export const PROFILE_HIGHLIGHT = gql`
  query ($PECompanyId: Int!) {
    company(PECompanyId: $PECompanyId) {
      CINNo
      Email
      Telephone
      website
      cityMaster
      stateMaster
      regionMaster
      countryMaster
      revenue
      ebitda
      pat
      companyManagement {
        ExecutiveId
        executive {
          ExecutiveName
          Designation
        }
      }
      latestInvestmentExitStatus
      latestInvestmentLink
      latestInvestmentStage
      latestInvestmentDate
      totalFundingUSD
      totalFundingExits
      totalInvestorCount
      latestInvestmentListingStatus
      shpInvestor
      shpPromotor
      shpESOP
      shpOther
      yearfounded
      auditor_name
      transaction_status
      peCompanyBoardMembers {
        executive
        designation
      }
      investments {
        peshp {
          pe_shp_investor_total
          pe_shp_promoters_total
          ESOP
          Others
        }
      }
    }
  }
`;
export const CAP_TABLE = gql`
  query ($PECompanyId: Int!) {
    company(PECompanyId: $PECompanyId) {
      investments {
        CaptableModuleDetail {
          first_name
          last_name
          total_shares
          valuation_last_round
          captableModuleRoundDetail {
            roundMap {
              new_round
            }
            value
          }
        }
      }
    }
  }
`;
export const FUNDING_INVESTMENT = gql`
  query ($PECompanyId: Int!) {
    company(PECompanyId: $PECompanyId) {
      investments {
        PEId
        roundmap {
          new_round
        }
        dates
        amount
        Revenue_Multiple
        Company_Valuation
        investors {
          Investor
          InvestorId
        }
        investorInfoNew
      }
    }
  }
`;

export const FUNDING_EXIT = gql`
  query ($PECompanyId: Int!) {
    company(PECompanyId: $PECompanyId) {
      getMAPEExitsInvestments {
        MandAId
        investors
        dealtype{
          DealType
        }
          Revenue_Multiple
        DealDate
        Company_Valuation
        DealAmount
        mandaExitStatus
      }
    }
  }
`;

export const FUNDING_BACKED_IPO = gql`
  query ($PECompanyId: Int!) {
    company(PECompanyId: $PECompanyId) {
      ipoInvestments{
        IPOId
        IPOSize
        IPODate
        InvestorSale
        IPOValuation
        investors{
          Investor
          InvestorId
        }
      }
    }
  }
`;

export const FUNDING_ANGEL = gql`
  query ($PECompanyId: Int!) {
    company(PECompanyId: $PECompanyId) {
      angelDealInvestor {
        Investor
        AngelDealId
        Dealdate
        Exited
      }
    }
  }
`;

export const FUNDING_INCUBATION = gql`
  query ($PECompanyId: Int!) {
    company(PECompanyId: $PECompanyId) {
      incubatorInvestments {
        IncDealId
        date_month_year
        incubator {
          Incubator
        }
        incstatus {
          Status
        }
      }
    }
  }
`;

export const MERGERANDACQUISITION = gql`
  query ($TagName: Mixed!) {
    mamavw(
      where: {
        OR: [
          { column: COMPANYNAME, operator: LIKE, value: $TagName }
          { column: ACQUIRER, operator: LIKE, value: $TagName }
        ]
      }
    ) {
      companyname
      mamaid
      pecompanyid
      acquirer
      amount
      dates
      Revenue_Multiple
      icon_path
    }
  }
`;

export const FROMMERGERANDACQUISITION = gql`
  query ($TagName: Mixed!) {
    mamavw(
      where: {
        OR: [
          { column: COMPANYNAME, operator: LIKE, value: $TagName }
          { column: ACQUIRER, operator: LIKE, value: $TagName }
        ]
      }
    ) {
      companyname
      sector_business
      acquirer
      amount
      deal_date
    }
  }
`;

export const FINANCIAL = gql`
  query ($PECompanyId: Int!) {
    company(PECompanyId: $PECompanyId) {
      cprofile {
        plStandardConsolidated {
          FY
          OptnlIncome
          EBITDA
          PAT
        }
        plStandardStandalone {
          FY
          OptnlIncome
          EBITDA
          PAT
        }
        balanceSheetConsolidated {
          FY
          ShareCapital
          TotalFunds
          T_equity_liabilities
          T_current_liabilities
          T_non_current_liabilities
        }
        balanceSheetStandalone {
          FY
          ShareCapital
          TotalFunds
          T_equity_liabilities
          T_current_liabilities
          T_non_current_liabilities
        }
        cashflowConsolidated {
          FY
          NetPLBefore
          NetIncDecCash
        }
        cashflowStandalone {
          FY
          NetPLBefore
          NetIncDecCash
        }
      }
    }
  }
`;

export const SIMILAR_COMPANIES = gql`
  query ($TagName: String!) {
    pecompany(c_tag: $TagName) {
      investments {
        PEId
      }
      PECompanyId
      companyname
      totalFundingUSD
      city
      latestInvestmentRound
      latestInvestmentDate
      latestInvestmentDealId
      latestInvestmentAmount
      pesectors {
        sector_name
      }
      companylogo
    }
  }
`;

export const NEWSLETTER = gql`
  query ($TagName: String!) {
    newsletter(
      heading: $TagName
      orderBy: { column: PUBLISHED_AT, order: DESC }
    ) {
      heading
      published_at
      slug
      category {
        slug
      }
    }
  }
`;

export const DEATAILPAGE_CARD = gql`
  query ($PECompanyId: Int!) {
    company(PECompanyId: $PECompanyId) {
      totalFundingUSD
      totalFundingExits
      latestInvestmentRound
      totalInvestorCount
      revenue
    }
  }
`;

//investor page query

//list page

export const PE_VC_INVESTOR_CARD_QUERY = gql`
  {
    totalPEVCInvestors
  }
`;

export const PE_VC_INVESTMENT_CARD_QUERY = gql`
  {
    totalPEVCInvestments
  }
`;

export const INVESTOR_TABLE_QUERY = gql`
  {
    peInvestors(first: 50) {
      data {
        Investor
        InvestorId
        total_amount
        funds
        portfolioCompaniesCount
        PEinvestor {
          NoFunds
          AlreadyInvested
        }
      }
    }
  }
`;

//detail page

export const INVESTOR_CARD_QUERY = gql`
  query ($InvestorId: ID!) {
    InvestorFund(InvestorId: $InvestorId) {
      investorFundTotal
      investorCompanycnt
      investorDealcnt
      mandaInvestorsvw {
        investorExitTotal
      }
      unicorncnt
      ipoCount
    }
  }
`;

export const INVESTOR_PROFILE_TABLE_QUERY = gql`
  query ($InvestorId: ID!) {
    Investor(InvestorId: $InvestorId) {
      Investor
      investorIndutry
      FirmType
      Description
      peinvestorLogo{
        icon_path
      }
    }
  }
`;

export const EXIT_PORTFOLIO_TABLE_QUERY = gql`
  query ($InvestorId: ID!) {
    exitInvestor(InvestorId: $InvestorId) {
      exitportfolioCompanies {
        PECompanyId
        companyname
        dealperiod
        MandAId
        Acquirer
        getExitStatus
        getExitType
        exitinvestments {
          Revenue_Multiple
        }
        pecompanyLogo{
          icon_path
        }
      }
    }
  }
`;

export const INVESTOR_PORTFOLIO_TABLE_QUERY = gql`
  query ($InvestorId: ID!) {
    peInvestor(InvestorId: $InvestorId) {
      portfolioCompanies {
        companyname
        PECompanyId
        dealperiod
        PEId
        amount
        Amount_INR
        Company_Valuation
        Revenue_Multiple
        pecompanyLogo{
          icon_path
        }
      }
    }
  }
`;

export const INVESTOR_FUND = gql`
  query ($InvestorId: ID!) {
    InvestorFund(InvestorId: $InvestorId) {
      Investor
      investorFund {
        fundName
        id
        fundId
        fundDate
        size
        amount_raised
        fundStatus
        source
      }
    }
  }
`;

export const INVESTORNEWSLETTER = gql`
  query ($TagName: String!) {
    newsletter(
      heading: $TagName
      orderBy: { column: PUBLISHED_AT, order: DESC }
    ) {
      heading
      published_at
      slug
      category {
        slug
      }
    }
  }
`;
// legal advisor detail page

export const LEGAL_ADVISOR_CARD_QUERY = gql`
  query ($CIAId: ID!, $AdvisorType: String!) {
    advisor(CIAId: $CIAId, AdvisorType: $AdvisorType) {
      advisorCompanyCnt
      advisorExitCompanyCnt
      advisorInvestorCnt
      advisorAcquirerCnt
      advisorTotalCnt
    }
  }
`;

export const LEGAL_ADVISOR_PROFILE = gql`
  query ($CIAId: ID!, $AdvisorType: String!) {
    advisor(CIAId: $CIAId, AdvisorType: $AdvisorType) {
      cianame
      AdvisorType
      peadvisorLogo{
        icon_path
      }  
    }
  }
`;

export const LEGAL_ADVISOR_OVERVIEW = gql`
  query ($CIAId: ID!, $AdvisorType: String!) {
    advisor(CIAId: $CIAId, AdvisorType: $AdvisorType) {
      contactperson
      designation
      email
      phoneno
      website
      address
      city
    }
  }
`;

export const ADVISOR_COMPANY_INVESTMENTS = gql`
  query ($CIAId: ID!, $AdvisorType: String!) {
    advisor(CIAId: $CIAId, AdvisorType: $AdvisorType) {
      advisorCompanyInvestment {
        PECompanyId
        companyname
        dates
        PEId
        amount
        Revenue_Multiple
        Company_Valuation
        investor
      }
    }
  }
`;

export const ADVISOR_COMPANY_EXITS = gql`
  query ($CIAId: ID!, $AdvisorType: String!) {
    advisor(CIAId: $CIAId, AdvisorType: $AdvisorType) {
      advisorExitCompany {
        PECompanyId
        Companyname
        DealDate
        MandAId
        DealType
        exitstatus
        Acquirer
        Revenue_Multiple
      }
    }
  }
`;

export const ADVISOR_COMPANY_TOINVESTORS = gql`
  query ($CIAId: ID!, $AdvisorType: String!) {
    advisor(CIAId: $CIAId, AdvisorType: $AdvisorType) {
      advisorInvestor {
        Investor
        InvestorId
        dates
        PEId
        amount
        PECompanyId
        Companyname
        logo_path
        Company_Valuation
        Revenue_Multiple
      }
    }
  }
`;

export const ADVISOR_COMPANY_TOACQUIRER = gql`
  query ($CIAId: ID!, $AdvisorType: String!) {
    advisor(CIAId: $CIAId, AdvisorType: $AdvisorType) {
      advisorAcquirer {
        acquirer
        acquirerId
        dealDate
        PEId
        dealAmount
        companyName
        companyId
        companyValuation
        revenueMultiple
      }
    }
  }
`;

export const ADVISORNEWSLETTER = gql`
  query ($TagName: String!) {
    newsletter(
      heading: $TagName
      orderBy: { column: PUBLISHED_AT, order: DESC }
    ) {
      heading
      published_at
    }
  }
`;

// transaction advisor detail page

export const T_LEGAL_ADVISOR_CARD_QUERY = gql`
  query ($CIAId: ID!, $AdvisorType: String!) {
    advisor(CIAId: $CIAId, AdvisorType: $AdvisorType) {
      advisorCompanyCnt
      advisorExitCompanyCnt
      advisorInvestorCnt
      advisorAcquirerCnt
      advisorTotalCnt
    }
  }
`;

export const T_LEGAL_ADVISOR_PROFILE = gql`
  query ($CIAId: ID!, $AdvisorType: String!) {
    advisor(CIAId: $CIAId, AdvisorType: $AdvisorType) {
      cianame
      AdvisorType
    }
  }
`;

export const T_LEGAL_ADVISOR_OVERVIEW = gql`
  query ($CIAId: ID!, $AdvisorType: String!) {
    advisor(CIAId: $CIAId, AdvisorType: $AdvisorType) {
      contactperson
      designation
      email
      phoneno
      website
      address
      city
    }
  }
`;

export const T_ADVISOR_COMPANY_INVESTMENTS = gql`
  query ($CIAId: ID!, $AdvisorType: String!) {
    advisor(CIAId: $CIAId, AdvisorType: $AdvisorType) {
      advisorCompanyInvestment {
        PECompanyId
        companyname
        dates
        PEId
        amount
        Revenue_Multiple
        Company_Valuation
        investor
      }
    }
  }
`;

export const T_ADVISOR_COMPANY_EXITS = gql`
  query ($CIAId: ID!, $AdvisorType: String!) {
    advisor(CIAId: $CIAId, AdvisorType: $AdvisorType) {
      advisorExitCompany {
        PECompanyId
        Companyname
        DealDate
        MandAId
        DealType
        exitstatus
        Acquirer
        Revenue_Multiple
      }
    }
  }
`;

export const T_ADVISOR_COMPANY_TOINVESTORS = gql`
  query ($CIAId: ID!, $AdvisorType: String!) {
    advisor(CIAId: $CIAId, AdvisorType: $AdvisorType) {
      advisorInvestor {
        Investor
        InvestorId
        dates
        PEId
        amount
        PECompanyId
        Companyname
        Company_Valuation
        Revenue_Multiple
      }
    }
  }
`;

export const T_ADVISOR_COMPANY_TOACQUIRER = gql`
  query ($CIAId: ID!, $AdvisorType: String!) {
    advisor(CIAId: $CIAId, AdvisorType: $AdvisorType) {
      advisorAcquirer {
        acquirer
        acquirerId
        dealDate
        PEId
        dealAmount
        companyName
        companyId
        companyValuation
        revenueMultiple
      }
    }
  }
`;

export const T_ADVISORNEWSLETTER = gql`
  query ($TagName: String!) {
    newsletter(
      heading: $TagName
      orderBy: { column: PUBLISHED_AT, order: DESC }
    ) {
      heading
      published_at
    }
  }
`;

export const LP_NEWSLETTER = gql`
  query ($TagName: String!) {
    newsletter(
      heading: $TagName
      orderBy: { column: PUBLISHED_AT, order: DESC }
    ) {
      heading
      published_at
      slug
      category {
        slug
      }
    }
  }
`;
// refine filter industry dropdown
export const INDUSTRY_DROPDOWN_QUERY = gql`
  {
    industries {
      industryid
      industry
    }
  }
`;

// refine filter sector dropdown
export const SECTOR_DROPDOWN_QUERY = gql`
  query ($industryid: Int!) {
    industryid
    industry
    pesectors {
      sector_name
    }
  }
`;

// Deal page card - Investment

export const DEAL_INVESTMENT_QUERY = gql`
  query ($PECompanyId: Int!) {
    company(PECompanyId: $PECompanyId) {
      totalFundingUSD
      totalFundingExits
      latestInvestmentRound
      totalInvestorCount
      revenue
    }
  }
`;

// Deal page card - Exit

export const DEAL_EXIT_QUERY = gql`
  query ($PECompanyId: Int!) {
    company(PECompanyId: $PECompanyId) {
      totalMandaFundingUSD
      totalMandaFundingExits
      getMandaDealType
      totalMandaInvestorCount
      revenue
    }
  }
`;
