/* The `moduleValues` array is exporting a list of objects. Each object represents a module and has a
`title` and `value` property. The `title` property represents the name of the module, such as
"Companies" or "Investors", and the `value` property represents a unique identifier for the module,
such as "1" or "2". This array can be used to populate a dropdown or select input with the module
options. */
export const moduleValues = [
    {
        title: "All",
        value: "All"
    },
    {
        title: "Companies",
        value: "1"
    },
    {
        title: "Investors",
        value: "2"
    },
    {
        title: "Legal Advisors",
        value: "3"
    },
    {
        title: "Transaction Advisors",
        value: "4"
    },
    {
        title: "Limited Partners",
        value: "5"
    },
    {
        title: "Acquirers",
        value: "6"
    },
    {
        title: "Funds",
        value: "7"
    },
    {
        title: "Incubators",
        value: "8"
    },
];

/* The `primaryMasterValues` array is exporting a list of objects. Each object represents a primary
master value and has a `title` and `value` property. The `title` property represents the name of the
primary master value, such as "All" or "PE", and the `value` property represents a unique identifier
for the primary master value, such as "All" or "1". This array can be used to populate a dropdown or
select input with the primary master value options. */
export const primaryMasterValues = [
    {
        title: "All",
        value: "All"
    },
    {
        title: "PE",
        value: "1"
    },
    {
        title: "VC",
        value: "2"
    },
    {
        title: "RE",
        value: "3"
    },
    {
        title: "M & A",
        value: "4"
    },
    {
        title: "CFS",
        value: "5"
    }
]

export const filterCategoryValues = [
    {
        title: "My Filters",
        value: "My Filters"
    },
    {
        title: "VI Filters",
        value: "VI Filters"
    },
    {
        title: "Both",
        value: "Both"
    },
]

// export const subMenuValues = [
//     {
//         title: "PE-VC Investments",
//         value: 1
//     },
//     {
//         title: "PE-VC Exits",
//         value: 2
//     },
//     {
//         title: "PE-VC Exits(M&A)",
//         value: 3
//     },
//     {
//         title: "PE-VC Exits(Public Market)",
//         value: 4
//     },
//     {
//         title: "PE-VC Backed IPOs",
//         value: 5
//     },
//     {
//         title: "PE-VC Angels",
//         value: 6
//     },
//     {
//         title: "PE-VC Incubation",
//         value: 7
//     },
//     {
//         title: "PE-VC Advisors",
//         value: 8
//     },
//     {
//         title: "PE-VC Exits",
//         value: 9
//     },
//     {
//         title: "PE-VC Exits(M&A)",
//         value: 10
//     },
//     {
//         title: "PE-VC Exits(Public Market)",
//         value: 11
//     },
//     {
//         title: "PE-VC Limited Partners",
//         value: 11
//     },
//     pe_vc_exits_limited_partners_id: 13,
//     pe_vc_ma_limited_partners_id: 14,
//     pe_vc_pm_limited_partners_id: 15,
//     pe_vc_funds_id: 16,
//     vc_investments_id: 17,
//     vc_exits_id: 18,
//     vc_ma_id: 19,
//     vc_pm_id: 20,
//     vc_backed_ipos_id: 21,
//     vc_angel_invesments_id: 22,
//     vc_incubation_id: 23,
//     vc_advisors_id: 24,
//     vc_advisors_exits_id: 25,
//     vc_advisors_ma_id: 26,
//     vc_advisors_pm_id: 27,
//     vc_limited_partners_id: 28,
//     vc_exits_limited_partners_id: 29,
//     vc_ma_limited_partners_id: 30,
//     vc_pm_limited_partners_id: 31,
//     vc_funds_id: 32,
//     re_investments_id: 33,
//     re_exits_id: 34,
//     re_others_ma_id: 35,
//     re_backed_ipos_id: 36,
//     re_investor_investment_id: 37,
//     re_investor_exits_id: 38,
//     re_investor_ipo_id: 39,
//     re_advisor_investment_id: 40,
//     re_advisor_exits_id: 41,
//     re_advisor_otherma_id: 42,
//     re_transaction_investment_id: 43,
//     re_transaction_exits_id: 44,
//     re_transaction_otherma_id: 45,
//     ma_companies_id: 46,
//     ma_acquirer_id: 47,
//     ma_l_advisor: 48,
//     ma_t_advisor: 49,
// ]
/* The `primaryMasterSubValues` array is exporting a list of objects. Each object represents a
sub-value for the primary master value and has a `title` and `value` property. The `title` property
represents the name of the sub-value, such as "PE-VC Investments" or "PE-VC Exits", and the `value`
property represents a unique identifier for the sub-value, such as "1" or "2". This array can be
used to populate a dropdown or select input with the sub-value options for the primary master value. */
export const primaryMasterSubValues = [    
    {
        title: "PE-VC Investments",
        value: "1"
    },
    {
        title: "PE-VC Exits",
        value: "2"
    },
    {
        title: "via M&A (PE-VC)",
        value: "3"
    },
    {
        title: "via Public Market (PE-VC)",
        value: "4"
    },
    {
        title: "PE-VC Backed IPOs",
        value: "5"
    },
    {
        title: "Angel Investments",
        value: "6"
    },
    {
        title: "Incubation",
        value: "7"
    },
    {
        title: "PE-VC Investments",
        value: "8"
    },
    {
        title: "PE-VC Exits",
        value: "9"
    },
    {
        title: "via M&A (PE-VC)",
        value: "10"
    },
    {
        title: "via Public Market (PE-VC)",
        value: "11"
    },
    {
        title: "VC Investments",
        value: "17"
    },
    {
        title: "VC Exits",
        value: "18"
    },
    {
        title: "via M&A (VC)",
        value: "19"
    },
    {
        title: "via Public Market (VC)",
        value: "20"
    },
    {
        title: "VC Backed IPOs",
        value: "21"
    },
    {
        title: "Angel Investments",
        value: "22"
    },
    {
        title: "Incubation",
        value: "23"
    },
    {
        title: "VC Investments",
        value: "24"
    },
    {
        title: "VC Exits",
        value: "25"
    },
    {
        title: "via M&A (VC)",
        value: "26"
    },
    {
        title: "via Public Market (VC)",
        value: "27"
    },
    {
        title: "PE-RE Investments",
        value: "33"
    },
    {
        title: "PE-RE Exits via M&A",
        value: "34"
    },
    {
        title: "Other M&A",
        value: "35"
    },
    {
        title: "PE-RE Backed IPOs",
        value: "36"
    },
    {
        title: "PE-RE Investments",
        value: "37"
    },
    {
        title: "PE-RE Exits via M&A",
        value: "38"
    },
    {
        title: "PE-RE Backed IPOs",
        value: "39"
    },
    {
        title: "PE-RE Investments",
        value: "40"
    },
    {
        title: "PE-RE Exits via M&A",
        value: "41"
    },
    {
        title: "PE-RE Other M&A",
        value: "42"
    },
    {
        title: "PE-RE Investments",
        value: "43"
    },
    {
        title: "PE-RE Exits via M&A",
        value: "44"
    },
    {
        title: "PE-RE Other M&A",
        value: "45"
    },


    

]

/* The `categories` array is exporting a list of objects. Each object represents a category and has a
`title` and `value` property. The `title` property represents the name of the category, such as
"Approved" or "Un Approved", and the `value` property represents a unique identifier for the
category, such as "approved" or "unApproved". This array can be used to populate a dropdown or
select input with the category options. */
export const categories = [
    {
        title: "Approved",
        value: "approved"
    },
    {
        title: "Un Approved",
        value: "unApproved"
    },
    {
        title: "Both",
        value: "both"
    },
    
]



/* The `SearchTrendOption` constant is exporting an array of objects. Each object represents an option
for a search trend and has a `title` and `value` property. The `title` property represents the name
of the search trend option, such as "Last 7 days" or "This Month", and the `value` property
represents a unique identifier for the search trend option, such as "7days" or "thisMonth". This
array can be used to populate a dropdown or select input with the search trend options. */
export const SearchTrendOption = [
    {
        title: "Last 7 days",
        value: "7days"
    },
    {
        title: "Last 15 days",
        value: "15days"
    },
    {
        title: "This Month",
        value: "thisMonth"
    },
    {
        title: "This Year",
        value: "thisYear"
    },
    {
        title: "All Time",
        value: "allTime"
    }
]