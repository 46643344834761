import React, { useState, useMemo, useEffect } from "react";
import { useSearchParams, Routes, Route, useParams, NavLink } from 'react-router-dom';
import { useQuery } from "@apollo/client";
import sorting from '../../../images/up-down-arrow.png'
import moment from "moment";
import DataTable from '../../../Components/DataTables/DataTable';
import { DataFoundContext } from "../../../Contexts/DataFoundContext";
import { useContext } from "react";


/**
 * The `TransactionAdvisorNewsActivity` function is a React component that displays recent news and
 * activities related to a transaction advisor.
 * @param props - The `props` parameter is an object that contains the properties passed to the
 * `TransactionAdvisorNewsActivity` component. These properties can be accessed using dot notation,
 * such as `props.propertyName`.
 * @returns The code is returning a React component that renders a section displaying recent news and
 * activities. It includes a table with columns for "News" and "Release Date", and fetches data from
 * the `props.newsActivity` array. The table is sorted by the "Release Date" column in descending
 * order.
 */
const TransactionAdvisorNewsActivity = (props) => {
  const { companyId } = useParams();
  let advisorProfileId = parseInt(companyId)

  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(20)
  const [newsletter, setNewsletter] = useState({});
  const { newsAndActivityDataFound } = useContext(DataFoundContext);

  const indexOfLastPost = currentPage * dataPerPage;
  const indexOfFirstPost = indexOfLastPost - dataPerPage;
  const currentData = newsletter?.newsletter?.slice(indexOfFirstPost, indexOfLastPost);
  // alert(currentData)
  const pageNumbers = []

  for (let i = 1; i <= Math.ceil(newsletter.length / dataPerPage); i++) {
    pageNumbers.push(i);
  }
 
  /**
   * The function `setPage` sets the current page to the specified page number.
   * @param pageNum - The pageNum parameter is the number of the page that you want to set as the
   * current page.
   */
  const setPage = (pageNum) => {
    setCurrentPage(pageNum)

  }
 
  /* The `customStringSortMemo` constant is using the `useMemo` hook to memoize a custom sorting
  function for string values in a table column. */
  const customStringSortMemo = useMemo(() => {
    return (rowA, rowB, columnId, desc) => {
        const defaultVal = desc ? '--' : 'A';
        return (rowA?.values[columnId]?.heading ?? defaultVal).localeCompare(rowB?.values[columnId]?.heading ?? defaultVal);
    }
}, []);

/* The code is defining a custom sorting function for the "Release Date" column in the table. */
const dealDateSort = useMemo(() => {
  return (rowA, rowB, columnId, desc) => {
      return moment.utc(rowA?.values[columnId].date).diff(moment.utc(rowB?.values[columnId].date))
  }
}, [])
  
/* The `const columns` variable is an array of objects that define the columns for the table in the
  `TransactionAdvisorNewsActivity` component. Each object represents a column and contains
  properties such as `Header` (the column header text), `accessor` (the key to access the data for
  that column), `className` (the CSS class for the column), `headerClassName` (the CSS class for the
  column header), and `Cell` (a function that returns the content for each cell in the column). */
  const columns = useMemo(() => [
    {
      Header: "News",
      accessor: "news",
      className: "text-[#333333] w-[14%] text-[12pt] px-6 py-4 flex-wrap lg:text-[14px] lg:leading-[16px]",
      headerClassName:"w-[15%]",
      Cell: ({ value }) => {
        return <a href={"https://news.ventureintelligence.com/" + (value?.category?.slug) + '/' + value?.slug} target="_blank" rel="noreferrer">
          {value?.heading}
        </a>
      }
    },
    {
      id: 'releaseDate',
      Header: "Release Date",
      accessor: "releaseDate",
      className: "text-[#333333] lg:px-12 px-3 py-4 float-center lg:float-right whitespace-nowrap lg:text-[14px] lg:leading-[16px]",
      headerClassName: "text-center float-right flex lg:px-10 px-4 py-3.5",
      Cell: ({ value }) => {
        return moment(value?.date).format('MMM DD, YYYY')
      },
      sortType: dealDateSort,
    }
  ], [])
  
  /* The `const rowData` variable is using the `useMemo` hook to memoize a function that creates an
  array of objects. Each object represents a row in the table and contains the data for the "News"
  and "Release Date" columns. */
  const rowData = useMemo(() => {
    const rows = [];
  
    if (newsletter) {
      props.newsActivity?.forEach(newsletter => {
        rows.push({
          news: newsletter,
          releaseDate: {date:newsletter?.published_at,news:newsletter}
        })
      });
    }
  
    return rows;
  
  }, [props.newsActivity])
  
  /* The `const initialSort` variable is using the `useMemo` hook to memoize an array that specifies
  the initial sorting configuration for the table in the `TransactionAdvisorNewsActivity` component. */
  const initialSort = useMemo(() => [
    {
        id: 'releaseDate',
        desc: true
    }
  ], []);
  return (
    <>
      {newsAndActivityDataFound ? (
        <div className="pb-5 px-4 bg-[#EDEDED] w-[100%]">
          <div className="bg-white overflow-hidden sm:rounded-lg shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
            <div className=" pt-4 px-5 border-b border-b-[#F1F1F1] ">
              <p className="text-[14pt] leading-[19pt] mb-3 lg:text-[17px] lg:leading-[22px] font-semibold text-[#333333]">
                Recent News & Activities
              </p>
            </div>
            <DataTable columns={columns} data={rowData} sortBy={initialSort}/>        
          </div>
        </div>
      ): ("")}
    </>
  );
};

export default TransactionAdvisorNewsActivity;
