import React from 'react'

/**
 * The CardWrapper function is a React component that wraps a set of children components in a div
 * container with specific styling and layout properties.
 * @returns The CardWrapper component is returning a div element with the class name 'sm:full
 * md:mt-[3rem] mt-[4.25rem] md:h-full bg-[#F2F2F2]'. Inside this div, there is another div element
 * with the class name 'overflow-x-auto lg:grid grid-cols-4 gap-4  px-4 py-[0.
 */
function CardWrapper({ children, className }) {
    return (
        <div className='sm:full md:mt-[3rem] mt-[4.25rem] md:h-full bg-[#F2F2F2]'>
            <div className={`overflow-x-auto lg:grid grid-cols-4 gap-4  px-4 py-[0.7rem] flex xl:overflow-hidden scrollbar-remove  ${className}`}>
                {children}
            </div>
            <div className='border-b-2 border-[#e5e5e5]'></div>
        </div >
    )
}

export default CardWrapper