import React from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import FilterTag from '../../../Components/Tag'
import RefineSearch from '../../../Components/RefineSearchComponent'
import Constants from '../../../Constants'
import { routeNames } from '../../../routeSegments'
import MainMenu from './MainMenu'
import TableComponent from './TableComponent'
import PrimaryButton from '../../../Components/shared/PrimaryButton'
import { AiOutlineArrowLeft } from 'react-icons/ai';
import ActiveInvestorRefineSearch from '../../../Components/RefineSearch/ActiveInvestorRefineSearch'
import RefineSearchBar from "../../../Components/RemovedArrowRefineSearch"

/**
 * The function `ActiveInvestors` is a React component that renders a page for active investors,
 * including a main section with a table of active investors and a sidebar for refining the search.
 * @returns a JSX element, which represents the structure and content of a React component.
 */
function ActiveInvestors({ primaryTypeMasterId, primaryMasterSubId, menu }) {
    const location = useLocation()
    return (
        <div className="flex h-screen flex-col  ">
            <div className="flex min-h-0 flex-1 overflow-hidden">

                <main className="min-w-0 flex-1 border-t border-gray-200 flex sm:full mt-[4.25rem] md:mt-[3rem]">

                    <section className="flex h-full min-w-0 flex-1 flex-col  lg:order-last bg-[#EDEDED]">
                        <div className='mt-2 mx-4 flex'>
                            <NavLink
                                to={primaryTypeMasterId == Constants.primaryMasterIds.peId ? routeNames.pe_investors_pe_vc_investments : routeNames.vc_investors_vc_investments}>
                                <button
                                    className="flex  bg-[#BC8B20] px-2 h-[24px] text-[#FFFFFF] text-[14px] rounded-[5px] leading-[17px] items-center">
                                    <AiOutlineArrowLeft className='mr-2' />
                                    <span className="whitespace-nowrap"> Back</span>
                                </button>
                            </NavLink>

                        </div>
                        <FilterTag />

                        <MainMenu submenu={menu} menu="active-investor" primaryTypeMasterId={primaryTypeMasterId}
                            subTypeMasterId={primaryMasterSubId}
                            typeId={Constants.moduleIds.investorsId} />
                        <div className="overflow-y-auto">
                            <TableComponent submenu={menu} menu="active-investor" primaryTypeMasterId={primaryTypeMasterId} primaryMasterSubId={primaryMasterSubId} />
                        </div>
                    </section>
                    {/* Secondary column (hidden on smaller screens)  */}
                    {primaryTypeMasterId == Constants.primaryMasterIds.peId?<aside className="order-first block flex-shrink-0">
                        <div className="relative flex flex-col border-r border-gray-200 bg-[#FAF5EA]">
                            {menu === "incubation" ? (
                                <RefineSearchBar /> 
                            ) : (
                            <RefineSearch>
                                <ActiveInvestorRefineSearch customPathname={location.pathname} menu={menu}/>
                            </RefineSearch>
                            )}
                        </div>
                    </aside>:<></>}
                </main>
            </div>           
        </div>
    )
}

export default ActiveInvestors