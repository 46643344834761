import React, { useState, useMemo, useEffect, useContext } from "react";
import { useSearchParams, Routes, Route, useParams, useLocation, matchPath } from 'react-router-dom';
import { useQuery } from "@apollo/client";
import axios from 'axios'
import FundingTable from '../../Funding/FundingTable';
import FundingTableInvestment from '../../../Components/Funding/FundingTableInvestment';
import FundingTableExit from '../../../Components/Funding/FundingTableExit'
import FundingTableAngelInvestment from "../../../Components/Funding/FundingTableAngelInvestment";
import FundingTableIncubation from "../../../Components/Funding/FundingTableIncubation";
import routePathSegments from "../../../routeSegments";
import ExcelDownloader from "../../shared/ExcelDownloader";
import { exportExcel, getCfsFundingApi, getFundingUrl } from "../../../Config/config";
import { FundingContext } from "../../../Contexts/FundingDataProvider";
import FundingTableBackedIpo from "../../../Components/Funding/FundingTableBackedIpo"
import Constants from "../../../Constants";
import { UserContext } from "../../../Contexts/UserContext";

const Funding = ({menu,companyName}) => {
  const [openTab, setOpenTab] = React.useState();
  const [showPopup, setShowPopup] = useState(false);
  const [showXlDownload, setshowXlDownload] = useState(false);
  const { pevcData,existData,angelData,incubationData , ipoData} = useContext(FundingContext);
  const { companyId } = useParams();
  const [fundingData, setFundingData] = useState();
  const { pathname, state } = useLocation();
  
  const {getToken, isStudent} = useContext(UserContext);

  const isVcCompanyMatch = matchPath({ path: `${routePathSegments.vc}/*` }, state?.prevPath ?? "")
  // const cfsMatch = matchPath({ path: `${routePathSegments.cfs}` }, state?.prevPath ?? "")
 /**
  * The function `getFunding` makes a POST request to a specified URL with the company ID and funding
  * type based on the current route path, and then sets the funding data if the request is successful.
  */
  const getFunding = () => {
    const peMatch = matchPath(`${routePathSegments.pe}/*`, pathname);
    const vcMatch = matchPath(`${routePathSegments.vc}/*`, pathname);
    const cfsMatch = matchPath(`${routePathSegments.cfs}/*`, pathname);
    const maMatch = matchPath(`${routePathSegments.cfs}/*`, pathname);
    axios(`${getFundingUrl}`, {
      method: "POST",
      data: {
        company_id: companyId,
        type: peMatch != null
          ? Constants.primaryMasterIds.peId
          : vcMatch != null
            ? Constants.primaryMasterIds.vcId
            : cfsMatch != null
              ? Constants.primaryMasterIds.cfsId
              : maMatch != null
                ? Constants.primaryMasterIds.mANDaId
                : Constants.primaryMasterIds.reId
      },

      headers: { 
        "Content-Type": "application/json",
        "Authorization":getToken() 
      },
    })
      .then((response) => {
        if (response?.data?.success == true) {
          console.log("setFundingDatasetFundingData",response?.data);
          setFundingData(response?.data?.data)
        }
      })
      .catch((error) => {
        console.log("API not working", error);
      });

  };
  
  useEffect(() => {  
    getFunding()                          
  }, [companyId]);
// console.log(existsData,'existsDataoffunding');
  useEffect(() => {
    var paths = pathname.split('/')
    var last = paths[paths.length - 2]

    // console.log("pathspaths", last);

    if (last === routePathSegments.incubation) {
      setOpenTab(5);
    } else if (last === routePathSegments.exists) {
      setOpenTab(2);
    } else if (last === routePathSegments.ipos) {
      setOpenTab(3);
    } else if (last === routePathSegments.angel_invesments) {
      setOpenTab(4);
    } else { 
      setOpenTab(1);
    }
  }, [pathname])

  useEffect(()=>{
    if(fundingData?.pe_vc_investments?.length >0 ){
      setOpenTab(1)
    }
    else if (fundingData?.pe_vc_exits?.length >0){
      setOpenTab(2)
    }
    else if (fundingData?.pe_vc_ipo?.length >0){
      setOpenTab(3)
    }
    else if (fundingData?.pe_vc_angel?.length >0){
      setOpenTab(4)
    }
    else if (fundingData?.pe_vc_incubation?.length >0){
      setOpenTab(5)
    }
  },[fundingData])

  /* The above code is defining a constant variable `xlDownloadRequest` using the `useMemo` hook in
  JavaScript. The `useMemo` hook is used to memoize the value of a variable and only recompute it
  when its dependencies change. */
  const xlDownloadRequest = useMemo(() => {
    let sub_section = "vc_investments";

    if (openTab === 2) {
      sub_section = "exits";
    } else if (openTab === 3) {
      sub_section = "ipos";
    } else if (openTab === 4) {
      sub_section = "angel";
    } else if (openTab === 5) {
      sub_section = "incubation";
    }
    return {
      "type": Constants.primaryMasterIds.cfsId,
      "export_from": "detail",
      "module_type": "investments",
      "section": "funding",
      "sub_section": sub_section,
      "company_id": [companyId]
    }
  }, [openTab, companyId])


  useEffect(() => {
    if (openTab === 1) {
      if (pevcData && fundingData?.isExport) {
        setshowXlDownload(true);
      } else {
        setshowXlDownload(false);
      }
    } else if (openTab === 2) {
      if (existData && fundingData?.isExport) {
        setshowXlDownload(true);
      } else {
        setshowXlDownload(false);
      }
    } else if (openTab === 3) {
      if (ipoData && fundingData?.isExport) {
        setshowXlDownload(true);
      } else {
        setshowXlDownload(false);
      }
    }
    else if (openTab === 4) {
      if (angelData && fundingData?.isExport) {
        setshowXlDownload(true);
      } else {
        setshowXlDownload(false);
      }
    } else if (openTab === 5) {
      if (incubationData && fundingData?.isExport) {
        setshowXlDownload(true);
      } else {
        setshowXlDownload(false);
      }
    }
    
  }, [openTab, pevcData, existData, incubationData, angelData])

  console.log("Checksee", pevcData, existData, incubationData, angelData);

  /* The above code is defining a constant variable called `fileName` using the `useMemo` hook in
  JavaScript. The `useMemo` hook is used to memoize the result of a function so that it is only
  recomputed when its dependencies change. */
  const fileName = useMemo(() => {

    let module = "CFS";

    let fileName = Constants.exportNames.funding_investment(companyName, module);


    if (openTab === 2) {
      fileName = Constants.exportNames.funding_exit(companyName, module);
    } else if (openTab === 3) {
      fileName = Constants.exportNames.funding_ipo(companyName, module);
    } else if (openTab === 4) {
      fileName = Constants.exportNames.funding_angel(companyName);
    } else if (openTab === 5) {
      fileName = Constants.exportNames.funding_incubation(companyName);
    }
    return fileName;
  }, [companyName, openTab])

  const allCountZero = fundingData?.pe_vc_investments?.length <=0 && fundingData?.pe_vc_exits?.length <=0 && fundingData?.pe_vc_ipo?.length <=0 && fundingData?.pe_vc_angel?.length <=0 && fundingData?.pe_vc_incubation?.length <=0

  return (
    <>
      <div className="mx-4 ">
        <div className="bg-white border shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] sm:rounded-lg">
          <div className=" pt-4 px-5 ">
            <div className="flex justify-between">
              <p className=" text-[14pt] leading-[19pt] text-[#333333] lg:text-[17px] font-semibold mb-3 lg:leading-[22px]">Funding</p>
             

              {isStudent=='false'&& showXlDownload && <ExcelDownloader fileName={fileName} data={xlDownloadRequest} url={exportExcel} />}
            </div>
          </div>
          <div className={`flex flex-row overflow-x-auto space-x-9 scrollbar px-5  ${allCountZero ?'':'border-t border-t-[#F1F1F1]'}`}>
          {fundingData?.pe_vc_investments?.length >0 &&
            <a
              className={
                "whitespace-nowrap   text-[12pt]  lg:text-[15px] lg:leading-[15px]   block leading-normal " +
                (openTab === 1
                  ? "text-[#2B2A29] py-[10px] border-b-[3px] border-[#F1CA75] font-semibold"
                  : " text-[#2B2A29] py-3  ")
              }
              onClick={e => {
                e.preventDefault();
                setOpenTab(1);
              }}
              data-toggle="tab"
              href="#link1"
              role="tablist"
            >
              PE-VC Investments
            </a>}
            {fundingData?.pe_vc_exits?.length >0 &&
            <a
              className={
                "whitespace-nowrap   text-[12pt]  lg:text-[15px] lg:leading-[15px]   block leading-normal " +
                (openTab === 2
                  ? "text-[#2B2A29] py-[10px] border-b-[3px] border-[#F1CA75] font-semibold"
                  : " text-[#2B2A29] py-3  ")
              }
              onClick={e => {
                e.preventDefault();
                setOpenTab(2);
              }}
              data-toggle="tab"
              href="#link2"
              role="tablist"
            >
              PE-VC Exits
            </a>
            }
            {fundingData?.pe_vc_ipo?.length >0 &&
            <a
              className={
                "whitespace-nowrap   text-[12pt]  lg:text-[15px] lg:leading-[15px]   block leading-normal " +
                (openTab === 3
                  ? "text-[#2B2A29] py-[10px] border-b-[3px] border-[#F1CA75] font-semibold"
                  : " text-[#2B2A29] py-3  ")
              }
              onClick={e => {
                e.preventDefault();
                setOpenTab(3);
              }}
              data-toggle="tab"
              href="#link3"
              role="tablist"
            >
              PE-VC Backed IPOs
            </a>
            }
            {fundingData?.pe_vc_angel?.length >0 &&
            <a
              className={
                "whitespace-nowrap   text-[12pt]  lg:text-[15px] lg:leading-[15px]   block leading-normal " +
                (openTab === 4
                  ? "text-[#2B2A29] py-[10px] border-b-[3px] border-[#F1CA75] font-semibold"
                  : "yellow-500 text-[#2B2A29] py-3  ")
              }
              onClick={e => {
                e.preventDefault();
                setOpenTab(4);
              }}
              data-toggle="tab"
              href="#link4"
              role="tablist"
            >
              Angel Investments
            </a>
            }
            {fundingData?.pe_vc_incubation?.length >0 &&
            <a
              className={
                "whitespace-nowrap  py-3 text-[12pt]  lg:text-[15px] lg:leading-[15px]   block leading-normal " +
                (openTab === 5
                  ? "text-[#2B2A29] py-[10px] border-b-[3px] border-[#F1CA75] font-semibold"
                  : "yellow-500 text-[#2B2A29] py-3  ")
              }
              onClick={e => {
                e.preventDefault();
                setOpenTab(5);
              }}
              data-toggle="tab"
              href="#link5"
              role="tablist"
            >
              Incubation
            </a>}

          </div >
          <div className={openTab === 1 ? "block" : "hidden"} id="link1">
              <FundingTableInvestment companyFundingId={fundingData?.company_id} menu={menu} data={fundingData?.pe_vc_investments} />
            </div>
            <div className={openTab === 2 ? "block" : "hidden"} id="link2">
              <FundingTableExit companyFundingId={fundingData?.company_id} menu={menu} data={fundingData?.pe_vc_exits} />
            </div>
            <div className={openTab === 3 ? "block" : "hidden"} id="link2">
              <FundingTableBackedIpo companyFundingId={fundingData?.company_id} menu={menu} data={fundingData?.pe_vc_ipo} />
            </div>
            <div className={openTab === 4 ? "block" : "hidden"} id="link3">
              <FundingTableAngelInvestment companyFundingId={fundingData?.company_id} menu={menu} data={fundingData?.pe_vc_angel} />
            </div>
            <div className={openTab === 5 ? "block" : "hidden"} id="link4">
              <FundingTableIncubation companyFundingId={fundingData?.company_id} menu={menu} data={fundingData?.pe_vc_incubation} />
            </div>

        </div>
      </div>
    </>
  )
}

export default Funding