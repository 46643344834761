import React, { useContext } from 'react'
import { useEffect } from 'react';
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import FilterWrapper from './FilterWrapper';

/**
 * The `SelectIndustryFilter` function is a React component that renders a dropdown menu for selecting
 * an industry filter option.
 * @returns a JSX element, which represents a filter component for selecting an industry.
 */
function SelectIndustryFilter({ open, accordionClick, IndustryOptions, setIndustryOptions, customPathname }) {
    const { Industries, refineSearchFilters } = useContext(PeFilterContext);

    /* The `useEffect` hook is used to perform side effects in a React functional component. In this
    case, the `useEffect` hook is used to update the `IndustryOptions` state based on the values of
    `Industries`, `customPathname`, `refineSearchFilters`, and `setIndustryOptions`. */
    useEffect(() => {
        const industryIds = refineSearchFilters[customPathname]?.industry?.map(value => value.id) ?? [];
        setIndustryOptions(Industries?.map(industry => ({ ...industry, isChecked: industryIds.includes(industry.id) })))
    }, [Industries, customPathname, refineSearchFilters, setIndustryOptions])


    /**
     * The function `handleInvestorTypeChange` updates the `isChecked` property of the
     * `IndustryOptions` array based on the selected value from the event target.
     * @param e - The parameter `e` is an event object that is passed to the `handleInvestorTypeChange`
     * function. It represents the event that triggered the function, such as a change event on an
     * input element.
     */
    const handleInvestorTypeChange = (e) => {
        const { value } = e.target;
        const currentIndustry = IndustryOptions.map((industry) =>
            ({ ...industry, isChecked: industry.id === parseInt(value) })
        );
        setIndustryOptions(currentIndustry);
    };

    const selectedIndustry = IndustryOptions?.filter(indutry => indutry.isChecked)?.at(0)

    let options = [<option key={"industry.id"}>All</option>]

    /* The code `IndustryOptions.forEach(industry => {
            options.push(<option key={industry.id} value={industry.id}>{industry.name}</option>)
        });` is iterating over each element in the `IndustryOptions` array and creating an
    `<option>` element for each industry. */
    IndustryOptions.forEach(industry => {
        options.push(<option key={industry.id} value={industry.id}>{industry.name}</option>)
    });



    return (
        <div className="border-b-[3px] border-[#E8E6E1]">
            <li
                className={
                    open
                        ? "bg-white relative px-3 py-1"
                        : "relative px-3 py-1"
                }
            >
                <FilterWrapper
                    isSelect={true}
                    label={selectedIndustry?.name ? "Industry - " + selectedIndustry?.name : "Industry"}
                    onClick={accordionClick}
                    open={open}
                />

                <div
                    className="relative overflow-hidden transition-all max-h-0 duration-700  overflow-y-auto no-scrollbar"
                    style={{ maxHeight: open ? "10rem" : "" }}
                >

                    <select name="" id="" value={selectedIndustry?.id ?? ''} onChange={handleInvestorTypeChange}
                        className="my-2 p-2 border border-[#D9BD7F] mr-3 rounded focus:outline-none bg-white w-full">
                        {IndustryOptions && IndustryOptions.length > 0
                            ? (options)
                            : (<h1>No results found!</h1>)
                        }
                    </select>
                </div>

            </li>

        </div>
    )
}

export default SelectIndustryFilter