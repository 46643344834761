import React, { useState } from 'react';



const GroupedTable = (props) => {
  console.log(props?.data, 'captable data1');
  const [expandedRows, setExpandedRows] = useState([]);

  // const groupedData = React.useMemo(() => {
  //   const groupedData = {};
  //   props?.data?.forEach(row => {
  //     const flag = row.Shareholders.flag;
  //     if (!groupedData[flag]) {
  //       groupedData[flag] = [];
  //     }
  //     groupedData[flag].push(row);
  //   });
  //   return groupedData;
  // }, [props?.data]);


  const groupedData = React.useMemo(() => {
    const groupedData = {};
    props?.data?.forEach(row => {
      const flag = row.Shareholders.flag;
      if (!groupedData[flag]) {
        groupedData[flag] = [];
      }
      if (row.Shareholders.flag === 'Fund') {
        groupedData['PE-VC Investors'].map((subRow, index) => {
          if (!groupedData['PE-VC Investors'][index]['sub']) {
            groupedData['PE-VC Investors'][index]['sub'] = [];
          }
          subRow?.Shareholders?.name == row.Shareholders.investor_name && groupedData['PE-VC Investors'][index]['sub'].push(row)
        });
      } else {
        groupedData[flag].push(row);
      }
    });
    // console.log(groupedData, 'pandi');
    return groupedData;
  }, [props?.data]);


/**
 * The function `toggleRowExpansion` toggles the expansion state of a row based on a flag.
 * @param flag - The flag parameter is a value that represents the row that needs to be expanded or
 * collapsed.
 */
  const toggleRowExpansion = (flag) => {
    if (expandedRows.includes(flag)) {
      setExpandedRows(expandedRows.filter(rowFlag => rowFlag !== flag));
    } else {
      setExpandedRows([...expandedRows, flag]);
    }
  };

  const formatter = new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 })
  const numFormatter = (number) => {
    return Math.round(number)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');      
  };

  /**
   * The `calculateGroupTotal` function calculates the total values for each key in a group of rows,
   * excluding rows with a flag of "Fund" or "Total".
   * @param groupRows - An array of objects representing group rows. Each object in the array contains
   * information about a specific group, including the shareholders and their respective values.
   * @returns The function `calculateGroupTotal` returns an object that represents the total values
   * calculated from the `groupRows` array.
   */
  const calculateGroupTotal = (groupRows) => {
    const total = {};
    groupRows.forEach(row => {
      Object.keys(row).forEach(key => {
        if (key !== 'Shareholders') {
          if (row.Shareholders.flag !== 'Fund' && row.Shareholders.flag !== "Total") {
            const value = row[key] !== null ? parseFloat(row[key]) : 0;
            if (!isNaN(value)) {
              total[key] = (total[key] || 0) + value;
            }
          }else if(row.Shareholders.flag == "Total"){
            const value = row[key] !== null ? parseFloat(row[key]) : 0;
            if (!isNaN(value)) {
              total[key] = value;
            }
          }
        }
      });
    });

    if (total['Stake Held'] !== undefined) {
      total['Stake Held'] = total['Stake Held'].toFixed(2);
    }
    if (total['Valuation as per the last transaction'] !== undefined) {
      total['Valuation as per the last transaction'] = total['Valuation as per the last transaction'].toFixed(2);
    }

    return total;
  };

  return (
    props?.data?.length === 0 ?
      <div className="h-[3rem] mx-auto lg:text-[15px] text-[15px] text-[#333333] relative mt-[1rem] text-center">
        No data found
      </div> :
      <div className='overflow-x-auto scrollbar'>
      <table className=" datatable w-full">
        <thead className="text-[12pt] leading-[15pt] bg-[#EDEDED] lg:text-[13px] lg:leading-[15px] text-[#666666] font-bold py-4 px-0 text-center ">
          <tr>
            <th className='py-3.5 px-5 whitespace-nowrap text-left w-[full]'>Shareholders</th>
            <th className='py-3.5 px-5 whitespace-nowrap text-right w-[10%]'>Equity</th>
            {Object.keys(props?.data[0])
              .filter(key => (key !== 'Shareholders' && key !== 'Equity'))
              .map(key => (
                <th key={key} className="py-3.5 px-5 whitespace-nowrap text-right w-[10%]">{key}</th>
              ))}
          </tr>
        </thead>
        <tbody className="text-[12pt] leading-[16pt] lg:text-[14px] text-center">
          {Object.entries(groupedData).map(([flag, rows]) => (
            <React.Fragment key={flag}>
              {flag !== "Fund" && (
                <>
                  <tr
                    onClick={() =>
                      (flag !== "ESOP" && flag !== "Total") &&
                      toggleRowExpansion(flag)
                    }
                    className="font-bold cursor-pointer border-b font-sans_semi_bold"
                  >
                    <td
                      width="20%"
                      className={`border-b text-[#666666] w-[10%] data-td-animate whitespace-nowrap px-5 py-4 text-left ${flag === "ESOP" || flag === "Total" ? "pl-[2rem]" : ""
                        }`}
                    >
                      {(flag !== "ESOP" && flag !== "Total") ? (
                        <>
                          {expandedRows.includes(flag) ? "-" : "+"} {flag}{" "} 
                        </>
                      ) : <>
                        {flag}{" "} 
                      </>}
                    </td>
                    <td className="border-b text-[#666666] w-[10%] data-td-animate whitespace-nowrap px-5 py-4 text-right">
                      {numFormatter(calculateGroupTotal(rows)["Equity"])}
                    </td>
                    {Object.keys(props?.data[0])
                      .filter((key) => key !== "Shareholders" && key !== "Equity")
                      .map((key) => (
                        <td
                          key={key}
                          className="border-b text-[#666666] w-[10%] data-td-animate whitespace-nowrap px-5 py-4 text-right"
                        >
                          {key === "Stake Held"
                            ? `${formatter.format(calculateGroupTotal(rows)[key])} %`
                            :  (key === "Valuation as per the last transaction" ? formatter.format(calculateGroupTotal(rows)[key])
                            : (calculateGroupTotal(rows)[key]
                              ? numFormatter(calculateGroupTotal(rows)[key])
                              : ""))}
                        </td>
                      ))}
                  </tr>
                  {expandedRows.includes(flag) &&
                    rows.map((row, index) => (
                      <React.Fragment key={index}>
                        {row.Shareholders.flag === "PE-VC Investors" && row?.sub?.length > 0 ? (
                          <>
                            <tr
                              onClick={() => toggleRowExpansion(index)}
                              className="cursor-pointer"
                              key={index}
                              style={{ display: "table-row" }}
                            >
                              <td className="border-b text-[#666666] w-[10%] data-td-animate whitespace-nowrap px-5 py-4 text-left pl-[2rem]">
                              {row.Shareholders.name} {" "} {" "}
                                {expandedRows.includes(index) ? (
                                  <button onClick={() => toggleRowExpansion(index)}> - </button>
                                ) : (
                                  <button onClick={() => toggleRowExpansion(index)}> + </button>
                                )}
                                
                              </td>
                              <td className="border-b text-[#666666] w-[10%] data-td-animate whitespace-nowrap px-5 py-4 text-right">
                                {numFormatter(row.Equity) == 0 ? "" :numFormatter(row.Equity)}
                              </td>
                              {Object.keys(row)
                                .filter(
                                  (key) => key !== "Shareholders" && key !== "Equity" && key !== "sub"
                                )
                                .map((key) => (
                                  <td
                                    key={key}
                                    className="border-b text-[#666666] w-[10%] data-td-animate whitespace-nowrap px-5 py-4 text-right"
                                  >
                                    {key === "Stake Held"
                                      ? (formatter.format(row[key]) == 0 ? "" : formatter.format(row[key])+ " %")
                                      :  (key === "Valuation as per the last transaction" ? (formatter.format(row[key]) == 0 ? "" : formatter.format(row[key]))
                                      : (numFormatter(row[key]) == 0 ? "" : numFormatter(row[key])))}
                                  </td>
                                ))}
                            </tr>
                            {expandedRows.includes(index) &&
                              row.sub.map((sub, index1) => (
                                <tr
                                  className=""
                                  key={index1}
                                  style={{ display: "table-row" }}
                                >
                                  <td className="border-b text-[#666666] w-[10%] data-td-animate whitespace-nowrap px-5 py-4 text-left pl-[4rem]">
                                    {sub.Shareholders.name}
                                  </td>
                                  <td className="border-b text-[#666666] w-[10%] data-td-animate whitespace-nowrap px-5 py-4 text-right">
                                    {numFormatter(sub.Equity) == 0 ? "" : numFormatter(sub.Equity)}
                                  </td>
                                  {Object.keys(sub)
                                    .filter(
                                      (key) =>
                                        key !== "Shareholders" && key !== "Equity" && key !== "sub"
                                    )
                                    .map((key) => (
                                      <td
                                        key={key}
                                        className="border-b text-[#666666] w-[10%] data-td-animate whitespace-nowrap px-5 py-4 text-right"
                                      >
                                        {key === "Stake Held"
                                          ? (formatter.format(sub[key])==0 ? "" : formatter.format(sub[key])+" %")
                                          :  (key === "Valuation as per the last transaction" ? (formatter.format(sub[key])==0 ? "" : formatter.format(sub[key]))
                                          : (numFormatter(sub[key])==0 ? "" : numFormatter(sub[key])))}
                                      </td>
                                    ))}
                                </tr>
                              ))}
                          </>
                        ) : (
                          <tr
                            className=""
                            key={index}
                            style={{ display: "table-row" }}
                          >
                            <td className="border-b text-[#666666] w-[10%] data-td-animate whitespace-nowrap px-5 py-4 text-left pl-[2rem]">
                              {row.Shareholders.name}
                            </td>
                            <td className="border-b text-[#666666] w-[10%] data-td-animate whitespace-nowrap px-5 py-4 text-right">
                              {numFormatter(row.Equity) == 0 ? "" : numFormatter(row.Equity)}
                            </td>
                            {Object.keys(row)
                              .filter(
                                (key) => key !== "Shareholders" && key !== "Equity" && key !== "sub"
                              )
                              .map((key) => (
                                <td
                                  key={key}
                                  className="border-b text-[#666666] w-[10%] data-td-animate whitespace-nowrap px-5 py-4 text-right"
                                >
                                  {key === "Stake Held"
                                    ? (formatter.format(row[key]) == 0 ? "" : formatter.format(row[key])+' %')
                                    :  (key === "Valuation as per the last transaction" ? (formatter.format(row[key]) == 0 ? "" : formatter.format(row[key]))
                                    : (numFormatter(row[key]) == 0 ? "" : numFormatter(row[key])))}
                                </td>
                              ))}
                          </tr>
                        )}

                      </React.Fragment>
                    ))}
                </>
              )}
            </React.Fragment>
          ))}
        </tbody>




      </table>
      </div>
  );
};

export default GroupedTable;
