import React from 'react';

/**
 * The StartWithTextComponent is a React component that renders an input field and updates the value of
 * a selected child in an array based on the entered value from the input field.
 * @returns The component is returning an `<input>` element with various props and attributes.
 */
const StartWithTextComponent = ({ child, selectedChild, setSelectedChild }) => {

  
  const childIndex = child.uniqueId;

  console.log(child.values,"child value");
/**
 * The handleChange function updates the value of a selected child in an array based on the entered
 * value from an event.
 * @param event - The event parameter is an object that represents the event that triggered the
 * handleChange function. It contains information about the event, such as the target element that
 * triggered the event and the value of the target element.
 */
  const handleChange = (event) => {
    const enteredValue = event.target.value;
    const childIndexx = selectedChild.findIndex((selectedChild, index) => selectedChild.uniqueId === childIndex);
    const updatedChild = { ...selectedChild[childIndexx] };
    updatedChild.values = [enteredValue];
    const updatedSelectedChild = [...selectedChild];
    updatedSelectedChild[childIndexx] = updatedChild;
    console.log(updatedSelectedChild,"updatedSelectedChild");
    setSelectedChild(updatedSelectedChild);
  };

  return (
    <input
      key={childIndex}
      type="text"
      name={child.id}
      onChange={handleChange}
      value={child.values[0] || ''}
      placeholder="Search..."
      className="p-2 border border-[#D9BD7F] rounded focus:outline-none bg-[#FFFFFF] w-[12rem]"
    />
  );
};

export default StartWithTextComponent;
