import React, { useState, useEffect, useMemo, useCallback, useContext } from 'react'
import image1 from '../../../src/images/Sixdot/Group15.png'
import detailViewIcon from '../../../src/images/detailview.png'
import '../Dashboard/dashboarcd.css'
import axios from 'axios';
import { savedFilterListApi } from '../../Config/config';
import { makeViFilterApi,savedFilterDetailApi } from '../../Config/config';
import Constants from '../../Constants';
import closeIcon from '../../images/search_close_button.png'
import { moduleValues, primaryMasterValues, categories, primaryMasterSubValues } from '../../Constants/values';
import { useFilters, usePagination, useTable } from 'react-table';
import DashboardPagination from '../shared/DashboardPagination';
import OverflowToolTip from '../shared/OverflowToolTip';
import { GrView } from 'react-icons/gr'
import { Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';
import { getRoutePathById } from '../../routeSegments';
// import { PeFilterContext } from '../../Contexts/PeFilterContext';
import Loader from '../Loader';
import NoDataPlaceholder from '../shared/NoDataPlaceholder';
import { UserContext } from '../../Contexts/UserContext';
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

/**
 * The function `getSectionLabelById` returns the label of a section based on its ID.
 * @returns The function `getSectionLabelById` returns the label of a section based on its ID. If the
 * ID is null, it returns null. If the ID matches a value in the `primaryMasterSubValues` array, it
 * returns the corresponding title. If the ID matches a value in the `Constants.primaryMasterSubIds`
 * object, it constructs a label based on the ID and returns it.
 */
const getSectionLabelById = (id) => {
    if(id == null){
        return null
    }
    const primaryMasterSub = primaryMasterSubValues.find(option=>parseInt(option.value) === parseInt(id))
    if(primaryMasterSub){
        return primaryMasterSub.title;
    }

    const key = Object.keys(Constants.primaryMasterSubIds)
        .find(subIdKey => Constants.primaryMasterSubIds[subIdKey] === id) ?? ""

    const label = key.split("_").filter(value => value !== "id")
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');

    return label;
}


/* The above code is a React component called `FilterListDataTable`. It is responsible for displaying a
table of saved filter lists. */
function FilterListDataTable() {
    const [savedFilterList, setSavedFilterList] = useState([]);
    const [savedFilterDetail, setSavedFilterDetail] = useState([]);
    const [detailData, setDetailData] = useState([]);
    const [detailId, setDetailId] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    // const { applyFilterFrom } = useContext(PeFilterContext);
    const [selectedPrimaryModule, setselectedPrimaryModule] = useState('All');
    const [adminSelectedCategory, setAdminSelectedCategory] = useState('unApproved');
    const {getToken, getUser} = useContext(UserContext);
    const [isPaneOpen , setIsPaneOpen] = useState(false)

    const navigate = useNavigate();

   /**
    * The function `getSavedList` makes a POST request to an API endpoint with user and filter
    * information, and then sets the retrieved filter list in the state.
    */
    const getSavedList = () => {
        setIsLoading(true);
        axios.post(savedFilterListApi, {
            "user_id": getUser().id,
            "primary_type_master_id": selectedPrimaryModule,
            "admin_filter_type": adminSelectedCategory,
        },{
            headers:{
                "Authorization": getToken()
            }
        }).then(response => {
            setIsLoading(false);
            if (response.status === 200) {
                console.log(response,'filter list response');
                setSavedFilterList(response.data?.listviewfilter ?? [])
            }
        })
    }

  /**
   * The function `getDetailList` makes a POST request to an API endpoint with user authentication
   * headers, retrieves data, and updates state variables based on the response.
   */
    const getDetailList = (id) => {
        setIsLoading(true);
        axios.post(savedFilterDetailApi, {
            "user_id": getUser().id,
            "id": id ? [id] : [detailId],
        },{
            headers:{
                "Authorization": getToken()
            }
        }).then(response => {
            setIsLoading(false);
            if (response.status === 200) {
                setSavedFilterDetail(response.data ?? [])
                setDetailData(response.data.listviewfilter[0])
            }
        })
    }

    useEffect(() => {
        getSavedList();
    }, [selectedPrimaryModule, adminSelectedCategory])

    useEffect(() => {
        getDetailList();
    }, [detailId])

   /* The above code is defining a function called `onViewClick` in a JavaScript React component. This
   function is using the `useCallback` hook to memoize the function and its dependencies. */
    const onViewClick = useCallback(async (id) => {
        const clickedFilter = savedFilterList.find(filter => filter.id == id);
        console.log("clickedFilterclickedFilter", id, clickedFilter);
        const moduleId = parseInt(clickedFilter.module_id);
        const primaryId = parseInt(clickedFilter.primary_type_master_id);
        const subPrimaryId = parseInt(clickedFilter.sub_type_master_id);

        const filter = JSON.parse(clickedFilter?.request_json)??{}
        navigate(getRoutePathById({ moduleId, primaryId, subPrimaryId }),{state:{filterData:filter?.filterData,monthRange:[filter?.date_range?.from_date,filter?.date_range?.to_date]}})
        // applyFilterFrom(JSON.parse(clickedFilter.request_json));

    }, [savedFilterList, navigate])

    /* The above code is defining a function called `onDetailViewClick` using the `useCallback` hook in
    React. This function takes an `id` parameter. */
    const onDetailViewClick = useCallback(async (id) => {  
        setIsPaneOpen(true)      
        setDetailId(id);
    }, [])
    

    /* The above code is a JavaScript React function that is used to make a filter click action. */
    const makeViFilterClick = useCallback(async (id,action) => {  
        setIsLoading(true);
        axios.post(makeViFilterApi, {
            "user_id": getUser().id,
            "id": [id],
            "action": action ? "remove" : "add",
        },{
            headers:{
                "Authorization": getToken()
            }
        }).then(response => {
            setIsLoading(false);
            if (response.status === 200) {
                getDetailList(id);
                getSavedList();
                // setSavedFilterDetail(response.data ?? [])
                // setDetailData(response.data.listviewfilter[0])
            }
        })
    }, [selectedPrimaryModule, adminSelectedCategory])

   /* The above code is defining an array of columns for a table in a React component. Each column has
   a Header (displayed as the column title), an accessor (used to access the data for that column),
   and a Cell component (used to render the data in that column). */
    const columns = useMemo(() => [
        {
            Header: "Module",
            accessor: "module",            
            className: "text-center ",
            Cell: ({ value }) => {
                return primaryMasterValues.map(data =>{
                    return data.value == value ? data.title :''})
            },
        },
        {
            Header: "Type",
            accessor: "type",            
            className: "text-center ",
        },
        {
            Header: "Sub Type",
            accessor: "subType",
            className: "text-center ",
            Cell:({value})=>{
                if(value) return value
                else return "N/A"
            }
        },
        {
            Header: "Refine Search Name",
            accessor: "searchName",
            className:"flex justify-center",
            Cell: ({ value }) => {
                return <OverflowToolTip title="Refine Search Name" className="max-w-[18rem]"
                    component={value} />
            }
        },
        {
            Header: "Created Date",
            accessor: "createdDate",
        },
       
        {
            Header: "Action",
            accessor: "actions",
            className: "flex justify-center",
            Cell: ({ value }) => {
                return <div className='flex space-x-8'>
                    <Tooltip className='text-center' title="Apply Filter">
                    <GrView onClick={(e) => {
                        onViewClick(value)
                    }} className='cursor-pointer ' />
                </Tooltip>
                <Tooltip className='text-center' title="View Detail">
                   <img src={detailViewIcon} className='w-4 h-4 cursor-pointer' onClick={(e) => {
                        onDetailViewClick(value)
                    }}/>
                </Tooltip>
                </div>
            }
        },
    ], [onViewClick])

   /* The above code is using the `useMemo` hook in React to create a memoized version of the `data`
   variable. */
    const data = useMemo(() => {
        let rows = [];

        savedFilterList?.forEach(savedFilter => {

            const filters = savedFilter?.request_json;
            let keys = new Set();
            filters && (
            Object.keys(filters)?.forEach(key => {

                if (Array.isArray(filters[key])) {
                    if (filters[key].length > 0) {
                        keys.add(key.toUpperCase().replace('_', " "))
                    }
                } else if (typeof filters[key] === "object") {
                    keys.add(key.toUpperCase().replace('_', " "))
                }
            }))



            rows.push({
                module: savedFilter.primary_type_master_id,
                type: moduleValues.find(data => data.value == savedFilter.module_id).title,
                subType: getSectionLabelById(savedFilter.sub_type_master_id),
                searchName: savedFilter.name,
                appliedFilter: Array.from(keys),
                createdDate: savedFilter.created_at,
                actions: savedFilter.id
            })
        })
        return rows;
    }, [savedFilterList])

    const tableInstance = useTable({
        columns,
        data,
        initialState: { pageSize: 10 }
    },
        useFilters,
        usePagination
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        // pageCount,
        gotoPage,
        // nextPage,
        // previousPage,
        setFilter,
        state: { pageIndex, pageSize },
    } = tableInstance


    /**
     * The function "onPaginationChange" is used to handle pagination changes in a React component.
     */
    const onPaginationChange = (page, pageSize) => {
        gotoPage(page - 1)
    }

   /**
    * The function `onModuleChange` updates the value of `selectedPrimaryModule` based on the value of
    * the target element.
    */
    const onModuleChange = (e) => {
        setselectedPrimaryModule(e.target.value);
    }


   /**
    * The function `onAdminCategoryChange` updates the selected category in the admin panel based on
    * the value of the target element.
    */
    const onAdminCategoryChange = (e) => {
        setAdminSelectedCategory(e.target.value);
    }

    useEffect(() => {
        setFilter('module', selectedPrimaryModule === 'All' ? 'All' : selectedPrimaryModule)
    }, [selectedPrimaryModule])


    // console.log("tableInstance987", tableInstance,page.length > 0);


    return (
        <div>
            <div className="">
                <div
                    className="block border border-[#CECECE] rounded-[4px] m-2 p-2 bg-[#EBEBEB] col-span-6 md:col-span-4 lg:col-span-4 ">
                    <div className="sm:flex flex justify-between items-center px-2 pb-2 border-b-[#CCCCCC] border-b border-x-1 ">
                        <div className="flex space-x-3 items-center">
                            <span className="items-center">
                                <img src={image1} alt="" className="w-[12px] h-[18px]" />
                            </span>

                            <p className="text-[#333333] text-[14px] leading-[17px] font-sans_medium_body ">Your Saved List</p>

                            <select onChange={onModuleChange}
                                value={selectedPrimaryModule}
                                className=" py-0.5 text-[13px] font-sans_book_body text-[#645326] leading-[16px] rounded-[10.5px] block px-2.5 bg-[#FFFFFF] border border-[#BC8B20]  focus:outline-none ">
                                {
                                    primaryMasterValues.map(primaryValue => (<option value={primaryValue.value}>
                                        {primaryValue.title}
                                    </option>))
                                }                               
                            </select>
                        </div>
                        <div className="flex space-x-3 items-center">
                            <p className="text-[#333333] text-[14px] leading-[17px] font-sans_medium_body ">Categories</p>

                            <select onChange={onAdminCategoryChange}
                                    value={adminSelectedCategory}
                                    className=" py-0.5 text-[13px] font-sans_book_body text-[#645326] leading-[16px] rounded-[10.5px] block px-2.5 bg-[#FFFFFF] border border-[#BC8B20]  focus:outline-none ">
                                    {
                                        categories.map(primaryValue => (<option value={primaryValue.value}>
                                            {primaryValue.title}
                                        </option>))
                                    }                                
                            </select> 
                        </div>                    
                    </div>
                    {/* <!-- table --> */}
                    <div>
                        <div className=" overflow-x-auto scrollbar">
                            <table className="w-full p-1 bg-[#EBEBEB] rounded-[4px] border-separate text-center border-spacing-y-1 " {...getTableProps()}>
                                <thead className="bg-[#EBEBEB] font-sans_medium_body text-[13px] leading-[16px]  text-[#666666]">
                                    {
                                        headerGroups.map(headerGroup => (

                                            <tr {...headerGroup.getHeaderGroupProps()}>
                                                {
                                                    headerGroup.headers.map(column => (

                                                        <th {...column.getHeaderProps([
                                                            { className: `whitespace-nowrap px-5 py-2 ${column.headerClassName}` }
                                                        ])}>
                                                            {
                                                                column.render('Header')}
                                                        </th>
                                                    ))}
                                            </tr>
                                        ))}
                                </thead>
                                {!isLoading && savedFilterList.length > 0 && page.length > 0 ? <tbody className="text-[15px] font-sans_book_body text-[#333333] leading-[16px] text-center " {...getTableBodyProps()}>
                                    {
                                        page.map(row => {
                                            prepareRow(row)
                                            return (
                                                <tr className="text rounded-[4px] bg-[#FFFFFF] px-2  " {...row.getRowProps()}>
                                                    {
                                                        row.cells.map(cell => {
                                                            return (
                                                                <td
                                                                    {...cell.getCellProps([
                                                                        {
                                                                            className: `whitespace-nowrap px-5 py-2 rounded text-left ${cell?.column.className}`,
                                                                        }])}
                                                                >
                                                                    {
                                                                        cell.render('Cell')
                                                                    }
                                                                </td>
                                                            )
                                                        })}
                                                </tr>
                                            )
                                        })}
                                </tbody> : <></>}
                            </table>
                            {
                                isLoading
                                    ? <div className='bg-white w-full flex items-center justify-center h-40'>
                                        <Loader />
                                    </div>
                                    : savedFilterList.length < 1 || page.length < 1
                                        ? <div className='bg-white w-full flex items-center justify-center h-40'>
                                            <NoDataPlaceholder />
                                        </div>
                                        : <></>
                            }

                        </div>
                    </div>
                    <div>
                        <div className='text-center'>
                            <DashboardPagination
                                current={pageIndex + 1}
                                total={rows.length}
                                pageSize={pageSize}
                                onChange={onPaginationChange}
                            />
                        </div>
                    </div>
                    {isPaneOpen && 
                    <SlidingPane
                        className="supplier-class mach-supplier"
                        overlayClassName="supplier-overlay-class z-[101]"
                        isOpen={isPaneOpen}
                        onRequestClose={() => {
                            setIsPaneOpen(false);
                        }}
                        shouldCloseOnEsc
                        width="30%"
                        hideHeader
                    >
                        <div className="bg-[#BC8B20] flex h-12 items-center justify-start px-4 w-full fixed left-0 top-0"><p className='w-full px-5 md:px-0 py-1 text-lg font-medium font-sans_book_body text-[18px] leading-6 text-[#FFFFFF]'>Detailed view</p>
                            <button
                                type="button"
                                className="rounded-md text-white hover:text-gray-500 focus:outline-none  "
                                onClick={() => setIsPaneOpen(false)}
                            >
                                <span className="sr-only">Close</span>
                                <img src={closeIcon} className="h-8 w-8 " /> 
                            </button>
                        </div>

                    {detailData &&
                        <>
                        <div className="border-2 rounded-md p-3 mt-10">
                            <div className="grid grid-cols-2 gap-2 py-2">
                                <p className="text-[#666666]">Module</p>
                                <p className="">{primaryMasterValues.map(data =>{ return data.value == detailData?.module_id ? data.title :''})}</p>
                            </div>
                            <div className="grid grid-cols-2 gap-2 py-2">
                                <p className="text-[#666666]">Type</p>
                                <p className="">{moduleValues.find(data => data.value == detailData.module_id).title}</p>
                            </div>
                            <div className="grid grid-cols-2 gap-2 py-2">
                                <p className="text-[#666666]">Sub Type</p>
                                <p className="">{getSectionLabelById(detailData.sub_type_master_id)}</p>
                            </div>
                            <div className="grid grid-cols-2 gap-2 py-2">
                                <p className="text-[#666666]">Refine Search Name</p>
                                <p className="">{ detailData?.name ?? 'NA'}</p>
                            </div>
                            <div className="grid grid-cols-2 gap-2 py-2">
                                <p className="text-[#666666]">Created Date</p>
                                <p className="">{ detailData?.created_at ?? 'NA'}</p>
                            </div>
                        </div>

                        <button onClick={(e) => {makeViFilterClick(detailData?.id,savedFilterDetail?.is_vi_filter)}} className='my-3 flex items-center gap-2 font-sans_meduim_body ml-auto bg-[#BC8B20] px-2 py-1 text-base text-white shadow-sm hover:bg-[#a37517] rounded-md'>
                            {savedFilterDetail?.is_vi_filter ? "Un Approve" : "Approve"}
                        </button>  
                        </>
                    }
                    </SlidingPane>
                    }
                </div>
            </div>
        </div>
    )
}

export default FilterListDataTable