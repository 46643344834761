import React, { useState, useEffect, useContext } from "react"
import { Link, NavLink, useNavigate } from 'react-router-dom';
import MonthPickerComponent from '../../Utils/MonthPickerComponent'
import { TableDownloadContext } from '../../Contexts/TableDownloadContext'
import moment from 'moment';
import line from "../../images/line.png"
import xls from "../../images/xls/png/Group 47.png"
import FileIcon from "../../images/file.png"
import FileIconBlur from "../../images/fileblur.png"
import ExcelPopup from "../ExcelPopup";
import '../MainMenu.css'
import { routeNames } from "../../routeSegments";

import SavedFilterContent from "../../Components/SavedFilter";
import SavedFilterICON from "../../Components/SavedFilter/SavedFilterICON";
import ExportPopup from "../../Components/ExportPopup";
import { vctransactionAdvisorProfileExportData ,vctransactionAdvisorexitExportData } from "../../Components/ExportPopup/ExportData";
import { exportAdvisorExcel } from "../../Config/config";
import { TableDataContext } from "../../Contexts/TableDataContext";
import { PeFilterContext } from "../../Contexts/PeFilterContext";
import Constants from "../../Constants";
import { UserContext } from "../../Contexts/UserContext";
import { useLocation } from 'react-router-dom'
import { FaFilter } from "react-icons/fa";
import { IoClose  } from "react-icons/io5";
/**
 * The MainMenu function is a React component that renders a menu with different options based on the
 * props passed to it.
 * @param props - The "props" parameter is an object that contains the properties passed to the
 * MainMenu component. These properties can be accessed using dot notation, such as props.menu,
 * props.subMenu, props.active, props.exportFileName, props.primaryTypeMasterId, props.subTypeMasterId,
 * and props.typeId.
 * @returns The MainMenu component is returning a JSX element.
 */
function MainMenu(props) {
  // console.log(props.subMenu, "menuprops1");

  const titleMap = {
    "tadvisor": {
      "vc": "VC Transaction Advisors VC Investments - Venture Intelligence",
      "exist-ma-vc": "VC Transaction Advisors VC Exits - Venture Intelligence",
      "exist-ma-ma": "VC Transaction Advisors Via M&A (VC) - Venture Intelligence",
      "exist-pm-pm": "VC Transaction Advisors Via Public Market (VC) - Venture Intelligence"
    }
  };
  
  const defaultTitle = "VC Transaction Advisors VC Exits - Venture Intelligence";
  
  document.title = titleMap[props.menu]?.[props.subMenu] || defaultTitle;
  

  // const [save,setSave]=useState(false)
  const {save, setSave,showMonthPicker ,refineSearchFilters,groupId, isMobile,setOpen,open,setIsMobile} = useContext(PeFilterContext);
  const { handleDownloadExcel } = useContext(TableDownloadContext)
  const [openTab, setOpenTab] = React.useState(1);
  const [showPopup, setShowPopup] = useState(false);
  const {activeTabDeal,setActiveTabDeal,setCurrentPage, globalSearchResult} =useContext(TableDataContext);
  const { isStudent,isTrialExport} = useContext(UserContext)


  const [date, setDate] = useState([
    moment().startOf("month").subtract(12, "months").format("YYYY-MM-DD"),
    moment().endOf("month").format("YYYY-MM-DD"),
  ]);

     /**
      * The function `savedList` toggles the value of the `save` variable.
      */
      const savedList = () => {
    setSave(!save)
  }


  /* The above code is using the useEffect hook in React to set the value of the "openTab" state
  variable to the value of the "active" prop whenever the "openTab" state variable changes. */
  useEffect(() => {
    setOpenTab(props.active)
  }, [openTab])

  useEffect(()=>{
    if (window.innerWidth < 800) {
        setOpen(true);
        setIsMobile(true);
    }    },[])
  /**
   * The function `download` calls the `handleDownloadExcel` function.
   */
  const download = () => {
    handleDownloadExcel()
  }

  const { pathname } = useLocation(); 
  const isFromGlobalSearch = ()=> refineSearchFilters[pathname]?.globalSearch?.length > 0 ? true : false

  const globalSearchCategory =
    props.type === "vc_investment"
      ? "investments_ids"
      : props.type === "vc_exit"
      ? "exits_ids"
      : "";
  
  return (
    <div className="pt-0 pl-3 pr-4">

      <div className="scrollbar  rounded">
        <div className="px-2  md:px-3 rounded-[6px] rounded-b-none bg-white block lg:flex flex-row justify-between shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
          {
            props.menu == "tadvisor" ? (
              <div className="flex flex-row  space-x-5 ">
                <div className="py-4 px-2 font-[Squeal Sans]">
                  <NavLink className={"whitespace-nowrap text-[12pt] leading-[17px] lg:text-[14px] lg:leading-[17pt]" + (openTab === 1 ? " font-bold border-b-[3px] border-[#F1CA75] py-4 pt-[1.1rem]"
                    : "whitespace-nowrap font-sans_medium_body text-[#2B2A29] opacity-[.8] hover:opacity-100")
                  }
                    data-toggle="tab"
                    to={props.subMenu == "vc" ? routeNames.vc_transaction_advisors_vc_advisors : routeNames.vc_transaction_advisors_vc_advisors}
                    role="tablist"
                  >
                    <div className="group inline-block">
                      <button onClick={()=>setCurrentPage(1)} 
                        className="outline-none focus:outline-none py-1 bg-white rounded-sm flex items-center "
                      >
                        <span className="  flex lg:text-[14px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                          {props.subMenu == "vc" ? "VC Investments" : "VC Investments"}
                        </span>

                      </button>
                    </div>
                  </NavLink>
                </div>
                <div className="py-4 pl-2 lg:pl-0">
                  <NavLink
                    className={
                      "whitespace-nowrap lg:text-[14px] lg:leading-[17px] text-[12pt] leading-[17pt]" +
                      (openTab === 2
                        ? " font-bold border-b-[3px] border-[#F1CA75] py-4 pt-[1.1rem]"
                        : "whitespace-nowrap font-sans_medium_body text-[#2B2A29] opacity-[.8] hover:opacity-100 ")
                    }
                    to={
                      props.subMenu == "exist-ma-ma"
                        ? routeNames.vc_transaction_advisors_ma_vc_advisors
                        // : props.subMenu == "exist-ma-ma"
                        // ? "/transaction-advisor/ma/pe-vc-advisors"
                        : props.subMenu == "exist-pm-pm"
                          ? routeNames.vc_transaction_advisors_pm_vc_advisors
                          : routeNames.vc_transaction_advisors_ma_vc_exits
                    }
                    data-toggle="tab"
                    role="tablist"
                  >

                    {isFromGlobalSearch() ? 
                     <div className="group inline-block">
                     {/* //mywork// */}
                     <button onClick={()=>setCurrentPage(1)}  className="outline-none w-30  justify-between focus:outline-none py-1  bg-white rounded-sm flex items-center ">
                       <span className=" flex text-[14px] leading-[15px]">
                         {
                           props.subMenu == "exist-ma-ma"
                             ? "Via M&A (VC)"
                             : props.subMenu == "exist-pm-pm"
                               ? "Via Public Market (VC)"
                               : "VC Exits "}
                       </span>

                      
                     </button>
                
                   </div>
                   :
                   <div className="group inline-block">
                   {/* //mywork// */}
                   <button onClick={()=>setCurrentPage(1)}  className="outline-none w-30  justify-between focus:outline-none py-1  bg-white rounded-sm flex items-center ">
                     <span className=" flex text-[14px] leading-[15px]">
                       {
                         props.subMenu == "exist-ma-ma"
                           ? "Via M&A (VC)"
                           : props.subMenu == "exist-pm-pm"
                             ? "Via Public Market (VC)"
                             : "VC Exits "}
                     </span>

                     <span>
                       <svg
                         className="fill-current h-4 w-4 transform group-hover:-rotate-180
                                         transition duration-150 ease-in-out"
                         xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 20 20"
                       >
                         <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                       </svg>
                     </span>
                   </button>
                   <ul
                     className="mt-1 bg-white rounded-sm transform scale-0 group-hover:scale-100 z-10 absolute 
                                 transition duration-150 ease-in-out origin-top min-w-[11rem]"
                   >
                     {
                       props.subMenu == "exist-ma-ma" ? (


                         <ul
                           className=" bg-white  py-1 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 absolute top-0 right-0  transition duration-150 ease-in-out origin-top-left min-w-32"
                         >
                           <li className="rounded-sm relative font-sans_book_body  px-6 py-2 hover:bg-gray-100  ">
                             <NavLink
                               to={routeNames.vc_transaction_advisors_ma_vc_exits}
                               className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                             >
                               <span onClick={()=>setCurrentPage(1)}  className=" flex-1 text-[#333333] text-[13px] leading-[15px]">
                                 VC Exits
                               </span>

                             </NavLink>
                           </li>
                           
                           <li className="rounded-sm relative font-sans_book_body  px-6 py-2 hover:bg-gray-100  ">
                             <NavLink
                               to={routeNames.vc_transaction_advisors_pm_vc_advisors}
                               className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                             >
                               <span onClick={()=>setCurrentPage(1)}  className="pr-1 flex-1 text-[#333333]  text-[13px] leading-[15px]">
                                 Via Public Market (VC)
                               </span>

                             </NavLink>

                           </li>
                         </ul>

                       )
                         : props.subMenu == "exist-pm-pm" ? (


                           <ul
                             className=" bg-white  py-1 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 absolute top-0 right-0  transition duration-150 ease-in-out origin-top-left min-w-32"
                           >
                             <li className="rounded-sm relative font-sans_book_body  px-6 py-2 hover:bg-gray-100  ">
                               <NavLink
                                 to={routeNames.vc_transaction_advisors_ma_vc_exits}
                                 className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                               >
                                 <span onClick={()=>setCurrentPage(1)}  className=" flex-1 text-[#333333]  text-[13px] leading-[15px]">
                                   VC Exits
                                 </span>

                               </NavLink>
                             </li>
                             <li className="rounded-sm relative font-sans_book_body  px-6 py-2 hover:bg-gray-100  ">
                               <NavLink
                                 to={routeNames.vc_transaction_advisors_ma_vc_advisors}
                                 className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                               >
                                 <span onClick={()=>setCurrentPage(1)}  className="pr-1 flex-1 text-[#333333]  text-[13px] leading-[15px]">
                                   Via M&A (VC)
                                 </span>

                               </NavLink>

                             </li>
                           
                           </ul>

                         ) :
                           (
                             <ul
                               className=" bg-white  py-1 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 absolute top-0 right-0  transition duration-150 ease-in-out origin-top-left min-w-32"
                             >
                               <li className="rounded-sm relative font-sans_book_body  px-6 py-2 hover:bg-gray-100  ">
                                 <NavLink
                                   to={routeNames.vc_transaction_advisors_ma_vc_advisors}
                                   className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                 >
                                   <span onClick={()=>setCurrentPage(1)}  className="pr-1 flex-1 text-[#333333]  text-[13px] leading-[15px]">
                                     Via M&A (VC)
                                   </span>

                                 </NavLink>

                               </li>
                               <li className="rounded-sm relative font-sans_book_body  px-6 py-2 hover:bg-gray-100  ">
                                 <NavLink
                                   to={routeNames.vc_transaction_advisors_pm_vc_advisors}
                                   className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                 >
                                   <span onClick={()=>setCurrentPage(1)}  className="pr-1 flex-1 text-[#333333]  text-[13px] leading-[15px]">
                                     Via Public Market (VC)                               </span>

                                 </NavLink>

                               </li>
                             </ul>
                           )}
                   </ul>
                 </div>
                    
                  }
                   
                  </NavLink>
                </div>

              </div>
            )
              : ""

          }
          <div className={"justify-between items-center px-2 py-2 space-x-4 "+(showMonthPicker!=0 ? 'md:flex':'flex')}>
              {!isFromGlobalSearch() && <label htmlFor="" className="text-[#666666]">
              <div className={showMonthPicker!=0 ?"":'flex'}>
                  <MonthPickerComponent />
                </div>
              </label>}
              <div className={showMonthPicker!=0 ?"hidden md:flex space-x-3":"flex space-x-3"}>  
              {isMobile && (
                  <>
                    <button
                      className="cursor-pointer lg:ml-7 ml-4 mt-2"
                      onClick={() => setOpen(!open)}
                    >
                      <FaFilter color="#bc8b20" size={21} />
                      {/* {!open && <IoClose color="#bc8b20" size={25} className="fixed z-50 top-16 right-[39vw] mb-2" />} */}
                    </button>
                    <a>
                      <img
                        className="h-[24px] max-w-[2px] mt-1 "
                        src={line}
                      />
                    </a>
                  </>
                )}
              {isTrialExport =='false' || isStudent == 'true' ? <></>  : <>
                  <button >
                  <img src={xls} className="h-[24px] mt-1 w-[21px]" alt=""
                      onClick={() => setShowPopup(!showPopup)}
                    />
                    {showPopup && (
                      <div>
                        <ExportPopup onClose={() => setShowPopup(false)}
                          module="transaction-advisor"
                          modules="advisor"  
                          module_type={props.primaryTypeMasterId===Constants.primaryMasterIds.vcId?"vc":"pe"}
                          fileName={props.exportFileName}
                          url={exportAdvisorExcel}
                          exportOptions={props.subMenu === "vc" ?  vctransactionAdvisorProfileExportData  : vctransactionAdvisorexitExportData} 
                          advisor_type={props.advisor_type}
                          type={
                          props.type!=null?props.type: props.subMenu === "vc" ? "vc_investment" : props.subMenu === "exist-ma-ma" ? "vc_ma_exit" : props.subMenu === "exist-pm-pm" ? "vc_pm_exit" : "pe_vc_exit"}
                        />
                      </div>
                    )}
                  </button>  
                  <a>
                    <img className="h-[24px] w-[2px] mt-1 " src={line} />
                  </a>
                </>}
                <SavedFilterICON  
                            save={save} 
                            FileIcon={FileIcon} 
                            FileIconBlur={FileIconBlur}   
                            savedList={savedList}  />  

              </div>
            </div>
           
        </div>
        {save ? <SavedFilterContent primaryTypeMasterId={props.primaryTypeMasterId}
                                subTypeMasterId={props.subTypeMasterId}
                                typeId={props.typeId} /> : ''}
      </div>
    </div>
  )
}

export default MainMenu