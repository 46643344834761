/**
 * The ThousandSeperator function takes a number as input and returns a string representation of that
 * number with commas separating every three digits.
 * @returns a string representation of the rounded value of the `props.value` with a comma as a
 * thousand separator.
 */
const ThousandSeperator = (props) => {
 
    return (
      Math.round(props?.value)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    )
}

export default ThousandSeperator
