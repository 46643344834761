import React, { useState, useContext, useEffect } from "react";
import MainMenu from '../../Pages/RE/ReMainMenu'
import Card from '../../Components/RE/ReListCard/AdvisorListCard'
import FilterTag from '../../Components/Tag'
import Table from '../../Components/REAdvisor/TableComponent'
import RefineSearch from '../../Components/RE/RefineSearchComponent'
import ReLayout from '../../Components/ReLayout'
import Constants from '../../Constants'
import { useLocation } from "react-router";
import { reAdvisorListExport } from "../../Config/config";
import { PeFilterContext } from "../../Contexts/PeFilterContext";
import MonthPickerComponent from "../../Utils/MonthPickerComponent";
import xls from "../../images/xls/png/Group 47.png";
import line from "../../images/line.png";
import FileIcon from "../../images/file.png";
import FileIconBlur from "../../images/fileblur.png";
import SavedFilterContent from "../../Components/SavedFilter";
import ExportPopup from "../../Components/ExportPopup";
import { reAdvisorInvestmentsExportData } from "../../Components/ExportPopup/ExportData"
import ReAdvisorRefineSearch from "../../Components/RefineSearch/ReAdvisorRefineSearch";
import { UserContext } from "../../Contexts/UserContext";
import { TableDataContext } from "../../Contexts/TableDataContext";
import { FaFilter } from "react-icons/fa";
import { IoClose  } from "react-icons/io5";


/**
 * The REVc function is a React component that renders a page for displaying and filtering real estate
 * advisor investments.
 * @returns a JSX element.
 */
function REVc(props) {
    const location = useLocation();
    const [showExcelPopup,setshowExcelPopup] =useState(false)
    const { save, setSave, showMonthPicker,refineSearchFilters ,groupId, isMobile,setOpen,open,setIsMobile} = useContext(PeFilterContext);
    const {globalSearchResult} = useContext(TableDataContext);
    const [openTab, setOpenTab] = React.useState(1);

    const savedList = () => {
        setSave(!save)
    }
    useEffect(() => {
        setOpenTab(props.active)
    }, [openTab])
    const {isTrialExport,isStudent} = useContext(UserContext)
    const {pathname} = useLocation()

    document.title = "RE Legal Advisors PE-RE Investments - Venture Intelligence"
   /**
    * The function checks if there are any global search filters applied
    */
    const isFromGlobalSearch = ()=> refineSearchFilters[pathname]?.globalSearch?.length > 0 ? true : false
    useEffect(()=>{
        if (window.innerWidth < 800) {
            setOpen(true);
            setIsMobile(true);
        }    },[])

    return (
        <ReLayout >
            <div className="flex min-h-screen h-screen flex-col">
            { isFromGlobalSearch() ? <></> :<Card />}
                <div className="flex min-h-0 flex-1 overflow-hidden">
                    <main className={`min-w-0 flex-1 md:border-t border-gray-200 flex ${isFromGlobalSearch() ? 'mt-[4.25rem] md:mt-[3rem]':''}`}>
                        <section className="flex h-full min-w-0 flex-1 flex-col lg:order-last bg-[#EDEDED]">
                            <FilterTag />
                            <div className="pl-3 pr-4 lg:pr-4 md:pr-[0.7rem]">
                                <div className="scrollbar ">
                                    <div className="px-2  md:px-3 rounded-[6px] rounded-b-none bg-white block lg:flex flex-row justify-between  ">

                                        <MainMenu active={1} menu="l-advisor" primaryTypeMasterId={Constants.primaryMasterIds.reId}
                                            subTypeMasterId={Constants.primaryMasterSubIds.re_advisor_investment_id}
                                            typeId={Constants.moduleIds.l_advisor_id} />
                                        
                                            <div className={"justify-between items-center px-2 py-2 space-x-4 "+(showMonthPicker!=0 ? 'md:flex':'flex')}>
                                                <label htmlFor="" className="text-[#666666] ">
                                                    <div className={showMonthPicker!=0 ?"":'flex'}>
                                                            <MonthPickerComponent />
                                                    </div>
                                                </label>                                           
                                                <div className={showMonthPicker!=0 ?"hidden md:flex space-x-3":"flex space-x-3"}>   
                                                {isMobile && (
                                                <>
                                                    <button
                                                    className="cursor-pointer lg:ml-7 ml-4 mt-2"
                                                    onClick={() => setOpen(!open)}
                                                    >
                                                    <FaFilter color="#bc8b20" size={21} />
                                                    {/* {!open && <IoClose color="#bc8b20" size={25} className="fixed z-50 mt-[92px] top-16 right-[39vw] mb-2" />} */}
                                                    </button>
                                                    <a>
                                                    <img
                                                        className="h-[24px] max-w-[2px] mt-1 "
                                                        src={line}
                                                    />
                                                    </a>
                                                </>
                                                )}
                                                {isTrialExport == 'false' || isStudent == 'true' ? <></>  : 
                                                    <>
                                                        {/* <button onClick={download}> */}
                                                        <button>
                                                            <img
                                                                src={xls}
                                                                className="h-[24px] mt-1 max-w-[21px]"
                                                                alt=""
                                                                onClick={() => setshowExcelPopup(!showExcelPopup)}
                                                            />
                                                            {showExcelPopup && (
                                                            <ExportPopup 
                                                            modules="advisor"
                                                            module="advisors"
                                                            fileName={Constants.exportNames.re_advisor_investments}
                                                            url={reAdvisorListExport}
                                                            onClose={() => setshowExcelPopup(false)} 
                                                            exportOptions={reAdvisorInvestmentsExportData} 
                                                            advisor_type="L"
                                                            type="re-investments"  />
                                                            )}
                                                        </button>
                                                
                                                

                                                
                                                        <img className="h-[24px] max-w-[2px] mt-1 " src={line} />
                                                
                                                    </>
                                                    }

                                                        <a className=" group cursor-pointer">
                                                        <div className="cursor-pointer" onClick={savedList}>
                                                            <img src={save ? FileIcon : FileIconBlur}
                                                                className="max-h-[24px] mt-1 max-w-[21px]"
                                                                alt="" />
                                                        </div>
                                                    </a> 
                                                </div>                                    
                                            </div>
                                        
                                    {save ? <SavedFilterContent
                                        primaryTypeMasterId={Constants.primaryMasterIds.reId}
                                        subTypeMasterId={Constants.primaryMasterSubIds.re_advisor_investment_id}
                                        typeId={Constants.moduleIds.l_advisor_id}
                                        show={save} /> : ''}
                                </div>
                            </div >
                            </div>
                            <div className='overflow-y-auto'>
                                <Table menu="re-ladvisor" advisor_type="L" sub_type_master_id={Constants.subCategoryIds.re_advisor_investment_id} />
                            </div>
                            {/* Your content */}
                        </section >
                        {/* Secondary column (hidden on smaller screens) */}
                        < aside className="order-first block flex-shrink-0" >
                            <div className="relative flex h-[100%]  flex-col  border-r border-gray-200 bg-[#FAF5EA]">
                                {/* Your content */}
                                <RefineSearch>
                                    <ReAdvisorRefineSearch customPathname={location.pathname} adviosrType="L"/>
                                </RefineSearch>
                            </div>
                        </aside >
                    </main>
                </div>

            </div >
        </ReLayout >
    )
}

export default REVc
