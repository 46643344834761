import React from 'react'
import FaqNavbarWrapper from './FaqNavbarWrapper'

/**
 * The FaqHeader function returns a FaqNavbarWrapper component.
 */
function FaqHeader() {
  return (
/* The line `<FaqNavbarWrapper />` is rendering the `FaqNavbarWrapper` component. It is essentially
creating an instance of the `FaqNavbarWrapper` component and including it in the returned JSX of the
`FaqHeader` function. */
    <FaqNavbarWrapper />
  )
}

export default FaqHeader