import React, { useState, useEffect, useContext } from "react";
import barRaiserImg from '../../images/barraise.png';
import delIcon from '../../images/Combined Shape_6/png/Combined Shape.png';
import editIcon from '../../images/combined-shape.png';
import { singleNoteApi, deleteNoteApi, updateNotesApi } from '../../Config/config'
import axios from 'axios'
import '../../../src/index.css'
import Loader from "../Loader";
import ConfirmPopup from "../../Utils/ConfirmPopup";
import RichTextEditor from "react-rte";
import {getTextAlignBlockMetadata,getTextAlignClassName,getTextAlignStyles} from "react-rte/lib/lib/blockStyleFunctions";
import { toast } from "react-toastify";
import { Tooltip } from "antd";
import PrimaryButton from "../shared/PrimaryButton";
import OutlineButton from "../shared/OutlineButton";
import CompanyLogo from "../CompanyLogo";
import RandomLogo from "../RandomLogo";
import { UserContext } from "../../Contexts/UserContext";
// import { NotesContext } from '../../Contexts/NotesContext'
import '../../index.css'
import moment from 'moment';
/* The above code is defining a functional component called `BarRaiserPage` in JavaScript. It takes in
a `props` object as a parameter. However, the code is incomplete as it is missing the closing
parentheses and curly braces for the component function. */

const BarRaiserPage = (props) => {
    // const [notesId, setNotesId] = useContext(NotesContext); 
    const [singleNote, setSingleNote] = useState([]);
    const [enableEdit, setEnableEdit] = useState({ disabled: true, readOnly: true })
    const [noteValue, setnoteValue] = useState();
    const [isLoading, setisLoading] = useState(false);
    const [alert, setalert] = useState({ show: false, message: "", note: {} })
    const {getToken} = useContext(UserContext);

    useEffect(() => {
        singleNoteDetail(props.notes_id)
    }, [props.notes_id]);
  /**
   * The function `singleNoteDetail` makes a POST request to a specific API endpoint to retrieve
   * details of a single note, sets the response data in the state variables, and handles any errors
   * that occur during the API call.
   */
    const singleNoteDetail = () => {
        // console.log(props.notes_id,'props notes id');
        setisLoading(true);
        axios(`${singleNoteApi}`, {
            method: "POST",
            data: {
                "notes_id": props.notes_id
            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": getToken()
            },
        })
            .then((response) => {
                // console.log(response, 'single note List response');
                setEnableEdit({ disabled: true, readOnly: true })
                setisLoading(false);

                if (response?.data?.status == 200) {
                    setSingleNote(response?.data)
                    setnoteValue(RichTextEditor.createValueFromString(response?.data?.notes[0]?.content, "html",{
                        customBlockFn: getTextAlignBlockMetadata,
                      }));
                    console.log(response?.data, 'single note list')
                }
                else {
                    setSingleNote(response?.data?.message)
                }
            })
            .catch((error) => {
                console.log("API not working", error);
            });

    }
   /**
    * The `deleteNote` function sends a POST request to a specified API endpoint to delete a note, and
    * then performs some actions based on the response.
    * @param note - The `note` parameter is an object that contains the following properties:
    */
    const deleteNote = (note) => {
        // console.log("print delet", note);
        // props?.onRefresh();

        // return;
        axios(`${deleteNoteApi}`, {
            method: "POST",
            data: {
                "noteable_id": note?.noteable_id,
                "noteable_sub_type_id": note?.noteable_sub_type_id,
                "notes_id": note?.id
            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": getToken()
            },
        })
            .then((response) => {
                console.log(response, 'delete note List response');
                setEnableEdit({ disabled: true, readOnly: true })
                if (response?.data?.status == 200) {
                    props?.onRefresh();
                    toast.success(response?.data?.message)
                    // setNoteDelete(response?.data)
                    // console.log(noteDelete, 'delete note list')
                    // setMsg(response?.data?.message)
                    // singleNoteDetail()
                    // setSuccess(response?.data?.sucesss)
                    // setShowNotification(true)
                    // props.onDelete();
                }
                else {
                    // setMsg(response?.data?.message)
                    // setSuccess(response?.data?.sucesss)
                    // setShowNotification(true)
                    toast.error(response?.data?.message)

                }
            })
            .catch((error) => {
                console.log("API not working", error);
            });

    }

   /**
    * The function `confirmDeletion` sets an alert message asking the user if they want to delete a
    * note.
    * @param note - The `note` parameter is the note that the user wants to delete.
    */
    const confirmDeletion = (note) => {
        setalert({ show: true, message: "Are you sure you want to delete this note?", note: note })
    }


  /**
   * The function `onConfirmation` checks if the flag is equal to 1, and if so, it calls the
   * `deleteNote` function with the `alert.note` as an argument. Then, it sets the `show` property of
   * the `alert` object to false, and resets the `message` and `note` properties to empty values.
   * @param flag - The flag parameter is used to determine the condition inside the if statement. If
   * the flag is equal to 1, it will execute the code inside the if block.
   */
    const onConfirmation = (flag) => {
        if (flag === 1) {
            deleteNote(alert.note)
        }
        setalert({ show: false, message: "", note: {} })
    }

 /**
  * The `onChange` function updates the `noteValue` state with the provided value.
  * @param value - The value parameter is the new value that is being passed to the onChange function.
  */
    const onChange = (value) => {
        setnoteValue(value);
    };

    /**
     * The function `validate` checks if a note is empty and displays a warning message if it is.
     * @returns The function `validate` returns a boolean value. It returns `false` if the notes are
     * empty and displays a warning toast message. It returns `true` if the notes are not empty.
     */
    const validate = () => {
        let isEmpty = !!noteValue?.getEditorState()?.getCurrentContent()?.getPlainText()?.trim();
        if (!isEmpty) {
            toast.warning("Notes must not be empty");
            return false;
        }
        return true;
    }

   /**
    * The `updateNote` function is used to update a note by sending a POST request to an API endpoint
    * with the updated note content and primary type master ID.
    * @param note - The `note` parameter is an object that contains information about the note. It has
    * the following properties:
    * @param primary_type_master_id - The `primary_type_master_id` parameter is the ID of the primary
    * type master for the note. It is used to specify the primary type of the note when updating it.
    * @returns The function `updateNote` returns nothing (undefined).
    */
    const updateNote = (note,primary_type_master_id) => {
        if (!validate()) {
            return;
        }
        setEnableEdit({ disabled: true, readOnly: true })
        axios(updateNotesApi, {
            method: "POST",
            data: {
                "noteable_id": note.noteable_id,
                "noteable_sub_type_id": note.noteable_sub_type_id,
                "content": noteValue?.toString("html", {
                    blockStyleFn: getTextAlignStyles,
                 }),
                "primary_type_master_id":primary_type_master_id
            },
            headers:{
                "Authorization": getToken()
            }
        })
            .then((response) => {
                // console.log(response, 'created Note response');     
                if (response?.data?.success === true) {
                    props?.onRefresh();
                    toast.success("The notes has been updated")
                }
                else {
                    toast.warning(response?.data?.message)
                }
            })
            .catch((error) => {
                console.log("API not working", error);
            });
    }

  /**
   * The `onCloseClick` function sets the value of a note to be displayed in a RichTextEditor component
   * and disables editing by setting the `disabled` and `readOnly` properties.
   */
    const onCloseClick = ()=>{
        setnoteValue(RichTextEditor.createValueFromString(singleNote?.notes[0]?.content, "html",{
            customBlockFn: getTextAlignBlockMetadata,
          }));
        setEnableEdit({ disabled: true, readOnly: true })
    }
   
    return (
        <>
            {/* {showNotification ? <NotesNotification message={msg} success={success} /> : ''} */}
            {<ConfirmPopup show={alert.show} message={alert.message} onChange={onConfirmation} />}
            {!isLoading ?
                singleNote?.notes?.length !== 0 && singleNote?.notes?.length
                    ? singleNote?.notes?.map((notes, i) => {
                        const formatedDate = moment(new Date(notes.created_at));
                        const primary_type_master_id=notes?.primary_type_master_id

                        console.log(notes.created_at,'cteated at');
                        return (
                            <div className=" lg:bg-white rounded-[4px] shadow-sm m-6 pt-5 ">
                                <div className="hidden lg:flex flex-shrink-0 self-center  sm:px-6">
                                    <div className="min-w-0 flex-1 mt-1 mr-3">
                                        <p
                                            className="  font-sans_book_body  text-[13px] text-left text-[#888888] tracking-[-0.28px] leading-[16px]">
                                                
                                            {formatedDate?.isValid() ? formatedDate.format('DD MMM yyyy hh:mm A') :''}
                                            </p>
                                    </div>
                                    <div className="relative  flex items-center text-left">
                                        {enableEdit.disabled
                                            ? <Tooltip title="Edit Note">
                                                <img alt="" src={editIcon} className="h-[18px] cursor-pointer mr-4" onClick={() => { setEnableEdit({ disabled: false, readOnly: false }) }} />
                                            </Tooltip> : <></>}
                                        <Tooltip title="Delete Note">
                                            <img alt="" src={delIcon} className="h-[18px] cursor-pointer" onClick={() => { confirmDeletion(notes) }} />
                                        </Tooltip>
                                    </div>
                                </div>
                                <div className="hidden lg:flex mt-4 items-center sm:px-6">
                                    <div className="flex-shrink-0">
                                        {/* <img src={barRaiserImg} alt="" className="w-[27px] h-[26px] " /> */}
                                        {notes?.logo_path ?
                                        <CompanyLogo name={notes?.entity_name} id={notes?.id} page="company page" logo={notes?.logo_path} />
                                        :
                                        <RandomLogo name={notes.entity_name} />
                                        }
                                    </div>
                                    <div className="min-w-0 flex-1">
                                        <p className=" font-sans_medium_body text-left text-[#333333] text-[16px] tracking-[-0.34px] leading-[19px]">  {notes.entity_name}
                                        </p>
                                    </div>
                                </div>

                                {/* <span dangerouslySetInnerHTML={{ __html: htmlDecode(notes.content), }}/> */}
                                <div className="p-5  sm:px-6">
                                <RichTextEditor blockStyleFn={getTextAlignClassName} editorClassName={"h-full w-full overflow-y-auto font-sans_medium_body "+(enableEdit.disabled ? 'pb-[1rem] ' :'pb-[3rem] ')} onChange={onChange} value={noteValue} {...enableEdit}/>
                                </div>
                                <div className={`${enableEdit.disabled ? "hidden" : "flex"} justify-end bg-gray-50 px-4 py-3`}>
                                    <OutlineButton onClick={onCloseClick}>Cancel</OutlineButton>
                                    <PrimaryButton onClick={() => updateNote(notes,primary_type_master_id)}>Update</PrimaryButton>
                                </div>


                                {/* <p dangerouslySetInnerHTML={{ __html: notes.content }} className="hidden lg:flex mt-3 font-sans_book_body text-[#333333] text-[16px] text-left tracking-[-0.34px] leading-[22px]" /> */}

                            </div>
                        )

                    })
                    : <div className="w-full h-full bg-[#FFFFFF] flex items-center place-content-center">
                        No Records Found
                    </div>
                : <div className=" m-6 p-5 h-96 sm:px-6 bg-[#FFFFFF] flex items-center place-content-center">
                    <Loader />
                </div>
            }
        </>
    )
}

export default BarRaiserPage
