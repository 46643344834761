import { React, useContext } from 'react'
import { TableDataContext } from '../../../Contexts/TableDataContext'
import ReInveatmentDealCard from './ReInvestmentDealCard';
import ReExitDealCard from './ReExitDealcard';
import OtherMaDealCard from './OtherMaDealCard';
import ReIpoDealCard from './ReIpoDealcard';

/**
 * The `Card` function is a React component that renders different types of deal cards based on the
 * active tab selected.
 * @returns The Card component is returning a div element with a className of "" and two child
 * elements. The first child element is a div with className 'sm:full md:mt-[3rem] mt-[4.25rem] h-full
 * bg-[#F2F2F2]'. The second child element is a div with className 'border-b-2 border-[#e5e5e
 */
 const Card = ({props,category}) => {

  const { activeTabDeal} = useContext(TableDataContext);
 


  return (
    <div className="">
    <div className='sm:full md:mt-[3rem] mt-[4.25rem] h-full bg-[#F2F2F2]'>
      {/* <div className="overflow-x-auto lg:grid grid-cols-4 gap-4  px-4 py-[0.7rem] flex xl:overflow-hidden scrollbar-remove "> */}
      {activeTabDeal == "re-investments" ?
        <ReInveatmentDealCard category={category}/>
        : activeTabDeal == "re-exits" ?
          <ReExitDealCard category={category}/>
          : activeTabDeal == "other-manda" ?
            <OtherMaDealCard category={category}/>
            :
              <ReIpoDealCard category={category}/>
             
        
      }

    </div>
    <div className='border-b-2 border-[#e5e5e5]'></div>
    {/* </div > */}
  </div >
  )
}

export default Card;