import React, { useMemo, useEffect, useState, useContext } from "react";
import { useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import SortAscendingIcon from 'mdi-react/SortAscendingIcon';
import SortDescendingIcon from 'mdi-react/SortDescendingIcon';
import SortIcon from 'mdi-react/SortIcon';
import Paginate from '../Paginate';
import axios from "axios";
import { getRequestListUrl } from "../../Config/config";
import Loader from "../Loader";
import OverflowToolTip from "../shared/OverflowToolTip";
import { Link } from "react-router-dom";
import { routeNames } from "../../routeSegments";
import moment from 'moment';
import { UserContext } from "../../Contexts/UserContext";

/**
 * The `RequestDataTable` component displays a table of request data,
 * including subject, description, date, type, and status, with sorting and pagination functionality.
 */
function RequestDataTable({ selectedStatus, searchText }) {
    const [requestList, setRequestList] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const { getToken, getUser } = useContext(UserContext);

    useEffect(() => {
        setIsLoading(true);
        axios.post(getRequestListUrl, {
            userId: getUser().id
        }, {
            headers: {
                "Authorization": getToken()
            }
        }).then(response => {
            setIsLoading(false);
            if (response.status === 200) {
                setRequestList(response.data?.raisedrequest ?? [])
            }
        })
    }, [])

    const dealDateSort = useMemo(() => {
        return (rowA, rowB, columnId, desc) => {

            const dateA = moment.utc(rowA?.values[columnId]);
            const dateB = moment.utc(rowB?.values[columnId]);

            if (!dateA.isValid()) {
                return -1;
            }
            if (!dateB.isValid()) {
                return 1;
            }
            return dateA.diff(dateB)
        }
    }, [])

    const subjectSort = useMemo(() => {
        return (rowA, rowB, columnId, desc) => {
            const defaultVal = desc ? '--' : 'A';
            // console.log(rowA?.values[columnId]?.roundName.toLowerCase(),'lower case');
            return (rowA?.values[columnId]?.subject ?? defaultVal).localeCompare(rowB?.values[columnId]?.subject ?? defaultVal);
        }
    }, []);

    const statusSort = useMemo(() => {
        return (rowA, rowB, columnId, desc) => {
            const defaultVal = desc ? '--' : 'A';
            return (rowA?.values[columnId]?.msg ?? defaultVal).localeCompare(rowB?.values[columnId]?.msg ?? defaultVal);
        }
    }, []);


    const columns = useMemo(() => [
        // {
        //   Header: "User Name",
        //   accessor: "userName",
        // },
        // {
        //   name: "Email ID",
        //   selector: "email",
        // },
        {
            Header: "Subject",
            accessor: "subject",
            Cell: ({ value }) => {
                return <OverflowToolTip title="Subject" component={<Link to={routeNames.requestById(value.id)}>{value.subject}</Link>} />
            },
            sortType:subjectSort,
        },
        {
            Header: "Description",
            accessor: "description",
            Cell: ({ value }) => {
                return <OverflowToolTip title="Description" component={value} />
            },
        },
        {
            Header: "Date",
            accessor: "date",
            Cell: ({ value }) => {
                const formatedDate = moment(new Date(value));
                return formatedDate?.isValid() ? formatedDate.format('DD MMM yyyy hh:mm A') : "--"
            },
            // sortType: dealDateSort,
        },
        {
            Header: "Type",
            accessor: "type",
        },
        {
            Header: "Status",
            accessor: "status",
            Cell: ({ value }) => {
                return (<span className="flex space-x-3 items-center"><span className={`mt-2 flex-shrink-0 rounded-full ${getStatusBackgroundColor(value)} top-0  inline-flex items-center justify-center h-1 w-1 p-1 text-xs  transform translate-x-1/2 -translate-y-1/2`} aria-hidden="true"></span><span>{value}</span></span>);
            },
            sortType:statusSort,
        },
        // {
        //     Header: "Resolution",
        //     accessor: "resolution",
        // }
    ], [])

    const data = useMemo(() => {
        let row = [];
        requestList?.forEach(request => {
            row.push({
                subject: { id: request.ticket_id, subject: request.subject },
                description: request.description,
                date: request.date,
                type: request.product,
                status: getStatus(request.status),
                resolution: request.resolution,
            });
        })
        return row;
    }, [requestList])

    function getStatusBackgroundColor(status) {
        if (status === 'In-progress') {
            return 'bg-[#F5A623]';
        }else if (status === 'Open ') {
            return 'bg-[#4A90E2]';
        }else if (status === 'Reopen') {
            return 'bg-[#EF4E3E]';
        }else if (status === 'Closed') {
            return 'bg-[#38B449]';
        }else{
            return 'bg-transparent';
        }
    }
    function getStatus(status) {
        if (status === 'work-in-progress') {
            return 'In-progress';
        }else if (status === 'open' || status === '' || status === null) {
            return 'Open ';
        }else if (status === 're-opened') {
            return 'Reopen';
        }else if (status === 'closed') {
            return 'Closed';
        }else{
            return '';
        }      
    }
  
    const initialSort = useMemo(() => [
        {
            id: 'date',
            desc: true
        }
    ], []);

    const tableInstance = useTable({
        columns,
        data,
        disableSortRemove: true,
        initialState: { pageSize: 10, pageIndex: 0, sortBy: initialSort },
    },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination,
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        page,
        prepareRow,
        gotoPage,
        setFilter,
        setGlobalFilter,
        state: { pageIndex, pageSize }
    } = tableInstance;

    useEffect(() => {
        setFilter("status", selectedStatus);
    }, [selectedStatus]);

    useEffect(() => {
        setGlobalFilter(searchText);
    }, [searchText])


    return (
        <>
            <div className='overflow-auto scrollbar'>
                <table className="w-full " {...getTableProps()}>
                    <thead className="text-[12pt] leading-[15pt] bg-[#EDEDED] lg:text-[12px] lg:leading-[15px] text-[#666666] font-sans_semi_bold py-4 px-0 text-center ">
                        {
                            headerGroups.map(headerGroup => (

                                <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                                    {
                                        headerGroup.headers.map(column => (

                                            <th {...column.getHeaderProps([
                                                {
                                                    className: `py-3.5 px-5 whitespace-nowrap ${column.headerClassName}`,
                                                    style: column.headerStyle,
                                                },
                                                column.getSortByToggleProps(),
                                            ])}>
                                                <div className="flex">
                                                    <span className="mr-1">{column.render('Header')}</span>
                                                    {column.isSorted
                                                        ? column.isSortedDesc
                                                            ? <SortAscendingIcon size={16} className="mt-[0.15rem] lg:mt-0" />
                                                            : <SortDescendingIcon size={16} className="mt-[0.15rem] lg:mt-0" />
                                                        : <SortIcon size={16} className="mt-[0.15rem] lg:mt-0" />}
                                                </div>
                                            </th>
                                        ))
                                    }
                                </tr>
                            ))
                        }
                    </thead>


                    <tbody {...getTableBodyProps()} className="text-[12pt] leading-[16pt] lg:text-[14px] font-sans_book_body text-left">

                        {page.map(row => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()} className="border-b">
                                    {
                                        row.cells.map(cell => {

                                            return (
                                                <td {...cell.getCellProps([
                                                    {
                                                        className: `whitespace-nowrap px-5 py-4 ${cell.column.className}`,
                                                        style: cell.column.style,
                                                    },
                                                ])}>
                                                    {
                                                        cell.value ? cell.render('Cell') : "--"
                                                    }
                                                </td>
                                            )
                                        })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                {page?.length === 0
                    ? <div className="w-full h-40 flex items-center justify-center">
                        {isLoading ? <Loader /> : "No data found"}
                    </div>
                    : <></>
                }
            </div>
            {rows?.length <= 5
                ? <></>
                : <Paginate
                    currentPage={pageIndex + 1}
                    totalCount={rows?.length}
                    pageSize={pageSize}
                    onPageChange={(page) => {
                        gotoPage(page - 1);
                    }}
                />}
        </>
    )
}

export default RequestDataTable