import React from 'react'
import CloseButton from "../../images/CloseBtn.svg";

/**
 * The `ExpandedTagList` function is a React component that renders a list of tags with a remove button
 * for each tag.
 * @returns a JSX element, specifically a `<div>` element with a class name of 'max-h-40
 * overflow-y-auto'. Inside the `<div>`, there is a mapping of the `tagList` array. For each tag in the
 * `tagList`, a `<div>` element is created with a key set to the tag's name. Inside this `<div>`, the
 * tag
 */
function ExpandedTagList({ tagList, onRemove }) {
    return (
        <div className='max-h-40 overflow-y-auto'>
            {
                tagList?.map(tag => {
                    return < div key={tag.name} className='p-2 flex justify-between items-center hover:bg-gray-200' >
                        {tag.name}
                        <img src={CloseButton} alt="remove tag" className="h-3 w-3 ml-3 cursor-pointer" onClick={() => onRemove(tag?.id)} />
                    </div>
                })
            }
        </div >

    )
}

export default ExpandedTagList