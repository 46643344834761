import React, {useContext, useCallback} from 'react';
import { CompareCompanyContext } from '../../Contexts/CompareCompanyContext'
import { BrowserRouter as Router, Link, useParams } from "react-router-dom";
import CompanyLogo from '../CompanyLogo';
import RandomLogo from '../RandomLogo';
import AddNote from '../../images/AtomFavourite Copy 5/png/AtomFavourite Copy 5.png'
import CancelImg from '../../images/Group 25.png';
import CompanyImg from '../../images/barraiser-logo.png';
import '../../index.css'

/* The above code is a React component called "Company". It receives props and uses the useContext hook
to access the "CompareCompanyContext". It renders a list of company details based on the
"companyDetail" state from the context. It also provides a function to remove a company from the
list when the user clicks on the remove button. The removed company is filtered out from the
"companyDetail" state and the "compareCompanyId" state is updated accordingly. The component also
includes conditional rendering and styling based on the data received. */
const Company = (props) => {
    const { companyDetail, setCompanyDetail, compareCompanyId, setCompareCompanyId } = useContext(CompareCompanyContext);
    console.log(companyDetail,"asdfghhjkhnk")

   /* The `removeCompany` function is a callback function created using the `useCallback` hook. It
   takes an `id` parameter and is used to remove a company from the list of company details. */
    const removeCompany = useCallback((id) => {
        setCompanyDetail((state) => state.filter((item) => item[0].value!== id))
        setCompareCompanyId(oldId=>oldId.filter((item)=>item!=id))
       
    },[compareCompanyId])
    
    return (
        <>
        {companyDetail?.map((detail,i) => {
            return(
                <div className="flex-shrink-0 w-[21rem] bg-wrapper">                    
                  <ul className="list-none divide-y">
                    <div className="pt-1 font-sans_book_body"> 
                    
                    {detail?.map((data, i) => { 
                        return(
                            typeof(data?.value) != "string" && typeof(data?.value) != "number" && data?.value ?
                            data?.display_mode != "none" &&
                            <p
                                className={" leading-[20px] text-[14px] text-[#333333] pl-4 py-4 "+
                                (
                                    data?.display_bold ?  data?.dynamic_styling ? " overflow-y-auto textwrap font-bold bg-[#ebebeb] h-[4rem]" :" font-bold bg-[#ebebeb] h-[3rem]" : data?.dynamic_styling ? " overflow-y-auto textwrap border-b h-[4rem]" : "overflow-y-auto textwrap border-b h-[3rem]" 
                                     // data?.dynamic_styling ? " h-[4rem]" : " h-[3rem]"
                                )}>
                                    {/* <p>bjhnb </p> */}
                                {data?.value?.length >0 ?
                                 data?.value?.map((dataa, j) => {
                                    return(
                                        data?.label=="Investors"?
                                        data?.value.length==j+1?
                                        <Link to={`/${data?.label=="Investors"?'investor':""}/${dataa?.id}`} >{dataa?.name}</Link>
                                        :<Link to={`/${data?.label=="Investors"?'investor':""}/${dataa?.id}`} >{dataa?.name} ,</Link>
                                        :
                                        data?.value.length==j+1?
                                        dataa?.name
                                        :dataa?.name+" ,"
                                    )
                                })
                                :"--"
                            }

                            </p>           
                            :
                            data?.display_mode != "none" &&
                            (data?.field_name == "company_name" || data?.field_name == "company" || data?.field_name == "investor_name" || data?.field_name == "advisor_name" || data?.field_name == "acquirer" || data?.field_name == "acquirer_name")?
                            <p
                            className={"flex justify-between items-center leading-[24px] text-[14px] text-[#333333] px-4 py-4 h-[3rem]"+
                            (
                                data?.display_bold ? " font-bold bg-[#ebebeb]" :" border-b "
                            )}>
                                <span className='flex'>
                                {detail[1]?.icon_path=="default.png" || detail[1]?.icon_path=="/default.png" || !detail[1]?.icon_path?
                                <RandomLogo name={`${detail[1]?.value}`} />
                                :
                                <CompanyLogo name={detail[1]?.value} page="company page" logo={detail[1]?.icon_path}/>
                                  } 
                             {/* <CompanyLogo id={detail.id} page="compare page"/> */}
                                <p
                                    className="pt-[3px] comapre_company font-sans_semi_bold text-[#333333] text-[17.28px] leading-[21px]">
                                    <span className='ml-3' title={data?.value}>{data?.value}</span></p>
                                    </span>
                                    {companyDetail?.length<=1?
                                        <img src={CancelImg} onClick={() => removeCompany(detail[0]?.value)} className="w-[1.3rem] h-[1.3rem] pointer-events-none" alt="" />
                                    :
                                        <img src={CancelImg} onClick={() => removeCompany(detail[0]?.value)} className="w-[1.3rem] h-[1.3rem]" alt="" />
                                    }
                                    {/* <div>
                                        <img src={AddNote} className="w-[1.3rem] h-[1.3rem]" alt="" />
                                    </div> */}
                                </p> 
                            :
                            data?.display_mode != "none" &&
                                    <p
                                        className={` leading-[24px] text-[14px] text-[#333333] pl-4 py-4 ${data?.dynamic_styling ? "overflow-y-auto h-[4rem]" : "h-[3rem]"}` +
                                            (
                                                data?.display_bold ? " font-bold bg-[#ebebeb]" : " border-b "
                                            )}>
                                        {(data?.value != null && data?.value!="0" &&data?.value != "") ? data?.value : data?.display_bold ? "" : "--"}
                                    </p>
                        )
                    })}  
                    </div>
                  </ul>                         
                </div>
            )})}
            </>

    )
}

export default Company