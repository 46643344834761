import { Radio, Tooltip } from "antd";
import { React, useState, useEffect, useContext} from "react";
import { useLocation} from "react-router-dom";
import { moduleValues } from "../../Constants/values";
import { RaiseRequestContext } from "../../Contexts/RaiseRequestContext";
import PlusIcon from '../../images/plusicon.png'
import AlertsDataTable from "../Alerts/AlertDataTable";
import RequestDataTable from "./RequestDataTable";
import Notifications from "./Notifications";
import ToggleSwitch from "../RefineSearchComponents/ToggleAndOrSwitch";
import useModule from "../../hooks/useModule";

const statusOptions = [
  {
    title: "All",
    value: "",
  },
  {
    title: "Open ",
    value: "Open ",
  },
  {
    title: "In-progress",
    value: "In-progress",
  },
  {
    title: "Closed",
    value: "Closed",
  },
  {
    title: "Reopen",
    value: "Reopen",
  },
];

/** The "Requests" component is responsible for rendering a tabbed
interface that allows the user to switch between different types of requests, alerts, and
notifications. */
const Requests = () => {

  const [search, setSearch] = useState(false)
  const [selectedStatus, setselectedStatus] = useState();
  const [active, setActive] = useState()
  const [searchText, setsearchText] = useState("");
  const [filterOptions, setfilterOptions] = useState([]);
  const { setOpen } = useContext(RaiseRequestContext)
  const [currentNotificaionType, setCurrentNotificaionType] = useState("alert")
  const [isRead, setIsRead] = useState(false)
  const {isNotifications,isRequests,isAlerts} = useModule()

  const onNotificationTypeChange = (e) => {    
    setCurrentNotificaionType(e.target.value)
  };

  const location = useLocation();


  const activeTable = (id) => {
    setActive(id)
  }



  const onStatusChange = (e) => {
    setselectedStatus(e.target.value)
  }

  const onSearchChange = (e) => {
    setsearchText(e.target.value);
  }

  useEffect(()=>{
    if(location?.state?.from){
    setActive(location?.state?.from)}
    
    else if(isAlerts){
      setActive(2)
    }
    else if(isNotifications){
      setActive(3)
    }
    else{
      setActive(1)
    }
  },[location?.pathname, location?.state])

  useEffect(() => {
    if (active === 1) {
      setfilterOptions(statusOptions)
    } else if (active === 2) {
      setfilterOptions(moduleValues);
    }
  }, [active])

  return (
    <div className="bg-white">
      <div className="flex flex-col md:flex-row justify-between  px-4  rounded-t-[6px] shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
        <div className="flex gap-7 pt-2">
          <button className={
            "whitespace-nowrap   text-[12pt]  lg:text-[13px] lg:leading-[15px] font-medium  block leading-normal " +
            (active === 1
              ? "text-[#333333] py-[10px] border-b-[3px] border-[#F1CA75] font-sans_semi_bold"
              : " text-[#333333] py-3 opacity-[.4] font-sans_medium_body")
          }
            onClick={() => activeTable(1)}>Requests</button>
          <button className={
            "whitespace-nowrap   text-[12pt]  lg:text-[13px] lg:leading-[15px] font-medium  block leading-normal " +
            (active === 2
              ? "text-[#333333] py-[10px] border-b-[3px] border-[#F1CA75] font-sans_semi_bold"
              : " text-[#333333] py-3 opacity-[.4] font-sans_medium_body")
          }
            onClick={() => activeTable(2)}>Alerts</button>
          <button className={
            "whitespace-nowrap   text-[12pt]  lg:text-[13px] lg:leading-[15px] font-medium  block leading-normal " +
            (active === 3
              ? "text-[#333333] py-[10px] border-b-[3px] border-[#F1CA75] font-sans_semi_bold"
              : " text-[#333333] py-3 opacity-[.4] font-sans_medium_body")
          }
            onClick={() => activeTable(3)}>Notifications</button>
        </div>
        <div className="flex flex-col md:flex-row ml-2 md:items-center">
          {
            active === 3
              ? <>
                <Radio.Group 
                onChange={onNotificationTypeChange} 
                value={currentNotificaionType}>
                  <Radio value="alert">Alerts</Radio>
                  <Radio value="request">Requests</Radio>
                  {/* <Radio value="general">Notifications</Radio> */}
                </Radio.Group>
              </>
              : <>
                <div className={`mx-3 input-search flex  items-center pointer-events-auto rounded   opacity-[0.6]`}>
                  <input
                    placeholder="Search"
                    type="search"
                    value={searchText}
                    onChange={onSearchChange}
                    className={`border border-gray-200 focus:border-primaryColor rounded-lg outline-none px-2 transition-all delay-150 py-1 ${search ? "md:w-[75%] lg:w-full" : "hidden"} `} />
                  <label className=" flex  rounded ">
                    <Tooltip title={search ? "Close search" : "Open search"}>
                      <svg onClick={() => {
                        setSearch(prev => !prev)
                        setsearchText("")
                      }} width="20" height="24" fill="none" aria-hidden="true" className={`text-slate-400 cursor-pointer ${search ? "text-primaryColor" : ""}`}>
                        <path d="m19 19-3.5-3.5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                        <circle cx="11" cy="11" r="6" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></circle>
                      </svg>
                    </Tooltip>
                  </label>
                </div>
              </>
          }
          <div className="flex items-center space-x-2 my-2 md:my-0 lg:my-0">
            {active === 3 ? "" :
            <select onChange={onStatusChange} value={selectedStatus} name="status" id="status" className="text-[#333333] border-x-2 mx-2 border-x-slate-100 lg:text-[13px] bg-white  focus:outline-none font-sans_book_body  text-sm px-2 py-1.5 inline-flex items-center">
                              {
                                filterOptions?.map(option => {
                                  return <option
                                    key={option.value}
                                    value={option.value}
                                    className="block py-2 px-4 bg-white hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white" >
                                    {option.title}</option>
                                })
                              }
                            </select>
            }

          {active === 1 ? <div className="block">
            <button onClick={() => setOpen(true)} className="flex bg-[#BC8B20] text-white text-[14px] leading-[17px] tracking-[-0.3px] items-center px-2 py-1.5 rounded-[4px]">
              <img alt="" className='w-[22px] h-[13px] px-1 brightness-0 invert-[1]' src={PlusIcon}></img>
              <span>New Request</span>
            </button>
          </div> : <></>}
          {active === 2 ? <div className="block">
            <button className="flex bg-[#BC8B20] text-white text-[14px] leading-[17px] tracking-[-0.3px] items-center px-2 py-1.5 rounded-[4px]">
              <img alt="" className='w-[22px] h-[13px] px-1 brightness-0 invert-[1]' src={PlusIcon}></img>
              <span>New Alerts</span>
            </button>
          </div> : <></>}
          {active === 3 
          ? <div className="block">
            <ToggleSwitch value={isRead} onLabel="Unread" offLabel="Read" onToggle={(value) => setIsRead(prev => !prev)} />
          </div> 
          : <></>
          }
          </div>
        </div>
      </div>
      <div className="h-full w-full overflow-y-auto">
        {active === 1
          ? <RequestDataTable selectedStatus={selectedStatus} searchText={searchText} />
          : active === 2 ? <AlertsDataTable selectedFilter={selectedStatus} searchText={searchText} />
            : <Notifications notificationType={currentNotificaionType} isRead={isRead}/>
        }
      </div>
    </div>
  )
}


export default Requests;


