import React, {useMemo, useState, useContext} from 'react'
import Table from './Table'


import { TableHeaderContext } from '../../Contexts/TableHeaderContext'

 

/**
 * The TableComponent function renders a table component based on the menu and advisor type props, with
 * columns and pagination data.
 * @param props - The `props` parameter is an object that contains the properties passed to the
 * `TableComponent` component. These properties can be accessed using dot notation, such as
 * `props.menu` and `props.advisor_type`.
 * @returns The TableComponent is being returned.
 */
const TableComponent = (props) => {
  const { AdvisorPEVCTableHeader,
          AdvisorVCTableHeader, 
          AdvisorPEVCExitMATableHeader, 
          AdvisorPEVCExitPMTableHeader,
          AdvisorVCExitMATableHeader,
          AdvisorVCExitPMTableHeader,
          advisororderingColumn,
        }
   = useContext(TableHeaderContext);
    const [investments,
        //  setInvestments
        ] = useState([]);
    const [pagination, 
        // setPagination
    ] = useState()

/* The `useMemo` hook is used to memoize the value of `investments`. It takes two arguments - a
function and a dependency array. */
    const tableRowData = useMemo(() => investments, [investments]);  
/* The line `const paginationData = useMemo(() => pagination, pagination);` is using the `useMemo` hook
to memoize the value of the `pagination` state variable. */
    const paginationData = useMemo(() => pagination, pagination);   
    // console.log(paginationData,"paginatorInfo")   
  
    const columns = props.menu == "pe_vc_advisor" ? AdvisorPEVCTableHeader
          :props.menu == "vc_advisor" ? AdvisorVCTableHeader
          :props.menu == "pe_vc_ma_advisor" || props.menu == "pe_vc_exit" ? AdvisorPEVCExitMATableHeader
          :props.menu == "pe_vc_pm_advisor" ? AdvisorPEVCExitPMTableHeader 
          :props.menu == "vc_ma_advisor" || props.menu == "vc_exit"? AdvisorVCExitMATableHeader 
          :props.menu == "vc_pm_advisor" ?AdvisorVCExitPMTableHeader
          : AdvisorPEVCTableHeader
    return (
        <div className="pl-3 pr-2">
            {/* {console.log('investments',tableRowData)} */}
            <div className="App ">
            <Table order="asc" orderBy={advisororderingColumn} menu={props?.menu} columns={columns} advisor_type={props?.advisor_type} pagination = {paginationData} sub_type_master_id={props?.sub_type_master_id}/>
            </div>
        </div>
    )
}
export default TableComponent