import React, { useContext} from 'react'
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../Contexts/UserContext';
import logowidget from "../../src/images/userAvatar.png";
import { Popover } from 'antd';

/** The code is defining a functional component called `Profile`. Inside the component, it is using the
`useContext` hook to access the `logout` function from the `UserContext`. It is also using the
`useNavigate` hook to get the `navigateTo` function. */
const Profile = () => {

  const { logout } = useContext(UserContext);
  const navigateTo = useNavigate();

  const logoutClick = (e) => {
    logout();
  }


  return <Popover
    trigger="click"
    placement='bottomLeft'
    openClassName='p-0'
    overlayInnerStyle={{
      padding: 0
    }}
    content={<div>
      <div onClick={() => navigateTo('/setting')} className="px-2 cursor-pointer py-2 font-sans_book_body text-[14px] text-[#333333] hover:bg-gray-100">Account Settings</div>
      <div onClick={logoutClick} className="cursor-pointer px-2  py-2 font-sans_book_body text-[14px] text-[#333333] hover:bg-gray-100">Logout</div>
    </div>}>
    <img
      className="h-9 w-9 rounded-full cursor-pointer"
      src={logowidget}
      alt=""
    />
  </Popover>
}
export default Profile;