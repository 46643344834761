import React from "react";

/**
 * The above function is a React component that renders investment details in a grid layout.
 * @param props - The `props` parameter is an object that contains any additional data or properties
 * that are passed to the `IpoInvestment` component. These props can be accessed within the component
 * using dot notation, such as `props.data`. In this case, the `data` prop is being used to display
 * @returns The code is returning a JSX element, specifically a `<div>` element with the class name
 * "lg:grid lg:grid-rows-8 gap-6". Inside this `<div>`, there is another `<div>` element with the class
 * name "w-full lg:grid lg:grid-rows-8 lg:grid-flow-col overflow-x-auto scrollbar". Inside this second
 * `<div>`,
 */
const IpoInvestment = (props) => {
    
    return (
        <div className="lg:grid lg:grid-rows-8 gap-6">
            <div className="w-full lg:grid lg:grid-rows-8 lg:grid-flow-col overflow-x-auto scrollbar ">
                <div className="row-span-3 px-5 ">
                    <div className=" ">
                        <p className="md:flex items-center ">
                            <span className="text-[14pt] leading-[19pt] text-[#333333]  lg:text-[17px] font-semibold  lg:leading-[22px] mr-1">
                                Investment Details
                            </span>

                        </p>
                    </div>
                    
                            <p className=" whitespace-pre-line px-2 py-3 text-[12pt] leading-[16pt] text-[#333333] lg:text-[14px] lg:leading-[16px] font-sans_book_body">
                                {props?.data ?? ""}
                            </p>
                       
                </div>
            </div>
        </div>
    );
};

export default IpoInvestment;
