import { React, useState,  useContext,  useRef } from "react";
import {  useParams, matchPath, useLocation } from "react-router-dom";
import ReactReadMoreReadLess from "react-read-more-read-less";
import compare from "../../images/compare.png";
import ComparePopUp from "../../Pages/ComparePopUp";
import { CompareCompanyContext } from "../../Contexts/CompareCompanyContext";
import AddNote from "../../Pages/addNote";
import {
  descriptionCharLimit,
  exportPdf,
} from "../../Config/config";
import CompanyLogo from "../CompanyLogo";
import RandomLogo from "../RandomLogo";
import AlertPopUp from "../DetailPage/AlertPopUp";
import PENotesPopup from "../../Components/NotesPopup/PENotesPopup";
import SendDealPopUp from "../SendDealPopUp";
import XLSPopup from "../DetailPage/DownloadXLS";
import PdfDownloader from "../shared/PdfDownloader";
import { RaiseRequestContext } from '../../Contexts/RaiseRequestContext'
import Constants from "../../Constants";
import { Tooltip } from "antd";
import { CurrencyContext } from '../../Contexts/CurrencyContext'
import routePathSegments from "../../routeSegments";
import AddNotePopover from "../shared/AddNotePopover";
import { UserContext } from "../../Contexts/UserContext";

//M&A Acquirer detail indfromation component
const AcquirerDetailInformation = (props) => {
  const {  setCompanyDetail, setCurrentPage } = useContext(CompareCompanyContext);
  const { currencyType,  currency,  isDetailed,  } = useContext(CurrencyContext);
  const { openRequestPopup } = useContext(RaiseRequestContext)
  console.log(props.id, "id")
  console.log(props, "dealpage");
  const [showAdd] = useState(false); 
  const { acquirerId } = useParams();
  let companyProfileId = parseInt(acquirerId);
  const [showModal, setShowModal] = useState(false);
  const [note, ] = useState(false);
  const location = useLocation();
  const {isStudent} = useContext(UserContext)
  const isPeMatch = matchPath(`${routePathSegments.pe}/*`, location.pathname)
  const tagsList = props?.data?.tags?.split(",").map((tag) => {
    const capital = tag.charAt(0).toUpperCase() + tag.slice(1);
    return (
      <li className="mt-2 lg:mt-0 nav-item flex active " role="presentation">
        {capital ? (
          <a className=" lg:block text-[10.7pt] leading-[17pt] lg:leading-[16px] font-sans_book_body px-3 mr-2 py-1 text-center text-[#333333] bg-[#EFEFEF] lg:text-[12px] rounded-[5px]">
            {capital}
          </a>
        ) : null}
      </li>
    );
  });
  
  const menuRef = useRef();
 
  /* The above code is assigning the value of `props?.data?.moreInformation` to the `longText` variable
  if `props?.page` is equal to "dealPage" and "madealpage". Otherwise, it assigns the value of
  `props?.data?.business_desc` to `longText`. The `descriptionCharLimit` variable is used to
  determine the length of the `longText` string. */
  let longText =
    props?.page == "dealPage" && props?.page == "madealpage"
      ? props?.data?.moreInformation
      : props?.data?.business_desc;
  const charLength = descriptionCharLimit;

 /* The above code is a conditional statement that determines the value of the `component` variable
 based on the value of the `props.activeTab` and `isPeMatch` variables. */
 
  const component = props?.activeTab == "investments" ? isPeMatch != null ? "moreInfoInvestment" : "moreDealVc"
    : props?.activeTab == "angels" ? "moreInfoAngel"
      : props?.activeTab == "incubations" ? "moreInfoIncubation"
        : props?.activeTab == "ipos" ? "moreInfoIncubation"
          : props?.activeTab == 'exits' ? "moreInfoIncubation"
            : isPeMatch != null ? "moreInfoInvestment" : "moreDealVc"



  return (
    <>
      <div>
        {/* <!-- BarRaiser--> */}
        <div className="pl-5 pr-3">
          <div className=" bg-[#FFFFFF]">
            <div className="lg:hidden mt-3  grid  md:grid-cols-8  grid-cols-10  items-center mb-4 ">
              <div className=" md:col-span-6 col-span-5">
                <Tooltip title="Compare Company" >
                  <button

                    className={
                      showModal
                        ? "bg-[#BC8B20] text-white text-[13px] py-1 lg:py-1 px-2 lg:px:4 border border-[#BC8B20] rounded-[4px] inline-flex items-center font-sans_book_body"
                        : "bg-[#FFFFFF] text-[#666666] text-[13px] py-1 lg:py-1 px-2 lg:px:4 border border-[#DDDDDD] rounded-[4px] inline-flex items-center font-sans_book_body"
                    }
                    type="button"
                    onClick={() => {
                      setShowModal(!showModal)
                      setCompanyDetail([])
                      setCurrentPage("acquirer")
                    }
                    }
                  >
                    <img

                      className={
                        showModal
                          ? "brightness-0 invert-[1] w-[20px] h-[18px] pr-1"
                          : "w-[20px] h-[18px] pr-1 blur-btn"
                      }
                      src={compare}
                      alt=""
                    />
                    <span>Compare</span>
                  </button>
                </Tooltip>
              </div>
              <div className="flex md:col-span-2 col-span-5 space-x-3">
              <div className="grid grid-cols-5  gap-3 md:gap-2 lg:gap-1  flex items-center">
                  <div className="col-span-1 ">
                    <AlertPopUp cid={props.id} type_id={1} />{" "}
                  </div>

                  <div className="col-span-1" ref={menuRef}>
                    <span>
                      <Tooltip title="Save List">
                        <AddNotePopover companyId={props.id} companyName={props?.data?.acquirer_name} type_id={Constants.moduleIds.acquirer_id} sub_type_master_id={Constants.subCategoryIds.ma_acquirer_id}/>
                      </Tooltip>                      
                    </span>
                  </div>
                  <div className="col-span-1 mt-1">
                    <PENotesPopup
                      note={note}
                      cid={props.id}
                      type_id={Constants.moduleIds.acquirer_id}
                    />
                  </div>
                  <div className="col-span-1">
                    <SendDealPopUp data={props?.data?.acquirer_name} />
                  </div>
                  {isStudent == 'true' ? <></> :
                  <div className="col-span-1">
                    {
                      props?.page == "dealPage" ? (
                        <XLSPopup activeTab={props?.activeTab} exportFileName={props.exportFileName} />
                      ) : (
                        <PdfDownloader
                          url={exportPdf}
                          fileName={props?.data?.acquirer_name?.replaceAll(
                            /\s/g,
                            ""
                          )}
                          data={{
                            entity_id: props.id,
                            type_id: Constants.moduleIds.acquirer_id,
                            is_detailed: isDetailed,
                            currency_cd: currencyType,
                            display_in: currency,
                            type: props.type == "pe" ? Constants.primaryMasterIds.peId : props.type == "vc" ? Constants.primaryMasterIds.vcId : props.type == "re" ? Constants.primaryMasterIds.reId :props.type == "ma" ? Constants.primaryMasterIds.mANDaId : props.type == "cfs" ? Constants.primaryMasterIds.cfsId : 6
                          }}
                        />
                      )
                      // <img src={pdf} className="h-[24px] mt-1 w-[21px]"/>
                    }
                  </div>
                  }
                </div>
              </div>
            </div>
            <ul role="list">
              {Object.keys(props?.data ?? {}).length > 0 ? (
                <>
                  <li className="w-full flex pt-6 bg-[#FFFFFF]">
                    <div className=" rounded-md">
                      {props?.page == "dealPage"  ?
                        props?.data?.icon_path == "default.png" || props?.data?.icon_path == "/default.png" || !props?.data?.icon_path ?
                          <RandomLogo name={props?.data?.companyname} page="detail page" />
                          :
                          <CompanyLogo name={props?.data?.companyname} id={props?.id} page="detail page" logo={props?.data?.icon_path} />

                        :
                        props?.data?.icon_path == "default.png" || props?.data?.icon_path == "/default.png" || !props?.data?.icon_path ?
                          <RandomLogo name={props?.data?.acquirer_name} page="detail page" />
                          :
                          <CompanyLogo name={props?.data?.acquirer_name} id={props?.id} page="detail page" logo={props?.data?.icon_path} />

                      }
                      

                    </div>

                    <div className="ml-3 flex flex-1 flex-col">

                      <p
                        className="text-[#333333] text-[22px] font-semibold leading-[24px]"

                      >
                        {" "}
                        {props?.page == "dealPage" ? props?.data?.companyname : props?.data?.acquirer_name}{" "}
                      </p>
                      {
                        props.page === "fillingPage"
                          ? props?.data?.acquirer_industry?.map(data => {
                            <span className=" text-[12pt] leading-[16pt] text-[#333333]  lg:text-[13px] lg:leading-[17px] font-sans_roman_body ">
                              {data.acquirer_industry}
                            </span>
                          })
                          : <span className=" text-[12pt] leading-[16pt] text-[#333333]  lg:text-[13px] lg:leading-[17px] font-sans_roman_body ">
                            {props.data?.acquirer_industry}
                          </span>
                      }

                      <span className="text-[11pt] leading-[15pt]  text-[#777777] lg:text-[13px] font-sans_book_body lg:leading-[21px]">
                        {props?.page == "dealPage" ? props?.data?.sector_business : props?.data?.sector}
                      </span>
                                         </div>

                    <div className="hidden lg:grid grid-cols-8 gap-2 ">
                      <div className="col-span-3 mt-2">
                        <Tooltip title="Compare Acquirer" >
                          <button

                            className={
                              showModal
                                ? "bg-[#BC8B20] text-white text-[13px] py-1 lg:py-1 px-2 lg:px:4 border border-[#BC8B20] rounded-[4px] inline-flex items-center font-sans_book_body"
                                : "bg-[#FFFFFF] text-[#666666] text-[13px] py-1 lg:py-1 px-2 lg:px:4 border border-[#DDDDDD] rounded-[4px] inline-flex items-center font-sans_book_body"
                            }
                            type="button"
                            onClick={() => {
                              setShowModal(!showModal)
                              setCompanyDetail([])
                              setCurrentPage("acquirer")
                            }
                            }
                          >
                            <img

                              className={
                                showModal
                                  ? "brightness-0 invert-[1] w-[20px] h-[18px] pr-1"
                                  : "w-[20px] h-[18px] pr-1 blur-btn"
                              }
                              src={compare}
                              alt=""
                            />
                            <span>Compare</span>
                          </button>
                        </Tooltip>
                      </div>
                      <div className="col-span-5 mt-[5px]">
                        <div className="grid grid-cols-5 gap-1 flex items-center">
                          <div className="col-span-1 ">
                            <AlertPopUp cid={props.id} type_id={Constants.moduleIds.acquirer_id} />{" "}
                          </div>

                          <div className="col-span-1" ref={menuRef}>
                            <span>

                              <AddNotePopover
                                title="Save List"
                                companyId={props.id}
                                companyName={props?.page == "dealPage" ? props?.data?.acquirer_name : props?.data?.acquirer_name}
                                type_id={Constants.moduleIds.acquirer_id} 
                                IsDetailed={true}
                                sub_type_master_id={Constants.subCategoryIds.ma_acquirer_id}
                                />
                             
                            </span>
                          </div>
                          <div className="col-span-1 mt-1">
                            <PENotesPopup
                              note={note}
                              cid={props.id}
                              type_id={Constants.moduleIds.acquirer_id}
                            />
                          </div>
                          <div className="col-span-1">
                            <SendDealPopUp data={props?.page == "dealPage" ? props?.data?.acquirer_name : props?.data?.acquirer_name} />
                          </div>
                          {isStudent == 'true' ? <></> :
                          <div className="col-span-1">
                            {
                               props?.page == "dealPage" ? (
                                <XLSPopup activeTab={props?.activeTab} exportFileName={props.exportFileName} />
                              ) : 
                              props?.page == "madealPage" ?
                              <XLSPopup activeTab={props?.activeTab} exportFileName={props.exportFileName} />

                              
                             : (
                                <PdfDownloader
                                  url={exportPdf}
                                  fileName={props?.page == "dealPage"
                                    ? props?.data?.acquirer_name?.replaceAll(/\s/g, "")
                                    : props?.data?.acquirer_name?.replaceAll(/\s/g, "")
                                  }
                                  data={{
                                    entity_id: props.id,
                                    type_id: Constants.moduleIds.acquirer_id,
                                    is_detailed: isDetailed,
                                    currency_cd: currencyType,
                                    display_in: currency,
                                    type: props.type == "pe" ? Constants.primaryMasterIds.peId : props.type == "vc" ? Constants.primaryMasterIds.vcId : props.type == "re" ? Constants.primaryMasterIds.reId :props.type == "ma" ? Constants.primaryMasterIds.mANDaId : props.type == "cfs" ? Constants.primaryMasterIds.cfsId : 6

                                  }}
                                />
                              )
                              // <img src={pdf} className="h-[24px] mt-1 w-[21px]"/>
                            }
                            {console.log(props,"props")}
                          </div>}
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>

                    {props?.data?.tags && props?.data?.tags != "" &&
                      <div className="bg-[#FFFFFF] mt-4">
                        <ul
                          className="nav nav-tabs flex flex-wrap  list-none border-b-0 pl-0 pb-2"
                          id="tabs-tab"
                          role="tablist"
                        >
                          {tagsList}
                        </ul>
                      </div>
                    }
                    <div className="">

                      {longText != "" ? (
                        <>
                          <p className="mt-3 whitespace-pre-line text-[11pt] leading-[18pt] py-1  lg:text-[14px] text-[#333333] lg:leading-[20px] font-sans_book_body">
                            {props?.activeTab == "investments" ||
                              props?.activeTab == "incubations" ||
                              props?.activeTab == "angels" ||
                              props?.activeTab == "exits" ? (
                              <p className="text-[11pt] leading-[18pt] py-2 lg:text-[14px] text-[#333333] lg:leading-[20px] font-sans_roman_body ">
                                Deal Details:
                              </p>
                            ) : props?.page == "companyPage" || props?.page == "reCompanyPage" ? (
                              " "
                            ) : (
                              <p className="text-[11pt] leading-[18pt] py-2 lg:text-[14px] text-[#333333] lg:leading-[20px] font-sans_roman_body ">
                                Ipo Structure:
                              </p>
                            )}
                            <ReactReadMoreReadLess
                              charLimit={charLength}
                              readMoreText={"View More "}
                              readLessText={"View Less "}
                              readMoreClassName="read-more-less--more lg:text-[13px]"
                              readLessClassName="read-more-less--less lg:text-[13px]"
                            >
                              {longText ?? ""}
                            </ReactReadMoreReadLess>
                            {props?.page == "companyPage"||props?.page == "reCompanyPage" ? (
                              " "
                            ) : (
                              <p className="mt-2">
                                {/* <button className='text-[#A5711A] hover:underline' onClick={()=>openRequestPopup(component)}>Click Here</button> */}
                                {props?.activeTab == "investments" ?
                                  <>
                                    <button className='text-[#A5711A] hover:underline' onClick={() => openRequestPopup(component)}>Request more information</button>&nbsp;
                                    specifying what details you would like. Note: For recent transactions, regulatory filing based information is typically less than that for older ones.
                                  </>
                                  : props?.activeTab == "incubations" || props?.activeTab == 'ipos' ?
                                    <>
                                      <button className='text-[#A5711A] hover:underline' onClick={() => openRequestPopup(component)}>Click Here</button>&nbsp;
                                      to request more details for this deal.
                                    </>
                                    : props?.activeTab == 'exits' ?
                                      <>
                                        <button className='text-[#A5711A] hover:underline' onClick={() => openRequestPopup(component)}>Request more information</button>&nbsp;
                                        specifying what details you would like.
                                      </>
                                      : props?.activeTab == "angels" ?
                                        <>
                                          <button className='text-[#A5711A] hover:underline' onClick={() => openRequestPopup(component)}>Click Here</button>&nbsp;
                                          to request more details (financials, valuations, etc.) to the extent available for this deal.
                                        </>
                                        :
                                        <>
                                          <button className='text-[#A5711A] hover:underline' onClick={() => openRequestPopup(component)}>Request more information</button>&nbsp;
                                          specifying what details you would like. Note: For recent transactions, regulatory filing based information is typically less than that for older ones.
                                        </>
                                }</p>
                            )}
                          </p>
                        </>
                      )
                        :
                        ""
                      }
                    </div>

                  </li>
                </>
              ) : (
                <div className="w-full bg-[#FFFFFF]">
                  <center>
                    <div role="status" className=" h-[8rem]">
                      <svg
                        className="relative top-[4rem] inline mr-2 w-12 h-12 text-gray-200 animate-spin dark:text-gray-600 fill-[#CA8A24]"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                    </div>
                  </center>
                </div>
              )}
              {showAdd ? <AddNote id={companyProfileId} /> : ""}
            </ul>
          </div>
          {/* <!-- tags--> */}

        </div>
      </div>
      {showModal ? <ComparePopUp id={1} onClose={(e) => setShowModal(false)} /> : null}
     
    </>
  );
};

export default AcquirerDetailInformation;
