import { Fragment, useState, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import edit from '../../images/pencil-icon.png'
import closeIcon from '../../images/search_close_button.png'
import { AiFillCloseCircle } from 'react-icons/ai'

// import downloadIcon from '../images/downloadIcon.jpeg'

/**
 * The `SavedFilterPopUp` component is a modal that allows users to upload a file or save a filter
 * request.
 * @returns The component returns a JSX structure that includes a modal popup for uploading files and a
 * dialog box for saving a filter request.
 */
const SavedFilterPopUp = ({ menu, onClose, editable, setFocus, editContent, inputEl, savedFilter, currentFilterRequest, setEditable, save, setSave }) => {
  console.log(currentFilterRequest, 'currentFilterRequest');
  const [open, setOpen] = useState(true)

  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState('');

  /**
   * The handleClick function is used to trigger a click event on a file input element.
   */
  const handleClick = () => {
    fileInputRef.current.click();
  };

  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  
  //   const allowedTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
  //   if (allowedTypes.includes(file.type)) {
     
  //     console.log(file);
  //     setFileName(file.name);
  //   } else {
  //     alert('Invalid file type. Only PDF, DOC, and DOCX files are allowed.');
  //   }
  // };

  /**
   * The function `handleFileChange` is used to handle a file change event and perform some action with
   * the selected file.
   * @param event - The event parameter is an object that represents the event that occurred. In this
   * case, it represents the change event that is triggered when a file is selected in an input element
   * of type "file".
   */
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    // Do something with the selected file
    console.log(file);
    setFileName(file.name);
  };

  return (
    <div>

      {open ?

        <div show={open} className='flex font-sans_book_body cursor-default justify-center items-center z-10 fixed left-0 top-0 w-screen h-screen  bg-gray-500 bg-opacity-75 transition-opacity'>
          <div className='flex flex-col w-[51%] md:w-[28%] h-[30%] bg-white rounded-lg overflow-hidden'>
            <div className='w-full flex flex-col '>
              <div className="px-4 bg-[#BC8B20]  flex items-center justify-start  w-full h-12">
                <div className="w-full px-5 md:px-0 py-1 text-lg font-medium font-sans_book_body text-[18px] leading-6 text-[#FFFFFF]">Upload File</div>
                <AiFillCloseCircle className='cursor-pointer mt-1' size={24} color='white' onClick={onClose} />
              </div>

              {/* <div className='flex justify-center mt-7'>
                <input
                  type="file"
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                  onChange={handleFileChange}
                />
                <button className='my-3 flex items-center gap-2 font-sans_medium_body bg-[#BC8B20] px-2 py-1 text-base text-white shadow-sm hover:bg-[#a37517] rounded-md' onClick={handleClick}>Upload File</button>
              </div> */}

              <div className='flex justify-center mt-[3.5rem]'>
                <input
                  type="file"
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                  accept=".pdf,.doc,.docx"
                  onChange={handleFileChange}
                />
                <button className='my-3 flex items-center gap-2 font-sans_medium_body bg-[#BC8B20] px-4 py-1 text-base text-white shadow-sm hover:bg-[#a37517] rounded-md' onClick={handleClick}>Upload File</button>
               
              </div>
              {fileName && <p className='flex justify-center mt-3 text-[15px] text-[#2B2A29] font-sans_book_body opacity-[.8] '>Selected file: {fileName}</p>}
            </div>
          </div>
        </div>




        :

        <Transition.Root show={save} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={setSave}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="items-center flex min-h-full  justify-center p-4 text-center ">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <div className="absolute top-0 right-0  pt-4 pr-4 ">
                      <button
                        type="button"
                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none  "
                        onClick={() => { setSave(false); setEditable(false) }}
                      >
                        <span className="sr-only">Close</span>
                        {/* <XMarkIcon className="h-6 w-6" aria-hidden="true" /> */}
                        <img src={closeIcon} className="w-8 h-8 " />
                      </button>
                    </div>
                    {/* {!show && <div> */}
                    <div className='text-left px-5 md:px-0 md:text-center font-[Sequel Sans] font-Medium'>
                      Save Filter Request
                    </div>
                    <div className="">
                      <div className="mt-3  lg:mt-0 lg:ml-4 lg:text-left">
                        <div className="flex w-full items-center py-2">
                          <input className={" text-[#BC8B20] text-[14px] font-sans_RomanBody leading-[17px] border-none p-2 focus:outline-none  w-[85%]" + (editable ? "cursor-text" : '')} contentEditable={editable} onChange={editContent} value={currentFilterRequest} ref={inputEl} />
                          {!editable ? <img alt='' className="ml-2 h-[14px] cursor-pointer" src={edit} onClick={setFocus} /> : ''}
                        </div>
                      </div>
                      <center>
                        <button className="rounded-[4px] px-4 py-1 text-[13px] text-[#FFFFFF] bg-[#BC8B20]" onClick={savedFilter}>Save</button>
                      </center>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>


      }
    </div>
  )
}

export default SavedFilterPopUp