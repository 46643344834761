import React from 'react'
import ReactReadMoreReadLess from "react-read-more-read-less";
import { descriptionCharLimit } from "../../../Config/config";

const screenWidth= window.innerWidth;
/* The `charLength` variable is determining the character limit for the ReactReadMoreReadLess component
based on the screen width. It uses a conditional (ternary) operator to set different character
limits for different screen widths. If the screen width is 1213, the character limit is set to 575.
If the screen width is greater than 1250, the character limit is set to 665. If the screen width is
greater than or equal to 1400, the character limit is set to 700. If the screen width is greater
than or equal to 1600, the character limit is set to 830. Otherwise, the character limit is set to
the value of `descriptionCharLimit`. */
const charLength =  screenWidth == 1213 ? '575'
    : screenWidth > 1250 ? '665'
    : screenWidth >= 1400 ? '700'
    : screenWidth >= 1600 ? '830'
    : descriptionCharLimit;
/**
 * The InvestmentExit function is a React component that renders investment details, including a
 * conditional rendering for when there is no data available.
 * @param props - The `props` parameter is an object that contains the data and other properties passed
 * to the `InvestmentExit` component. It is used to access the data and render it in the component.
 * @returns The InvestmentExit component is returning a JSX element.
 */
const InvestmentExit = (props) => {
    return (
        <div className="w-full  bg-[#FFFFFF] lg:col-span-4 mt-5 lg:mt-0 overflow-x-auto space-x-5 scrollbar shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] rounded-[4px]">
            <div className="lg:grid lg:grid-rows-9 max-h-[13rem]">
                <div className="px-6">
                    <p className="text-[14pt] leading-[19pt] text-[#333333] lg:text-[17px] font-semibold  lg:leading-[22px]">
                        Investment details</p>
                </div>
                <div className="w-full ">
                    {props?.data ?
                        props?.data?.investment_details == 0 ?
                            <center><div className="bg-[#F3F3F3] p-3 h-[2.5rem] text-[14px] leading-[16px] font-sans_book_body text-[#333333] relative mt-[1rem] ">No data available</div></center>
                            :
                            <div className="grid grid-cols-1 gap-4 text-[12pt] leading-[16pt] text-[#333333] lg:text-[14px] lg:leading[16px] font-sans_book_body ">
                                <div className="px-7 lg:leading-[21px] py-2 pr-3.5">
                                    {/* {props?.data == 0 ? "--" : props?.data? props?.data: "--"} */}
                                    <ReactReadMoreReadLess
                                        charLimit={charLength}
                                        readMoreText={"View More "}
                                        readLessText={"View Less "}
                                        readMoreClassName="read-more-less--more lg:text-[13px]"
                                        readLessClassName="read-more-less--less lg:text-[13px]"
                                    >
                                        {props?.data == 0 ? "--" : props?.data? props?.data: "--"}
                                    </ReactReadMoreReadLess>
                                </div>
                            </div>
                        :
                        <div className="w-full bg-[#FFFFFF]">
                            <center><div role="status" className=" h-[8rem]">
                                <svg className="relative top-[4rem] inline mr-2 w-12 h-12 text-gray-200 animate-spin dark:text-gray-600 fill-[#CA8A24]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                </svg>
                            </div></center>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default InvestmentExit
