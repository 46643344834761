import {React, useState, createContext} from 'react'

export const HeaderMenuContext = createContext('');

/**
 * The `HeaderMenuProvider` component is a context provider that sets up the header menu data and other
 * state values for its children components.
 * @returns The `HeaderMenuProvider` component is being returned.
 */
const HeaderMenuProvider = ({children}) => {
    const [PEHeaderMenu, setPEHeaderMenu] = useState(
        [
            {
                id:1,
                name:"Companies",
                path:"/pe"
            },
            {
                id:2,
                name:"Investors",
                path:"/investors/pe-vc-investments"
            },
            {
                id:4,
                name:"Legal Advisors",
                path:"/advisor"
            },
            {
                id:5,
                name:"Transaction Advisors",
                path:"/others"
            },
            {
                id:5,
                name:"Others",
                path:"/others"
            },
        ]
    );

    const [InvAdvHeaderMenu, setInvAdvHeaderMenu] = useState(
        [
            {
                id:1,
                name:"Companies",
                path:"/pe"
            },
            {
                id:2,
                name:"Investors",
                path:"/investors/pe-vc-investments"
            },
            {
                id:3,
                name:"Acquirers",
                path:"/acquirer"
            }
        ]
    );

    const [notificationCount, setNotificationCount] = useState()

    const [searchText, setSearchText] = useState("");
    const headerMenu = {PEHeaderMenu, InvAdvHeaderMenu, notificationCount, setNotificationCount,searchText,setSearchText }


    return (
        <HeaderMenuContext.Provider value={headerMenu}>
            {children}
        </HeaderMenuContext.Provider>
    )
}

export default HeaderMenuProvider
