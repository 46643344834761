import React, { useState } from 'react'
import { descriptionCharLimit } from '../../Config/config';

function ReadMoreReadLess({ text = "", charLimit = descriptionCharLimit, className, ...props }) {
    const [showFullDescription, setFullDescription] = useState(false);

/**
 * The `showFullDescriptionHandler` function toggles the value of `showFullDescription` between true
 * and false.
 */
    const showFullDescriptionHandler = () => {
        setFullDescription(!showFullDescription);
    };

    const description = showFullDescription
        ? text
        : text.slice(0, charLimit);

    const showButton = text?.length > charLimit

    return <p className='className font-sans_book_body text-[14px]' {...props}>
        {description} {showButton ? <button role="presentation" className='whitespace-nowrap lg:text-[13px] text-primaryColor' onClick={showFullDescriptionHandler}>
            {showFullDescription ? "View Less" : "View More"}
        </button>
            : null}
    </p>
}

export default ReadMoreReadLess