import React from 'react'
import { useLocation } from 'react-router-dom'
import Card from '../../Components/Investor/InvestorPageCard/Card'
import Layout from '../../Components/InvestorLayout'
import MainMenu from '../../Components/VC/InvestorVcLimitedPartnerMainmenu'
// import RefineSearch from '../../Components/RefineSearch'
import Table from '../../Components/InvestorLimitedPartner/TableComponent'
import FilterTag from '../../Components/Tag'
import RefineSearch from '../../Components/Investor/RefineSearchComponent'
import {INVESTOR_TABLE_QUERY} from '../../Queries/Pe';
import ChatIcon from '../../images/Group 13/png/Group 13.png';
import chatPopupIcon from '../../images/chatPopupIcon.png'
import ChatPopup from '../../Components/ChatPopup'
import TopSection from '../../Components/InvestorLimitedpartnerDetail/TopSection'
import LimitedPartBreadCrum from './LimitedPartBreadCrum'
import Constants from '../../Constants'

/**
 * The function `InvestorLimitedPartnerExitsPmVc` is a React component that renders a page for
 * displaying limited partner exits in the venture capital industry.
 * @returns The InvestorLimitedPartnerExitsPmVc component is being returned.
 */
const InvestorLimitedPartnerExitsPmVc = () => { 
    const [showModal, setShowModal] = React.useState(false);
    const location = useLocation();       
    return (
    <div>
        <>
            <Layout>
                <div className="flex h-screen flex-col ">
                    {/* Top nav*/}
                  {/* <div className='sm:full mt-[3rem] pt-2 h-full bg-[#EDEDED]'> */}
                       {/* <div className='border-b-2 border-[#e5e5e5]'></div> */}
                       {/* <TopSection/> */}
                    {/* <Card card1={window.location.pathname ?(window.location.pathname !=('/investors/angel-invesments')|| ('/investors/incubation')? 'PE-VC Investors':''):''} card2={window.location.pathname ?(window.location.pathname !=('/investors/angel-invesments')|| ('/investors/incubation')? 'PE-VC Invesstments':''):''}/> */}
                    {/* Bottom section */}
                    <div className="flex min-h-0 flex-1 overflow-hidden ">
                        {/* Narrow sidebar*/}
                        {/* Main area */}
                        <main className="min-w-0 flex-1 border-t border-gray-200 lg:flex sm:full mt-[4.25rem] md:mt-[3rem]">
                            {/* Primary column */}
                            <section
                                className="flex h-full mt-3 min-w-0 flex-1 flex-col  lg:order-last bg-[#EDEDED]"
                            >
                                {/* Your content */}
                                {/* <FilterTag />                                 */}
                                <LimitedPartBreadCrum  menu = "investorvc" subMenu="vc" />
                                    <MainMenu active={2} menu="public_vc" primaryTypeMasterId={Constants.primaryMasterIds.vcId}
                                subTypeMasterId={Constants.primaryMasterSubIds.vc_pm_limited_partners_id}
                                typeId={Constants.moduleIds.investorsId}
                                exportFileName={Constants.exportNames.vc_limited_partner_exit_pm}/>
                                    <div className="overflow-y-auto">
                                        <Table menu="exit-pm-vc"/> 
                                    </div>                                
                            </section>
                            {/* Secondary column (hidden on smaller screens) */}
                            <aside className="hidden lg:order-first lg:block lg:flex-shrink-0">
                                <div className="relative flex h-full  flex-col overflow-y-auto border-r border-gray-200 bg-[#FAF5EA]">
                                    {/* Your content */}
                     
                                    {/* <RefineSearch /> */}
                                </div>
                            </aside>
                        </main>
                    </div>                    
                </div>
            </Layout>
        </>
    </div>
    )
}

export default InvestorLimitedPartnerExitsPmVc