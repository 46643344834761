import { React, useState, useMemo, useContext } from 'react'
import Table from './Table'
import { TableHeaderContext } from '../../Contexts/TableHeaderContext'
/**
 * The TableComponent function renders a table component with specified columns, ordering, menu, and
 * pagination.
 * @param props - The `props` parameter is an object that contains the properties passed to the
 * `TableComponent` component. These properties can be accessed using dot notation, for example
 * `props.menu` would give you the value of the `menu` property passed to the component.
 * @returns The TableComponent is returning a div element with the className "pl-3 pr-2" and another
 * div element with the className "App". Inside the second div, there is a Table component being
 * rendered with the following props: order, columns, orderBy, menu, and pagination.
 */

const TableComponent = (props) => {

    const { maAcquirerTableHeader, orderBy, maAcquirerOrderingColumn } = useContext(TableHeaderContext);
    const [pagination, setPagination] = useState()
    const paginationData = useMemo(() => pagination, pagination);

    const columns = maAcquirerTableHeader

    return (
        <div className="pl-3 pr-2">
            <div className="App ">
                <Table order="asc" columns={columns}  orderBy={maAcquirerOrderingColumn} menu={props?.menu} pagination={paginationData} sub_type_master_id={props?.sub_type_master_id}/>
            </div>
        </div>
    )
}

export default TableComponent





