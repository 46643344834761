import React, {useMemo, useState, useContext} from 'react'
import Table from './T_Tabel'

import { TableHeaderContext } from '../../Contexts/TableHeaderContext'

 

/**
 * The `TableComponent` is a React component that renders a table based on the values from the
 * `TableHeaderContext` and the `props` passed to it.
 * @returns The `TableComponent` is returning a JSX element. It is rendering a `div` with the class
 * name "pl-3 pr-2" and another `div` with the class name "App". Inside the second `div`, it is
 * rendering a `Table` component with various props such as `order`, `orderBy`, `menu`, `columns`,
 * `advisor_type`, `pagination`,
 */
const TableComponent = (props) => {
 /* The code is using the `useContext` hook to access the values from the `TableHeaderContext`. It is
 destructuring the values from the context and assigning them to the corresponding variables. The
 variables `AdvisorPEVCTableHeader`, `AdvisorVCTableHeader`, `AdvisorPEVCExitMATableHeader`,
 `AdvisorPEVCExitPMTableHeader`, `AdvisorVCExitMATableHeader`, `AdvisorVCExitPMTableHeader`, and
 `advisororderingColumn` will hold the values from the context. */
  const { AdvisorPEVCTableHeader,
          AdvisorVCTableHeader, 
          AdvisorPEVCExitMATableHeader, 
          AdvisorPEVCExitPMTableHeader,
          AdvisorVCExitMATableHeader,
          AdvisorVCExitPMTableHeader,
          advisororderingColumn, 
        }
   = useContext(TableHeaderContext);

    const [investments,
        //  setInvestments
        ] = useState([]);
    const [pagination,
        //  setPagination
        ] = useState()

    // const tableRowData = useMemo(() => investments, [investments]);  
    const paginationData = useMemo(() => pagination, pagination);   
  
    const columns = props.menu === "pe_vc_advisor" ? AdvisorPEVCTableHeader
          :props.menu === "vc_advisor" ? AdvisorVCTableHeader
          :props.menu === "pe_vc_ma_advisor" ? AdvisorPEVCExitMATableHeader
          :props.menu === "pe_vc_pm_advisor" || props.menu === "pe_vc_exit" ? AdvisorPEVCExitPMTableHeader 
          :props.menu === "vc_ma_advisor" || props.menu === "vc_exit" ? AdvisorVCExitMATableHeader 
          :props.menu === "vc_pm_advisor" ?AdvisorVCExitPMTableHeader
          : AdvisorPEVCTableHeader
    return (
        <div className="pl-3 pr-2">
            <div className="App ">
            <Table order="asc" orderBy={advisororderingColumn} menu={props?.menu} columns={columns} advisor_type={props?.advisor_type} pagination = {paginationData} sub_type_master_id={props?.sub_type_master_id}/>
            </div>
        </div>
    )
}
export default TableComponent