import { useState, useContext } from "react";
import { Switch } from "@headlessui/react";
import { TableDataContext } from '../../../../Contexts/TableDataContext'

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

 /* The code snippet is a React functional component that renders a switch toggle between two
 currencies, INR and USD. */
export default function Example() {
  const { currency, setCurrency } = useContext(TableDataContext);
  const [enabled, setEnabled] = useState(currency === "USD");

/**
 * The function `onEnable` toggles the value of `enabled` and sets the currency to either 'INR' or
 * 'USD' based on the new value of `enabled`.
 */
  const onEnable = () => {
    setEnabled(!enabled)
    setCurrency(enabled ? 'INR' : 'USD')
  }

  return (
    <>
      <div className="space-x-3 lg:pt-[3px] flex items-center mx-1 md:mx-2 lg:mx-2">
        <a className="text-[12pt] leading-[23pt] font-bold lg:text-[17px] lg:leading-[23px] tracking-[-0.3px] text-[#A5711A] ">
          INR
        </a>
        
          <Switch
            checked={enabled}
            onChange={onEnable}
            className={classNames(
              enabled ? "bg-[#BC8B20] focus:ring-offset-2 " : "bg-[#BC8B20] focus:outline-none",
              " inline-flex h-5 w-10 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out "
            )}
          >
            <span className="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              className={classNames(
                enabled ? "translate-x-5" : "translate-x-0 ",
                "pointer-events-none inline-block h-[1rem] 2xl:h-[1rem] md:h-4 lg:h-4 w-4 transform rounded-full bg-[#FFFFFF]  ring-0 transition duration-200 ease-in-out"
              )}
            />
          </Switch>
       
        <a className="text-[12pt] leading-[23pt] font-bold lg:text-[17px] lg:leading-[23px] tracking-[-0.3px] text-[#A5711A] ">
          USD
        </a>
      </div>
    </>
  );
}
