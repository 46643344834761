import React from "react";
import Requests from "../../Components/Requests/Requests";
// import BreadCrum from "../Investor/LimitedPartBreadCrum";

/**
 * The RequestsPage function returns a React component that renders a page with a main section
 * containing a BreadCrum component, a MainMenu component, and a Requests component.
 * @returns The function `RequestsPage` is returning a JSX element.
 */
function RequestsPage() {
  return (
 
   <>
    <div className="flex h-screen flex-col  ">
                        <div className="flex min-h-0 flex-1 overflow-hidden">
                            <main className="min-w-0 flex-1 border-t border-gray-200 lg:flex sm:full mt-[4.25rem] md:mt-[3rem]">
                                <section
                                    className="flex h-full min-w-0 flex-1 flex-col mt-3 px-3 lg:order-last bg-[#EDEDED]"
                                >    
                                    {/* <BreadCrum menu="requests" subMenu="requests" /> */}
                                    {/* <MainMenu active={1} menu="investor" subMenu="investor"  primaryTypeMasterId={Constants.primaryMasterIds.peId}
                                        subTypeMasterId={Constants.primaryMasterSubIds.pe_vc_limited_partners_id}
                                        typeId={Constants.moduleIds.investorsId} /> */}
                                    <div className="overflow-y-auto">
                                       <Requests /> 
                                    </div>
                                </section>
                            </main>
                        </div>
                        <br />
                    </div>
   </>
  );
}

export default RequestsPage;
