import React, { useContext } from 'react'
import { Navigate, useLocation } from 'react-router-dom';
import { UserContext } from '../../Contexts/UserContext';
import { routeNames } from '../../routeSegments';

/**
 * The ProtectedRoute function is a component that restrict already logged in user to visit some pages.
 */
function ProtectedRoute({children}) {
    const { isLoggedIn } = useContext(UserContext);
    // const location = useLocation();
    if (isLoggedIn()) {
        // not logged in so redirect to login page with the return url
        return <Navigate to={routeNames.PE_company} />
    }
    // if(location.pathname === "/pe" ){
    //   return <Navigate to="/companies/pe-vc-investments" />
    // }
    // authorized so return child components
    return children;
}

export default ProtectedRoute