import React, {useState, useMemo} from 'react'
import { useQuery } from '@apollo/client'
import tfs_Icon from '../../../images/Shape_4/png/Shape.png';

/**
 * The above function is a React component that renders a card with a "Follow on %" value and an icon.
 * @returns The code is returning a JSX element that represents a card component. The card displays the
 * "Follow on %" value, which is set to 30 in this example. It also includes an image that is wrapped
 * in a link.
 */
function FollowOn({TOTAL_FUNDING_USD_CARD_QUERY}) {
    return (
        <div className="flex-shrink-0">
            {/* ----card2----- */}
            <section>
                <div className=" bg-white px-3 py-1 rounded-md  border border-gray-200 overflow-hidden card-animate card-animate">
                    <div className="grid grid-cols-6 ">
                        <div className="col-span-5">
                            <div className="text-[13px]  leading-[17px] font-sans_book_body tracking-[-0.3px]">Follow on %</div>
                            <div className="flex space-x-1  tracking-[-0.3px]">
                                <div className="  ">
                                    <span
                                        className=' leading-[23px] font-sans_bold_body text-[17px] text-[#A5711A] '>30</span>
                                </div>
                            </div>
                        </div>
                            <div className="flex-shrink-0 flex items-center justify-center">
                                <a href="#">
                                    <img className="h-[24px] max-w-[25px]"
                                        src={tfs_Icon} />
                                </a>
                            </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default FollowOn
