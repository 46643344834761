import React, { useState, useEffect } from 'react'
import Paginate from '../Paginate';
import Loader from '../Loader';
import axios from 'axios';
import { getNotification } from '../../Config/config';
import { useContext } from 'react';
import { UserContext } from '../../Contexts/UserContext';
import Constants from '../../Constants';
import { useMemo } from 'react';
import moment from "moment"
import SortAscendingIcon from 'mdi-react/SortAscendingIcon';
import SortDescendingIcon from 'mdi-react/SortDescendingIcon';
import SortIcon from 'mdi-react/SortIcon';
import { useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import RandomLogo from '../RandomLogo';
import CompanyLogo from '../CompanyLogo';
import OverflowToolTip from '../shared/OverflowToolTip';
import { Link } from 'react-router-dom';
import { routeNames } from '../../routeSegments';
import {AiFillEye} from "react-icons/ai"
import {TbFileDownload} from "react-icons/tb"
import { Tooltip } from 'antd';

/* The above code is a React component called "Notifications" that displays a table of notifications.
It fetches the notifications from an API using axios and displays them in a table format. The
component accepts two props: "notificationType" and "isRead" to filter the notifications based on
their type and read status. The table has columns for the entity, notification type, message, date,
and action. The data for the table is fetched from the API and stored in the "notifications" state
variable. The table is rendered using the react-table library, which provides functionality for
sorting, filtering, and */
function Notifications({ notificationType, isRead }) {

    const { getToken, logout } = useContext(UserContext);
    const [notifications, setNotifications] = useState(null)
    const [isLoading, setIsLoading] = useState(true)


    useEffect(() => {
        setIsLoading(true)
        axios(`${getNotification}`, {
            method: "POST",
            data: {},
            headers: {
                "Content-Type": "application/json",
                Authorization: getToken(),
            },
        })
            .then((response) => {
                setIsLoading(false)
                console.log(response, "Notification Response");
                setNotifications(response.data)

            })
            .catch((error) => {
                setIsLoading(false)
                if (error?.response?.status == Constants.sessionTerminatedStatus) {
                    logout();
                }
                console.log("API not working", error);
            });
    }, [getToken, logout]);


  /* The above code is defining a set of columns for a table in a React component. The columns are used
  to display data in each column of the table. */
    const columns = useMemo(() => [
        {
            Header: "Entity",
            accessor: "company",
            className: "w-[20%]",
            Cell: ({ value }) => {
                return <span className="leading-[2rem] font-sans_book_body items-center justify-center">
                    {value?.icon_path == "default.png" || value?.icon_path == "/default.png" || !value?.icon_path ?
                        <RandomLogo name={value?.name} />
                        :
                        <CompanyLogo name={value?.name} id={value.id} page="company page" logo={value?.icon_path} />
                    }
                    <OverflowToolTip component={
                        <Link rel="noopener noreferrer" className='hover:text-[#BC8B20]'
                            to={routeNames.maCompanyDealById(value.id, value.dealId)}
                        >
                            {value.name ?? "--"}
                        </Link>} />
                </span>
            },
        },
        ...notificationType === "alert"
            ? [{
                Header: "Notification Type",
                accessor: "type",
            }]
            : [],
        {
            Header: "Message",
            accessor: "message",
            Cell: ({ value }) => {
                return value ? <OverflowToolTip className="w-full" component={value} /> : "--"
            }
        },

        {
            Header: "Date",
            accessor: "date",
        },
        {
            Header: "Action",
            accessor: "action",
            disableSortBy: true,
            Cell:({value})=>{
                if(notificationType === "general"){
                    return <Tooltip placement='topLeft' title={`Download ${value.tooltip}`}>
                        <TbFileDownload color='#BC8B20' size={22}/>
                    </Tooltip>
                }
                return <AiFillEye color='#BC8B20' size={22}/>
            }
        }
    ], [notificationType])

   /* The `data` variable is using the `useMemo` hook to memoize the data for the table. It is creating
   an array of objects based on the notifications fetched from the API. Each object in the array
   represents a row in the table and contains properties for the company, notification type,
   message, date, and action. The values for these properties are extracted from the `notifications`
   object based on the `notificationType` and `isRead` props. The `useMemo` hook ensures that the
   data is only recalculated when the `isRead`, `notificationType`, or `notifications` variables
   change. */
    const data = useMemo(() => {
        let row = [];
        notifications?.[notificationType]?.[isRead ? "read" : "unread"]?.forEach(notification => {
            row.push({
                company: { name: notification?.entity_name, id: notification?.entity_id, icon_path: notification?.entity_logo },
                type: notification?.notification_type,
                message: notification?.message,
                date: notification?.date,
                action:{tooltip:notification?.file_name}
            });
        })
        return row;
    }, [isRead, notificationType, notifications])


  /* The code `const tableInstance = useTable({...})` is using the `useTable` hook from the
  `react-table` library to create an instance of a table. */
    const tableInstance = useTable({
        columns,
        data,
        disableSortRemove: true,
        initialState: { pageSize: 10, pageIndex: 0 },
    },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination,
    );

  /* The code is using destructuring assignment to extract specific properties from the `tableInstance`
  object returned by the `useTable` hook. */
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        page,
        prepareRow,
        gotoPage,
        state: { pageIndex, pageSize }
    } = tableInstance;



    return (
        <>
            <div className='overflow-auto scrollbar'>
                <table className="w-full " {...getTableProps()}>
                    <thead className="text-[12pt] leading-[15pt] bg-[#EDEDED] lg:text-[12px] lg:leading-[15px] text-[#666666] font-sans_semi_bold py-4 px-0 text-center ">
                        {
                            headerGroups.map(headerGroup => (

                                <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                                    {
                                        headerGroup.headers.map(column => (

                                            <th {...column.getHeaderProps([
                                                {
                                                    className: `py-3.5 px-5 whitespace-nowrap ${column.headerClassName}`,
                                                    style: column.headerStyle,
                                                },
                                                column.getSortByToggleProps(),
                                            ])}>
                                                <div className="flex">
                                                    <span className="mr-1">{column.render('Header')}</span>
                                                    {column.disableSortBy
                                                    ? null
                                                    : column.isSorted
                                                        ? column.isSortedDesc
                                                            ? <SortAscendingIcon size={16} className="mt-[0.15rem] lg:mt-0" />
                                                            : <SortDescendingIcon size={16} className="mt-[0.15rem] lg:mt-0" />
                                                        : <SortIcon size={16} className="mt-[0.15rem] lg:mt-0" />}
                                                </div>
                                            </th>
                                        ))
                                    }
                                </tr>
                            ))
                        }
                    </thead>


                    <tbody {...getTableBodyProps()} className="text-[12pt] leading-[16pt] lg:text-[14px] font-sans_book_body text-left">

                        {page.map(row => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()} className="border-b">
                                    {
                                        row.cells.map(cell => {

                                            return (
                                                <td {...cell.getCellProps([
                                                    {
                                                        className: `whitespace-nowrap px-5 py-4 ${cell.column.className}`,
                                                        style: cell.column.style,
                                                    },
                                                ])}>
                                                    {
                                                        cell.value ? cell.render('Cell') : "--"
                                                    }
                                                </td>
                                            )
                                        })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                {page?.length === 0
                    ? <div className="w-full h-40 flex items-center justify-center">
                        {isLoading ? <Loader /> : "No data found"}
                    </div>
                    : <></>
                }
            </div>
            {rows?.length <= 5
                ? <></>
                : <Paginate
                    currentPage={pageIndex + 1}
                    totalCount={rows?.length}
                    pageSize={pageSize}
                    onPageChange={(page) => {
                        gotoPage(page - 1);
                    }}
                />}
        </>
    )
}

export default Notifications