import React from 'react'
import Layout from '../../Components/InvestorLayout'
import MainMenu from '../../Components/Investor/LimitedPartnerMainmenu'
// import RefineSearch from '../../Components/RefineSearch'
import Table from '../../Components/InvestorLimitedPartner/TableComponent'
import FilterTag from "../../Components/Tag";
import LimitedPartBreadCrum from './LimitedPartBreadCrum'
import Constants from '../../Constants'

/**
 * The `InvestorLimitedPartner` function returns a React component that renders a layout with a top
 * navigation bar, a sidebar, and a main section with a table of limited partner investments.
 * @returns The InvestorLimitedPartner component is returning a JSX element.
 */
const InvestorLimitedPartner = () => {
    return (
        <div>
            <>
                <Layout>
                    <div className="flex h-screen flex-col  ">
                        {/* Top nav*/}
                        {/* <div className='sm:full mt-[3rem] pt-2 h-full bg-[#EDEDED]'> */}
                        {/* <div className='border-b-2 border-[#e5e5e5]'></div> */}
                        {/* <TopSection /> */}
                        {/* <Card card1={window.location.pathname ?(window.location.pathname !=('/investors/angel-invesments')|| ('/investors/incubation')? 'PE-VC Investors':''):''} card2={window.location.pathname ?(window.location.pathname !=('/investors/angel-invesments')|| ('/investors/incubation')? 'PE-VC Invesstments':''):''}/> */}
                        {/* Bottom section */}
                        <div className="flex min-h-0 flex-1 overflow-hidden">
                            {/* Narrow sidebar*/}
                            {/* Main area */}
                            <main className="min-w-0 flex-1 border-t border-gray-200 lg:flex sm:full mt-[4.25rem] md:mt-[3rem]">
                                {/* Primary column */}
                                <section
                                    className="flex h-full min-w-0 flex-1 flex-col mt-3  lg:order-last bg-[#EDEDED]"
                                >
                                    {/* Your content */}
                                    <FilterTag  page="inv_lp"/>                                
                                    <LimitedPartBreadCrum subMenu="vc" />
                                    <MainMenu active={1} menu="investor" subMenu="investor"  primaryTypeMasterId={Constants.primaryMasterIds.peId}
                                        subTypeMasterId={Constants.primaryMasterSubIds.pe_vc_limited_partners_id}
                                        typeId={Constants.moduleIds.investorsId} 
                                        exportFileName={Constants.exportNames.pe_limited_partner_investment}/>
                                    <div className="overflow-y-auto">
                                        <Table menu="inv_l_partner" sub_type_master_id={Constants.subCategoryIds.pe_lp_investments_id}/>
                                    </div>
                                </section>                                
                            </main>
                        </div>
                       
                    </div>
                </Layout>
            </>
        </div>
    )
}

export default InvestorLimitedPartner