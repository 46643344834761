import React, { useContext } from 'react'
import { useEffect } from 'react';
import { PeFilterContext } from '../../Contexts/PeFilterContext'

/**
 * The AcquirerDealRangeFilter function is a React component that renders a filter for selecting a deal
 * range in US$ M, with minimum and maximum options.
 * @returns The AcquirerDealRangeFilter component is returning a JSX element that renders a filter for
 * selecting a deal range. It includes two select dropdowns for selecting the minimum and maximum deal
 * range values, and also displays the selected values.
 */
function AcquirerDealRangeFilter({ MinDealOptions,
    MaxDealOptions,
    setMinDealOptions,
    setMaxDealOptions,
    customPathname,
    label = "Deal Range (US$ M)",
    MinDealRange = [],
    MaxDealrange = [],
    dataPath="dealRange"
}) {
    const { refineSearchFilters } = useContext(PeFilterContext);

    useEffect(() => {
        const min = refineSearchFilters[customPathname]?.[dataPath]?.at(0)
        const max = refineSearchFilters[customPathname]?.[dataPath]?.at(1)
        setMinDealOptions(MinDealRange?.map(option => ({ ...option, isChecked: min?.name == option.name })))
        setMaxDealOptions(MaxDealrange?.map(option => ({ ...option, isChecked: max?.name == option.name })))
    }, [refineSearchFilters, customPathname, setMinDealOptions, setMaxDealOptions, MinDealRange, MaxDealrange,dataPath])

    const selectedMin = MinDealOptions?.filter(option => option.isChecked)?.at(0)
    const selectedMax = MaxDealOptions?.filter(option => option.isChecked)?.at(0)


  /**
   * The function `handleMinRange` checks if the selected minimum range is greater than the selected
   * maximum range and updates the minimum deal options accordingly.
   * @param e - The parameter `e` is an event object that is passed to the `handleMinRange` function.
   * It represents the event that triggered the function, such as a change event on an input element.
   */
    const handleMinRange = (e) => {
        const { value } = e.target;
        if(selectedMax){
            if (parseInt(value) > parseInt(selectedMax?.name ?? 0)) {
            alert('Min deal dange is not greater than max deal range')
            }
            else {
                setMinDealOptions(MinDealOptions?.map(type => ({ ...type, isChecked: value === "" ? false : type.name === value })))
            }
        } 
        else {
            setMinDealOptions(MinDealOptions?.map(type => ({ ...type, isChecked: value === "" ? false : type.name === value })))
        }
    }

  /**
   * The function `handleMaxRange` checks if the selected maximum deal range is not less than the
   * minimum deal range and updates the maximum deal options accordingly.
   * @param e - The parameter `e` is an event object that is passed to the `handleMaxRange` function.
   * It is typically an event object that is triggered by a user action, such as clicking a button or
   * changing the value of an input field.
   */
    const handleMaxRange = (e) => {
        const { value } = e.target;
        if (parseInt(value) < parseInt(selectedMin?.name ?? 0)) {
            alert('Max deal dange is not lesser than min deal range')
        } else {
            setMaxDealOptions(MaxDealOptions?.map(type => ({ ...type, isChecked: value === "" ? false : type.name === value })))
        }
    }

    

    console.log('MinDealOptions', MinDealOptions);

    return (
        <div className="border-b-[3px] border-[#E8E6E1]">
            <li
                className="relative px-3 py-2"
            >
                <p className="text-[14px] tracking-[-.3px]">{label}</p>
                <div className="flex mt-2">
                    <select value={selectedMin?.name ?? ""} name="" id="" onChange={handleMinRange}
                        className=" border border-[#D9BD7F] mr-3 rounded focus:outline-none bg-[#FFF9EC] lg:w-[5rem] w-[9.5rem] md:w-[16rem] lg:h-[1.6rem]">
                        <option value="" selected>All</option>
                        {MinDealOptions && MinDealOptions.length > 0 ? (
                            MinDealOptions.map((date, index) => (
                                <option value={date.name ? date.name : ''}>{date.name ? date.name : ''}</option>
                            )))
                            :
                            (
                                <h1>No results found!</h1>
                            )}
                    </select>
                    <p className="text-sm mr-3">To</p>
                    <select value={selectedMax?.name ?? ""} name="" id="" onChange={handleMaxRange}
                        className=" border border-[#D9BD7F] mr-3 rounded focus:outline-none bg-[#FFF9EC] lg:w-[5rem] w-[9.5rem] md:w-[16rem] lg:h-[1.6rem]">
                        <option value="" selected>All</option>
                        {MaxDealOptions && MaxDealOptions.length > 0 ? (
                            MaxDealOptions.map((date, index) => (
                                <option value={date.name ? date.name : ''}>{date.name ? date.name : ''}</option>
                            )))
                            :
                            (
                                <h1>No results found!</h1>
                            )}
                    </select>
                </div>

            </li>
        </div>
    )
}

export default AcquirerDealRangeFilter