import React, {useState, useMemo} from 'react'
import { useQuery } from '@apollo/client'
import tfIn_Icon from '../../../images/noun-investment-2180740_2/png/noun-investment-2180740.png';

/**
 * The MostFunded function is a React component that renders a card displaying the category "Online
 * Services" as the most funded, along with an icon.
 * @returns a JSX element, which represents a div containing a section. Inside the section, there is a
 * div with a grid layout. The grid has two columns, with the first column taking up 5 spans and the
 * second column taking up 1 span. Inside the first column, there is a div with text "Most Funded" and
 * another div with a flex layout. Inside the
 */
function MostFunded({TOTAL_FUNDING_INR_CARD_QUERY}) {
    
    return (
        <div className="flex-shrink-0">
            {/* ----card3----- */}
            <section >
                <div className=" bg-white px-3 py-1 rounded-md  border border-gray-200 overflow-hidden card-animate card-animate">
                    <div className="grid grid-cols-6 ">
                        <div className="col-span-5 ">
                            <div className="text-[13px] font-sans_book_body leading-[17px] tracking-[-0.3px]">Most Funded</div>
                            {/* {TotalFundingInRCardData? */}
                            <div className="flex space-x-1  tracking-[-0.3px]">
                                <div className="  ">
                                    <span
                                        className='  text-[17px] leading-[23px] font-sans_bold_body text-[#A5711A] '>Online Services</span>
                                </div>
                            </div>
                        </div>
                        <div className="flex-shrink-0 flex items-center justify-center">
                            <a href="#">
                                <img className="h-[26px] max-w-[25px]"
                                    src={tfIn_Icon} />
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default MostFunded
