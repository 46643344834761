import { Fragment, React, useState, useContext } from 'react'
import axios from "axios";
import { toast } from "react-toastify";
import Share from '../../src/images/share.png';
import '../index.css'
import { sendDetailsApi } from '../Config/config'
import ConfirmPopup from '../Utils/ConfirmPopup';
import { Dialog, Transition } from "@headlessui/react";
import { Tooltip } from 'antd';
import {AiFillCloseCircle} from 'react-icons/ai'
import { UserContext } from '../Contexts/UserContext';


/**
 * The SendDealPopUp component is a React component that renders a pop-up window for sending deals to
 * colleagues.
 * When the button is clicked, a dialog box is displayed with a form to send a deal. The form includes input fields for
 * the recipient's email address, subject, link, and a message. There are also buttons to reset the
 * form and submit the deal.
 */
const SendDealPopUp = (props) => {

    const [open, setOpen] = useState(false)
    // const [showNotification, setShowNotification] = useState(false)
    const [sendDeals, setSendDeals] = useState(false)
    const [count, setCount] = useState('');
    // const [sendDetails, setSendDetails] = useState()
    const [message, setMessage] = useState("")
    const [toaddress, setToAddress] = useState("")
    const [setAlert, setsetAlert] = useState({ message: "", show: false });
    const {getToken} = useContext(UserContext);

    const subjecthandleChange = (e) => {
        setToAddress(e.target.value);

    }
    const handleMessageChange = event => {
    console.log(event.target.value);
    setMessage(event.target.value);
    setCount(event.target.value);
  };
    // const messagehandleChange = (e) => {
    //     setMessage(e.target.value);

    // }

    const resetRaiseRequest = () => {
        setToAddress("");
        setCount("");

    }
    const handleSubmit = event => {
        // setShowNotification(true)
        

          event.preventDefault();

    }

    const sendDetail = () => {
        
        if (toaddress) {
            
            // setShowNotification(false);
            setSendDeals(!sendDeals)
            setToAddress("");
            setMessage("")
            setCount("");
            console.log(sendDetail, "hai")
            axios(`${sendDetailsApi}`, {
                method: "POST",
                data: {
                    "user_id": 1,
                    "shareable_id": 5,
                    "shareable_sub_id": 15,
                    "from": "bhavani.k@praniontech.com",
                    "to": toaddress,
                    "subject": "List api",
                    "link": "http://127.0.0.1:8000/api/matex/categorylist/4",
                    "message": message,
                    
                },
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": getToken()
                },
            })
                .then((response) => {
                    console.log(response, 'send details api response');
                    if (response?.share?.success == 200) {
                        // setSendDetails(response?.share)
                        console.log(response?.share, "send details")

                        if (response?.data?.message) {
                            // setMsg(response?.data?.message)
                            toast.warning(response?.data?.message)
                            // setShowNotification(true)
                        }
                        else {
                            //  setMsg('Share has been completed')
                            toast.success("");
                             }
                        
                    }
                    else {
                        // setMsg(response?.data?.message)
                        toast.success(response?.data?.message)

                        // setShowNotification(true)
                    }
                })
                .catch((error) => {
                    console.log("API not working", error);
                })
        }
    }

    const onConfirmation = (flag) => {
        if (flag === 1) {
          setOpen(!open)
        } else {
    
        }
    
        setsetAlert({ show: false, message: "" });
      }
    
      const isShare = () => {
        setSendDeals(true)
        setOpen(true)
      }
    //   console.log("propspropsprops", setAlert);

    return (
        <>
            <div >

            {<ConfirmPopup {...setAlert} onChange={onConfirmation} />}
            <Tooltip title="Share">
                <button className="z-10" type="button" aria-labelledby="modal-title" role="dialog" aria-modal="true" >
                    <img
                        className={"w-[22px] h-[22px] brightness-0 invert-[.6] "}
                        src={Share} onClick={isShare}
                        alt=""
                    />
                </button>
                </Tooltip>
                {sendDeals ?
                 <Transition.Root show={open} as={Fragment}>
                 <Dialog
                   as="div"
                   className="relative z-10"
                   onClose={() => setOpen(false)}
                 >
                   <Transition.Child
                     as={Fragment}
                     enter="ease-out duration-300"
                     enterFrom="opacity-0"
                     enterTo="opacity-100"
                     leave="ease-in duration-200"
                     leaveFrom="opacity-100"
                     leaveTo="opacity-0"
                   >
                     <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                   </Transition.Child>
         
                   <div className="fixed inset-0 z-10 overflow-y-auto">
                     <div className="items-center flex min-h-full  justify-center p-4 text-center ">
                       <Transition.Child
                         as={Fragment}
                         enter="ease-out duration-300"
                         enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                         enterTo="opacity-100 translate-y-0 sm:scale-100"
                         leave="ease-in duration-200"
                         leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                         leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                       >
                         <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white   text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg ">
                    {/* <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
                        <div className="fixed inset-0 z-10 overflow-y-auto">
                            <div className="flex min-h-full items-end justify-center p-4 text-center items-center sm:p-0"> */}
                                <div className="relative transform overflow-hidden rounded-lg bg-white text-left transition-all sm:w-full sm:max-w-lg">
                                    {/* <div className="bg-white"> */}
                                        <div className="px-4 bg-[#BC8B20]  flex items-center justify-center  w-full h-14 text-center">
                                            <div className="w-full px-5 md:px-0 py-1 text-lg font-medium font-sans_book_body text-[18px] leading-6 text-[#FFFFFF]">Send this to your Colleague</div>
                                            <AiFillCloseCircle className='cursor-pointer mt-1' size={24} color='white' onClick={() => setSendDeals(!sendDeals)}/>
                                            {/* <button type="button" className="rounded-full bg-white text-gray-400 hover:text-gray-500 focus:outline-none pr-1"
                                                onClick={() => setSendDeals(!sendDeals)}>
                                                <span className="sr-only">Close</span>
                                                <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                </svg>
                                            </button> */}
                                        </div>
                                        <form onSubmit={(event) => { handleSubmit(event) }}>
                                            <div className="py-4 items-center px-4">
                                                <label className='text-[13pt] leading-[17pt] lg:text-[14px] lg:leading-[17px] text-[#333333] font-sans_semi_bold'
                                                > To*</label>
                                                <input name="toaddress"
                                                    type='text' value={toaddress} required onChange={(e) => { subjecthandleChange(e) }}
                                                    id="toaddress" className='border w-full py-1 px-2 mt-1 text-sm text-gray-500' />
                                            </div>
                                            <div className="py-4  p-4 items-center px-4">
                                                <label className='text-[13pt] leading-[17pt] lg:text-[14px] lg:leading-[17px] text-[#333333] font-sans_semi_bold'> Subject*</label>
                                                <input type="text" name="subject" id="subject" className='border w-full py-1 px-2 mt-1 text-sm text-gray-500' value={'Checkout this deal - ' + props.data + ' - in Venture Intelligence'} />
                                            </div>
                                            <div className="py-4  p-4 items-center px-4">
                                                <label className='text-[13pt] leading-[17pt] lg:text-[14px] lg:leading-[17px] text-[#333333] font-sans_semi_bold'> Link</label>
                                                <input type="text" name="link" id="link" className='border w-full py-1 px-2 mt-1 text-sm text-gray-500' value={window.location.href} />
                                            </div>
                                            <div className="py-4  p-4 items-center px-4 ">
                                                <div className='flex justify-between '>
                                                    <label className='text-[13pt] leading-[17pt] lg:text-[14px] lg:leading-[17px] text-[#333333] font-sans_semi_bold'> Your Message</label>
                                                    {/* {console.log(count, 'count12')} */}
                                                    <span className='text-[13pt] leading-[17pt] lg:text-[14px] lg:leading-[17px] text-[#333333]'>
                                                        {/* Words left: <span id="word_left">
                                                        {count == 0 ? 200 : 200-count.length}
                                                        </span> */}
                                                        </span>
                                                </div>
                                                <textarea name="text" id="link"
                                                    className="border w-full py-1 px-2 mt-2 text-sm text-gray-500"
                                                    placeholder="Enter your text here..."
                                                    type='text' value={count}  onChange={handleMessageChange}
                                                >
                                                </textarea>
                                            </div>
                                            <div className="bg-gray-50 px-4 py-3  flex  justify-end w-full items-center space-x-3">
                                                
                                                    <button type="reset" className="lg:mt-3 inline-flex w-full justify-center font-sans_bold_body rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none  sm:mt-0 sm:w-auto sm:text-sm"
                                                        value="reset" onClick={resetRaiseRequest}>
                                                        Reset
                                                    </button>
                                                                                                
                                                    <button type='submit' value="submit"
                                                        onClick={sendDetail}

                                                        className="inline-flex w-full justify-center rounded-md border border-transparent font-sans_bold_body font-medium text-white bg-[#BC8B20] px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-[#a37517] sm:ml-3 lg:mt-3 sm:w-auto sm:text-sm">
                                                    Submit
                                                    </button>                                                                                               
                                            </div>
                                        </form>
                                    
                                </div>
                            {/* </div> */}
                        {/* </div> */}
                    {/* </div> */}
                    </Dialog.Panel>
                    </Transition.Child>
                    </div>
                    </div>
                    </Dialog>
                    </Transition.Root>
                    : ''}
            </div>
        </>

    )
}

export default SendDealPopUp
