import React from 'react'
 import LimitedPartnerInvestorDetail from '../../Components/InvestorLimitedpartnerDetail/Investor_LP_Detail'
 import RefineSearch from '../../Components/Investor/RefineSearchComponent'
 //  import ChatIcon from '../../images/Group 13/png/Group 13.png';
import Layout from '../../Components/InvestorLayout'
// import chatPopupIcon from '../../images/chatPopupIcon.png'
// import ChatPopup from '../../Components/ChatPopup'
import TopSection from '../../Components/InvestorLimitedpartnerDetail/TopSection'
/**
 * The InvestorLimitedPartnerDetail component is a React component that renders a layout with a top
 * section, a primary column, and a secondary column.
 * @returns The InvestorLimitedPartnerDetail component is returning a JSX element.
 */
const InvestorLimitedPartnerDetail = ({menu}) => {

  return ( 
    <div>
    <Layout>
    <div className="flex h-screen flex-col">
           
            <TopSection />
             
            <div className="flex min-h-0 flex-1 overflow-hidden">
                {/* Primary column */}
                <section
                    aria-labelledby="primary-heading"
                    className="flex h-full min-w-0 flex-1 flex-col overflow-y-auto lg:order-last">
                    {/* <CompanyDetail COMPANY_PROFILE={COMPANY_PROFILE}  /> */}
                    <LimitedPartnerInvestorDetail menu={menu}/>
                    
                {/* <Outlet /> */}
                {/* Your content */}
                </section>
                {/* Secondary column (hidden on smaller screens) */}
                <aside className="hidden lg:order-first lg:block lg:flex-shrink-0">
                    <div className="relative flex h-full  flex-col overflow-y-auto border-r border-gray-200 bg-[#FAF5EA]">
                        {/* Your content */}
                        <RefineSearch />
                    </div>
                </aside>
            </div>            
        </div>
    </Layout>
</div>
  )
}

export default InvestorLimitedPartnerDetail