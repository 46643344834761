import { matchPath, useLocation, useSearchParams } from "react-router-dom"
import Constants from "../Constants";
import routePathSegments, { routeNames } from "../routeSegments"

/** The `useModule` function is a custom hook that is used to get current module information. */
function useModule() {
    const location = useLocation();
    const [URLSearchParams, SetURLSearchParams] = useSearchParams();

    const type = URLSearchParams.get('type')
    const typeParam = type == null ? null : parseInt(type)

    const peMatch = matchPath(`${routePathSegments.pe}/*`, location.pathname);
    const vcMatch = matchPath(`${routePathSegments.vc}/*`, location.pathname);
    const cfsMatch = matchPath(`${routePathSegments.cfs}/*`, location.pathname);
    const maMatch = matchPath(`${routePathSegments.ma}/*`, location.pathname);
    const reMatch = matchPath(`${routePathSegments.re}/*`, location.pathname);


    const primaryMasterId = typeParam != null ? typeParam : peMatch != null
        ? Constants.primaryMasterIds.peId
        : vcMatch != null
            ? Constants.primaryMasterIds.vcId
            : cfsMatch != null
                ? Constants.primaryMasterIds.cfsId
                : maMatch != null || typeParam === Constants.primaryMasterIds.mANDaId
                    ? Constants.primaryMasterIds.mANDaId
                    : reMatch != null
                    ? Constants.primaryMasterIds.reId
                    : Constants.primaryMasterIds.peId

    // console.log('primaryMasterId', primaryMasterId, typeParam); 

    const isPE = primaryMasterId === Constants.primaryMasterIds.peId;
    const isVC = primaryMasterId === Constants.primaryMasterIds.vcId;
    const isRE = primaryMasterId === Constants.primaryMasterIds.reId;
    const isMA = primaryMasterId === Constants.primaryMasterIds.mANDaId;
    const isCFS = primaryMasterId === Constants.primaryMasterIds.cfsId;
    const isDadhboard = location.pathname === `/${routePathSegments.dashboard}`
    const isNotifications = location.pathname === `/${routePathSegments.notifications}`
    const isRequests = location.pathname === `/${routePathSegments.requests}`
    const isAlerts = location.pathname === `/${routePathSegments.alerts}`
    const isPeFunds = location.pathname === routeNames.pe_investors_pe_vc_funds
    const isReFunds = location.pathname === routeNames.re_investors_funds
    const isPeLimitedpartners = location.pathname === routeNames.pe_investors_pe_vc_limited_partners
    const allowedModulesStr = localStorage.getItem(Constants.localStorageKeys.allowedModules);
    // console.log(isPeLimitedpartners,'12345');
    const allowedModulesList = JSON.stringify(allowedModulesStr) ?? [];

    return { primaryMasterId, isPE, isVC, isRE, isMA, isCFS,isDadhboard,isPeFunds,isReFunds,isPeLimitedpartners,allowedModulesList,isNotifications,isRequests,isAlerts }
}

export default useModule;