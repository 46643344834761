import React from 'react';

/**
 * The TextComponent function is a React component that renders an input field and updates the selected
 * child's value based on user input.
 * @returns The TextComponent is returning an input element with various attributes and properties such
 * as type, name, onChange, value, placeholder, and className.
 */
const TextComponent = ({ child, selectedChild, setSelectedChild }) => {
  const childIndex = child.uniqueId;
/**
 * The handleChange function updates the value of a selected child based on the entered value from an
 * event.
 * @param event - The event parameter is an object that represents the event that triggered the
 * handleChange function. It contains information about the event, such as the target element and the
 * value entered by the user.
 */
  const handleChange = (event) => {
    const enteredValue = event.target.value;
    
    const childIndexx = selectedChild.findIndex((selectedChild, index) => selectedChild.uniqueId === childIndex );

    const updatedChild = { ...selectedChild[childIndexx] };
    updatedChild.values = [enteredValue];
    const updatedSelectedChild = [...selectedChild];
    updatedSelectedChild[childIndexx] = updatedChild;
    setSelectedChild(updatedSelectedChild);
  };

  return (
    <input
    key={childIndex}
      type="text"
      name={child.id}
      onChange={handleChange}
      value={child.values[0] || ''}
      placeholder="Search..."
      className="p-2 border border-[#D9BD7F] rounded focus:outline-none bg-[#FFFFFF] w-[12rem]"
    />
  );
};

export default TextComponent;
