import React, { useEffect, useState, useMemo, useContext } from "react";
import DataTable from "../../DataTables/DataTable";
import moment from 'moment';
import { UserContext } from "../../../Contexts/UserContext";
import { useParams } from "react-router-dom";
import axios from "axios";
import { reInvestorNewsUrl } from "../../../Config/config";
import useModule from "../../../hooks/useModule";
import Constants from "../../../Constants";
import { DataFoundContext } from "../../../Contexts/DataFoundContext";


/**
 * The `InvestorNewsActivity` function is a React component that fetches and displays recent news and
 * activity for a specific investor.
 * @returns The InvestorNewsActivity component is being returned.
 */
const InvestorNewsActivity = () => {

  const [newsletter, setNewsletter] = useState([]);
  const { getToken, logout } = useContext(UserContext);
  const { primaryMasterId } = useModule()
  const { reinvestorId } = useParams()
  const {newsAndActivityDataFound, setNewsAndActivityDataFound} = useContext(DataFoundContext);

  /* The `useEffect` hook is used to perform side effects in a functional component. In this case, it
  is used to fetch investor news and activity data from an API endpoint when the component mounts or
  when the dependencies (`getToken`, `logout`, `primaryMasterId`, `reinvestorId`) change. */
  useEffect(() => {
    axios.post(reInvestorNewsUrl, {
      "investor_id": reinvestorId,
      "type": primaryMasterId,
    }, {
      headers: {
        'Authorization': getToken(),
      }
    }).then(response => {
      if (response.status === 200 && response.data?.data) {
        setNewsletter(response.data.data);
        setNewsAndActivityDataFound(true);
      }
      else if(response.message === 'No Data Found'){
        setNewsAndActivityDataFound(false);
      }
    }).catch(error => {
      setNewsAndActivityDataFound(false);
      if (error?.response?.status === Constants.sessionTerminatedStatus) {
        logout()
      }
    })
    return () => {
      setNewsAndActivityDataFound(false);
    }
  }, [getToken, logout, primaryMasterId, reinvestorId])

  /* The `customStringSortMemo` constant is using the `useMemo` hook to memoize a sorting function for
  string values in a DataTable component. */
  const customStringSortMemo = useMemo(() => {
    return (rowA, rowB, columnId, desc) => {
      const defaultVal = desc ? '--' : 'A';
      return (rowA?.values[columnId]?.news ?? defaultVal).localeCompare(rowB?.values[columnId]?.news ?? defaultVal);
    }
  }, []);
  /* The `dealDateSort` constant is using the `useMemo` hook to memoize a sorting function for date
  values in a DataTable component. */
  const dealDateSort = useMemo(() => {
    return (rowA, rowB, columnId, desc) => {
      return moment.utc(rowA?.values[columnId].date).diff(moment.utc(rowB?.values[columnId].date))
    }
  }, [])
  /* The `const columns` variable is an array of objects that define the columns for a DataTable
  component. Each object represents a column and contains properties such as `id`, `Header`,
  `accessor`, `className`, `headerClassName`, `Cell`, and `sortType`. */
  const columns = useMemo(() => [
    {
      id: "news",
      Header: "News",
      accessor: "news",
      className: "text-[#333333] text-[12pt]  py-4 flex-wrap lg:text-[14px] lg:leading-[16px]",
      headerClassName: "w-[15%]",
      Cell: ({ value }) => {
        return <a href={value.link} target="_blank" rel="noreferrer">
          {value.news}
        </a>
      },
      sortType: customStringSortMemo,
    },
    {
      id: "releaseDate",
      Header: "Release Date",
      accessor: "releaseDate",
      className: "text-[#333333] pr-[3.5rem] py-4 float-center lg:float-right whitespace-nowrap  lg:text-[14px] lg:leading-[16px] text-right",
      headerClassName: "text-center float-right flex lg:px-10 px-8 py-3.5",
      Cell: ({ value }) => {
        return moment(value?.date).format('MMM DD, YYYY')
      },
      sortType: dealDateSort,
    }
  ], [customStringSortMemo, dealDateSort])

  /* The `rowData` constant is using the `useMemo` hook to memoize a function that transforms the
  `newsletter` data into a format that can be used by the DataTable component. */
  const rowData = useMemo(() => {
    const rows = [];

    if (newsletter) {
      newsletter?.forEach(newsData => {
        rows.push({
          news: { news: newsData?.news, link: newsData?.link },
          releaseDate: { date: newsData?.release_date, news: newsData?.news }
        })
      });
    }

    return rows;

  }, [newsletter])
  /* The `const initialSort` variable is using the `useMemo` hook to memoize an array that specifies
  the initial sorting configuration for the DataTable component. */
  const initialSort = useMemo(() => [
    {
      id: "releaseDate",
      desc: true
    }
  ], []);
  return (
    <>
      { newsAndActivityDataFound ? (
        <div className="mx-4 ">
          <div className="bg-white border mb-[-1.75rem] overflow-hidden sm:rounded-lg shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
            <div className="pt-4  px-5">
              <p className="text-[14pt] leading-[19pt] lg:text-[#333333] lg:text-[17px] font-semibold  lg:leading-[22px] mb-3 ">
                Recent News & Activity
              </p>
            </div>
            <DataTable
              columns={columns}
              data={rowData}
              sortBy={initialSort}
            />
          </div>
          <br />
          <br />
        </div>
      ) : ("")}
    </>
  )
};

export default InvestorNewsActivity;
