import React from 'react'
import CloseButton from '../../../images/CloseBtn.svg';

const ExpandedTagList = ({ tagList, onRemove }) => {
  return (
    <div className="max-h-40 overflow-y-auto">
        {tagList.values?.map((value, index) => (
            <div
              key={index}
              className="p-2 flex justify-between items-center hover:bg-gray-200"
            >
              {value}
              <img
                src={CloseButton}
                alt="remove tag"
                className="h-3 w-3 ml-3 cursor-pointer"
                onClick={() => onRemove(value)}
              />
            </div>
          ))
        }
    </div>
  )
}

export default ExpandedTagList;