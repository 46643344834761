import React, { useContext } from 'react'
import { Navigate, useLocation } from 'react-router-dom';
import { UserContext } from '../../Contexts/UserContext'



/**
 * The PrivateRoute function is a React component that checks if a user is logged in and redirects them
 * to the login page if they are not.
 * @returns The PrivateRoute component is returning the children components if the user is logged in.
 * If the user is not logged in, it will redirect to the login page with the return URL.
 */
function PrivateRoute({ children }) {
  const { isLoggedIn } = useContext(UserContext);
  const location = useLocation();
  if (!isLoggedIn()) {
    // not logged in so redirect to login page with the return url
    return <Navigate to="/login" state={{ from: location.pathname }} />
  }
  // if(location.pathname === "/pe" ){
  //   return <Navigate to="/companies/pe-vc-investments" />
  // }
  // authorized so return child components
  return children;
}
export default PrivateRoute;