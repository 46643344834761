import React, { useContext } from 'react'
import { NavLink} from "react-router-dom";
import { routeNames } from '../../routeSegments';
import { PeFilterContext } from '../../Contexts/PeFilterContext';

/**
 * The FundsBreadCrum component is a breadcrumb navigation component that displays the path to the
 * Funds page within a larger navigation structure.
 * @returns The component is returning a JSX element that represents a breadcrumb navigation bar. It
 * includes a navigation link and a current page indicator for the "Funds" section.
 */

const FundsBreadCrum = (props) => {

    const { groupId ,setGroupId  } = useContext(PeFilterContext);

  return (
    <div className='   pl-3 pr-4 '>
        <nav className="flex p-1 border-b bg-white rounded-t-[6px] pl-3   " aria-label="Breadcrumb">
                    <ol role="list" className="flex items-center space-x-1">
                        <li>
                            <NavLink
                           onClick={()=>setGroupId({})}

                                 to={
                                props?.menu =="investorvc"? routeNames.vc_investors_vc_investments
                                :props?.menu =="advisor"? routeNames.pe_advisors_pe_vc_advisors 
                                :props?.menu =="advisorvc"? routeNames.vc_advisors_vc_advisors
                                :props?.menu =="tadvisor"? routeNames.pe_transaction_advisors_pe_vc_advisors    
                                :props?.menu =="tadvisorvc"? routeNames.vc_transaction_advisors_vc_advisors   
                                :props?.menu == "reFund" ? routeNames.re_investors_re_investments
                                :routeNames.pe_investors_pe_vc_investments} className="ml-1 text-[11pt] lg:text-[12px]  leading-[14px] font-sans_book_body text-[#666666]">
                                    <span className="">
                                            {props.subMenu == "vc" ? "Investors" 
                                            :props.subMenu == "advisor"? "Advisor"
                                            :props.subMenu == "tadvisor"? "Transaction-Advisor"
                                            :props.subMenu == "re"? "Investors"
                                            
                                            :""}
                                        </span>
                                     
                                </NavLink>
                        </li>
                        <li>
                            <div className="flex items-center ">
                                <span className=" text-[12px] text-[#666666] mr-1">/</span>
                                <a href="#" className="text-[10pt] lg:text-[12px]  text-[#666666] leading-[14px]  font-semibold"
                                    aria-current="page">
                                    Funds
                                      </a>
                            </div>
                        </li>
                    </ol>
                </nav>
    </div>
  )
}

export default FundsBreadCrum