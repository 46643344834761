import React from 'react'
import NavbarWrapper from './NavbarWrapper'

/** This component renders a header for a `CFS` section of a
website. */
function CfsHeader() {
  return (
    <NavbarWrapper />
  )
}

export default CfsHeader