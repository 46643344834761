import React, {useEffect, useState } from 'react'
import downloadIcon from '../../images/ReportsImages/download.png'
import Loader from "../Loader";

/* The code defines a functional component called `ArchieveContent` that takes two props:
`currentTabHeader` and `isLoading`. */
const ArchieveContent=({currentTabHeader,isLoading})=> {

  const [openTab, setOpenTab] = React.useState(0);
  const [activeTab, setActiveTab] = React.useState(0);
  const [yearTab, setYearTab] = useState(0);

  useEffect(() => {
    setActiveTab(0);
    setYearTab(currentTabHeader?.yearHeader?.at(0)?? 0);
  },[currentTabHeader])

  return (
    <div>
                <div className="border-2 border-gray-200 w-full h-full  ">
                {isLoading ?
                  <div className="h-40 flex items-center justify-center">
                    <Loader />
                  </div> :
                <div>    
                  <div className=" space-x-4 flex no-scrollbar1 overflow-x-auto items-center m-2 px-5 lg:px-12 lg:pt-7 lg:mt-5  ">
                  {currentTabHeader != [] && currentTabHeader?.yearHeader.map((data,i)=>{
                    return <button className={"items-center  h-[34px] w-[100px] px-8 mb-2 font-book text-[14px] tracking-[-0.3px] leading-[17px] rounded-[6px] " + 
                       (activeTab === i
                            ? "text-white bg-[#BC8C22] font-sans_medium_body  "
                            : "text-[#666666] font-sans_book_body  bg-gray-200")
                    }
                        onClick={e => {
                            e.preventDefault();
                            setActiveTab(i);
                            setYearTab(data);
                        }}
                        data-toggle="tab"
                        href=""
                        role="tablist" 
                        key={i}>
                        {data}</button>
                   })}

                  </div>
                   <div className="">
                    <div className=" m-2 md:px-12 px-[0.5rem]">
                        {(yearTab > 0 && currentTabHeader.hasOwnProperty(yearTab)) && currentTabHeader[yearTab].map((data,i)=>{
                          return <div className="hover:bg-[#FDF4E3] flex w-[75%]">
                          <p
                          className="text-[#2B2A29] text-[14px] font-sans_book_body tracking-[-0.3px] leading-[17px] py-5 pl-4" key={i}>
                          {data.fileName}</p>
                          <a href={data.url} className="float-end ml-auto" target="_blank" rel="noreferrer"><img src={downloadIcon} className="w-[20px] h-[20px] mt-4 ml-5 mr-5" alt='' /></a>
                          </div>
                        })}
                    </div>
                  </div>
                  </div>}
                </div> 
    </div>
  )
}

export default ArchieveContent