
import { Fragment, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon, SearchIcon } from '@heroicons/react/solid'
import {
  ArchiveIcon,
  BanIcon,
  BellIcon,
  FlagIcon,
  InboxIcon,
  MenuIcon,
  PencilAltIcon,
  UserCircleIcon,
  XIcon,
} from '@heroicons/react/outline'
// import Header from './HeaderComponent'
import SideMenu from './SideMenu'
import MainMenu from './MainMenu'
import { Outlet } from 'react-router-dom'
import MAHeader from './M&AHeader'

const user = {
  name: 'Whitney Francis',
  email: 'whitney.francis@example.com',
  imageUrl:
    'https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
}
const navigation = [
  {
    name: 'Inboxes',
    href: '#',
    children: [
      { name: 'Technical Support', href: '#' },
      { name: 'Sales', href: '#' },
      { name: 'General', href: '#' },
    ],
  },
  { name: 'Reporting', href: '#', children: [] },
  { name: 'Settings', href: '#', children: [] },
]
const sidebarNavigation = [
  { name: 'Open', href: '#', icon: InboxIcon, current: true },
  { name: 'Archive', href: '#', icon: ArchiveIcon, current: false },
  { name: 'Customers', href: '#', icon: UserCircleIcon, current: false },
  { name: 'Flagged', href: '#', icon: FlagIcon, current: false },
  { name: 'Spam', href: '#', icon: BanIcon, current: false },
  { name: 'Drafts', href: '#', icon: PencilAltIcon, current: false },
]
const userNavigation = [
  { name: 'Your Profile', href: '#' },
  { name: 'Sign out', href: '#' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

/**
 * The `MALayout` function is a React component that renders a layout with a top navigation bar, a
 * narrow sidebar, and a main area, with the ability to toggle a mobile menu.
 * @returns The `children` prop is being returned.
 */
export default function MALayout({children}) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  return children;

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html className="h-full bg-gray-100">
        <body className="h-full overflow-hidden">
        ```
      */}
      <div className="bg-gray-100">
        <div className="min-h-screen flex flex-col">
            {/* Top nav*/}
            {/* <Header /> */}
            <MAHeader />
            {/* Bottom section */}
            <div className=" min-h-0 overflow-hidden">
            {/* Narrow sidebar*/}
            <div className="hidden md:block	">
                <SideMenu />
            </div>
            
            {/* Main area */}
            <main className="lg:ml-[5rem] md:ml-[5.25rem]">
               {children}
            </main>
            
            </div>
        </div>
      </div>
    </>
  )
}
