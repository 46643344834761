import React, { useState, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import { TableDownloadContext } from "../Contexts/TableDownloadContext";
import moment from 'moment';
import "./MainMenu.css";
import { PeFilterContext } from "../Contexts/PeFilterContext";
import { TableDataContext } from '../Contexts/TableDataContext'
import { useLocation } from "react-router";

import {
  PlusCircleIcon, UserGroupIcon, ShieldCheckIcon,
} from '@heroicons/react/outline'
import { routeNames } from "../routeSegments";


const solutions = [
  { name: 'via M&A(PE-VC)', href: '#', icon: ShieldCheckIcon },
  { name: 'M&A(VC)', href: '#', icon: UserGroupIcon },
  { name: 'via Public Market(PE-VC)', href: '#', icon: PlusCircleIcon },
  { name: 'Public Market(VC)', href: '#', icon: ShieldCheckIcon },

]

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

/* The above code is a JavaScript function called `MainMenu` that renders a main menu component. */
function MainMenu(props) {


  // const [connection, setConnection] = useState(true);
  // const {
  //   monthPickerValue,
  //   setMonthPickerValue,
  //   isMonthPickerChanged,
  //   setIsMonthPickerChanged,
  //   isTagClosed,
  //   setIsTagClosed,
  // } = useContext(PeFilterContext);

  const { handleDownloadExcel } = useContext(TableDownloadContext);
  const { activeTabDeal, setActiveTabDeal, setCurrentPage } = useContext(TableDataContext);
  const [openTab, setOpenTab] = React.useState(1);
  const [date, setDate] = useState([moment().subtract(12, 'months'), moment().startOf('second')]);

    if (props.menu === "pe") {
      document.title = "PE Companies PE-VC Investments - Venture Intelligence";
  } else if (props.menu === "exit-pe-vc") {
      document.title = "PE Companies PE-VC Exists - Venture Intelligence";
  } else if (props.menu === "exit-ma-pe-vc") {
      document.title = "PE Companies Exits Via M&A (PE-VC) - Venture Intelligence";
  } else if (props.menu === "exit-pm-pe-vc") {
      document.title = "PE Companies Exits Via Public Market (PE-VC) - Venture Intelligence";
  } else if (props.menu === "PE-VC-IPOs") {
      document.title = "PE Companies PE-VC BAcked IPO's - Venture Intelligence";
  } else if (props.menu === "angel") {
      document.title = "PE Companies Angel Investments - Venture Intelligence";
  } else if (props.menu === "incubation") {
      document.title = "PE Companies Incubation - Venture Intelligence";
  } else {
      document.title = "Venture Intelligence";
  }


  if(props.menu === "advisor"){
    if (props.subMenu === "ma-exist"){
      document.title = "PE Legal Advisors PE-VC Exits - Venture Intelligence"
    }else if (props.subMenu === "exist-ma"){
      document.title = "PE Legal Advisors Exits Via M&A (PE-VC) - Venture Intelligence"
    }else if (props.subMenu === "exist-pm"){
      document.title = "PE Legal Advisors Exits Via Public Market (PE-VC) - Venture Intelligence"
    }
    else{
      document.title = "PE Legal Advisors PE-VC Investment - Venture Intelligence"
    }
  }  

  if(props.menu === "tadvisor"){
    if (props.subMenu === "texist-pm"){
      document.title = "PE Transaction Advisors PE-VC Exits - Venture Intelligence"
    }else if (props.subMenu === "exist-ma"){
      document.title = "PE Transaction Advisors Exits Via M&A (PE-VC) - Venture Intelligence"
    }else if (props.subMenu === "exist-pm"){
      document.title = "PE Transaction Advisors Exits Via Public Market (PE-VC) - Venture Intelligence"
    }
    else{
      document.title = "PE Transaction Advisors PE-VC Investment - Venture Intelligence"
    }
  }  

    console.log(props.menu , "menu5")
  

  if (props.active == 1) {
    setActiveTabDeal('investments')
  }
  console.log(activeTabDeal, "me")

  useEffect(() => {
    setOpenTab(props.active);
  }, [openTab]);

  const Link1 = () => { document.getElementById("link1").scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' }); }
  const Link2 = () => { document.getElementById("link2").scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' }); }
  const Link3 = () => { document.getElementById("link3").scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' }); }
  const Link4 = () => { document.getElementById("link4").scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' }); }
  const Link5 = () => { document.getElementById("link5").scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' }); }

  useEffect(() => {
    if (openTab === 1) {
      Link1()
    }
    else if (openTab === 2) {
      Link2()
    }
    else if (openTab === 3) {
      Link3()
    } else if (openTab === 4) {
      Link4()
    }
    else if (openTab === 5) {
      Link5()
    }

  }, [openTab])

  // const download = () => {
  //   handleDownloadExcel();
  // };
  const { refineSearchFilters, groupId, setGroupId } = useContext(PeFilterContext);

  const { pathname } = useLocation();
  const isFromGlobalSearch = () => refineSearchFilters[pathname]?.globalSearch?.length > 0 ? true : false
  const { dealsView } = useContext(PeFilterContext);

  return (
    <>

      <div className="flex flex-row px-3 md:px-3 overflow-x-auto space-x-5 scrollbar-remove">
        <div className="scrollbar">
          <div
            className="rounded-[6px] rounded-b-none bg-white block lg:flex flex-row justify-between ">
            {
              props.menu == "advisor" ? (
                <div className="flex flex-row space-x-5 overflow-x-auto lg:overflow-hidden">
                  <div className="lg:py-4 py-5" id="link1">

                    <NavLink
                      className={
                        "whitespace-nowrap lg:text-[14px] lg:leading-[17px] text-[12pt] leading-[17pt]" +
                        (openTab == 1
                          ? " font-bold  top-[8px] mb-[8px] text-[15px]   text-[#2B2A29]  border-b-[3px] border-[#F1CA75] py-4 pt-[1.1rem]"
                          : "whitespace-nowrap font-sans_medium_body text-[#2B2A29] opacity-[.8] hover:opacity-100 ")
                      }
                      data-toggle="tab"
                      to={
                        props.subMenu == "vc"
                          ? routeNames.vc_advisors_vc_advisors
                          : routeNames.pe_advisors_pe_vc_advisors
                      }
                      role="tablist"
                    >
                      <div className="group inline-block">
                        {/* mywork */}
                        <button onClick={() => setCurrentPage(1)} className="outline-none  focus:outline-none py-1  rounded-sm flex items-center ">
                          <span className=" flex lg:text-[14px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                            {props.subMenu == "vc"
                              ? "VC Advisor"
                              : "PE-VC Investment"}
                          </span>

                        </button>

                      </div>
                    </NavLink>
                  </div>
                  <div className="lg:py-4 py-5" id="link2">
                    <NavLink
                      className={
                        "whitespace-nowrap lg:text-[14px] lg:leading-[17px] text-[12pt] leading-[17pt]" +
                        (openTab == 2
                          ? " font-bold text-[#333333]  text-[#2B2A29]  border-b-[3px] border-[#F1CA75] py-4 pt-[1.1rem]"
                          : "whitespace-nowrap font-sans_medium_body text-[#2B2A29] opacity-[.8] hover:opacity-100 ")
                      }
                      to={
                        props.subMenu == "exist-ma"
                          ? routeNames.pe_advisors_ma_pe_vc_advisors
                          : props.subMenu == "exist-ma-ma"
                            ? routeNames.pe_advisors_ma_advisors_exits
                            : props.subMenu == "exist-pm-pm"
                              ? routeNames.pe_advisors_pm_pe_vc_advisors
                              : routeNames.pe_advisors_ma_advisors_exits
                      }


                    >
                      {isFromGlobalSearch() ?
                        <div className="group inline-block">
                          <button onClick={() => setCurrentPage(1)} className="outline-none  justify-between focus:outline-none py-1  rounded-sm flex items-center ">
                            <span className=" flex-1 lg:text-[14px] lg:leading-[15px] text-[12pt] leading-[14pt]">
                              {props.subMenu == "exist-pm"
                                ? "Via Public Market(PE-VC)"
                                : props.subMenu == "exit-pm-pe-vc"
                                  ? "PE-VC Exits"
                                  : props.subMenu == "exist-ma"
                                    ? "Via M&A (PE-VC)"
                                    : "PE-VC Exits"}
                            </span>


                          </button>

                        </div>
                        :
                        <div className="group inline-block">
                          <button onClick={() => setCurrentPage(1)} className="outline-none  justify-between focus:outline-none py-1  rounded-sm flex items-center ">
                            <span className=" flex-1 lg:text-[14px] lg:leading-[15px] text-[12pt] leading-[14pt]">
                              {props.subMenu == "exist-pm"
                                ? "Via Public Market(PE-VC)"
                                : props.subMenu == "exit-pm-pe-vc"
                                  ? "PE-VC Exits"
                                  : props.subMenu == "exist-ma"
                                    ? "Via M&A (PE-VC)"
                                    : "PE-VC Exits"}
                            </span>

                            <span>
                              <svg
                                className="fill-current h-4 w-4 transform group-hover:-rotate-180
                                         transition duration-150 ease-in-out"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                              >
                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                              </svg>
                            </span>
                          </button>


                          <ul
                            className="mt-2 bg-white rounded-lg shadow-lg lg:ring-1 ring-black ring-opacity-5 transform scale-0 group-hover:scale-100 z-10 absolute 
                                 transition duration-150 ease-in-out origin-top min-w-[9rem] md:min-w-[12.5rem]"
                          >
                            {props.subMenu == "exist-pm" ? (


                              <ul
                                className=" bg-white  py-1 rounded-lg shadow-lg lg:ring-1 ring-black ring-opacity-5 absolute top-0 md:right-0 right-[3%] transition duration-150 ease-in-out origin-top-left min-w-32"
                              >
                                <li className="rounded-sm relative font-sans_book_body  px-6 py-2 hover:bg-gray-100 ">
                                  <NavLink
                                    to={routeNames.pe_advisors_ma_advisors_exits}
                                    className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                  >
                                    <span onClick={() => setCurrentPage(1)} className=" flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                      PE-VC Exits
                                    </span>

                                  </NavLink>
                                </li>
                                <li className="rounded-sm relative font-sans_book_body  px-6 py-2 hover:bg-gray-100  ">
                                  <NavLink
                                    to={routeNames.pe_advisors_ma_pe_vc_advisors}
                                    className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                  >
                                    <span onClick={() => setCurrentPage(1)} className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                      Via M&A (PE-VC)
                                    </span>
                                    <span className="mr-auto">

                                    </span>
                                  </NavLink>

                                </li>

                              </ul>

                            ) : props.subMenu == "exit-pm-pe-vc" ? (


                              <ul
                                className=" bg-white  py-1 rounded-lg shadow-lg lg:ring-1 ring-black ring-opacity-5 absolute top-0 md:right-0 right-[3%]  transition duration-150 ease-in-out origin-top-left min-w-32"
                              >
                                <li className="rounded-sm relative font-sans_book_body  px-6 py-2 hover:bg-gray-100  ">
                                  <NavLink
                                    to={routeNames.pe_advisors_ma_advisors_exits}
                                    className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                  >
                                    <span onClick={() => setCurrentPage(1)} className=" flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                      PE-VC Exits
                                    </span>

                                  </NavLink>
                                </li>
                                <li className="rounded-sm relative font-sans_book_body  px-6 py-2 hover:bg-gray-100  ">
                                  <NavLink
                                    to={routeNames.pe_advisors_ma_pe_vc_advisors}
                                    className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                  >
                                    <span onClick={() => setCurrentPage(1)} className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                      Via M&A (PE-VC)
                                    </span>
                                    <span className="mr-auto">

                                    </span>
                                  </NavLink>

                                </li>
                                <li className="rounded-sm relative font-sans_book_body  px-6 py-2 hover:bg-gray-100  ">
                                  <NavLink
                                    to={routeNames.pe_advisors_pm_pe_vc_advisors}
                                    className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                  >
                                    <span onClick={() => setCurrentPage(1)} className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                      Via Public Market (PE-VC)
                                    </span>
                                    <span className="mr-auto">

                                    </span>
                                  </NavLink>

                                </li>
                              </ul>

                            ) : props.subMenu == "exist-ma" ? (


                              <ul
                                className=" bg-white  py-1 rounded-lg shadow-lg lg:ring-1 ring-black ring-opacity-5 absolute top-0 md:right-0 right-[3%]  transition duration-150 ease-in-out origin-top-left min-w-32"
                              >

                                <li className="rounded-sm relative font-sans_book_body  px-6 py-2 hover:bg-gray-100 ">
                                  <NavLink
                                    to={routeNames.pe_advisors_ma_advisors_exits}
                                    className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                  >
                                    <span onClick={() => setCurrentPage(1)} className=" flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                      PE-VC Exits
                                    </span>
                                    <span className="mr-auto pl-16">

                                    </span>
                                  </NavLink>
                                </li>
                                <li className="rounded-sm relative font-sans_book_body  px-6 py-2 hover:bg-gray-100  ">
                                  <NavLink
                                    to={routeNames.pe_advisors_pm_pe_vc_advisors}
                                    className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                  >
                                    <span onClick={() => setCurrentPage(1)} className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                      Via Public Market (PE-VC)
                                    </span>
                                    <span className="mr-auto">

                                    </span>
                                  </NavLink>

                                </li>
                              </ul>

                            ) : props.subMenu == "exist-pm" ? (


                              <ul
                                className=" bg-white  py-1 rounded-lg shadow-lg lg:ring-1 ring-black ring-opacity-5 absolute top-0 md:right-0 right-[3%]  transition duration-150 ease-in-out origin-top-left min-w-32"
                              >
                                <li className="rounded-sm relative font-sans_book_body  px-6 py-2 hover:bg-gray-100 ">
                                  <NavLink
                                    to={routeNames.pe_advisors_ma_advisors_exits}
                                    className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                  >
                                    <span onClick={() => setCurrentPage(1)} className=" flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                      PE-VC Exits
                                    </span>
                                    <span className="mr-auto pl-16">

                                    </span>
                                  </NavLink>
                                </li>
                                <li className="rounded-sm relative font-sans_book_body  px-6 py-2 hover:bg-gray-100  ">
                                  <NavLink
                                    to={routeNames.pe_advisors_ma_pe_vc_advisors}
                                    className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                  >
                                    <span onClick={() => setCurrentPage(1)} className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                      Via M&A (PE-VC)
                                    </span>
                                    <span className="mr-auto">

                                    </span>
                                  </NavLink>

                                </li>
                                <li className="rounded-sm relative font-sans_book_body  px-6 py-2 hover:bg-gray-100  ">
                                  <NavLink
                                    to={routeNames.pe_advisors_pm_pe_vc_advisors}
                                    className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                  >
                                    <span onClick={() => setCurrentPage(1)} className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                      Via Public Market (PE-VC)
                                    </span>
                                    <span className="mr-auto">

                                    </span>
                                  </NavLink>

                                </li>
                              </ul>

                            ) : (
                              <ul
                                className=" bg-white  py-1 rounded-lg shadow-lg lg:ring-1 ring-black ring-opacity-5 absolute top-0 md:right-0 right-[3%]  transition duration-150 ease-in-out origin-top-left min-w-32"
                              >
                                <li className="rounded-sm relative font-sans_book_body  px-6 py-2 hover:bg-gray-100  ">
                                  <NavLink
                                    to={routeNames.pe_advisors_ma_pe_vc_advisors}
                                    className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                  >
                                    <span onClick={() => setCurrentPage(1)} className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                      Via M&A (PE-VC)
                                    </span>
                                    <span className="mr-auto">

                                    </span>
                                  </NavLink>

                                </li>
                                <li className="rounded-sm relative font-sans_book_body  px-6 py-2 hover:bg-gray-100  ">
                                  <NavLink
                                    to={routeNames.pe_advisors_pm_pe_vc_advisors}
                                    className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                  >
                                    <span onClick={() => setCurrentPage(1)} className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                      Via Public Market (PE-VC)
                                    </span>
                                    <span className="mr-auto">

                                    </span>
                                  </NavLink>

                                </li>
                              </ul>
                            )}
                          </ul>
                        </div>

                      }

                    </NavLink>
                  </div>
                </div>
              )
                //start of advisor limited partner
                : props.menu == "ladvisorlimitedpartner" ? (
                  <div className="flex flex-row space-x-5">
                    <div className="py-4 " id="link1">

                      <NavLink
                        className={
                          "whitespace-nowrap lg:text-[14px] lg:leading-[17px] text-[12pt] leading-[17pt]" +
                          (openTab == 1
                            ? " font-bold text-[#333333]  text-[#2B2A29]  border-b-[3px] border-[#F1CA75] py-4 pt-[1.1rem]"
                            : "whitespace-nowrap font-sans_medium_body text-[#2B2A29] opacity-[.8] hover:opacity-100 ")
                        }
                        data-toggle="tab"
                        to={
                          props.subMenu == "vc"
                            ? "/advisors/vc-limited-partners"
                            : "/advisors/pe-vc-limited-partners"
                        }
                        role="tablist"
                      >
                        <div className="group inline-block">
                          {/* mywork */}
                          <button onClick={() => setCurrentPage(1)} className="outline-none focus:outline-none py-1 rounded-sm flex items-center ">
                            <span className=" flex lg:text-[14px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                              {props.subMenu == "vc"
                                ? "VC Advisor"
                                : "PE-VC Advisor"}
                            </span>
                            {/* <span>
                          <svg
                            className="fill-current h-4 w-4 transform group-hover:-rotate-180 
                                            transition duration-150 ease-in-out"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                          </svg>
                        </span> */}
                          </button>
                          {/* <ul
                        className="  border border-gray-300   absolute z-10 mt-1 bg-white rounded-sm transform scale-0 group-hover:scale-100 absolute 
                                    transition duration-150 ease-in-out origin-top min-w-32"
                      >
                        <li>
                          {props.subMenu == "vc" ? (
                            <NavLink
                              className=" opacity-100 z-30 rounded-sm px-3 py-1 hover:bg-gray-100 text-[#A5711A] text-[13px] leading-[15px]"
                              to="/advisors/pe-vc-advisors"
                            >
                              PE-VC Advisor
                            </NavLink>
                          ) : (
                            <NavLink
                               
                            >
                              
                            </NavLink>
                          )}
                        </li>
                      </ul> */}
                        </div>
                      </NavLink>
                    </div>
                    <div className="py-4 pl-2 lg:pl-0" id="link2">
                      <NavLink
                        className={
                          "whitespace-nowrap lg:text-[14px] lg:leading-[17px] text-[12pt] leading-[17pt]" +
                          (openTab == 2
                            ? " font-bold text-[#333333]  text-[#2B2A29]  border-b-[3px] border-[#F1CA75] py-4 pt-[1.1rem]"
                            : "whitespace-nowrap font-sans_medium_body text-[#2B2A29] opacity-[.8] hover:opacity-100 ")
                        }
                        to={
                          props.subMenu == "exist-ma"
                            ? "/advisors/ma/vc-limited-partners"
                            : props.subMenu == "exist-ma-ma"
                              ? "/advisors/ma/pe-vc-limited-partners"
                              : props.subMenu == "exist-pm-pm"
                                ? "/advisors/pm/pe-vc-limited-partners"
                                : "/advisors/ma/pe-vc-limited-partners"
                        }
                        data-toggle="tab"
                        role="tablist"
                      >
                        <div className="group inline-block">
                          {/* //mywork// */}
                          <button onClick={() => setCurrentPage(1)} className="outline-none w-36  justify-between focus:outline-none py-1 rounded-sm flex items-center ">
                            <span className=" flex-1 lg:text-[14px] lg:leading-[15px] text-[12pt] leading-[14pt]">
                              {props.subMenu == "exist-pm"
                                ? "PM_PE_VC-Advisor"
                                : props.subMenu == "exit-pm-pe-vc"
                                  ? "MA_VC-Advisor"
                                  : props.subMenu == "exist-ma"
                                    ? "MA_PE_VC-Advisor"
                                    : " Advisor Exits "}
                            </span>

                            <span>
                              <svg
                                className="fill-current h-4 w-4 transform group-hover:-rotate-180
                                            transition duration-150 ease-in-out"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                              >
                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                              </svg>
                            </span>
                          </button>
                          <ul
                            className="mt-2 bg-white rounded-lg shadow-lg lg:ring-1 ring-black ring-opacity-5 transform scale-0 group-hover:scale-100 z-10 absolute 
                                    transition duration-150 ease-in-out origin-top min-w-[7.5rem]  lg:min-w-[12.5rem]"
                          >
                            {props.subMenu == "exist-pm" ? (
                              <li className="rounded-sm relative font-sans_book_body  px-6 py-2 hover:bg-gray-100  ">
                                <NavLink
                                  to="/advisors/ma/vc-limited-partners"
                                  className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                >
                                  <span onClick={() => setCurrentPage(1)} className=" flex-1 text-[#A5711A] text-[13px] leading-[15px]">
                                    Advisor Exits
                                  </span>
                                  <span className="mr-auto pl-16">
                                    <svg
                                      className="fill-current h-4 w-4
                                                transition duration-150 ease-in-out"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                    >
                                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                    </svg>
                                  </span>
                                </NavLink>

                                <ul
                                  className="bg-white border border-gray-300 rounded-sm absolute top-0  
                                                    transition duration-150 ease-in-out origin-top-left
                                                    min-w-32"
                                >
                                  <li className="rounded-sm relative font-sans_book_body  px-6 py-2 hover:bg-gray-100 ">
                                    <NavLink
                                      to="/advisors/ma/pe-vc-limited-partners"
                                      className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                    >
                                      <span onClick={() => setCurrentPage(1)} className="pr-1 flex-1 text-[#A5711A] text-[13px] leading-[15px]">
                                        MA_PE_VC-Advisor
                                      </span>
                                      <span className="mr-auto">
                                        
                                      </span>
                                    </NavLink>
                                   
                                  </li>
                                  <li className="rounded-sm relative font-sans_book_body  px-6 py-2 hover:bg-gray-100 ">
                                    <NavLink
                                      to="/advisors/pm/pe-vc-limited-partners"
                                      className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                    >
                                      <span onClick={() => setCurrentPage(1)} className="pr-1 flex-1 text-[#A5711A] text-[13px] leading-[15px]">
                                        PM_PE_VC-Advisor
                                      </span>
                                      <span className="mr-auto">
                                       
                                      </span>
                                    </NavLink>
                                    
                                  </li>
                                </ul>
                              </li>
                            ) : props.subMenu == "exit-pm-pe-vc" ? (
                              <li className="rounded-sm relative font-sans_book_body  px-6 py-2 hover:bg-gray-100 ">
                                <NavLink
                                  to="/advisors/ma/vc-limited-partners"
                                  className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                >
                                  <span onClick={() => setCurrentPage(1)} className=" flex-1 text-[#A5711A] text-[13px] leading-[15px]">
                                    Advisor Exits
                                  </span>
                                  <span className="mr-auto pl-16">
                                    <svg
                                      className="fill-current h-4 w-4
                                          transition duration-150 ease-in-out"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                    >
                                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                    </svg>
                                  </span>
                                </NavLink>

                                <ul
                                  className="bg-white border border-gray-300 rounded-sm absolute top-0 md:right-0 right-[3%]
                                          transition duration-150 ease-in-out origin-top-left
                                          min-w-32"
                                >
                                  <li className="rounded-sm relative font-sans_book_body  px-6 py-2 hover:bg-gray-100  ">
                                    <NavLink
                                      to="/advisors/ma/pe-vc-limited-partners"
                                      className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                    >
                                      <span onClick={() => setCurrentPage(1)} className="pr-1 flex-1 text-[#A5711A] text-[13px] leading-[15px]">
                                        MA_PE_VC-Advisor
                                      </span>
                                      <span className="mr-auto">
                                       
                                      </span>
                                    </NavLink>
                                   
                                  </li>
                                  <li className="rounded-sm relative font-sans_book_body  px-6 py-2 hover:bg-gray-100  ">
                                    <NavLink
                                      to="/advisors/pm/pe-vc-limited-partners"
                                      className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                    >
                                      <span onClick={() => setCurrentPage(1)} className="pr-1 flex-1 text-[#A5711A] text-[13px] leading-[15px]">
                                        PM_PE_VC-Advisor
                                      </span>
                                      <span className="mr-auto">
                                       
                                      </span>
                                    </NavLink>
                                    
                                  </li>
                                </ul>
                              </li>
                            ) : props.subMenu == "exist-ma" ? (
                              <li className="rounded-sm relative font-sans_book_body  px-6 py-2 hover:bg-gray-100  ">
                                <NavLink
                                  to="/advisors/ma/vc-limited-partners"
                                  className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                >
                                  <span onClick={() => setCurrentPage(1)} className=" flex-1 text-[#A5711A] text-[13px] leading-[15px]">
                                    Advisor Exits
                                  </span>
                                  <span className="mr-auto pl-16">
                                    <svg
                                      className="fill-current h-4 w-4
                                                            transition duration-150 ease-in-out"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                    >
                                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                    </svg>
                                  </span>
                                </NavLink>

                                <ul
                                  className="bg-white border border-gray-300 rounded-sm absolute top-0 md:right-0 right-[3%]
                                      transition duration-150 ease-in-out origin-top-left
                                      min-w-32"
                                >
                                  <li className="rounded-sm relative font-sans_book_body  px-6 py-2 hover:bg-gray-100  ">
                                    <NavLink
                                      to="/advisors/ma/pe-vc-limited-partners"
                                      className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                    >
                                      <span onClick={() => setCurrentPage(1)} className="pr-1 flex-1 text-[#A5711A] text-[13px] leading-[15px]">
                                        MA_PE_VC-Advisor
                                      </span>
                                      <span className="mr-auto">
                                        
                                      </span>
                                    </NavLink>
                                   
                                  </li>
                                  <li className="rounded-sm relative font-sans_book_body  px-6 py-2 hover:bg-gray-100  ">
                                    <NavLink
                                      to="/advisors/pm/pe-vc-limited-partners"
                                      className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                    >
                                      <span onClick={() => setCurrentPage(1)} className="pr-1 flex-1 text-[#A5711A] text-[13px] leading-[15px]">
                                        PM_PE_VC-Advisor
                                      </span>
                                      <span className="mr-auto">
                                       
                                      </span>
                                    </NavLink>
                                    
                                  </li>
                                </ul>
                              </li>
                            ) : props.subMenu == "exist-pm" ? (
                              <li className="rounded-sm relative font-sans_book_body  px-6 py-2 hover:bg-gray-100  ">
                                <NavLink
                                  to="/advisors/ma/vc-limited-partners"
                                  className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                >
                                  <span onClick={() => setCurrentPage(1)} className=" flex-1 text-[#A5711A] text-[13px] leading-[15px]">
                                    Advisor Exits
                                  </span>
                                  <span className="mr-auto pl-16">
                                   
                                  </span>
                                </NavLink>

                                <ul
                                  className="bg-white border border-gray-300 rounded-sm absolute top-0 md:right-0 right-[3%]
                                                    transition duration-150 ease-in-out origin-top-left
                                                    min-w-32"
                                >
                                  <li className="rounded-sm relative font-sans_book_body  px-6 py-2 hover:bg-gray-100  ">
                                    <NavLink
                                      to="/advisors/ma/pe-vc-limited-partners"
                                      className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                    >
                                      <span onClick={() => setCurrentPage(1)} className="pr-1 flex-1 text-[#A5711A] text-[13px] leading-[15px]">
                                        MA_PE_VC-Advisor
                                      </span>
                                      <span className="mr-auto">
                                        
                                      </span>
                                    </NavLink>
                                    
                                  </li>
                                  <li className="rounded-sm relative font-sans_book_body  px-6 py-2 hover:bg-gray-100  ">
                                    <NavLink
                                      to="/advisors/pm/pe-vc-limited-partners"
                                      className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                    >
                                      <span onClick={() => setCurrentPage(1)} className="pr-1 flex-1 text-[#A5711A] text-[13px] leading-[15px]">
                                        PM_PE_VC-Advisor
                                      </span>
                                      <span className="mr-auto">
                                      
                                      </span>
                                    </NavLink>
                                    
                                  </li>
                                </ul>
                              </li>
                            ) : (
                              <ul
                                className="bg-white border border-gray-300 rounded-sm absolute top-0 md:right-0 right-[3%]
                                      transition duration-150 ease-in-out origin-top-left
                                      min-w-32"
                              >
                                <li className="rounded-sm relative font-sans_book_body  px-6 py-2 hover:bg-gray-100  ">
                                  <NavLink
                                    to="/advisors/ma/pe-vc-limited-partners"
                                    className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                  >
                                    <span onClick={() => setCurrentPage(1)} className="pr-1 flex-1 text-[#A5711A] text-[13px] leading-[15px]">
                                      MA_PE_VC-Advisor
                                    </span>
                                    <span className="mr-auto">
                                     
                                    </span>
                                  </NavLink>
                                  
                                </li>
                                <li className="rounded-sm relative font-sans_book_body  px-6 py-2 hover:bg-gray-100  ">
                                  <NavLink
                                    to="/advisors/pm/pe-vc-limited-partners"
                                    className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                  >
                                    <span onClick={() => setCurrentPage(1)} className="pr-1 flex-1 text-[#A5711A] text-[13px] leading-[15px]">
                                      PM_PE_VC-Advisor
                                    </span>
                                    <span className="mr-auto">
                                      
                                    </span>
                                  </NavLink>
                                 
                                </li>
                              </ul>
                            )}
                          </ul>
                        </div>
                      </NavLink>
                    </div>
                  </div>
                )
                  // end of adivosr limited partner
                  : props.menu == "tadvisor" ? (
                    <div className="flex flex-row space-x-5">
                      <div className="py-4 " id="link1">
                        <NavLink
                          className={
                            "whitespace-nowrap lg:text-[14px] lg:leading-[17px] text-[12pt] leading-[17pt]" +
                            (openTab == 1
                              ? " font-bold text-[#333333]  text-[#2B2A29]  border-b-[3px] border-[#F1CA75] py-4 pt-[1.1rem]"
                              : "whitespace-nowrap font-sans_medium_body text-[#2B2A29] opacity-[.8] hover:opacity-100 ")
                          }
                          data-toggle="tab"
                          to={
                            props.subMenu == "vc"
                              ? routeNames.pe_transaction_advisors_ma_pe_vc_advisors
                              : routeNames.pe_transaction_advisors_pe_vc_advisors
                          }
                          role="tablist"
                        >
                          <div className="group inline-block">
                            {/* mywork */}
                            <button onClick={() => setCurrentPage(1)} className="outline-none focus:outline-none py-1 rounded-sm flex items-center ">
                              <span className=" flex lg:text-[14px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                {props.subMenu == "vc"
                                  ? "Vc Advisor"
                                  : "PE-VC Investment"}
                              </span>

                            </button>

                          </div>
                        </NavLink>
                      </div>
                      <div className="py-4 pl-2 lg:pl-0" id="link2">
                        <NavLink
                          className={
                            "whitespace-nowrap lg:text-[14px] lg:leading-[17px] text-[12pt] leading-[17pt]" +
                            (openTab == 2
                              ? " font-bold text-[#333333]  text-[#2B2A29]  border-b-[3px] border-[#F1CA75] py-4 pt-[1.1rem]"
                              : "whitespace-nowrap font-sans_medium_body text-[#2B2A29] opacity-[.8] hover:opacity-100 ")
                          }
                          to={
                            props.subMenu == "exist-ma"
                              ? routeNames.pe_transaction_advisors_ma_pe_vc_advisors
                              : props.subMenu == "exist-ma-ma"
                                ? routeNames.pe_transaction_advisors_ma_advisors_exits
                                : props.subMenu == "exist-pm-pm"
                                  ? routeNames.pe_transaction_advisors_pm_pe_vc_advisors
                                  : routeNames.pe_transaction_advisors_ma_advisors_exits
                          }
                          data-toggle="tab"
                          role="tablist"
                        >
                          {isFromGlobalSearch() ?
                            <div className="group inline-block">
                              {/* //mywork// */}
                              <button onClick={() => setCurrentPage(1)} className="outline-none   justify-between focus:outline-none py-1   rounded-sm flex items-center ">
                                <span className=" flex-1 lg:text-[14px] lg:leading-[15px] text-[12pt] leading-[14pt]">
                                  {props.subMenu == "exist-pm"
                                    ? "Via Public Market (PE-VC)"
                                    : props.subMenu == "exit-pm-pe-vc"
                                      ? "PE-VC Exits"
                                      : props.subMenu == "exist-ma"
                                        ? "Via M&A (PE-VC)"
                                        : "PE-VC Exits"}
                                </span>


                              </button>

                            </div>
                            :
                            <div className="group inline-block">
                              {/* //mywork// */}
                              <button onClick={() => setCurrentPage(1)} className="outline-none   justify-between focus:outline-none py-1   rounded-sm flex items-center ">
                                <span className=" flex-1 lg:text-[14px] lg:leading-[15px] text-[12pt] leading-[14pt]">
                                  {props.subMenu == "exist-pm"
                                    ? "Via Public Market (PE-VC)"
                                    : props.subMenu == "exit-pm-pe-vc"
                                      ? "PE-VC Exits"
                                      : props.subMenu == "exist-ma"
                                        ? "Via M&A (PE-VC)"
                                        : "PE-VC Exits"}
                                </span>

                                <span>
                                  <svg
                                    className="fill-current h-4 w-4 transform group-hover:-rotate-180
                                         transition duration-150 ease-in-out"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                  >
                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                  </svg>
                                </span>
                              </button>
                              <ul
                                className="mt-2 bg-white rounded-lg shadow-lg lg:ring-1 ring-black ring-opacity-5 transform scale-0 group-hover:scale-100 z-10 absolute 
                                 transition duration-150 ease-in-out origin-top min-w-[9rem] md:min-w-[12.5rem]"
                              >
                                {props.subMenu == "exist-pm" ? (


                                  <ul
                                    className=" bg-white  py-1 rounded-lg shadow-lg lg:ring-1 ring-black ring-opacity-5 absolute top-0 md:right-0 right-[26px] transition duration-150 ease-in-out origin-top-left min-w-32"
                                  >
                                    <li className="rounded-sm relative font-sans_book_body  px-6 py-2 hover:bg-gray-100 ">
                                      <NavLink
                                        to={routeNames.pe_transaction_advisors_ma_advisors_exits}
                                        className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                      >
                                        <span onClick={() => setCurrentPage(1)} className=" flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                          PE-VC Exits
                                        </span>

                                      </NavLink>
                                    </li>
                                    <li className="rounded-sm relative font-sans_book_body  px-6 py-2 hover:bg-gray-100  ">
                                      <NavLink
                                        to={routeNames.pe_transaction_advisors_ma_pe_vc_advisors}
                                        className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                      >
                                        <span onClick={() => setCurrentPage(1)} className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                          Via M&A (PE-VC)
                                        </span>
                                        <span className="mr-auto">

                                        </span>
                                      </NavLink>

                                    </li>
                                   
                                  </ul>

                                ) : props.subMenu == "exit-pm-pe-vc" ? (


                                  <ul
                                    className=" bg-white  py-1 rounded-lg shadow-lg lg:ring-1 ring-black ring-opacity-5 absolute top-0 md:right-0 right-[26px] transition duration-150 ease-in-out origin-top-left min-w-32"
                                  >
                                    <li className="rounded-sm relative font-sans_book_body  px-6 py-2 hover:bg-gray-100  ">
                                      <NavLink
                                        to={routeNames.pe_transaction_advisors_ma_advisors_exits}
                                        className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                      >
                                        <span onClick={() => setCurrentPage(1)} className=" flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                          PE-VC Exits
                                        </span>

                                      </NavLink>
                                    </li>
                                    <li className="rounded-sm relative font-sans_book_body  px-6 py-2 hover:bg-gray-100  ">
                                      <NavLink
                                        to={routeNames.pe_transaction_advisors_ma_pe_vc_advisors}
                                        className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                      >
                                        <span onClick={() => setCurrentPage(1)} className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                          Via M&A (PE-VC)
                                        </span>
                                        <span className="mr-auto">

                                        </span>
                                      </NavLink>

                                    </li>
                                    <li className="rounded-sm relative font-sans_book_body  px-6 py-2 hover:bg-gray-100  ">
                                      <NavLink
                                        to={routeNames.pe_transaction_advisors_pm_pe_vc_advisors}
                                        className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                      >
                                        <span onClick={() => setCurrentPage(1)} className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                          Via Public Market (PE-VC)
                                        </span>
                                        <span className="mr-auto">

                                        </span>
                                      </NavLink>

                                    </li>
                                  </ul>

                                ) : props.subMenu == "exist-ma" ? (


                                  <ul
                                    className=" bg-white  py-1 rounded-lg shadow-lg lg:ring-1 ring-black ring-opacity-5 absolute top-0 md:right-0 right-[26px] transition duration-150 ease-in-out origin-top-left min-w-32"
                                  >
                                    <li className="rounded-sm relative font-sans_book_body  px-6 py-2 hover:bg-gray-100  ">
                                      <NavLink
                                        to={routeNames.pe_transaction_advisors_ma_advisors_exits}
                                        className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                      >
                                        <span onClick={() => setCurrentPage(1)} className=" flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                          PE-VC Exits
                                        </span>

                                      </NavLink>
                                    </li>
                                  
                                    <li className="rounded-sm relative font-sans_book_body  px-6 py-2 hover:bg-gray-100  ">
                                      <NavLink
                                        to={routeNames.pe_transaction_advisors_pm_pe_vc_advisors}
                                        className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                      >
                                        <span onClick={() => setCurrentPage(1)} className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                          Via Public Market (PE-VC)
                                        </span>
                                        <span className="mr-auto">

                                        </span>
                                      </NavLink>

                                    </li>
                                  </ul>

                                ) : props.subMenu == "exist-pm" ? (


                                  <ul
                                    className=" bg-white  py-1 rounded-lg shadow-lg lg:ring-1 ring-black ring-opacity-5 absolute top-0 md:right-0 right-[26px] transition duration-150 ease-in-out origin-top-left min-w-32"
                                  >
                                    <li className="rounded-sm relative font-sans_book_body  px-6 py-2 hover:bg-gray-100 ">
                                      <NavLink
                                        to={routeNames.pe_transaction_advisors_ma_advisors_exits}
                                        className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                      >
                                        <span onClick={() => setCurrentPage(1)} className=" flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                          PE-VC Exits
                                        </span>
                                        <span className="mr-auto pl-16">

                                        </span>
                                      </NavLink>
                                    </li>
                                    <li className="rounded-sm relative font-sans_book_body  px-6 py-2 hover:bg-gray-100  ">
                                      <NavLink
                                        to={routeNames.pe_transaction_advisors_ma_pe_vc_advisors}
                                        className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                      >
                                        <span onClick={() => setCurrentPage(1)} className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                          Via M&A (PE-VC)
                                        </span>
                                        <span className="mr-auto">

                                        </span>
                                      </NavLink>

                                    </li>
                                    <li className="rounded-sm relative font-sans_book_body  px-6 py-2 hover:bg-gray-100  ">
                                      <NavLink
                                        to={routeNames.pe_transaction_advisors_pm_pe_vc_advisors}
                                        className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                      >
                                        <span onClick={() => setCurrentPage(1)} className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                          Via Public Market (PE-VC)
                                        </span>
                                        <span className="mr-auto">

                                        </span>
                                      </NavLink>

                                    </li>
                                  </ul>

                                ) : (
                                  <ul
                                    className=" bg-white  py-1 rounded-lg shadow-lg lg:ring-1 ring-black ring-opacity-5 absolute top-0 md:right-0 right-[26px] transition duration-150 ease-in-out origin-top-left min-w-32"
                                  >
                                    <li className="rounded-sm relative font-sans_book_body  px-6 py-2 hover:bg-gray-100  ">
                                      <NavLink
                                        to={routeNames.pe_transaction_advisors_ma_pe_vc_advisors}
                                        className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                      >
                                        <span onClick={() => setCurrentPage(1)} className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                          Via M&A (PE-VC)
                                        </span>
                                        <span className="mr-auto">

                                        </span>
                                      </NavLink>

                                    </li>
                                    <li className="rounded-sm relative font-sans_book_body  px-6 py-2 hover:bg-gray-100  ">
                                      <NavLink
                                        to={routeNames.pe_transaction_advisors_pm_pe_vc_advisors}
                                        className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                      >
                                        <span onClick={() => setCurrentPage(1)} className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                          Via Public Market (PE-VC)                               </span>
                                        <span className="mr-auto">

                                        </span>
                                      </NavLink>

                                    </li>
                                  </ul>
                                )}
                              </ul>
                            </div>


                          }

                        </NavLink>
                      </div>

                    </div>

                  )
                    // start of trans limited partner
                    : props.menu == "tlimitedadvisor" ? (
                      <div className="flex flex-row space-x-5">
                        <div className="py-4 " id="link1">
                          <NavLink
                            className={
                              "whitespace-nowrap lg:text-[14px] lg:leading-[17px] text-[12pt] leading-[17pt]" +
                              (openTab == 1
                                ? " font-bold  text-[#2B2A29]  border-b-[3px] border-[#F1CA75] py-4 pt-[1.1rem]"
                                : "whitespace-nowrap font-sans_medium_body text-[#2B2A29] opacity-[.8] hover:opacity-100 ")
                            }
                            data-toggle="tab"
                            to={
                              props.subMenu == "vc"
                                ? "/transaction-advisor/vc-limited-partners"
                                : "/transaction-advisor/pe-vc-limited-partners"
                            }
                            role="tablist"
                          >
                            <div className="group inline-block">
                              {/* mywork */}
                              <button onClick={() => setCurrentPage(1)} className="outline-none  focus:outline-none py-1  rounded-sm flex items-center ">
                                <span className=" flex lg:text-[14px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                  {props.subMenu == "vc"
                                    ? "Vc Advisor"
                                    : "PE-VC Advisor"}
                                </span>
                                
                              </button>
                             
                            </div>
                          </NavLink>
                        </div>
                        <div className="py-4 pl-2 lg:pl-0" id="link2">
                          <NavLink
                            className={
                              "whitespace-nowrap lg:text-[14px] lg:leading-[17px] text-[12pt] leading-[17pt]" +
                              (openTab == 2
                                ? " font-bold text-[#2B2A29]  border-b-[3px] border-[#F1CA75] py-4 pt-[1.1rem]"
                                : "whitespace-nowrap font-sans_medium_body text-[#2B2A29] opacity-[.8] hover:opacity-100 ")
                            }
                            to={
                              props.subMenu == "exist-ma"
                                ? "/transaction-advisor/ma/vc-limited-partners"
                                : props.subMenu == "exist-ma-ma"
                                  ? "/transaction-advisor/ma/pe-vc-limited-partners"
                                  : props.subMenu == "exist-pm-pm"
                                    ? "/transaction-advisor/pm/pe-vc-limited-partners"
                                    : "/transaction-advisor/ma/pe-vc-limited-partners"
                            }
                            data-toggle="tab"
                            role="tablist"
                          >
                            <div className="group inline-block">
                              {/* //mywork// */}
                              <button onClick={() => setCurrentPage(1)} className="outline-none  justify-between focus:outline-none py-1  rounded-sm flex items-center ">
                                <span className=" flex-1 lg:text-[14px] lg:leading-[15px] text-[12pt] leading-[14pt]">
                                  {props.subMenu == "exist-pm"
                                    ? "PM_PE_VC-Advisor"
                                    : props.subMenu == "exit-pm-pe-vc"
                                      ? "MA_VC-Advisor"
                                      : props.subMenu == "exist-ma"
                                        ? "MA_PE_VC-Advisor"
                                        : " Advisor Exits "}
                                </span>

                                <span>
                                  <svg
                                    className="fill-current h-4 w-4 transform group-hover:-rotate-180
                                            transition duration-150 ease-in-out"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                  >
                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                  </svg>
                                </span>
                              </button>
                              <ul
                                className="mt-2 bg-white rounded-lg shadow-lg lg:ring-1 ring-black ring-opacity-5 transform scale-0 group-hover:scale-100 z-10 absolute transition duration-150 ease-in-out origin-top min-w-[7.5rem]  lg:min-w-[12.5rem]"
                              >
                                {props.subMenu == "exist-pm" ? (
                                  <li className="rounded-sm relative px-3 py-1 hover:bg-gray-100 ">
                                    <NavLink
                                      to="/transaction-advisor/ma/vc-limited-partners"
                                      className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                    >
                                      <span onClick={() => setCurrentPage(1)} className=" flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                        Advisor Exits
                                      </span>
                                      <span className="mr-auto pl-16">
                                        <svg
                                          className="fill-current h-4 w-4
                                        transition duration-150 ease-in-out"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 20 20"
                                        >
                                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                        </svg>
                                      </span>
                                    </NavLink>

                                    <ul
                                      className="bg-white border border-gray-300 rounded-sm absolute top-0 md:right-0 right-[3%]
                                  transition duration-150 ease-in-out origin-top-left
                                  min-w-32"
                                    >
                                      <li className="rounded-sm relative px-3 py-1 hover:bg-gray-100 ">
                                        <NavLink
                                          to="/transaction-advisor/ma/pe-vc-limited-partners"
                                          className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                        >
                                          <span onClick={() => setCurrentPage(1)} className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                            MA_PE_VC-Advisor
                                          </span>
                                          <span className="mr-auto">
                                           
                                          </span>
                                        </NavLink>

                                      </li>
                                      <li className="rounded-sm relative px-3 py-1 hover:bg-gray-100 ">
                                        <NavLink
                                          to="/transaction-advisor/pm/pe-vc-limited-partners"
                                          className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                        >
                                          <span onClick={() => setCurrentPage(1)} className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                            PM_PE_VC-Advisor
                                          </span>
                                          <span className="mr-auto">
                                           
                                          </span>
                                        </NavLink>
                                        
                                      </li>
                                    </ul>
                                  </li>
                                ) : props.subMenu == "exit-pm-pe-vc" ? (
                                  <li className="rounded-sm relative px-3 py-1 hover:bg-gray-100 ">
                                    <NavLink
                                      to="/advisors/ma/vc-limited-partners"
                                      className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                    >
                                      <span onClick={() => setCurrentPage(1)} className=" flex-1 text-[#A5711A] text-[13px] leading-[15px]">
                                        Advisor Exits
                                      </span>
                                      <span className="mr-auto pl-16">
                                        <svg
                                          className="fill-current h-4 w-4
                                                          transition duration-150 ease-in-out"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 20 20"
                                        >
                                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                        </svg>
                                      </span>
                                    </NavLink>

                                    <ul
                                      className="bg-white border border-gray-300 rounded-sm absolute top-0 md:right-0 right-[3%] 
                                        transition duration-150 ease-in-out origin-top-left
                                        min-w-32"
                                    >
                                      <li className="rounded-sm relative px-3 py-1 hover:bg-gray-100 ">
                                        <NavLink
                                          to="/advisors/ma/pe-vc-limited-partners"
                                          className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                        >
                                          <span onClick={() => setCurrentPage(1)} className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                            MA_PE_VC-Advisor
                                          </span>
                                          <span className="mr-auto">
                                            
                                          </span>
                                        </NavLink>
                                        
                                      </li>
                                      <li className="rounded-sm relative px-3 py-1 hover:bg-gray-100 ">
                                        <NavLink
                                          to="/transaction-advisor/pm/pe-vc-limited-partners"
                                          className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                        >
                                          <span onClick={() => setCurrentPage(1)} className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                            PM_PE_VC-Advisor
                                          </span>
                                          <span className="mr-auto">
                                           
                                          </span>
                                        </NavLink>
                                       
                                      </li>
                                    </ul>
                                  </li>
                                ) : props.subMenu == "exist-ma" ? (
                                  <li className="rounded-sm relative px-3 py-1 hover:bg-gray-100 ">
                                    <NavLink
                                      to="/transaction-advisor/ma/vc-limited-partners"
                                      className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                    >
                                      <span conClick={() => setCurrentPage(1)} lassName=" flex-1 text-[#A5711A] text-[13px] leading-[15px]">
                                        Advisor Exits
                                      </span>
                                      <span className="mr-auto pl-16">
                                        <svg
                                          className="fill-current h-4 w-4
                                            transition duration-150 ease-in-out"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 20 20"
                                        >
                                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                        </svg>
                                      </span>
                                    </NavLink>

                                    <ul
                                      className="bg-white border border-gray-300 rounded-sm absolute top-0 md:right-0 right-[3%]
                                              transition duration-150 ease-in-out origin-top-left
                                              min-w-32"
                                    >
                                      <li className="rounded-sm relative px-3 py-1 hover:bg-gray-100 ">
                                        <NavLink
                                          to="/transaction-advisor/ma/pe-vc-limited-partners"
                                          className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                        >
                                          <span onClick={() => setCurrentPage(1)} className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                            MA_PE_VC-Advisor
                                          </span>
                                          <span className="mr-auto">
                                           
                                          </span>
                                        </NavLink>
                                       
                                      </li>
                                      <li className="rounded-sm relative px-3 py-1 hover:bg-gray-100 ">
                                        <NavLink
                                          to="/transaction-advisor/pm/pe-vc-limited-partners"
                                          className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                        >
                                          <span onClick={() => setCurrentPage(1)} className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                            PM_PE_VC-Advisor
                                          </span>
                                          <span className="mr-auto">
                                            
                                          </span>
                                        </NavLink>
                                      
                                      </li>
                                    </ul>
                                  </li>
                                ) : props.subMenu == "exist-pm" ? (
                                  <li className="rounded-sm relative px-3 py-1 hover:bg-gray-100 ">
                                    <NavLink
                                      to="/transaction-advisor/ma/vc-limited-partners"
                                      className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                    >
                                      <span onClick={() => setCurrentPage(1)} className=" flex-1 text-[#A5711A] text-[13px] leading-[15px]">
                                        Advisor Exits
                                      </span>
                                      <span className="mr-auto pl-16">
                                        <svg
                                          className="fill-current h-4 w-4
                                            transition duration-150 ease-in-out"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 20 20"
                                        >
                                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                        </svg>
                                      </span>
                                    </NavLink>

                                    <ul
                                      className="bg-white border border-gray-300 rounded-sm absolute top-0 md:right-0 right-[3%] 
                                       transition duration-150 ease-in-out origin-top-left min-w-32"
                                    >
                                      <li className="rounded-sm relative px-3 py-1 hover:bg-gray-100 ">
                                        <NavLink
                                          to="/transaction-advisor/ma/pe-vc-limited-partners"
                                          className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                        >
                                          <span onClick={() => setCurrentPage(1)} className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                            MA_PE_VC-Advisor
                                          </span>
                                          <span className="mr-auto">

                                          </span>
                                        </NavLink>

                                      </li>
                                      <li className="rounded-sm relative px-3 py-1 hover:bg-gray-100 ">
                                        <NavLink
                                          to="/transaction-advisor/pm/pe-vc-limited-partners"
                                          className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                        >
                                          <span onClick={() => setCurrentPage(1)} className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                            PM_PE_VC-Advisor
                                          </span>
                                          <span className="mr-auto">

                                          </span>
                                        </NavLink>

                                      </li>
                                    </ul>
                                  </li>
                                ) : (
                                  <ul
                                    className="bg-white border border-gray-300 rounded-sm absolute top-0 md:right-0 right-[3%] 
                                  transition duration-150 ease-in-out origin-top-left
                                  min-w-32"
                                  >
                                    <li className="rounded-sm relative px-3 py-1 hover:bg-gray-100 ">
                                      <NavLink
                                        to="/transaction-advisor/ma/pe-vc-limited-partners"
                                        className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                      >
                                        <span onClick={() => setCurrentPage(1)} className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                          MA_PE_VC-Advisor
                                        </span>
                                        <span className="mr-auto">

                                        </span>
                                      </NavLink>

                                    </li>
                                    <li className="rounded-sm relative px-3 py-1 hover:bg-gray-100 ">
                                      <NavLink
                                        to="/transaction-advisor/pm/pe-vc-limited-partners"
                                        className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                      >
                                        <span onClick={() => setCurrentPage(1)} className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                          PM_PE_VC-Advisor
                                        </span>
                                        <span className="mr-auto">

                                        </span>
                                      </NavLink>

                                    </li>
                                  </ul>
                                )}
                              </ul>
                            </div>
                          </NavLink>
                        </div>
                      </div>

                    )
                      // end of trans limited partner


                      : props?.menu == "re" || props?.menu == "ma" ? (
                        <div className="flex bg-white px-3 rounded-md flex-row overflow-x-auto ">
                          <div className="flex flex-row space-x-3 ">
                            <div className="lg:py-4 py-5" id="link1">
                              <NavLink
                                className={
                                  "whitespace-nowrap lg:text-[14px] lg:leading-[17px] text-[14pt] leading-[17pt] " +
                                  (openTab == 1
                                    ? " font-bold  text-[#2B2A29]  border-b-[3px] border-[#F1CA75] py-4 pt-[1.1rem]"
                                    : " whitespace-nowrap font-sans_medium_body text-[#2B2A29] opacity-[.8] hover:opacity-100   ")
                                }
                                // onClick={e=> {
                                //     e.preventDefault();
                                //     setOpenTab(5)
                                // }}
                                data-toggle="tab"
                                to={routeNames.re_companies_re_investments}
                                role="tablist"
                              >
                                PE-RE Investments

                              </NavLink>

                            </div>
                            <div className="lg:py-4  py-5 lg:pl-1 pl-2 " id="link2">
                              <NavLink
                                className={
                                  "whitespace-nowrap lg:text-[14px] lg:leading-[17px] text-[14pt] leading-[17pt] " +
                                  (openTab == 2
                                    ? " font-bold  text-[#2B2A29]  border-b-[3px] border-[#F1CA75] py-4 pt-[1.1rem]"
                                    : " whitespace-nowrap font-sans_medium_body text-[#2B2A29] opacity-[.8] hover:opacity-100   ")
                                }

                                data-toggle="tab"
                                to={routeNames.re_companies_exists}
                                role="tablist"
                              >
                                PE-RE Exits via M&A

                              </NavLink>
                            </div>
                            <div className="lg:py-4  py-5 lg:pl-1 pl-2 " id="link3">
                              <NavLink
                                className={
                                  "whitespace-nowrap lg:text-[14px] lg:leading-[17px] text-[14pt] leading-[17pt] " +
                                  (openTab == 3
                                    ? " font-bold  text-[#2B2A29]  border-b-[3px] border-[#F1CA75] py-4 pt-[1.1rem]"
                                    : " whitespace-nowrap font-sans_medium_body text-[#2B2A29] opacity-[.8] hover:opacity-100   ")
                                }
                                // onClick={e=> {
                                //     e.preventDefault();
                                //     setOpenTab(5)
                                // }}
                                data-toggle="tab"
                                to={routeNames.re_companies_other_ma}
                                role="tablist"
                              >
                                Other M&A

                              </NavLink>
                            </div>

                            <div className="lg:py-4 py-5 pl-4  lg:pl-3" id="link4">
                              <NavLink
                                className={
                                  "whitespace-nowrap lg:text-[14px] lg:leading-[17px] text-[14pt] leading-[17pt] " +
                                  (openTab == 4
                                    ? "font-bold  text-[#2B2A29]  border-b-[3px] border-[#F1CA75] py-4 pt-[1.1rem]"
                                    : " whitespace-nowrap font-sans_medium_body text-[#2B2A29] opacity-[.8] hover:opacity-100  ")
                                }
                                // onClick={e=> {
                                //     e.preventDefault();
                                //     setOpenTab(4)
                                // }}
                                data-toggle="tab"
                                to={routeNames.re_companies_backed_ipos}
                                role="tablist"
                              >
                                PE-RE Backed IPOs
                              </NavLink>
                            </div>

                          </div>
                        </div>
                      )
                        : (
                          <div className="flex flex-row space-x-5 overflow-x-auto lg:overflow-hidden">
                            <div className="py-4 " id="link1">
                              <NavLink
                                className={
                                  "whitespace-nowrap lg:text-[14px] lg:leading-[17px] text-[12pt] leading-[17pt]" +
                                  (openTab == 1
                                    ? " font-bold  text-[#2B2A29]  border-b-[3px] border-[#F1CA75] py-4 pt-[1.1rem]"
                                    : "whitespace-nowrap font-sans_medium_body text-[#2B2A29] opacity-[.8] hover:opacity-100 ")
                                }
                                data-toggle="tab"
                                to={
                                  props.menu == "vc"
                                    ? routeNames.vc_companies_vc_investments
                                    : routeNames.PE_home
                                }
                                role="tablist"

                              >
                                <div className="group inline-block">
                                  {/* mywork */}
                                  <button onClick={() => setCurrentPage(1)} className="outline-none focus:outline-none py-1  rounded-sm flex items-center ">
                                    <span className=" flex lg:text-[14px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                      {props.menu == "vc"
                                        ? "VC Investments"
                                        : "PE-VC Investments"}
                                    </span>
                                  </button>
                                </div>
                              </NavLink>
                            </div>
                            <div className="py-4 pl-2 lg:pl-0" id="link2">
                              <NavLink
                                className={
                                  "whitespace-nowrap lg:text-[14px] lg:leading-[17px] text-[12pt] leading-[17pt]" +
                                  (openTab == 2
                                    ? " font-bold  text-[#2B2A29]  border-b-[3px] border-[#F1CA75] py-4 pt-[1.1rem]"
                                    : "whitespace-nowrap font-sans_medium_body text-[#2B2A29] opacity-[.8] hover:opacity-100 ")
                                }
                                to={
                                  props.menu == "exit-ma-pe-vc"
                                    ? routeNames.pe_companies_exists_ma_pe_vc_investments
                                    : props.menu == "exit-pm-pe-vc"
                                      ? routeNames.pe_companies_exists_pm_pe_vc_investments
                                      : props.menu == "exit-ma-vc"
                                        ? routeNames.vc_companies_exists_ma_vc_investments
                                        : props.menu == "exit-pm-vc"
                                          ? routeNames.vc_companies_exists_pm_vc_investments
                                          : routeNames.pe_companies_exists_pe_vc_investments
                                }
                                data-toggle="tab"
                                role="tablist"
                                state={{groupId:groupId}}
                              >
                                {isFromGlobalSearch() ?
                                  <div className="group inline-block">
                                    <button onClick={() => setCurrentPage(1)} className="outline-none w-30 focus:outline-none py-1  rounded-sm flex items-center ">
                                      <span className=" flex lg:text-[14px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                        {props.menu == "exit-ma-pe-vc"
                                          ? "via M&A (PE-VC)"
                                          : props.menu == "exit-pm-pe-vc"
                                            ? "via Public Market (PE-VC)"
                                            : props.menu == "exit-ma-vc"
                                              ? "M&A (VC)"
                                              : props.menu == "exit-pm-vc"
                                                ? "Public Market (VC)"
                                                : "PE-VC Exits"}
                                      </span>

                                    </button>

                                  </div>
                                  :
                                  <div className="group inline-block">
                                    <button onClick={() => setCurrentPage(1)} className="outline-none w-30 focus:outline-none py-1  rounded-sm flex items-center ">
                                      <span className=" flex lg:text-[14px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                        {props.menu == "exit-ma-pe-vc"
                                          ? "via M&A (PE-VC)"
                                          : props.menu == "exit-pm-pe-vc"
                                            ? "via Public Market (PE-VC)"
                                            : props.menu == "exit-ma-vc"
                                              ? "M&A (VC)"
                                              : props.menu == "exit-pm-vc"
                                                ? "Public Market (VC)"
                                                : "PE-VC Exits"}
                                      </span>

                                      <span>
                                        <svg
                                          className="fill-current h-4 w-4 transform group-hover:-rotate-180
                      transition duration-150 ease-in-out"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 20 20"
                                        >
                                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                        </svg>
                                      </span>
                                    </button>
                                    <ul
                                      className="mt-2 bg-white rounded-lg shadow-lg lg:ring-1 ring-black ring-opacity-5 transform scale-0 group-hover:scale-100 z-10 absolute 
              transition duration-150 ease-in-out origin-top min-w-[7.5rem]  lg:min-w-[12.5rem]"
                                    >
                                      {props.menu == "exit-ma-pe-vc" ?
                                      (


                                        <ul className=" bg-white  py-1 rounded-lg shadow-lg lg:ring-1 ring-black ring-opacity-5 absolute top-0 md:right-0 right-[26px] transition duration-150 ease-in-out origin-top-left min-w-32">


                                          <li className="rounded-sm font-sans_book_body px-6 py-2 relative hover:bg-gray-100 ">
                                            <NavLink
                                              to={routeNames.pe_companies_exists_pe_vc_investments}
                                              className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                            >
                                              {/* mywork */}
                                              {/* <div className=" px-10 text-left flex"> */}
                                              <span onClick={() => setCurrentPage(1)} className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                                PE-VC Exits
                                              </span>
                                            </NavLink>
                                          </li>
                                          <li className="rounded-sm font-sans_book_body px-6 py-2 relative  hover:bg-gray-100 ">
                                            <NavLink
                                              to={routeNames.pe_companies_exists_pm_pe_vc_investments}
                                              className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                            >
                                              <span onClick={() => setCurrentPage(1)} className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                                via Public Market (PE-VC)
                                              </span>
                                              <span className="mr-auto">
                                              </span>
                                            </NavLink>
                                          </li>
                                        </ul>

                                      ) : props.menu == "exit-pm-pe-vc" ? (


                                        <ul className=" bg-white  py-1 rounded-lg shadow-lg lg:ring-1 ring-black ring-opacity-5 absolute top-0 md:right-0 right-[26px] transition duration-150 ease-in-out origin-top-left min-w-32">
                                          <li className="rounded-sm relative font-sans_book_body  px-6 py-2 hover:bg-gray-100 ">
                                            <NavLink
                                              to={routeNames.pe_companies_exists_pe_vc_investments}
                                              className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                            >

                                              <span onClick={() => setCurrentPage(1)} className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                                PE-VC Exits
                                              </span>


                                            </NavLink>
                                          </li>
                                          <li className="rounded-sm font-sans_book_body px-6 py-2 relative  hover:bg-gray-100 ">

                                            <NavLink
                                              to={routeNames.pe_companies_exists_ma_pe_vc_investments}
                                              className=" navlink w-full text-left flex items-center z-30 outline-none focus:outline-none"
                                            >
                                              <span onClick={() => setCurrentPage(1)} className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                                Via M&A (PE-VC)
                                              </span>
                                              <span className="mr-auto">

                                              </span>
                                            </NavLink>

                                          </li>

                                        </ul>

                                      ) : props.menu == "exit-ma-vc" ? (

                                        <ul className="bg-white  py-3 rounded-lg shadow-lg lg:ring-1 ring-black ring-opacity-5 absolute top-0 md:right-0 right-[45px] transition duration-150 ease-in-out origin-top-left min-w-32">
                                          <li className="rounded-sm font-sans_book_body px-6 py-2 relative  hover:bg-gray-100 ">
                                            <NavLink
                                              to={routeNames.pe_companies_exists_pe_vc_investments}
                                              className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                            >
                                              {/* mywork */}
                                              {/* <div className=" px-10 text-left flex"> */}
                                              <span onClick={() => setCurrentPage(1)} className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                                PE-VC Exits
                                              </span>

                                            </NavLink>
                                          </li>
                                          <li className="rounded-sm font-sans_book_body px-6 py-2  relative  hover:bg-gray-100 ">


                                            <NavLink
                                              to={routeNames.pe_companies_exists_ma_pe_vc_investments}
                                              className=" navlink w-full text-left flex items-center z-30 outline-none focus:outline-none"
                                            >
                                              <span onClick={() => setCurrentPage(1)} className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                                Via M&A (PE-VC)
                                              </span>
                                              <span className="mr-auto">

                                              </span>
                                            </NavLink>

                                          </li>
                                          <li className="rounded-sm font-sans_book_body px-6 py-2 relative  hover:bg-gray-100 ">
                                            <NavLink
                                              to={routeNames.pe_companies_exists_pm_pe_vc_investments}
                                              className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                            >
                                              <span onClick={() => setCurrentPage(1)} className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                                via Public Market (PE-VC)
                                              </span>
                                              <span className="mr-auto">

                                              </span>
                                            </NavLink>

                                          </li>
                                        </ul>

                                      ) : props.menu == "exit-pm-vc" ? (


                                        <ul className="  bg-white  py-1 rounded-lg shadow-lg lg:ring-1 ring-black ring-opacity-5 absolute top-0 md:right-0 right-[26px] transition duration-150 ease-in-out origin-top-left min-w-32">

                                          <li className="rounded-sm font-sans_book_body px-6 py-2 relative hover:bg-gray-100 ">
                                            <NavLink
                                              to={routeNames.pe_companies_exists_pe_vc_investments}
                                              className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                            >
                                              {/* mywork */}
                                              {/* <div className=" px-10 text-left flex"> */}
                                              <span onClick={() => setCurrentPage(1)} className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                                PE-VC Exits
                                              </span>

                                            </NavLink>
                                          </li>
                                          <li className="rounded-sm font-sans_book_body px-6 py-2 relative  hover:bg-gray-100 ">

                                            <NavLink
                                              to={routeNames.pe_companies_exists_ma_pe_vc_investments}
                                              className=" navlink w-full text-left flex items-center z-30 outline-none focus:outline-none"
                                            >
                                              <span onClick={() => setCurrentPage(1)} className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                                Via M&A (PE-VC)
                                              </span>
                                              <span className="mr-auto">

                                              </span>
                                            </NavLink>

                                          </li>
                                          <li className="rounded-sm font-sans_book_body px-6 py-2 relative hover:bg-gray-100 ">
                                            <NavLink
                                              to={routeNames.pe_companies_exists_pm_pe_vc_investments}
                                              className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                            >
                                              <span onClick={() => setCurrentPage(1)} className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                                via Public Market (PE-VC)
                                              </span>
                                              <span className="mr-auto">

                                              </span>
                                            </NavLink>

                                          </li>
                                        </ul>

                                      ) : props.menu == "exit-ma-pe-vc" ? (


                                        <ul className="bg-white  py-3 rounded-lg shadow-lg lg:ring-1 ring-black ring-opacity-5 absolute top-0 md:right-0 right-[26px] transition duration-150 ease-in-out origin-top-left min-w-32">
                                          <li className="rounded-sm font-sans_book_body px-6 py-2 relative  hover:bg-gray-100 ">
                                            <NavLink
                                              to={routeNames.pe_companies_exists_pe_vc_investments}
                                              className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                            >
                                              {/* mywork */}
                                              {/* <div className=" px-10 text-left flex"> */}
                                              <span onClick={() => setCurrentPage(1)} className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                                PE-VC Exits
                                              </span>

                                            </NavLink>
                                          </li>
                                          <li className="rounded-sm font-sans_book_body px-6 py-2 relative  hover:bg-gray-100 ">

                                            <NavLink
                                              to={routeNames.pe_companies_exists_ma_pe_vc_investments}
                                              className=" navlink w-full text-left flex items-center z-30 outline-none focus:outline-none"
                                            >
                                              <span onClick={() => setCurrentPage(1)} className="pr-1 z-30 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                                Via M&A (PE-VC)
                                              </span>
                                              <span className="mr-auto">

                                              </span>
                                            </NavLink>

                                          </li>
                                          <li className="rounded-sm font-sans_book_body px-6 py-2 relative  hover:bg-gray-100 ">
                                            <NavLink
                                              to={routeNames.pe_companies_exists_pm_pe_vc_investments}
                                              className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                            >
                                              <span onClick={() => setCurrentPage(1)} className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                                via Public Market (PE-VC)
                                              </span>
                                              <span className="mr-auto">

                                              </span>
                                            </NavLink>

                                          </li>
                                        </ul>

                                      ) : (
                                        <ul className=" z-10 bg-white py-1 rounded-lg shadow-lg lg:ring-1 ring-black ring-opacity-5 absolute top-0 md:right-0 right-[3%] transition duration-150 ease-in-out origin-top-left min-w-32">
                                          <li className="rounded-sm font-sans_book_body px-6 py-2 relative  hover:bg-gray-100 ">
                                            <NavLink
                                              to={routeNames.pe_companies_exists_ma_pe_vc_investments}
                                              className=" navlink w-full text-left flex items-center z-30 outline-none focus:outline-none"
                                            >
                                              <span onClick={() => setCurrentPage(1)} className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                                Via M&A (PE-VC)
                                              </span>
                                              <span className="mr-auto">

                                              </span>
                                            </NavLink>

                                          </li>
                                          <li className="rounded-sm font-sans_book_body px-6 py-2 relative  hover:bg-gray-100 ">
                                            <NavLink
                                              to={routeNames.pe_companies_exists_pm_pe_vc_investments}
                                              className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                            >
                                              <span onClick={() => setCurrentPage(1)} className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                                via Public Market (PE-VC)
                                              </span>
                                              <span className="mr-auto">

                                              </span>
                                            </NavLink>

                                          </li>
                                        </ul>
                                      )}
                                    </ul>
                                  </div>

                                }

                              </NavLink>
                            </div>
                            <div className="py-4" id="link3">
                              <NavLink
                                className={
                                  "whitespace-nowrap lg:text-[14px] lg:leading-[17px] text-[12pt] leading-[17pt] " +
                                  (openTab == 3
                                    ? " font-bold  text-[#2B2A29]  border-b-[3px] border-[#F1CA75] py-4 pt-[1.1rem]"
                                    : " whitespace-nowrap font-sans_medium_body text-[#2B2A29] opacity-[.8] hover:opacity-100  ")
                                }
                                to={
                                  props.menu == "VC-IPOs"
                                    ? routeNames.vc_companies_backed_ipos_vc_investments
                                    : routeNames.pe_companies_backed_ipos_pe_vc_investments
                                }
                                data-toggle="tab"
                                role="tablist"
                              >
                                <div className="group inline-block">
                                  {/* mywork */}
                                  <button onClick={() => setCurrentPage(1)} className="outline-none  focus:outline-none py-1  rounded-sm flex items-center ">
                                    <span className=" flex lg:text-[14px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                      {props.menu == "VC-IPOs"
                                        ? "VC Backed IPOs"
                                        : "PE-VC Backed IPOs"}
                                    </span>
                                    <span>

                                    </span>
                                  </button>

                                </div>
                              </NavLink>
                            </div>

                            <div className="lg:py-4 py-4 pl-3 lg:pl-0" id="link4">
                              <NavLink
                                className={
                                  "whitespace-nowrap lg:text-[14px] lg:leading-[17px] text-[12pt] leading-[15pt] " +
                                  (openTab == 4
                                    ? " font-bold lg:text-[14px] lg:leading-[17px] text-[12pt] leading-[15pt]  text-[#2B2A29]  border-b-[3px] border-[#F1CA75] py-4 pt-[1.1rem]"
                                    : " whitespace-nowrap lg:text-[14px] lg:leading-[17px] text-[12pt] leading-[15pt] font-sans_medium_body text-[#2B2A29] opacity-[.8] hover:opacity-100  ")
                                }
                                // onClick={e=> {
                                //     e.preventDefault();
                                //     setOpenTab(4)
                                // }}
                                data-toggle="tab"
                                to={routeNames.pe_companies_angel_investments}
                                role="tablist"
                              >
                                <span className=" flex-1 lg:text-[14px] lg:leading-[15px] text-[12pt] leading-[14pt]" onClick={() => setCurrentPage(1)}>Angel Investments</span>
                              </NavLink>
                            </div>
                            <div className="lg:py-4 lg:pl-1 pl-2 py-4 " id="link5">
                              <NavLink
                                className={
                                  "whitespace-nowrap lg:text-[14px] lg:leading-[17px] text-[12pt] leading-[15pt] " +
                                  (openTab == 5
                                    ? " font-bold lg:text-[14px] lg:leading-[17px] text-[12pt] leading-[15pt] text-[#2B2A29]  border-b-[3px] border-[#F1CA75] py-4 pt-[1.1rem]"
                                    : " whitespace-nowrap lg:text-[14px] lg:leading-[17px] text-[12pt] leading-[15pt] font-sans_medium_body text-[#2B2A29] opacity-[.8] hover:opacity-100   ")
                                }
                                // onClick={e=> {
                                //     e.preventDefault();
                                //     setOpenTab(5)
                                // }}
                                data-toggle="tab"
                                to={routeNames.pe_companies_incubation}
                                role="tablist"
                              >
                                <span className=" flex-1 lg:text-[14px] lg:leading-[15px] text-[12pt] leading-[14pt]" onClick={() => setCurrentPage(1)}>Incubation</span>
                              </NavLink>
                            </div>
                          </div>
                        )}
          </div>
        </div>
      </div>
    </>
  );
}

export default MainMenu;
