import React from 'react'
import subscription from '../../images/subscription.png'
import ProfilePage from './ProfilePage';
import SubscriptionPage from '../../Components/ProfileSetting/SubscriptionPage';
import { ChevronDownIcon } from '@heroicons/react/solid'

/**
 * The `classNames` function takes in multiple class names as arguments and returns a string with all
 * the non-empty class names joined together with a space separator.
 * @param classes - The `classes` parameter is a rest parameter, which means it allows you to pass any
 * number of arguments to the function. In this case, it is used to pass a list of class names as
 * arguments to the function.
 * @returns The function `classNames` returns a string that is the concatenation of all the truthy
 * values in the `classes` array, separated by a space.
 */
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

/**
 * The AccountSetting function is a React component that renders a tabbed interface for managing
 * account settings, including a profile page and a subscription page.
 * @returns The AccountSetting component is returning a JSX element.
 */
const AccountSetting = () => {
    const [openTab, setOpenTab] = React.useState(2);    

    /**
     * The function `accordionClick` toggles the value of `openTab` based on the input `val`.
     * @param val - The `val` parameter is the value that is passed to the `accordionClick` function.
     * It is used to determine which tab should be opened or closed in an accordion component.
     */
    const accordionClick = (val) => {
        if (val === openTab) {
            setOpenTab(0);
        } else {
            setOpenTab(val);
        }
    };
    return (
        <div className='pt-3 lg:pt-0'>
            <div className='md:grid md:grid-cols-7 lg:grid-cols-5 lg:gap-4'>
                <div className='md:col-span-2 lg:col-span-1 md:bg-[#FAF5EA] bg-[#F5F5F5] md:shadow md:h-screen'>
                    <p
                        className=" px-5 mb-3 mt-3 text-[#2B2A29] leading-[22px] text-[18px] font-sans_medium_body tracking-[-0.39px]">
                        Account Setting
                    </p>
                    <div className='md:block hidden'>                        
                        <a className={
                                " group flex items-center px-5 py-3   text-[15px] font-medium  block leading-[22px] " +
                                (openTab === 2
                                    ? "border-r-4 border-[#F1CA75] bg-white text-[#F1CA75] font-sans_medium_body "
                                    : "border border-[#E3E3E3] text-[#333333] font-sans_book_body ")
                            }
                            onClick={e => {
                                e.preventDefault();
                                setOpenTab(2);
                            }}
                            data-toggle="tab"
                            href="#link2"
                            role="tablist"
                        >
                            <img className="flex-shrink-0 -ml-1 mr-3 h-[20px] w-[28px] text-[#F1CA75]"
                                src={subscription} />
                            <span
                                className="  font-sans_book_body text-[15px] leading-[18px] tracking-[-0.32px] capitalize ">Subscription</span>
                        </a>
                    </div>
                    <div className='md:hidden block mx-3 mt-4'>  
                        {/* <div className='mb-4'>
                            <div onClick={() => accordionClick(1)} className="cursor-pointer  font-sans_medium_body  justify-between p-4  flex bg-white  py-2 text-[18px] tracking-[-0.34pt] leading-[19pt] ">
                                Profile
                                <div> <ChevronDownIcon className={classNames(" cursor-pointer text-[#666666] justify-self-end col-span-2 h-7 w-7", openTab === 2 ? "rotate-180" : "rotate-0")} aria-hidden="true" /></div>
                            </div>   
                            <div className={`${openTab === 1 ? "block" : "hidden"}`} id="link1">
                                <div className="" >
                                    <ProfilePage />
                                </div>
                            </div>
                         </div> */}
                         <div>
                            <div onClick={() => accordionClick(2)}  className="cursor-pointer  font-sans_medium_body justify-between p-4 flex bg-white  py-2 text-[18px] tracking-[-0.34pt] leading-[19pt] ">
                                Subscription
                                <div> <ChevronDownIcon className={classNames(" cursor-pointer text-[#666666] justify-self-end col-span-2 h-7 w-7", openTab === 2 ? "rotate-180" : "rotate-0")} aria-hidden="true" /></div>
                            </div>  
                            
                            <div className={`${openTab === 2 ? "block" : "hidden"}`}  id="link2">
                                <div className="" >
                                    <SubscriptionPage />
                                </div>
                            </div>  
                        </div>                      
                    </div>                    
                </div>

                <div className='md:col-span-5 lg:col-span-4 px-3 hidden md:block'>
                    <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                        <div className="" >
                            <ProfilePage />
                        </div>
                    </div>
                    <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                        <div className="" >
                            <SubscriptionPage />
                        </div>
                    </div>
                </div>

            </div >           
        </div>


    )
}

export default AccountSetting