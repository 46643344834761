import { Select, Spin, Tag } from 'antd';
import { Option } from 'antd/es/mentions';
import React, { useState, useRef, useEffect } from 'react'



/**
 * The `DebounceSelect` function is a custom React component that provides a debounced search
 * functionality for a select input, fetching options based on the user's input.
 */
function DebounceSelect({ fetchOptions, debounceTimeout = 200, ...props }) {
    const [fetching, setFetching] = useState(false);
    const [options, setOptions] = useState([]);
    const [searchText, setSearchText] = useState("");
    const firstRender = useRef(false);
    console.log(searchText,"searchText")

    useEffect(() => {
        let sub;
        if (firstRender.current) {
            sub = setTimeout(() => {
                if (searchText !== "" && searchText?.length>=3) {
                    setOptions([]);
                    setFetching(true);
                    fetchOptions(searchText).then(newOptions => {
                        setOptions(newOptions);
                        setFetching(false);
                    });
                } else {
                    setOptions([]);
                }
            }, debounceTimeout);
        } else {
            firstRender.current = true;
        }
        return () => clearTimeout(sub);
    }, [searchText])

    return (
        <Select
            filterOption={false}
            searchValue={searchText}
            onSearch={(value) => setSearchText(value)}
            bordered={false}
            style={{ width: '100%' }}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            {...props}
        >
            {
                options?.map(option => {
                    return <Option value={option.value}><p dangerouslySetInnerHTML={{ __html: option.label }} /></Option>
                })
            }
        </Select>
    );
}

export default DebounceSelect;
