import React from 'react'

/**
 * The function `ReportHeader` returns a JSX element that displays a header for a report.
 * @returns a JSX element, specifically a div containing another div with an h1 element inside.
 */
function ReportHeader() {
  return (
    <div>
        <div>
          <h1
          className=" text-left md:text-center text-[24px] traking-[-0.51px] leading-[29px] text-[#2B2A29]  font-sans_medium_body py-3 px-3 lg:px-0">
          Reports</h1>
      </div>
    </div>
  )
}

export default ReportHeader