import { Select, Spin, Tag } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, createContext, useRef, useState } from 'react';
import { auditorNameSearchApi } from '../../Config/config';
import '../../index.css'
import { Option } from 'antd/es/mentions';
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import { UserContext } from '../../Contexts/UserContext';
import { AiFillCloseCircle } from 'react-icons/ai'


/**
 * The `tagRender` function renders a tag component with a label, value, and a close button.
 * @param props - The `props` parameter is an object that contains the following properties:
 * @returns The code is returning a JSX element, specifically a `<div>` element with some nested
 * elements inside.
 */
const tagRender = (props) => {
  const { label, value, closable, onClose } = props;
  // const onPreventMouseDown = (event) => {
  //   event.preventDefault();
  //   event.stopPropagation();
  // };
  console.log(props, "label-8");
  return (
    <div className="mx-1 my-1 text-[12px] flex items-stretch justify-between font-bold  px-1 py-1 rounded-sm bg-slate-100 text-black border ">
      <div>
        {label}
      </div>

      <div onClick={onClose} className='px-1 flex items-center justify-center cursor-pointer'>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="black" className="w-3 h-3">
          <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </div>


    </div>
  );
};

/**
 * The `AuditorNameFilter` component is a dropdown filter for selecting auditor names, with options
 * fetched from an API based on user input.
 * @returns The `AuditorNameFilter` component is being returned.
 */
const AuditorNameFilter = ({ AuditiorNameOptions, customPathname, setAuditiorNameOptions }) => {
  const { getToken } = useContext(UserContext);


  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const [searchText, setSearchText] = useState("");
  const firstRender = useRef(false);
  const { refineSearchFilters } = useContext(PeFilterContext);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setAuditiorNameOptions(refineSearchFilters[customPathname]?.auditorName ?? [])
  }, [customPathname, refineSearchFilters, setAuditiorNameOptions])



  useEffect(() => {
    let sub;
    if (firstRender.current) {
      sub = setTimeout(() => {
        if (searchText !== "" && searchText?.length > 2) {
          setOptions([]);
          setFetching(true);
          getSearchTags(searchText).then(newOptions => {
            console.log(newOptions, "newOptionsnewOptions");
            setOptions(newOptions);
            setFetching(false);
          });
        } else {
          setOptions([]);
        }
      }, 500);
    } else {
      firstRender.current = true;
      setOptions(AuditiorNameOptions)
    }
    return () => clearTimeout(sub);
  }, [searchText])

/**
 * The handleChange function logs the value parameter, sets the auditiorNameOptions state based on the
 * value parameter, closes the open state, and clears the options.
 * @param value - The `value` parameter is the selected value or values from a dropdown or multi-select
 * component. It can be an array of selected values or a single value.
 * @param options - The `options` parameter is an array of values that are used to create the
 * `auditiorNameOptions` array. Each value in the `options` array is mapped to an object with
 * properties `id` and `name`, where `id` is set to the value itself and `name`
 */
  const handleChange = (value, options) => {
    console.log("optionsoptionsoptions", value);
    setAuditiorNameOptions(value?.map(option => ({ id: option, name: option })))
    setOpen(false)
    clearOptions()
  };
 /**
  * The function `getSearchTags` is an asynchronous function that makes a POST request to an API
  * endpoint to search for auditor names based on a given search text, and returns an array of auditor
  * names if the request is successful.
  * @param searchText - The `searchText` parameter is a string that represents the text that will be
  * used to search for auditor names.
  * @returns The function `getSearchTags` returns an array of auditor names.
  */
  const getSearchTags = async (searchText) => {

    try {
      const response = await axios.post(auditorNameSearchApi, {
        "auditor_name": searchText,

      }, {
        headers: {
          "Authorization": getToken()
        }
      });

      if (response.status === 200) {
        return response.data?.auditor_names;
      } else {
        return [];
      }
    } catch (error) {
      return [];
    }

  }

/**
 * The clearOptions function clears the options array and resets the searchText variable.
 */
  const clearOptions = () => {
    setOptions([])
    setSearchText("");
  }


  return <div className="border-b-[3px] border-[#E8E6E1] py-2">
    <div className='px-3 pb-2 space-y-2'>
      <label>Auditor Name</label>
      <Select
        mode="multiple"
        filterOption={false}
        style={{
          width: '100%',
          border: '1px solid #BC8B20',
          borderRadius: '4px',
          background: '#ffffff',
          maxHeight: '60px',
          overflowY: "auto"
        }}
        className="text-[#333333]"
        open={open}
        onDropdownVisibleChange={(visible) => setOpen(visible)}
        bordered={false}
        tagRender={tagRender}
        onSearch={(value) => setSearchText(value)}
        onChange={handleChange}
        value={AuditiorNameOptions?.map(auditor => auditor.name)}
        onBlur={clearOptions}
        virtual={false}
        notFoundContent={searchText === "" || searchText?.length < 3 ? null : fetching ? <p className='text-[#333333] text-[13px]'>Searching...</p> : <p className='text-[#333333] text-[13px]'>No data found</p>}
      >
        {options?.map(option => {
          return <Option key={option.name} value={option.name}>{option.name}</Option>
        })}
      </Select>
    </div>
  </div>
};

export default AuditorNameFilter;