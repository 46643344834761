import { React, useState } from "react";
import { NavLink } from 'react-router-dom';
import MappleToolTip from "reactjs-mappletooltip";
import { CheckCircleIcon } from '@heroicons/react/outline'
import '../../../index.css'
import RandomLogo from "../../RandomLogo";
import CompanyLogo from "../../CompanyLogo";
import useModule from "../../../hooks/useModule";
import Constants from "../../../Constants";
import { routeNames } from "../../../routeSegments";
/* The `InvestorTimeline` component is a React functional component that displays a timeline of
investors. It takes in a `props` object as a parameter. */
const InvestorTimeline = (props) => {
  // var patternSpace=0;  
  const { primaryMasterId } = useModule();
  return (
    <>
    <div className="grid grid-cols-12">
      <div className="col-span-1 space-y-4">
      {props?.data.map((along, i) => {
        // console.log(along?.pattern_info?.length,'pattern length');        
        return (
          !props?.SelfInvestor &&
          <div className="flex items-center pl-0">
            <div className="sticky left-0 bg-white h-full">
              <div className="flex items-center">
                {along?.logo_path == "default.png" || along?.logo_path == "/default.png" || !along?.logo_path
                  ? <NavLink onClick={(e) => {
                    if (!props.redirection) {
                        e.preventDefault();
                        e.stopPropagation();
                        props.onDisabledLinkClick();
                    }
                }} target="_blank" className="" to={primaryMasterId == Constants.primaryMasterIds.peId ? routeNames.peInvestorById(along?.id) : routeNames.vcInvestorById(along?.id)} ><RandomLogo name={along?.name} page="company page" /></NavLink>
                  : <NavLink onClick={(e) => {
                    if (!props.redirection) {
                        e.preventDefault();
                        e.stopPropagation();
                        props.onDisabledLinkClick();
                    }
                }} target="_blank" className="" to={primaryMasterId == Constants.primaryMasterIds.peId ? routeNames.peInvestorById(along?.id) : routeNames.vcInvestorById(along?.id)} ><CompanyLogo name={along?.name} id={along?.id} page="company page" logo={along?.logo_path} /></NavLink>
                }
              </div>
            </div>            
          </div>
          )}
          )}
        </div>
      <div className="col-span-11">
        <div className="ml-1 overflow-x-auto space-y-5">
        {props?.data.map((along, i) => {
          return (            
            <div className="pl-2 pt-1">
              <div className={"h-6 flex items-center "}>
                <div>
                  <dh-component>
                    <div>
                      <div className="bg-[#BC8B20] h-0.5 flex items-center space-x-10 ">
                        {/* pattern loop */}
                        {along?.pattern_info.map((pattern, i) => {
                          return (
                            <>
                              <MappleToolTip>

                                <div
                                  data-tip
                                  // data-for="registerTip"
                                  className={
                                    pattern?.pattern == 1 ?
                                        "relative flex h-6 w-6 items-center rounded-full bg-[#BC8B20] hover:bg-[#BC8B20] border-[#BC8B20] "
                                      : "relative flex h-6 w-6 items-center rounded-full flex items-center " + (pattern?.pattern == 0 && i == 0 ? "justify-start " : pattern?.pattern == 0 && i == along?.pattern_info?.length - 1 ? "justify-end " : "justify-center ")}
                                >
                                  {pattern?.pattern == 1 ?
                                    <CheckCircleIcon className="h-6 w-6 text-white" />
                                    : <div className="border-2 border-[#BC8B20] relative flex h-3 w-3 items-center justify-center rounded-full bg-white"></div>
                                  }

                                </div>

                                {/* Tooltip data */}
                                <div>
                                  {pattern?.deal_amount
                                    ? '$ ' + pattern?.deal_amount + ' M'
                                    : "N/A"}
                                </div>
                              </MappleToolTip>
                            </>
                          );
                        })}
                        {/* pattern loop end*/}
                      </div>
                    </div>
                  </dh-component>
                </div>
              </div>
              {props?.data?.length - 1 == i ?
                <>
                  {along?.pattern_info.map((pattern, i) => {
                    return (
                      <span className={"whitespace-nowrap overflow-x-auto text-[9px] mt-1 " + (pattern?.pattern == 1 && along?.pattern_info?.length - 1 != i ? 'mx-3.5' : pattern?.pattern == 1 && props?.data?.length == 1 && along?.pattern_info?.length - 1 != i ? 'mx-3' : along?.pattern_info?.length - 1 == i ? "ml-2.5" : 'mx-3')} style={{ marginLeft: i == 0 ? '-2px' : '' }}>
                        {pattern?.deal_date
                          ? pattern?.deal_date
                          : "N/A"}
                      </span>
                    )
                  })}
                </> : ''}
            </div>           
          );
        })}
        </div>
        {props?.data?.length == 0 ? "No Co-Investments":null}
    </div>
    </div>
    </>
  );
};

export default InvestorTimeline;
