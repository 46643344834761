import { Select } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { tagSearchUrl } from '../../Config/config';
import '../../index.css'
import { Option } from 'antd/es/mentions';
import { UserContext } from '../../Contexts/UserContext';



const tagRender = (props) => {
  const { label, value, closable, onClose } = props;

  return (
    <div className="mx-1 my-1 text-[12px] flex items-stretch justify-between font-bold  px-1 py-1 rounded-sm bg-slate-100 text-black border ">
      <div>
        {label}
      </div>

      <div onClick={onClose} className='px-1 flex items-center justify-center cursor-pointer'>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="black" className="w-3 h-3">
          <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </div>


    </div>
  );
};

/**
 * The `TagSearch` is a component that allows users to search for tags and select
 * multiple options from a dropdown menu.
 */
const TagSearch = ({ TagsOptions, disabled, setTagsOptions, primaryMasterId, url=tagSearchUrl }) => {

  const { getToken } = useContext(UserContext);
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const [searchText, setSearchText] = useState("");
  const firstRender = useRef(false);
  const [open, setOpen] = useState(false);



  useEffect(() => {
    let sub;
    if (firstRender.current) {
      sub = setTimeout(() => {
        if (searchText !== "") {
          setOptions([]);
          setFetching(true);
          getSearchTags(searchText).then(newOptions => {
            console.log(newOptions, "newOptionsnewOptions");
            setOptions(newOptions);
            setFetching(false);
          });
        } else {
          setOptions([]);
        }
      }, 500);
    } else {
      firstRender.current = true;
      setOptions(TagsOptions)
    }
    return () => clearTimeout(sub);
  }, [searchText])

  const handleChange = (value, options) => {
    console.log("optionsoptionsoptions", options, value);
    setTagsOptions(value?.map(option => ({ id: option, name: option })))
    setOpen(false)
    clearOptions()
  };
  const getSearchTags = async (searchText) => {
    try {
      const response = await axios.post(url, {
        "tag_name": searchText,
        "type"    : primaryMasterId

      }, {
        headers: {
          "Authorization": getToken()
        }
      });

      if (response.status === 200) {
        return response.data?.tags;
      } else {
        return [];
      }
    } catch (error) {
      return [];
    }
  }

  const clearOptions = () => {
    setOptions([])
    setSearchText("");
  }


  return <Select
    mode="multiple"
    disabled={disabled}
    filterOption={false}
    style={{
      width: '100%',
      maxHeight: '30px',
      overflowY:"auto",
    }}
    
    className="text-black"
    open={open}
    bordered={false}
    onDropdownVisibleChange={(visible) => setOpen(visible)}
    placeholder="Search by Tags..."
    tagRender={tagRender}
    onSearch={(value) => setSearchText(value)}
    onChange={handleChange}
    value={TagsOptions?.map(tag => tag.name)}
    onBlur={clearOptions}
    notFoundContent={searchText === "" ? null : fetching ? <p className='text-black'>Searching...</p> : <p className='text-black'>No tags found</p>}
  >
    {options?.map(option => {
      return <Option key={option.name} value={option.name}>{option.name}</Option>
    })}
  </Select>
};

export default TagSearch;