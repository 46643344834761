import React, {useContext} from 'react';
import PeVcCard from './PeVcCard';
import TotalFundingUSDCard from './TotalFundingUSDCard';
import TotalFundingInRCard from './TotalFundingInRCard';
import TotalCompaniesCard from './TotalCompaniesCard';
import {TableDataContext} from '../../Contexts/TableDataContext'

/**
 * The `Card` component renders four different cards based on the data provided, displaying various
 * funding and company information.
 * @returns The Card component is returning a JSX element.
 */
 const Card = () => {
  const { data, isLoad, isDataLoad } = useContext(TableDataContext);
  return (
    <div className=""> 
      <div className='sm:full md:mt-[3rem] mt-[4.25rem] h-full bg-[#F2F2F2]'>
           <div className="overflow-x-auto lg:grid grid-cols-4 gap-4  px-4 py-[0.7rem] flex xl:overflow-hidden scrollbar-remove ">
           {/* ----card1----- */}
          <TotalFundingUSDCard data={data} isLoad={isLoad} isDataLoad={isDataLoad} />
          {/* ----card2----- */}
          <TotalFundingInRCard data={data} isLoad={isLoad} isDataLoad={isDataLoad}/>
          {/* ----card3----- */}
          <TotalCompaniesCard data={data} isLoad={isLoad} isDataLoad={isDataLoad}/> 
          {/* ----card4----- */}
          <PeVcCard data={data} isLoad={isLoad} isDataLoad={isDataLoad}/>

            </div>
        <div className='border-b-2 border-[#e5e5e5]'></div>
      </div >
    </div >
  )
}

export default Card;
