import React from 'react';
import { Select } from 'antd';
import { SteppedLineTo } from 'react-lineto';
const { Option } = Select;


/* The `MultiCalenderComponent` is a functional component that takes in several props: `child`,
`years`, `months`, `selectedChild`, and `setSelectedChild`. These props are destructured from the
component's props object using object destructuring syntax. */
const MultiCalenderComponent = ({ child, years, months, selectedChild, setSelectedChild }) => {

  const childIndex = child.uniqueId;

  const lineColor = "#BDBDBD";
    /**
     * The handleChangeMonth function updates the month value in the selected child's values array.
     * @param event - The event parameter is the event object that is passed when the handleChangeMonth
     * function is called. It typically contains information about the event that triggered the
     * function, such as the target element or the value of the input field. In this case, it seems
     * that the event parameter is expected to contain the selected month
     */
    const handleChangeMonth = (event) => {
        const month = event;
    
        setSelectedChild((prevSelectedChild) =>
          prevSelectedChild.map((row, index) => {
            if (row.id === child.id && row.uniqueId === childIndex) {
              return {
                ...row,
                values: [month, child.values[1] ,child.values[2], child.values[3],] // Keep start year unchanged, update end year value
              };
            }
            return row;
          })
        );
      };


  /**
   * The handleChangeYear function updates the start year value of a selected child in an array of
   * child objects.
   * @param event - The `event` parameter is the event object that is passed when the
   * `handleChangeYear` function is called. It typically contains information about the event that
   * triggered the function, such as the target element and its value. In this case, it seems like the
   * `event` parameter is expected to be the
   */
  const handleChangeYear = (event) => {
    const year = event;

    setSelectedChild((prevSelectedChild) =>
      prevSelectedChild.map((row, index) => {
        if (row.id === child.id && row.uniqueId === childIndex) {
          return {
            ...row,
            values: [child.values[0], year, child.values[2], child.values[3],] // Update start year value, keep end year unchanged
          };
        }
        return row;
      })
    );
  };

  /**
   * The function `handleChangeMonthTo` updates the month value of a selected child in an array of
   * child objects.
   * @param event - The `event` parameter is the value that is passed when the `handleChangeMonthTo`
   * function is called. It represents the new month value that needs to be updated.
   */
  const handleChangeMonthTo = (event) => {
    const month = event;

    setSelectedChild((prevSelectedChild) =>
      prevSelectedChild.map((row, index) => {
        if (row.id === child.id && row.uniqueId === childIndex) {
          return {
            ...row,
            values: [child.values[0], child.values[1], month, child.values[3]] // Keep start year unchanged, update end year value
          };
        }
        return row;
      })
    );
  };


/**
 * The handleChangeYearTo function updates the start year value of a selected child object in an array
 * while keeping the end year value unchanged.
 * @param event - The event parameter is the value that is passed when the handleChangeYearTo function
 * is called. It represents the new year value that you want to update in the selected child object.
 */
const handleChangeYearTo = (event) => {
const year = event;

setSelectedChild((prevSelectedChild) =>
  prevSelectedChild.map((row, index) => {
    if (row.id === child.id && row.uniqueId === childIndex) {
      return {
        ...row,
        values: [child.values[0], child.values[1],child.values[2], year] // Update start year value, keep end year unchanged
      };
    }
    return row;
  })
);
};


  

  return (
    <div className='flex space-x-2'>
      <div className='singleSearch fromDate'>
      <SteppedLineTo within='query-builder' borderColor={lineColor} delay={100} borderWidth={5} from={`fromDate${childIndex}`} to={`fromMonth${childIndex}`} orientation={"h"} />
    <Select
          key={childIndex}
      showSearch
      placeholder= "From month"
      name={child.id}
      onChange={handleChangeMonth}
      className=" rounded focus:outline-none  w-[7rem]"
    >
      {months.map((month, i) => (
        <Option key={month} value={i+1}>
          {month}
        </Option>
      ))}
    </Select>
    </div>
    <div className={`singleSearch fromMonth${childIndex}`}>
    <Select
          key={childIndex}
    showSearch
    placeholder= "From year"
      name={child.id}
      onChange={handleChangeYear}
      className=" rounded focus:outline-none  w-[7rem]"
    >
      {years.map((year) => (
        <Option key={year} value={year}>
          {year}
        </Option>
      ))}
    </Select>
    </div>
    <SteppedLineTo within='query-builder' borderColor={lineColor} delay={100} borderWidth={5} from={`fromMonth${childIndex}`} to={`toDatee${childIndex}`} orientation={"h"} />
    {/* <p className='p-0 tooDate'>To</p> */}
    <div className={`singleSearch toDatee${childIndex}`}>
    {/* <SteppedLineTo within='query-builder' borderColor={lineColor} delay={100}  borderWidth={5} from={`${key}`} to={`${key}secBtn`} orientation={"h"} /> */}
    <SteppedLineTo within='query-builder' borderColor={lineColor} delay={100} borderWidth={5} from={`toDatee${childIndex}`} to={`toMonthh${childIndex}`} orientation={"h"} />
    <Select
          key={childIndex}
    showSearch
    placeholder= "To month"
      name={child.id}
      onChange={handleChangeMonthTo}
      className=" rounded focus:outline-none  w-[7rem]"
    >
      {months.map((month, i) => (
        <Option key={month} value={i+1}>
          {month}
        </Option>
      ))}
    </Select>
    </div>
    <div className={`singleSearch toMonthh${childIndex}`}>
    <Select
          key={childIndex}
    showSearch
    placeholder= "To year"
      name={child.id}
      onChange={handleChangeYearTo}
      className=" rounded focus:outline-none  w-[7rem]"
    >
      {years.map((year) => (
        <Option key={year} value={year}>
          {year}
        </Option>
      ))}
    </Select>
    </div>
    </div>
  );
};

export default MultiCalenderComponent;
