import { Fragment, useState, useContext } from "react";
import { exportExcel } from "../../../../Config/config";
import axios from "axios";
import { Dialog, Transition } from "@headlessui/react";
import closeIcon from "../../../../images/search_close_button.png";
import { TableDataContext } from "../../../../Contexts/TableDataContext";

/* The `LoadPage` component is a React functional component that displays a dialog box for data
loading. */
const LoadPage = (props) => {
  const { companiesid, setCompaniesId } = useContext(TableDataContext);

  console.log(props.menu, "menu");
  console.log(props.target, "target");

  console.log(props.subMenu, "submenu");
  console.log(companiesid, "allcompanyxl");

  const [open, setOpen] = useState(true);
  const [selectedExportType, setSelectedExportType] = useState();

  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => props.setShowPop(!props?.showPop)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="items-center flex min-h-full  justify-center p-4 text-center ">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative rounded-lg  transform overflow-hidden  bg-white pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg ">
                  <div className="text-center px-5 md:px-0 text-white bg-[#BC8B20] py-2 font-sans_semi_bold text-[#ffffff] text-[15px] ">
                    Data Loading from MCA ...
                  </div>

                  {/* </div>} */}
                  {selectedExportType == "2" ? (
                    <div className="mt-3  ml-8 ">
                      <label>
                        <input
                          type="checkbox"
                          className="mx-2 font-[Squel Sans] text-[#333333] text-[13px]"
                          // onChange={this.handleChange}
                        />{" "}
                        HFA
                      </label>
                    </div>
                  ) : (
                    ""
                  )}

                  <div>
                    <div className="text-center px-3 lg:px-0 mt-2 font-[Squel Sans] text-[#333333] text-[13px] font-Medium ">
                      MCA data look up. It can be upto 2 minutes to load...
                    </div>
                  </div>
                  <div className="text-center items-center py-3 justify-center ">
                    <button
                      onClick={() => props?.setShowPop(!props?.showPop)}
                      className="bg-[#BC8B20] rounded-[5px]  px-2 h-[28px] text-[#FFFFFF]  text-[14px] leading-[17px]"
                    >
                      Cancel
                    </button>
                  </div>
                  <p className="mb-5 text-center font-[Squel Sans] text-[#333333] text-[13px] font-Medium">
                    This is too slow.
                  </p>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default LoadPage;
