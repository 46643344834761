import React from 'react'
import MainMenu from '../../Components/RETransactionAdvisor/RETransactionMainmenu'
import Card from '../../Components/RE/ReListCard/Card'
import FilterTag from '../../Components/RETransactionAdvisor/Tag'
import Table from '../../Components/RETransactionAdvisor/TableComponent'
import RefineSearch from '../../Components/RETransactionAdvisor/RefineSearchComponet'
import {PE_TABLE_QUERY} from '../../Queries/Pe';
import ReLayout from '../../Components/ReLayout'
import chatPopupIcon from '../../images/chatPopupIcon.png'
import ChatPopup from '../../Components/ChatPopup'
import Constants from '../../Constants'

/**
 * The BackedIpo function is a React component that renders a layout with a primary column and a secondary
 * column, along with various other components.
 * @returns a JSX element.
 */
function BackedIpo() {
    const [showModal, setShowModal] = React.useState(false);
    return (
        <ReLayout>
        <Card />
                <div className="h-full min-w-0 flex-1 border-t border-gray-200 lg:flex">
                    {/* Primary column */}
                    <section
                    aria-labelledby="primary-heading"
                    className="flex h-full min-w-0 flex-1 flex-col overflow-y-auto lg:order-last"
                    >
                    <FilterTag/>
                    <MainMenu active={4}   menu="re" primaryTypeMasterId={Constants.primaryMasterIds.reId}
                                subTypeMasterId={Constants.primaryMasterSubIds.re_backed_ipos_id}
                                typeId={Constants.moduleIds.t_advisor_id}/>
                    <Table PE_TABLE_QUERY = {PE_TABLE_QUERY} menu="re"/>

                    {/* <Outlet /> */}
                    {/* Your content */}
                    </section>

                    {/* Secondary column (hidden on smaller screens) */}
                    <aside className="hidden lg:order-first lg:block lg:flex-shrink-0">
                    <div className="relative flex h-[100%] overflow-y-auto flex-col  border-r border-gray-200 bg-[#FAF5EA]">
                        {/* Your content */}
                        <RefineSearch/>
                    </div>
                    </aside>
                    
                </div>
        </ReLayout>
    )
}

export default BackedIpo
