import {React, useState, createContext} from 'react'

export const SearchExportContext = createContext('');


// {
//     id:3,
//     name:"Company Profile + All transactions",
//     isChecked: false,
//     hasDetail: true 
// }
 
/**
 * The `SearchExportProvider` component is a React component that provides a context for managing
 * search export values and allows its children components to access and update those values.
 * @returns The code is returning a component called `SearchExportProvider`. This component wraps its
 * children with a `SearchExportContext.Provider` component and passes down the `searchExportValue` and
 * `setSearchExportValue` as values to the context provider. The children components can access these
 * values using the `SearchExportContext.Consumer` component.
 */
const SearchExportProvider = ({children}) => {
    const [searchExportValue, setSearchExportValue] = useState({
        data:[
                {
                    firstFilter: [
                        {
                            id:1,
                            name:"Company Profile",
                            isChecked: false,
                            hasDetail: false
                        },
                        {
                            id:2,
                            name:"Company Profile  + PE-VC Investments",
                            isChecked: false,
                            hasDetail: true
                        }
                    ],
                    secondFilter: [
                        {
                            id:1,
                            data:[
                            ]
                        },
                        {
                            id:2,
                            data:[
                                {
                                    id:100,
                                    name:"All",
                                    value: "all",
                                    isChecked: false 
                                },
                                {
                                    id:21,
                                    name:"Profile",
                                    value: "profile",
                                    isChecked: false 
                                },
                                {
                                    id:22,
                                    name:"Deal Info",
                                    isChecked: false 
                                },
                                {
                                    id:23,
                                    name:"Valuation Info",
                                    isChecked: false 
                                },
                                {
                                    id:24,
                                    name:"Investor info",
                                    isChecked: false 
                                },
                                {
                                    id:25,
                                    name:"Financials",
                                    isChecked: false 
                                },
                                {
                                    id:26,
                                    name:"Advisor-Company",
                                    isChecked: false 
                                },
                                {
                                    id:27,
                                    name:"Advisor-Investor",
                                    isChecked: false 
                                }
                            ]
                        },
                        {
                            id:3,
                            data:[
                                {
                                    id:100,
                                    name:"All",
                                    isChecked: false 
                                },
                                {
                                    id:31,
                                    name:"PE-VC Investments",
                                    isChecked: false 
                                },
                                {
                                    id:32,
                                    name:"PE-VC Exits",
                                    isChecked: false 
                                },
                                {
                                    id:33,
                                    name:"PE-VC backed IPOs",
                                    isChecked: false 
                                }
                            ]
                        }
                    ],
                    thirdFilter: [
                        {
                            id:31,
                            data:[
                                {
                                    id:100,
                                    name:"All",
                                    isChecked: false 
                                },
                                {
                                    id:311,
                                    name:"Profile1",
                                    isChecked: false 
                                },
                                {
                                    id:312,
                                    name:"Deal Info1",
                                    isChecked: false 
                                },
                                {
                                    id:313,
                                    name:"Valuation Info1",
                                    isChecked: false 
                                }
                            ]
                        },
                        {
                            id:32,
                            data:[
                                {
                                    id:100,
                                    name:"All",
                                    isChecked: false 
                                },
                                {
                                    id:321,
                                    name:"Profile2",
                                    isChecked: false 
                                },
                                {
                                    id:322,
                                    name:"Deal Info2",
                                    isChecked: false 
                                },
                                {
                                    id:323,
                                    name:"Valuation Info2",
                                    isChecked: false 
                                }
                            ]
                        },
                        {
                            id:33,
                            data:[
                                {
                                    id:100,
                                    name:"All",
                                    isChecked: false 
                                },
                                {
                                    id:331,
                                    name:"Profile3",
                                    isChecked: false 
                                },
                                {
                                    id:332,
                                    name:"Deal Info3",
                                    isChecked: false 
                                },
                                {
                                    id:333,
                                    name:"Valuation Info3",
                                    isChecked: false 
                                }
                            ]
                        },
                        {
                            id:21,
                            data:[
                                {
                                    id:100,
                                    name:"All",
                                    value: "all",
                                    isChecked: false 
                                },
                                {
                                    id:211,
                                    name:"Industry",
                                    value: "Industry",
                                    isChecked: false 
                                },
                                {
                                    id:212,
                                    name:"Sector",
                                    value: "Sector",
                                    isChecked: false 
                                },
                                {
                                    id:213,
                                    name:"CIN",
                                    value: "CIN No",
                                    isChecked: false 
                                },
                                {
                                    id:214,
                                    name:"Location",
                                    value: "Country",
                                    isChecked: false 
                                },
                                {
                                    id:215,
                                    name:"Website",
                                    value: "Website",
                                    isChecked: false 
                                },
                                {
                                    id:216,
                                    name:"Type",
                                    value: "Company Type",
                                    isChecked: false 
                                },
                                {
                                    id:217,
                                    name:"Top Management",
                                    value: "Top Management",
                                    isChecked: false 
                                },
                                {
                                    id:218,
                                    name:"Contact",
                                    value: "Contact",
                                    isChecked: false 
                                },
                                {
                                    id:219,
                                    name:"Investor Board Member",
                                    value: "Investor Board Member",
                                    isChecked: false 
                                }
                            ]
                        },
                        {
                            id:22,
                            data:[
                                {
                                    id:100,
                                    name:"All",
                                    value: "all",
                                    isChecked: false 
                                },
                                {
                                    id:221,
                                    name:"Amount ($ M)",
                                    value: "Amount(US\$M)",
                                    isChecked: false 
                                },
                                {
                                    id:222,
                                    name:"Amount (₹ Cr)",
                                    value: "Amount(INR Cr)",
                                    isChecked: false 
                                },
                                {
                                    id:223,
                                    name:"Exit Status",
                                    value: "Exit Status",
                                    isChecked: false 
                                },
                                {
                                    id:224,
                                    name:"Date",
                                    value: "Date",
                                    isChecked: false 
                                },
                                {
                                    id:225,
                                    name:"Stake",
                                    value: "Stake (%)",
                                    isChecked: false 
                                },
                                {
                                    id:226,
                                    name:"Stage",
                                    value: "Stage",
                                    isChecked: false 
                                },
                                {
                                    id:227,
                                    name:"Round",
                                    value: "Round",
                                    isChecked: false 
                                },
                                {
                                    id:228,
                                    name:"News Link",
                                    value: "Link",
                                    isChecked: false 
                                },
                                {
                                    id:229,
                                    name:"Price Per Share",
                                    value: "Price Per Share",
                                    isChecked: false 
                                },
                                {
                                    id:230,
                                    name:"BV Per Share",
                                    value: "Book Value Per Share",
                                    isChecked: false 
                                },
                                {
                                    id:231,
                                    name:"Price To Book",
                                    value: "Price to Book",
                                    isChecked: false 
                                }
                            ]
                        },
                        {
                            id:23,
                            data:[
                                {
                                    id:100,
                                    name:"All",
                                    value: "all",
                                    isChecked: false 
                                },
                                {
                                    id:231,
                                    name:"Pre Money Valuation (INR Cr)",
                                    value: "Pre Money Valuation (INR Cr)",
                                    isChecked: false 
                                },
                                {
                                    id:232,
                                    name:"Revenue Multiple (Pre)",
                                    value: "Revenue Multiple (Pre)",
                                    isChecked: false 
                                },
                                {
                                    id:233,
                                    name:"EBITDA Multiple (Pre)",
                                    value: "EBITDA Multiple (Pre)",
                                    isChecked: false 
                                },
                                {
                                    id:100,
                                    name:"PAT Multiple (Pre)",
                                    value: "PAT Multiple (Pre)",
                                    isChecked: false 
                                },
                                {
                                    id:231,
                                    name:"Post Money Valuation (INR Cr)",
                                    value: "Post Money Valuation (INR Cr)",
                                    isChecked: false 
                                },
                                {
                                    id:232,
                                    name:"Revenue Multiple (Post)",
                                    value: "Revenue Multiple (Post)",
                                    isChecked: false 
                                },
                                {
                                    id:233,
                                    name:"EBITDA Multiple (Post)",
                                    value: "EBITDA Multiple (Post)",
                                    isChecked: false 
                                },{
                                    id:232,
                                    name:"PAT Multiple (Post)",
                                    value: "PAT Multiple (Post)",
                                    isChecked: false 
                                },
                                {
                                    id:233,
                                    name:"Enterprise Valuation (INR Cr)",
                                    value: "Enterprise Valuation (INR Cr)",
                                    isChecked: false 
                                },
                                {
                                    id:231,
                                    name:"Revenue Multiple (EV)",
                                    value: "Revenue Multiple (EV)",
                                    isChecked: false 
                                },
                                {
                                    id:231,
                                    name:"EBITDA Multiple (EV)",
                                    value: "EBITDA Multiple (EV)",
                                    isChecked: false 
                                },
                                {
                                    id:232,
                                    name:"PAT Multiple (EV)",
                                    value: "PAT Multiple (EV)",
                                    isChecked: false 
                                },
                                {
                                    id:233,
                                    name:"More Info",
                                    value: "More Details",
                                    isChecked: false 
                                }
                            ]
                        },
                        {
                            id:24,
                            data:[
                                {
                                    id:100,
                                    name:"All",
                                    value: "all",
                                    isChecked: false 
                                },
                                {
                                    id:231,
                                    name:"Investor Name",
                                    value: "Investors",
                                    isChecked: false 
                                },
                                {
                                    id:232,
                                    name:"Investor Type",
                                    value: "Investor Type",
                                    isChecked: false 
                                }
                            ]
                        },
                        {
                            id:25,
                            data:[
                                {
                                    id:100,
                                    name:"All",
                                    value: "all",
                                    isChecked: false 
                                },
                                {
                                    id:231,
                                    name:"Book Value Per Share",
                                    value: "Book Value Per Share",
                                    isChecked: false 
                                },
                                {
                                    id:232,
                                    name:"Revenue  (INR Cr)",
                                    value: "all",
                                    isChecked: false 
                                },
                                {
                                    id:231,
                                    name:"EBITDA  (INR Cr)",
                                    value: "EBITDA (INR Cr)",
                                    isChecked: false 
                                },
                                {
                                    id:231,
                                    name:"PAT  (INR Cr)",
                                    value: "PAT (INR Cr)",
                                    isChecked: false 
                                },
                                {
                                    id:232,
                                    name:"Total Debt  (INR Cr)",
                                    value: "Total Debt (INR Cr)",
                                    isChecked: false 
                                },
                                {
                                    id:232,
                                    name:"Cash & Cash Equ.  (INR Cr)",
                                    value: "Cash & Cash Equ. (INR Cr)",
                                    isChecked: false 
                                }
                            ]
                        },
                        {
                            id:26,
                            data:[
                                {
                                    id:100,
                                    name:"All",
                                    value: "all",
                                    isChecked: false 
                                },
                                {
                                    id:231,
                                    name:"Advisor Name",
                                    value: "Advisor-Investors",
                                    isChecked: false 
                                }
                            ]
                        },
                        {
                            id:27,
                            data:[
                                {
                                    id:100,
                                    name:"All",
                                    value: "all",
                                    isChecked: false 
                                },
                                {
                                    id:231,
                                    name:"Advisor Name",
                                    value: "Advisor-Investors",
                                    isChecked: false 
                                }
                            ]
                        }
                    ],
                    fourthFilter: [
                        {
                            id:311,
                            data:[
                                {
                                    id:100,
                                    name:"All",
                                    isChecked: false 
                                },
                                {
                                    id:3111,
                                    name:"Industry41",
                                    isChecked: false 
                                },
                                {
                                    id:3112,
                                    name:"Sector41",
                                    isChecked: false 
                                },
                                {
                                    id:3113,
                                    name:"CIN41",
                                    isChecked: false 
                                }
                            ]
                        },
                        {
                            id:312,
                            data:[
                                {
                                    id:100,
                                    name:"All",
                                    isChecked: false 
                                },
                                {
                                    id:3111,
                                    name:"Industry42",
                                    isChecked: false 
                                },
                                {
                                    id:3112,
                                    name:"Sector42",
                                    isChecked: false 
                                },
                                {
                                    id:3113,
                                    name:"CIN42",
                                    isChecked: false 
                                }
                            ]
                        },
                        {
                            id:313,
                            data:[
                                {
                                    id:100,
                                    name:"All",
                                    isChecked: false 
                                },
                                {
                                    id:3111,
                                    name:"Industry43",
                                    isChecked: false 
                                },
                                {
                                    id:3112,
                                    name:"Sector43",
                                    isChecked: false 
                                },
                                {
                                    id:3113,
                                    name:"CIN43",
                                    isChecked: false 
                                }
                            ]
                        },
                        {
                            id:321,
                            data:[
                                {
                                    id:100,
                                    name:"All",
                                    isChecked: false 
                                },
                                {
                                    id:3111,
                                    name:"Industry44",
                                    isChecked: false 
                                },
                                {
                                    id:3112,
                                    name:"Sector44",
                                    isChecked: false 
                                },
                                {
                                    id:3113,
                                    name:"CIN44",
                                    isChecked: false 
                                }
                            ]
                        },
                        {
                            id:322,
                            data:[
                                {
                                    id:100,
                                    name:"All",
                                    isChecked: false 
                                },
                                {
                                    id:3111,
                                    name:"Industry45",
                                    isChecked: false 
                                },
                                {
                                    id:3112,
                                    name:"Sector45",
                                    isChecked: false 
                                },
                                {
                                    id:3113,
                                    name:"CIN45",
                                    isChecked: false 
                                }
                            ]
                        },
                        {
                            id:323,
                            data:[
                                {
                                    id:100,
                                    name:"All",
                                    isChecked: false 
                                },
                                {
                                    id:3111,
                                    name:"Industry46",
                                    isChecked: false 
                                },
                                {
                                    id:3112,
                                    name:"Sector46",
                                    isChecked: false 
                                },
                                {
                                    id:3113,
                                    name:"CIN46",
                                    isChecked: false 
                                }
                            ]
                        },
                        {
                            id:331,
                            data:[
                                {
                                    id:100,
                                    name:"All",
                                    isChecked: false 
                                },
                                {
                                    id:3111,
                                    name:"Industry47",
                                    isChecked: false 
                                },
                                {
                                    id:3112,
                                    name:"Sector47",
                                    isChecked: false 
                                },
                                {
                                    id:3113,
                                    name:"CIN47",
                                    isChecked: false 
                                }
                            ]
                        },
                        {
                            id:332,
                            data:[
                                {
                                    id:100,
                                    name:"All",
                                    isChecked: false 
                                },
                                {
                                    id:3111,
                                    name:"Industry48",
                                    isChecked: false 
                                },
                                {
                                    id:3112,
                                    name:"Sector48",
                                    isChecked: false 
                                },
                                {
                                    id:3113,
                                    name:"CIN48",
                                    isChecked: false 
                                }
                            ]
                        },
                        {
                            id:333,
                            data:[
                                {
                                    id:100,
                                    name:"All",
                                    isChecked: false 
                                },
                                {
                                    id:3111,
                                    name:"Industry49",
                                    isChecked: false 
                                },
                                {
                                    id:3112,
                                    name:"Sector49",
                                    isChecked: false 
                                },
                                {
                                    id:3113,
                                    name:"CIN49",
                                    isChecked: false 
                                }
                            ]
                        }
                    ]
        
                }
            ]}
    )
    const filterDetail = {searchExportValue, setSearchExportValue}
    return (
        <SearchExportContext.Provider value={filterDetail}>
            {children}
        </SearchExportContext.Provider>
    )
}

export default SearchExportProvider
