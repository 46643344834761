import { Select } from 'antd'
import React from 'react'

/**
 * The SelectInput function is a React component that renders a select input with customizable options,
 * default value, and onChange event handler.
 */
function SelectInput({ options = [], defaultValue = "", onChange, value }) {
  return (
    <Select
      defaultValue={defaultValue}
      onChange={onChange}
      value={value}
      className='w-full reports-select whitespace-normal'
      bordered={false}
      options={options}
    />
  )
}



export default SelectInput