import React, { useContext } from "react"
import { useLocation } from 'react-router-dom'
import Layout from '../../../Components/InvestorLayout'
import MainMenu from '../../../Components/Investor/MainMenu'
import Card from '../../../Components/Investor/InvestorPageCard/Card'
import Table from '../../../Components/Investor/TableComponent'
// import {PE_TABLE_QUERY} from '../../../Queries/Pe';

import RefineSearch from '../../../Components/RefineSearchComponent'
import InvestorExistRefineSearch from '../../../Components/RefineSearch/InvestorExistRefineSearch'
import FilterTag from '../../../Components/Investor/Tag'
// import chatPopupIcon from '../../../images/chatPopupIcon.png'
// import ChatPopup from '../../../Components/ChatPopup'
import '../../../Components/TableView.css'
import Constants from '../../../Constants'
import { PeFilterContext } from "../../../Contexts/PeFilterContext";


/**
 * The function `PE_VC_EXIT` is a React component that renders a layout with a main section and an
 * aside section, displaying a table of PE-VC exits and a refine search component.
 * @returns a JSX element, which represents the UI component to be rendered.
 */
function PE_VC_EXIT() {
    // const [showModal, setShowModal] = React.useState(false);
    const location = useLocation();
    const {refineSearchFilters,isMobile} = useContext(PeFilterContext);
    const { pathname } = useLocation(); 

    const isFromGlobalSearch = ()=> refineSearchFilters[pathname]?.globalSearch?.length > 0 ? true : false

    return (
        <Layout>
            <div className="flex h-screen flex-col">
                                    {/* <Card card1={window.location.pathname ?(window.location.pathname !=('/pe/investors/angel-invesments')|| ('/pe/investors/incubation')? 'PE-VC Investors':''):''} card2={window.location.pathname ?(window.location.pathname !=('/pe/investors/angel-invesments')|| ('/pe/investors/incubation')? 'PE-VC Exits':''):''}/> */}
                                    <div className="hidden lg:block"><Card card2={window.location.pathname ?(window.location.pathname =='/pe/investors/exits/pe-vc-investments'? 'PE-VC Exits':''):''} /></div>
     
      <div className="flex min-h-0 flex-1 overflow-hidden">
                    {/* Primary column */}
                    <main className={isFromGlobalSearch() ?"mt-[3rem] min-w-0 flex-1 border-t border-gray-200 flex":"min-w-0 flex-1 border-t border-gray-200 flex"}>

                        <section
                            className="flex h-full min-w-0 flex-1 flex-col  lg:order-last bg-[#EDEDED]"
                        >
                            <FilterTag menu = "investor" />
                            <MainMenu active={2} menu="pevcExit" primaryTypeMasterId={Constants.primaryMasterIds.peId}
                                subTypeMasterId={Constants.primaryMasterSubIds.pe_vc_exits_id}
                                typeId={Constants.moduleIds.investorsId}
                                exportFileName={Constants.exportNames.pe_investor_exit}/>
                            <div className=' overflow-y-auto'>

                                <Table menu="pevcExit" sub_type_master_id={Constants.subCategoryIds.pe_investor_exits_id}/>

                            </div>
                            {/* <Outlet /> */}
                            {/* Your content */}
                        </section>
                        <aside className={`order-first block flex-shrink-0 ${isMobile ? "pt-14" : ""}`}>
                    <div className="relative flex flex-col border-r border-gray-200 bg-[#FAF5EA]">
                        {/* Your content */}

                        <RefineSearch><InvestorExistRefineSearch customPathname={location.pathname}/> </RefineSearch>
                    </div>
                </aside>
                    </main>
                </div>

                {/* Secondary column (hidden on smaller screens) */}
                
                
            </div>

        </Layout>
    )
}

export default PE_VC_EXIT
