import React from 'react'
import MALayout from '../../../Components/M&ALayout'
import Card from '../../../Components/MaAcquirers//AcqirerDetailTopCard'
import AcquirerDetail from '../../../Components/MaAcquirers/AcquirersDetail'
import RefineSearch from '../../../Components/RE/RefineSearchComponent'


/**
 * The DetailPage function returns a React component that renders a layout with a primary and secondary
 * column, along with some additional components.
 * @returns The DetailPage component is returning a JSX element.
 */
function DetailPage() {
    return (
        <div>
            <MALayout>
                <div className="flex h-screen flex-col">
                    <Card />
                    <div className="flex min-h-0 flex-1 overflow-hidden">
                        {/* Primary column */}
                        <section
                            aria-labelledby="primary-heading"
                            className="flex h-full min-w-0 flex-1 flex-col overflow-y-auto lg:order-last">
                            <AcquirerDetail />

                            {/* <Outlet /> */}
                            {/* Your content */}
                        </section>
                        {/* Secondary column (hidden on smaller screens) */}
                        <aside className="hidden lg:order-first lg:block lg:flex-shrink-0">
                            <div className="relative flex h-full  flex-col overflow-y-auto border-r border-gray-200 bg-[#FAF5EA]">
                                {/* Your content */}
                                <RefineSearch />
                            </div>
                        </aside>
                    </div>                    
                </div>
            </MALayout>
        </div>
    )
}

export default DetailPage
