import { React, useState, useEffect, useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import { RaiseRequestContext } from "../../../Contexts/RaiseRequestContext";
import HeaderButton from "./HeaderButton";
import lock from "../../../images/lock-icon.png";
import { CurrencyContext } from "../../../Contexts/CurrencyContext";
import ExcelDownloader from "../../shared/ExcelDownloader";
import { exportExcel } from "../../../Config/config";
import useModule from "../../../hooks/useModule";
import Constants from "../../../Constants";
import { UserContext } from "../../../Contexts/UserContext";
import { Tooltip } from "antd";
import CurrencyComponent from "../../Financial/CurrencyComponent";
import CurrencyTypeComponent from "../../Financial/CurrencyTypeComponent";
import LockIcon from "mdi-react/LockIcon";
import ConfirmPopup from "../../../Utils/ConfirmPopup";
import { TableHeaderContext } from "../../../Contexts/TableHeaderContext";
import { map } from "lodash";
import { TableDataContext } from "../../../Contexts/TableDataContext";

/* The above code is a React component written in JavaScript. It is a statement component for
displaying financial data. */
function PLStatement({
  data,
  isDetails,
  setIsDetail,
  companyName,
  openTab,
  isExport,
  companyLabel,
  cfsFinKeys
}) {
  const [selected, setSelected] = useState(1);
  const [alert, setalert] = useState({ message: "", show: false });
  const { openRequestPopup } = useContext(RaiseRequestContext);
  const {forExRate, setForExRate} = useContext(TableDataContext);

  const consolidatedData = data?.filter(
    (data) => data.fin_type === "Consolidated"
  ).sort((a, b) => parseInt(b.fy) - parseInt(a.fy));
  

  const standaloneData = data?.filter((data) => data.fin_type === "Standalone").sort((a, b) => parseInt(b.fy) - parseInt(a.fy));

  
  const { currencyType, currency, setFinancialType } =
    useContext(CurrencyContext);
  const { companyId } = useParams();

  const { primaryMasterId } = useModule();
  const { isStudent, isDirectoryUser, isTrialExport } = useContext(UserContext);
  console.log(isDirectoryUser, 'directory')

  let companyProfileId = parseInt(companyId);

  /**
   * The function `onButtonSelect` updates the selected and financial type based on the selected
   * type.
   */
  const onButtonSelect = (selectedType) => {
    setSelected(selectedType);
    setFinancialType(selectedType === 1 ? "Consolidated" : "Standalone");
  };

   useEffect(() => {
        if (consolidatedData?.length == 0) {
            onButtonSelect(2);
        } else if (consolidatedData?.length != 0) {
            onButtonSelect(1);
        }
    }, [data])

  const exportRequest = useMemo(() => {
    if (openTab === 1) {
      return {
        sub_section: ["plstandard"],
        sub_section_type: selected === 1 ? "PL-Consolidated" : "PL-Standalone",
        fileName:
          selected === 1
            ? Constants.exportNames.plConsolidated(companyName)
            : Constants.exportNames.plStandalone(companyName),
      };
    } else if (openTab === 2) {
      return {
        sub_section: ["balancesheet"],
        sub_section_type: selected === 1 ? "BS-Consolidated" : "BS-Standalone",
        fileName:
          selected === 1
            ? Constants.exportNames.balanceSheetCon(companyName)
            : Constants.exportNames.balanceSheetStandalone(companyName),
      };
    } else {
      return {
        sub_section: ["cashflow"],
        sub_section_type: selected === 1 ? "CF-Consolidated" : "CF-Standalone",
        fileName:
          selected === 1
            ? Constants.exportNames.cashflowCon(companyName)
            : Constants.exportNames.cashflowStandalone(companyName),
      };
    }
  }, [companyName, openTab, selected]);

  const onBlurColumnClick = () => {
    setalert({
      show: true,
      message: Constants.cfsSubscriptionMsg,
      isAlertOnly: true,
      closeBtnName: "OK",
    });
  };

  const onConfirmation = (flag) => {
    setalert({ show: false, message: "" });
  };

  const addCommasToNumber = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  function changeCurrencyType(fy, val, USDinM, INRinC, INRinL) {

    let showInUSD = val;
    const usdValue = forExRate?.find(item => item?.fy === fy)?.value;
    
    if (currencyType === 'USD') {
      if (usdValue) {
          showInUSD /= parseFloat(usdValue);
      } 
    }

    let divByval = 1;
  
    if (currencyType === 'INR' && currency === 'C') {
        divByval = INRinC;
    } else if(currencyType === 'INR' && currency === 'L'){
        divByval = INRinL
    }
     else if (currencyType === 'USD' && currency === 'M') {
        divByval = USDinM;
    }

    const totval = (showInUSD / divByval)
    let formattedValue;
    if (currency === 'A') {
      formattedValue = totval;
    } else {
      formattedValue = totval.toFixed(2);
    }

    return addCommasToNumber(formattedValue);
}
  
  useEffect(()=>{
    console.log('currencyType changed')
  }, [currency, currencyType]);


  return (
    <div>
      <div className="flex items-center justify-between px-3 md:px-5">
        <div className="flex items-center space-x-2">
          <div className="flex items-center space-x-2 md:space-x-3">
            {consolidatedData?.length != 0 && consolidatedData != null ? (
              <div className="">
                <HeaderButton
                  isSelected={selected === 1}
                  onClick={(e) => onButtonSelect(1)}
                >
                  Consolidated
                </HeaderButton>
              </div>
            ) : (
              <></>
            )}
            {standaloneData?.length != 0 && standaloneData != null ? (
              <div className="py-4">
                <HeaderButton
                  isSelected={selected === 2}
                  onClick={(e) => onButtonSelect(2)}
                >
                  Standalone
                </HeaderButton>
              </div>
            ) : (
              <></>
            )}
          </div>
          {companyLabel && (
              <div className="rounded-full bg-purple-100 px-2.5 py-[0.1rem] text-[9px] leading-[12px] font-sans_book_body text-[#333333]">
                XBRL
              </div>
            )}
        </div>

        {isStudent === "false" ? (
          <div>
            {(openTab === 1 || openTab === 2 || openTab === 3) &&
            data?.length !== 0 &&
            isExport === true ? (
              <div className="flex items-center md:justify-center justify-between">
                <div className="flex items-center mr-4">
                  <CurrencyTypeComponent />
                  <span className="md:mx-3 mx-1">in</span>
                  <CurrencyComponent />
                </div>
                {isStudent == "true" ? (
                  <></>
                ) : (
                  <ExcelDownloader
                    url={exportExcel}
                    fileName={exportRequest.fileName}
                    data={{
                      export_from: "detail",
                      module_type: "investments",
                      section: "financials",
                      type: primaryMasterId,
                      is_detailed: isDetails,
                      currency_cd: currencyType,
                      display_in: currency,
                      company_id: [companyProfileId],
                      sub_section: exportRequest.sub_section,
                      sub_section_type: exportRequest.sub_section_type,
                    }}
                  />
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
      {selected === 1 && consolidatedData?.length != 0 ? (
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead className="text-[12pt] leading-[15pt] bg-[#EDEDED] lg:text-[13px] lg:leading-[15px] font-bold text-[#666666] py-4 px-0 text-left">
              <tr>
                <th
                  width={`${isDetails ? "23%" : '10%'}`}
                  className="whitespace-nowrap px-5 py-3.5 text-left sticky left-0 bg-[#EDEDED]"
                >
                  Financials
                </th>
                {consolidatedData.map((data, index) => (
                  <th width={`${consolidatedData.length-1 === index ? '5':'8%'}`} className={`${consolidatedData.length - 1 === index ? "px-5" : "px-2"} py-3`}>
                    <div className={`flex justify-center ${consolidatedData.length-1 === index ? currency === "A" ? 'w-28' : currency === "L" ? "w-[5.5rem]" : 'w-16' :"w-auto" }`}>
                      FY {data.fy}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="text-[12pt] leading-[15pt] lg:text-[12px] lg:leading-[15px] text-[#666666] py-4 px-0 text-left">
              {cfsFinKeys.map(({ accessor, label, USDinM, INRinC, INRinL, style }) => (
                <tr
                  key={label}
                  className="border-b font-sans_book_body text-[#333333] leading-[24px] text-[14px]"
                >
                  <td className={`${style} md:whitespace-nowrap font-sans_book_body px-5 py-3 text-left sticky left-0 bg-white text-[#333333] w-[3rem]`}>
                    {label}
                  </td>
                  {consolidatedData?.map((year, index) => (
                    isDirectoryUser ? (
                      <td
                        key={`${accessor}-${year.fy}-restricted`}
                        onClick={onBlurColumnClick}
                        className="relative whitespace-nowrap cursor-pointer"
                      >
                        <div className="blur-sm bg-[#DCDCDC] select-none h-full px-5 py-4 w-20">
                          {Constants.restrictedPlaceholder}
                        </div>
                      </td>
                    ) : (
                      <td
                        key={`${accessor}-${year.fy}`}
                        className={`${style} md:whitespace-nowrap font-sans_book_body px-5 py-3 bg-white text-[#333333] w-[2rem]`}
                      >
                        <div className={`text-center ${consolidatedData.length-1 === index ? currency === "A" ? 'w-28' : currency === "L" ? "w-[5.5rem]" : 'w-16' :"w-auto" }`}>
                        {year[accessor] === 0 || year[accessor] === null || !year[accessor]
                          ? ""
                          : changeCurrencyType(year.fy, year[accessor], USDinM, INRinC, INRinL)}
                        </div>
                      </td>
                    )
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : selected === 2 && standaloneData?.length != 0 ? (
        <div className="overflow-auto">
          <table className="w-full">
            <thead className="text-[12pt] leading-[15pt] bg-[#EDEDED] lg:text-[13px] lg:leading-[15px] font-medium text-[#666666] py-4 px-0 text-left">
              <tr>
                <th width={`${isDetails ? "25%" : '10%'}`}
                  className="whitespace-nowrap px-5 py-3.5 text-left sticky left-0 top-0 bg-[#EDEDED]">
                  Financials
                </th>
                {standaloneData.map((fin, index) => {
                  return (
                    <th width={`${standaloneData.length - 1 === index ? "5" : "8%"}`}
                      className={`${standaloneData.length - 1 === index ? "px-5" : "px-2"} py-3`}
                      key={index}
                    >
                        {fin?.fy && (
                          <div className={`flex justify-center ${standaloneData.length - 1 === index ? currency === "A" ? 'w-28' : currency === "L" ? "w-[5.5rem]" : 'w-16' : "w-auto"}`}>
                          {`FY ${String(fin.fy).slice(-2)}`}
                          </div>
                        )}

                      </th>
                  );
                })}
              </tr>
            </thead>
            <tbody className="text-[12pt] leading-[15pt] lg:text-[12px] lg:leading-[15px] text-[#666666] py-4 px-0 text-left">
              {cfsFinKeys.map(({ accessor, label, USDinM, INRinC, INRinL, style }) => (
                label !== "Profit(loss) of minority interest" && label !== "Total PAT" && label !== "Minority interest" && (
                <tr
                  key={label}
                  className="border-b font-sans_book_body text-[#333333] leading-[24px]  text-[14px]"
                >
                  <td className={`${style} md:whitespace-nowrap font-sans_book_body px-5 py-3 text-left sticky left-0  bg-white text-[#333333] w-[3rem]`}>
                    {label}
                  </td>
                  {standaloneData.map((year, index) => (
                    isDirectoryUser ? (
                      <td
                        key={`${accessor}-${year.fy}-restricted`}
                        onClick={onBlurColumnClick}
                        className="relative whitespace-nowrap cursor-pointer"
                      >
                        <div className="blur-sm bg-[#DCDCDC] select-none h-full px-5 py-4 w-20">
                          {Constants.restrictedPlaceholder}
                        </div>
                      </td>
                    ) : (
                      <td
                        key={`${accessor}-${year.fy}`}
                        className={`${style} md:whitespace-nowrap font-sans_book_body px-5 py-3 text-left bg-white text-[#333333] w-[2rem]`}
                      >
                        <div className={`text-center ${standaloneData.length-1 === index ? currency === "A" ? 'w-28' : currency === "L" ? "w-[5.5rem]" : 'w-16' :"w-auto" }`}>
                          {year[accessor] === 0 || year[accessor] === null || !year[accessor] ? "" : changeCurrencyType(year.fy, year[accessor],USDinM, INRinC, INRinL)}
                        </div>
                      </td>
                    )
                  ))}
                </tr>
                )
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="h-28 w-full">
          <div className="w-full h-full flex items-center justify-center">
            {/* <img className='w-10 h-10' src={AlertGif} alt="" />     */}
            <p className="px-5 text-[14px]">
              Data not found. Please{" "}
              <button
                className="text-[#A5711A] hover:underline"
                onClick={() => openRequestPopup("financial", "CFS")}
              >
                Click Here
              </button>{" "}
              to alert Venture Intelligence about this. Thanks
            </p>
          </div>
        </div>
      )}
      {consolidatedData?.length === 0 && standaloneData?.length === 0 || isDetails ? <></> : 
        <div className="flex">
                  <button
                      onClick={(e) => {
                          e.preventDefault();
                          setIsDetail(true)
                      }}
                      className=" py-1 px-2 border rounded-[5px] border-[#D2D2D2] inline-flex items-center float-right mx-3 my-3"
                  >
                      <img src={lock} alt='' />
                      <span className="pl-2 pr-1 text-[#333333] text-[13px]">
                          Detailed
                      </span>
                  </button>
            </div>
        }
      {<ConfirmPopup {...alert} onChange={onConfirmation} />}
    </div>
  );
}

export default PLStatement;
