import React, { useState, useEffect } from 'react'
import ReactApexChart from "react-apexcharts";

import getChartColorsArray from "../../../../Components/Common/ChartsDynamicColor";
import { Link, NavLink } from 'react-router-dom';
import routePathSegments from '../../../../routeSegments';
import Loader from '../../../../Components/Loader';
import { DealRangeData } from '../../../../Components/Reports/ReportsMain';
import NoDataPlaceholder from '../../../../Components/shared/NoDataPlaceholder';


/**
 * The BarChart function is a React component that renders a bar chart based on the provided data and
 * options.
 * @returns The BarChart component is being returned.
 */
const BarChart = ({ dataColors, resultSet, title, chartFor, reportsFilter, masterData }) => {

    // console.log('from bar chart resultSet',  resultSet.categories())
    const product = reportsFilter.product;
    console.log('product', product);

    /**
     * The function "numberWithCommas" takes a number as input and returns a string representation of
     * that number with commas separating every three digits.
     * @param x - The parameter "x" is a number that you want to format with commas.
     * @returns the input number with commas added as thousands separators.
     */
    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }




    var chartStackedBarColors = getChartColorsArray(dataColors); 

    const dataSet = resultSet?.loadResponse?.results[0]?.data;
    console.log(dataSet,'dataset');


    
    let dataSetSource = '';
    let dataSetSourcePeriod = '';

    if (resultSet?.loadResponse?.results[0].query.dimensions?.length >= 3) {
        console.log('in - if');
        dataSetSource = resultSet?.loadResponse?.pivotQuery?.dimensions[resultSet?.loadResponse?.pivotQuery?.dimensions.length - 1];
        dataSetSourcePeriod = resultSet?.loadResponse?.pivotQuery?.dimensions[resultSet?.loadResponse?.pivotQuery?.dimensions.length - 2];
    } else {
        console.log('in - else');
        dataSetSource = resultSet?.loadResponse?.results[0].query ? resultSet?.loadResponse?.results[0].query.dimensions[1] : resultSet?.loadResponse?.results[0].pivotQuery.dimensions[1];
        dataSetSourcePeriod = resultSet?.loadResponse?.results[0].query ? resultSet?.loadResponse?.results[0].query.dimensions[0] : resultSet?.loadResponse?.results[0].pivotQuery.dimensions[0];
    }

    // const dataSetSource =  resultSet?.loadResponse?.results[0].query.dimensions[dataSet[0].query.dimensions.length - 1];
    // const dataSetSourcePeriod =  resultSet?.loadResponse?.results[0].query.dimensions[dataSet[0].query.dimensions.length - 2];

    // .filter((data) => data[dataSet[0].query.dimensions[dataSet[0].query.dimensions.length - 1]] === industry)

    /* Extracting unique industries from the resultSet */
    const dataSetSources = [...new Set(dataSet.map((data) => data[dataSetSource]))];

    // console.log('industries', industries);

    // Creating series data

    let filterMeasure = '';

    console.log('chartFor', chartFor);

    if (product == 'PE' || product == 'VC') {

        if (chartFor == 'amount') {
            filterMeasure = 'DealFlat.AmountM';
        } else {
            // filterMeasure = 'DealFlat.deal_id';
            filterMeasure = 'DealFlat.deal_id';
        }
    } else if (product == 'MA') {
        //MADateMaster.cy
        //MaInvestmentsFlat.dealAmount: 0
        // MaInvestmentsFlat.dealCount
        if (chartFor == 'amount') {
            filterMeasure = 'MaInvestmentsFlat.ma_dealAmount';
        } else {
            filterMeasure = 'MaInvestmentsFlat.ma_dealCount';
        }
    } else {
        if (chartFor == 'amount') {
            filterMeasure = 'REinvestments.amount';
        } else {
            filterMeasure = 'REinvestments.count';
        }
    }

    // const haveTypes = resultSet?.loadResponse?.results[0].query.dimensions?.length >= 3

    // const seriesData = resultSet?.series({
    //     y:haveTypes?resultSet?.loadResponse?.results[0].query.dimensions:[resultSet?.loadResponse?.results[0].query.dimensions[1]],
    //     x:['measures']
    // })
    // console.log("seriesData",seriesData);


   
    
    const years = Array.from(new Set(dataSet.map(item => item[dataSetSourcePeriod])))
        .filter(year => year !== null)
        .map(year => String(year));

    const isMultipleDimension = resultSet?.loadResponse?.results[0].query.dimensions?.length >= 3

    /* The `series` variable is an array that is created by mapping over the `dataSetSources` array.
    For each element in `dataSetSources`, it creates an object with two properties: `name` and
    `data`. */
    const series = dataSetSources.map((industry) => ({
        name: reportsFilter?.filterViewOption === "dealRange" ? DealRangeData.find(data => data.id === industry)?.name ?? industry : industry,
        data:years.map(year=>{

            const key = isMultipleDimension 
            ? resultSet?.loadResponse?.results[0].query.dimensions[1]
            : resultSet?.loadResponse?.results[0].query.dimensions[0]

            const factor = isMultipleDimension 
            ? resultSet?.loadResponse?.results[0].query.dimensions[2]
            : resultSet?.loadResponse?.results[0].query.dimensions[1]

            const value = dataSet?.find(data=>data[key] == year && data[factor] == industry)
            return value ? value[filterMeasure]:null
        }) 
    }));
   
    console.log('series-years', series,years,dataSet);

    // options.xaxis.categories = years;

    var options = {
        chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            toolbar: {
                show: false,
            }
        },
        dataLabels: {
            enabled: false,
        },
        plotOptions: {
            bar: {
                horizontal: true,
                barHeight:years.length * 15,
            },
        },
        stroke: {
            width: 1,
            colors: ['#fff']
        },
        title: {
            text: '',
            style: {
                fontWeight: 500,
            },
        },
        xaxis: {
            categories: years, //[2008, 2009, 2010, 2011, 2012, 2013, 2014],
            labels: {
                formatter: (value) => { return numberWithCommas(value); }
            }

        },
        yaxis: {
            title: {
                text: undefined
            },
            labels: {
                offsetY:years.length === 1? -10:0,
                formatter: (value, timeStampe, opts) => {

                    const dateMasterKeys = {
                        PE: "DateMasterCombo",
                        MA: "DateMasterCombo",
                        "PE-RE": "DateMasterCombo",
                    }
                    const periodKeys = {
                        CY: "cy",
                        FY: "fy",
                    }
                    const periodTypeKeys = {
                        quarterly: "qy",
                        halfYearly: "hy",
                        monthly: "my",
                        yearly: "",
                    }
                    if (reportsFilter?.period == "CY" && reportsFilter?.viewOption === "yearly") {
                        return value;
                        // formatter: (value) => '$ '+ numberWithCommas(Math.floor(value)),
                    }
                    const searchKey = `${dateMasterKeys[product ?? "PE"]}.${periodKeys[reportsFilter?.period ?? "FY"]}${periodTypeKeys[reportsFilter?.viewOption ?? "yearly"]}`;
                    console.log("searchKey",searchKey);
                    return masterData?.filter(data => data[searchKey] == value)?.at(0)?.[`${searchKey}Desc`] 
                }
            }
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return numberWithCommas(val);
                }
            }
        },
        fill: {
            opacity: 1
        },
        legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
        },
        colors: chartStackedBarColors,
    };

    return (
        <React.Fragment>
            <div className='bg-white border shadow rounded block w-full border-gray-100 md:ml-3 md:mr-3'>
                <h3 className='m-3 border-b-2 border-b-primaryColor w-fit'> {title}</h3>
                <div className='p-1'>
                    {dataSet.length >0 ?
                        <ReactApexChart
                            className="apex-charts"
                            options={options}
                            series={series}
                            type="bar"
                            height={450}
                        />                        
                        : <div className='my-3'><NoDataPlaceholder /></div>
                        // <p className='py-10 text-center'>No results found</p>

                    }
                </div>
            </div>
        </React.Fragment>
    );
};



export {
    BarChart
};