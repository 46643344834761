import React from 'react'
import Layout from '../../Components/Layout'
import AccountSetting from '../../Components/ProfileSetting/AccountSetting'

/**
 * The function returns a layout with  an account setting component.
 * @returns a JSX element. It is returning a Layout component with a paragraph element and an
 * AccountSetting component inside it.
 */
function Setting(){
    return (
        <Layout>
            <p className="mt-[3.5rem]">  </p>
            <AccountSetting/>                    
        </Layout>
    )
}

export default Setting;