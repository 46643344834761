import{React, useState, createContext} from 'react'
export const FaqContext = createContext('');

/**
 * The FaqContextProvider function is a React component that provides state and functions related to
 * FAQs to its children components.
 * @returns The FaqContextProvider component is being returned. It wraps the children components with
 * the FaqContext.Provider, providing the addDetail object as the value for the FaqContext.
 */
const FaqContextProvider = ({children}) => {
    const [searchResult, setSearchResult] = useState([]);
    const [selectedSearchResult, setSelectedSearchResult] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [faqs, setFaqs] = useState([]);
    const [trends,setTrends] = useState([]);



    const addDetail = {searchResult, setSearchResult,selectedSearchResult, setSelectedSearchResult, isLoading, setIsLoading ,faqs, setFaqs,trends,setTrends}

    return (
        <FaqContext.Provider value={addDetail}>
        {children}
    </FaqContext.Provider>
    )
}

export default FaqContextProvider
