import{React, useState, createContext} from 'react'

/* The code is creating a context object called `CurrencyContext` using the `createContext` function
from React. The context object is initialized with an initial value that contains several properties
and their corresponding setter functions. These properties include `currencyType`,
`setCurrencyType`, `currency`, `setCurrency`, `isDetailed`, `setIsDetailed`, `financialType`,
`setFinancialType`, `subSection`, `setSubSection`, `subSectionType`, and `setSubSectionType`. */
export const CurrencyContext = createContext({
    currencyType: null,
    setCurrencyType: () => { },
    currency: null,
    setCurrency: () => { }, 
    isDetailed:null,
    setIsDetailed:()=>{},   
    financialType:null,
    setFinancialType:()=>{ },
    subSection:null,
    setSubSection :()=>{ },
    subSectionType:null,
    setSubSectionType :()=>{ }
});

/**
 * The CurrencyContextProvider component is a wrapper that provides currency-related state and
 * functions to its children components.
 * @returns The CurrencyContextProvider component is being returned.
 */
const CurrencyContextProvider = ({children}) => {
    
    const [currencyType, setCurrencyType] =useState('INR');
    const [currency, setCurrency] = useState('C');    
    const [isDetailed, setIsDetailed] = useState(false); 
    const [financialType,setFinancialType] =useState()  
    const [subSection,setSubSection] =useState() 
    const [subSectionType,setSubSectionType] =useState()

    const addDetail = { currencyType, setCurrencyType, currency,setCurrency, isDetailed, setIsDetailed ,financialType,setFinancialType ,subSectionType,setSubSectionType,subSection,setSubSection,}
    // console.log(addDetail,'currency details')

  return (
    <CurrencyContext.Provider value={{ currencyType, setCurrencyType, currency,setCurrency,isDetailed, setIsDetailed,financialType,setFinancialType ,subSectionType,setSubSectionType,subSection,setSubSection,}}>
    {children}
</CurrencyContext.Provider>
  )
}

export default CurrencyContextProvider
