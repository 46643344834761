import React, { useState, useMemo, useEffect } from "react";
import { useQuery } from "@apollo/client";
import moment from "moment";
import DataTable from '../../Components/DataTables/DataTable';

/**
 * The NewsActivity component is a React component that displays recent news and activity data in a
 * data table format, with sorting functionality and pagination.
 * @returns The NewsActivity component is returning a JSX element that represents a section of a web
 * page. It includes a heading "Recent News & Activity" and a DataTable component that displays news
 * data.
 */
const NewsActivity = ({ Lparray, LP_NEWSLETTER }) => {
  const [
    currentData,
     setCurrentData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage,
     setDataPerPage
    ] = useState(20);
  const [newsletter, setNewsletter] = useState({});
  const [order, setOrder] = useState(true);
   

  const sample = Lparray;
  const partnerName = sample.map((number) => number.lpName);
  

  let limitedPartner = "%" + partnerName + "%";
  

  /**
   * The function handles the response from a newsletter fetch request and sets the newsletter state
   * with the response data.
   */
  const handleFetchNewsletterRequestCallback = (response, requestSuccess) => {
    try {
      setNewsletter(response);
      console.log("newsletter123", response);
    } catch (e) {
      console.log("Error", e);
    }
  };

  /* The code `const { data, loading, error } = useQuery(LP_NEWSLETTER, {...})` is using the `useQuery`
  hook from the Apollo Client library to make a GraphQL query request. */
  const { data, loading, error } = useQuery(LP_NEWSLETTER, {
    variables: { TagName: limitedPartner },
    onCompleted: (response) =>
      handleFetchNewsletterRequestCallback(response, true),
    onError: (response) => handleFetchNewsletterRequestCallback(response),
    refetch: LP_NEWSLETTER,
  });
  const pageNumbers = [];
  const NewsLetterData = useMemo(() => newsletter, [newsletter]);
  const indexOfLastPost = currentPage * dataPerPage;
  const indexOfFirstPost = indexOfLastPost - dataPerPage;

  useEffect(() => {
    console.log("newsletter123", newsletter);

    setCurrentData(
      NewsLetterData?.newsletter?.slice(indexOfFirstPost, indexOfLastPost)
    );

    for (
      let i = 1;
      i <= Math.ceil(NewsLetterData?.newsletter?.length / dataPerPage);
      i++
    ) {
      pageNumbers.push(i);
    }
  }, [NewsLetterData]);
  console.log("newsletter12345", NewsLetterData?.newsletter);
  const setPage = (pageNum) => {
    setCurrentPage(pageNum);
  };

  /**
   * The function "sorting" is used to sort an array of newsletter data based on a specified column
   * (either "heading" or "published_at") in ascending or descending order.
   */
  const sorting = (col) => {
    const numAscending = [...NewsLetterData?.newsletter].sort((a, b) =>
      col == "heading"
        ? order
          ? a.heading > b.heading
            ? 1
            : -1
          : a.heading < b.heading
          ? 1
          : -1
        : order
        ? a.published_at > b.published_at
          ? 1
          : -1
        : a.published_at < b.published_at
        ? 1
        : -1
    );
    setOrder(!order);
    setCurrentData(numAscending?.slice(indexOfFirstPost, indexOfLastPost));

    for (let i = 1; i <= Math.ceil(numAscending?.length / dataPerPage); i++) {
      pageNumbers.push(i);
    }
    console.log(numAscending);
  };
  /* The `customStringSortMemo` constant is using the `useMemo` hook to memoize a custom sorting
  function for string values in a data table. */
  const customStringSortMemo = useMemo(() => {
    return (rowA, rowB, columnId, desc) => {
        const defaultVal = desc ? '--' : 'A';
        return (rowA?.values[columnId]?.heading ?? defaultVal).localeCompare(rowB?.values[columnId]?.heading ?? defaultVal);
    }
}, []);
  /* The `const columns` variable is an array of objects that defines the columns for the data table in
  the NewsActivity component. Each object represents a column and contains properties such as `id`,
  `Header`, `accessor`, `className`, and `Cell`. */
  const columns = useMemo(() => [
    {
      id:"news",
      Header: "News",
      accessor: "news",
      className: "text-[#333333] text-[12pt] px-6 py-4 flex-wrap lg:text-[14px] lg:leading-[16px]",
      Cell: ({ value }) => {
        return <a href={"https://news.ventureintelligence.com/" + JSON.parse(value?.category?.slug) + '/' + value?.slug} target="_blank" rel="noreferrer">
          {value?.heading}
        </a>
      },
      // sortType: customStringSortMemo,
    },
    {
      id:"releaseDate",
      Header: "Release Date",
      accessor: "releaseDate",
      className: "text-[#333333] lg:px-12 px-8 py-4 float-center lg:float-right whitespace-nowrap lg:text-[14px] lg:leading-[16px]",
      headerClassName: "text-center float-right flex lg:px-10 px-8 py-3.5",
      Cell: ({ value }) => {
        return moment(value).format('MMM DD, YYYY')
      }
    }
  ], [])
  
  /* The `rowData` constant is using the `useMemo` hook to memoize an array of objects. Each object
  represents a row in the data table and contains the data for each column. */
  const rowData = useMemo(() => {
    const rows = [];
  
    if (newsletter) {
      newsletter?.newsletter?.forEach(newsletter => {
        rows.push({
          news: newsletter,
          releaseDate: newsletter?.published_at,
        })
      });
    }
  
    return rows;
  
  }, [newsletter])
  /* The `initialSort` constant is using the `useMemo` hook to memoize an array of objects. Each object
  represents a column in the data table and specifies the initial sorting configuration for that
  column. In this case, the `id` property is set to `'releaseDate'`, indicating that the column with
  the ID `'releaseDate'` should be sorted initially. The `desc` property is set to `false`,
  indicating that the sorting should be in ascending order for that column. The empty dependency
  array `[]` ensures that the `initialSort` value is only computed once and does not change during
  re-renders. */
  const initialSort = useMemo(() => [
    {
        id: 'releaseDate',
        desc: false
    }
  ], []);
  return (
    <div className="px-4  bg-[#EDEDED] w-[100%]">
      <div className="  bg-white border  overflow-hidden sm:rounded-lg shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
        <div className="pt-4  px-5">
          <p className="text-[14pt] leading-[19pt] lg:text-[#333333] lg:text-[17px] font-semibold  lg:leading-[22px] mb-3 ">
            Recent News & Activity
          </p>
        </div>
        <DataTable columns={columns} data={rowData} sortBy={initialSort}/>        
      </div>
      <br />
      <br />
    </div>
  );
};

export default NewsActivity;
